<ng-container [formGroup]="targetingForm">
  <hr class='subDivider'>
  <h3>{{ 'campaign.domainName.title' | translate }}</h3>
  <div formArrayName="domainNameTargets" class="d-flex flex-column align-items-center">
    <div>
      <span [innerHTML]="'campaign.domainName.descriptionPrefix' | translate"></span><strong
      *ngIf="selectedDomainNames() as selectedList"><span
      *ngIf="selectedList.length === 0">{{ 'campaign.domainName.any' | translate }}</span><span
      *ngIf="selectedList.length > 0"><span
      *ngFor="let selectedItem of selectedList; let isLast=last">{{ selectedItem }}<span
      *ngIf="!isLast" translate="grammar.listSeparator"></span> </span></span></strong>
    </div>
    <div>
      <ng-container *ngFor="let domainNameTargetControl of targetingForm.controls.domainNameTargets.controls; let i = index">
        <div class="form-check form-check-inline" *ngIf="'domainNameTarget_'+uuid+i as id">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="i"
            [id]="id"
          />
          <label class="form-check-label" [for]="id" *ngIf="getDomainNameTargetFromIndex(i) as domainNameTarget">
            {{domainNameTarget}}
          </label>
        </div>
      </ng-container>
    </div>
    <div class="small">{{ 'campaign.domainName.instructions' | translate }}</div>
  </div>
</ng-container>
