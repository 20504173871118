import {Component, Input, OnInit} from '@angular/core';
import {LineItem} from '@app/@core/@models/advertising/lineitem.model';
import {CampaignForm} from '@app/@core/advertising/campaign/campaign-form';
import {EMPTY, finalize, Observable, tap} from 'rxjs';
import {ReachEstimate} from '@app/@core/@models/advertising/reach-estimate.model';
import {ReachEstimateResource} from '@app/@core/@rest/advertising/reach-estimate-resource.service';
import {catchError, filter} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import { isErrorMessage } from '@app/@core/@models/common/error-message';
import { I18nLangService } from '@app/@i18n/services/i18n-lang.service';

@Component({
  selector: 'app-campaign-reach-estimate[lineItem][campaignForm]',
  templateUrl: './campaign-reach-estimate.component.html',
  styleUrls: ['./campaign-reach-estimate.component.scss']
})
export class CampaignReachEstimateComponent implements OnInit {
  @Input() lineItem!: LineItem;
  @Input() campaignForm!: CampaignForm;
  doingReachEstimate: boolean = false;
  reachEstimate$?: Observable<ReachEstimate>;
  error?: string;
  reachEstimateType?: 'VERY_GOOD' | 'GOOD' | 'COMPETITION' | 'MUCH_COMPETITION' | 'LOTS_COMPETITION';
  reachEstimateRangeAve: number = 0;
  reachEstimateRangeLower: number = 0;
  reachEstimateRangeUpper: number = 0;

  constructor(private reachEstimateResource: ReachEstimateResource, private i18nLangService: I18nLangService) {
  }

  ngOnInit(): void {
  }

  makeReachEstimate() {
    this.doingReachEstimate = true;
    this.error = undefined;
    this.reachEstimate$ = this.reachEstimateResource.post(this.lineItem.id).pipe(
      catchError((err) => {
        if (isErrorMessage(err.error)) {
          this.error = this.i18nLangService.translateWithDefault(err.error.text, 'validation.' + err.error.code, err.error.params);
        } else {
          this.error = err.statusText || err;
        }
        return EMPTY;
      }),
      tap((reachEstimate) => {
        const reachObj: number = this.lineItem.objectives!.RENDERED_IMPRESSION! || 0;
        this.reachEstimateRangeAve = reachEstimate.reach - reachEstimate.allocated - reachObj;
        this.reachEstimateRangeLower = reachEstimate.reachLowerBound - reachEstimate.allocated - reachObj;
        this.reachEstimateRangeUpper = reachEstimate.reachUpperBound - reachEstimate.allocated - reachObj;
        if (this.reachEstimateRangeLower > 0) {
          this.reachEstimateType = 'VERY_GOOD';
        } else if (this.reachEstimateRangeAve > 0) {
          this.reachEstimateType = 'GOOD';
        } else if (this.reachEstimateRangeUpper > 0) {
          this.reachEstimateType = 'COMPETITION';
        } else if (this.reachEstimateRangeUpper > -100000) {
          this.reachEstimateType = 'MUCH_COMPETITION';
        } else {
          this.reachEstimateType = 'LOTS_COMPETITION';
        }
      }),
      finalize(() => {
        this.doingReachEstimate = false;
      })
    );
  }

  getAlertType() {
    if (this.reachEstimateType === 'VERY_GOOD' || this.reachEstimateType === 'GOOD') {
      return 'success';
    } else if (this.reachEstimateType === 'COMPETITION') {
      return 'info';
    } else {
      return 'warning';
    }
  }

  isReachEstimateTriggerDisabled() {
    return (this.lineItem.objectives?.RENDERED_IMPRESSION && this.lineItem.objectives.RENDERED_IMPRESSION < 1)
      || !this.campaignForm.pristine
      || this.campaignForm.pending;
  }

}
