import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AccessToken } from '@app/@core/@models/auth/access-token.model';
import { ConfigService } from '@app/@core/@config/config.service';
import { CacheRegistry } from '@shared/storage/cache-registry.service';
import { AccessTokenStore } from '@app/@core/auth/access-token-store.service';
import {AnonProfileStore} from '@app/@core/anon/anon-profile-store.service';
import {RollbarService} from '@shared/logging/rollbar.service';

// TODO: extract http access to AuthenticateResource service
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private accessToken$: BehaviorSubject<AccessToken | undefined>;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private cacheRegistry: CacheRegistry,
    private accessTokenStore: AccessTokenStore,
    private anonProfileStore: AnonProfileStore,
    private rollbarService: RollbarService
  ) {
    this.accessToken$ = new BehaviorSubject(this.accessTokenStore.getAccessToken());
  }

  authenticate(username: string, password: string, masqUsername?: string): Observable<AccessToken> {
    const encUsername = encodeURIComponent(username);
    const encPassword = encodeURIComponent(password);
    const encMasq = encodeURIComponent(masqUsername || '');
    const authEndPoint = this.configService.getUri(masqUsername ? 'masquerade' : 'authenticate');
    return this.httpClient
      .post<AccessToken>(
        `${authEndPoint}?app=SELF_SERVICE`,
        `grant_type=password&scope=ng_api&username=${encUsername}&password=${encPassword}&masqueradeUser=${encMasq}`,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .pipe(
        // success
        tap((accessToken) => {
          this.cacheRegistry.flushAll();
          this.setAccessToken(accessToken);
          this.anonProfileStore.updateAnonProfile({
            username: username,
            masqueradeUser: masqUsername ? masqUsername : undefined
          });
        })
      );
  }

  refreshToken(): Observable<AccessToken> {
    const authEndPoint = this.configService.getUri('authenticate');
    const refreshToken = this.getAccessToken()?.refresh_token!;
    return this.httpClient
      .post<AccessToken>(
        `${authEndPoint}?app=SELF_SERVICE`,
        `grant_type=refresh_token&refresh_token=${refreshToken}`,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .pipe(
        // success
        tap((accessToken) => {
          this.setAccessToken(accessToken);
        })
      );
  }

  setAccessToken(accessToken: AccessToken) {
    this.accessTokenStore.setAccessToken(accessToken);
    this.accessToken$.next(accessToken);
  }

  getAccessToken(): AccessToken | undefined {
    return this.accessToken$.value;
  }

  signOut() {
    this.cacheRegistry.flushAll();
    this.accessToken$.next(undefined);
    this.rollbarService.setUserId(undefined);
  }

  isAuthenticated() {
    const token = this.accessToken$.value;
    return token != undefined;
  }

  isMasquerade(): boolean | undefined {
    const token = this.accessToken$.value;
    if (token) {
      return !!token.masquerader_username;
    }
    return undefined;
  }

  reset(username: string): Observable<undefined> {
    const interfaceCode = this.configService.getNetworkData()?.interface || '';
    return this.httpClient.post<undefined>(
      this.configService.getUri('authenticate/reset'),
      new HttpParams().set('username', username).toString(),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        params: new HttpParams().set('app', 'SELF_SERVICE').set('interface', interfaceCode),
      }
    );
  }
}
