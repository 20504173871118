<ng-container [formGroup]="textComponentForm">
  <label [for]="'textarea'+uuid" class="form-label">{{ textComponent().name || textComponent().tag }}</label>
  <textarea class="form-control" [id]="'textarea'+uuid" [rows]="rows" formControlName="componentValue" [ngClass]="{ 'is-invalid': isInvalid() }"
            data-lpignore="true" *ngIf='rows > 1'
  ></textarea>
  <input class="form-control" [id]="'textarea'+uuid" formControlName="componentValue" [ngClass]="{ 'is-invalid': isInvalid() }"
            data-lpignore="true" *ngIf='rows < 2'
  >
  <div [ngClass]="{ 'invalid-feedback': isInvalid()}" class="form-text">
    <div class="d-flex justify-content-between">
      <div *ngIf="textComponent().required === 'MANDATORY'"
           [innerHTML]="'campaign.ad.textHintChars' | translate: { min: textComponent().minLength, max: textComponent().maxLength }"></div>
      <div *ngIf="textComponent().required === 'OPTIONAL'"
           [innerHTML]="'campaign.ad.textHintCharsOptional' | translate: { min: textComponent().minLength, max: textComponent().maxLength }"></div>
      <div>{{ 'campaign.ad.textCount' | translate: {count: textControl().value.length} }}</div>
    </div>
    <div *ngIf="isInvalid()">
      <div *ngFor="let err of textControl().errors | keyvalue">
        <span *ngIf="err.key === 'maxlength' || err.key === 'minlength'">{{ 'validation.pattern' | translate: {
          minLength: textComponent().minLength,
          maxLength: textComponent().maxLength
        } }}</span>
        <span *ngIf="err.key !== 'maxlength' && err.key !== 'minlength'">{{ 'validation.' + err.key | translate }}</span>
      </div>
      <ng-container *ngIf="showServerValidationWarning()">
        <ng-container *ngFor="let warning of serverValidationWarnings">
          <div *ngIf="warning.code !== 'validation.warnings.creative.missing.component'">{{ i18nFormat.translateValidationWarningMessage(warning) }}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
