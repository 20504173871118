import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TextComponent } from '@app/@core/@models/advertising/layout/text-component.model';
import { TextComponentForm } from '@app/@core/advertising/campaign/layout/text-component-form';
import { ValidationWarningMessage } from '@app/@core/@models/advertising/validation-warning-message.model';
import { I18nFormatService } from '@app/@i18n/services/i18n-format.service';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-text-component-editor[textComponentForm][serverValidationWarnings]',
  templateUrl: './text-component-editor.component.html',
  styleUrls: ['./text-component-editor.component.scss']
})
export class TextComponentEditorComponent implements OnInit {
  @Input() textComponentForm!: TextComponentForm;
  @Input() serverValidationWarnings?: ValidationWarningMessage[];
  uuid: string = Uuid.generate();
  rows: number = 0;

  constructor(public i18nFormat: I18nFormatService) {
  }

  ngOnInit(): void {
    this.rows = Math.min(Math.max(Math.floor(this.textComponent().maxLength / 80 + 1), 1), 10);
  }

  textComponent(): TextComponent {
    return this.textComponentForm.value.layoutComponent as TextComponent;
  }

  textControl(): FormControl<string> {
    return this.textComponentForm.controls.componentValue;
  }

  isInvalid(): boolean {
    return this.textControl().invalid || this.showServerValidationWarning();
  }

  showServerValidationWarning(): boolean {
    return this.textControl().pristine && !!this.serverValidationWarnings?.length;
  }
}
