import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleIdService extends String {
  locale: string;

  constructor() {
    super();
    this.locale = 'en';
  }

  override toString() {
    return this.locale;
  }

  setLocale(locale: string) {
    this.locale = locale;
  }

}
