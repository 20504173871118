import {Component, Input, OnInit} from '@angular/core';
import { AdvertForm } from '@app/@core/advertising/campaign/advert/advert-form';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-copyright-declaration[advertForm]',
  templateUrl: './copyright-declaration.component.html',
  styleUrls: ['./copyright-declaration.component.scss']
})
export class CopyrightDeclarationComponent implements OnInit {
  @Input() advertForm!: AdvertForm;
  uuid: string = Uuid.generate();

  constructor() { }

  ngOnInit(): void {
  }

  isInvalid(): boolean {
    return !this.advertForm.controls.copyrightStatus.value;
  }
}
