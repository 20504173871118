export function getMeinestadtLocations() {
  return {
    "aach-trier": "Aach b. Trier",
    "aach-im-hegau": "Aach im Hegau",
    "aachen": "Aachen",
    "aalen": "Aalen",
    "aarbergen": "Aarbergen",
    "aasbuettel": "Aasbüttel",
    "abenberg": "Abenberg",
    "abensberg": "Abensberg",
    "abentheuer": "Abentheuer",
    "abstatt": "Abstatt",
    "abtsbessingen": "Abtsbessingen",
    "abtsgmuend": "Abtsgmünd",
    "abtsteinach": "Abtsteinach",
    "abtswind": "Abtswind",
    "abtweiler": "Abtweiler",
    "achberg": "Achberg",
    "achern": "Achern",
    "achim-bremen": "Achim b. Bremen",
    "achim-wolfenbuettel": "Achim/Kr. Wolfenbüttel",
    "achslach": "Achslach",
    "achstetten": "Achstetten",
    "acht": "Acht",
    "achtelsbach": "Achtelsbach",
    "achterwehr": "Achterwehr",
    "achtrup": "Achtrup",
    "adelberg": "Adelberg",
    "adelebsen": "Adelebsen",
    "adelheidsdorf": "Adelheidsdorf",
    "adelmannsfelden": "Adelmannsfelden",
    "adelschlag": "Adelschlag",
    "adelsdorf-mittelfranken": "Adelsdorf/Mittelfranken",
    "adelsheim": "Adelsheim",
    "adelshofen-fuerstenfeldbruck": "Adelshofen Kr. Fürstenfeldbruck",
    "adelshofen-mittelfranken": "Adelshofen/Mittelfranken",
    "adelsried": "Adelsried",
    "adelzhausen": "Adelzhausen",
    "adenau": "Adenau",
    "adenbach": "Adenbach",
    "adenbuettel": "Adenbüttel",
    "adendorf": "Adendorf",
    "adenstedt": "Adenstedt",
    "adlkofen": "Adlkofen",
    "admannshagen-bargeshagen": "Admannshagen-Bargeshagen",
    "adorf": "Adorf/Vogtl.",
    "aebtissinwisch": "Aebtissinwisch",
    "aerzen": "Aerzen",
    "affalterbach": "Affalterbach",
    "affing": "Affing",
    "affinghausen": "Affinghausen",
    "affler": "Affler",
    "agathenburg": "Agathenburg",
    "agethorst": "Agethorst",
    "aglasterhausen": "Aglasterhausen",
    "aham": "Aham",
    "ahaus": "Ahaus",
    "ahausen": "Ahausen",
    "ahlbeck-uecker-randow": "Ahlbeck Kr. Uecker-Randow",
    "ahlden-aller": "Ahlden/Aller",
    "ahlefeld-bistensee": "Ahlefeld-Bistensee",
    "ahlen": "Ahlen",
    "ahlerstedt": "Ahlerstedt",
    "ahlsdorf-eisleben": "Ahlsdorf b. Eisleben",
    "ahlstaedt": "Ahlstädt",
    "ahnatal": "Ahnatal",
    "ahneby": "Ahneby",
    "ahnsbeck": "Ahnsbeck",
    "ahnsen": "Ahnsen",
    "aholfing": "Aholfing",
    "aholming": "Aholming",
    "ahorn-coburg": "Ahorn Kr. Coburg",
    "ahorn-baden": "Ahorn/Baden",
    "ahorntal": "Ahorntal",
    "ahrbrueck": "Ahrbrück",
    "ahrensboek": "Ahrensbök",
    "ahrensburg": "Ahrensburg",
    "ahrensfelde": "Ahrensfelde",
    "ahrenshagen-daskow": "Ahrenshagen-Daskow",
    "ahrenshoeft": "Ahrenshöft",
    "ahrenshoop": "Ahrenshoop",
    "ahrenvioel": "Ahrenviöl",
    "ahrenvioelfeld": "Ahrenviölfeld",
    "aicha-vorm-wald": "Aicha vorm Wald",
    "aichach": "Aichach",
    "aichelberg": "Aichelberg",
    "aichen": "Aichen",
    "aichhalden": "Aichhalden",
    "aichstetten": "Aichstetten",
    "aichtal": "Aichtal",
    "aichwald": "Aichwald",
    "aidenbach": "Aidenbach",
    "aidhausen": "Aidhausen",
    "aidlingen": "Aidlingen",
    "aiglsbach": "Aiglsbach",
    "ailertchen": "Ailertchen",
    "aindling": "Aindling",
    "ainring": "Ainring",
    "aislingen": "Aislingen",
    "aiterhofen": "Aiterhofen",
    "aitern": "Aitern",
    "aitrach": "Aitrach",
    "aitrang": "Aitrang",
    "aken-elbe": "Aken (Elbe)",
    "aland": "Aland",
    "albaching": "Albaching",
    "albbruck": "Albbruck",
    "albersdorf-stadtroda": "Albersdorf b. Stadtroda",
    "albersdorf-holstein": "Albersdorf/Holst.",
    "albershausen": "Albershausen",
    "albersweiler": "Albersweiler",
    "albertshofen": "Albertshofen",
    "albessen": "Albessen",
    "albig": "Albig",
    "albisheim-pfrimm": "Albisheim/Pfrimm",
    "albsfelde": "Albsfelde",
    "albstadt": "Albstadt",
    "aldenhoven": "Aldenhoven",
    "aldersbach": "Aldersbach",
    "aldingen": "Aldingen",
    "alerheim": "Alerheim",
    "alesheim": "Alesheim",
    "aletshausen": "Aletshausen",
    "alf": "Alf",
    "alfdorf": "Alfdorf",
    "alfeld-leine": "Alfeld/Leine",
    "alfeld-mittelfranken": "Alfeld/Mittelfranken",
    "alfhausen": "Alfhausen",
    "alflen": "Alflen",
    "alfstedt": "Alfstedt",
    "alfter": "Alfter",
    "algermissen": "Algermissen",
    "alheim": "Alheim",
    "alken": "Alken",
    "alkersleben": "Alkersleben",
    "alkersum": "Alkersum",
    "allenbach": "Allenbach",
    "allendorf-rudolstadt": "Allendorf b. Rudolstadt",
    "allendorf-eder": "Allendorf/Eder",
    "allendorf-lumda": "Allendorf/Lumda",
    "allendorf-rhein-lahn-kreis": "Allendorf/Rhein-Lahn-Kreis",
    "allenfeld": "Allenfeld",
    "allensbach": "Allensbach",
    "allersberg": "Allersberg",
    "allershausen": "Allershausen",
    "alleshausen": "Alleshausen",
    "alling": "Alling",
    "allmannshofen": "Allmannshofen",
    "allmannsweiler": "Allmannsweiler",
    "allmendingen": "Allmendingen",
    "allmersbach-im-tal": "Allmersbach im Tal",
    "allstedt": "Allstedt",
    "almdorf": "Almdorf",
    "almersbach": "Almersbach",
    "almstedt": "Almstedt",
    "alpen": "Alpen",
    "alpenrod": "Alpenrod",
    "alperstedt": "Alperstedt",
    "alpirsbach": "Alpirsbach",
    "alsbach": "Alsbach",
    "alsbach-haehnlein": "Alsbach-Hähnlein",
    "alsdorf-eifel": "Alsdorf/Eifel",
    "alsdorf-rheinland": "Alsdorf/Rheinland",
    "alsdorf-sieg": "Alsdorf/Sieg",
    "alsenz": "Alsenz",
    "alsfeld": "Alsfeld",
    "alsheim": "Alsheim",
    "alsleben-saale": "Alsleben (Saale)",
    "alt-bennebek": "Alt Bennebek",
    "alt-bukow": "Alt Bukow",
    "alt-duvenstedt": "Alt Duvenstedt",
    "alt-krenzlin": "Alt Krenzlin",
    "alt-meteln": "Alt Meteln",
    "alt-moelln": "Alt Mölln",
    "alt-schwerin": "Alt Schwerin",
    "alt-tellin": "Alt Tellin",
    "alt-tucheband": "Alt Tucheband",
    "alt-zachun": "Alt Zachun",
    "alt-zauche-wusswerk": "Alt Zauche-Wußwerk",
    "altbach": "Altbach",
    "altdoebern": "Altdöbern",
    "altdorf-nuernberg": "Altdorf b. Nürnberg",
    "altdorf-nuertingen": "Altdorf b. Nürtingen",
    "altdorf-boeblingen": "Altdorf Kr. Böblingen",
    "altdorf-nieder": "Altdorf/Niederb.",
    "altdorf-pfalz": "Altdorf/Pfalz",
    "altefaehr": "Altefähr",
    "alteglofsheim": "Alteglofsheim",
    "altena": "Altena",
    "altenahr": "Altenahr",
    "altenbamberg": "Altenbamberg",
    "altenbeken": "Altenbeken",
    "altenberg": "Altenberg",
    "altenberga": "Altenberga",
    "altenberge": "Altenberge",
    "altenbeuthen": "Altenbeuthen",
    "altenbuch": "Altenbuch",
    "altenburg": "Altenburg",
    "altendiez": "Altendiez",
    "altendorf-nabburg": "Altendorf b. Nabburg",
    "altendorf-bamberg": "Altendorf Kr. Bamberg",
    "altenfeld": "Altenfeld",
    "altenglan": "Altenglan",
    "altengottern": "Altengottern",
    "altenhagen-altentreptow": "Altenhagen b. Altentreptow",
    "altenhausen": "Altenhausen",
    "altenhof-eckernfoerde": "Altenhof b. Eckernförde",
    "altenhof-roebel": "Altenhof b. Röbel",
    "altenholz": "Altenholz",
    "altenkirchen-pfalz": "Altenkirchen/Pfalz",
    "altenkirchen-ruegen": "Altenkirchen/Rügen",
    "altenkirchen-westerwald": "Altenkirchen/Westerwald",
    "altenkrempe": "Altenkrempe",
    "altenkunstadt": "Altenkunstadt",
    "altenmarkt-alz": "Altenmarkt a.d. Alz",
    "altenmedingen": "Altenmedingen",
    "altenmoor": "Altenmoor",
    "altenmuenster": "Altenmünster",
    "altenpleen": "Altenpleen",
    "altenriet": "Altenriet",
    "altenstadt-waldnaab": "Altenstadt a.d. Waldnaab",
    "altenstadt-schongau": "Altenstadt b. Schongau",
    "altenstadt-hessen": "Altenstadt/Hessen",
    "altenstadt-iller": "Altenstadt/Iller",
    "altensteig": "Altensteig",
    "altenthann": "Altenthann",
    "altentreptow": "Altentreptow",
    "alterkuelz": "Alterkülz",
    "altersbach": "Altersbach",
    "altertheim": "Altertheim",
    "altfraunhofen": "Altfraunhofen",
    "althegnenberg": "Althegnenberg",
    "altheim-ehingen": "Altheim b. Ehingen",
    "altheim-riedlingen": "Altheim b. Riedlingen",
    "altheim-alb": "Altheim/Alb",
    "althengstett": "Althengstett",
    "althornbach": "Althornbach",
    "althuette": "Althütte",
    "althuettendorf": "Althüttendorf",
    "altkalen": "Altkalen",
    "altkirchen": "Altkirchen",
    "altlandsberg": "Altlandsberg",
    "altlay": "Altlay",
    "altleiningen": "Altleiningen",
    "altlussheim": "Altlußheim",
    "altmannstein": "Altmannstein",
    "altmaerkische-hoehe": "Altmärkische Höhe",
    "altmaerkische-wische": "Altmärkische Wische",
    "altmittweida": "Altmittweida",
    "altomuenster": "Altomünster",
    "altoetting": "Altötting",
    "altrich": "Altrich",
    "altrip": "Altrip",
    "altscheid": "Altscheid",
    "altshausen": "Altshausen",
    "altstrimmig": "Altstrimmig",
    "alt-suehrkow": "Alt-Sührkow",
    "altusried": "Altusried",
    "altwarp": "Altwarp",
    "altweidelbach": "Altweidelbach",
    "altwigshagen": "Altwigshagen",
    "alveslohe": "Alveslohe",
    "alzenau": "Alzenau",
    "alzey": "Alzey",
    "am-ettersberg": "Am Ettersberg",
    "am-grossen-bruch": "Am Großen Bruch",
    "am-mellensee": "Am Mellensee",
    "am-ohmberg": "Am Ohmberg",
    "am-salzhaff": "Am Salzhaff",
    "amberg-oberpfalz": "Amberg",
    "amberg-buchloe": "Amberg b. Buchloe",
    "amelinghausen": "Amelinghausen",
    "amerang": "Amerang",
    "amerdingen": "Amerdingen",
    "ammeldingen-our": "Ammeldingen a.d. Our",
    "ammeldingen-neuerburg": "Ammeldingen b. Neuerburg",
    "ammerbuch": "Ammerbuch",
    "ammerndorf": "Ammerndorf",
    "ammersbek": "Ammersbek",
    "ammerthal": "Ammerthal",
    "amoeneburg": "Amöneburg",
    "amorbach": "Amorbach",
    "ampfing": "Ampfing",
    "amstetten": "Amstetten",
    "amt-neuhaus": "Amt Neuhaus",
    "amt-wachsenburg": "Amt Wachsenburg",
    "amtsberg": "Amtsberg",
    "amtzell": "Amtzell",
    "an-der-poststrasse": "An der Poststraße",
    "an-der-schmuecke": "An der Schmücke",
    "andechs": "Andechs",
    "anderlingen": "Anderlingen",
    "andernach": "Andernach",
    "andervenne": "Andervenne",
    "andisleben": "Andisleben",
    "angelbachtal": "Angelbachtal",
    "angelburg": "Angelburg",
    "angelroda": "Angelroda",
    "anger": "Anger",
    "angermuende": "Angermünde",
    "angern": "Angern",
    "anhausen": "Anhausen",
    "ankershagen": "Ankershagen",
    "anklam": "Anklam",
    "ankum": "Ankum",
    "annaberg-buchholz": "Annaberg-Buchholz",
    "annaburg": "Annaburg",
    "annweiler-am-trifels": "Annweiler am Trifels",
    "anroechte": "Anröchte",
    "anrode": "Anrode",
    "ansbach": "Ansbach",
    "anschau": "Anschau",
    "antdorf": "Antdorf",
    "antrifttal": "Antrifttal",
    "antweiler": "Antweiler",
    "anzing": "Anzing",
    "apelern": "Apelern",
    "apen": "Apen",
    "apenburg-winterfeld-flecken": "Apenburg-Winterfeld, Flecken",
    "apensen": "Apensen",
    "apfeldorf": "Apfeldorf",
    "apfeltrach": "Apfeltrach",
    "apolda": "Apolda",
    "appel": "Appel",
    "appen": "Appen",
    "appenheim": "Appenheim",
    "appenweier": "Appenweier",
    "arbach": "Arbach",
    "arberg": "Arberg",
    "aremberg": "Aremberg",
    "arendsee-altmark": "Arendsee (Altmark)",
    "arenrath": "Arenrath",
    "arenshausen": "Arenshausen",
    "aresing": "Aresing",
    "arft": "Arft",
    "argenbuehl": "Argenbühl",
    "argenschwang": "Argenschwang",
    "argenthal": "Argenthal",
    "arholzen": "Arholzen",
    "arkebek": "Arkebek",
    "arlewatt": "Arlewatt",
    "armsheim": "Armsheim",
    "armstedt": "Armstedt",
    "armstorf": "Armstorf",
    "arnbruck": "Arnbruck",
    "arneburg": "Arneburg",
    "arnis": "Arnis",
    "arnsberg": "Arnsberg",
    "arnschwang": "Arnschwang",
    "arnsdorf": "Arnsdorf b. Dresden",
    "arnshoefen": "Arnshöfen",
    "arnstadt": "Arnstadt",
    "arnstein": "Arnstein",
    "arnstein-sachsen-anhalt": "Arnstein (Sachsen-Anhalt)",
    "arnstorf": "Arnstorf",
    "arpsdorf": "Arpsdorf",
    "arrach": "Arrach",
    "artern-unstrut": "Artern",
    "artlenburg": "Artlenburg",
    "arzbach": "Arzbach",
    "arzberg-torgau": "Arzberg b. Torgau",
    "arzberg-oberfranken": "Arzberg/Oberfranken",
    "arzfeld": "Arzfeld",
    "asbach-hunsrueck": "Asbach/Hunsrück",
    "asbach-westerwald": "Asbach/Westerwald",
    "asbach-baeumenheim": "Asbach-Bäumenheim",
    "asbach-sickenberg": "Asbach-Sickenberg",
    "ascha": "Ascha",
    "aschaffenburg": "Aschaffenburg",
    "aschau-inn": "Aschau a. Inn",
    "aschau-chiemgau": "Aschau i. Chiemgau",
    "aschbach": "Aschbach",
    "ascheberg-holstein": "Ascheberg/Holstein",
    "ascheberg-westfalen": "Ascheberg/Westfalen",
    "ascheffel": "Ascheffel",
    "aschenhausen": "Aschenhausen",
    "aschersleben": "Aschersleben",
    "aschheim": "Aschheim",
    "asendorf-bruchhausen-vilsen": "Asendorf b. Bruchhausen-Vilsen",
    "asendorf-nordheide": "Asendorf/Nordheide",
    "aspach-backnang": "Aspach b. Backnang",
    "asperg": "Asperg",
    "aspisheim": "Aspisheim",
    "assamstadt": "Assamstadt",
    "asselfingen": "Asselfingen",
    "asslar": "Aßlar",
    "assling": "Aßling",
    "astert": "Astert",
    "attendorn": "Attendorn",
    "attenhausen": "Attenhausen",
    "attenhofen": "Attenhofen",
    "attenkirchen": "Attenkirchen",
    "attenweiler": "Attenweiler",
    "atting": "Atting",
    "atzelgift": "Atzelgift",
    "au-am-rhein": "Au am Rhein",
    "au-hallertau": "Au i.d. Hallertau",
    "au-breisgau": "Au/Breisgau",
    "aub": "Aub",
    "aubstadt": "Aubstadt",
    "auderath": "Auderath",
    "aue": "Aue",
    "aue-bad-schlema": "Aue-Bad Schlema",
    "auel": "Auel",
    "auen": "Auen",
    "auengrund": "Auengrund",
    "auenwald": "Auenwald",
    "auerbach-erzgebirge": "Auerbach/Erzgebirge",
    "auerbach-niederbayern": "Auerbach/Niederbayern",
    "auerbach-oberpfalz": "Auerbach/Oberpfalz",
    "auerbach-vogtland": "Auerbach/Vogtland",
    "auetal": "Auetal",
    "aufhausen": "Aufhausen",
    "aufsess": "Aufseß",
    "auggen": "Auggen",
    "augsburg": "Augsburg",
    "augustdorf": "Augustdorf",
    "augustusburg": "Augustusburg",
    "auhagen": "Auhagen",
    "auhausen": "Auhausen",
    "aukrug": "Aukrug",
    "aulendorf": "Aulendorf",
    "aull": "Aull",
    "auma-weidatal": "Auma-Weidatal",
    "aumuehle": "Aumühle",
    "aura-saale": "Aura a.d. Saale",
    "aura-sinngrund": "Aura i. Sinngrund",
    "aurach": "Aurach",
    "aurachtal": "Aurachtal",
    "aurich": "Aurich",
    "ausacker": "Ausacker",
    "ausleben": "Ausleben",
    "aussernzell": "Außernzell",
    "auufer": "Auufer",
    "auw-an-der-kyll": "Auw an der Kyll",
    "auw-pruem": "Auw b. Prüm",
    "aventoft": "Aventoft",
    "averlak": "Averlak",
    "axstedt": "Axstedt",
    "aying": "Aying",
    "ayl": "Ayl",
    "aystetten": "Aystetten",
    "baabe": "Baabe",
    "baar": "Baar",
    "baar-schwaben": "Baar/Schwaben",
    "baar-ebenhausen": "Baar-Ebenhausen",
    "babenhausen-hessen": "Babenhausen/Hessen",
    "babenhausen-schwaben": "Babenhausen/Schwaben",
    "babensham": "Babensham",
    "bach-donau": "Bach a.d. Donau",
    "bacharach": "Bacharach",
    "bachenberg": "Bachenberg",
    "bachfeld": "Bachfeld",
    "bachhagel": "Bachhagel",
    "baechingen-brenz": "Bächingen a.d. Brenz",
    "backnang": "Backnang",
    "bad-abbach": "Bad Abbach",
    "bad-aibling": "Bad Aibling",
    "bad-alexandersbad": "Bad Alexandersbad",
    "bad-arolsen": "Bad Arolsen",
    "bad-bellingen": "Bad Bellingen",
    "belzig": "Bad Belzig",
    "bad-bentheim": "Bad Bentheim",
    "bad-bergzabern": "Bad Bergzabern",
    "bad-berka": "Bad Berka",
    "bad-berleburg": "Bad Berleburg",
    "bad-berneck-fichtelgebirge": "Bad Berneck i. Fichtelgebirge",
    "bad-bertrich": "Bad Bertrich",
    "bad-bevensen": "Bad Bevensen",
    "bad-bibra": "Bad Bibra",
    "bad-birnbach": "Bad Birnbach",
    "bad-blankenburg": "Bad Blankenburg",
    "bad-bocklet": "Bad Bocklet",
    "bad-bodenteich-flecken": "Bad Bodenteich Flecken",
    "bad-boll": "Bad Boll",
    "bad-brambach": "Bad Brambach",
    "bad-bramstedt": "Bad Bramstedt",
    "bad-breisig": "Bad Breisig",
    "bad-brueckenau": "Bad Brückenau",
    "bad-buchau": "Bad Buchau",
    "bad-camberg": "Bad Camberg",
    "bad-colberg-heldburg": "Bad Colberg-Heldburg",
    "bad-ditzenbach": "Bad Ditzenbach",
    "bad-doberan": "Bad Doberan",
    "bad-driburg": "Bad Driburg",
    "bad-dueben": "Bad Düben",
    "bad-duerkheim": "Bad Dürkheim",
    "bad-duerrenberg": "Bad Dürrenberg",
    "bad-duerrheim": "Bad Dürrheim",
    "bad-eilsen": "Bad Eilsen",
    "bad-elster": "Bad Elster",
    "bad-ems": "Bad Ems",
    "bad-emstal": "Bad Emstal",
    "bad-endbach": "Bad Endbach",
    "bad-endorf": "Bad Endorf",
    "bad-essen": "Bad Essen",
    "bad-fallingbostel": "Bad Fallingbostel",
    "bad-feilnbach": "Bad Feilnbach",
    "bad-frankenhausen": "Bad Frankenhausen",
    "bad-freienwalde-oder": "Bad Freienwalde/Oder",
    "bad-friedrichshall": "Bad Friedrichshall",
    "bad-fuessing": "Bad Füssing",
    "bad-gandersheim": "Bad Gandersheim",
    "bad-gottleuba-berggiesshuebel": "Bad Gottleuba-Berggießhübel",
    "bad-griesbach-rottal": "Bad Griesbach i.Rottal",
    "bad-groenenbach-markt": "Bad Grönenbach Markt",
    "bad-grund": "Bad Grund",
    "bad-harzburg": "Bad Harzburg",
    "bad-heilbrunn": "Bad Heilbrunn",
    "bad-herrenalb": "Bad Herrenalb",
    "bad-hersfeld": "Bad Hersfeld",
    "bad-hindelang-markt": "Bad Hindelang Markt",
    "bad-homburg": "Bad Homburg v.d. Höhe",
    "bad-honnef": "Bad Honnef",
    "bad-hoenningen": "Bad Hönningen",
    "bad-iburg": "Bad Iburg",
    "bad-karlshafen": "Bad Karlshafen",
    "bad-kissingen": "Bad Kissingen",
    "bad-kleinen": "Bad Kleinen",
    "bad-klosterlausnitz": "Bad Klosterlausnitz",
    "bad-kohlgrub": "Bad Kohlgrub",
    "bad-koenig": "Bad König",
    "bad-koenigshofen-grabfeld": "Bad Königshofen i. Grabfeld",
    "bad-koestritz": "Bad Köstritz",
    "bad-koetzting": "Bad Kötzting",
    "bad-kreuznach": "Bad Kreuznach",
    "bad-krozingen": "Bad Krozingen",
    "bad-laasphe": "Bad Laasphe",
    "bad-laer": "Bad Laer",
    "bad-langensalza": "Bad Langensalza",
    "bad-lauchstaedt": "Bad Lauchstädt",
    "bad-lausick": "Bad Lausick",
    "bad-lauterberg-im-harz": "Bad Lauterberg im Harz",
    "bad-liebenstein": "Bad Liebenstein",
    "bad-liebenwerda": "Bad Liebenwerda",
    "bad-liebenzell": "Bad Liebenzell",
    "bad-lippspringe": "Bad Lippspringe",
    "bad-lobenstein": "Bad Lobenstein",
    "bad-marienberg-westerwald": "Bad Marienberg/Westerwald",
    "bad-mergentheim": "Bad Mergentheim",
    "bad-muender-am-deister": "Bad Münder am Deister",
    "bad-muenstereifel": "Bad Münstereifel",
    "bad-muskau": "Bad Muskau",
    "bad-nauheim": "Bad Nauheim",
    "bad-nenndorf": "Bad Nenndorf",
    "bad-neuenahr-ahrweiler": "Bad Neuenahr-Ahrweiler",
    "bad-neustadt-saale": "Bad Neustadt a.d. Saale",
    "bad-oeynhausen": "Bad Oeynhausen",
    "bad-oldesloe": "Bad Oldesloe",
    "bad-orb": "Bad Orb",
    "bad-peterstal-griesbach": "Bad Peterstal-Griesbach",
    "bad-pyrmont": "Bad Pyrmont",
    "bad-rappenau": "Bad Rappenau",
    "bad-reichenhall": "Bad Reichenhall",
    "bad-rippoldsau-schapbach": "Bad Rippoldsau-Schapbach",
    "bad-rodach": "Bad Rodach",
    "bad-rothenfelde": "Bad Rothenfelde",
    "bad-saarow": "Bad Saarow",
    "bad-sachsa": "Bad Sachsa",
    "bad-saeckingen": "Bad Säckingen",
    "bad-salzdetfurth": "Bad Salzdetfurth",
    "bad-salzschlirf": "Bad Salzschlirf",
    "bad-salzuflen": "Bad Salzuflen",
    "bad-salzungen": "Bad Salzungen",
    "bad-sassendorf": "Bad Sassendorf",
    "bad-saulgau": "Bad Saulgau",
    "bad-schandau": "Bad Schandau",
    "bad-schlema": "Bad Schlema",
    "bad-schmiedeberg": "Bad Schmiedeberg",
    "bad-schoenborn": "Bad Schönborn",
    "bad-schussenried": "Bad Schussenried",
    "bad-schwalbach": "Bad Schwalbach",
    "bad-schwartau": "Bad Schwartau",
    "bad-segeberg": "Bad Segeberg",
    "bad-sobernheim": "Bad Sobernheim",
    "bad-soden-am-taunus": "Bad Soden am Taunus",
    "bad-soden-salmuenster": "Bad Soden-Salmünster",
    "bad-sooden-allendorf": "Bad Sooden-Allendorf",
    "bad-staffelstein": "Bad Staffelstein",
    "bad-steben": "Bad Steben",
    "bad-sulza": "Bad Sulza",
    "bad-suelze": "Bad Sülze",
    "bad-tabarz": "Bad Tabarz",
    "bad-teinach-zavelstein": "Bad Teinach-Zavelstein",
    "bad-tennstedt": "Bad Tennstedt",
    "bad-toelz": "Bad Tölz",
    "bad-ueberkingen": "Bad Überkingen",
    "bad-urach": "Bad Urach",
    "bad-vilbel": "Bad Vilbel",
    "bad-waldsee": "Bad Waldsee",
    "bad-wiessee": "Bad Wiessee",
    "bad-wildbad-im-schwarzwald": "Bad Wildbad im Schwarzwald",
    "bad-wildungen": "Bad Wildungen",
    "bad-wilsnack": "Bad Wilsnack",
    "bad-wimpfen": "Bad Wimpfen",
    "bad-windsheim": "Bad Windsheim",
    "bad-woerishofen": "Bad Wörishofen",
    "bad-wuennenberg": "Bad Wünnenberg",
    "bad-wurzach": "Bad Wurzach",
    "bad-zwesten": "Bad Zwesten",
    "bad-zwischenahn": "Bad Zwischenahn",
    "badbergen": "Badbergen",
    "baddeckenstedt": "Baddeckenstedt",
    "badem": "Badem",
    "baden-baden": "Baden-Baden",
    "badendorf": "Badendorf",
    "badenhard": "Badenhard",
    "badenheim": "Badenheim",
    "badenweiler": "Badenweiler",
    "baesweiler": "Baesweiler",
    "bahlingen": "Bahlingen",
    "bahrdorf": "Bahrdorf",
    "bahrenborstel": "Bahrenborstel",
    "bahrenfleth": "Bahrenfleth",
    "bahrenhof": "Bahrenhof",
    "bahretal": "Bahretal",
    "baienfurt": "Baienfurt",
    "baierbach": "Baierbach",
    "baierbrunn": "Baierbrunn",
    "baiern": "Baiern",
    "baiersbronn": "Baiersbronn",
    "baiersdorf": "Baiersdorf",
    "baindt": "Baindt",
    "baisweil": "Baisweil",
    "baek-ratzeburg": "Bäk b. Ratzeburg",
    "bakum": "Bakum",
    "baelau": "Bälau",
    "balderschwang": "Balderschwang",
    "baldringen": "Baldringen",
    "balduinstein": "Balduinstein",
    "balesfeld": "Balesfeld",
    "balge": "Balge",
    "balgheim": "Balgheim",
    "balgstaedt": "Balgstädt",
    "balingen": "Balingen",
    "balje": "Balje",
    "ballendorf": "Ballendorf",
    "ballenstedt": "Ballenstedt",
    "ballermann": "Ballermann",
    "ballhausen": "Ballhausen",
    "ballrechten-dottingen": "Ballrechten-Dottingen",
    "ballstaedt": "Ballstädt",
    "ballstedt": "Ballstedt",
    "balow": "Balow",
    "baltmannsweiler": "Baltmannsweiler",
    "baltrum": "Baltrum",
    "balve": "Balve",
    "balzhausen": "Balzhausen",
    "balzheim": "Balzheim",
    "bamberg": "Bamberg",
    "bammental": "Bammental",
    "bandelin": "Bandelin",
    "bandenitz": "Bandenitz",
    "bann": "Bann",
    "bannberscheid": "Bannberscheid",
    "bannewitz": "Bannewitz",
    "banteln": "Banteln",
    "banzkow": "Banzkow",
    "barbelroth": "Barbelroth",
    "barbing": "Barbing",
    "barby-elbe": "Barby",
    "barchfeld-immelborn": "Barchfeld-Immelborn",
    "bardowick": "Bardowick",
    "baerenbach-idar-oberstein": "Bärenbach b. Idar-Oberstein",
    "baerenbach-simmern": "Bärenbach b. Simmern",
    "barenburg": "Barenburg",
    "barendorf": "Barendorf",
    "baerenstein-annaberg-buchholz": "Bärenstein b. Annaberg-Buchholz",
    "baerenthal": "Bärenthal",
    "bargenstedt": "Bargenstedt",
    "bargfeld-stegen": "Bargfeld-Stegen",
    "bargischow": "Bargischow",
    "bargstall": "Bargstall",
    "bargstedt-stade": "Bargstedt Kr. Stade",
    "bargstedt-holstein": "Bargstedt/Holstein",
    "bargteheide": "Bargteheide",
    "bargum": "Bargum",
    "bark": "Bark",
    "barkelsby": "Barkelsby",
    "barkenholm": "Barkenholm",
    "barkhagen": "Barkhagen",
    "barleben": "Barleben",
    "barlt": "Barlt",
    "barmissen": "Barmissen",
    "barmstedt": "Barmstedt",
    "baernau": "Bärnau",
    "barnekow": "Barnekow",
    "barnin": "Barnin",
    "barnitz": "Barnitz",
    "barnstaedt": "Barnstädt",
    "barnstedt": "Barnstedt",
    "barnstorf": "Barnstorf",
    "barntrup": "Barntrup",
    "barsbek": "Barsbek",
    "barsbuettel": "Barsbüttel",
    "barsinghausen": "Barsinghausen",
    "barssel": "Barßel",
    "bartenshagen-parkentin": "Bartenshagen-Parkentin",
    "barth": "Barth",
    "bartholomae": "Bartholomä",
    "bartow": "Bartow",
    "barum-bad-bevensen": "Barum b. Bad Bevensen",
    "barum-lueneburg": "Barum Kr. Lüneburg",
    "baruth-mark": "Baruth/Mark",
    "barver": "Barver",
    "barwedel": "Barwedel",
    "barweiler": "Barweiler",
    "baerweiler": "Bärweiler",
    "basberg": "Basberg",
    "basdahl": "Basdahl",
    "basedow-teterow": "Basedow b. Teterow",
    "basedow-herzogtum-lauenburg": "Basedow/Herzogtum Lauenburg",
    "bassenheim": "Bassenheim",
    "bassum": "Bassum",
    "bastheim": "Bastheim",
    "basthorst": "Basthorst",
    "bastorf": "Bastorf",
    "battenberg-eder": "Battenberg/Eder",
    "battenberg-pfalz": "Battenberg/Pfalz",
    "battweiler": "Battweiler",
    "baudenbach": "Baudenbach",
    "bauler-barweiler": "Bauler b. Barweiler",
    "bauler-neuerburg": "Bauler b. Neuerburg",
    "baumgarten-buetzow": "Baumgarten b. Bützow",
    "baumholder": "Baumholder",
    "baunach": "Baunach",
    "baunatal": "Baunatal",
    "bausendorf": "Bausendorf",
    "baustert": "Baustert",
    "bautzen": "Bautzen",
    "bawinkel": "Bawinkel",
    "bayerbach-ergoldsbach": "Bayerbach b. Ergoldsbach",
    "bayerbach-rott": "Bayerbach/Rott",
    "bayerfeld-steckweiler": "Bayerfeld-Steckweiler",
    "bayerisch-eisenstein": "Bayerisch Eisenstein",
    "bayerisch-gmain": "Bayerisch Gmain",
    "bayersoien": "Bayersoien",
    "bayreuth": "Bayreuth",
    "bayrischzell": "Bayrischzell",
    "bebensee": "Bebensee",
    "bebra": "Bebra",
    "becheln": "Becheln",
    "bechenheim": "Bechenheim",
    "becherbach-kirn": "Becherbach b. Kirn",
    "becherbach-pfalz": "Becherbach/Pfalz",
    "bechhofen-heide": "Bechhofen a.d. Heide",
    "bechhofen-pfalz": "Bechhofen/Pfalz",
    "bechstedt": "Bechstedt",
    "bechstedtstrass": "Bechstedtstraß",
    "bechtheim": "Bechtheim",
    "bechtolsheim": "Bechtolsheim",
    "bechtsrieth": "Bechtsrieth",
    "beckdorf": "Beckdorf",
    "beckedorf": "Beckedorf",
    "beckeln": "Beckeln",
    "beckingen": "Beckingen",
    "beckum": "Beckum",
    "bedburg": "Bedburg",
    "bedburg-hau": "Bedburg-Hau",
    "bedesbach": "Bedesbach",
    "beedenbostel": "Beedenbostel",
    "beelen": "Beelen",
    "beelitz-mark": "Beelitz/Mark",
    "beendorf": "Beendorf",
    "beerfelden": "Beerfelden",
    "beeskow": "Beeskow",
    "beesten": "Beesten",
    "beetzendorf": "Beetzendorf",
    "beetzsee": "Beetzsee",
    "beetzseeheide": "Beetzseeheide",
    "beggerow": "Beggerow",
    "behlendorf": "Behlendorf",
    "behrendorf-husum": "Behrendorf b. Husum",
    "behrenhoff": "Behrenhoff",
    "behren-luebchin": "Behren-Lübchin",
    "behrensdorf-ostsee": "Behrensdorf/Ostsee",
    "beichlingen": "Beichlingen",
    "beidenfleth": "Beidenfleth",
    "beiersdorf-loebau": "Beiersdorf b. Löbau",
    "beiersdorf-freudenberg": "Beiersdorf-Freudenberg",
    "beierstedt": "Beierstedt",
    "beilingen": "Beilingen",
    "beilngries": "Beilngries",
    "beilrode": "Beilrode",
    "beilstein-mosel": "Beilstein/Mosel",
    "beilstein-wuerttemberg": "Beilstein/Württemberg",
    "beimerstetten": "Beimerstetten",
    "beindersheim": "Beindersheim",
    "beinerstadt": "Beinerstadt",
    "beinhausen": "Beinhausen",
    "bekdorf": "Bekdorf",
    "bekmuende": "Bekmünde",
    "bekond": "Bekond",
    "belau": "Belau",
    "beldorf": "Beldorf",
    "belg": "Belg",
    "belgern-schildau": "Belgern-Schildau",
    "belgershain": "Belgershain",
    "belgweiler": "Belgweiler",
    "bell": "Bell",
    "bell-hunsrueck": "Bell/Hunsrück",
    "bellenberg": "Bellenberg",
    "bellheim": "Bellheim",
    "bellingen-westerwald": "Bellingen/Westerwald",
    "bellstedt": "Bellstedt",
    "belm": "Belm",
    "belrieth": "Belrieth",
    "belsch": "Belsch",
    "beltheim": "Beltheim",
    "belum": "Belum",
    "bempflingen": "Bempflingen",
    "bendestorf": "Bendestorf",
    "bendfeld": "Bendfeld",
    "bendorf-holstein": "Bendorf/Holstein",
    "bendorf-rhein": "Bendorf/Rhein",
    "benediktbeuern": "Benediktbeuern",
    "bengel": "Bengel",
    "bengerstorf": "Bengerstorf",
    "benitz": "Benitz",
    "benndorf-sachsen-anhalt": "Benndorf/Sachsen-Anhalt",
    "bennewitz": "Bennewitz",
    "bennhausen": "Bennhausen",
    "benningen-memmingen": "Benningen b. Memmingen",
    "benningen-neckar": "Benningen/Neckar",
    "bensdorf": "Bensdorf",
    "benshausen": "Benshausen",
    "bensheim": "Bensheim",
    "bentwisch-rostock": "Bentwisch b. Rostock",
    "bentzin": "Bentzin",
    "benz-wismar": "Benz b. Wismar",
    "benz-usedom": "Benz/Usedom",
    "benzweiler": "Benzweiler",
    "beratzhausen": "Beratzhausen",
    "berching": "Berching",
    "berchtesgaden": "Berchtesgaden",
    "bereborn": "Bereborn",
    "berenbach": "Berenbach",
    "berg-neumarkt-oberpfalz": "Berg b. Neumarkt i.d. Oberpfalz",
    "berg-im-gau": "Berg im Gau",
    "berg-ahrweiler": "Berg Kr. Ahrweiler",
    "berg-ravensburg": "Berg Kr. Ravensburg",
    "berg-starnberg": "Berg Kr. Starnberg",
    "berg-oberfranken": "Berg/Obfr.",
    "berg-pfalz": "Berg/Pfalz",
    "berg-taunus": "Berg/Taunus",
    "berga-rossla": "Berga b. Roßla",
    "berga-elster": "Berga/Elster",
    "bergatreute": "Bergatreute",
    "berge-perleberg": "Berge b. Perleberg",
    "berge-quakenbrueck": "Berge b. Quakenbrück",
    "bergen-an-der-dumme": "Bergen an der Dumme",
    "bergen-auf-ruegen": "Bergen auf Rügen",
    "bergen-auerbach": "Bergen b. Auerbach",
    "bergen-kirn-nahe": "Bergen b. Kirn/Nahe",
    "bergen-celle": "Bergen Kr. Celle",
    "bergen-chiemgau": "Bergen/Chiemgau",
    "bergen-mittelfranken": "Bergen/Mittelfranken",
    "bergenhausen": "Bergenhausen",
    "bergenhusen": "Bergenhusen",
    "bergewoehrden": "Bergewöhrden",
    "bergfeld": "Bergfeld",
    "berghaupten": "Berghaupten",
    "berghausen": "Berghausen",
    "bergheim-donau": "Bergheim/Donau",
    "bergheim-erft": "Bergheim/Erft",
    "bergholz-pasewalk": "Bergholz b. Pasewalk",
    "berghuelen": "Berghülen",
    "bergisch-gladbach": "Bergisch Gladbach",
    "bergkamen": "Bergkamen",
    "bergkirchen": "Bergkirchen",
    "berglangenbach": "Berglangenbach",
    "berglen": "Berglen",
    "berglern": "Berglern",
    "berglicht": "Berglicht",
    "bergneustadt": "Bergneustadt",
    "bergrheinfeld": "Bergrheinfeld",
    "bergtheim": "Bergtheim",
    "bergweiler": "Bergweiler",
    "beringstedt": "Beringstedt",
    "berka-vor-dem-hainich": "Berka v.d. Hainich",
    "berka-werra": "Berka/Werra",
    "berkatal": "Berkatal",
    "berkenbrueck": "Berkenbrück",
    "berkenthin": "Berkenthin",
    "berkheim": "Berkheim",
    "berkholz-meyenburg": "Berkholz-Meyenburg",
    "berkoth": "Berkoth",
    "berlin": "Berlin",
    "berlingen": "Berlingen",
    "berlingerode": "Berlingerode",
    "berlstedt": "Berlstedt",
    "bermatingen": "Bermatingen",
    "bermbach": "Bermbach",
    "bermel": "Bermel",
    "bermersheim-worms": "Bermersheim b. Worms",
    "bermersheim-vor-der-hoehe": "Bermersheim v.d. Höhe",
    "bernau-chiemsee": "Bernau a. Chiemsee",
    "bernau-bei-berlin": "Bernau bei Berlin",
    "bernau-im-schwarzwald": "Bernau im Schwarzwald",
    "bernbeuren": "Bernbeuren",
    "bernburg-saale": "Bernburg (Saale)",
    "berndorf": "Berndorf",
    "berndroth": "Berndroth",
    "berne": "Berne",
    "berngau": "Berngau",
    "bernhardswald": "Bernhardswald",
    "bernitt": "Bernitt",
    "bernkastel-kues": "Bernkastel-Kues",
    "bernried-niederbayern": "Bernried/Niederbayern",
    "bernried-starnberger-see": "Bernried/Starnberger See",
    "bernsdorf-oberlausitz": "Bernsdorf (Oberlausitz)",
    "bernsdorf-hohenstein-ernstthal": "Bernsdorf b. Hohenstein-Ernstthal",
    "bernstadt-wuertt": "Bernstadt (Württ.)",
    "bernstadt-eigen": "Bernstadt a.d. Eigen",
    "bernstorf": "Bernstorf",
    "bernterode-heiligenstadt": "Bernterode b. Heiligenstadt",
    "berod-hachenburg": "Berod b. Hachenburg",
    "berod-wallmerod": "Berod b. Wallmerod",
    "berscheid": "Berscheid",
    "berschweiler-baumholder": "Berschweiler b. Baumholder",
    "berschweiler-kirn": "Berschweiler b. Kirn",
    "bersenbrueck": "Bersenbrück",
    "bersteland": "Bersteland",
    "bertsdorf-hoernitz": "Bertsdorf-Hörnitz",
    "berumbur": "Berumbur",
    "berzhahn": "Berzhahn",
    "berzhausen": "Berzhausen",
    "bescheid": "Bescheid",
    "beschendorf": "Beschendorf",
    "besdorf": "Besdorf",
    "beselich": "Beselich",
    "besenthal": "Besenthal",
    "beseritz": "Beseritz",
    "besigheim": "Besigheim",
    "besitz": "Besitz",
    "bessenbach": "Bessenbach",
    "bestensee": "Bestensee",
    "bestwig": "Bestwig",
    "betheln": "Betheln",
    "bethenhausen": "Bethenhausen",
    "betteldorf": "Betteldorf",
    "bettendorf": "Bettendorf",
    "bettenfeld": "Bettenfeld",
    "bettingen": "Bettingen",
    "betzdorf": "Betzdorf",
    "betzendorf": "Betzendorf",
    "betzenstein": "Betzenstein",
    "betzenweiler": "Betzenweiler",
    "betzigau": "Betzigau",
    "beulich": "Beulich",
    "beuren-nuertingen": "Beuren b. Nürtingen",
    "beuren-eifel": "Beuren/Eifel",
    "beuren-hochwald": "Beuren/Hochwald",
    "beuron": "Beuron",
    "beutelsbach": "Beutelsbach",
    "bevern-holzminden": "Bevern Kr. Holzminden",
    "bevern-holstein": "Bevern/Holstein",
    "beverstedt": "Beverstedt",
    "beverungen": "Beverungen",
    "bexbach": "Bexbach",
    "biberach-an-der-riss": "Biberach an der Riß",
    "biberach-baden": "Biberach/Baden",
    "biberbach": "Biberbach",
    "bibertal": "Bibertal",
    "biblis": "Biblis",
    "bibow": "Bibow",
    "bibra-jena": "Bibra b. Jena",
    "biburg": "Biburg",
    "bichl": "Bichl",
    "bickenbach-bergstrasse": "Bickenbach/Bergstraße",
    "bickenbach-hunsrueck": "Bickenbach/Hunsrück",
    "bickendorf": "Bickendorf",
    "bidingen": "Bidingen",
    "biebelnheim": "Biebelnheim",
    "biebelried": "Biebelried",
    "biebelsheim": "Biebelsheim",
    "bieberehren": "Bieberehren",
    "biebergemuend": "Biebergemünd",
    "biebern": "Biebern",
    "biebertal": "Biebertal",
    "biebesheim-am-rhein": "Biebesheim am Rhein",
    "biebrich": "Biebrich",
    "biedenkopf": "Biedenkopf",
    "biederbach": "Biederbach",
    "biederitz": "Biederitz",
    "biedershausen": "Biedershausen",
    "biedesheim": "Biedesheim",
    "bielefeld": "Bielefeld",
    "biendorf-bad-doberan": "Biendorf b. Bad Doberan",
    "bienenbuettel": "Bienenbüttel",
    "bienstaedt": "Bienstädt",
    "biersdorf-am-see": "Biersdorf am See",
    "biesdorf": "Biesdorf",
    "biesenthal": "Biesenthal",
    "biessenhofen": "Biessenhofen",
    "bietigheim-rastatt": "Bietigheim Kr. Rastatt",
    "bietigheim-bissingen": "Bietigheim-Bissingen",
    "bilkheim": "Bilkheim",
    "billerbeck": "Billerbeck",
    "billigheim": "Billigheim",
    "billigheim-ingenheim": "Billigheim-Ingenheim",
    "bilsen": "Bilsen",
    "bilshausen": "Bilshausen",
    "bilzingsleben": "Bilzingsleben",
    "bimoehlen": "Bimöhlen",
    "binau": "Binau",
    "bindlach": "Bindlach",
    "bingen-am-rhein": "Bingen am Rhein",
    "bingen-sigmaringen": "Bingen b. Sigmaringen",
    "binnen": "Binnen",
    "binningen": "Binningen",
    "binsfeld": "Binsfeld",
    "binswangen": "Binswangen",
    "binz": "Binz",
    "binzen": "Binzen",
    "bippen": "Bippen",
    "birenbach": "Birenbach",
    "birgel": "Birgel",
    "birgland": "Birgland",
    "birkenau": "Birkenau",
    "birkenbeul": "Birkenbeul",
    "birkenfeld-marktheidenfeld": "Birkenfeld b. Marktheidenfeld",
    "birkenfeld-nahe": "Birkenfeld/Nahe",
    "birkenfeld-wuerttemberg": "Birkenfeld/Württemberg",
    "birkenfelde": "Birkenfelde",
    "birkenheide": "Birkenheide",
    "birken-honigsessen": "Birken-Honigsessen",
    "birkenhoerdt": "Birkenhördt",
    "birkenhuegel": "Birkenhügel",
    "birkenwerder": "Birkenwerder",
    "birkheim": "Birkheim",
    "birkweiler": "Birkweiler",
    "birlenbach": "Birlenbach",
    "birnbach": "Birnbach",
    "birresborn": "Birresborn",
    "birstein": "Birstein",
    "birtlingen": "Birtlingen",
    "birx": "Birx",
    "bischberg": "Bischberg",
    "bischbrunn": "Bischbrunn",
    "bischheim": "Bischheim",
    "bischoffen": "Bischoffen",
    "bischofrod": "Bischofrod",
    "bischofroda": "Bischofroda",
    "bischofsgruen": "Bischofsgrün",
    "bischofsheim-rhoen": "Bischofsheim a.d. Rhön",
    "bischofsheim-ruesselsheim": "Bischofsheim b. Rüsselsheim",
    "bischofsmais": "Bischofsmais",
    "bischofswerda": "Bischofswerda",
    "bischofswiesen": "Bischofswiesen",
    "bischweier": "Bischweier",
    "bisingen": "Bisingen",
    "bismark-altmark": "Bismark (Altmark)",
    "bispingen": "Bispingen",
    "bissee": "Bissee",
    "bissendorf": "Bissendorf",
    "bissersheim": "Bissersheim",
    "bissingen-an-der-teck": "Bissingen an der Teck",
    "bissingen-schwab": "Bissingen/Schwab",
    "bisterschied": "Bisterschied",
    "bitburg": "Bitburg",
    "bitterfeld-wolfen": "Bitterfeld-Wolfen",
    "bitz": "Bitz",
    "bitzen": "Bitzen",
    "blaibach": "Blaibach",
    "blaichach": "Blaichach",
    "blankenbach": "Blankenbach",
    "blankenberg-mecklenburg": "Blankenberg/Mecklenburg",
    "blankenberg-thueringen": "Blankenberg/Thüringen",
    "blankenburg-harz": "Blankenburg (Harz)",
    "blankenburg-thueringen": "Blankenburg/Thüringen",
    "blankenfelde-mahlow": "Blankenfelde-Mahlow",
    "blankenhagen": "Blankenhagen",
    "blankenhain": "Blankenhain",
    "blankenheim-sangerhausen": "Blankenheim b. Sangerhausen",
    "blankenheim-ahr": "Blankenheim/Ahr",
    "blankenhof": "Blankenhof",
    "blankenrath": "Blankenrath",
    "blankensee-neustrelitz": "Blankensee b. Neustrelitz",
    "blankensee-vorpommern": "Blankensee/Vorpommern",
    "blankenstein": "Blankenstein",
    "blaubach": "Blaubach",
    "blaubeuren": "Blaubeuren",
    "blaufelden": "Blaufelden",
    "blaustein": "Blaustein",
    "bleckede": "Bleckede",
    "bleckhausen": "Bleckhausen",
    "bleialf": "Bleialf",
    "bleicherode": "Bleicherode",
    "blekendorf": "Blekendorf",
    "blender": "Blender",
    "blesewitz": "Blesewitz",
    "bleyen-genschmar": "Bleyen-Genschmar",
    "bliedersdorf": "Bliedersdorf",
    "bliesdorf": "Bliesdorf",
    "blieskastel": "Blieskastel",
    "bliestorf": "Bliestorf",
    "blievenstorf": "Blievenstorf",
    "blindheim": "Blindheim",
    "blomberg-lippe": "Blomberg/Lippe",
    "blomberg-ostfriesland": "Blomberg/Ostfriesland",
    "blomesche-wildnis": "Blomesche Wildnis",
    "blowatz": "Blowatz",
    "blumberg-baden": "Blumberg/Baden",
    "blumenholz": "Blumenholz",
    "blumenthal-holstein": "Blumenthal/Holstein",
    "blunk": "Blunk",
    "bobeck": "Bobeck",
    "bobenheim-am-berg": "Bobenheim am Berg",
    "bobenheim-roxheim": "Bobenheim-Roxheim",
    "bobenthal": "Bobenthal",
    "boebing": "Böbing",
    "bobingen": "Bobingen",
    "boebingen-rems": "Böbingen a.d. Rems",
    "boebingen-pfalz": "Böbingen/Pfalz",
    "bobitz": "Bobitz",
    "boeblingen": "Böblingen",
    "boebrach": "Böbrach",
    "bobritzsch-hilbersdorf": "Bobritzsch-Hilbersdorf",
    "bobzin": "Bobzin",
    "boechingen": "Böchingen",
    "bocholt": "Bocholt",
    "bochum": "Bochum",
    "bocka": "Bocka",
    "bockau": "Bockau",
    "bockenau": "Bockenau",
    "bockenem": "Bockenem",
    "bockenheim-weinstrasse": "Bockenheim/Weinstraße",
    "bockhorn-jadebusen": "Bockhorn/Jadebusen",
    "bockhorn-oberbayern": "Bockhorn/Oberbayern",
    "bockhorst": "Bockhorst",
    "boddin-teterow": "Boddin b. Teterow",
    "bodelshausen": "Bodelshausen",
    "bodelwitz": "Bodelwitz",
    "boden": "Boden",
    "bodenbach": "Bodenbach",
    "bodenfelde": "Bodenfelde",
    "bodenheim": "Bodenheim",
    "bodenkirchen": "Bodenkirchen",
    "bodenmais": "Bodenmais",
    "bodenrode-westhausen": "Bodenrode-Westhausen",
    "bodensee-gemeinde": "Bodensee (Gemeinde)",
    "bodenwerder": "Bodenwerder",
    "bodenwoehr": "Bodenwöhr",
    "bodman-ludwigshafen": "Bodman-Ludwigshafen",
    "bodnegg": "Bodnegg",
    "bodolz": "Bodolz",
    "boeel": "Böel",
    "boffzen": "Boffzen",
    "bogel": "Bogel",
    "bogen": "Bogen",
    "boehen": "Böhen",
    "boehlen-leipzig": "Böhlen b. Leipzig",
    "boehlen-thueringen": "Böhlen/Thüringen",
    "boehl-iggelheim": "Böhl-Iggelheim",
    "boehme": "Böhme",
    "boehmenkirch": "Böhmenkirch",
    "boehmfeld": "Böhmfeld",
    "bohmstedt": "Bohmstedt",
    "bohmte": "Bohmte",
    "boehnhusen": "Böhnhusen",
    "boiensdorf": "Boiensdorf",
    "boitze": "Boitze",
    "boitzenburger-land": "Boitzenburger Land",
    "boizenburg-elbe": "Boizenburg/Elbe",
    "bokel-elmshorn": "Bokel b. Elmshorn",
    "bokel-rendsburg": "Bokel b. Rendsburg",
    "bokelrehm": "Bokelrehm",
    "bokensdorf": "Bokensdorf",
    "bokholt-hanredder": "Bokholt-Hanredder",
    "bokhorst": "Bokhorst",
    "boeklund": "Böklund",
    "boksee": "Boksee",
    "bolanden": "Bolanden",
    "boldekow": "Boldekow",
    "bollberg": "Bollberg",
    "boellen": "Böllen",
    "bollenbach": "Bollenbach",
    "boellenborn": "Böllenborn",
    "bollendorf": "Bollendorf",
    "bollewick": "Bollewick",
    "bollingstedt": "Bollingstedt",
    "bollschweil": "Bollschweil",
    "boelsberg": "Bölsberg",
    "bolsterlang": "Bolsterlang",
    "boltenhagen": "Boltenhagen",
    "bomlitz": "Bomlitz",
    "boms": "Boms",
    "bondelum": "Bondelum",
    "bondorf": "Bondorf",
    "boenebuettel": "Bönebüttel",
    "bonefeld": "Bonefeld",
    "boenen": "Bönen",
    "bonerath": "Bonerath",
    "bongard": "Bongard",
    "bonn": "Bonn",
    "bonndorf-im-schwarzwald": "Bonndorf im Schwarzwald",
    "boennigheim": "Bönnigheim",
    "boenningstedt": "Bönningstedt",
    "bonstetten": "Bonstetten",
    "boock-vorpommern": "Boock/Vorpommern",
    "boos-memmingen": "Boos b. Memmingen",
    "boos-eifel": "Boos/Eifel",
    "boos-nahe": "Boos/Nahe",
    "boostedt": "Boostedt",
    "bopfingen": "Bopfingen",
    "boppard": "Boppard",
    "borchen": "Borchen",
    "boerdeaue": "Bördeaue",
    "boerde-hakel": "Börde-Hakel",
    "boerdeland": "Bördeland",
    "bordelum": "Bordelum",
    "bordesholm": "Bordesholm",
    "boren": "Boren",
    "boerfink": "Börfink",
    "borgdorf-seedorf": "Borgdorf-Seedorf",
    "borgentreich": "Borgentreich",
    "boerger": "Börger",
    "boergerende-rethwisch": "Börgerende-Rethwisch",
    "borgholzhausen": "Borgholzhausen",
    "borgstedt": "Borgstedt",
    "borgsum": "Borgsum",
    "borgwedel": "Borgwedel",
    "borken-hessen": "Borken/Hessen",
    "borken-westfalen": "Borken/Westfalen",
    "borkheide": "Borkheide",
    "borkow": "Borkow",
    "borkum": "Borkum",
    "borkwalde": "Borkwalde",
    "borler": "Borler",
    "boerm": "Börm",
    "born-darss": "Born a. Darß",
    "borna-leipzig": "Borna b. Leipzig",
    "borne-stassfurt": "Borne b. Staßfurt",
    "bornhagen": "Bornhagen",
    "bornheim-pfalz": "Bornheim/Pfalz",
    "bornheim-rhein": "Bornheim/Rhein",
    "bornheim-rheinhessen": "Bornheim/Rheinhessen",
    "bornholt": "Bornholt",
    "bornhoeved": "Bornhöved",
    "bornich": "Bornich",
    "boernichen-erzgebirge": "Börnichen/Erzgebirge",
    "boernsen": "Börnsen",
    "bornstedt-lutherstadt": "Bornstedt b. Lutherstadt",
    "borod": "Borod",
    "borrentin": "Borrentin",
    "boerrstadt": "Börrstadt",
    "boersborn": "Börsborn",
    "borsdorf": "Borsdorf",
    "borsfleth": "Borsfleth",
    "boerslingen": "Börslingen",
    "boerssum": "Börßum",
    "borstel-neumuenster": "Borstel b. Neumünster",
    "borstel-sulingen": "Borstel b. Sulingen",
    "borstel-hohenraden": "Borstel-Hohenraden",
    "borstorf": "Borstorf",
    "boertlingen": "Börtlingen",
    "borxleben": "Borxleben",
    "bosau": "Bosau",
    "bosbuell": "Bosbüll",
    "boesdorf-ploen": "Bösdorf b. Plön",
    "boesel": "Bösel",
    "bosenbach": "Bosenbach",
    "boesenbrunn": "Bösenbrunn",
    "boesingen": "Bösingen",
    "boesleben-wuellersleben": "Bösleben-Wüllersleben",
    "boetersen": "Bötersen",
    "bothel": "Bothel",
    "bothenheilingen": "Bothenheilingen",
    "bothkamp": "Bothkamp",
    "bottenbach": "Bottenbach",
    "boettingen": "Böttingen",
    "bottrop": "Bottrop",
    "boetzingen": "Bötzingen",
    "bous": "Bous",
    "bovenau": "Bovenau",
    "bovenden": "Bovenden",
    "boxberg-daun": "Boxberg Kr. Daun",
    "boxberg-baden": "Boxberg/Baden",
    "boxberg-oberlausitz": "Boxberg/O.L.",
    "boexlund": "Böxlund",
    "braak": "Braak",
    "brachbach": "Brachbach",
    "brachtendorf": "Brachtendorf",
    "brachttal": "Brachttal",
    "brackel": "Brackel",
    "brackenheim": "Brackenheim",
    "braderup": "Braderup",
    "brahlstorf": "Brahlstorf",
    "brahmenau": "Brahmenau",
    "brake-unterweser": "Brake/Unterweser",
    "brakel": "Brakel",
    "brammer": "Brammer",
    "bramsche": "Bramsche",
    "bramstedtlund": "Bramstedtlund",
    "brand": "Brand",
    "brande-hoernerkirchen": "Brande-Hörnerkirchen",
    "brandenburg-an-der-havel": "Brandenburg",
    "brand-erbisdorf": "Brand-Erbisdorf",
    "brandis-wurzen": "Brandis b. Wurzen",
    "brandscheid-eifel": "Brandscheid/Eifel",
    "brandscheid-westerwald": "Brandscheid/Westerwald",
    "brannenburg": "Brannenburg",
    "braubach": "Braubach",
    "brauneberg": "Brauneberg",
    "braunfels": "Braunfels",
    "braunichswalde": "Braunichswalde",
    "braunlage": "Braunlage",
    "braeunlingen": "Bräunlingen",
    "braunsbach": "Braunsbach",
    "braunsbedra": "Braunsbedra",
    "braunschweig": "Braunschweig",
    "braunshorn": "Braunshorn",
    "braunweiler": "Braunweiler",
    "brauweiler": "Brauweiler",
    "brebel": "Brebel",
    "brechen": "Brechen",
    "brecht": "Brecht",
    "breckerfeld": "Breckerfeld",
    "breddenberg": "Breddenberg",
    "breddin": "Breddin",
    "breddorf": "Breddorf",
    "bredenbek": "Bredenbek",
    "bredenfelde-malchin": "Bredenfelde b. Malchin",
    "bredstedt": "Bredstedt",
    "breege": "Breege",
    "breese": "Breese",
    "breesen-neubrandenburg": "Breesen b. Neubrandenburg",
    "breest": "Breest",
    "brehme": "Brehme",
    "breidenbach": "Breidenbach",
    "breiholz": "Breiholz",
    "breisach-am-rhein": "Breisach am Rhein",
    "breit": "Breit",
    "breitbrunn-am-chiemsee": "Breitbrunn am Chiemsee",
    "breitbrunn-unterfranken": "Breitbrunn/Unterfranken",
    "breitenau-westerwald": "Breitenau/Westerwald",
    "breitenbach-herzberg": "Breitenbach a. Herzberg",
    "breitenbach-pfalz": "Breitenbach/Pfalz",
    "breitenberg-itzehoe": "Breitenberg b. Itzehoe",
    "breitenberg-niederbayern": "Breitenberg/Niederbayern",
    "breitenbrunn-erzgebirge": "Breitenbrunn/Erzgebirge",
    "breitenbrunn-oberpfalz": "Breitenbrunn/Oberpfalz",
    "breitenbrunn-schwaben": "Breitenbrunn/Schwaben",
    "breitenburg": "Breitenburg",
    "breitenfelde": "Breitenfelde",
    "breitenguessbach": "Breitengüßbach",
    "breitenheim": "Breitenheim",
    "breitenthal-idar-oberstein": "Breitenthal b. Idar-Oberstein",
    "breitenthal-krumbach": "Breitenthal b. Krumbach",
    "breitenworbis": "Breitenworbis",
    "breitingen": "Breitingen",
    "breitnau": "Breitnau",
    "breitscheid-neuwied": "Breitscheid Kr. Neuwied",
    "breitscheid-hessen": "Breitscheid/Hessen",
    "breitscheid-hunsrueck": "Breitscheid/Hunsrück",
    "breitscheidt": "Breitscheidt",
    "breitungen-werra": "Breitungen/Werra",
    "brekendorf": "Brekendorf",
    "breklum": "Breklum",
    "bremberg": "Bremberg",
    "stadt-bremen": "Bremen",
    "bremerhaven": "Bremerhaven",
    "bremervoerde": "Bremervörde",
    "bremm": "Bremm",
    "bremsnitz": "Bremsnitz",
    "brenk": "Brenk",
    "brennberg": "Brennberg",
    "brensbach": "Brensbach",
    "brenz": "Brenz",
    "bresegard-eldena": "Bresegard b. Eldena",
    "bresegard-picher": "Bresegard b. Picher",
    "brest": "Brest",
    "bretleben": "Bretleben",
    "bretnig-hauswalde": "Bretnig-Hauswalde",
    "bretten": "Bretten",
    "bretthausen": "Bretthausen",
    "bretzenheim": "Bretzenheim",
    "bretzfeld": "Bretzfeld",
    "breuberg": "Breuberg",
    "breuna": "Breuna",
    "breunigweiler": "Breunigweiler",
    "brevoerde": "Brevörde",
    "brey": "Brey",
    "breydin": "Breydin",
    "brickeln": "Brickeln",
    "briedel": "Briedel",
    "brieden": "Brieden",
    "briedern": "Briedern",
    "brieselang": "Brieselang",
    "briesen-mark": "Briesen/Mark",
    "briesen-niederlausitz": "Briesen/Niederlausitz",
    "brieskow-finkenheerd": "Brieskow-Finkenheerd",
    "brietlingen": "Brietlingen",
    "brietzig": "Brietzig",
    "brigachtal": "Brigachtal",
    "briggow": "Briggow",
    "brilon": "Brilon",
    "brimingen": "Brimingen",
    "brinjahe": "Brinjahe",
    "brinkum": "Brinkum",
    "britz": "Britz",
    "broebberow": "Bröbberow",
    "brockel": "Brockel",
    "broeckel": "Bröckel",
    "brockscheid": "Brockscheid",
    "brockum": "Brockum",
    "brodenbach": "Brodenbach",
    "brodersby-kappeln": "Brodersby b. Kappeln",
    "brodersby-schleswig": "Brodersby b. Schleswig",
    "brodersby-goltoft": "Brodersby-Goltoft",
    "brodersdorf": "Brodersdorf",
    "broderstorf": "Broderstorf",
    "brohl-bei-mayen": "Brohl",
    "brohl-luetzing": "Brohl-Lützing",
    "brokdorf": "Brokdorf",
    "brokstedt": "Brokstedt",
    "brombachtal": "Brombachtal",
    "brome": "Brome",
    "bromskirchen": "Bromskirchen",
    "bronkow": "Bronkow",
    "broethen": "Bröthen",
    "brotterode-trusetal": "Brotterode-Trusetal",
    "bruch": "Bruch",
    "bruchertseifen": "Bruchertseifen",
    "bruchhausen": "Bruchhausen",
    "bruchhausen-vilsen": "Bruchhausen-Vilsen",
    "bruchkoebel": "Bruchköbel",
    "bruchmuehlbach-miesau": "Bruchmühlbach-Miesau",
    "bruchsal": "Bruchsal",
    "bruchstedt": "Bruchstedt",
    "bruchweiler": "Bruchweiler",
    "bruchweiler-baerenbach": "Bruchweiler-Bärenbach",
    "brueck": "Brück",
    "bruck-oberpfalz": "Bruck i.d. Oberpfalz",
    "bruck-oberbayern": "Bruck/Oberbayern",
    "bruckberg-mittelfranken": "Bruckberg/Mittelfranken",
    "bruckberg-niederbayern": "Bruckberg/Niederbayern",
    "bruecken-birkenfeld": "Brücken/Birkenfeld",
    "bruecken-pfalz": "Brücken/Pfalz",
    "bruecken-hackpfueffel": "Brücken-Hackpfüffel",
    "bruckmuehl": "Bruckmühl",
    "bruecktal": "Brücktal",
    "brueel": "Brüel",
    "bruegge": "Brügge",
    "brueggen-leine": "Brüggen/Leine",
    "brueggen-niederrhein": "Brüggen/Niederrhein",
    "brueheim": "Brüheim",
    "bruehl-baden": "Brühl/Baden",
    "bruehl-rheinland": "Brühl/Rheinland",
    "brunn-neubrandenburg": "Brunn b. Neubrandenburg",
    "brunn-regensburg": "Brunn Kr. Regensburg",
    "bruenn-thueringen": "Brünn/Thüringen",
    "brunnen": "Brunnen",
    "brunnhartshausen": "Brunnhartshausen",
    "brunnthal": "Brunnthal",
    "brunow-parchim": "Brunow b. Parchim",
    "brunsbek": "Brunsbek",
    "brunsbuettel": "Brunsbüttel",
    "brunsmark": "Brunsmark",
    "brunstorf": "Brunstorf",
    "bruenzow": "Brünzow",
    "bruschied": "Bruschied",
    "bruesewitz": "Brüsewitz",
    "bruessow": "Brüssow",
    "bruttig-fankel": "Bruttig-Fankel",
    "bubach": "Bubach",
    "bubenheim-pfalz": "Bubenheim/Pfalz",
    "bubenheim-rheinhessen": "Bubenheim/Rheinhessen",
    "bubenreuth": "Bubenreuth",
    "bubesheim": "Bubesheim",
    "buborn": "Buborn",
    "bubsheim": "Bubsheim",
    "buch-buchrain": "Buch a. Buchrain",
    "buch-erlbach": "Buch a. Erlbach",
    "buch-wald": "Buch a. Wald",
    "buch-illertissen": "Buch b. Illertissen",
    "buch-hunsrueck": "Buch/Hunsrück",
    "buch-taunus": "Buch/Taunus",
    "bucha-jena": "Bucha b. Jena",
    "bucha-poessneck": "Bucha b. Pößneck",
    "buchbach": "Buchbach",
    "buchbrunn": "Buchbrunn",
    "buchdorf": "Buchdorf",
    "buechel-cochem": "Büchel b. Cochem",
    "buechel-soemmerda": "Büchel b. Sömmerda",
    "buechen": "Büchen",
    "buchen-odenwald": "Buchen/Odenwald",
    "buchenbach": "Buchenbach",
    "buechenbach": "Büchenbach",
    "buchenberg": "Buchenberg",
    "buechenbeuren": "Büchenbeuren",
    "buchet": "Buchet",
    "buchfart": "Buchfart",
    "buchheim": "Buchheim",
    "buchhofen": "Buchhofen",
    "buchholz-nordhausen": "Buchholz b. Nordhausen",
    "buchholz-ratzeburg": "Buchholz b. Ratzeburg",
    "buchholz-roebel": "Buchholz b. Röbel",
    "buchholz-stadthagen": "Buchholz b. Stadthagen",
    "buchholz-aller": "Buchholz/Aller",
    "buchholz-dithmarschen": "Buchholz/Dithmarschen",
    "buchholz-forstgutsbezirk": "Buchholz/Forstgutsbezirk",
    "buchholz-nordheide": "Buchholz/Nordheide",
    "buchholz-westerwald": "Buchholz/Westerwald",
    "buchhorst": "Buchhorst",
    "buechlberg": "Büchlberg",
    "buchloe": "Buchloe",
    "buckautal": "Buckautal",
    "bueckeburg": "Bückeburg",
    "buecken": "Bücken",
    "buckenhof": "Buckenhof",
    "buckow-maerkische-schweiz": "Buckow/Märkische Schweiz",
    "bueddenstedt": "Büddenstedt",
    "buedelsdorf": "Büdelsdorf",
    "budenbach": "Budenbach",
    "budenheim": "Budenheim",
    "buedesheim": "Büdesheim",
    "buedingen": "Büdingen",
    "buedlich": "Büdlich",
    "bufleben": "Bufleben",
    "bugewitz": "Bugewitz",
    "buggenhagen": "Buggenhagen",
    "buggingen": "Buggingen",
    "buehl": "Bühl",
    "buhla": "Buhla",
    "buhlenberg": "Buhlenberg",
    "buehlertal": "Bühlertal",
    "buehlertann": "Bühlertann",
    "buehlerzell": "Bühlerzell",
    "buehnsdorf": "Bühnsdorf",
    "buehren": "Bühren",
    "buelkau": "Bülkau",
    "bullay": "Bullay",
    "bullenkuhlen": "Bullenkuhlen",
    "buelow-schwerin": "Bülow b. Schwerin",
    "buelstedt": "Bülstedt",
    "buelstringen": "Bülstringen",
    "bunde": "Bunde",
    "buende": "Bünde",
    "bundenbach": "Bundenbach",
    "bundenthal": "Bundenthal",
    "bundorf": "Bundorf",
    "buensdorf": "Bünsdorf",
    "bunsoh": "Bunsoh",
    "burbach-eifel": "Burbach/Eifel",
    "burbach-siegerland": "Burbach/Siegerland",
    "buerdenbach": "Bürdenbach",
    "bueren": "Büren",
    "burg-spreewald": "Burg (Spreewald)",
    "burg-magdeburg": "Burg b. Magdeburg",
    "burg-stargard": "Burg Stargard",
    "burg-dithmarschen": "Burg/Dithmarschen",
    "burg-eifel": "Burg/Eifel",
    "burg-mosel": "Burg/Mosel",
    "burgau": "Burgau",
    "burgberg-allgaeu": "Burgberg i. Allgäu",
    "burgbernheim": "Burgbernheim",
    "burgbrohl": "Burgbrohl",
    "burgdorf-hannover": "Burgdorf (Hannover)",
    "burgdorf-salzgitter": "Burgdorf b. Salzgitter",
    "burgebrach": "Burgebrach",
    "buergel": "Bürgel",
    "burgen-bernkastel-kues": "Burgen b. Bernkastel-Kues",
    "burgen-mosel": "Burgen/Mosel",
    "burggen": "Burggen",
    "burghaslach": "Burghaslach",
    "burghaun": "Burghaun",
    "burghausen": "Burghausen",
    "burgheim": "Burgheim",
    "burgk": "Burgk",
    "burgkirchen-alz": "Burgkirchen a.d. Alz",
    "burgkunstadt": "Burgkunstadt",
    "burglahr": "Burglahr",
    "burglauer": "Burglauer",
    "burglengenfeld": "Burglengenfeld",
    "burgoberbach": "Burgoberbach",
    "burgpreppach": "Burgpreppach",
    "burgrieden": "Burgrieden",
    "burgsalach": "Burgsalach",
    "burgschwalbach": "Burgschwalbach",
    "burgsinn": "Burgsinn",
    "burgsponheim": "Burgsponheim",
    "burgstaedt": "Burgstädt",
    "buergstadt": "Bürgstadt",
    "burgstall": "Burgstall",
    "burgstetten": "Burgstetten",
    "burgthann": "Burgthann",
    "burgwald": "Burgwald",
    "burgwalde": "Burgwalde",
    "burgwedel": "Burgwedel",
    "burgwindheim": "Burgwindheim",
    "burk": "Burk",
    "burkardroth": "Burkardroth",
    "burkau": "Burkau",
    "burkhardtsdorf": "Burkhardtsdorf",
    "burladingen": "Burladingen",
    "burow": "Burow",
    "burrweiler": "Burrweiler",
    "burscheid-rheinland": "Burscheid/Rheinland",
    "buerstadt": "Bürstadt",
    "burtenbach": "Burtenbach",
    "burtscheid": "Burtscheid",
    "burweg": "Burweg",
    "buschvitz": "Buschvitz",
    "busdorf": "Busdorf",
    "buseck": "Buseck",
    "busenberg": "Busenberg",
    "busenhausen": "Busenhausen",
    "busenwurth": "Busenwurth",
    "buesingen-am-hochrhein": "Büsingen am Hochrhein",
    "buesum": "Büsum",
    "buesumer-deichhausen": "Büsumer Deichhausen",
    "butjadingen": "Butjadingen",
    "buetow": "Bütow",
    "buettel": "Büttel",
    "buettelborn": "Büttelborn",
    "buttelstedt": "Buttelstedt",
    "buttenheim": "Buttenheim",
    "buttenwiesen": "Buttenwiesen",
    "buetthard": "Bütthard",
    "buttlar": "Buttlar",
    "buttstaedt": "Buttstädt",
    "buettstedt": "Büttstedt",
    "butzbach": "Butzbach",
    "buetzow": "Bützow",
    "butzow-anklam": "Butzow b. Anklam",
    "buxheim-memmingen": "Buxheim b. Memmingen",
    "buxheim-oberbayern": "Buxheim/Oberbayern",
    "byhleguhre-byhlen": "Byhleguhre-Byhlen",
    "caan": "Caan",
    "caaschwitz": "Caaschwitz",
    "cadenberge": "Cadenberge",
    "cadolzburg": "Cadolzburg",
    "calau": "Calau",
    "calbe-saale": "Calbe (Saale)",
    "calberlah": "Calberlah",
    "calden": "Calden",
    "callbach": "Callbach",
    "callenberg": "Callenberg",
    "calvoerde": "Calvörde",
    "calw": "Calw",
    "cambs": "Cambs",
    "cammin-rostock": "Cammin b. Rostock",
    "cappeln-oldenburg": "Cappeln/Oldenburg",
    "carinerland": "Carinerland",
    "carlow": "Carlow",
    "carlsberg": "Carlsberg",
    "carmzow-wallmow": "Carmzow-Wallmow",
    "carpin": "Carpin",
    "casekow": "Casekow",
    "castell": "Castell",
    "castrop-rauxel": "Castrop-Rauxel",
    "cavertitz": "Cavertitz",
    "celle": "Celle",
    "cham": "Cham",
    "chamerau": "Chamerau",
    "charlottenberg": "Charlottenberg",
    "chemnitz": "Chemnitz",
    "chieming": "Chieming",
    "chiemsee": "Chiemsee",
    "chorin": "Chorin",
    "christes": "Christes",
    "christiansholm": "Christiansholm",
    "christinenthal": "Christinenthal",
    "clausen": "Clausen",
    "claussnitz": "Claußnitz",
    "clausthal-zellerfeld": "Clausthal-Zellerfeld",
    "cleebronn": "Cleebronn",
    "clenze": "Clenze",
    "clingen": "Clingen",
    "cloppenburg": "Cloppenburg",
    "coburg": "Coburg",
    "cochem": "Cochem",
    "coesfeld": "Coesfeld",
    "coelbe": "Cölbe",
    "colbitz": "Colbitz",
    "colditz": "Colditz",
    "collenberg": "Collenberg",
    "colmberg": "Colmberg",
    "colnrade": "Colnrade",
    "coelpin": "Cölpin",
    "contwig": "Contwig",
    "coppenbruegge": "Coppenbrügge",
    "coppengrave": "Coppengrave",
    "cornberg": "Cornberg",
    "coswig-dresden": "Coswig b. Dresden",
    "coswig-anhalt": "Coswig/Anhalt",
    "cottbus": "Cottbus",
    "crailsheim": "Crailsheim",
    "cramberg": "Cramberg",
    "cramme": "Cramme",
    "cramonshagen": "Cramonshagen",
    "crawinkel": "Crawinkel",
    "creglingen": "Creglingen",
    "cremlingen": "Cremlingen",
    "creussen": "Creußen",
    "creuzburg": "Creuzburg",
    "crimla": "Crimla",
    "crimmitschau": "Crimmitschau",
    "crinitz": "Crinitz",
    "crinitzberg": "Crinitzberg",
    "crispendorf": "Crispendorf",
    "crivitz": "Crivitz",
    "cronenberg": "Cronenberg",
    "crossen-elster": "Crossen/Elster",
    "crostwitz": "Crostwitz",
    "crottendorf": "Crottendorf",
    "cumlosen": "Cumlosen",
    "cunewalde": "Cunewalde",
    "cursdorf": "Cursdorf",
    "cuxhaven": "Cuxhaven",
    "daaden": "Daaden",
    "daasdorf-berge": "Daasdorf a. Berge",
    "dabel": "Dabel",
    "dabergotz": "Dabergotz",
    "daberkow": "Daberkow",
    "dachau": "Dachau",
    "dachsbach": "Dachsbach",
    "dachsberg-suedschwarzwald": "Dachsberg/Südschwarzwald",
    "dachsenhausen": "Dachsenhausen",
    "dachwig": "Dachwig",
    "dackenheim": "Dackenheim",
    "dackscheid": "Dackscheid",
    "dagebuell": "Dagebüll",
    "daegeling": "Dägeling",
    "dahlem-bitburg": "Dahlem b. Bitburg",
    "dahlem-kall": "Dahlem b. Kall",
    "dahlem-niedersachsen": "Dahlem/Niedersachsen",
    "dahlen-sachsen": "Dahlen/Sachsen",
    "dahlenburg": "Dahlenburg",
    "dahlheim": "Dahlheim",
    "dahlum": "Dahlum",
    "dahme-holstein": "Dahme/Holstein",
    "dahme-mark": "Dahme/Mark",
    "dahmen": "Dahmen",
    "dahmetal": "Dahmetal",
    "dahmker": "Dahmker",
    "dahn": "Dahn",
    "dahnen": "Dahnen",
    "daehre": "Dähre",
    "daisendorf": "Daisendorf",
    "daiting": "Daiting",
    "dalberg-bad-kreuznach": "Dalberg Kr. Bad Kreuznach",
    "dalberg-wendelstorf": "Dalberg-Wendelstorf",
    "daldorf": "Daldorf",
    "daleiden": "Daleiden",
    "dalheim": "Dalheim",
    "dalkendorf": "Dalkendorf",
    "dalldorf": "Dalldorf",
    "dallgow-doeberitz": "Dallgow-Döberitz",
    "dambach": "Dambach",
    "dambeck-perleberg": "Dambeck b. Perleberg",
    "damendorf": "Damendorf",
    "damflos": "Damflos",
    "damlos": "Damlos",
    "dammbach": "Dammbach",
    "damme-duemmer": "Damme/Dümmer",
    "dammfleth": "Dammfleth",
    "damnatz": "Damnatz",
    "damp": "Damp",
    "damscheid": "Damscheid",
    "damsdorf-segeberg": "Damsdorf/Kr. Segeberg",
    "damshagen": "Damshagen",
    "daenischenhagen": "Dänischenhagen",
    "dankerath": "Dankerath",
    "dankmarshausen": "Dankmarshausen",
    "dannau": "Dannau",
    "danndorf": "Danndorf",
    "dannenberg-elbe": "Dannenberg/Elbe",
    "dannenfels": "Dannenfels",
    "dannewerk": "Dannewerk",
    "dannstadt-schauernheim": "Dannstadt-Schauernheim",
    "dargelin": "Dargelin",
    "dargen": "Dargen",
    "dargun": "Dargun",
    "darmstadt-wissenschaftsstadt": "Darmstadt",
    "darscheid": "Darscheid",
    "darstein": "Darstein",
    "dasburg": "Dasburg",
    "dasing": "Dasing",
    "dassel": "Dassel",
    "dassendorf": "Dassendorf",
    "dassow": "Dassow",
    "daetgen": "Dätgen",
    "datteln": "Datteln",
    "dattenberg": "Dattenberg",
    "datzeroth": "Datzeroth",
    "datzetal": "Datzetal",
    "daubach-hunsrueck": "Daubach/Hunsrück",
    "daubach-westerwald": "Daubach/Westerwald",
    "dauchingen": "Dauchingen",
    "daun": "Daun",
    "dausenau": "Dausenau",
    "dautmergen": "Dautmergen",
    "dautphetal": "Dautphetal",
    "dauwelshausen": "Dauwelshausen",
    "daxweiler": "Daxweiler",
    "dechow": "Dechow",
    "deckenpfronn": "Deckenpfronn",
    "dedelstorf": "Dedelstorf",
    "dedenbach": "Dedenbach",
    "deensen": "Deensen",
    "deesbach": "Deesbach",
    "deesen": "Deesen",
    "deggendorf": "Deggendorf",
    "deggenhausertal": "Deggenhausertal",
    "deggingen": "Deggingen",
    "deidesheim": "Deidesheim",
    "deilingen": "Deilingen",
    "deimberg": "Deimberg",
    "deining": "Deining",
    "deiningen": "Deiningen",
    "deinste": "Deinste",
    "deinstedt": "Deinstedt",
    "deisenhausen": "Deisenhausen",
    "deisslingen": "Deißlingen",
    "deizisau": "Deizisau",
    "delbrueck": "Delbrück",
    "delingsdorf": "Delingsdorf",
    "delitzsch": "Delitzsch",
    "dellfeld": "Dellfeld",
    "delligsen": "Delligsen",
    "dellstedt": "Dellstedt",
    "delmenhorst": "Delmenhorst",
    "delve": "Delve",
    "demen": "Demen",
    "demerath": "Demerath",
    "demitz-thumitz": "Demitz-Thumitz",
    "demmin": "Demmin",
    "denkendorf-oberbayern": "Denkendorf/Oberbayern",
    "denkendorf-wuerttemberg": "Denkendorf/Württemberg",
    "denkingen": "Denkingen",
    "denklingen": "Denklingen",
    "denkte": "Denkte",
    "dennheritz": "Dennheritz",
    "dennweiler-frohnbach": "Dennweiler-Frohnbach",
    "densborn": "Densborn",
    "dentlein-forst": "Dentlein a. Forst",
    "denzlingen": "Denzlingen",
    "derental": "Derental",
    "dermbach": "Dermbach",
    "dernau": "Dernau",
    "dernbach-dierdorf": "Dernbach b. Dierdorf",
    "dernbach-montabaur": "Dernbach b. Montabaur",
    "dernbach-pfalz": "Dernbach/Pfalz",
    "dersau": "Dersau",
    "derschen": "Derschen",
    "dersekow": "Dersekow",
    "dersenow": "Dersenow",
    "dersum": "Dersum",
    "desloch": "Desloch",
    "despetal": "Despetal",
    "dessau-rosslau": "Dessau-Roßlau",
    "dessighofen": "Dessighofen",
    "detern": "Detern",
    "detmold": "Detmold",
    "dettelbach": "Dettelbach",
    "dettenhausen": "Dettenhausen",
    "dettenheim": "Dettenheim",
    "dettighofen": "Dettighofen",
    "dettingen-an-der-erms": "Dettingen an der Erms",
    "dettingen-an-der-iller": "Dettingen an der Iller",
    "dettingen-unter-teck": "Dettingen unter Teck",
    "dettmannsdorf": "Dettmannsdorf",
    "dettum": "Dettum",
    "detzem": "Detzem",
    "deudesfeld": "Deudesfeld",
    "deuerling": "Deuerling",
    "deuna": "Deuna",
    "deuselbach": "Deuselbach",
    "deutsch-evern": "Deutsch Evern",
    "deutschneudorf": "Deutschneudorf",
    "dexheim": "Dexheim",
    "deyelsdorf": "Deyelsdorf",
    "dhronecken": "Dhronecken",
    "dichtelbach": "Dichtelbach",
    "dickel": "Dickel",
    "dickendorf": "Dickendorf",
    "dickenschied": "Dickenschied",
    "dickesbach": "Dickesbach",
    "didderse": "Didderse",
    "diebach": "Diebach",
    "dieblich": "Dieblich",
    "dieburg": "Dieburg",
    "diedorf": "Diedorf",
    "diedorf-rhoen": "Diedorf/Rhön",
    "diedrichshagen": "Diedrichshagen",
    "diefenbach": "Diefenbach",
    "diekhof": "Diekhof",
    "diekholzen": "Diekholzen",
    "diekhusen-fahrstedt": "Diekhusen-Fahrstedt",
    "dielheim": "Dielheim",
    "dielkirchen": "Dielkirchen",
    "dielmissen": "Dielmissen",
    "diemelsee": "Diemelsee",
    "diemelstadt": "Diemelstadt",
    "dienethal": "Dienethal",
    "dienheim": "Dienheim",
    "diensdorf-radlow": "Diensdorf-Radlow",
    "dienstweiler": "Dienstweiler",
    "diepenau": "Diepenau",
    "diepholz-gemeinde": "Diepholz (Gemeinde)",
    "diera-zehren": "Diera-Zehren",
    "dierbach": "Dierbach",
    "dierdorf": "Dierdorf",
    "dierfeld": "Dierfeld",
    "dierhagen": "Dierhagen",
    "dierscheid": "Dierscheid",
    "diesdorf-flecken": "Diesdorf, Flecken",
    "diespeck": "Diespeck",
    "diessen-am-ammersee": "Dießen am Ammersee",
    "dietenheim": "Dietenheim",
    "dietenhofen": "Dietenhofen",
    "dieterode": "Dieterode",
    "dietersburg": "Dietersburg",
    "dietersheim": "Dietersheim",
    "dieterskirchen": "Dieterskirchen",
    "dietfurt-altmuehl": "Dietfurt a.d. Altmühl",
    "diethardt": "Diethardt",
    "dietingen": "Dietingen",
    "dietmannsried": "Dietmannsried",
    "dietramszell": "Dietramszell",
    "dietrichingen": "Dietrichingen",
    "dietzenbach": "Dietzenbach",
    "dietzenrode-vatterode": "Dietzenrode/Vatterode",
    "dietzhoelztal": "Dietzhölztal",
    "diez": "Diez",
    "dill": "Dill",
    "dillenburg": "Dillenburg",
    "dillendorf": "Dillendorf",
    "dillingen-donau": "Dillingen a.d. Donau",
    "dillingen-saar": "Dillingen/Saar",
    "dillstaedt": "Dillstädt",
    "dimbach": "Dimbach",
    "dingdorf": "Dingdorf",
    "dingelstaedt": "Dingelstädt",
    "dingen": "Dingen",
    "dingolfing": "Dingolfing",
    "dingolshausen": "Dingolshausen",
    "dingsleben": "Dingsleben",
    "dinkelsbuehl": "Dinkelsbühl",
    "dinkelscherben": "Dinkelscherben",
    "dinklage": "Dinklage",
    "dinslaken": "Dinslaken",
    "dintesheim": "Dintesheim",
    "dippach": "Dippach",
    "dipperz": "Dipperz",
    "dippoldiswalde": "Dippoldiswalde",
    "dirlewang": "Dirlewang",
    "dirmstein": "Dirmstein",
    "dischingen": "Dischingen",
    "dissen-am-teutoburger-wald": "Dissen am Teutoburger Wald",
    "dissen-striesow": "Dissen-Striesow",
    "ditfurt": "Ditfurt",
    "ditscheid": "Ditscheid",
    "dittelbrunn": "Dittelbrunn",
    "dittelsheim-hessloch": "Dittelsheim-Heßloch",
    "dittenheim": "Dittenheim",
    "dittersdorf-schleiz": "Dittersdorf b. Schleiz",
    "dittweiler": "Dittweiler",
    "ditzingen": "Ditzingen",
    "divitz-spoldershagen": "Divitz-Spoldershagen",
    "dobbertin": "Dobbertin",
    "dobbin-linstow": "Dobbin-Linstow",
    "dobel": "Dobel",
    "doebeln": "Döbeln",
    "doberlug-kirchhain": "Doberlug-Kirchhain",
    "doebern": "Döbern",
    "doberschau-gaussig": "Doberschau-Gaußig",
    "doberschuetz": "Doberschütz",
    "dobersdorf": "Dobersdorf",
    "dobin-am-see": "Dobin am See",
    "dobitschen": "Dobitschen",
    "doebritschen": "Döbritschen",
    "doebritz": "Döbritz",
    "dockendorf": "Dockendorf",
    "dockweiler": "Dockweiler",
    "dodenburg": "Dodenburg",
    "dogern": "Dogern",
    "doehlau": "Döhlau",
    "dohma": "Dohma",
    "dohm-lammersdorf": "Dohm-Lammersdorf",
    "dohna": "Dohna",
    "dohr": "Dohr",
    "dohren-emsland": "Dohren/Emsland",
    "dohren-nordheide": "Dohren/Nordheide",
    "dolgen-am-see": "Dolgen am See",
    "dolgesheim": "Dolgesheim",
    "dollern": "Dollern",
    "dollerup": "Dollerup",
    "dollnstein": "Dollnstein",
    "dollrottfeld": "Dollrottfeld",
    "doellstaedt": "Döllstädt",
    "dombuehl": "Dombühl",
    "doemitz": "Dömitz",
    "dommershausen": "Dommershausen",
    "dommitzsch": "Dommitzsch",
    "domsuehl": "Domsühl",
    "donaueschingen": "Donaueschingen",
    "donaustauf": "Donaustauf",
    "donauwoerth": "Donauwörth",
    "donndorf": "Donndorf",
    "donnersdorf": "Donnersdorf",
    "donsieders": "Donsieders",
    "donzdorf": "Donzdorf",
    "doerentrup": "Dörentrup",
    "dorf-mecklenburg": "Dorf Mecklenburg",
    "dorfchemnitz-sayda": "Dorfchemnitz b. Sayda",
    "dorfen": "Dorfen",
    "dorfhain": "Dorfhain",
    "doerfles-esbach": "Dörfles-Esbach",
    "dorfprozelten": "Dorfprozelten",
    "dormagen": "Dormagen",
    "dormettingen": "Dormettingen",
    "dormitz": "Dormitz",
    "doernberg": "Dörnberg",
    "dornburg-westerwald": "Dornburg/Westerwald",
    "camburg": "Dornburg-Camburg",
    "dorn-duerkheim": "Dorn-Dürkheim",
    "dornhan": "Dornhan",
    "dornheim": "Dornheim",
    "dornholzhausen": "Dornholzhausen",
    "doernick": "Dörnick",
    "dornstadt": "Dornstadt",
    "dornstetten": "Dornstetten",
    "dornum": "Dornum",
    "doerpen": "Dörpen",
    "doerphof": "Dörphof",
    "doerpling": "Dörpling",
    "doerpstedt": "Dörpstedt",
    "doerrebach": "Dörrebach",
    "doerrenbach": "Dörrenbach",
    "doerrmoschel": "Dörrmoschel",
    "doerscheid": "Dörscheid",
    "doersdorf": "Dörsdorf",
    "dorsel": "Dorsel",
    "dorsheim": "Dorsheim",
    "dorstadt": "Dorstadt",
    "dorsten": "Dorsten",
    "doerth": "Dörth",
    "dortmund": "Dortmund",
    "doerverden": "Dörverden",
    "doerzbach": "Dörzbach",
    "doeschnitz": "Döschnitz",
    "dossenheim": "Dossenheim",
    "doetlingen": "Dötlingen",
    "dotternhausen": "Dotternhausen",
    "doettesfeld": "Döttesfeld",
    "drachhausen": "Drachhausen",
    "drachselsried": "Drachselsried",
    "drackenstein": "Drackenstein",
    "drage-hohenaspe": "Drage b. Hohenaspe",
    "drage-elbe": "Drage/Elbe",
    "drage-nordfriesland": "Drage/Nordfriesland",
    "dragun": "Dragun",
    "drahnsdorf": "Drahnsdorf",
    "drakenburg": "Drakenburg",
    "dransfeld": "Dransfeld",
    "dranske": "Dranske",
    "dreba": "Dreba",
    "drebach": "Drebach",
    "drebber": "Drebber",
    "drebkau": "Drebkau",
    "drechow": "Drechow",
    "drees": "Drees",
    "dreetz-buetzow": "Dreetz b. Bützow",
    "dreetz-neustadt": "Dreetz b. Neustadt",
    "dreggers": "Dreggers",
    "drehnow": "Drehnow",
    "drei-gleichen": "Drei Gleichen",
    "dreieich": "Dreieich",
    "dreifelden": "Dreifelden",
    "dreiheide": "Dreiheide",
    "dreikirchen": "Dreikirchen",
    "dreis-bernkastel-wittlich": "Dreis Kr. Bernkastel-Wittlich",
    "dreisbach": "Dreisbach",
    "dreis-brueck": "Dreis-Brück",
    "dreisen": "Dreisen",
    "dreitzsch": "Dreitzsch",
    "drelsdorf": "Drelsdorf",
    "drensteinfurt": "Drensteinfurt",
    "drentwede": "Drentwede",
    "dreschvitz": "Dreschvitz",
    "dresden": "Dresden",
    "drestedt": "Drestedt",
    "driedorf": "Driedorf",
    "droebischau": "Dröbischau",
    "drochtersen": "Drochtersen",
    "drogen": "Drogen",
    "drognitz": "Drognitz",
    "drolshagen": "Drolshagen",
    "droyssig": "Droyßig",
    "duechelsdorf": "Düchelsdorf",
    "ducherow": "Ducherow",
    "duchroth": "Duchroth",
    "duckow": "Duckow",
    "dudeldorf": "Dudeldorf",
    "duedenbuettel": "Düdenbüttel",
    "dudenhofen": "Dudenhofen",
    "duderstadt": "Duderstadt",
    "duggendorf": "Duggendorf",
    "duingen": "Duingen",
    "duisburg": "Duisburg",
    "duelmen": "Dülmen",
    "duemmer": "Dümmer",
    "dummerstorf": "Dummerstorf",
    "duempelfeld": "Dümpelfeld",
    "duenfus": "Dünfus",
    "duengenheim": "Düngenheim",
    "dunningen": "Dunningen",
    "duensen": "Dünsen",
    "dunsum": "Dunsum",
    "dunum": "Dunum",
    "duenwald": "Dünwald",
    "dunzweiler": "Dunzweiler",
    "duppach": "Duppach",
    "durach": "Durach",
    "durbach": "Durbach",
    "duerbheim": "Dürbheim",
    "durchhausen": "Durchhausen",
    "dueren": "Düren",
    "durlangen": "Durlangen",
    "duermentingen": "Dürmentingen",
    "durmersheim": "Durmersheim",
    "duernau-bad-buchau": "Dürnau b. Bad Buchau",
    "duernau-goeppingen": "Dürnau Kr. Göppingen",
    "duerrhennersdorf": "Dürrhennersdorf",
    "duerrholz": "Dürrholz",
    "duerrlauingen": "Dürrlauingen",
    "duerrroehrsdorf-dittersbach": "Dürrröhrsdorf-Dittersbach",
    "duerrwangen": "Dürrwangen",
    "duesseldorf": "Düsseldorf",
    "dusslingen": "Dußlingen",
    "duvensee": "Duvensee",
    "ebeleben": "Ebeleben",
    "ebelsbach": "Ebelsbach",
    "ebensfeld": "Ebensfeld",
    "ebenshausen": "Ebenshausen",
    "ebenweiler": "Ebenweiler",
    "eberbach": "Eberbach",
    "eberdingen": "Eberdingen",
    "eberfing": "Eberfing",
    "ebergoetzen": "Ebergötzen",
    "eberhardzell": "Eberhardzell",
    "eberholzen": "Eberholzen",
    "ebermannsdorf": "Ebermannsdorf",
    "ebermannstadt": "Ebermannstadt",
    "ebern": "Ebern",
    "ebernhahn": "Ebernhahn",
    "ebersbach-an-der-fils": "Ebersbach an der Fils",
    "ebersbach-grossenhain": "Ebersbach b. Großenhain",
    "ebersbach-musbach": "Ebersbach-Musbach",
    "ebersbach-neugersdorf": "Ebersbach-Neugersdorf",
    "ebersberg": "Ebersberg",
    "ebersburg": "Ebersburg",
    "ebersdorf-bremervoerde": "Ebersdorf b. Bremervörde",
    "ebersdorf-coburg": "Ebersdorf b. Coburg",
    "ebershausen": "Ebershausen",
    "eberstadt": "Eberstadt",
    "eberstedt": "Eberstedt",
    "eberswalde": "Eberswalde",
    "ebertshausen": "Ebertshausen",
    "ebertsheim": "Ebertsheim",
    "ebhausen": "Ebhausen",
    "ebnath": "Ebnath",
    "ebrach": "Ebrach",
    "ebringen": "Ebringen",
    "ebsdorfergrund": "Ebsdorfergrund",
    "ebstorf": "Ebstorf",
    "echem": "Echem",
    "eching-am-ammersee": "Eching am Ammersee",
    "eching-freising": "Eching Kr. Freising",
    "eching-niederbayern": "Eching/Niederbayern",
    "echternacherbrueck": "Echternacherbrück",
    "echtershausen": "Echtershausen",
    "echzell": "Echzell",
    "eckartsberga": "Eckartsberga",
    "eckelsheim": "Eckelsheim",
    "eckenroth": "Eckenroth",
    "eckental": "Eckental",
    "eckernfoerde": "Eckernförde",
    "eckersdorf": "Eckersdorf",
    "eckersweiler": "Eckersweiler",
    "eckfeld": "Eckfeld",
    "ecklak": "Ecklak",
    "ecklingerode": "Ecklingerode",
    "eckstedt": "Eckstedt",
    "eddelak": "Eddelak",
    "edelsfeld": "Edelsfeld",
    "edemissen": "Edemissen",
    "edenkoben": "Edenkoben",
    "ederheim": "Ederheim",
    "edermuende": "Edermünde",
    "edersleben": "Edersleben",
    "edertal": "Edertal",
    "edesheim": "Edesheim",
    "edewecht": "Edewecht",
    "ediger-eller": "Ediger-Eller",
    "edingen-neckarhausen": "Edingen-Neckarhausen",
    "edling": "Edling",
    "effelder-leinefelde": "Effelder b. Leinefelde",
    "effeltrich": "Effeltrich",
    "efringen-kirchen": "Efringen-Kirchen",
    "egeln": "Egeln",
    "egelsbach": "Egelsbach",
    "egenhausen": "Egenhausen",
    "egenhofen": "Egenhofen",
    "egesheim": "Egesheim",
    "egestorf": "Egestorf",
    "egg-guenz": "Egg a.d. Günz",
    "eggebek": "Eggebek",
    "eggenfelden": "Eggenfelden",
    "eggenstein-leopoldshafen": "Eggenstein-Leopoldshafen",
    "eggenthal": "Eggenthal",
    "eggermuehlen": "Eggermühlen",
    "eggesin": "Eggesin",
    "eggingen": "Eggingen",
    "egglham": "Egglham",
    "egglkofen": "Egglkofen",
    "eggolsheim": "Eggolsheim",
    "eggstaett": "Eggstätt",
    "eggstedt": "Eggstedt",
    "eging-see": "Eging a. See",
    "eglfing": "Eglfing",
    "egling-paar": "Egling a.d. Paar",
    "egling-wolfratshausen": "Egling b. Wolfratshausen",
    "egloffstein": "Egloffstein",
    "egmating": "Egmating",
    "egweil": "Egweil",
    "ehekirchen": "Ehekirchen",
    "ehingen-ries": "Ehingen a. Ries",
    "ehingen-wertingen": "Ehingen b. Wertingen",
    "ehingen-donau": "Ehingen/Donau",
    "ehingen-mittelfranken": "Ehingen/Mittelfranken",
    "ehlenz": "Ehlenz",
    "ehlscheid": "Ehlscheid",
    "ehndorf": "Ehndorf",
    "ehningen": "Ehningen",
    "ehr": "Ehr",
    "ehra-lessien": "Ehra-Lessien",
    "ehrenberg-hildburghausen": "Ehrenberg b. Hildburghausen",
    "ehrenberg-rhoen": "Ehrenberg/Rhön",
    "ehrenburg": "Ehrenburg",
    "ehrenfriedersdorf": "Ehrenfriedersdorf",
    "ehrenkirchen": "Ehrenkirchen",
    "ehringshausen": "Ehringshausen",
    "ehweiler": "Ehweiler",
    "eibelstadt": "Eibelstadt",
    "eibenstock": "Eibenstock",
    "eich-rheinhessen": "Eich/Rheinhessen",
    "eichelhardt": "Eichelhardt",
    "eichen": "Eichen",
    "eichenau": "Eichenau",
    "eichenbach": "Eichenbach",
    "eichenberg-jena": "Eichenberg b. Jena",
    "eichenberg-suhl": "Eichenberg b. Suhl",
    "eichenbuehl": "Eichenbühl",
    "eichendorf": "Eichendorf",
    "eichenzell": "Eichenzell",
    "eichigt": "Eichigt",
    "eichstaett": "Eichstätt",
    "eichstedt-altmark": "Eichstedt (Altmark)",
    "eichstegen": "Eichstegen",
    "eichstetten-am-kaiserstuhl": "Eichstetten am Kaiserstuhl",
    "eichstruth": "Eichstruth",
    "eichwalde": "Eichwalde",
    "eickeloh": "Eickeloh",
    "eicklingen": "Eicklingen",
    "eigeltingen": "Eigeltingen",
    "eilenburg": "Eilenburg",
    "eilscheid": "Eilscheid",
    "eilsleben": "Eilsleben",
    "eime": "Eime",
    "eimeldingen": "Eimeldingen",
    "eimen": "Eimen",
    "eimke": "Eimke",
    "eimsheim": "Eimsheim",
    "einbeck": "Einbeck",
    "eineborn": "Eineborn",
    "einhaus": "Einhaus",
    "einhausen-meiningen": "Einhausen b. Meiningen",
    "einhausen-hessen": "Einhausen/Hessen",
    "einig": "Einig",
    "einoellen": "Einöllen",
    "einselthum": "Einselthum",
    "eiselfing": "Eiselfing",
    "eisenach-thueringen": "Eisenach",
    "eisenach-trier": "Eisenach b. Trier",
    "eisenbach": "Eisenbach",
    "eisenberg-allgaeu": "Eisenberg/Allgäu",
    "eisenberg-pfalz": "Eisenberg/Pfalz",
    "eisenberg-thueringen": "Eisenberg/Thüringen",
    "eisendorf": "Eisendorf",
    "eisenheim": "Eisenheim",
    "eisenhuettenstadt": "Eisenhüttenstadt",
    "eisenschmitt": "Eisenschmitt",
    "eisfeld": "Eisfeld",
    "eisighofen": "Eisighofen",
    "eisingen-wuerzburg": "Eisingen Kr. Würzburg",
    "eisingen-baden": "Eisingen/Baden",
    "eislingen-fils": "Eislingen/Fils",
    "eitelborn": "Eitelborn",
    "eitensheim": "Eitensheim",
    "eiterfeld": "Eiterfeld",
    "eitorf": "Eitorf",
    "eitting": "Eitting",
    "eixen": "Eixen",
    "elbe-salzgitter": "Elbe",
    "elben": "Elben",
    "elbe-parey": "Elbe-Parey",
    "elbingen": "Elbingen",
    "elbingerode-niedersachsen": "Elbingerode",
    "elbtal": "Elbtal",
    "elchesheim-illingen": "Elchesheim-Illingen",
    "elchingen": "Elchingen",
    "elchweiler": "Elchweiler",
    "eldena": "Eldena",
    "eldetal": "Eldetal",
    "eldingen": "Eldingen",
    "elfershausen": "Elfershausen",
    "elgersburg": "Elgersburg",
    "elisabeth-sophien-koog": "Elisabeth-Sophien-Koog",
    "elkenroth": "Elkenroth",
    "elleben": "Elleben",
    "ellefeld": "Ellefeld",
    "ellenberg-birkenfeld": "Ellenberg b. Birkenfeld",
    "ellenberg-wuerttemberg": "Ellenberg/Württemberg",
    "ellenhausen": "Ellenhausen",
    "ellenz-poltersdorf": "Ellenz-Poltersdorf",
    "ellerau": "Ellerau",
    "ellerbek": "Ellerbek",
    "ellerdorf": "Ellerdorf",
    "ellerhoop": "Ellerhoop",
    "ellern-hunsrueck": "Ellern/Hunsrück",
    "ellersleben": "Ellersleben",
    "ellerstadt": "Ellerstadt",
    "ellgau": "Ellgau",
    "ellhofen": "Ellhofen",
    "ellhoeft": "Ellhöft",
    "ellingen": "Ellingen",
    "ellingshausen": "Ellingshausen",
    "ellingstedt": "Ellingstedt",
    "ellrich": "Ellrich",
    "ellscheid": "Ellscheid",
    "ellwangen-jagst": "Ellwangen/Jagst",
    "ellweiler": "Ellweiler",
    "ellzee": "Ellzee",
    "elmenhorst-rostock": "Elmenhorst b. Rostock",
    "elmenhorst-stormarn": "Elmenhorst/Kr. Stormarn",
    "elmenhorst-lauenburg": "Elmenhorst/Lauenburg",
    "elmenhorst-vorpommern": "Elmenhorst/Vorpommern",
    "elmshorn": "Elmshorn",
    "elmstein": "Elmstein",
    "elpersbuettel": "Elpersbüttel",
    "elsdorf-niedersachsen": "Elsdorf/Niedersachsen",
    "elsdorf-rheinland": "Elsdorf/Rheinland",
    "elsdorf-westermuehlen": "Elsdorf-Westermühlen",
    "elsendorf": "Elsendorf",
    "elsenfeld": "Elsenfeld",
    "elsfleth": "Elsfleth",
    "elskop": "Elskop",
    "elsnig": "Elsnig",
    "elsoff-westerwald": "Elsoff/Westerwald",
    "elsteraue": "Elsteraue",
    "elsterberg": "Elsterberg",
    "elsterheide": "Elsterheide",
    "elstertrebnitz": "Elstertrebnitz",
    "elsterwerda": "Elsterwerda",
    "elstra": "Elstra",
    "elterlein": "Elterlein",
    "eltmann": "Eltmann",
    "eltville-am-rhein": "Eltville am Rhein",
    "elxleben-gera": "Elxleben a.d. Gera",
    "elxleben-arnstadt": "Elxleben b. Arnstadt",
    "elz": "Elz",
    "elzach": "Elzach",
    "elze": "Elze",
    "elztal": "Elztal",
    "elzweiler": "Elzweiler",
    "embsen": "Embsen",
    "embuehren": "Embühren",
    "emden-ostfriesland": "Emden",
    "emeringen": "Emeringen",
    "emerkingen": "Emerkingen",
    "emersacker": "Emersacker",
    "emkendorf": "Emkendorf",
    "emleben": "Emleben",
    "emlichheim": "Emlichheim",
    "emmelbaum": "Emmelbaum",
    "emmelsbuell-horsbuell": "Emmelsbüll-Horsbüll",
    "emmelshausen": "Emmelshausen",
    "emmendingen": "Emmendingen",
    "emmendorf": "Emmendorf",
    "emmerich-am-rhein": "Emmerich am Rhein",
    "emmering-wasserburg": "Emmering b. Wasserburg",
    "emmering-fuerstenfeldbruck": "Emmering Kr. Fürstenfeldbruck",
    "emmerthal": "Emmerthal",
    "emmerting": "Emmerting",
    "emmerzhausen": "Emmerzhausen",
    "emmingen-liptingen": "Emmingen-Liptingen",
    "empfertshausen": "Empfertshausen",
    "empfingen": "Empfingen",
    "emsbueren": "Emsbüren",
    "emsdetten": "Emsdetten",
    "emskirchen": "Emskirchen",
    "emstek": "Emstek",
    "emtinghausen": "Emtinghausen",
    "emtmannsberg": "Emtmannsberg",
    "endingen-am-kaiserstuhl": "Endingen am Kaiserstuhl",
    "endlichhofen": "Endlichhofen",
    "endschuetz": "Endschütz",
    "engden": "Engden",
    "engelbrechtsche-wildnis": "Engelbrechtsche Wildnis",
    "engelsberg": "Engelsberg",
    "engelsbrand": "Engelsbrand",
    "engelschoff": "Engelschoff",
    "engelskirchen": "Engelskirchen",
    "engelstadt": "Engelstadt",
    "engelthal": "Engelthal",
    "engen": "Engen",
    "enger": "Enger",
    "enge-sande": "Enge-Sande",
    "engstingen": "Engstingen",
    "eningen-unter-achalm": "Eningen unter Achalm",
    "enkenbach-alsenborn": "Enkenbach-Alsenborn",
    "enkirch": "Enkirch",
    "ennepetal": "Ennepetal",
    "ennigerloh": "Ennigerloh",
    "ensch": "Ensch",
    "ensdorf-oberpfalz": "Ensdorf/Oberpfalz",
    "ensdorf-saar": "Ensdorf/Saar",
    "ense": "Ense",
    "ensheim": "Ensheim",
    "enspel": "Enspel",
    "entenhausen": "Entenhausen",
    "enzen": "Enzen",
    "enzkloesterle": "Enzklösterle",
    "epenwoehrden": "Epenwöhrden",
    "epfenbach": "Epfenbach",
    "epfendorf": "Epfendorf",
    "eppelborn": "Eppelborn",
    "eppelheim": "Eppelheim",
    "eppelsheim": "Eppelsheim",
    "eppenberg": "Eppenberg",
    "eppenbrunn": "Eppenbrunn",
    "eppendorf": "Eppendorf",
    "eppenrod": "Eppenrod",
    "eppenschlag": "Eppenschlag",
    "eppertshausen": "Eppertshausen",
    "eppingen": "Eppingen",
    "eppishausen": "Eppishausen",
    "eppstein": "Eppstein",
    "erbach-donau": "Erbach/Donau",
    "erbach-hunsrueck": "Erbach/Hunsrück",
    "erbach-odenwald": "Erbach/Odenwald",
    "erbendorf": "Erbendorf",
    "erbenhausen": "Erbenhausen",
    "erbes-buedesheim": "Erbes-Büdesheim",
    "erden": "Erden",
    "erdesbach": "Erdesbach",
    "erding": "Erding",
    "erdmannhausen": "Erdmannhausen",
    "erdweg": "Erdweg",
    "eresing": "Eresing",
    "erfde": "Erfde",
    "erftstadt": "Erftstadt",
    "erfurt": "Erfurt",
    "erfweiler": "Erfweiler",
    "ergersheim": "Ergersheim",
    "ergeshausen": "Ergeshausen",
    "ergolding": "Ergolding",
    "ergoldsbach": "Ergoldsbach",
    "erharting": "Erharting",
    "ering": "Ering",
    "eriskirch": "Eriskirch",
    "erkelenz": "Erkelenz",
    "erkenbrechtsweiler": "Erkenbrechtsweiler",
    "erkerode": "Erkerode",
    "erkheim": "Erkheim",
    "erkner": "Erkner",
    "erkrath": "Erkrath",
    "erlabrunn-unterfranken": "Erlabrunn/Unterfranken",
    "erlangen": "Erlangen",
    "erlau": "Erlau",
    "erlbach-altoetting": "Erlbach Kr. Altötting",
    "erlenbach-main": "Erlenbach a. Main",
    "erlenbach-dahn": "Erlenbach b. Dahn",
    "erlenbach-kandel": "Erlenbach b. Kandel",
    "erlenbach-marktheidenfeld": "Erlenbach b. Marktheidenfeld",
    "erlenbach-heilbronn": "Erlenbach Kr. Heilbronn",
    "erlenmoos": "Erlenmoos",
    "erlensee": "Erlensee",
    "erligheim": "Erligheim",
    "ermershausen": "Ermershausen",
    "erndtebrueck": "Erndtebrück",
    "ernsgaden": "Ernsgaden",
    "ernst": "Ernst",
    "ernzen": "Ernzen",
    "erolzheim": "Erolzheim",
    "erpel": "Erpel",
    "erpolzheim": "Erpolzheim",
    "ersfeld": "Ersfeld",
    "ertingen": "Ertingen",
    "erwitte": "Erwitte",
    "erxleben-haldensleben": "Erxleben b. Haldensleben",
    "erzenhausen": "Erzenhausen",
    "erzhausen": "Erzhausen",
    "esch-gerolstein": "Esch b. Gerolstein",
    "esch-wittlich": "Esch b. Wittlich",
    "eschach": "Eschach",
    "eschau": "Eschau",
    "eschbach-nastaetten": "Eschbach b. Nastätten",
    "eschbach-markgraeflerland": "Eschbach/Markgräflerland",
    "eschbach-pfalz": "Eschbach/Pfalz",
    "eschborn": "Eschborn",
    "eschbronn": "Eschbronn",
    "esche": "Esche",
    "escheburg": "Escheburg",
    "eschede": "Eschede",
    "eschelbronn": "Eschelbronn",
    "eschenbach-oberpfalz": "Eschenbach/Oberpfalz",
    "eschenbach-wuerttemberg": "Eschenbach/Württemberg",
    "eschenbergen": "Eschenbergen",
    "eschenburg": "Eschenburg",
    "eschenlohe": "Eschenlohe",
    "eschershausen": "Eschershausen",
    "eschfeld": "Eschfeld",
    "eschlkam": "Eschlkam",
    "eschwege": "Eschwege",
    "eschweiler": "Eschweiler",
    "esens": "Esens",
    "esgrus": "Esgrus",
    "eslarn": "Eslarn",
    "eslohe-sauerland": "Eslohe/Sauerland",
    "espelkamp": "Espelkamp",
    "espenau": "Espenau",
    "essbach": "Eßbach",
    "essel": "Essel",
    "esselbach": "Esselbach",
    "esselborn": "Esselborn",
    "essen-oldenburg": "Essen/Oldenburg",
    "essen-ruhr": "Essen/Ruhr",
    "essenbach-markt": "Essenbach Markt",
    "essenheim": "Essenheim",
    "essing": "Essing",
    "essingen-pfalz": "Essingen/Pfalz",
    "essingen-wuerttemberg": "Essingen/Württemberg",
    "essleben-teutleben": "Eßleben-Teutleben",
    "esslingen-eifel": "Eßlingen",
    "esslingen-am-neckar": "Esslingen am Neckar",
    "essweiler": "Eßweiler",
    "estenfeld": "Estenfeld",
    "esterwegen": "Esterwegen",
    "esthal": "Esthal",
    "estorf-stade": "Estorf Kr. Stade",
    "estorf-weser": "Estorf/Weser",
    "etgert": "Etgert",
    "etschberg": "Etschberg",
    "ettal": "Ettal",
    "etteldorf": "Etteldorf",
    "ettenhausen-suhl": "Ettenhausen a.d. Suhl",
    "ettenheim": "Ettenheim",
    "ettenstatt": "Ettenstatt",
    "ettersburg": "Ettersburg",
    "ettinghausen": "Ettinghausen",
    "ettlingen": "Ettlingen",
    "ettringen-eifel": "Ettringen/Eifel",
    "ettringen-wertach": "Ettringen/Wertach",
    "etzbach": "Etzbach",
    "etzelsrode": "Etzelsrode",
    "etzelwang": "Etzelwang",
    "etzenricht": "Etzenricht",
    "etzleben": "Etzleben",
    "euerbach": "Euerbach",
    "euerdorf": "Euerdorf",
    "eulenberg": "Eulenberg",
    "eulenbis": "Eulenbis",
    "eulgem": "Eulgem",
    "eurasburg-friedberg": "Eurasburg b. Friedberg",
    "eurasburg-oberbayern": "Eurasburg b. Wolfratsh.",
    "euscheid": "Euscheid",
    "euskirchen": "Euskirchen",
    "eussenheim": "Eußenheim",
    "eusserthal": "Eußerthal",
    "eutin": "Eutin",
    "eutingen-im-gaeu": "Eutingen im Gäu",
    "everode": "Everode",
    "eversmeer": "Eversmeer",
    "everswinkel": "Everswinkel",
    "evessen": "Evessen",
    "ewighausen": "Ewighausen",
    "extertal": "Extertal",
    "eydelstedt": "Eydelstedt",
    "eyendorf": "Eyendorf",
    "eystrup": "Eystrup",
    "fachbach": "Fachbach",
    "fahrdorf": "Fahrdorf",
    "fahren": "Fahren",
    "fahrenbach": "Fahrenbach",
    "fahrenkrug": "Fahrenkrug",
    "fahrenwalde": "Fahrenwalde",
    "fahrenzhausen": "Fahrenzhausen",
    "faid": "Faid",
    "falkenberg-elster": "Falkenberg/Elster",
    "falkenberg-mark": "Falkenberg/Mark",
    "falkenberg-niederbayern": "Falkenberg/Niederbayern",
    "falkenberg-oberpfalz": "Falkenberg/Oberpfalz",
    "falkenfels": "Falkenfels",
    "falkenhagen-mark": "Falkenhagen (Mark)",
    "falkensee": "Falkensee",
    "falkenstein-harz": "Falkenstein/Harz",
    "falkenstein-oberpfalz": "Falkenstein/Oberpfalz",
    "falkenstein-pfalz": "Falkenstein/Pfalz",
    "falkenstein-vogtland": "Falkenstein/Vogtland",
    "fambach": "Fambach",
    "farchant": "Farchant",
    "fargau-pratjau": "Fargau-Pratjau",
    "farnstaedt": "Farnstädt",
    "farschweiler": "Farschweiler",
    "farven": "Farven",
    "fassberg": "Faßberg",
    "faulbach": "Faulbach",
    "faulenrost": "Faulenrost",
    "fedderingen": "Fedderingen",
    "fehl-ritzhausen": "Fehl-Ritzhausen",
    "fehrbellin": "Fehrbellin",
    "feichten-alz": "Feichten a.d. Alz",
    "feilbingert": "Feilbingert",
    "feilitzsch": "Feilitzsch",
    "feilsdorf": "Feilsdorf",
    "feldafing": "Feldafing",
    "feldatal": "Feldatal",
    "feldberg-schwarzwald": "Feldberg/Schwarzwald",
    "feldberger-seenlandschaft": "Feldberger Seenlandschaft",
    "felde": "Felde",
    "feldhorst": "Feldhorst",
    "feldkirchen-muenchen": "Feldkirchen Kr. München",
    "feldkirchen-niederbayern": "Feldkirchen/Niederbayern",
    "feldkirchen-westerham": "Feldkirchen-Westerham",
    "felixsee": "Felixsee",
    "fell": "Fell",
    "fellbach": "Fellbach",
    "fellen": "Fellen",
    "fellheim": "Fellheim",
    "felm": "Felm",
    "felsberg": "Felsberg",
    "fensdorf": "Fensdorf",
    "fensterbach": "Fensterbach",
    "ferdinandshof": "Ferdinandshof",
    "ferna": "Ferna",
    "fernwald": "Fernwald",
    "ferschweiler": "Ferschweiler",
    "feucht": "Feucht",
    "feuchtwangen": "Feuchtwangen",
    "feuerscheid": "Feuerscheid",
    "feusdorf": "Feusdorf",
    "fichtelberg": "Fichtelberg",
    "fichtenau": "Fichtenau",
    "fichtenberg-wuerttemberg": "Fichtenberg/Württemberg",
    "fichtenhoehe": "Fichtenhöhe",
    "fichtwald": "Fichtwald",
    "fiefbergen": "Fiefbergen",
    "fiersbach": "Fiersbach",
    "filderstadt": "Filderstadt",
    "filsen": "Filsen",
    "filsum": "Filsum",
    "filz": "Filz",
    "fincken": "Fincken",
    "finkenbach-gersweiler": "Finkenbach-Gersweiler",
    "finkenthal": "Finkenthal",
    "finne": "Finne",
    "finneland": "Finneland",
    "finnentrop": "Finnentrop",
    "finning": "Finning",
    "finningen": "Finningen",
    "finsing": "Finsing",
    "finsterwalde": "Finsterwalde",
    "fintel": "Fintel",
    "firrel": "Firrel",
    "fisch": "Fisch",
    "fischach": "Fischach",
    "fischbach-dahn": "Fischbach b. Dahn",
    "fischbach-idar-oberstein": "Fischbach b. Idar-Oberstein",
    "fischbach-kaiserslautern": "Fischbach Kr. Kaiserslautern",
    "fischbachau": "Fischbachau",
    "fischbach-oberraden": "Fischbach-Oberraden",
    "fischbachtal": "Fischbachtal",
    "fischen-allgaeu": "Fischen i. Allgäu",
    "fischerbach": "Fischerbach",
    "fischingen": "Fischingen",
    "fitzbek": "Fitzbek",
    "fitzen": "Fitzen",
    "flachslanden": "Flachslanden",
    "flacht": "Flacht",
    "fladungen": "Fladungen",
    "flammersfeld": "Flammersfeld",
    "flarchheim": "Flarchheim",
    "flechtingen": "Flechtingen",
    "fleckeby": "Fleckeby",
    "flein": "Flein",
    "fleischwangen": "Fleischwangen",
    "flemlingen": "Flemlingen",
    "flensburg": "Flensburg",
    "fleringen": "Fleringen",
    "flieden": "Flieden",
    "fliessem": "Fließem",
    "flieth-stegelitz": "Flieth-Stegelitz",
    "flintbek": "Flintbek",
    "flintsbach-inn": "Flintsbach a. Inn",
    "floeha": "Flöha",
    "floh-seligenthal": "Floh-Seligenthal",
    "flomborn": "Flomborn",
    "flonheim": "Flonheim",
    "floersbachtal": "Flörsbachtal",
    "floersheim-am-main": "Flörsheim am Main",
    "floersheim-dalsheim": "Flörsheim-Dalsheim",
    "florstadt": "Florstadt",
    "floss": "Floß",
    "flossenbuerg": "Flossenbürg",
    "floethe": "Flöthe",
    "fluorn-winzeln": "Fluorn-Winzeln",
    "flussbach": "Flußbach",
    "fluterschen": "Fluterschen",
    "fockbek": "Fockbek",
    "foeckelberg": "Föckelberg",
    "fockendorf": "Fockendorf",
    "foehrden-barl": "Föhrden-Barl",
    "foehren": "Föhren",
    "fohren-linden": "Fohren-Linden",
    "forbach": "Forbach",
    "forchheim-breisgau": "Forchheim/Breisgau",
    "forchheim-oberfranken": "Forchheim/Oberfranken",
    "forchtenberg": "Forchtenberg",
    "forheim": "Forheim",
    "foeritz": "Föritz",
    "foeritztal": "Föritztal",
    "forst-lausitz": "Forst (Lausitz)",
    "forst-an-der-weinstrasse": "Forst an der Weinstraße",
    "forst-wissen": "Forst b. Wissen",
    "forst-baden": "Forst/Baden",
    "forst-eifel": "Forst/Eifel",
    "forst-hunsrueck": "Forst/Hunsrück",
    "forstern": "Forstern",
    "forstinning": "Forstinning",
    "forstmehren": "Forstmehren",
    "framersheim": "Framersheim",
    "frammersbach": "Frammersbach",
    "frankelbach": "Frankelbach",
    "frankenau": "Frankenau",
    "frankenberg-eder": "Frankenberg/Eder",
    "frankenberg-s": "Frankenberg/Sa.",
    "frankenblick": "Frankenblick",
    "frankendorf-weimar": "Frankendorf b. Weimar",
    "frankeneck": "Frankeneck",
    "frankenfeld": "Frankenfeld",
    "frankenhain-thueringen": "Frankenhain/Thüringen",
    "frankenhardt": "Frankenhardt",
    "frankenheim-meiningen": "Frankenheim b. Meiningen",
    "frankenroda": "Frankenroda",
    "frankenstein-pfalz": "Frankenstein/Pfalz",
    "frankenthal-pfalz": "Frankenthal",
    "frankenthal-sachsen": "Frankenthal/Sachsen",
    "frankenwinheim": "Frankenwinheim",
    "frankfurt-oder": "Frankfurt (Oder)",
    "frankfurt-am-main": "Frankfurt am Main",
    "fraenkisch-crumbach": "Fränkisch-Crumbach",
    "frankweiler": "Frankweiler",
    "franzburg": "Franzburg",
    "franzenheim": "Franzenheim",
    "frasdorf": "Frasdorf",
    "frauenau": "Frauenau",
    "frauenberg": "Frauenberg",
    "frauendorf-oberlausitz": "Frauendorf/Oberlausitz",
    "frauenneuharting": "Frauenneuharting",
    "frauenpriessnitz": "Frauenprießnitz",
    "frauensee": "Frauensee",
    "frauenstein": "Frauenstein",
    "frauenwald": "Frauenwald",
    "fraunberg": "Fraunberg",
    "fraureuth": "Fraureuth",
    "frechen": "Frechen",
    "freckenfeld": "Freckenfeld",
    "fredeburg": "Fredeburg",
    "freden-leine": "Freden (Leine)",
    "fredenbeck": "Fredenbeck",
    "fredersdorf-vogelsdorf": "Fredersdorf-Vogelsdorf",
    "fredesdorf": "Fredesdorf",
    "freiamt": "Freiamt",
    "freiberg-am-neckar": "Freiberg am Neckar",
    "freiberg-sachsen": "Freiberg/Sachsen",
    "freiburg-im-breisgau": "Freiburg im Breisgau",
    "freiburg-elbe": "Freiburg/Elbe",
    "freienbessingen": "Freienbessingen",
    "freienhagen-heiligenstadt": "Freienhagen b. Heiligenstadt",
    "freienorla": "Freienorla",
    "freiensteinau": "Freiensteinau",
    "freienwill": "Freienwill",
    "freigericht": "Freigericht",
    "freihung": "Freihung",
    "freilassing": "Freilassing",
    "frei-laubersheim": "Frei-Laubersheim",
    "freilingen": "Freilingen",
    "freimersheim-pfalz": "Freimersheim/Pfalz",
    "freimersheim-rheinhessen": "Freimersheim/Rheinhessen",
    "freinsheim": "Freinsheim",
    "freirachdorf": "Freirachdorf",
    "freisbach": "Freisbach",
    "freisen": "Freisen",
    "freising": "Freising",
    "freistatt": "Freistatt",
    "freital": "Freital",
    "frellstedt": "Frellstedt",
    "fremdingen": "Fremdingen",
    "frensdorf": "Frensdorf",
    "freren": "Freren",
    "fresenburg": "Fresenburg",
    "fresendelf": "Fresendelf",
    "frestedt": "Frestedt",
    "frettenheim": "Frettenheim",
    "fretterode": "Fretterode",
    "freudenberg-am-main": "Freudenberg am Main",
    "freudenberg-oberpfalz": "Freudenberg/Oberpfalz",
    "freudenberg-westfalen": "Freudenberg/Westfalen",
    "freudenburg": "Freudenburg",
    "freudenstadt": "Freudenstadt",
    "freudental": "Freudental",
    "freyburg-unstrut": "Freyburg/Unstrut",
    "freystadt": "Freystadt",
    "freyung": "Freyung",
    "frickenhausen-main": "Frickenhausen a. Main",
    "frickenhausen-wuerttemberg": "Frickenhausen/Württemberg",
    "frickingen": "Frickingen",
    "fridingen-an-der-donau": "Fridingen an der Donau",
    "fridolfing": "Fridolfing",
    "friedberg-bayern": "Friedberg/Bayern",
    "friedberg-hessen": "Friedberg/Hessen",
    "friedeburg-ostfriesland": "Friedeburg/Ostfriesland",
    "friedelshausen": "Friedelshausen",
    "friedelsheim": "Friedelsheim",
    "friedenfels": "Friedenfels",
    "friedenweiler": "Friedenweiler",
    "friedersdorf-thueringen": "Friedersdorf/Thüringen",
    "friedewald-hessen": "Friedewald/Hessen",
    "friedewald-westerwald": "Friedewald/Westerwald",
    "friedland-niederlausitz": "Friedland (Niederlausitz)",
    "friedland-goettingen": "Friedland/Kr. Göttingen",
    "friedland-neubrandenburg": "Friedland/Neubrandenburg",
    "friedrichroda": "Friedrichroda",
    "friedrichsdorf": "Friedrichsdorf",
    "friedrichsgabekoog": "Friedrichsgabekoog",
    "friedrichsgraben": "Friedrichsgraben",
    "friedrichshafen": "Friedrichshafen",
    "friedrichsholm": "Friedrichsholm",
    "friedrichskoog": "Friedrichskoog",
    "friedrichsruhe": "Friedrichsruhe",
    "friedrichstadt": "Friedrichstadt",
    "friedrichsthal-nordhausen": "Friedrichsthal b. Nordhausen",
    "friedrichsthal-saar": "Friedrichsthal/Saar",
    "friedrichswalde": "Friedrichswalde",
    "friedrichswerth": "Friedrichswerth",
    "friedrich-wilhelm-luebke-koog": "Friedrich-Wilhelm-Lübke-Koog",
    "frielendorf": "Frielendorf",
    "friemar": "Friemar",
    "friesack": "Friesack",
    "friesenhagen": "Friesenhagen",
    "friesenheim-baden": "Friesenheim/Baden",
    "friesenheim-rheinhessen": "Friesenheim/Rheinhessen",
    "friesenried": "Friesenried",
    "friesoythe": "Friesoythe",
    "friolzheim": "Friolzheim",
    "frittlingen": "Frittlingen",
    "fritzlar": "Fritzlar",
    "frohburg": "Frohburg",
    "froehnd": "Fröhnd",
    "frohnhofen": "Frohnhofen",
    "frohnsdorf": "Frohnsdorf",
    "froemmstedt": "Frömmstedt",
    "froendenberg-ruhr": "Fröndenberg/Ruhr",
    "fronhausen": "Fronhausen",
    "fronhofen": "Fronhofen",
    "fronreute": "Fronreute",
    "frontenhausen": "Frontenhausen",
    "fruecht": "Frücht",
    "fuchshofen": "Fuchshofen",
    "fuchsmuehl": "Fuchsmühl",
    "fuchsstadt": "Fuchsstadt",
    "fuchstal": "Fuchstal",
    "fuhlendorf-wiemersdorf": "Fuhlendorf b. Wiemersdorf",
    "fuhlendorf-darss": "Fuhlendorf/Darß",
    "fuhlenhagen": "Fuhlenhagen",
    "fulda": "Fulda",
    "fuldabrueck": "Fuldabrück",
    "fuldatal": "Fuldatal",
    "fuenfseen": "Fünfseen",
    "fuenfstetten": "Fünfstetten",
    "fuerfeld": "Fürfeld",
    "fuerstenau": "Fürstenau",
    "fuerstenberg-havel": "Fürstenberg/Havel",
    "fuerstenberg-weser": "Fürstenberg/Weser",
    "fuersteneck": "Fürsteneck",
    "fuerstenfeldbruck": "Fürstenfeldbruck",
    "fuerstenstein": "Fürstenstein",
    "fuerstenwalde-spree": "Fürstenwalde/Spree",
    "fuerstenzell": "Fürstenzell",
    "fuerth-bayern": "Fürth",
    "furth-im-wald": "Furth im Wald",
    "furth-landshut": "Furth Kr. Landshut",
    "fuerth-odenwald": "Fürth/Odenwald",
    "fuerthen": "Fürthen",
    "furtwangen-im-schwarzwald": "Furtwangen im Schwarzwald",
    "fuessen": "Füssen",
    "fussgoenheim": "Fußgönheim",
    "gablenz-oberlausitz": "Gablenz/Oberlausitz",
    "gablingen": "Gablingen",
    "gabsheim": "Gabsheim",
    "gachenbach": "Gachenbach",
    "gackenbach": "Gackenbach",
    "gadebusch": "Gadebusch",
    "gaedheim": "Gädheim",
    "gaegelow": "Gägelow",
    "gager": "Gager",
    "gaggenau": "Gaggenau",
    "gaiberg": "Gaiberg",
    "gaienhofen": "Gaienhofen",
    "gaildorf": "Gaildorf",
    "gailingen-am-hochrhein": "Gailingen am Hochrhein",
    "gaimersheim": "Gaimersheim",
    "gaissach": "Gaißach",
    "galenbeck": "Galenbeck",
    "galenberg": "Galenberg",
    "gallin-boizenburg": "Gallin b. Boizenburg",
    "gallin-kuppentin": "Gallin-Kuppentin",
    "gallmersgarten": "Gallmersgarten",
    "galmsbuell": "Galmsbüll",
    "gamlen": "Gamlen",
    "gammelby": "Gammelby",
    "gammelin": "Gammelin",
    "gammelsdorf": "Gammelsdorf",
    "gammelshausen": "Gammelshausen",
    "gammertingen": "Gammertingen",
    "ganderkesee": "Ganderkesee",
    "gandesbergen": "Gandesbergen",
    "gangelt": "Gangelt",
    "gangkofen": "Gangkofen",
    "gangloffsoemmern": "Gangloffsömmern",
    "ganzlin": "Ganzlin",
    "gappenach": "Gappenach",
    "garbsen": "Garbsen",
    "garching-alz": "Garching a.d. Alz",
    "garching-muenchen": "Garching b. München",
    "gardelegen": "Gardelegen",
    "garding-eiderstedt": "Garding",
    "garlstorf": "Garlstorf",
    "garmisch-partenkirchen": "Garmisch-Partenkirchen",
    "garrel": "Garrel",
    "gars-inn": "Gars a. Inn",
    "garstedt": "Garstedt",
    "gartow-elbe": "Gartow/Elbe",
    "gaertringen": "Gärtringen",
    "gartz-oder": "Gartz/Oder",
    "garz-ruegen": "Garz/Rügen",
    "garz-usedom": "Garz/Usedom",
    "garzau-garzin": "Garzau-Garzin",
    "gattendorf": "Gattendorf",
    "gau-algesheim": "Gau-Algesheim",
    "gau-bickelheim": "Gau-Bickelheim",
    "gau-bischofsheim": "Gau-Bischofsheim",
    "gauern": "Gauern",
    "gauersheim": "Gauersheim",
    "gaeufelden": "Gäufelden",
    "gaugrehweiler": "Gaugrehweiler",
    "gau-heppenheim": "Gau-Heppenheim",
    "gaukoenigshofen": "Gaukönigshofen",
    "gau-odernheim": "Gau-Odernheim",
    "gaushorn": "Gaushorn",
    "gauting": "Gauting",
    "gau-weinheim": "Gau-Weinheim",
    "gebenbach": "Gebenbach",
    "gebesee": "Gebesee",
    "gebhardshain": "Gebhardshain",
    "gebroth": "Gebroth",
    "gebsattel": "Gebsattel",
    "gechingen": "Gechingen",
    "gedern": "Gedern",
    "geeste": "Geeste",
    "geesthacht": "Geesthacht",
    "geestland": "Geestland",
    "gefell-schleiz": "Gefell b. Schleiz",
    "gefell-daun": "Gefell Kr. Daun",
    "gefrees": "Gefrees",
    "gehlberg": "Gehlberg",
    "gehlert": "Gehlert",
    "gehlsbach": "Gehlsbach",
    "gehlweiler": "Gehlweiler",
    "gehofen": "Gehofen",
    "gehrde": "Gehrde",
    "gehrden-hannover": "Gehrden",
    "gehren-thueringen": "Gehren/Thüringen",
    "gehrweiler": "Gehrweiler",
    "geichlingen": "Geichlingen",
    "geiersthal": "Geiersthal",
    "geilenkirchen": "Geilenkirchen",
    "geilnau": "Geilnau",
    "geisa": "Geisa",
    "geiselbach": "Geiselbach",
    "geiselberg": "Geiselberg",
    "geiselhoering": "Geiselhöring",
    "geiselwind": "Geiselwind",
    "geisenfeld": "Geisenfeld",
    "geisenhain": "Geisenhain",
    "geisenhausen": "Geisenhausen",
    "geisenheim": "Geisenheim",
    "geisfeld": "Geisfeld",
    "geisig": "Geisig",
    "geisingen": "Geisingen",
    "geisleden": "Geisleden",
    "geislingen-an-der-steige": "Geislingen an der Steige",
    "geislingen-balingen": "Geislingen b. Balingen",
    "geismar": "Geismar",
    "geithain": "Geithain",
    "gelbensande": "Gelbensande",
    "gelchsheim": "Gelchsheim",
    "geldern": "Geldern",
    "geldersheim": "Geldersheim",
    "gelenau-erzgebirge": "Gelenau/Erzgebirge",
    "gelenberg": "Gelenberg",
    "gelnhausen": "Gelnhausen",
    "gelsenkirchen": "Gelsenkirchen",
    "geltendorf": "Geltendorf",
    "gelting": "Gelting",
    "geltorf": "Geltorf",
    "gemmerich": "Gemmerich",
    "gemmingen": "Gemmingen",
    "gemmrigheim": "Gemmrigheim",
    "gemuend": "Gemünd",
    "gemuenden-main": "Gemünden a. Main",
    "gemuenden-felda": "Gemünden/Felda",
    "gemuenden-hunsrueck": "Gemünden/Hunsrück",
    "gemuenden-westerwald": "Gemünden/Westerwald",
    "gemuenden-wohra": "Gemünden/Wohra",
    "genderkingen": "Genderkingen",
    "gengenbach": "Gengenbach",
    "gensingen": "Gensingen",
    "genthin": "Genthin",
    "gentingen": "Gentingen",
    "genzkow": "Genzkow",
    "georgenberg": "Georgenberg",
    "georgensgmuend": "Georgensgmünd",
    "georgenthal-thueringer-wald": "Georgenthal/Thüringer Wald",
    "georgsdorf": "Georgsdorf",
    "georgsmarienhuette": "Georgsmarienhütte",
    "gera": "Gera",
    "geraberg": "Geraberg",
    "gerabronn": "Gerabronn",
    "gerach-idar-oberstein": "Gerach b. Idar-Oberstein",
    "gerach-oberfranken": "Gerach/Oberfranken",
    "geratal": "Geratal",
    "geratskirchen": "Geratskirchen",
    "gerbach": "Gerbach",
    "gerbershausen": "Gerbershausen",
    "gerbrunn": "Gerbrunn",
    "gerbstedt": "Gerbstedt",
    "gerdau": "Gerdau",
    "gerdshagen": "Gerdshagen",
    "geretsried": "Geretsried",
    "gerhardsbrunn": "Gerhardsbrunn",
    "gerhardshofen": "Gerhardshofen",
    "gering": "Gering",
    "geringswalde": "Geringswalde",
    "gerlingen": "Gerlingen",
    "germaringen": "Germaringen",
    "germering": "Germering",
    "germersheim": "Germersheim",
    "gernrode-leinefelde": "Gernrode b. Leinefelde",
    "gernsbach": "Gernsbach",
    "gernsheim": "Gernsheim",
    "geroda-neustadt": "Geroda b. Neustadt",
    "geroda-unterfranken": "Geroda/Unterfranken",
    "geroldsgruen": "Geroldsgrün",
    "geroldshausen": "Geroldshausen",
    "gerolfingen": "Gerolfingen",
    "gerolsbach": "Gerolsbach",
    "gerolsheim": "Gerolsheim",
    "gerolstein": "Gerolstein",
    "gerolzhofen": "Gerolzhofen",
    "gersdorf-hohenstein-ernstthal": "Gersdorf b. Hohenstein-Ernstthal",
    "gersfeld-rhoen": "Gersfeld/Rhön",
    "gersheim": "Gersheim",
    "gersten": "Gersten",
    "gerstenberg": "Gerstenberg",
    "gerstengrund": "Gerstengrund",
    "gerstetten": "Gerstetten",
    "gersthofen": "Gersthofen",
    "gerstungen": "Gerstungen",
    "gerswalde": "Gerswalde",
    "gerterode": "Gerterode",
    "gertewitz": "Gertewitz",
    "gerzen": "Gerzen",
    "geschendorf": "Geschendorf",
    "gescher": "Gescher",
    "geschwenda": "Geschwenda",
    "gesees": "Gesees",
    "geseke": "Geseke",
    "geslau": "Geslau",
    "gessertshausen": "Gessertshausen",
    "gestratz": "Gestratz",
    "getelo": "Getelo",
    "gettorf": "Gettorf",
    "gevelsberg": "Gevelsberg",
    "gevenich": "Gevenich",
    "gevensleben": "Gevensleben",
    "geversdorf": "Geversdorf",
    "geyer": "Geyer",
    "giebelstadt": "Giebelstadt",
    "gieboldehausen": "Gieboldehausen",
    "giekau": "Giekau",
    "gieleroth": "Gieleroth",
    "gielert": "Gielert",
    "gielow": "Gielow",
    "giengen-an-der-brenz": "Giengen an der Brenz",
    "gierschnach": "Gierschnach",
    "giershausen": "Giershausen",
    "giersleben": "Giersleben",
    "gierstaedt": "Gierstädt",
    "giesdorf": "Giesdorf",
    "giesen": "Giesen",
    "giesenhausen": "Giesenhausen",
    "giesensdorf-ratzeburg": "Giesensdorf b. Ratzeburg",
    "giessen": "Gießen",
    "gifhorn": "Gifhorn",
    "gilching": "Gilching",
    "gillenbeuren": "Gillenbeuren",
    "gillenfeld": "Gillenfeld",
    "gillersdorf": "Gillersdorf",
    "gilserberg": "Gilserberg",
    "gilten": "Gilten",
    "gilzem": "Gilzem",
    "gimbsheim": "Gimbsheim",
    "gimbweiler": "Gimbweiler",
    "gindorf": "Gindorf",
    "gingen-an-der-fils": "Gingen an der Fils",
    "gingst": "Gingst",
    "ginsheim-gustavsburg": "Ginsheim-Gustavsburg",
    "ginsweiler": "Ginsweiler",
    "gipperath": "Gipperath",
    "girkenroth": "Girkenroth",
    "girod": "Girod",
    "gischow": "Gischow",
    "gladbach": "Gladbach",
    "gladbeck": "Gladbeck",
    "gladenbach": "Gladenbach",
    "glanbruecken": "Glanbrücken",
    "glandorf": "Glandorf",
    "glan-muenchweiler": "Glan-Münchweiler",
    "glasau": "Glasau",
    "glasehausen": "Glasehausen",
    "glasewitz": "Glasewitz",
    "glashuette-sachsen": "Glashütte/Sachsen",
    "glashuetten-oberfranken": "Glashütten/Oberfranken",
    "glashuetten-taunus": "Glashütten/Taunus",
    "glasin": "Glasin",
    "glasow": "Glasow",
    "glattbach": "Glattbach",
    "glatten": "Glatten",
    "glaubitz": "Glaubitz",
    "glauburg": "Glauburg",
    "glauchau": "Glauchau",
    "glees": "Glees",
    "gleichen": "Gleichen",
    "gleina": "Gleina",
    "gleiritsch": "Gleiritsch",
    "gleissenberg": "Gleißenberg",
    "gleisweiler": "Gleisweiler",
    "gleiszellen-gleishorbach": "Gleiszellen-Gleishorbach",
    "glewitz": "Glewitz",
    "glienicke-nordbahn": "Glienicke/Nordbahn",
    "glinde-stormarn": "Glinde/Kr. Stormarn",
    "glonn": "Glonn",
    "gloett": "Glött",
    "glottertal": "Glottertal",
    "glowe": "Glowe",
    "gluecksburg-ostsee": "Glücksburg/Ostsee",
    "glueckstadt": "Glückstadt",
    "gluesing": "Glüsing",
    "gmund-tegernsee": "Gmund a. Tegernsee",
    "gnarrenburg": "Gnarrenburg",
    "gneus": "Gneus",
    "gneven": "Gneven",
    "gnevkow": "Gnevkow",
    "gnewitz": "Gnewitz",
    "gnoien": "Gnoien",
    "gnotzheim": "Gnotzheim",
    "gnutz": "Gnutz",
    "goch": "Goch",
    "gochsheim": "Gochsheim",
    "goecklingen": "Göcklingen",
    "goeda": "Göda",
    "goddert": "Goddert",
    "godendorf": "Godendorf",
    "goedenroth": "Gödenroth",
    "goedenstorf": "Gödenstorf",
    "goeggingen": "Göggingen",
    "goehl": "Göhl",
    "goehlen-ludwigslust": "Göhlen b. Ludwigslust",
    "goehrde": "Göhrde",
    "goehren-altburger-land": "Göhren Kr. Altburger Land",
    "goehren-ruegen": "Göhren/Rügen",
    "goehren-lebbin": "Göhren-Lebbin",
    "gohrisch": "Gohrisch",
    "gokels": "Gokels",
    "golchen": "Golchen",
    "goldbach-gotha": "Goldbach b. Gotha",
    "goldbach-unterfranken": "Goldbach/Unterfranken",
    "goldbeck-altmark": "Goldbeck (Altmark)",
    "goldberg": "Goldberg",
    "goldebek": "Goldebek",
    "goldelund": "Goldelund",
    "goeldenitz": "Göldenitz",
    "goldenstedt": "Goldenstedt",
    "goldisthal": "Goldisthal",
    "goldkronach": "Goldkronach",
    "goelenkamp": "Gölenkamp",
    "gollenberg": "Gollenberg",
    "gollenberg-havelland": "Gollenberg (Havelland)",
    "goellheim": "Göllheim",
    "gollhofen": "Gollhofen",
    "goellnitz-schmoelln": "Göllnitz b. Schmölln",
    "golmbach": "Golmbach",
    "golmsdorf": "Golmsdorf",
    "golssen": "Golßen",
    "goltoft": "Goltoft",
    "golzow-brandenburg": "Golzow b. Brandenburg",
    "golzow-oderbruch": "Golzow/Oderbruch",
    "gomadingen": "Gomadingen",
    "gomaringen": "Gomaringen",
    "gommern": "Gommern",
    "gommersheim": "Gommersheim",
    "gompertshausen": "Gompertshausen",
    "gonbach": "Gonbach",
    "gondelsheim": "Gondelsheim",
    "gondenbrett": "Gondenbrett",
    "gondershausen": "Gondershausen",
    "gondorf": "Gondorf",
    "goennebek": "Gönnebek",
    "goennersdorf-gerolstein": "Gönnersdorf b. Gerolstein",
    "goennersdorf-ahrweiler": "Gönnersdorf Kr. Ahrweiler",
    "goennheim": "Gönnheim",
    "goosefeld": "Goosefeld",
    "goepfersdorf": "Göpfersdorf",
    "goeppingen": "Göppingen",
    "gorden-staupitz": "Gorden-Staupitz",
    "goergeshausen": "Görgeshausen",
    "goerisried": "Görisried",
    "goeritz-prenzlau": "Göritz b. Prenzlau",
    "goerkwitz": "Görkwitz",
    "gorleben": "Gorleben",
    "goerlitz": "Görlitz",
    "gorlosen": "Gorlosen",
    "goermin": "Görmin",
    "gornau-erzgebirge": "Gornau/Erzgebirge",
    "gornhausen": "Gornhausen",
    "gornsdorf": "Gornsdorf",
    "goersbach": "Görsbach",
    "gorsleben": "Gorsleben",
    "goerwihl": "Görwihl",
    "gorxheimertal": "Gorxheimertal",
    "goerzke": "Görzke",
    "goeschitz": "Göschitz",
    "goseck": "Goseck",
    "goesen": "Gösen",
    "gosen-neu-zittau": "Gosen-Neu Zittau",
    "goesenroth": "Gösenroth",
    "gosheim": "Gosheim",
    "goslar": "Goslar",
    "gossel": "Gossel",
    "goessenheim": "Gössenheim",
    "gossersweiler-stein": "Gossersweiler-Stein",
    "goessitz": "Gössitz",
    "goessnitz": "Gößnitz",
    "goessweinstein": "Gößweinstein",
    "gotha": "Gotha",
    "gottenheim": "Gottenheim",
    "gottesgabe": "Gottesgabe",
    "gotteszell": "Gotteszell",
    "gottfrieding": "Gottfrieding",
    "gotthun": "Gotthun",
    "goettin": "Göttin",
    "goettingen": "Göttingen",
    "gottmadingen": "Gottmadingen",
    "graach-an-der-mosel": "Graach an der Mosel",
    "graal-mueritz": "Graal-Müritz",
    "grabau-schwarzenbeck": "Grabau b. Schwarzenbek",
    "grabau-stormarn": "Grabau/Kr. Stormarn",
    "graeben": "Gräben",
    "graben-lechfeld": "Graben/Lechfeld",
    "graben-neudorf": "Graben-Neudorf",
    "grabenstaett": "Grabenstätt",
    "grabenstetten": "Grabenstetten",
    "grabfeld": "Grabfeld",
    "grabow-mecklenburg": "Grabow/Mecklenburg",
    "grabow-below": "Grabow-Below",
    "grabowhoefe": "Grabowhöfe",
    "graefelfing": "Gräfelfing",
    "grafenau-niederbayern": "Grafenau/Niederbayern",
    "grafenau-wuerttemberg": "Grafenau/Württemberg",
    "grafenberg": "Grafenberg",
    "graefenberg": "Gräfenberg",
    "graefendhron": "Gräfendhron",
    "graefendorf-unterfranken": "Gräfendorf/Unterfranken",
    "grafengehaig": "Grafengehaig",
    "graefenhain": "Gräfenhain",
    "graefenhainichen": "Gräfenhainichen",
    "grafenhausen": "Grafenhausen",
    "grafenrheinfeld": "Grafenrheinfeld",
    "graefenroda": "Gräfenroda",
    "graefenthal": "Gräfenthal",
    "grafenwiesen": "Grafenwiesen",
    "grafenwoehr": "Grafenwöhr",
    "grafhorst": "Grafhorst",
    "grafing-muenchen": "Grafing b. München",
    "grafling": "Grafling",
    "grafrath": "Grafrath",
    "grafschaft": "Grafschaft",
    "grainau": "Grainau",
    "grainet": "Grainet",
    "graitschen-buergel": "Graitschen b. Bürgel",
    "grambek": "Grambek",
    "grambin": "Grambin",
    "grambow-pasewalk": "Grambow b. Pasewalk",
    "grambow-schwerin": "Grambow b. Schwerin",
    "grammendorf": "Grammendorf",
    "grammentin": "Grammentin",
    "grammow": "Grammow",
    "gramzow": "Gramzow",
    "grande": "Grande",
    "gransdorf": "Gransdorf",
    "gransebieth": "Gransebieth",
    "gransee": "Gransee",
    "granzin": "Granzin",
    "grapzow": "Grapzow",
    "grasberg": "Grasberg",
    "grasbrunn": "Grasbrunn",
    "grasellenbach": "Grasellenbach",
    "grasleben": "Grasleben",
    "grassau-chiemgau": "Grassau/Chiemgau",
    "grattersdorf": "Grattersdorf",
    "grauel": "Grauel",
    "graevenwiesbach": "Grävenwiesbach",
    "grebenau": "Grebenau",
    "grebenhain": "Grebenhain",
    "grebenstein": "Grebenstein",
    "grebin": "Grebin",
    "grebs-niendorf": "Grebs-Niendorf",
    "greding": "Greding",
    "grefrath": "Grefrath",
    "greifenberg": "Greifenberg",
    "greifenstein": "Greifenstein",
    "greifswald": "Greifswald",
    "greiling": "Greiling",
    "greimerath-trier": "Greimerath b. Trier",
    "greimerath-eifel": "Greimerath/Eifel",
    "greimersburg": "Greimersburg",
    "greiz": "Greiz",
    "gremersdorf-holstein": "Gremersdorf/Holstein",
    "gremersdorf-buchholz": "Gremersdorf-Buchholz",
    "gremsdorf": "Gremsdorf",
    "grenderich": "Grenderich",
    "grenzach-wyhlen": "Grenzach-Wyhlen",
    "gresse": "Gresse",
    "grethem": "Grethem",
    "grettstadt": "Grettstadt",
    "greussen": "Greußen",
    "greussenheim": "Greußenheim",
    "greven-boizenburg": "Greven b. Boizenburg",
    "greven-westfalen": "Greven/Westfalen",
    "grevenbroich": "Grevenbroich",
    "grevenkop": "Grevenkop",
    "grevenkrug": "Grevenkrug",
    "grevesmuehlen": "Grevesmühlen",
    "gribbohm": "Gribbohm",
    "gribow": "Gribow",
    "griebelschied": "Griebelschied",
    "grieben-grevesmuehlen": "Grieben b. Grevesmühlen",
    "griefstedt": "Griefstedt",
    "gries": "Gries",
    "griesheim": "Griesheim",
    "griesingen": "Griesingen",
    "griesstaett": "Griesstätt",
    "grimburg": "Grimburg",
    "grimma": "Grimma",
    "grimmelshausen": "Grimmelshausen",
    "grimmen": "Grimmen",
    "grinau": "Grinau",
    "grischow-altentreptow": "Grischow b. Altentreptow",
    "grobengereuth": "Grobengereuth",
    "groebenzell": "Gröbenzell",
    "groede": "Gröde",
    "groeden": "Gröden",
    "groedersby": "Grödersby",
    "groeditz": "Gröditz",
    "groitzsch": "Groitzsch",
    "grolsheim": "Grolsheim",
    "groembach": "Grömbach",
    "groemitz": "Grömitz",
    "gronau-leine": "Gronau (Leine)",
    "gronau-westfalen": "Gronau/Westfalen",
    "groeningen": "Gröningen",
    "groenwohld": "Grönwohld",
    "gross-berssen": "Groß Berßen",
    "gross-bieberau": "Groß Bieberau",
    "gross-boden": "Groß Boden",
    "gross-buchwald": "Groß Buchwald",
    "gross-disnack": "Groß Disnack",
    "gross-dueben": "Groß Düben",
    "gross-godems": "Groß Godems",
    "gross-groenau": "Groß Grönau",
    "gross-ippener": "Groß Ippener",
    "gross-kelle": "Groß Kelle",
    "gross-kiesow": "Groß Kiesow",
    "gross-kordshagen": "Groß Kordshagen",
    "gross-koeris": "Groß Köris",
    "gross-krams": "Groß Krams",
    "gross-kreutz-havel": "Groß Kreutz (Havel)",
    "gross-kummerfeld": "Groß Kummerfeld",
    "gross-laasch": "Groß Laasch",
    "gross-lindow": "Groß Lindow",
    "gross-luckow": "Groß Luckow",
    "gross-meckelsen": "Groß Meckelsen",
    "gross-miltzow": "Groß Miltzow",
    "gross-mohrdorf": "Groß Mohrdorf",
    "gross-molzahn": "Groß Molzahn",
    "gross-nemerow": "Groß Nemerow",
    "gross-niendorf-holstein": "Groß Niendorf/Holstein",
    "gross-nordende": "Groß Nordende",
    "gross-oesingen": "Groß Oesingen",
    "gross-offenseth-aspern": "Groß Offenseth-Aspern",
    "gross-pampau": "Groß Pampau",
    "gross-pankow": "Groß Pankow",
    "gross-plasten": "Groß Plasten",
    "gross-polzin": "Groß Polzin",
    "gross-quenstedt": "Groß Quenstedt",
    "gross-rheide": "Groß Rheide",
    "gross-roge": "Groß Roge",
    "gross-rohrheim": "Groß Rohrheim",
    "gross-roennau": "Groß Rönnau",
    "gross-sarau": "Groß Sarau",
    "gross-schacksdorf-simmersdorf": "Groß Schacksdorf-Simmersdorf",
    "gross-schenkenberg": "Groß Schenkenberg",
    "gross-schwiesow": "Groß Schwiesow",
    "gross-siemz": "Groß Siemz",
    "gross-stieten": "Groß Stieten",
    "gross-teetzleben": "Groß Teetzleben",
    "gross-twuelpstedt": "Groß Twülpstedt",
    "gross-vollstedt": "Groß Vollstedt",
    "gross-wittensee": "Groß Wittensee",
    "gross-wuestenfelde": "Groß Wüstenfelde",
    "gross-zimmern": "Groß Zimmern",
    "grossaitingen": "Großaitingen",
    "grossalmerode": "Großalmerode",
    "grossbardorf": "Großbardorf",
    "grossbarkau": "Großbarkau",
    "grossbartloff": "Großbartloff",
    "grossbeeren": "Großbeeren",
    "grossbettlingen": "Großbettlingen",
    "grossbockedra": "Großbockedra",
    "grossbottwar": "Großbottwar",
    "grossbreitenbach": "Großbreitenbach",
    "grossbrembach": "Großbrembach",
    "grossbundenbach": "Großbundenbach",
    "grossderschau": "Großderschau",
    "grossdubrau": "Großdubrau",
    "grossefehn": "Großefehn",
    "grosseibstadt": "Großeibstadt",
    "grosselfingen": "Grosselfingen",
    "grossenaspe": "Großenaspe",
    "grossenbrode": "Großenbrode",
    "grossenehrich": "Großenehrich",
    "grossengottern": "Großengottern",
    "grossenhain": "Großenhain",
    "grossenkneten": "Großenkneten",
    "grossenlueder": "Großenlüder",
    "grossenrade": "Großenrade",
    "grossensee-eisenach": "Großensee b. Eisenach",
    "grossensee-stormarn": "Großensee/Kr. Stormarn",
    "grossenseebach": "Großenseebach",
    "grossenstein": "Großenstein",
    "grossenwiehe": "Großenwiehe",
    "grossenwoerden": "Großenwörden",
    "grosserlach": "Großerlach",
    "grosseutersdorf": "Großeutersdorf",
    "grossfahner": "Großfahner",
    "grossfischlingen": "Großfischlingen",
    "gross-gerau": "Groß-Gerau",
    "grosshabersdorf": "Großhabersdorf",
    "grosshansdorf": "Großhansdorf",
    "grossharrie": "Großharrie",
    "grossharthau": "Großharthau",
    "grosshartmannsdorf": "Großhartmannsdorf",
    "grossheide": "Großheide",
    "grossheirath": "Großheirath",
    "grossheringen": "Großheringen",
    "grossheubach": "Großheubach",
    "grossholbach": "Großholbach",
    "grosskampenberg": "Großkampenberg",
    "grosskarlbach": "Großkarlbach",
    "grosskarolinenfeld": "Großkarolinenfeld",
    "grosskmehlen": "Großkmehlen",
    "grosskrotzenburg": "Großkrotzenburg",
    "grosslangenfeld": "Großlangenfeld",
    "grosslangheim": "Großlangheim",
    "grosslittgen": "Großlittgen",
    "grossloebichau": "Großlöbichau",
    "grosslohra": "Großlohra",
    "grossmaischeid": "Großmaischeid",
    "grossmehring": "Großmehring",
    "grossmoelsen": "Großmölsen",
    "grossnaundorf": "Großnaundorf",
    "grossneuhausen": "Großneuhausen",
    "grossniedesheim": "Großniedesheim",
    "grossobringen": "Großobringen",
    "grossolbersdorf": "Großolbersdorf",
    "grossostheim": "Großostheim",
    "grosspoesna": "Großpösna",
    "grosspostwitz-oberlausitz": "Großpostwitz/Oberlausitz",
    "grosspuerschuetz": "Großpürschütz",
    "grossraeschen": "Großräschen",
    "grossrinderfeld": "Großrinderfeld",
    "grossroehrsdorf": "Großröhrsdorf",
    "grossrosseln": "Großrosseln",
    "grossrueckerswalde": "Großrückerswalde",
    "grossrudestedt": "Großrudestedt",
    "grossschirma": "Großschirma",
    "grossschoenau": "Großschönau",
    "grossschwabhausen": "Großschwabhausen",
    "grossschweidnitz": "Großschweidnitz",
    "grossseifen": "Großseifen",
    "grosssolt": "Großsolt",
    "grosssteinhausen": "Großsteinhausen",
    "grossthiemig": "Großthiemig",
    "gross-umstadt": "Groß-Umstadt",
    "grossvargula": "Großvargula",
    "grosswallstadt": "Großwallstadt",
    "grossweil": "Großweil",
    "grossweitzschen": "Großweitzschen",
    "gross-wokern": "Groß-Wokern",
    "grosswoltersdorf": "Großwoltersdorf",
    "grothusenkoog": "Grothusenkoog",
    "grove": "Grove",
    "groven": "Groven",
    "grub-forst": "Grub a. Forst",
    "grub-suhl": "Grub b. Suhl",
    "grube-holstein": "Grube/Holstein",
    "gruibingen": "Gruibingen",
    "grumbach-glan": "Grumbach/Glan",
    "gruenbach": "Grünbach",
    "gruenberg-hessen": "Grünberg/Hessen",
    "gruendau": "Gründau",
    "grundhof": "Grundhof",
    "grundsheim": "Grundsheim",
    "gruenebach": "Grünebach",
    "gruenenbach": "Grünenbach",
    "gruenendeich": "Grünendeich",
    "gruenewald": "Grünewald",
    "gruenhain-beierfeld": "Grünhain-Beierfeld",
    "gruenhainichen": "Grünhainichen",
    "gruenheide-mark": "Grünheide (Mark)",
    "gruenkraut": "Grünkraut",
    "gruenow-mecklenburg": "Grünow b. Neustr.",
    "gruenow-prenzlau": "Grünow b. Prenzlau",
    "grunow-dammendorf": "Grunow-Dammendorf",
    "gruensfeld": "Grünsfeld",
    "gruenstadt": "Grünstadt",
    "gruenwald": "Grünwald",
    "gschwend": "Gschwend",
    "gstadt-chiemsee": "Gstadt a. Chiemsee",
    "guben": "Guben",
    "gueby": "Güby",
    "guckheim": "Guckheim",
    "gueckingen": "Gückingen",
    "gudendorf": "Gudendorf",
    "gudensberg": "Gudensberg",
    "guderhandviertel": "Guderhandviertel",
    "gudow": "Gudow",
    "guggenhausen": "Guggenhausen",
    "gueglingen": "Güglingen",
    "guhrow": "Guhrow",
    "guldental": "Guldental",
    "guellesheim": "Güllesheim",
    "gueltz": "Gültz",
    "guelzow-malchin": "Gülzow b. Malchin",
    "guelzow-lauenburg": "Gülzow/Kr. Hzgt. Lauenburg",
    "guelzow-pruezen": "Gülzow-Prüzen",
    "gumbsheim": "Gumbsheim",
    "gummersbach": "Gummersbach",
    "gumperda": "Gumperda",
    "gumtow": "Gumtow",
    "gundelfingen-donau": "Gundelfingen a.d. Donau",
    "gundelfingen-breisgau": "Gundelfingen/Breisgau",
    "gundelsheim-oberfranken": "Gundelsheim/Oberfranken",
    "gundelsheim-wuerttemberg": "Gundelsheim/Württemberg",
    "gunderath": "Gunderath",
    "gundersheim": "Gundersheim",
    "gundersweiler": "Gundersweiler",
    "gundheim": "Gundheim",
    "gundremmingen": "Gundremmingen",
    "gunningen": "Gunningen",
    "guenstedt": "Günstedt",
    "guntersblum": "Guntersblum",
    "guentersleben": "Güntersleben",
    "guenthersleben-wechmar": "Günthersleben-Wechmar",
    "guenzach": "Günzach",
    "guenzburg": "Günzburg",
    "gunzenhausen": "Gunzenhausen",
    "gusborn": "Gusborn",
    "gusenburg": "Gusenburg",
    "gusow-platkow": "Gusow-Platkow",
    "guesten": "Güsten",
    "guester": "Güster",
    "gusterath": "Gusterath",
    "gustow": "Gustow",
    "guestrow": "Güstrow",
    "gutach-im-breisgau": "Gutach im Breisgau",
    "gutach-schwarzwaldbahn": "Gutach/Schwarzwaldbahn",
    "guteborn": "Guteborn",
    "gutenacker": "Gutenacker",
    "guetenbach": "Gütenbach",
    "gutenberg-bad-kreuznach": "Gutenberg Kr. Bad Kreuznach",
    "gutenborn": "Gutenborn",
    "guteneck": "Guteneck",
    "gutenstetten": "Gutenstetten",
    "gutenzell-huerbel": "Gutenzell-Hürbel",
    "guetersloh": "Gütersloh",
    "guthmannshausen": "Guthmannshausen",
    "guetlitz-reetz": "Gütlitz-Reetz",
    "gutow": "Gutow",
    "gutsbezirk-muensingen": "Gutsbezirk Münsingen",
    "guttenberg": "Guttenberg",
    "gutweiler": "Gutweiler",
    "guetzkow": "Gützkow",
    "guxhagen": "Guxhagen",
    "gyhum": "Gyhum",
    "haag-amper": "Haag a.d. Amper",
    "haag-oberbayern": "Haag/Oberbayern",
    "haag-oberfranken": "Haag/Oberfranken",
    "haale": "Haale",
    "haan": "Haan",
    "haar": "Haar",
    "haarbach": "Haarbach",
    "habach": "Habach",
    "habichtswald": "Habichtswald",
    "habscheid": "Habscheid",
    "haby": "Haby",
    "hachenburg": "Hachenburg",
    "hackenheim": "Hackenheim",
    "hadamar": "Hadamar",
    "hademstorf": "Hademstorf",
    "hadenfeld": "Hadenfeld",
    "hafenlohr": "Hafenlohr",
    "hage": "Hage",
    "haeg-ehrsberg": "Häg-Ehrsberg",
    "hagelstadt": "Hagelstadt",
    "hagen-westfalen": "Hagen",
    "hagen-am-teutoburger-wald": "Hagen am Teutoburger Wald",
    "hagen-bad-bramstedt": "Hagen b. Bad Bramstedt",
    "hagen-im-bremischen": "Hagen im Bremischen",
    "hagenbach": "Hagenbach",
    "hagenbuechach": "Hagenbüchach",
    "hagenburg": "Hagenburg",
    "hagenow": "Hagenow",
    "hagermarsch": "Hagermarsch",
    "hagnau-am-bodensee": "Hagnau am Bodensee",
    "hahausen": "Hahausen",
    "hahn-am-see": "Hahn am See",
    "hahn-marienberg": "Hahn b. Marienberg",
    "hahn-hunsrueck": "Hahn/Hunsrück",
    "hahnbach": "Hahnbach",
    "hahnenbach": "Hahnenbach",
    "hahnheim": "Hahnheim",
    "haehnichen": "Hähnichen",
    "hahnstaetten": "Hahnstätten",
    "hahnweiler": "Hahnweiler",
    "haibach-niederbayern": "Haibach/Niederbayern",
    "haibach-unterfranken": "Haibach/Unterfranken",
    "haidmuehle": "Haidmühle",
    "haiger": "Haiger",
    "haigerloch": "Haigerloch",
    "haimhausen": "Haimhausen",
    "haiming": "Haiming",
    "haina-gotha": "Haina b. Gotha",
    "haina-kloster": "Haina/Kloster",
    "hainau": "Hainau",
    "hainburg": "Hainburg",
    "hainewalde": "Hainewalde",
    "hainfeld": "Hainfeld",
    "hainichen": "Hainichen",
    "hainichen-sachsen": "Hainichen/Sachsen",
    "hainrode-hainleite": "Hainrode/Hainleite",
    "hainsfarth": "Hainsfarth",
    "hainspitz": "Hainspitz",
    "haiterbach": "Haiterbach",
    "halbe": "Halbe",
    "halbemond": "Halbemond",
    "halberstadt": "Halberstadt",
    "halblech": "Halblech",
    "halbs": "Halbs",
    "haldensleben": "Haldensleben",
    "haldenwang-guenzburg": "Haldenwang Kr. Günzburg",
    "haldenwang-allgaeu": "Haldenwang/Allgäu",
    "halenbeck-rohlsdorf": "Halenbeck-Rohlsdorf",
    "halfing": "Halfing",
    "hallbergmoos": "Hallbergmoos",
    "halle-saale": "Halle (Saale)",
    "halle-neuenhaus": "Halle b. Neuenhaus",
    "halle-holzminden": "Halle Kr. Holzminden",
    "halle-westfalen": "Halle/Westfalen",
    "hallenberg": "Hallenberg",
    "hallerndorf": "Hallerndorf",
    "hallgarten": "Hallgarten",
    "hallschlag": "Hallschlag",
    "hallstadt": "Hallstadt",
    "hallungen": "Hallungen",
    "halsbach": "Halsbach",
    "halsbruecke": "Halsbrücke",
    "halsdorf": "Halsdorf",
    "halsenbach": "Halsenbach",
    "halstenbek": "Halstenbek",
    "haltern-am-see": "Haltern am See",
    "halver": "Halver",
    "halvesbostel": "Halvesbostel",
    "hambach": "Hambach",
    "hamberge": "Hamberge",
    "hambergen": "Hambergen",
    "hambruecken": "Hambrücken",
    "hambuch": "Hambuch",
    "hambuehren": "Hambühren",
    "hamburg": "Hamburg",
    "hamdorf": "Hamdorf",
    "haemelhausen": "Hämelhausen",
    "hameln": "Hameln",
    "hamersen": "Hamersen",
    "hamfelde-hzgt-lauenburg": "Hamfelde/Kr. Hzgt Lauenburg",
    "hamfelde-stormarn": "Hamfelde/Kr. Stormarn",
    "hamm-westfalen": "Hamm",
    "hamm-eifel": "Hamm/Eifel",
    "hamm-am-rhein": "Hamm/Rheinhessen",
    "hamm-sieg": "Hamm/Sieg",
    "hammah": "Hammah",
    "hammelburg": "Hammelburg",
    "hammer-uecker": "Hammer a.d. Uecker",
    "hammersbach": "Hammersbach",
    "hammerstedt": "Hammerstedt",
    "hammerstein": "Hammerstein",
    "hamminkeln": "Hamminkeln",
    "hammoor": "Hammoor",
    "hamwarde": "Hamwarde",
    "hamweddel": "Hamweddel",
    "hanau": "Hanau",
    "handeloh": "Handeloh",
    "handewitt": "Handewitt",
    "handorf": "Handorf",
    "handrup": "Handrup",
    "hanerau-hademarschen": "Hanerau-Hademarschen",
    "hangen-weisheim": "Hangen-Weisheim",
    "hanhofen": "Hanhofen",
    "hankensbuettel": "Hankensbüttel",
    "hannover": "Hannover",
    "hann-muenden": "Hannoversch Münden",
    "hanroth": "Hanroth",
    "buxtehude": "Hansestadt Buxtehude",
    "hanshagen-greifswald": "Hanshagen b. Greifswald",
    "hanstedt-uelzen": "Hanstedt Kr. Uelzen",
    "hanstedt-nordheide": "Hanstedt/Nordheide",
    "happurg": "Happurg",
    "harbach": "Harbach",
    "harbarnsen": "Harbarnsen",
    "harbke": "Harbke",
    "harburg-schwaben": "Harburg/Schwaben",
    "hardebek": "Hardebek",
    "hardegsen": "Hardegsen",
    "hardert": "Hardert",
    "hardheim": "Hardheim",
    "hardisleben": "Hardisleben",
    "hardt-schramberg": "Hardt b. Schramberg",
    "hardt-westerwald": "Hardt/Westerwald",
    "hardthausen-am-kocher": "Hardthausen am Kocher",
    "haren-ems": "Haren/Ems",
    "hargarten": "Hargarten",
    "hargesheim": "Hargesheim",
    "harmsdorf-ratzeburg": "Harmsdorf b. Ratzeburg",
    "harmsdorf-holstein": "Harmsdorf/Holstein",
    "harmstorf": "Harmstorf",
    "harpstedt": "Harpstedt",
    "harra": "Harra",
    "harrislee": "Harrislee",
    "harschbach": "Harschbach",
    "harscheid": "Harscheid",
    "harsdorf": "Harsdorf",
    "harsefeld": "Harsefeld",
    "harsewinkel": "Harsewinkel",
    "harsleben": "Harsleben",
    "harspelt": "Harspelt",
    "harsum": "Harsum",
    "hartenfels": "Hartenfels",
    "hartenholm": "Hartenholm",
    "hartenstein-zwickau": "Hartenstein b. Zwickau",
    "hartenstein-mittelfranken": "Hartenstein/Mittelfranken",
    "hartha-kreis-doebeln": "Hartha/Kreis Döbeln",
    "harthausen": "Harthausen",
    "hartheim": "Hartheim am Rhein",
    "harth-poellnitz": "Harth-Pöllnitz",
    "haertlingen": "Härtlingen",
    "hartmannsdorf-chemnitz": "Hartmannsdorf b. Chemnitz",
    "hartmannsdorf-eisenberg": "Hartmannsdorf b. Eisenberg",
    "hartmannsdorf-gera": "Hartmannsdorf b. Gera",
    "hartmannsdorf-kirchberg": "Hartmannsdorf b. Kirchberg",
    "hartmannsdorf-reichenau": "Hartmannsdorf-Reichenau",
    "harxheim": "Harxheim",
    "harzgerode": "Harzgerode",
    "harztor": "Harztor",
    "harzungen": "Harzungen",
    "hasbergen": "Hasbergen",
    "hasborn": "Hasborn",
    "haschbach-am-remigiusberg": "Haschbach am Remigiusberg",
    "hasel": "Hasel",
    "haselau": "Haselau",
    "haselbach-altenburg": "Haselbach b. Altenburg",
    "haselbach-mitterfels": "Haselbach b. Mitterfels",
    "haselbachtal": "Haselbachtal",
    "haseldorf": "Haseldorf",
    "haselund": "Haselund",
    "haseluenne": "Haselünne",
    "hasenkrug": "Hasenkrug",
    "hasenmoor": "Hasenmoor",
    "haserich": "Haserich",
    "haslach-im-kinzigtal": "Haslach im Kinzigtal",
    "hasloch": "Hasloch",
    "hasloh": "Hasloh",
    "hassbergen": "Haßbergen",
    "hassel-weser": "Hassel (Weser)",
    "hassel-stendal": "Hassel b. Stendal",
    "hasselbach-hunsrueck": "Hasselbach/Hunsrück",
    "hasselbach-westerwald": "Hasselbach/Westerwald",
    "hasselberg": "Hasselberg",
    "hasselroth": "Hasselroth",
    "hassendorf": "Hassendorf",
    "hassfurt": "Haßfurt",
    "hassleben-thueringen": "Haßleben/Thüringen",
    "hassloch": "Haßloch",
    "hassmersheim": "Haßmersheim",
    "hassmoor": "Haßmoor",
    "haste": "Haste",
    "hatten": "Hatten",
    "hattenhofen-fuerstenfeldbruck": "Hattenhofen Kr. Fürstenfeldbruck",
    "hattenhofen-wuerttemberg": "Hattenhofen/Württemberg",
    "hattersheim-am-main": "Hattersheim am Main",
    "hattert": "Hattert",
    "hattgenstein": "Hattgenstein",
    "hattingen": "Hattingen",
    "hattorf-am-harz": "Hattorf am Harz",
    "hattstedt": "Hattstedt",
    "hattstedtermarsch": "Hattstedtermarsch",
    "hatzenbuehl": "Hatzenbühl",
    "hatzenport": "Hatzenport",
    "hatzfeld-eder": "Hatzfeld/Eder",
    "hauenstein": "Hauenstein",
    "haundorf": "Haundorf",
    "hauneck": "Hauneck",
    "haunetal": "Haunetal",
    "haunsheim": "Haunsheim",
    "hauptstuhl": "Hauptstuhl",
    "hauroth": "Hauroth",
    "hausach": "Hausach",
    "hausbay": "Hausbay",
    "hausen-am-bussen": "Hausen am Bussen",
    "hausen-am-tann": "Hausen am Tann",
    "hausen-aschaffenburg": "Hausen b. Aschaffenburg",
    "hausen-wuerzburg": "Hausen b. Würzburg",
    "hausen-im-wiesental": "Hausen im Wiesental",
    "hausen-ob-verena": "Hausen ob Verena",
    "hausen-eichsfeld": "Hausen/Eichsfeld",
    "hausen-hunsrueck": "Hausen/Hunsrück",
    "hausen-niederbayern": "Hausen/Niederbayern",
    "hausen-oberfranken": "Hausen/Oberfranken",
    "hausen-rhoen": "Hausen/Rhön",
    "hausen-wied": "Hausen/Wied",
    "haeusern": "Häusern",
    "hausham": "Hausham",
    "haeuslingen": "Häuslingen",
    "haussoemmern": "Haussömmern",
    "hausten": "Hausten",
    "hausweiler": "Hausweiler",
    "hauteroda": "Hauteroda",
    "hauzenberg": "Hauzenberg",
    "havekost": "Havekost",
    "havelaue": "Havelaue",
    "havelberg": "Havelberg",
    "havelsee": "Havelsee",
    "haverlah": "Haverlah",
    "havetoft": "Havetoft",
    "havixbeck": "Havixbeck",
    "hawangen": "Hawangen",
    "hayingen": "Hayingen",
    "haynrode": "Haynrode",
    "hebertsfelden": "Hebertsfelden",
    "hebertshausen": "Hebertshausen",
    "hechingen": "Hechingen",
    "hechthausen": "Hechthausen",
    "heckelberg-brunow": "Heckelberg-Brunow",
    "hecken": "Hecken",
    "heckenbach": "Heckenbach",
    "heckenmuenster": "Heckenmünster",
    "heckhuscheid": "Heckhuscheid",
    "hecklingen": "Hecklingen",
    "heddert": "Heddert",
    "heddesbach": "Heddesbach",
    "heddesheim": "Heddesheim",
    "hedeper": "Hedeper",
    "hedersleben-aschersleben": "Hedersleben b. Aschersleben",
    "hedwigenkoog": "Hedwigenkoog",
    "heede-barmstedt": "Heede b. Barmstedt",
    "heede-ems": "Heede/Ems",
    "heek": "Heek",
    "heemsen": "Heemsen",
    "heere": "Heere",
    "heeslingen": "Heeslingen",
    "heessen": "Heeßen",
    "hefersweiler": "Hefersweiler",
    "hehlen": "Hehlen",
    "heichelheim": "Heichelheim",
    "heide": "Heide",
    "heideblick": "Heideblick",
    "heideck": "Heideck",
    "heidekamp": "Heidekamp",
    "heideland-eisenberg": "Heideland",
    "heideland-brandenburg": "Heideland/Brandenburg",
    "heidelberg": "Heidelberg",
    "heiden": "Heiden",
    "heidenau-nordheide": "Heidenau/Nordheide",
    "heidenau-sachsen": "Heidenau/Sachsen",
    "heidenburg": "Heidenburg",
    "heidenheim-an-der-brenz": "Heidenheim an der Brenz",
    "heidenheim-mittelfranken": "Heidenheim/Mittelfranken",
    "heidenrod": "Heidenrod",
    "heidersdorf": "Heidersdorf",
    "heidesee": "Heidesee",
    "heidesheim-am-rhein": "Heidesheim am Rhein",
    "heidgraben": "Heidgraben",
    "heidmoor": "Heidmoor",
    "heidmuehlen": "Heidmühlen",
    "heidweiler": "Heidweiler",
    "heigenbruecken": "Heigenbrücken",
    "heikendorf": "Heikendorf",
    "heilbach": "Heilbach",
    "heilbad-heiligenstadt": "Heilbad Heiligenstadt",
    "heilberscheid": "Heilberscheid",
    "heilbronn": "Heilbronn",
    "heilenbach": "Heilenbach",
    "heiligenberg": "Heiligenberg",
    "heiligengrabe": "Heiligengrabe",
    "heiligenhafen": "Heiligenhafen",
    "heiligenhaus": "Heiligenhaus",
    "heiligenmoschel": "Heiligenmoschel",
    "heiligenroth": "Heiligenroth",
    "heiligenstadt-oberfranken": "Heiligenstadt i. Oberfranken",
    "heiligenstedten": "Heiligenstedten",
    "heiligenstedtenerkamp": "Heiligenstedtenerkamp",
    "heiligkreuzsteinach": "Heiligkreuzsteinach",
    "heilsbronn": "Heilsbronn",
    "heilshoop": "Heilshoop",
    "heimbach-eifel": "Heimbach/Eifel",
    "heimbach-nahe": "Heimbach/Nahe",
    "heimborn": "Heimborn",
    "heimbuchenthal": "Heimbuchenthal",
    "heimenkirch": "Heimenkirch",
    "heimertingen": "Heimertingen",
    "heimsheim": "Heimsheim",
    "heimweiler": "Heimweiler",
    "heinade": "Heinade",
    "heinbockel": "Heinbockel",
    "heinersbrueck": "Heinersbrück",
    "heinersreuth": "Heinersreuth",
    "heiningen-wolfenbuettel": "Heiningen b. Wolfenbüttel",
    "heiningen-goeppingen": "Heiningen Kr. Göppingen",
    "heinkenborstel": "Heinkenborstel",
    "heinrichsthal": "Heinrichsthal",
    "heinrichswalde": "Heinrichswalde",
    "heinsberg": "Heinsberg",
    "heinsdorfergrund": "Heinsdorfergrund",
    "heinsen": "Heinsen",
    "heinzenbach": "Heinzenbach",
    "heinzenberg": "Heinzenberg",
    "heinzenhausen": "Heinzenhausen",
    "heisdorf": "Heisdorf",
    "heist": "Heist",
    "heistenbach": "Heistenbach",
    "heitersheim": "Heitersheim",
    "helbeduendorf": "Helbedündorf",
    "helbra": "Helbra",
    "heldburg": "Heldburg",
    "heldenstein": "Heldenstein",
    "heldrungen": "Heldrungen",
    "helferskirchen": "Helferskirchen",
    "helgoland": "Helgoland",
    "hellenhahn-schellenberg": "Hellenhahn-Schellenberg",
    "hellenthal": "Hellenthal",
    "hellertshausen": "Hellertshausen",
    "hellingen": "Hellingen",
    "hellschen-heringsand-unterschaar": "Hellschen-Heringsand-Unterschaar",
    "hellwege": "Hellwege",
    "helmbrechts": "Helmbrechts",
    "helmenzen": "Helmenzen",
    "helmeroth": "Helmeroth",
    "helmsdorf": "Helmsdorf",
    "helmstadt": "Helmstadt",
    "helmstadt-bargen": "Helmstadt-Bargen",
    "helmstedt": "Helmstedt",
    "helmstorf": "Helmstorf",
    "helpsen": "Helpsen",
    "helsa": "Helsa",
    "helse": "Helse",
    "heltersberg": "Heltersberg",
    "helvesiek": "Helvesiek",
    "hemau": "Hemau",
    "hemdingen": "Hemdingen",
    "hemer": "Hemer",
    "hemhofen": "Hemhofen",
    "hemleben": "Hemleben",
    "hemme": "Hemme",
    "hemmelzen": "Hemmelzen",
    "hemmersheim": "Hemmersheim",
    "hemmingen-hannover": "Hemmingen",
    "hemmingen-wuerttemberg": "Hemmingen/Württemberg",
    "hemmingstedt": "Hemmingstedt",
    "hemmoor": "Hemmoor",
    "hemsbach": "Hemsbach",
    "hemsbuende": "Hemsbünde",
    "hemslingen": "Hemslingen",
    "hemsloh": "Hemsloh",
    "henau": "Henau",
    "hendungen": "Hendungen",
    "henfenfeld": "Henfenfeld",
    "henfstaedt": "Henfstädt",
    "hengersberg": "Hengersberg",
    "henneberg": "Henneberg",
    "hennef-sieg": "Hennef/Sieg",
    "hennigsdorf": "Hennigsdorf",
    "hennstedt-itzehoe": "Hennstedt b. Itzehoe",
    "hennstedt-dithmarschen": "Hennstedt/Dithmarschen",
    "hennweiler": "Hennweiler",
    "henschleben": "Henschleben",
    "henschtal": "Henschtal",
    "henstedt-ulzburg": "Henstedt-Ulzburg",
    "hentern": "Hentern",
    "hepberg": "Hepberg",
    "heppenheim-bergstrasse": "Heppenheim/Bergstraße",
    "hepstedt": "Hepstedt",
    "herbertingen": "Herbertingen",
    "herbolzheim": "Herbolzheim",
    "herborn-idar-oberstein": "Herborn b. Idar-Oberstein",
    "herborn-hessen": "Herborn/Hessen",
    "herbrechtingen": "Herbrechtingen",
    "herbsleben": "Herbsleben",
    "herbstadt": "Herbstadt",
    "herbstein": "Herbstein",
    "herbstmuehle": "Herbstmühle",
    "herchweiler": "Herchweiler",
    "herdecke": "Herdecke",
    "herdorf": "Herdorf",
    "herdwangen-schoenach": "Herdwangen-Schönach",
    "heretsried": "Heretsried",
    "herford": "Herford",
    "herforst": "Herforst",
    "hergatz": "Hergatz",
    "hergenfeld": "Hergenfeld",
    "hergenroth": "Hergenroth",
    "hergensweiler": "Hergensweiler",
    "hergersweiler": "Hergersweiler",
    "hergisdorf": "Hergisdorf",
    "heringen-helme": "Heringen/Helme",
    "heringen-werra": "Heringen/Werra",
    "heringsdorf-ostholstein": "Heringsdorf Kr. Ostholstein",
    "herl": "Herl",
    "herleshausen": "Herleshausen",
    "hermaringen": "Hermaringen",
    "hermersberg": "Hermersberg",
    "hermeskeil": "Hermeskeil",
    "hermsdorf-ruhland": "Hermsdorf b. Ruhland",
    "hermsdorf-erzgebirge": "Hermsdorf/Erzgebirge",
    "hermsdorf-thueringen": "Hermsdorf/Thüringen",
    "herne": "Herne",
    "herold-rhein-lahn-kreis": "Herold/Rhein-Lahn-Kreis",
    "heroldishausen": "Heroldishausen",
    "heroldsbach": "Heroldsbach",
    "heroldsberg": "Heroldsberg",
    "heroldstatt": "Heroldstatt",
    "herrenberg": "Herrenberg",
    "herrenhof": "Herrenhof",
    "herren-sulzbach": "Herren-Sulzbach",
    "herresbach": "Herresbach",
    "herrieden": "Herrieden",
    "herrischried": "Herrischried",
    "herrmannsacker": "Herrmannsacker",
    "herrngiersdorf": "Herrngiersdorf",
    "herrnhut": "Herrnhut",
    "herrnschwende": "Herrnschwende",
    "herrsching-am-ammersee": "Herrsching am Ammersee",
    "herrstein": "Herrstein",
    "hersbruck": "Hersbruck",
    "herschbach-selters": "Herschbach b. Selters",
    "herschbach-oberwesterwald": "Herschbach/Oberwesterwald",
    "herschberg": "Herschberg",
    "herschbroich": "Herschbroich",
    "herschdorf-ilmenau": "Herschdorf b. Ilmenau",
    "herscheid": "Herscheid",
    "herschweiler-pettersheim": "Herschweiler-Pettersheim",
    "hersdorf": "Hersdorf",
    "herten": "Herten",
    "herxheim-am-berg": "Herxheim am Berg",
    "herxheim-landau-pfalz": "Herxheim b. Landau/Pfalz",
    "herxheimweyher": "Herxheimweyher",
    "herzberg-am-harz": "Herzberg am Harz",
    "herzberg-elster": "Herzberg/Elster",
    "herzberg-mark": "Herzberg/Mark",
    "herzebrock-clarholz": "Herzebrock-Clarholz",
    "herzfeld-eifel": "Herzfeld/Eifel",
    "herzhorn": "Herzhorn",
    "herzlake": "Herzlake",
    "herzogenaurach": "Herzogenaurach",
    "herzogenrath": "Herzogenrath",
    "hesel": "Hesel",
    "hespe": "Hespe",
    "hessdorf": "Heßdorf",
    "hesseneck": "Hesseneck",
    "hessheim": "Heßheim",
    "hessigheim": "Hessigheim",
    "hessisch-lichtenau": "Hessisch Lichtenau",
    "hessisch-oldendorf": "Hessisch Oldendorf",
    "hesweiler": "Hesweiler",
    "hetlingen": "Hetlingen",
    "hetschburg": "Hetschburg",
    "hettenhausen": "Hettenhausen",
    "hettenleidelheim": "Hettenleidelheim",
    "hettenrodt": "Hettenrodt",
    "hettenshausen": "Hettenshausen",
    "hettingen": "Hettingen",
    "hettstadt": "Hettstadt",
    "hettstedt": "Hettstedt",
    "hetzerath": "Hetzerath",
    "hetzles": "Hetzles",
    "heubach": "Heubach",
    "heuchelheim-giessen": "Heuchelheim a. d. Lahn",
    "heuchelheim-frankenthal": "Heuchelheim b. Frankenthal",
    "heuchelheim-klingen": "Heuchelheim-Klingen",
    "heuchlingen": "Heuchlingen",
    "heuerssen": "Heuerßen",
    "heukewalde": "Heukewalde",
    "heupelzen": "Heupelzen",
    "heusenstamm": "Heusenstamm",
    "heustreu": "Heustreu",
    "heusweiler": "Heusweiler",
    "heuthen": "Heuthen",
    "heuweiler": "Heuweiler",
    "heuzert": "Heuzert",
    "heyen": "Heyen",
    "heyersdorf": "Heyersdorf",
    "heygendorf": "Heygendorf",
    "hiddenhausen": "Hiddenhausen",
    "hilbersdorf-gera": "Hilbersdorf b. Gera",
    "hilchenbach": "Hilchenbach",
    "hildburghausen": "Hildburghausen",
    "hilden": "Hilden",
    "hilders": "Hilders",
    "hildesheim": "Hildesheim",
    "hildrizhausen": "Hildrizhausen",
    "hilgenroth": "Hilgenroth",
    "hilgermissen": "Hilgermissen",
    "hilgert": "Hilgert",
    "hilgertshausen-tandern": "Hilgertshausen-Tandern",
    "hilkenbrook": "Hilkenbrook",
    "hille": "Hille",
    "hillerse": "Hillerse",
    "hillesheim-eifel": "Hillesheim/Eifel",
    "hillesheim-rheinhessen": "Hillesheim/Rheinhessen",
    "hillgroven": "Hillgroven",
    "hillscheid": "Hillscheid",
    "hilpoltstein": "Hilpoltstein",
    "hilscheid": "Hilscheid",
    "hilst": "Hilst",
    "hiltenfingen": "Hiltenfingen",
    "hilter-am-teutoburger-wald": "Hilter am Teutoburger Wald",
    "hiltpoltstein": "Hiltpoltstein",
    "hilzingen": "Hilzingen",
    "himbergen": "Himbergen",
    "himmelkron": "Himmelkron",
    "himmelpforten": "Himmelpforten",
    "himmelstadt": "Himmelstadt",
    "himmighofen": "Himmighofen",
    "hingstheide": "Hingstheide",
    "hinrichshagen-greifswald": "Hinrichshagen b. Greifswald",
    "hinte": "Hinte",
    "hinterschmiding": "Hinterschmiding",
    "hintersee": "Hintersee",
    "hintertiefenbach": "Hintertiefenbach",
    "hinterweidenthal": "Hinterweidenthal",
    "hinterweiler": "Hinterweiler",
    "hinterzarten": "Hinterzarten",
    "hinzenburg": "Hinzenburg",
    "hinzert-poelert": "Hinzert-Pölert",
    "hinzweiler": "Hinzweiler",
    "hipstedt": "Hipstedt",
    "hirrlingen": "Hirrlingen",
    "hirschaid": "Hirschaid",
    "hirschau": "Hirschau",
    "hirschbach": "Hirschbach",
    "hirschberg-bergstrasse": "Hirschberg a.d. Bergstraße",
    "hirschberg-rhein-lahn-kreis": "Hirschberg/Rhein-Lahn-Kreis",
    "hirschberg-saale": "Hirschberg/Saale",
    "hirschfeld-elsterwerda": "Hirschfeld b. Elsterwerda",
    "hirschfeld-gera": "Hirschfeld b. Gera",
    "hirschfeld-zwickau": "Hirschfeld b. Zwickau",
    "hirschfeld-hunsrueck": "Hirschfeld/Hunsrück",
    "hirschhorn-neckar": "Hirschhorn/Neckar",
    "hirschhorn-pfalz": "Hirschhorn/Pfalz",
    "hirschstein": "Hirschstein",
    "hirschthal": "Hirschthal",
    "hirten": "Hirten",
    "hirzenhain": "Hirzenhain",
    "hirz-maulsbach": "Hirz-Maulsbach",
    "hisel": "Hisel",
    "hittbergen": "Hittbergen",
    "hitzacker-elbe": "Hitzacker/Elbe",
    "hitzhofen": "Hitzhofen",
    "hitzhusen": "Hitzhusen",
    "hoechberg": "Höchberg",
    "hochborn": "Hochborn",
    "hochdonn": "Hochdonn",
    "hochdorf-plochingen": "Hochdorf b. Plochingen",
    "hochdorf-riss": "Hochdorf/Riß",
    "hochdorf-assenheim": "Hochdorf-Assenheim",
    "hoechenschwand": "Höchenschwand",
    "hoechheim": "Höchheim",
    "hochheim-am-main": "Hochheim am Main",
    "hochheim-gotha": "Hochheim b. Gotha",
    "hochkirch": "Hochkirch",
    "hochscheid": "Hochscheid",
    "hochspeyer": "Hochspeyer",
    "hoechst-odenwald": "Höchst i. Odenwald",
    "hochstadt-main": "Hochstadt a. Main",
    "hoechstadt-aisch": "Höchstadt a.d. Aisch",
    "hoechstaedt-donau": "Höchstädt a.d. Donau",
    "hoechstaedt-fichtelgebirge": "Höchstädt i. Fichtelgebirge",
    "hochstadt-pfalz": "Hochstadt/Pfalz",
    "hochstaetten": "Hochstätten",
    "hoechstberg": "Höchstberg",
    "hoechstenbach": "Höchstenbach",
    "hochstetten-dhaun": "Hochstetten-Dhaun",
    "hockenheim": "Hockenheim",
    "hockweiler": "Hockweiler",
    "hodenhagen": "Hodenhagen",
    "hodorf": "Hodorf",
    "hof-bayern": "Hof",
    "hof-westerwald": "Hof/Westerwald",
    "hofbieber": "Hofbieber",
    "hoefen-an-der-enz": "Höfen an der Enz",
    "hoffeld-bordesholm": "Hoffeld b. Bordesholm",
    "hoffeld-eifel": "Hoffeld/Eifel",
    "hofgeismar": "Hofgeismar",
    "hofheim-am-taunus": "Hofheim am Taunus",
    "hofheim-unterfranken": "Hofheim/Unterfranken",
    "hofkirchen": "Hofkirchen",
    "hofstetten-landsberg": "Hofstetten Kr. Landsberg",
    "hofstetten-kinzigtal": "Hofstetten/Kinzigtal",
    "hoegel": "Högel",
    "hoegersdorf": "Högersdorf",
    "hoegsdorf": "Högsdorf",
    "hoehbeck": "Höhbeck",
    "hohberg": "Hohberg",
    "hohe-boerde": "Hohe Börde",
    "hoeheinoed": "Höheinöd",
    "hoeheischweiler": "Höheischweiler",
    "hohen-neuendorf": "Hohen Neuendorf",
    "hohen-pritz": "Hohen Pritz",
    "hohen-sprenz": "Hohen Sprenz",
    "hohen-viecheln": "Hohen Viecheln",
    "hohen-wangelin": "Hohen Wangelin",
    "hohenahr": "Hohenahr",
    "hohenaltheim": "Hohenaltheim",
    "hohenaspe": "Hohenaspe",
    "hohenau": "Hohenau",
    "hohenberg-eger": "Hohenberg a.d. Eger",
    "hohenberg-krusemark": "Hohenberg-Krusemark",
    "hohenbocka": "Hohenbocka",
    "hohenbollentin": "Hohenbollentin",
    "hohenbrunn": "Hohenbrunn",
    "hohenbucko": "Hohenbucko",
    "hohenburg": "Hohenburg",
    "hohen-demzin": "Hohen-Demzin",
    "hohendubrau": "Hohendubrau",
    "hohenfelde-bad-doberan": "Hohenfelde b. Bad Doberan",
    "hohenfelde-elmshorn": "Hohenfelde b. Elmshorn",
    "hohenfelde-kiel": "Hohenfelde b. Kiel",
    "hohenfelde-stormarn": "Hohenfelde/Kr. Stormarn",
    "hohenfelden": "Hohenfelden",
    "hohenfels-stockach": "Hohenfels b. Stockach",
    "hohenfels-oberpfalz": "Hohenfels/Oberpfalz",
    "hohenfels-essingen": "Hohenfels-Essingen",
    "hohenfinow": "Hohenfinow",
    "hohenfurch": "Hohenfurch",
    "hohengandern": "Hohengandern",
    "hohenhameln": "Hohenhameln",
    "hohenhorn": "Hohenhorn",
    "hohenkammer": "Hohenkammer",
    "hohenkirchen-gotha": "Hohenkirchen b. Gotha",
    "hohenkirchen-wismar": "Hohenkirchen b. Wismar",
    "hoehenkirchen-siegertsbrunn": "Höhenkirchen-Siegertsbrunn",
    "hoehenland": "Höhenland",
    "hohenleimbach": "Hohenleimbach",
    "hohenleipisch": "Hohenleipisch",
    "hohenleuben": "Hohenleuben",
    "hohenlinden": "Hohenlinden",
    "hohenlockstedt": "Hohenlockstedt",
    "hohenmocker": "Hohenmocker",
    "hohenmoelsen": "Hohenmölsen",
    "hohenoellen": "Hohenöllen",
    "hohenpeissenberg": "Hohenpeißenberg",
    "hohenpolding": "Hohenpolding",
    "hohenroda": "Hohenroda",
    "hohenroth": "Hohenroth",
    "hohenselchow-gross-pinnow": "Hohenselchow-Groß Pinnow",
    "hohenstadt": "Hohenstadt",
    "hohenstein-nordhausen": "Hohenstein b. Nordhausen",
    "hohenstein-untertaunus": "Hohenstein/Untertaunus",
    "hohenstein-wuerttemberg": "Hohenstein/Württemberg",
    "hohenstein-ernstthal": "Hohenstein-Ernstthal",
    "hohen-suelzen": "Hohen-Sülzen",
    "hohentengen-am-hochrhein": "Hohentengen am Hochrhein",
    "hohentengen-saulgau": "Hohentengen b. Saulgau",
    "hohenthann": "Hohenthann",
    "hohenwart": "Hohenwart",
    "hohenwarte": "Hohenwarte",
    "hohenwarth": "Hohenwarth",
    "hohenwestedt": "Hohenwestedt",
    "hohenzieritz": "Hohenzieritz",
    "hohes-kreuz": "Hohes Kreuz",
    "hoehfroeschen": "Höhfröschen",
    "hohn": "Hohn",
    "hoehn": "Höhn",
    "hoehndorf": "Höhndorf",
    "hohndorf-stollberg": "Hohndorf b. Stollberg",
    "hohne": "Hohne",
    "hohnhorst": "Hohnhorst",
    "hohnstein": "Hohnstein",
    "hohnstorf-elbe": "Hohnstorf/Elbe",
    "hoehr-grenzhausen": "Höhr-Grenzhausen",
    "hohwacht-ostsee": "Hohwacht/Ostsee",
    "hoisdorf": "Hoisdorf",
    "holdorf-gadebusch": "Holdorf b. Gadebusch",
    "holdorf-niedersachsen": "Holdorf/Niedersachsen",
    "holenberg": "Holenberg",
    "holldorf": "Holldorf",
    "holle": "Holle",
    "hollenbach-aichach": "Hollenbach b. Aichach",
    "hollenbek": "Hollenbek",
    "hollenstedt": "Hollenstedt",
    "holler": "Holler",
    "hollern-twielenfleth": "Hollern-Twielenfleth",
    "hollfeld": "Hollfeld",
    "hollingstedt-delve": "Hollingstedt b. Delve",
    "hollingstedt-schleswig": "Hollingstedt b. Schleswig",
    "hollnich": "Hollnich",
    "hollnseth": "Hollnseth",
    "hollstadt": "Hollstadt",
    "holm-suederluegum": "Holm b. Süderlügum",
    "holm-pinneberg": "Holm/Kr. Pinneberg",
    "holste": "Holste",
    "holstenniendorf": "Holstenniendorf",
    "holsthum": "Holsthum",
    "holt": "Holt",
    "holtgast": "Holtgast",
    "holthusen": "Holthusen",
    "holtland": "Holtland",
    "holtsee": "Holtsee",
    "holzappel": "Holzappel",
    "holzbach": "Holzbach",
    "holzbunge": "Holzbunge",
    "holzdorf-eckernfoerde": "Holzdorf b. Eckernförde",
    "holzen": "Holzen",
    "holzerath": "Holzerath",
    "holzgerlingen": "Holzgerlingen",
    "holzguenz": "Holzgünz",
    "holzhausen-an-der-haide": "Holzhausen an der Haide",
    "holzheim-forst": "Holzheim a. Forst",
    "holzheim-neu-ulm": "Holzheim b. Neu-Ulm",
    "holzheim-rain": "Holzheim b. Rain",
    "holzheim-dillingen": "Holzheim Kr. Dillingen",
    "holzheim-rhein-lahn-kreis": "Holzheim/Rhein-Lahn-Kreis",
    "holzkirch": "Holzkirch",
    "holzkirchen-oberbayern": "Holzkirchen/Oberbayern",
    "holzkirchen-unterfranken": "Holzkirchen/Unterfranken",
    "holzmaden": "Holzmaden",
    "holzminden": "Holzminden",
    "holzsussra": "Holzsußra",
    "holzwickede": "Holzwickede",
    "hoemberg": "Hömberg",
    "homberg-efze": "Homberg (Efze)",
    "homberg-ohm": "Homberg (Ohm)",
    "homberg-lauterecken": "Homberg b. Lauterecken",
    "homberg-westerwald": "Homberg/Westerwald",
    "homburg": "Homburg",
    "hommerdingen": "Hommerdingen",
    "honerath": "Honerath",
    "honigsee": "Honigsee",
    "hoenningen": "Hönningen",
    "hontheim": "Hontheim",
    "hooge": "Hooge",
    "hoogstede": "Hoogstede",
    "hoort": "Hoort",
    "hopferau": "Hopferau",
    "hopfgarten-weimar": "Hopfgarten b. Weimar",
    "hoepfingen": "Höpfingen",
    "hoppegarten": "Hoppegarten",
    "hoppenrade-guestrow": "Hoppenrade b. Güstrow",
    "hoppstaedten-kusel": "Hoppstädten Kr. Kusel",
    "hoppstaedten-weiersbach": "Hoppstädten-Weiersbach",
    "hopsten": "Hopsten",
    "horath": "Horath",
    "horb-am-neckar": "Horb am Neckar",
    "horbach-simmertal": "Horbach b. Simmertal",
    "horbach-pfalz": "Horbach/Pfalz",
    "horbach-westerwald": "Horbach/Westerwald",
    "horben": "Horben",
    "horbruch": "Horbruch",
    "hoerden": "Hörden am Harz",
    "hoerdt": "Hördt",
    "horgau": "Horgau",
    "horgenzell": "Horgenzell",
    "hoergertshausen": "Hörgertshausen",
    "horhausen-rhein-lahn-kreis": "Horhausen/Rhein-Lahn-Kreis",
    "horhausen-westerwald": "Horhausen/Westerwald",
    "hoeringen": "Höringen",
    "horka": "Horka",
    "horn-hunsrueck": "Horn/Hunsrück",
    "hornbach": "Hornbach",
    "horn-bad-meinberg": "Horn-Bad Meinberg",
    "hornbek": "Hornbek",
    "hornberg": "Hornberg",
    "horneburg": "Horneburg",
    "hornow-wadelsdorf": "Hornow-Wadelsdorf",
    "hornsoemmern": "Hornsömmern",
    "hornstorf": "Hornstorf",
    "hoernum-sylt": "Hörnum/Sylt",
    "horperath": "Horperath",
    "horrweiler": "Horrweiler",
    "horschbach": "Horschbach",
    "hoerscheid": "Hörscheid",
    "hoerschhausen": "Hörschhausen",
    "hoersel": "Hörsel",
    "hoerselberg-hainich": "Hörselberg-Hainich",
    "horst-holstein": "Horst/Holstein",
    "horst-lauenburg": "Horst/Kr. Hzgt. Lauenburg",
    "horstedt-husum": "Horstedt b. Husum",
    "horstedt-rotenburg": "Horstedt Kr. Rotenburg",
    "hoerstel": "Hörstel",
    "hoersten": "Hörsten",
    "horstmar": "Horstmar",
    "hoerup": "Hörup",
    "hoesbach": "Hösbach",
    "hosenfeld": "Hosenfeld",
    "hoeslwang": "Höslwang",
    "hosskirch": "Hoßkirch",
    "hosten": "Hosten",
    "hoetensleben": "Hötensleben",
    "hottenbach": "Hottenbach",
    "hoettingen": "Höttingen",
    "hoevede": "Hövede",
    "hoevelhof": "Hövelhof",
    "hoevels": "Hövels",
    "hoexter": "Höxter",
    "hoya": "Hoya",
    "hoyerhagen": "Hoyerhagen",
    "hoyershausen": "Hoyershausen",
    "hoyerswerda": "Hoyerswerda",
    "huebingen": "Hübingen",
    "hueblingen": "Hüblingen",
    "hueckelhoven": "Hückelhoven",
    "hueckeswagen": "Hückeswagen",
    "huede": "Hüde",
    "hude-oldenburg": "Hude (Oldb)",
    "hude-husum": "Hude b. Husum",
    "hueffelsheim": "Hüffelsheim",
    "hueffenhardt": "Hüffenhardt",
    "hueffler": "Hüffler",
    "huefingen": "Hüfingen",
    "huegelsheim": "Hügelsheim",
    "huglfing": "Huglfing",
    "hugoldsdorf": "Hugoldsdorf",
    "huisheim": "Huisheim",
    "huje": "Huje",
    "huelben": "Hülben",
    "huellhorst": "Hüllhorst",
    "huelseburg": "Hülseburg",
    "huelsede": "Hülsede",
    "huemmel": "Hümmel",
    "hummelfeld": "Hummelfeld",
    "hummelshain": "Hummelshain",
    "hummeltal": "Hummeltal",
    "huemmerich": "Hümmerich",
    "huempfershausen": "Hümpfershausen",
    "humptrup": "Humptrup",
    "hunderdorf": "Hunderdorf",
    "hundeshagen": "Hundeshagen",
    "hundhaupten": "Hundhaupten",
    "hunding": "Hunding",
    "hundsangen": "Hundsangen",
    "hundsbach": "Hundsbach",
    "hundsdorf": "Hundsdorf",
    "huenfeld": "Hünfeld",
    "huenfelden": "Hünfelden",
    "hungen": "Hungen",
    "hungenroth": "Hungenroth",
    "huenstetten": "Hünstetten",
    "huenxe": "Hünxe",
    "hunzel": "Hunzel",
    "hupperath": "Hupperath",
    "hurlach": "Hurlach",
    "huertgenwald": "Hürtgenwald",
    "huerth": "Hürth",
    "huerup": "Hürup",
    "husby": "Husby",
    "huesby": "Hüsby",
    "husum-nienburg": "Husum Kr. Nienburg",
    "husum-nordsee": "Husum/Nordsee",
    "huetschenhausen": "Hütschenhausen",
    "huettblek": "Hüttblek",
    "huetten-ascheffel": "Hütten b. Ascheffel",
    "huetten-eifel": "Hütten/Eifel",
    "huettenberg": "Hüttenberg",
    "huetterscheid": "Hütterscheid",
    "hutthurm": "Hutthurm",
    "huettingen-an-der-kyll": "Hüttingen an der Kyll",
    "huettingen-lahr": "Hüttingen b. Lahr",
    "huettisheim": "Hüttisheim",
    "huettlingen": "Hüttlingen",
    "hueven": "Hüven",
    "huy": "Huy",
    "ibach": "Ibach",
    "ibbenbueren": "Ibbenbüren",
    "ichenhausen": "Ichenhausen",
    "ichstedt": "Ichstedt",
    "ichtershausen": "Ichtershausen",
    "icking": "Icking",
    "idar-oberstein": "Idar-Oberstein",
    "idelberg": "Idelberg",
    "iden": "Iden",
    "idenheim": "Idenheim",
    "idesheim": "Idesheim",
    "idstedt": "Idstedt",
    "idstein": "Idstein",
    "iffeldorf": "Iffeldorf",
    "iffezheim": "Iffezheim",
    "ifta": "Ifta",
    "igel": "Igel",
    "igensdorf": "Igensdorf",
    "igersheim": "Igersheim",
    "iggensbach": "Iggensbach",
    "iggingen": "Iggingen",
    "igling": "Igling",
    "ihlienworth": "Ihlienworth",
    "ihlow-jueterbog": "Ihlow/Jüterbog",
    "ihlow-ostfriesland": "Ihlow/Ostfriesland",
    "ihringen": "Ihringen",
    "ihrlerstein": "Ihrlerstein",
    "ilberstedt": "Ilberstedt",
    "ilbesheim-kirchheimbolanden": "Ilbesheim b. Kirchheimbolanden",
    "ilbesheim-landau-pfalz": "Ilbesheim b. Landau/Pfalz",
    "illerich": "Illerich",
    "illerkirchberg": "Illerkirchberg",
    "illerrieden": "Illerrieden",
    "illertissen": "Illertissen",
    "illesheim": "Illesheim",
    "illingen-saar": "Illingen/Saar",
    "illingen-wuerttemberg": "Illingen/Württemberg",
    "illmensee": "Illmensee",
    "illschwang": "Illschwang",
    "ilmenau": "Ilmenau",
    "ilmmuenster": "Ilmmünster",
    "ilmtal": "Ilmtal",
    "ilmtal-weinstrasse": "Ilmtal-Weinstraße",
    "ilsede": "Ilsede",
    "ilsenburg-harz": "Ilsenburg (Harz)",
    "ilsfeld": "Ilsfeld",
    "ilshofen": "Ilshofen",
    "ilvesheim": "Ilvesheim",
    "immendingen": "Immendingen",
    "immenhausen": "Immenhausen",
    "immenreuth": "Immenreuth",
    "immenstaad-am-bodensee": "Immenstaad am Bodensee",
    "immenstadt-allgaeu": "Immenstadt i. Allgäu",
    "immenstedt-albersdorf": "Immenstedt b. Albersdorf",
    "immenstedt-nordfriesland": "Immenstedt/Nordfriesland",
    "immerath": "Immerath",
    "immert": "Immert",
    "immesheim": "Immesheim",
    "impflingen": "Impflingen",
    "imsbach": "Imsbach",
    "imsweiler": "Imsweiler",
    "inchenhofen": "Inchenhofen",
    "inden": "Inden",
    "ingelbach": "Ingelbach",
    "ingeleben": "Ingeleben",
    "ingelfingen": "Ingelfingen",
    "ingelheim-am-rhein": "Ingelheim am Rhein",
    "ingendorf": "Ingendorf",
    "ingenried": "Ingenried",
    "ingersheim": "Ingersheim",
    "ingersleben": "Ingersleben",
    "ingoldingen": "Ingoldingen",
    "ingolstadt": "Ingolstadt",
    "innernzell": "Innernzell",
    "inning-holz": "Inning a. Holz",
    "inning-am-ammersee": "Inning am Ammersee",
    "insel-hiddensee": "Insel Hiddensee",
    "insel-poel": "Insel Poel",
    "insheim": "Insheim",
    "insingen": "Insingen",
    "insul": "Insul",
    "inzell": "Inzell",
    "inzigkofen": "Inzigkofen",
    "inzlingen": "Inzlingen",
    "iphofen": "Iphofen",
    "ippenschied": "Ippenschied",
    "ippesheim": "Ippesheim",
    "ipsheim": "Ipsheim",
    "irchenrieth": "Irchenrieth",
    "irlbach": "Irlbach",
    "irmenach": "Irmenach",
    "irmtraut": "Irmtraut",
    "irndorf": "Irndorf",
    "irrel": "Irrel",
    "irrhausen": "Irrhausen",
    "irsch": "Irsch",
    "irschenberg": "Irschenberg",
    "irsee": "Irsee",
    "isen": "Isen",
    "isenburg": "Isenburg",
    "isenbuettel": "Isenbüttel",
    "iserlohn": "Iserlohn",
    "isernhagen": "Isernhagen",
    "isert": "Isert",
    "ismaning": "Ismaning",
    "isny-im-allgaeu": "Isny im Allgäu",
    "ispringen": "Ispringen",
    "isselbach": "Isselbach",
    "isselburg": "Isselburg",
    "isseroda": "Isseroda",
    "issersheilingen": "Issersheilingen",
    "issigau": "Issigau",
    "issum": "Issum",
    "isterberg": "Isterberg",
    "itterbeck": "Itterbeck",
    "ittlingen": "Ittlingen",
    "itzehoe": "Itzehoe",
    "itzgrund": "Itzgrund",
    "itzstedt": "Itzstedt",
    "iven": "Iven",
    "ivenack": "Ivenack",
    "jabel-waren": "Jabel b. Waren",
    "jachenau": "Jachenau",
    "jacobsdorf": "Jacobsdorf",
    "jade": "Jade",
    "jagel": "Jagel",
    "jagsthausen": "Jagsthausen",
    "jagstzell": "Jagstzell",
    "jahnsdorf-erzge": "Jahnsdorf/Erzgeb.",
    "jahrsdorf": "Jahrsdorf",
    "jakobsdorf": "Jakobsdorf",
    "jakobsweiler": "Jakobsweiler",
    "jameln": "Jameln",
    "jamlitz": "Jamlitz",
    "jaemlitz-klein-dueben": "Jämlitz-Klein Düben",
    "jandelsbrunn": "Jandelsbrunn",
    "janneby": "Janneby",
    "jaenschwalde": "Jänschwalde",
    "jardelund": "Jardelund",
    "jarmen": "Jarmen",
    "jatznick": "Jatznick",
    "jeckenbach": "Jeckenbach",
    "jelmstorf": "Jelmstorf",
    "jembke": "Jembke",
    "jemgum": "Jemgum",
    "jena": "Jena",
    "jenaloebnitz": "Jenalöbnitz",
    "jengen": "Jengen",
    "jerichow": "Jerichow",
    "jerrishoe": "Jerrishoe",
    "jersbek": "Jersbek",
    "jerxheim": "Jerxheim",
    "jesberg": "Jesberg",
    "jesendorf": "Jesendorf",
    "jesenwang": "Jesenwang",
    "jesewitz": "Jesewitz",
    "jessen-elster": "Jessen (Elster)",
    "jesteburg": "Jesteburg",
    "jestetten": "Jestetten",
    "jettenbach-oberbayern": "Jettenbach/Oberbayern",
    "jettenbach-pfalz": "Jettenbach/Pfalz",
    "jettingen-boeblingen": "Jettingen Kr. Böblingen",
    "jettingen-scheppach": "Jettingen-Scheppach",
    "jetzendorf": "Jetzendorf",
    "jevenstedt": "Jevenstedt",
    "jever": "Jever",
    "joachimsthal": "Joachimsthal",
    "jockgrim": "Jockgrim",
    "johannesberg": "Johannesberg",
    "johanngeorgenstadt": "Johanngeorgenstadt",
    "johanniskirchen": "Johanniskirchen",
    "joehstadt": "Jöhstadt",
    "joldelund": "Joldelund",
    "jonaswalde": "Jonaswalde",
    "joerdenstorf": "Jördenstorf",
    "jork": "Jork",
    "joerl": "Jörl",
    "jossgrund": "Jossgrund",
    "juebar": "Jübar",
    "juebek": "Jübek",
    "juechen": "Jüchen",
    "jueckelberg": "Jückelberg",
    "jucken": "Jucken",
    "judenbach": "Judenbach",
    "jugenheim-in-rheinhessen": "Jugenheim in Rheinhessen",
    "juehnde": "Jühnde",
    "juist": "Juist",
    "julbach": "Julbach",
    "juelich": "Jülich",
    "juliusburg": "Juliusburg",
    "jungingen": "Jungingen",
    "juenkerath": "Jünkerath",
    "juergenshagen": "Jürgenshagen",
    "juergenstorf": "Jürgenstorf",
    "jueterbog": "Jüterbog",
    "kaaks": "Kaaks",
    "kaarst": "Kaarst",
    "kabelhorst": "Kabelhorst",
    "kabelsketal": "Kabelsketal",
    "kaebschuetztal": "Käbschütztal",
    "kaden": "Kaden",
    "kadenbach": "Kadenbach",
    "kahl-main": "Kahl a. Main",
    "kahla-thueringen": "Kahla/Thüringen",
    "kaifenheim": "Kaifenheim",
    "kail": "Kail",
    "kaisborstel": "Kaisborstel",
    "kaiserpfalz": "Kaiserpfalz",
    "kaisersbach": "Kaisersbach",
    "kaisersesch": "Kaisersesch",
    "kaiserslautern": "Kaiserslautern",
    "kaiser-wilhelm-koog": "Kaiser-Wilhelm-Koog",
    "kaisheim": "Kaisheim",
    "kakenstorf": "Kakenstorf",
    "kalbach": "Kalbach",
    "kalbe-sittensen": "Kalbe b. Sittensen",
    "kalbe-milde": "Kalbe/Milde",
    "kalbsrieth": "Kalbsrieth",
    "kalchreuth": "Kalchreuth",
    "kalefeld": "Kalefeld",
    "kalenborn-kaisersesch": "Kalenborn b. Kaisersesch",
    "kalenborn-ahrweiler": "Kalenborn Kr. Ahrweiler",
    "kalenborn-scheuern": "Kalenborn-Scheuern",
    "kalkar": "Kalkar",
    "kalkhorst": "Kalkhorst",
    "kalkofen": "Kalkofen",
    "kall": "Kall",
    "kalletal": "Kalletal",
    "kallmerode": "Kallmerode",
    "kallmuenz": "Kallmünz",
    "kallstadt": "Kallstadt",
    "kalt": "Kalt",
    "kaltenborn": "Kaltenborn",
    "kaltenengers": "Kaltenengers",
    "kaltenholzhausen": "Kaltenholzhausen",
    "kaltenkirchen": "Kaltenkirchen",
    "kaltennordheim": "Kaltennordheim",
    "kaltensundheim": "Kaltensundheim",
    "kaltental": "Kaltental",
    "kaltenwestheim": "Kaltenwestheim",
    "kaluebbe": "Kalübbe",
    "kamen": "Kamen",
    "kamenz": "Kamenz",
    "kamern": "Kamern",
    "kammeltal": "Kammeltal",
    "kammerforst-thueringen": "Kammerforst/Thüringen",
    "kammerforst-westerwald": "Kammerforst/Westerwald",
    "kammerstein": "Kammerstein",
    "kamminke": "Kamminke",
    "kammlach": "Kammlach",
    "kamp-bornhofen": "Kamp-Bornhofen",
    "kampen-sylt": "Kampen/Sylt",
    "kaempfelbach": "Kämpfelbach",
    "kamp-lintfort": "Kamp-Lintfort",
    "kamsdorf": "Kamsdorf",
    "kandel": "Kandel",
    "kandern": "Kandern",
    "kankelau": "Kankelau",
    "kannawurf": "Kannawurf",
    "kanzach": "Kanzach",
    "kanzem": "Kanzem",
    "kapellendorf": "Kapellendorf",
    "kapellen-drusweiler": "Kapellen-Drusweiler",
    "kaperich": "Kaperich",
    "kappel-hunsrueck": "Kappel/Hunsrück",
    "kappel-grafenhausen": "Kappel-Grafenhausen",
    "kappeln-lauterecken": "Kappeln b. Lauterecken",
    "kappeln-schlei": "Kappeln/Schlei",
    "kappelrodeck": "Kappelrodeck",
    "kapsweyer": "Kapsweyer",
    "karbach-hunsrueck": "Karbach/Hunsrück",
    "karbach-unterfranken": "Karbach/Unterfranken",
    "karben": "Karben",
    "karby": "Karby",
    "karenz": "Karenz",
    "kargow": "Kargow",
    "karl": "Karl",
    "karlsbad": "Karlsbad",
    "karlsburg": "Karlsburg",
    "karlsdorf-saale-holzland-kreis": "Karlsdorf/Saale-Holzland-Kreis",
    "karlsdorf-neuthard": "Karlsdorf-Neuthard",
    "karlsfeld": "Karlsfeld",
    "karlshagen": "Karlshagen",
    "karlshausen": "Karlshausen",
    "karlshuld": "Karlshuld",
    "karlskron": "Karlskron",
    "karlsruhe": "Karlsruhe",
    "karlstadt": "Karlstadt",
    "karlstein-main": "Karlstein a. Main",
    "karlum": "Karlum",
    "karnin": "Karnin",
    "karolinenkoog": "Karolinenkoog",
    "karrenzin": "Karrenzin",
    "karsbach": "Karsbach",
    "karsdorf": "Karsdorf",
    "karstaedt-ludwigslust": "Karstädt b. Ludwigslust",
    "karstaedt-prignitz": "Karstädt/Prignitz",
    "karwitz": "Karwitz",
    "kasbach-ohlenberg": "Kasbach-Ohlenberg",
    "kaschenbach": "Kaschenbach",
    "kasdorf": "Kasdorf",
    "kasel-trier": "Kasel b.Trier",
    "kasel-golzig": "Kasel-Golzig",
    "kasendorf": "Kasendorf",
    "kaeshofen": "Käshofen",
    "kasseburg": "Kasseburg",
    "kasseedorf": "Kasseedorf",
    "kassel-documenta-stadt": "Kassel",
    "kassow": "Kassow",
    "kastellaun": "Kastellaun",
    "kastel-staadt": "Kastel-Staadt",
    "kastl-amberg": "Kastl b. Amberg",
    "kastl-kemnath": "Kastl b. Kemnath",
    "kastl-altoetting": "Kastl Kr. Altötting",
    "kastorf": "Kastorf",
    "katharinenheerd": "Katharinenheerd",
    "katlenburg-lindau": "Katlenburg-Lindau",
    "kattendorf": "Kattendorf",
    "katzenbach": "Katzenbach",
    "katzenelnbogen": "Katzenelnbogen",
    "katzhuette": "Katzhütte",
    "katzow": "Katzow",
    "katzweiler": "Katzweiler",
    "katzwinkel-eifel": "Katzwinkel/Eifel",
    "katzwinkel-sieg": "Katzwinkel/Sieg",
    "kaub": "Kaub",
    "kauern": "Kauern",
    "kaufbeuren": "Kaufbeuren",
    "kaufering": "Kaufering",
    "kaufungen": "Kaufungen",
    "kaulsdorf": "Kaulsdorf",
    "kausen": "Kausen",
    "kayhude": "Kayhude",
    "kefenrod": "Kefenrod",
    "kefferhausen": "Kefferhausen",
    "kehl": "Kehl",
    "kehlbach": "Kehlbach",
    "kehmstedt": "Kehmstedt",
    "kehrig": "Kehrig",
    "keidelheim": "Keidelheim",
    "keila": "Keila",
    "kelberg": "Kelberg",
    "kelbra-kyffhaeuser": "Kelbra/Kyffhäuser",
    "kelheim": "Kelheim",
    "kelkheim-taunus": "Kelkheim/Taunus",
    "kell-am-see": "Kell am See",
    "kella": "Kella",
    "kellenbach": "Kellenbach",
    "kellenhusen-ostsee": "Kellenhusen/Ostsee",
    "kellinghusen": "Kellinghusen",
    "kellmuenz-iller": "Kellmünz a.d. Iller",
    "kelsterbach": "Kelsterbach",
    "keltern": "Keltern",
    "kemberg": "Kemberg",
    "kemmenau": "Kemmenau",
    "kemmern": "Kemmern",
    "kemnath": "Kemnath",
    "kemnitz-greifswald": "Kemnitz b. Greifswald",
    "kempen": "Kempen",
    "kempenich": "Kempenich",
    "kempfeld": "Kempfeld",
    "kempten-allgaeu": "Kempten (Allgäu)",
    "kenn": "Kenn",
    "kentzlin": "Kentzlin",
    "kenzingen": "Kenzingen",
    "kenz-kuestrow": "Kenz-Küstrow",
    "keppeshausen": "Keppeshausen",
    "kerben": "Kerben",
    "kerken": "Kerken",
    "kernen-im-remstal": "Kernen im Remstal",
    "kerpen-eifel": "Kerpen/Eifel",
    "kerpen-rhein-erft": "Kerpen/Rhein-Erft",
    "kerschenbach": "Kerschenbach",
    "kerzenheim": "Kerzenheim",
    "kescheid": "Kescheid",
    "kesfeld": "Kesfeld",
    "kesseling": "Kesseling",
    "kesten": "Kesten",
    "kestert": "Kestert",
    "ketsch": "Ketsch",
    "kettenhausen": "Kettenhausen",
    "kettenheim": "Kettenheim",
    "kettenkamp": "Kettenkamp",
    "kettershausen": "Kettershausen",
    "kettig": "Kettig",
    "ketzin": "Ketzin/Havel",
    "kevelaer": "Kevelaer",
    "kickeshausen": "Kickeshausen",
    "kiebitzreihe": "Kiebitzreihe",
    "kiedrich": "Kiedrich",
    "kiefersfelden": "Kiefersfelden",
    "kiel": "Kiel",
    "kienberg-oberbayern": "Kienberg/Oberbayern",
    "kierspe": "Kierspe",
    "kieselbronn": "Kieselbronn",
    "kieve": "Kieve",
    "kiliansroda": "Kiliansroda",
    "kindelbrueck": "Kindelbrück",
    "kindenheim": "Kindenheim",
    "kinderbeuern": "Kinderbeuern",
    "kinding": "Kinding",
    "kindsbach": "Kindsbach",
    "kinheim": "Kinheim",
    "kinsau": "Kinsau",
    "kinzenburg": "Kinzenburg",
    "kipfenberg": "Kipfenberg",
    "kippenheim": "Kippenheim",
    "kirburg": "Kirburg",
    "kirch-jesar": "Kirch Jesar",
    "kirch-mulsow": "Kirch Mulsow",
    "kirchanschoering": "Kirchanschöring",
    "kirchardt": "Kirchardt",
    "kirchbarkau": "Kirchbarkau",
    "kirchberg-an-der-iller": "Kirchberg an der Iller",
    "kirchberg-an-der-jagst": "Kirchberg an der Jagst",
    "kirchberg-an-der-murr": "Kirchberg an der Murr",
    "kirchberg-holzland": "Kirchberg/Holzland",
    "kirchberg-hunsrueck": "Kirchberg/Hunsrück",
    "kirchberg-sachsen": "Kirchberg/Sachsen",
    "kirchberg-wald": "Kirchberg/Wald",
    "kirchbrak": "Kirchbrak",
    "kirchdorf-inn": "Kirchdorf a. Inn",
    "kirchdorf-amper": "Kirchdorf a.d. Amper",
    "kirchdorf-an-der-iller": "Kirchdorf an der Iller",
    "kirchdorf-haag": "Kirchdorf b. Haag",
    "kirchdorf-sulingen": "Kirchdorf b. Sulingen",
    "kirchdorf-wald": "Kirchdorf i. Wald",
    "kirchdorf-kelheim": "Kirchdorf Kr. Kelheim",
    "kirchehrenbach": "Kirchehrenbach",
    "kircheib": "Kircheib",
    "kirchen-sieg": "Kirchen (Sieg)",
    "kirchendemenreuth": "Kirchendemenreuth",
    "kirchenlamitz": "Kirchenlamitz",
    "kirchenpingarten": "Kirchenpingarten",
    "kirchensittenbach": "Kirchensittenbach",
    "kirchentellinsfurt": "Kirchentellinsfurt",
    "kirchenthumbach": "Kirchenthumbach",
    "kirchgandern": "Kirchgandern",
    "kirchgellersen": "Kirchgellersen",
    "kirchhain": "Kirchhain",
    "kirchham": "Kirchham",
    "kirchhaslach": "Kirchhaslach",
    "kirchheilingen": "Kirchheilingen",
    "kirchheim-weinstrasse": "Kirchheim a.d. Weinstraße",
    "kirchheim-am-neckar": "Kirchheim am Neckar",
    "kirchheim-am-ries": "Kirchheim am Ries",
    "kirchheim-arnstadt": "Kirchheim b. Arnstadt",
    "kirchheim-muenchen": "Kirchheim b. München",
    "kirchheim-unter-teck": "Kirchheim unter Teck",
    "kirchheim-hessen": "Kirchheim/Hessen",
    "kirchheim-schwaben": "Kirchheim/Schwaben",
    "kirchheim-unterfranken": "Kirchheim/Unterfranken",
    "kirchheimbolanden": "Kirchheimbolanden",
    "kirchhundem": "Kirchhundem",
    "kirchlauter": "Kirchlauter",
    "kirchlengern": "Kirchlengern",
    "kirchlinteln": "Kirchlinteln",
    "kirchnuechel": "Kirchnüchel",
    "kirchroth": "Kirchroth",
    "kirchsahr": "Kirchsahr",
    "kirchseelte": "Kirchseelte",
    "kirchseeon": "Kirchseeon",
    "garding-kirchspiel": "Kirchspiel Garding",
    "kirchtimke": "Kirchtimke",
    "kirchwald": "Kirchwald",
    "kirchwalsede": "Kirchwalsede",
    "kirchweidach": "Kirchweidach",
    "kirchweiler": "Kirchweiler",
    "kirchworbis": "Kirchworbis",
    "kirchzarten": "Kirchzarten",
    "kirchzell": "Kirchzell",
    "kirf": "Kirf",
    "kirkel": "Kirkel",
    "kirn": "Kirn",
    "kirnitzschtal": "Kirnitzschtal",
    "kirrweiler-lauterecken": "Kirrweiler b. Lauterecken",
    "kirrweiler-pfalz": "Kirrweiler/Pfalz",
    "kirsbach": "Kirsbach",
    "kirschkau": "Kirschkau",
    "kirschroth": "Kirschroth",
    "kirschweiler": "Kirschweiler",
    "kirtorf": "Kirtorf",
    "kisdorf": "Kisdorf",
    "kisselbach": "Kisselbach",
    "kissenbrueck": "Kissenbrück",
    "kissing": "Kissing",
    "kisslegg": "Kißlegg",
    "kist": "Kist",
    "kittendorf": "Kittendorf",
    "kittlitz-ratzeburg": "Kittlitz b. Ratzeburg",
    "kitzingen": "Kitzingen",
    "kitzscher": "Kitzscher",
    "klamp": "Klamp",
    "klanxbuell": "Klanxbüll",
    "klappholz": "Klappholz",
    "klausdorf-stralsund": "Klausdorf b. Stralsund",
    "klausen": "Klausen",
    "klein-barkau": "Klein Barkau",
    "klein-belitz": "Klein Belitz",
    "klein-bennebek": "Klein Bennebek",
    "klein-berssen": "Klein Berßen",
    "klein-buenzow": "Klein Bünzow",
    "klein-gladebruegge": "Klein Gladebrügge",
    "klein-kussewitz": "Klein Kussewitz",
    "klein-meckelsen": "Klein Meckelsen",
    "klein-nordende": "Klein Nordende",
    "klein-offenseth-sparrieshoop": "Klein Offenseth-Sparrieshoop",
    "klein-pampau": "Klein Pampau",
    "klein-rheide": "Klein Rheide",
    "klein-rogahn": "Klein Rogahn",
    "klein-roennau": "Klein Rönnau",
    "klein-trebbow": "Klein Trebbow",
    "klein-upahl": "Klein Upahl",
    "klein-vielen": "Klein Vielen",
    "klein-wesenberg": "Klein Wesenberg",
    "klein-wittensee": "Klein Wittensee",
    "klein-zecher": "Klein Zecher",
    "kleinaitingen": "Kleinaitingen",
    "kleinbartloff": "Kleinbartloff",
    "kleinblittersdorf": "Kleinblittersdorf",
    "kleinbockedra": "Kleinbockedra",
    "kleinbodungen": "Kleinbodungen",
    "kleinbrembach": "Kleinbrembach",
    "kleinbundenbach": "Kleinbundenbach",
    "kleinebersdorf": "Kleinebersdorf",
    "kleines-wiesental": "Kleines Wiesental",
    "kleineutersdorf": "Kleineutersdorf",
    "kleinfischlingen": "Kleinfischlingen",
    "kleinfurra": "Kleinfurra",
    "kleinheubach": "Kleinheubach",
    "kleinich": "Kleinich",
    "kleinkahl": "Kleinkahl",
    "kleinkarlbach": "Kleinkarlbach",
    "kleinlangenfeld": "Kleinlangenfeld",
    "kleinlangheim": "Kleinlangheim",
    "kleinmachnow": "Kleinmachnow",
    "kleinmaischeid": "Kleinmaischeid",
    "kleinmoelsen": "Kleinmölsen",
    "kleinneuhausen": "Kleinneuhausen",
    "kleinniedesheim": "Kleinniedesheim",
    "kleinobringen": "Kleinobringen",
    "kleinostheim": "Kleinostheim",
    "kleinrinderfeld": "Kleinrinderfeld",
    "kleinschwabhausen": "Kleinschwabhausen",
    "kleinsendelbach": "Kleinsendelbach",
    "kleinsteinhausen": "Kleinsteinhausen",
    "kleinwallstadt": "Kleinwallstadt",
    "kleinwelsbach": "Kleinwelsbach",
    "klein-winternheim": "Klein-Winternheim",
    "klempau": "Klempau",
    "klessen-goerne": "Kleßen-Görne",
    "kletkamp": "Kletkamp",
    "klettbach": "Klettbach",
    "klettgau": "Klettgau",
    "klettstedt": "Klettstedt",
    "kletzin": "Kletzin",
    "kleve-itzehoe": "Kleve b. Itzehoe",
    "kleve-dithmarschen": "Kleve/Dithmarschen",
    "kleve-rhein": "Kleve/Niederrhein",
    "kliding": "Kliding",
    "klietz": "Klietz",
    "klingelbach": "Klingelbach",
    "klingenberg": "Klingenberg",
    "klingenberg-main": "Klingenberg a. Main",
    "klingenmuenster": "Klingenmünster",
    "klingenthal": "Klingenthal",
    "klink": "Klink",
    "klinkrade": "Klinkrade",
    "klipphausen": "Klipphausen",
    "klixbuell": "Klixbüll",
    "klocksin": "Klocksin",
    "kloster-lehnin": "Kloster Lehnin",
    "kloster-tempzin": "Kloster Tempzin",
    "kloster-vessra": "Kloster Veßra",
    "klosterkumbd": "Klosterkumbd",
    "klosterlechfeld": "Klosterlechfeld",
    "klostermansfeld": "Klostermansfeld",
    "klotten": "Klotten",
    "kloetze": "Klötze",
    "kludenbach": "Kludenbach",
    "kluis": "Kluis",
    "kluse": "Kluse",
    "kluesserath": "Klüsserath",
    "kluetz": "Klütz",
    "knau": "Knau",
    "kneese": "Kneese",
    "kneitlingen": "Kneitlingen",
    "knetzgau": "Knetzgau",
    "knittelsheim": "Knittelsheim",
    "knittlingen": "Knittlingen",
    "knopp-labach": "Knopp-Labach",
    "knoeringen": "Knöringen",
    "knorrendorf": "Knorrendorf",
    "knuellwald": "Knüllwald",
    "koberg": "Koberg",
    "kobern-gondorf": "Kobern-Gondorf",
    "koblentz": "Koblentz",
    "koblenz": "Koblenz",
    "kobrow": "Kobrow",
    "kochel-see": "Kochel a. See",
    "koedderitzsch": "Ködderitzsch",
    "kodersdorf": "Kodersdorf",
    "koeditz": "Köditz",
    "koednitz": "Ködnitz",
    "koefering": "Köfering",
    "kogel-zarretin": "Kogel b. Zarretin",
    "kohlberg-oberpfalz": "Kohlberg/Oberpfalz",
    "kohlberg-wuerttemberg": "Kohlberg/Württemberg",
    "koehn": "Köhn",
    "kohren-sahlis": "Kohren-Sahlis",
    "kolbermoor": "Kolbermoor",
    "kolbingen": "Kolbingen",
    "koelbingen": "Kölbingen",
    "koldenbuettel": "Koldenbüttel",
    "kolitzheim": "Kolitzheim",
    "kolkerheide": "Kolkerheide",
    "kolkwitz-niederlausitz": "Kolkwitz (Niederlausitz)",
    "koelleda": "Kölleda",
    "kollig": "Kollig",
    "kollmar": "Kollmar",
    "kollmoor": "Kollmoor",
    "kollnburg": "Kollnburg",
    "koelln-reisiek": "Kölln-Reisiek",
    "kollow": "Kollow",
    "kollweiler": "Kollweiler",
    "koeln": "Köln",
    "kolverath": "Kolverath",
    "kommen": "Kommen",
    "koengen": "Köngen",
    "koengernheim": "Köngernheim",
    "koenigheim": "Königheim",
    "koenigs-wusterhausen": "Königs Wusterhausen",
    "koenigsau": "Königsau",
    "koenigsbach-stein": "Königsbach-Stein",
    "koenigsberg-bayern": "Königsberg/Bayern",
    "koenigsbronn": "Königsbronn",
    "koenigsbrueck": "Königsbrück",
    "koenigsbrunn": "Königsbrunn",
    "koenigsdorf": "Königsdorf",
    "koenigsee-rottenbach": "Königsee",
    "koenigseggwald": "Königseggwald",
    "koenigsfeld": "Königsfeld",
    "koenigsfeld-rochlitz": "Königsfeld b. Rochlitz",
    "koenigsfeld-im-schwarzwald": "Königsfeld im Schwarzwald",
    "koenigsfeld-eifel": "Königsfeld/Eifel",
    "koenigsfeld-oberfranken": "Königsfeld/Oberfranken",
    "koenigshain-goerlitz": "Königshain b. Görlitz",
    "koenigshain-wiederau": "Königshain-Wiederau",
    "koenigsheim": "Königsheim",
    "koenigshuegel": "Königshügel",
    "koenigslutter-am-elm": "Königslutter am Elm",
    "koenigsmoor": "Königsmoor",
    "koenigsmoos": "Königsmoos",
    "koenigstein-im-taunus": "Königstein im Taunus",
    "koenigstein-oberpfalz": "Königstein/Oberpfalz",
    "koenigstein-saechsische-schweiz": "Königstein/Sächsische Schweiz",
    "koenigswalde": "Königswalde",
    "koenigswartha": "Königswartha",
    "koenigswinter": "Königswinter",
    "konken": "Konken",
    "koennern": "Könnern",
    "konnersreuth": "Konnersreuth",
    "konradsreuth": "Konradsreuth",
    "konstanz": "Konstanz",
    "konz": "Konz",
    "konzell": "Konzell",
    "kopp": "Kopp",
    "korb": "Korb",
    "korbach": "Korbach",
    "koerborn": "Körborn",
    "korbussen": "Korbußen",
    "kordel": "Kordel",
    "koerdorf": "Kördorf",
    "koerle": "Körle",
    "korlingen": "Korlingen",
    "koerner": "Körner",
    "korntal-muenchingen": "Korntal-Münchingen",
    "kornwestheim": "Kornwestheim",
    "koerperich": "Körperich",
    "korschenbroich": "Korschenbroich",
    "korswandt": "Korswandt",
    "korweiler": "Korweiler",
    "koesching": "Kösching",
    "kosel": "Kosel",
    "koserow": "Koserow",
    "kospoda": "Kospoda",
    "koesslarn": "Kößlarn",
    "koethel-kr-hzgt-lauenburg": "Köthel/Kr Hzgt Lauenburg",
    "koethel-stormarn": "Köthel/Kr. Stormarn",
    "koethen-anhalt": "Köthen (Anhalt)",
    "kottenborn": "Kottenborn",
    "kottenheim": "Kottenheim",
    "koetterichen": "Kötterichen",
    "kottgeisering": "Kottgeisering",
    "kottmar": "Kottmar",
    "kottweiler-schwanden": "Kottweiler-Schwanden",
    "koetz": "Kötz",
    "kotzen": "Kotzen",
    "kotzenbuell": "Kotzenbüll",
    "koewerich": "Köwerich",
    "koxhausen": "Koxhausen",
    "kraam": "Kraam",
    "krackow": "Krackow",
    "kradenbach": "Kradenbach",
    "kraftisried": "Kraftisried",
    "kraftsdorf": "Kraftsdorf",
    "kraehenberg": "Krähenberg",
    "kraiburg-inn": "Kraiburg a. Inn",
    "kraichtal": "Kraichtal",
    "krailling": "Krailling",
    "kraja": "Kraja",
    "krakow-am-see": "Krakow am See",
    "kramerhof": "Kramerhof",
    "kranenburg-niederelbe": "Kranenburg/Niederelbe",
    "kranenburg-niederrhein": "Kranenburg/Niederrhein",
    "kranichfeld": "Kranichfeld",
    "kranzberg": "Kranzberg",
    "kratzeburg": "Kratzeburg",
    "kratzenburg": "Kratzenburg",
    "krauchenwies": "Krauchenwies",
    "krauschwitz-oberlausitz": "Krauschwitz i.d. O.L.",
    "krausnick-gross-wasserburg": "Krausnick-Groß Wasserburg",
    "krauthausen": "Krauthausen",
    "krautheim-weimar": "Krautheim b. Weimar",
    "krautheim-jagst": "Krautheim/Jagst",
    "krautscheid": "Krautscheid",
    "krayenberggemeinde": "Krayenberggemeinde",
    "kreba-neudorf": "Kreba-Neudorf",
    "krebeck": "Krebeck",
    "krefeld": "Krefeld",
    "kreien": "Kreien",
    "kreimbach-kaulbach": "Kreimbach-Kaulbach",
    "kreischa": "Kreischa",
    "krembz": "Krembz",
    "kremitzaue": "Kremitzaue",
    "kremmen": "Kremmen",
    "kremmin": "Kremmin",
    "krempdorf": "Krempdorf",
    "krempe": "Krempe",
    "krempel": "Krempel",
    "kremperheide": "Kremperheide",
    "krempermoor": "Krempermoor",
    "krems-ii": "Krems II",
    "kressberg": "Kreßberg",
    "kressbronn-am-bodensee": "Kressbronn am Bodensee",
    "kretz": "Kretz",
    "kretzschau": "Kretzschau",
    "kreuth": "Kreuth",
    "kreuzau": "Kreuzau",
    "kreuzebra": "Kreuzebra",
    "kreuztal": "Kreuztal",
    "kreuzwertheim": "Kreuzwertheim",
    "krickenbach": "Krickenbach",
    "kriebitzsch": "Kriebitzsch",
    "kriebstein": "Kriebstein",
    "kriegsfeld": "Kriegsfeld",
    "krien": "Krien",
    "kriesow": "Kriesow",
    "kriftel": "Kriftel",
    "kritzmow": "Kritzmow",
    "kritzow": "Kritzow",
    "krogaspe": "Krogaspe",
    "krokau": "Krokau",
    "kroelpa": "Krölpa",
    "krombach-heiligenstadt": "Krombach b. Heiligenstadt",
    "krombach-unterfranken": "Krombach/Unterfranken",
    "kromsdorf": "Kromsdorf",
    "kronach": "Kronach",
    "kronau": "Kronau",
    "kronberg-im-taunus": "Kronberg im Taunus",
    "kronburg": "Kronburg",
    "kroening": "Kröning",
    "kronprinzenkoog": "Kronprinzenkoog",
    "kronsgaard": "Kronsgaard",
    "kronshagen": "Kronshagen",
    "kronsmoor": "Kronsmoor",
    "kronweiler": "Kronweiler",
    "kroepelin": "Kröpelin",
    "kropp": "Kropp",
    "kroppach": "Kroppach",
    "kroeppelshagen-fahrendorf": "Kröppelshagen-Fahrendorf",
    "kroppen": "Kroppen",
    "kroeppen": "Kröppen",
    "kroppenstedt": "Kroppenstedt",
    "kroeslin": "Kröslin",
    "krostitz": "Krostitz",
    "krottelbach": "Krottelbach",
    "kroev": "Kröv",
    "kruchten": "Kruchten",
    "kruckow": "Kruckow",
    "kruft": "Kruft",
    "krugsdorf": "Krugsdorf",
    "krukow-hzgt-lauenburg": "Krukow/Kr. Hzgt Lauenburg",
    "krumbach-schwaben": "Krumbach/Schwaben",
    "krummbek": "Krummbek",
    "kruemmel": "Krümmel",
    "krummenau": "Krummenau",
    "krummendeich": "Krummendeich",
    "krummendiek": "Krummendiek",
    "krummennaab": "Krummennaab",
    "krummesse": "Krummesse",
    "krummhoern": "Krummhörn",
    "krummin": "Krummin",
    "krummwisch": "Krummwisch",
    "krumstedt": "Krumstedt",
    "kruen": "Krün",
    "krunkel": "Krunkel",
    "krusenfelde": "Krusenfelde",
    "krusenhagen": "Krusenhagen",
    "kruezen": "Krüzen",
    "kublank": "Kublank",
    "kubschuetz": "Kubschütz",
    "kuchelmiss": "Kuchelmiß",
    "kuchen": "Kuchen",
    "kuckssee": "Kuckssee",
    "kuddewoerde": "Kuddewörde",
    "kuden": "Kuden",
    "kudensee": "Kudensee",
    "kuhardt": "Kuhardt",
    "kuehbach": "Kühbach",
    "kuehdorf": "Kühdorf",
    "kuhfelde": "Kuhfelde",
    "kuehlenthal": "Kühlenthal",
    "kuhlen-wendorf": "Kuhlen-Wendorf",
    "kuehlungsborn": "Kühlungsborn",
    "kuehndorf": "Kühndorf",
    "kuhnhoefen": "Kuhnhöfen",
    "kuehren-preetz": "Kühren b. Preetz",
    "kuhs": "Kuhs",
    "kuehsen": "Kühsen",
    "kuhstorf": "Kuhstorf",
    "kuekels": "Kükels",
    "kuellstedt": "Küllstedt",
    "kulmain": "Kulmain",
    "kulmbach": "Kulmbach",
    "kulpin": "Kulpin",
    "kuelsheim": "Külsheim",
    "kuelz-hunsrueck": "Külz/Hunsrück",
    "kuembdchen": "Kümbdchen",
    "kumhausen": "Kumhausen",
    "kummerfeld": "Kummerfeld",
    "kuemmernitztal": "Kümmernitztal",
    "kummerow-malchin": "Kummerow b. Malchin",
    "kummerow-stralsund": "Kummerow b. Stralsund",
    "kuemmersbruck": "Kümmersbruck",
    "kundert": "Kundert",
    "kunreuth": "Kunreuth",
    "kuenzell": "Künzell",
    "kuenzelsau": "Künzelsau",
    "kuenzing": "Künzing",
    "kupferberg": "Kupferberg",
    "kupferzell": "Kupferzell",
    "kuppenheim": "Kuppenheim",
    "kueps": "Küps",
    "kuernach": "Kürnach",
    "kuernbach": "Kürnbach",
    "kurort-jonsdorf": "Kurort Jonsdorf",
    "kurort-oybin": "Kurort Oybin",
    "kuerten": "Kürten",
    "kurtscheid": "Kurtscheid",
    "kusel": "Kusel",
    "kuessaberg": "Küssaberg",
    "kuesten": "Küsten",
    "kusterdingen": "Kusterdingen",
    "kuestriner-vorland": "Küstriner Vorland",
    "kutenholz": "Kutenholz",
    "kutzenhausen": "Kutzenhausen",
    "kutzleben": "Kutzleben",
    "kyffhaeuserland": "Kyffhäuserland",
    "kyllburg": "Kyllburg",
    "kyllburgweiler": "Kyllburgweiler",
    "kyritz": "Kyritz",
    "laaber": "Laaber",
    "laage": "Laage",
    "laar": "Laar",
    "laasdorf": "Laasdorf",
    "laatzen": "Laatzen",
    "labenz": "Labenz",
    "laberweinting": "Laberweinting",
    "laboe": "Laboe",
    "lachen": "Lachen",
    "lachendorf": "Lachendorf",
    "ladbergen": "Ladbergen",
    "ladelund": "Ladelund",
    "ladenburg": "Ladenburg",
    "laer": "Laer",
    "lage-dinkel": "Lage/Dinkel",
    "lage-lippe": "Lage/Lippe",
    "laegerdorf": "Lägerdorf",
    "laehden": "Lähden",
    "lahn": "Lahn",
    "lahnau": "Lahnau",
    "lahnstein": "Lahnstein",
    "lahntal": "Lahntal",
    "lahr-eifel": "Lahr/Eifel",
    "lahr-hunsrueck": "Lahr/Hunsrück",
    "lahr-schwarzwald": "Lahr/Schwarzwald",
    "laichingen": "Laichingen",
    "lalendorf": "Lalendorf",
    "lalling": "Lalling",
    "lam": "Lam",
    "lambertsberg": "Lambertsberg",
    "lambrecht-pfalz": "Lambrecht/Pfalz",
    "lambrechtshagen": "Lambrechtshagen",
    "lambsborn": "Lambsborn",
    "lambsheim": "Lambsheim",
    "lamerdingen": "Lamerdingen",
    "lammershagen": "Lammershagen",
    "lampaden": "Lampaden",
    "lampertheim": "Lampertheim",
    "lampertswalde-sachsen": "Lampertswalde/Sachsen",
    "lamspringe": "Lamspringe",
    "lamstedt": "Lamstedt",
    "lancken-granitz": "Lancken-Granitz",
    "landau-isar": "Landau a.d. Isar",
    "landau-in-der-pfalz": "Landau/Pfalz",
    "landensberg": "Landensberg",
    "landesbergen": "Landesbergen",
    "landkern": "Landkern",
    "landolfshausen": "Landolfshausen",
    "landrecht": "Landrecht",
    "landsberg-am-lech": "Landsberg a. Lech",
    "landsberg-halle": "Landsberg/Halle",
    "landsberied": "Landsberied",
    "landscheid": "Landscheid",
    "landscheide": "Landscheide",
    "landshut": "Landshut",
    "landstuhl": "Landstuhl",
    "landwehr": "Landwehr",
    "langballig": "Langballig",
    "langdorf": "Langdorf",
    "langeln-pinneberg": "Langeln/Kr. Pinneberg",
    "langelsheim": "Langelsheim",
    "langen-bruetz": "Langen Brütz",
    "langen-jarchow": "Langen Jarchow",
    "langen-emsland": "Langen/Emsland",
    "langen-hessen": "Langen/Hessen",
    "langenaltheim": "Langenaltheim",
    "langenargen": "Langenargen",
    "langenau-wuerttemberg": "Langenau/Württemberg",
    "langenbach-kirburg": "Langenbach b. Kirburg",
    "langenbach-freising": "Langenbach Kr. Freising",
    "langenbach-pfalz": "Langenbach/Pfalz",
    "langenberg": "Langenberg",
    "langenbernsdorf": "Langenbernsdorf",
    "langenbrettach": "Langenbrettach",
    "langenburg": "Langenburg",
    "langendorf-elbe": "Langendorf/Elbe",
    "langenenslingen": "Langenenslingen",
    "langeness": "Langeneß",
    "langenfeld-eifel": "Langenfeld/Eifel",
    "langenfeld-mittelfranken": "Langenfeld/Mittelfranken",
    "langenfeld-rheinland": "Langenfeld/Rheinland",
    "langenhagen-hannover": "Langenhagen",
    "langenhahn": "Langenhahn",
    "langenhorn": "Langenhorn",
    "langenlehsten": "Langenlehsten",
    "langenleuba-niederhain": "Langenleuba-Niederhain",
    "langenlonsheim": "Langenlonsheim",
    "langenmosen": "Langenmosen",
    "langenneufnach": "Langenneufnach",
    "langenorla": "Langenorla",
    "langenpreising": "Langenpreising",
    "langenscheid": "Langenscheid",
    "langenselbold": "Langenselbold",
    "langensendelbach": "Langensendelbach",
    "langenthal": "Langenthal",
    "langenweissbach": "Langenweißbach",
    "langenwetzendorf": "Langenwetzendorf",
    "langenwolschendorf": "Langenwolschendorf",
    "langenzenn": "Langenzenn",
    "langeoog": "Langeoog",
    "langerringen": "Langerringen",
    "langerwehe": "Langerwehe",
    "langewahl": "Langewahl",
    "langewiesen": "Langewiesen",
    "langfurth": "Langfurth",
    "langgoens": "Langgöns",
    "langlingen": "Langlingen",
    "langquaid": "Langquaid",
    "langscheid": "Langscheid",
    "langstedt": "Langstedt",
    "langsur": "Langsur",
    "langwedel-flecken": "Langwedel/Flecken",
    "langwedel-holstein": "Langwedel/Holstein",
    "langweid-lech": "Langweid a. Lech",
    "langweiler-idar-oberstein": "Langweiler b. Idar-Oberstein",
    "langweiler-lauterecken": "Langweiler b. Lauterecken",
    "langwieden": "Langwieden",
    "lanitz-hassel-tal": "Lanitz-Hassel-Tal",
    "lankau": "Lankau",
    "lanz": "Lanz",
    "lanze": "Lanze",
    "lappersdorf-markt": "Lappersdorf Markt",
    "laerz": "Lärz",
    "lasbek": "Lasbek",
    "lascheid": "Lascheid",
    "lasel": "Lasel",
    "lassan": "Lassan",
    "lastrup": "Lastrup",
    "latendorf": "Latendorf",
    "lathen": "Lathen",
    "laubach-eifel": "Laubach/Eifel",
    "laubach-hessen": "Laubach/Hessen",
    "laubach-hunsrueck": "Laubach/Hunsrück",
    "lauben-memmingen": "Lauben b. Memmingen",
    "lauben-oberallgaeu": "Lauben/Oberallgäu",
    "laubenheim": "Laubenheim",
    "laucha-an-der-unstrut": "Laucha an der Unstrut",
    "lauchhammer": "Lauchhammer",
    "lauchheim": "Lauchheim",
    "lauchringen": "Lauchringen",
    "lauda-koenigshofen": "Lauda-Königshofen",
    "laudenbach-bergstrasse": "Laudenbach/Bergstraße",
    "laudenbach-unterfranken": "Laudenbach/Unterfranken",
    "laudert": "Laudert",
    "lauenau": "Lauenau",
    "lauenbrueck": "Lauenbrück",
    "lauenburg-elbe": "Lauenburg/Elbe",
    "lauenfoerde": "Lauenförde",
    "lauenhagen": "Lauenhagen",
    "lauf-pegnitz": "Lauf a.d. Pegnitz",
    "lauf-baden": "Lauf/Baden",
    "laufach": "Laufach",
    "laufeld": "Laufeld",
    "laufen": "Laufen",
    "laufenburg-baden": "Laufenburg/Baden",
    "laufersweiler": "Laufersweiler",
    "lauffen-am-neckar": "Lauffen am Neckar",
    "laugna": "Laugna",
    "lauingen-donau": "Lauingen/Donau",
    "laumersheim": "Laumersheim",
    "lauperath": "Lauperath",
    "laupheim": "Laupheim",
    "laurenburg": "Laurenburg",
    "lauscha": "Lauscha",
    "lauschied": "Lauschied",
    "lausnitz-neustadt-orla": "Lausnitz b. Neustadt/Orla",
    "laussig": "Laußig",
    "laussnitz": "Laußnitz",
    "lauta": "Lauta",
    "lautenbach": "Lautenbach",
    "lauter-oberfranken": "Lauter/Oberfranken",
    "lauterach": "Lauterach",
    "lauterbach-eisenach": "Lauterbach b. Eisenach",
    "lauterbach-hessen": "Lauterbach/Hessen",
    "lauterbach-schwarzwald": "Lauterbach/Schwarzwald",
    "lauter-bernsbach": "Lauter-Bernsbach",
    "lauterecken": "Lauterecken",
    "lauterhofen": "Lauterhofen",
    "lautersheim": "Lautersheim",
    "lauterstein": "Lauterstein",
    "lautert": "Lautert",
    "lautertal-oberfranken": "Lautertal/Oberfranken",
    "lautertal-odenwald": "Lautertal/Odenwald",
    "lautertal-vogelsberg": "Lautertal/Vogelsberg",
    "lautrach": "Lautrach",
    "lautzenbruecken": "Lautzenbrücken",
    "lautzenhausen": "Lautzenhausen",
    "lawalde": "Lawalde",
    "lawitz": "Lawitz",
    "lebach": "Lebach",
    "lebrade": "Lebrade",
    "lebus": "Lebus",
    "lebusa": "Lebusa",
    "lechbruck-am-see": "Lechbruck am See",
    "leck": "Leck",
    "lederhose": "Lederhose",
    "leegebruch": "Leegebruch",
    "leer-ostfriesland": "Leer/Ostfriesland",
    "leese": "Leese",
    "leezdorf": "Leezdorf",
    "leezen-schwerin": "Leezen b. Schwerin",
    "leezen-holstein": "Leezen/Holstein",
    "legau": "Legau",
    "legde-quitzoebel": "Legde/Quitzöbel",
    "legden": "Legden",
    "lehe-dithmarschen": "Lehe/Dithmarschen",
    "lehe-ems": "Lehe/Ems",
    "lehesten-jena": "Lehesten b. Jena",
    "lehesten-thueringer-wald": "Lehesten/Thüringer Wald",
    "lehmen": "Lehmen",
    "lehmkuhlen": "Lehmkuhlen",
    "lehmrade": "Lehmrade",
    "lehnstedt": "Lehnstedt",
    "lehrberg": "Lehrberg",
    "lehre": "Lehre",
    "lehrensteinsfeld": "Lehrensteinsfeld",
    "lehrte": "Lehrte",
    "leibertingen": "Leibertingen",
    "leiblfing": "Leiblfing",
    "leichlingen-rheinland": "Leichlingen/Rheinland",
    "leidenborn": "Leidenborn",
    "leidersbach": "Leidersbach",
    "leienkaul": "Leienkaul",
    "leiferde": "Leiferde",
    "leimbach-adenau": "Leimbach b. Adenau",
    "leimbach-bad-salzungen": "Leimbach b. Bad Salzungen",
    "leimbach-neuerburg": "Leimbach b. Neuerburg",
    "leimen-baden": "Leimen/Baden",
    "leimen-pfalz": "Leimen/Pfalz",
    "leimersheim": "Leimersheim",
    "leinach": "Leinach",
    "leinatal": "Leinatal",
    "leinburg": "Leinburg",
    "leinefelde-worbis": "Leinefelde-Worbis",
    "leinfelden-echterdingen": "Leinfelden-Echterdingen",
    "leingarten": "Leingarten",
    "leiningen": "Leiningen",
    "leinsweiler": "Leinsweiler",
    "leinzell": "Leinzell",
    "leipheim": "Leipheim",
    "leipzig": "Leipzig",
    "leisel": "Leisel",
    "leisnig": "Leisnig",
    "leitzweiler": "Leitzweiler",
    "leiwen": "Leiwen",
    "leizen": "Leizen",
    "lelkendorf": "Lelkendorf",
    "lemberg": "Lemberg",
    "lembruch": "Lembruch",
    "lemfoerde": "Lemförde",
    "lemgo": "Lemgo",
    "lemgow": "Lemgow",
    "lemnitz": "Lemnitz",
    "lemwerder": "Lemwerder",
    "lengdorf": "Lengdorf",
    "lengede": "Lengede",
    "lengenbostel": "Lengenbostel",
    "lengenfeld-sachsen": "Lengenfeld i. Sachsen",
    "lengenwang": "Lengenwang",
    "lengerich-emsland": "Lengerich/Emsland",
    "lengerich-westfalen": "Lengerich/Westfalen",
    "lengfeld": "Lengfeld",
    "lenggries": "Lenggries",
    "lenne": "Lenne",
    "lennestadt": "Lennestadt",
    "lenningen": "Lenningen",
    "lensahn": "Lensahn",
    "lenterode": "Lenterode",
    "lentfoehrden": "Lentföhrden",
    "lenting": "Lenting",
    "lenzen": "Lenzen",
    "lenzerwische": "Lenzerwische",
    "lenzkirch": "Lenzkirch",
    "leonberg-oberpfalz": "Leonberg/Oberpfalz",
    "leonberg-wuerttemberg": "Leonberg/Württemberg",
    "leopoldshagen": "Leopoldshagen",
    "leopoldshoehe": "Leopoldshöhe",
    "letschin": "Letschin",
    "lettweiler": "Lettweiler",
    "leubsdorf-rhein": "Leubsdorf/Rhein",
    "leubsdorf-sachsen": "Leubsdorf/Sachsen",
    "leuchtenberg": "Leuchtenberg",
    "leun": "Leun",
    "leuna": "Leuna",
    "leupoldsgruen": "Leupoldsgrün",
    "leussow": "Leussow",
    "leutenbach-oberfranken": "Leutenbach/Oberfranken",
    "leutenbach-wuerttemberg": "Leutenbach/Württemberg",
    "leutenberg": "Leutenberg",
    "leutenthal": "Leutenthal",
    "leuterod": "Leuterod",
    "leutersdorf-oberlausitz": "Leutersdorf (Oberlausitz)",
    "leutersdorf-meiningen": "Leutersdorf b. Meiningen",
    "leutershausen": "Leutershausen",
    "leutesdorf": "Leutesdorf",
    "leutkirch-im-allgaeu": "Leutkirch im Allgäu",
    "levenhagen": "Levenhagen",
    "leverkusen": "Leverkusen",
    "lewitzrand": "Lewitzrand",
    "lexgaard": "Lexgaard",
    "lich": "Lich",
    "lichte": "Lichte",
    "lichtenau-chemnitz": "Lichtenau b. Chemnitz",
    "lichtenau-baden": "Lichtenau/Baden",
    "lichtenau-mittelfranken": "Lichtenau/Mittelfranken",
    "lichtenau-westfalen": "Lichtenau/Westfalen",
    "lichtenberg-bischofswerda": "Lichtenberg b. Bischofswerda",
    "lichtenberg-erzgebirge": "Lichtenberg/Erzgebirge",
    "lichtenberg-oberfranken": "Lichtenberg/Oberfranken",
    "lichtenborn": "Lichtenborn",
    "lichtenfels-bayern": "Lichtenfels/Bayern",
    "lichtenfels-hessen": "Lichtenfels/Hessen",
    "lichtenstein-sachsen": "Lichtenstein/Sachsen",
    "lichtenstein-wuerttemberg": "Lichtenstein/Württemberg",
    "lichtentanne": "Lichtentanne",
    "lichtenwald": "Lichtenwald",
    "lichterfeld-schacksdorf": "Lichterfeld-Schacksdorf",
    "liebenau-flecken": "Liebenau/Flecken",
    "liebenau-hessen": "Liebenau/Hessen",
    "liebenburg": "Liebenburg",
    "liebenscheid": "Liebenscheid",
    "liebenstein": "Liebenstein",
    "liebenwalde": "Liebenwalde",
    "lieberose": "Lieberose",
    "liebschuetzberg": "Liebschützberg",
    "liebshausen": "Liebshausen",
    "liebstadt": "Liebstadt",
    "liederbach-am-taunus": "Liederbach am Taunus",
    "lieg": "Lieg",
    "lienen": "Lienen",
    "liepe-eberswalde-finow": "Liepe b. Eberswalde-Finow",
    "liepgarten": "Liepgarten",
    "lierfeld": "Lierfeld",
    "lierschied": "Lierschied",
    "liesenich": "Liesenich",
    "lieser": "Lieser",
    "liessem": "Ließem",
    "lieth": "Lieth",
    "lietzen": "Lietzen",
    "lietzow-ruegen": "Lietzow/Rügen",
    "lilienthal": "Lilienthal",
    "limbach-kirn": "Limbach b. Kirn",
    "limbach-reichenbach": "Limbach b. Reichenbach",
    "limbach-baden": "Limbach/Baden",
    "limbach-westerwald": "Limbach/Westerwald",
    "limbach-oberfrohna": "Limbach-Oberfrohna",
    "limburg-lahn": "Limburg a.d. Lahn",
    "limburgerhof": "Limburgerhof",
    "limeshain": "Limeshain",
    "linau": "Linau",
    "lind-mayen": "Lind b. Mayen",
    "lind-ahrweiler": "Lind Kr. Ahrweiler",
    "linda-neustadt-orla": "Linda b. Neustadt/Orla",
    "linda-weida": "Linda b. Weida",
    "lindau-kiel": "Lindau b. Kiel",
    "lindau-bodensee": "Lindau/Bodensee",
    "lindberg": "Lindberg",
    "linden-hessen": "Linden/Hessen",
    "linden-holstein": "Linden/Holstein",
    "linden-pfalz": "Linden/Pfalz",
    "linden-westerwald": "Linden/Westerwald",
    "lindenau-oberlausitz": "Lindenau (Oberlausitz)",
    "lindenberg-allgaeu": "Lindenberg i. Allgäu",
    "lindenberg-pfalz": "Lindenberg/Pfalz",
    "lindenberg-vorpommern": "Lindenberg/Vorpommern",
    "lindendorf": "Lindendorf",
    "lindenfels": "Lindenfels",
    "lindenkreuz": "Lindenkreuz",
    "lindenschied": "Lindenschied",
    "lindern-oldenburg": "Lindern/Oldenburg",
    "lindetal": "Lindetal",
    "lindewerra": "Lindewerra",
    "lindewitt": "Lindewitt",
    "lindholz": "Lindholz",
    "lindhorst": "Lindhorst",
    "lindig": "Lindig",
    "lindlar": "Lindlar",
    "lindow-mark": "Lindow/Mark",
    "lindwedel": "Lindwedel",
    "lingen-ems": "Lingen/Ems",
    "lingenfeld": "Lingenfeld",
    "lingerhahn": "Lingerhahn",
    "linkenbach": "Linkenbach",
    "linkenheim-hochstetten": "Linkenheim-Hochstetten",
    "linnich": "Linnich",
    "linsburg": "Linsburg",
    "linsengericht": "Linsengericht",
    "linthe": "Linthe",
    "linz-am-rhein": "Linz am Rhein",
    "lippersdorf-erdmannsdorf": "Lippersdorf-Erdmannsdorf",
    "lippetal": "Lippetal",
    "lipporn": "Lipporn",
    "lipprechterode": "Lipprechterode",
    "lippstadt": "Lippstadt",
    "lirstal": "Lirstal",
    "lisberg": "Lisberg",
    "lissendorf": "Lissendorf",
    "list": "List auf Sylt",
    "litzendorf": "Litzendorf",
    "loebau": "Löbau",
    "lobbach": "Lobbach",
    "loeberschuetz": "Löberschütz",
    "loebichau": "Löbichau",
    "loebnitz-delitzsch": "Löbnitz b. Delitzsch",
    "loebnitz-ribnitz-damgarten": "Löbnitz b. Ribnitz-Damgarten",
    "loechgau": "Löchgau",
    "lochum": "Lochum",
    "loecknitz": "Löcknitz",
    "lockstedt": "Lockstedt",
    "lockwisch": "Lockwisch",
    "loddin": "Loddin",
    "loedla": "Lödla",
    "loef": "Löf",
    "loffenau": "Loffenau",
    "loeffingen": "Löffingen",
    "lohbarbek": "Lohbarbek",
    "lohberg": "Lohberg",
    "lohe-foehrden": "Lohe-Föhrden",
    "lohe-rickelshof": "Lohe-Rickelshof",
    "lohfelden": "Lohfelden",
    "lohheide": "Lohheide gemfr. Bezirk",
    "lohkirchen": "Lohkirchen",
    "loehma": "Löhma",
    "lohmar": "Lohmar",
    "lohme": "Lohme",
    "lohmen-guestrow": "Lohmen b. Güstrow",
    "lohmen-sachsen": "Lohmen/Sachsen",
    "loehnberg": "Löhnberg",
    "loehne": "Löhne",
    "lohne-oldenburg": "Lohne/Oldenburg",
    "lohnsfeld": "Lohnsfeld",
    "lohnweiler": "Lohnweiler",
    "lohr-main": "Lohr a. Main",
    "lohra": "Lohra",
    "lohrheim": "Lohrheim",
    "lohsa": "Lohsa",
    "loiching": "Loiching",
    "loissin": "Loissin",
    "loit": "Loit",
    "loitsche": "Loitsche-Heinrichsberg",
    "loitz": "Loitz",
    "loitzendorf": "Loitzendorf",
    "lollar": "Lollar",
    "loellbach": "Löllbach",
    "lollschied": "Lollschied",
    "lommatzsch": "Lommatzsch",
    "longen": "Longen",
    "longkamp": "Longkamp",
    "longuich": "Longuich",
    "loeningen": "Löningen",
    "lonnerstadt": "Lonnerstadt",
    "lonnig": "Lonnig",
    "lonsee": "Lonsee",
    "lonsheim": "Lonsheim",
    "looft": "Looft",
    "loop": "Loop",
    "loose": "Loose",
    "loeptin": "Löptin",
    "lorch-rheingau": "Lorch/Rheingau",
    "lorch-wuerttemberg": "Lorch/Württemberg",
    "loerrach": "Lörrach",
    "lorsch": "Lorsch",
    "lorscheid": "Lorscheid",
    "lorup": "Lorup",
    "loerzweiler": "Lörzweiler",
    "losheim-am-see": "Losheim am See",
    "loesnich": "Lösnich",
    "lossatal": "Lossatal",
    "lossburg": "Loßburg",
    "loessnitz": "Lößnitz",
    "lotte": "Lotte",
    "lottorf": "Lottorf",
    "lottstetten": "Lottstetten",
    "loetzbeuren": "Lötzbeuren",
    "loewenberger-land": "Löwenberger Land",
    "loewenstedt": "Löwenstedt",
    "loewenstein": "Löwenstein",
    "loxstedt": "Loxstedt",
    "luebbecke": "Lübbecke",
    "luebben-spreewald": "Lübben/Spreewald",
    "luebbenau-spreewald": "Lübbenau/Spreewald",
    "luebberstedt": "Lübberstedt",
    "luebberstorf": "Lübberstorf",
    "luebbow": "Lübbow",
    "luebeck": "Lübeck",
    "luebesse": "Lübesse",
    "lueblow": "Lüblow",
    "lubmin": "Lubmin",
    "luebow": "Lübow",
    "luebs-torgelow": "Lübs b. Torgelow",
    "luebstorf": "Lübstorf",
    "luebtheen": "Lübtheen",
    "luebz": "Lübz",
    "luechow-sandesneben": "Lüchow b. Sandesneben",
    "luechow-wendland": "Lüchow/Wendland",
    "lucka": "Lucka",
    "luckaitztal": "Luckaitztal",
    "luckau-niederlausitz": "Luckau (Niederlausitz)",
    "luckau-wendland": "Luckau/Wendland",
    "luckenbach": "Luckenbach",
    "lueckenburg": "Lückenburg",
    "luckenwalde": "Luckenwalde",
    "luckow-torgelow": "Luckow b. Torgelow",
    "luedenscheid": "Lüdenscheid",
    "lueder": "Lüder",
    "luedersburg": "Lüdersburg",
    "luedersdorf-mecklenburg": "Lüdersdorf/Meck.",
    "luedersfeld": "Lüdersfeld",
    "luedershagen": "Lüdershagen",
    "luedinghausen": "Lüdinghausen",
    "ludorf": "Ludorf",
    "ludwigsau": "Ludwigsau",
    "ludwigsburg-wuerttemberg": "Ludwigsburg/Württemberg",
    "ludwigschorgast": "Ludwigschorgast",
    "ludwigsfelde": "Ludwigsfelde",
    "ludwigshafen-am-rhein": "Ludwigshafen",
    "ludwigshoehe": "Ludwigshöhe",
    "ludwigslust": "Ludwigslust",
    "ludwigsstadt": "Ludwigsstadt",
    "ludwigswinkel": "Ludwigswinkel",
    "lueerdissen": "Lüerdissen",
    "lug-pfalz": "Lug/Pfalz",
    "lugau-erzgebirge": "Lugau/Erzgebirge",
    "luegde": "Lügde",
    "luehburg": "Lühburg",
    "luhden": "Luhden",
    "luhe-wildenau": "Luhe-Wildenau",
    "luehmannsdorf": "Lühmannsdorf",
    "luhnstedt": "Luhnstedt",
    "luisenthal": "Luisenthal",
    "luelsfeld": "Lülsfeld",
    "lumpzig": "Lumpzig",
    "lunden": "Lunden",
    "luenebach": "Lünebach",
    "lueneburg": "Lüneburg",
    "luenen": "Lünen",
    "luenne": "Lünne",
    "lunow-stolzenhagen": "Lunow-Stolzenhagen",
    "lunzenau": "Lunzenau",
    "lupburg": "Lupburg",
    "luerschau": "Lürschau",
    "luessow-guestrow": "Lüssow b. Güstrow",
    "luessow-stralsund": "Lüssow b. Stralsund",
    "lustadt": "Lustadt",
    "luetau": "Lütau",
    "luetetsburg": "Lütetsburg",
    "lutherstadt-eisleben": "Lutherstadt Eisleben",
    "lutherstadt-wittenberg": "Lutherstadt Wittenberg",
    "luetjenburg": "Lütjenburg",
    "luetjenholm": "Lütjenholm",
    "luetjensee": "Lütjensee",
    "luetjenwestedt": "Lütjenwestedt",
    "luetow": "Lütow",
    "lutter-am-barenberge": "Lutter am Barenberge",
    "lutter-heilbad": "Lutter b. Heilbad",
    "lutterbek": "Lutterbek",
    "luettow-valluhn": "Lüttow-Valluhn",
    "luetz": "Lütz",
    "luetzelbach": "Lützelbach",
    "luetzen": "Lützen",
    "lutzerath": "Lutzerath",
    "lutzhorn": "Lutzhorn",
    "lutzingen": "Lutzingen",
    "luetzkampen": "Lützkampen",
    "luetzow": "Lützow",
    "luxem": "Luxem",
    "lychen": "Lychen",
    "lykershausen": "Lykershausen",
    "maasbuell": "Maasbüll",
    "maasen": "Maasen",
    "maasholm": "Maasholm",
    "machern": "Machern",
    "macken": "Macken",
    "mackenbach": "Mackenbach",
    "mackenrode-heiligenstadt": "Mackenrode b. Heiligenstadt",
    "mackenrodt": "Mackenrodt",
    "magdala": "Magdala",
    "magdeburg": "Magdeburg",
    "magstadt": "Magstadt",
    "mahlberg": "Mahlberg",
    "mahlstetten": "Mahlstetten",
    "maehren": "Mähren",
    "maehring": "Mähring",
    "maierhoefen": "Maierhöfen",
    "maihingen": "Maihingen",
    "maikammer": "Maikammer",
    "mainaschaff": "Mainaschaff",
    "mainbernheim": "Mainbernheim",
    "mainburg": "Mainburg",
    "mainhardt": "Mainhardt",
    "mainhausen": "Mainhausen",
    "mainleus-markt": "Mainleus Markt",
    "mainstockheim": "Mainstockheim",
    "maintal": "Maintal",
    "mainz": "Mainz",
    "maisach": "Maisach",
    "maisborn": "Maisborn",
    "maitenbeth": "Maitenbeth",
    "maitzborn": "Maitzborn",
    "malberg-eifel": "Malberg/Eifel",
    "malberg-westerwald": "Malberg/Westerwald",
    "malbergweich": "Malbergweich",
    "malborn": "Malborn",
    "malchin": "Malchin",
    "malching": "Malching",
    "malchow": "Malchow",
    "malente": "Malente",
    "malgersdorf": "Malgersdorf",
    "malk-goehren": "Malk Göhren",
    "mallersdorf-pfaffenberg": "Mallersdorf-Pfaffenberg",
    "malliss": "Malliß",
    "malsburg-marzell": "Malsburg-Marzell",
    "malsch-wiesloch": "Malsch b. Wiesloch",
    "malsch-karlsruhe": "Malsch Kr. Karlsruhe",
    "malschwitz": "Malschwitz",
    "malsfeld": "Malsfeld",
    "malterdingen": "Malterdingen",
    "mammelzen": "Mammelzen",
    "mammendorf": "Mammendorf",
    "mamming": "Mamming",
    "manching": "Manching",
    "mandel": "Mandel",
    "mandelbachtal": "Mandelbachtal",
    "mandern": "Mandern",
    "manderscheid-waxweiler": "Manderscheid b. Waxweiler",
    "manderscheid-eifel": "Manderscheid/Eifel",
    "manhagen": "Manhagen",
    "mannebach-saarburg": "Mannebach b. Saarburg",
    "mannebach-eifel": "Mannebach/Eifel",
    "mannheim": "Mannheim",
    "mannstedt": "Mannstedt",
    "mannweiler-coelln": "Mannweiler-Cölln",
    "mansfeld-suedharz": "Mansfeld/Südharz",
    "mantel": "Mantel",
    "manubach": "Manubach",
    "marbach-am-neckar": "Marbach am Neckar",
    "marburg": "Marburg",
    "march": "March",
    "margetshoechheim": "Margetshöchheim",
    "mariaposching": "Mariaposching",
    "marienberg": "Marienberg",
    "marienfels": "Marienfels",
    "marienfliess": "Marienfließ",
    "marienhafe": "Marienhafe",
    "marienhagen": "Marienhagen",
    "marienhausen": "Marienhausen",
    "marienheide": "Marienheide",
    "marienmuenster": "Marienmünster",
    "marienrachdorf": "Marienrachdorf",
    "mariental": "Mariental",
    "marienwerder": "Marienwerder",
    "maring-noviand": "Maring-Noviand",
    "marisfeld": "Marisfeld",
    "mark-landin": "Mark Landin",
    "markdorf": "Markdorf",
    "markersdorf": "Markersdorf",
    "markgroeningen": "Markgröningen",
    "maerkisch-linden": "Märkisch Linden",
    "maerkisch-luch": "Märkisch Luch",
    "maerkisch-buchholz": "Märkisch/Buchholz",
    "maerkische-heide": "Märkische Heide",
    "maerkische-hoehe": "Märkische Höhe",
    "markkleeberg": "Markkleeberg",
    "marklkofen": "Marklkofen",
    "marklohe": "Marklohe",
    "markneukirchen": "Markneukirchen",
    "markranstaedt": "Markranstädt",
    "marksuhl": "Marksuhl",
    "markt-absberg": "Markt Absberg",
    "markt-berolzheim": "Markt Berolzheim",
    "markt-bibart": "Markt Bibart",
    "markt-einersheim": "Markt Einersheim",
    "markt-erlbach": "Markt Erlbach",
    "markt-indersdorf": "Markt Indersdorf",
    "markt-nordheim": "Markt Nordheim",
    "markt-rettenbach": "Markt Rettenbach",
    "markt-schwaben": "Markt Schwaben",
    "markt-taschendorf": "Markt Taschendorf",
    "markt-wald": "Markt Wald",
    "marktbergel": "Marktbergel",
    "marktbreit": "Marktbreit",
    "marktgraitz": "Marktgraitz",
    "marktheidenfeld": "Marktheidenfeld",
    "marktl": "Marktl",
    "marktleugast": "Marktleugast",
    "marktleuthen": "Marktleuthen",
    "marktoberdorf": "Marktoberdorf",
    "marktoffingen": "Marktoffingen",
    "marktredwitz": "Marktredwitz",
    "marktrodach": "Marktrodach",
    "marktschellenberg": "Marktschellenberg",
    "marktschorgast": "Marktschorgast",
    "marktsteft": "Marktsteft",
    "marktzeuln": "Marktzeuln",
    "markvippach": "Markvippach",
    "marl-duemmer": "Marl/Dümmer",
    "marl-westfalen": "Marl/Westfalen",
    "marloffstein": "Marloffstein",
    "marlow": "Marlow",
    "marne": "Marne",
    "marnerdeich": "Marnerdeich",
    "marnheim": "Marnheim",
    "marnitz": "Marnitz",
    "maroldsweisach": "Maroldsweisach",
    "marolterode": "Marolterode",
    "maroth": "Maroth",
    "marpingen": "Marpingen",
    "marquartstein": "Marquartstein",
    "marsberg": "Marsberg",
    "marschacht": "Marschacht",
    "martensrade": "Martensrade",
    "martfeld": "Martfeld",
    "marth": "Marth",
    "martinroda-ilmenau": "Martinroda b. Ilmenau",
    "martinsheim": "Martinsheim",
    "martinshoehe": "Martinshöhe",
    "martinstein": "Martinstein",
    "marxen": "Marxen",
    "marxheim": "Marxheim",
    "marxzell": "Marxzell",
    "marzhausen": "Marzhausen",
    "marzling": "Marzling",
    "masburg": "Masburg",
    "maselheim": "Maselheim",
    "massbach": "Maßbach",
    "massenbachhausen": "Massenbachhausen",
    "massen-niederlausitz": "Massen-Niederlausitz",
    "masserberg": "Masserberg",
    "massing": "Massing",
    "massow": "Massow",
    "massweiler": "Maßweiler",
    "mastershausen": "Mastershausen",
    "masthorn": "Masthorn",
    "matzenbach": "Matzenbach",
    "matzerath": "Matzerath",
    "mauchenheim": "Mauchenheim",
    "mauden": "Mauden",
    "mauel": "Mauel",
    "mauer": "Mauer",
    "mauern": "Mauern",
    "mauerstetten": "Mauerstetten",
    "maulbronn": "Maulbronn",
    "maulburg": "Maulburg",
    "mauschbach": "Mauschbach",
    "mauth": "Mauth",
    "maxdorf": "Maxdorf",
    "maxhuette-haidhof": "Maxhütte-Haidhof",
    "maxsain": "Maxsain",
    "mayen": "Mayen",
    "mayschoss": "Mayschoß",
    "mechelroda": "Mechelroda",
    "mechernich": "Mechernich",
    "mechow": "Mechow",
    "mechtersen": "Mechtersen",
    "meckel": "Meckel",
    "meckenbach-kirn": "Meckenbach b. Kirn",
    "meckenbach-birkenfeld": "Meckenbach Kr. Birkenfeld",
    "meckenbeuren": "Meckenbeuren",
    "meckenheim-pfalz": "Meckenheim/Pfalz",
    "meckenheim-rhein": "Meckenheim/Rhein",
    "meckesheim": "Meckesheim",
    "medard": "Medard",
    "meddersheim": "Meddersheim",
    "meddewade": "Meddewade",
    "medebach": "Medebach",
    "medelby": "Medelby",
    "medlingen": "Medlingen",
    "medow": "Medow",
    "meeder": "Meeder",
    "meerane": "Meerane",
    "meerbeck": "Meerbeck",
    "meerbusch": "Meerbusch",
    "meerfeld": "Meerfeld",
    "meersburg": "Meersburg",
    "meesiger": "Meesiger",
    "meezen": "Meezen",
    "megesheim": "Megesheim",
    "meggerdorf": "Meggerdorf",
    "mehlbach": "Mehlbach",
    "mehlbek": "Mehlbek",
    "mehlingen": "Mehlingen",
    "mehlmeisel": "Mehlmeisel",
    "mehmels": "Mehmels",
    "mehna": "Mehna",
    "mehren-daun": "Mehren Kr. Daun",
    "mehren-westerwald": "Mehren/Westerwald",
    "mehring-altoetting": "Mehring Kr. Altötting",
    "mehring-mosel": "Mehring/Mosel",
    "mehrstetten": "Mehrstetten",
    "meiersberg": "Meiersberg",
    "meinborn": "Meinborn",
    "meine": "Meine",
    "meinersen": "Meinersen",
    "meinerzhagen": "Meinerzhagen",
    "meineweh": "Meineweh",
    "meinhard": "Meinhard",
    "meinheim": "Meinheim",
    "meiningen": "Meiningen",
    "meisburg": "Meisburg",
    "meisenheim": "Meisenheim",
    "meissen": "Meißen",
    "meissenheim": "Meißenheim",
    "meissner": "Meißner",
    "meitingen": "Meitingen",
    "melbeck": "Melbeck",
    "melchow": "Melchow",
    "meldorf": "Meldorf",
    "melle": "Melle",
    "mellenbach-glasbach": "Mellenbach-Glasbach",
    "mellenthin": "Mellenthin",
    "mellingen": "Mellingen",
    "mellinghausen": "Mellinghausen",
    "mellrichstadt": "Mellrichstadt",
    "melpers": "Melpers",
    "melsbach": "Melsbach",
    "melsdorf": "Melsdorf",
    "melsungen": "Melsungen",
    "melz": "Melz",
    "memmelsdorf": "Memmelsdorf",
    "memmingen": "Memmingen",
    "memmingerberg": "Memmingerberg",
    "menden-sauerland": "Menden/Sauerland",
    "mendig": "Mendig",
    "mengen": "Mengen",
    "mengerschied": "Mengerschied",
    "mengerskirchen": "Mengerskirchen",
    "mengkofen": "Mengkofen",
    "menningen": "Menningen",
    "menslage": "Menslage",
    "menteroda": "Menteroda",
    "menzendorf": "Menzendorf",
    "meppen": "Meppen",
    "merching": "Merching",
    "merchweiler": "Merchweiler",
    "merdingen": "Merdingen",
    "merenberg": "Merenberg",
    "mering": "Mering",
    "merkelbach": "Merkelbach",
    "merkendorf-mittelfranken": "Merkendorf/Mittelfranken",
    "merklingen": "Merklingen",
    "merlscheid": "Merlscheid",
    "mermuth": "Mermuth",
    "merschbach": "Merschbach",
    "merseburg": "Merseburg",
    "mertendorf-eisenberg": "Mertendorf b. Eisenberg",
    "mertendorf-naumburg": "Mertendorf b. Naumburg",
    "mertesdorf": "Mertesdorf",
    "mertesheim": "Mertesheim",
    "mertingen": "Mertingen",
    "mertloch": "Mertloch",
    "merxheim": "Merxheim",
    "merzalben": "Merzalben",
    "merzdorf-elsterwerda": "Merzdorf b. Elsterwerda",
    "merzen": "Merzen",
    "merzenich": "Merzenich",
    "merzhausen": "Merzhausen",
    "merzig": "Merzig",
    "merzkirchen": "Merzkirchen",
    "merzweiler": "Merzweiler",
    "meschede": "Meschede",
    "mescherin": "Mescherin",
    "mesekenhagen": "Mesekenhagen",
    "mesenich": "Mesenich",
    "mespelbrunn": "Mespelbrunn",
    "messel": "Messel",
    "messenkamp": "Messenkamp",
    "messerich": "Messerich",
    "messingen": "Messingen",
    "messkirch": "Meßkirch",
    "messstetten": "Meßstetten",
    "mestlin": "Mestlin",
    "metelen": "Metelen",
    "metelsdorf": "Metelsdorf",
    "metten": "Metten",
    "mettendorf": "Mettendorf",
    "mettenheim-muehldorf": "Mettenheim Kr. Mühldorf",
    "mettenheim-rheinhessen": "Mettenheim/Rheinhessen",
    "metterich": "Metterich",
    "mettingen": "Mettingen",
    "mettlach": "Mettlach",
    "mettmann": "Mettmann",
    "mettweiler": "Mettweiler",
    "metzels": "Metzels",
    "metzenhausen": "Metzenhausen",
    "metzingen": "Metzingen",
    "meudt": "Meudt",
    "meura": "Meura",
    "meusebach": "Meusebach",
    "meuselbach-schwarzmuehle": "Meuselbach-Schwarzmühle",
    "meuselwitz": "Meuselwitz",
    "meuspath": "Meuspath",
    "meyenburg": "Meyenburg",
    "meyn": "Meyn",
    "michelau-oberfranken": "Michelau i. Oberfranken",
    "michelau-steigerwald": "Michelau i. Steigerwald",
    "michelbach-an-der-bilz": "Michelbach an der Bilz",
    "michelbach-hunsrueck": "Michelbach/Hunsrück",
    "michelbach-westerwald": "Michelbach/Westerwald",
    "michelfeld": "Michelfeld",
    "michelsneukirchen": "Michelsneukirchen",
    "michelstadt": "Michelstadt",
    "michendorf": "Michendorf",
    "mickhausen": "Mickhausen",
    "middelhagen": "Middelhagen",
    "midlum-foehr": "Midlum/Föhr",
    "miehlen": "Miehlen",
    "mielkendorf": "Mielkendorf",
    "miellen": "Miellen",
    "miesbach": "Miesbach",
    "miesitz": "Miesitz",
    "mietingen": "Mietingen",
    "mihla": "Mihla",
    "milda": "Milda",
    "mildenau": "Mildenau",
    "mildstedt": "Mildstedt",
    "millienhagen-oebelitz": "Millienhagen-Oebelitz",
    "milmersdorf": "Milmersdorf",
    "milow-ludwigslust": "Milow b. Ludwigslust",
    "milower-land": "Milower Land",
    "miltach": "Miltach",
    "miltenberg": "Miltenberg",
    "mindelheim": "Mindelheim",
    "mindelstetten": "Mindelstetten",
    "minden-bitburg": "Minden Kr. Bitburg",
    "minden-westfalen": "Minden/Westfalen",
    "minderlittgen": "Minderlittgen",
    "minfeld": "Minfeld",
    "minheim": "Minheim",
    "mintraching": "Mintraching",
    "mirow": "Mirow",
    "misselberg": "Misselberg",
    "missen-wilhams": "Missen-Wilhams",
    "mistelbach": "Mistelbach",
    "mistelgau": "Mistelgau",
    "mistorf": "Mistorf",
    "mittelangeln": "Mittelangeln",
    "mittelbiberach": "Mittelbiberach",
    "mittelbrunn": "Mittelbrunn",
    "mitteleschenbach": "Mitteleschenbach",
    "mittelfischbach": "Mittelfischbach",
    "mittelherwigsdorf": "Mittelherwigsdorf",
    "mittelhof": "Mittelhof",
    "mittelneufnach": "Mittelneufnach",
    "mittelnkirchen": "Mittelnkirchen",
    "mittelpoellnitz": "Mittelpöllnitz",
    "mittelreidenbach": "Mittelreidenbach",
    "mittelsinn": "Mittelsinn",
    "mittelsoemmern": "Mittelsömmern",
    "mittelstenahe": "Mittelstenahe",
    "mittelstetten": "Mittelstetten",
    "mittelstrimmig": "Mittelstrimmig",
    "mittenaar": "Mittenaar",
    "mittenwald": "Mittenwald",
    "mittenwalde": "Mittenwalde",
    "mittenwalde-templin": "Mittenwalde b. Templin",
    "mitterfels": "Mitterfels",
    "mitterskirchen": "Mitterskirchen",
    "mitterteich": "Mitterteich",
    "mittweida": "Mittweida",
    "mitwitz": "Mitwitz",
    "mixdorf": "Mixdorf",
    "mochau-doebeln": "Mochau b. Döbeln",
    "moeckern-burg": "Möckern b. Burg",
    "moeckern-stadtroda": "Möckern b. Stadtroda",
    "moeckmuehl": "Möckmühl",
    "mockrehna": "Mockrehna",
    "modautal": "Modautal",
    "moedingen": "Mödingen",
    "moers": "Moers",
    "mogendorf": "Mogendorf",
    "moegglingen": "Mögglingen",
    "moeglingen": "Möglingen",
    "mohlsdorf-teichwolframsdorf": "Mohlsdorf-Teichwolframsdorf",
    "moehnesee": "Möhnesee",
    "moehnsen": "Möhnsen",
    "moehrendorf": "Möhrendorf",
    "mohrkirch": "Mohrkirch",
    "moisburg": "Moisburg",
    "molau": "Molauer Land",
    "molbergen": "Molbergen",
    "molfsee": "Molfsee",
    "moellenbeck-ludwigslust": "Möllenbeck b. Ludwigslust",
    "moellenbeck-neustrelitz": "Möllenbeck b. Neustrelitz",
    "moellenhagen": "Möllenhagen",
    "moelln-altentreptow": "Mölln b. Altentreptow",
    "moelln-lauenburg": "Mölln/Kr. Hzgt. Lauenburg",
    "molsberg": "Molsberg",
    "molschleben": "Molschleben",
    "moelschow": "Mölschow",
    "moelsheim": "Mölsheim",
    "moltzow": "Moltzow",
    "molzhain": "Molzhain",
    "moembris": "Mömbris",
    "moemlingen": "Mömlingen",
    "mommenheim": "Mommenheim",
    "moenchberg": "Mönchberg",
    "moenchengladbach": "Mönchengladbach",
    "moenchenholzhausen": "Mönchenholzhausen",
    "moenchgut": "Mönchgut",
    "moenchhagen": "Mönchhagen",
    "moenchpfiffel-nikolausrieth": "Mönchpfiffel-Nikolausrieth",
    "moenchsdeggingen": "Mönchsdeggingen",
    "moenchsroth": "Mönchsroth",
    "moenchweiler": "Mönchweiler",
    "monheim-am-rhein": "Monheim am Rhein",
    "monheim-schwaben": "Monheim/Schwaben",
    "moenkeberg": "Mönkeberg",
    "moenkebude": "Mönkebude",
    "moenkhagen": "Mönkhagen",
    "moenkloh": "Mönkloh",
    "monreal": "Monreal",
    "monschau": "Monschau",
    "monsheim": "Monsheim",
    "moensheim": "Mönsheim",
    "monstab": "Monstab",
    "montabaur": "Montabaur",
    "moentenich": "Möntenich",
    "monzelfeld": "Monzelfeld",
    "monzernheim": "Monzernheim",
    "monzingen": "Monzingen",
    "moordiek": "Moordiek",
    "moorenweis": "Moorenweis",
    "moorgrund": "Moorgrund",
    "moorhusen": "Moorhusen",
    "moormerland": "Moormerland",
    "moorrege": "Moorrege",
    "moorweg": "Moorweg",
    "moos-bodensee": "Moos/Bodensee",
    "moos-niederbayern": "Moos/Niederbayern",
    "moosach": "Moosach",
    "moosbach": "Moosbach",
    "moosburg-isar": "Moosburg a.d. Isar",
    "moosburg-buchau": "Moosburg b. Buchau",
    "moosinning": "Moosinning",
    "moosthenning": "Moosthenning",
    "moraas": "Moraas",
    "morbach": "Morbach",
    "moerel": "Mörel",
    "moerfelden-walldorf": "Mörfelden-Walldorf",
    "moringen": "Moringen",
    "moritzburg": "Moritzburg",
    "moritzheim": "Moritzheim",
    "moerlen": "Mörlen",
    "moerlenbach": "Mörlenbach",
    "moernsheim": "Mörnsheim",
    "morsbach": "Morsbach",
    "moersbach": "Mörsbach",
    "moerschbach": "Mörschbach",
    "morscheid": "Morscheid",
    "morschen": "Morschen",
    "morschheim": "Morschheim",
    "moerschied": "Mörschied",
    "moersdorf-stadtroda": "Mörsdorf b. Stadtroda",
    "moersdorf-hunsrueck": "Mörsdorf/Hunsrück",
    "moersfeld": "Mörsfeld",
    "morshausen": "Morshausen",
    "moerstadt": "Mörstadt",
    "mosbach": "Mosbach",
    "mosbruch": "Mosbruch",
    "moschheim": "Moschheim",
    "moselkern": "Moselkern",
    "moeser": "Möser",
    "mossautal": "Mossautal",
    "mossbach": "Moßbach",
    "moessingen": "Mössingen",
    "motten": "Motten",
    "moettingen": "Möttingen",
    "moetzing": "Mötzing",
    "moetzingen": "Mötzingen",
    "moxa": "Moxa",
    "moezen": "Mözen",
    "much": "Much",
    "mucheln": "Mucheln",
    "muecheln-geiseltal": "Mücheln/Geiseltal",
    "muchow": "Muchow",
    "muecka": "Mücka",
    "muecke": "Mücke",
    "mueckeln": "Mückeln",
    "mudau": "Mudau",
    "mueden-aller": "Müden/Aller",
    "mueden-mosel": "Müden/Mosel",
    "mudenbach": "Mudenbach",
    "mudersbach": "Mudersbach",
    "mudershausen": "Mudershausen",
    "muegeln": "Mügeln",
    "muggensturm": "Muggensturm",
    "mueglitztal": "Müglitztal",
    "muehbrook": "Mühbrook",
    "muehl-rosin": "Mühl Rosin",
    "muehlacker": "Mühlacker",
    "muehlau": "Mühlau",
    "muehlberg-elbe": "Mühlberg/Elbe",
    "muehldorf-inn": "Mühldorf a. Inn",
    "muehlen-eichsen": "Mühlen Eichsen",
    "muehlenbach": "Mühlenbach",
    "muehlenbarbek": "Mühlenbarbek",
    "muehlenbecker-land": "Mühlenbecker Land",
    "muehlenberge": "Mühlenberge",
    "muehlenfliess": "Mühlenfließ",
    "muehlenrade": "Mühlenrade",
    "muehlental": "Mühlental",
    "muehlhausen-im-taele": "Mühlhausen im Täle",
    "muehlhausen-kraichgau": "Mühlhausen/Kraichgau",
    "muehlhausen-mittelfranken": "Mühlhausen/Mittelfranken",
    "muehlhausen-oberpfalz": "Mühlhausen/Oberpfalz",
    "muehlhausen-thueringen": "Mühlhausen/Thüringen",
    "muehlhausen-ehingen": "Mühlhausen-Ehingen",
    "muehlheim-am-main": "Mühlheim am Main",
    "muehlheim-an-der-donau": "Mühlheim an der Donau",
    "muehlingen": "Mühlingen",
    "muehlpfad": "Mühlpfad",
    "muehltal": "Mühltal",
    "muhr-see": "Muhr a. See",
    "muelbach": "Mülbach",
    "mulda-sachsen": "Mulda/Sachsen",
    "muldenhammer": "Muldenhammer",
    "muldestausee": "Muldestausee",
    "mulfingen": "Mulfingen",
    "muelheim-an-der-mosel": "Mülheim an der Mosel",
    "muelheim-an-der-ruhr": "Mülheim an der Ruhr",
    "muelheim-kaerlich": "Mülheim-Kärlich",
    "muellenbach-adenau": "Müllenbach b. Adenau",
    "muellenbach-mayen": "Müllenbach b. Mayen",
    "muellheim": "Müllheim",
    "muellrose": "Müllrose",
    "muelsen": "Mülsen",
    "muelverstedt": "Mülverstedt",
    "muenchberg": "Münchberg",
    "muencheberg": "Müncheberg",
    "muenchehofe-koenigs-wusterhausen": "Münchehofe b. Königs Wusterhausen",
    "muenchen": "München",
    "muenchenbernsdorf": "Münchenbernsdorf",
    "muenchhausen-christenberg": "Münchhausen a. Christenberg",
    "muenchsmuenster": "Münchsmünster",
    "muenchsteinach": "Münchsteinach",
    "muenchwald": "Münchwald",
    "muenchweiler-am-klingbach": "Münchweiler am Klingbach",
    "muenchweiler-an-der-alsenz": "Münchweiler an der Alsenz",
    "muenchweiler-an-der-rodalb": "Münchweiler an der Rodalb",
    "mundelsheim": "Mundelsheim",
    "munderkingen": "Munderkingen",
    "muendersbach": "Mündersbach",
    "muenk": "Münk",
    "munkbrarup": "Munkbrarup",
    "muennerstadt": "Münnerstadt",
    "munningen": "Munningen",
    "muensing": "Münsing",
    "muensingen": "Münsingen",
    "munster": "Munster",
    "muenster-westfalen": "Münster",
    "muenster-hessen": "Münster (Hessen)",
    "muenster-dieburg": "Münster b. Dieburg",
    "muenster-lech": "Münster/Lech",
    "muensterappel": "Münsterappel",
    "muensterdorf": "Münsterdorf",
    "muensterhausen": "Münsterhausen",
    "muenstermaifeld": "Münstermaifeld",
    "muenster-sarmsheim": "Münster-Sarmsheim",
    "muenstertal-schwarzwald": "Münstertal/Schwarzwald",
    "muenzenberg": "Münzenberg",
    "murchin": "Murchin",
    "murg": "Murg",
    "muerlenbach": "Mürlenbach",
    "murnau-staffelsee": "Murnau a. Staffelsee",
    "murr": "Murr",
    "murrhardt": "Murrhardt",
    "muesch": "Müsch",
    "mueschenbach": "Müschenbach",
    "muessen": "Müssen",
    "mustin-ratzeburg": "Mustin b. Ratzeburg",
    "mustin-stern": "Mustin b. Sternb.",
    "musweiler": "Musweiler",
    "mutlangen": "Mutlangen",
    "mutterschied": "Mutterschied",
    "mutterstadt": "Mutterstadt",
    "muetzenich": "Mützenich",
    "muxerath": "Muxerath",
    "mylau": "Mylau",
    "nabburg": "Nabburg",
    "nachrodt-wiblingwerde": "Nachrodt-Wiblingwerde",
    "nachtsheim": "Nachtsheim",
    "nack": "Nack",
    "nackenheim": "Nackenheim",
    "nadrensee": "Nadrensee",
    "nagel": "Nagel",
    "nagold": "Nagold",
    "nahe": "Nahe",
    "nahetal-waldau": "Nahetal-Waldau",
    "nahrendorf": "Nahrendorf",
    "naila": "Naila",
    "nalbach": "Nalbach",
    "namborn": "Namborn",
    "nandlstadt": "Nandlstadt",
    "nannhausen": "Nannhausen",
    "nanzdietschweiler": "Nanzdietschweiler",
    "narsdorf": "Narsdorf",
    "nasingen": "Nasingen",
    "nassau": "Nassau",
    "nassenfels": "Nassenfels",
    "nastaetten": "Nastätten",
    "natendorf": "Natendorf",
    "nattenheim": "Nattenheim",
    "nattheim": "Nattheim",
    "nauen": "Nauen",
    "nauendorf-kranichfeld": "Nauendorf b. Kranichfeld",
    "nauheim": "Nauheim",
    "naumburg-saale": "Naumburg (Saale)",
    "naumburg-hessen": "Naumburg/Hessen",
    "naundorf-oschatz": "Naundorf b. Oschatz",
    "naunheim": "Naunheim",
    "naunhof-muldentalkreis": "Naunhof/Muldentalkreis",
    "nauort": "Nauort",
    "naurath-eifel": "Naurath/Eifel",
    "naurath-wald": "Naurath/Wald",
    "nauroth": "Nauroth",
    "nausitz": "Nausitz",
    "nausnitz": "Nausnitz",
    "nazza": "Nazza",
    "nebel": "Nebel",
    "nebelschuetz": "Nebelschütz",
    "nebra-unstrut": "Nebra (Unstrut)",
    "neckarbischofsheim": "Neckarbischofsheim",
    "neckargemuend": "Neckargemünd",
    "neckargerach": "Neckargerach",
    "neckarsteinach": "Neckarsteinach",
    "neckarsulm": "Neckarsulm",
    "neckartailfingen": "Neckartailfingen",
    "neckartenzlingen": "Neckartenzlingen",
    "neckarwestheim": "Neckarwestheim",
    "neckarzimmern": "Neckarzimmern",
    "neddemin": "Neddemin",
    "neef": "Neef",
    "neenstetten": "Neenstetten",
    "neetze": "Neetze",
    "neetzka": "Neetzka",
    "neetzow-liepen": "Neetzow-Liepen",
    "negenborn": "Negenborn",
    "negenharrie": "Negenharrie",
    "negernboetel": "Negernbötel",
    "nehms": "Nehms",
    "nehmten": "Nehmten",
    "nehren-mosel": "Nehren/Mosel",
    "nehren-wuerttemberg": "Nehren/Württemberg",
    "neichen": "Neichen",
    "neidenbach": "Neidenbach",
    "neidenfels": "Neidenfels",
    "neidenstein": "Neidenstein",
    "neidhartshausen": "Neidhartshausen",
    "neidlingen": "Neidlingen",
    "neisseaue": "Neißeaue",
    "neisse-malxetal": "Neiße-Malxetal",
    "neissemuende": "Neißemünde",
    "neitersen": "Neitersen",
    "nellingen": "Nellingen",
    "nemsdorf-goehrendorf": "Nemsdorf-Göhrendorf",
    "nenndorf": "Nenndorf",
    "nennhausen": "Nennhausen",
    "nennslingen": "Nennslingen",
    "nentershausen-hessen": "Nentershausen/Hessen",
    "nentershausen-westerwald": "Nentershausen/Westerwald",
    "nerdlen": "Nerdlen",
    "nerenstetten": "Nerenstetten",
    "neresheim": "Neresheim",
    "neritz": "Neritz",
    "neroth": "Neroth",
    "nersingen": "Nersingen",
    "nerzweiler": "Nerzweiler",
    "neschwitz": "Neschwitz",
    "nesse-apfelstaedt": "Nesse-Apfelstädt",
    "nesselwang": "Nesselwang",
    "nessetal": "Nessetal",
    "netphen": "Netphen",
    "nettelsee": "Nettelsee",
    "nettersheim": "Nettersheim",
    "nettetal": "Nettetal",
    "netzbach": "Netzbach",
    "netzschkau": "Netzschkau",
    "neu-bartelshagen": "Neu Bartelshagen",
    "neu-boltenhagen": "Neu Boltenhagen",
    "neu-darchau": "Neu Darchau",
    "neu-duvenstedt": "Neu Duvenstedt",
    "neu-guelze": "Neu Gülze",
    "neu-kaliss": "Neu Kaliß",
    "neu-kosenow": "Neu Kosenow",
    "neu-poserin": "Neu Poserin",
    "neu-wulmstorf": "Neu Wulmstorf",
    "neu-zauche": "Neu Zauche",
    "neualbenreuth": "Neualbenreuth",
    "neu-anspach": "Neu-Anspach",
    "neu-bamberg": "Neu-Bamberg",
    "neuberend": "Neuberend",
    "neuberg": "Neuberg",
    "neubeuern": "Neubeuern",
    "neubiberg": "Neubiberg",
    "neuboerger": "Neubörger",
    "neubrandenburg": "Neubrandenburg",
    "neubrunn-meiningen": "Neubrunn b. Meiningen",
    "neubrunn-wuerzburg": "Neubrunn b. Würzburg",
    "neubukow": "Neubukow",
    "neubulach": "Neubulach",
    "neuburg-inn": "Neuburg a. Inn",
    "neuburg-donau": "Neuburg a.d. Donau",
    "neuburg-kammel": "Neuburg a.d. Kammel",
    "neuburg-am-rhein": "Neuburg am Rhein",
    "neuburg-nordwestmecklenburg": "Neuburg-Steinhausen",
    "neuching": "Neuching",
    "neudenau": "Neudenau",
    "neudorf-bornstein": "Neudorf-Bornstein",
    "neudrossenfeld": "Neudrossenfeld",
    "neu-eichenberg": "Neu-Eichenberg",
    "neuenbrook": "Neuenbrook",
    "neuenbuerg": "Neuenbürg",
    "neuenburg-am-rhein": "Neuenburg am Rhein",
    "neuendeich": "Neuendeich",
    "neuendettelsau": "Neuendettelsau",
    "neuendorf-elmshorn": "Neuendorf b. Elmshorn",
    "neuendorf-eifel": "Neuendorf/Eifel",
    "neuendorf-main": "Neuendorf/Main",
    "neuendorf-sachsenbande": "Neuendorf-Sachsenbande",
    "neuengoenna": "Neuengönna",
    "neuengoers": "Neuengörs",
    "neuenhagen-berlin": "Neuenhagen b. Berlin",
    "neuenhaus": "Neuenhaus",
    "neuenkirchen-anklam": "Neuenkirchen b. Anklam",
    "neuenkirchen-bramsche": "Neuenkirchen b. Bramsche",
    "neuenkirchen-greifswald": "Neuenkirchen b. Greifswald",
    "neuenkirchen-horneburg": "Neuenkirchen b. Horneburg",
    "neuenkirchen-neubrandenburg": "Neuenkirchen b. Neubrandenburg",
    "neuenkirchen-bassum": "Neuenkirchen b.Bassum",
    "neuenkirchen-steinfurt": "Neuenkirchen Kr.Steinfurt",
    "neuenkirchen-dithmarschen": "Neuenkirchen/Dithmarschen",
    "neuenkirchen-hadeln": "Neuenkirchen/Hadeln",
    "neuenkirchen-lueneheide": "Neuenkirchen/Lüneb.Heide",
    "neuenkirchen-ruegen": "Neuenkirchen/Rügen",
    "neuenkirchen-voerden": "Neuenkirchen-Vörden",
    "neuenmarkt": "Neuenmarkt",
    "neuenrade": "Neuenrade",
    "neuensalz": "Neuensalz",
    "neuenstadt-am-kocher": "Neuenstadt am Kocher",
    "neuenstein-hessen": "Neuenstein/Hessen",
    "neuenstein-wuerttemberg": "Neuenstein/Württemberg",
    "neuental": "Neuental",
    "neuerburg": "Neuerburg",
    "neuerkirch": "Neuerkirch",
    "neufahrn-freising": "Neufahrn b. Freising",
    "neufahrn-niederbayern": "Neufahrn i. Niederbayern",
    "neufeld": "Neufeld",
    "neufelderkoog": "Neufelderkoog",
    "neuffen": "Neuffen",
    "neufra": "Neufra",
    "neufraunhofen": "Neufraunhofen",
    "neuhardenberg": "Neuhardenberg",
    "neuharlingersiel": "Neuharlingersiel",
    "neuhaus-inn": "Neuhaus a. Inn",
    "neuhaus-pegnitz": "Neuhaus a.d. Pegnitz",
    "neuhaus-am-rennweg": "Neuhaus am Rennweg",
    "neuhaus-oste": "Neuhaus/Oste",
    "neuhaeusel": "Neuhäusel",
    "neuhausen-auf-den-fildern": "Neuhausen auf den Fildern",
    "neuhausen-ob-eck": "Neuhausen ob Eck",
    "neuhausen-enzkreis": "Neuhausen/Enzkreis",
    "neuhausen-erzgebirge": "Neuhausen/Erzgebirge",
    "neuhausen-spree": "Neuhausen/Spree",
    "neuhaus-schierschnitz": "Neuhaus-Schierschnitz",
    "neuheilenbach": "Neuheilenbach",
    "neuhemsbach": "Neuhemsbach",
    "neuhof-zenn": "Neuhof a.d. Zenn",
    "neuhof-hildesheim": "Neuhof b. Hildesheim",
    "neuhof-fulda": "Neuhof Kr. Fulda",
    "neuhofen": "Neuhofen",
    "neuhuetten-hunsrueck": "Neuhütten/Hunsrück",
    "neuhuetten-unterfranken": "Neuhütten/Unterfranken",
    "neu-isenburg": "Neu-Isenburg",
    "neukalen": "Neukalen",
    "neukamperfehn": "Neukamperfehn",
    "neukieritzsch": "Neukieritzsch",
    "neukirch-lausitz": "Neukirch (Lausitz)",
    "neukirch-koenigsbrueck": "Neukirch b. Königsbrück",
    "neukirch-tettnang": "Neukirch b. Tettnang",
    "neukirchen-bogen": "Neukirchen b. Bogen",
    "neukirchen-heiligenblut": "Neukirchen b. Heiligenblut",
    "neukirchen-niebuell": "Neukirchen b. Niebüll",
    "neukirchen-oldenburg": "Neukirchen b. Oldenburg",
    "neukirchen-sulzbach-rosenberg": "Neukirchen b. Sulzbach-Rosenberg",
    "neukirchen-vorm-wald": "Neukirchen vorm Wald",
    "neukirchen-erzgebirge": "Neukirchen/Erzgebirge",
    "neukirchen-knuellgebirge": "Neukirchen/Knüllgebirge",
    "neukirchen-pleisse": "Neukirchen/Pleiße",
    "neukirchen-balbini": "Neukirchen-Balbini",
    "neukirchen-vluyn": "Neukirchen-Vluyn",
    "neukloster": "Neukloster",
    "neulehe": "Neulehe",
    "neuleiningen": "Neuleiningen",
    "neuler": "Neuler",
    "neulewin": "Neulewin",
    "neulingen-enzkreis": "Neulingen/Enzkreis",
    "neulussheim": "Neulußheim",
    "neumagen-dhron": "Neumagen-Dhron",
    "neumark-weimar": "Neumark b. Weimar",
    "neumark-sachsen": "Neumark/Sachsen",
    "neumarkt-oberpfalz": "Neumarkt i.d. Oberpfalz",
    "neumarkt-sankt-veit": "Neumarkt-Sankt Veit",
    "neumuenster": "Neumünster",
    "neunburg-vorm-wald": "Neunburg vorm Wald",
    "neundorf-lobenstein": "Neundorf b. Lobenstein",
    "neundorf-schleiz": "Neundorf b. Schleiz",
    "neunheilingen": "Neunheilingen",
    "neunkhausen": "Neunkhausen",
    "neunkirchen-brand": "Neunkirchen a. Brand",
    "neunkirchen-sand": "Neunkirchen a. Sand",
    "neunkirchen-am-potzberg": "Neunkirchen am Potzberg",
    "neunkirchen-mosbach": "Neunkirchen b. Mosbach",
    "neunkirchen-hunsrueck": "Neunkirchen/Hunsrück",
    "neunkirchen-saar": "Neunkirchen/Saar",
    "neunkirchen-siegerland": "Neunkirchen/Siegerland",
    "neunkirchen-unterfranken": "Neunkirchen/Unterfranken",
    "neunkirchen-westerwald": "Neunkirchen/Westerwald",
    "neunkirchen-seelscheid": "Neunkirchen-Seelscheid",
    "neuoetting": "Neuötting",
    "neupetershain": "Neupetershain",
    "neupotz": "Neupotz",
    "neureichenau": "Neureichenau",
    "neuried-muenchen": "Neuried Kr. München",
    "neuried-ortenaukreis": "Neuried/Ortenaukreis",
    "neuruppin": "Neuruppin",
    "neusalza-spremberg": "Neusalza-Spremberg",
    "neusaess": "Neusäß",
    "neuschoenau": "Neuschönau",
    "neuschoo": "Neuschoo",
    "neu-seeland": "Neu-Seeland",
    "neusiss": "Neusiß",
    "neusitz": "Neusitz",
    "neusorg": "Neusorg",
    "neuss": "Neuss",
    "neustadt-main": "Neustadt a. Main",
    "neustadt-aisch": "Neustadt a.d. Aisch",
    "neustadt-orla": "Neustadt a.d. Orla",
    "neustadt-waldnaab": "Neustadt a.d. Waldnaab",
    "neustadt-am-kulm": "Neustadt am Kulm",
    "neustadt-am-rennsteig": "Neustadt am Rennsteig",
    "neustadt-ruebenberge": "Neustadt am Rübenberge",
    "neustadt-coburg": "Neustadt b. Coburg",
    "neustadt-sachsen": "Neustadt in Sachsen",
    "neustadt-donau": "Neustadt/Donau",
    "neustadt-dosse": "Neustadt/Dosse",
    "neustadt-harz": "Neustadt/Harz",
    "neustadt-hessen": "Neustadt/Hessen",
    "neustadt-holstein": "Neustadt/Holstein",
    "neustadt-vogtland": "Neustadt/Vogtland",
    "neustadt-weinstrasse": "Neustadt/Weinstraße",
    "neustadt-westerwald": "Neustadt/Westerwald",
    "neustadt-wied": "Neustadt/Wied",
    "neustadt-glewe": "Neustadt-Glewe",
    "neustetten": "Neustetten",
    "neustrelitz": "Neustrelitz",
    "neutraubling": "Neutraubling",
    "neutrebbin": "Neutrebbin",
    "neu-ulm": "Neu-Ulm",
    "neuweiler": "Neuweiler",
    "neuwied": "Neuwied",
    "neuwittenbek": "Neuwittenbek",
    "neuzelle": "Neuzelle",
    "neverin": "Neverin",
    "neversdorf": "Neversdorf",
    "newel": "Newel",
    "ney": "Ney",
    "nickenich": "Nickenich",
    "nidda": "Nidda",
    "niddatal": "Niddatal",
    "nidderau": "Nidderau",
    "nideggen": "Nideggen",
    "nieblum": "Nieblum",
    "niebuell": "Niebüll",
    "nieby": "Nieby",
    "nieden": "Nieden",
    "niedenstein": "Niedenstein",
    "nieder-kostenz": "Nieder Kostenz",
    "niederahr": "Niederahr",
    "niederaichbach": "Niederaichbach",
    "niederalben": "Niederalben",
    "niederalteich": "Niederalteich",
    "niederau": "Niederau",
    "niederaula": "Niederaula",
    "niederbachheim": "Niederbachheim",
    "niederbergkirchen": "Niederbergkirchen",
    "niederboesa": "Niederbösa",
    "niederbreitbach": "Niederbreitbach",
    "niederbrombach": "Niederbrombach",
    "niederburg": "Niederburg",
    "niederdorf": "Niederdorf",
    "niederdorfelden": "Niederdorfelden",
    "niederdreisbach": "Niederdreisbach",
    "niederduerenbach": "Niederdürenbach",
    "niedere-boerde": "Niedere Börde",
    "niederelbert": "Niederelbert",
    "niederer-flaeming": "Niederer Fläming",
    "niedererbach": "Niedererbach",
    "niedereschach": "Niedereschach",
    "niederfell": "Niederfell",
    "niederfinow": "Niederfinow",
    "niederfischbach": "Niederfischbach",
    "niederfrohna": "Niederfrohna",
    "niederfuellbach": "Niederfüllbach",
    "niedergebra": "Niedergebra",
    "niedergeckler": "Niedergeckler",
    "niedergoersdorf": "Niedergörsdorf",
    "niederhambach": "Niederhambach",
    "niederhausen-an-der-appel": "Niederhausen an der Appel",
    "niederhausen-nahe": "Niederhausen/Nahe",
    "niederheimbach": "Niederheimbach",
    "nieder-hilbersheim": "Nieder-Hilbersheim",
    "niederhofen": "Niederhofen",
    "niederhorbach": "Niederhorbach",
    "niederhosenbach": "Niederhosenbach",
    "niederirsen": "Niederirsen",
    "niederkassel": "Niederkassel",
    "niederkirchen-deidesheim": "Niederkirchen b. Deidesheim",
    "niederkirchen-kaiserslautern": "Niederkirchen Kr. Kaiserslautern",
    "niederkruechten": "Niederkrüchten",
    "niederkumbd": "Niederkumbd",
    "niederlangen": "Niederlangen",
    "niederlauch": "Niederlauch",
    "niederlauer": "Niederlauer",
    "niedermohr": "Niedermohr",
    "niedermoschel": "Niedermoschel",
    "niedermurach": "Niedermurach",
    "niedernberg": "Niedernberg",
    "niederneisen": "Niederneisen",
    "niedernhall": "Niedernhall",
    "niedernhausen": "Niedernhausen",
    "niedernwoehren": "Niedernwöhren",
    "niederoefflingen": "Niederöfflingen",
    "nieder-olm": "Nieder-Olm",
    "niederorschel": "Niederorschel",
    "niederotterbach": "Niederotterbach",
    "niederpierscheid": "Niederpierscheid",
    "niederraden": "Niederraden",
    "niederrieden": "Niederrieden",
    "niederrossbach": "Niederroßbach",
    "niedersayn": "Niedersayn",
    "niederscheidweiler": "Niederscheidweiler",
    "niederschlettenbach": "Niederschlettenbach",
    "niederschoenenfeld": "Niederschönenfeld",
    "niedersohren": "Niedersohren",
    "niederstadtfeld": "Niederstadtfeld",
    "niederstaufenbach": "Niederstaufenbach",
    "niederstedem": "Niederstedem",
    "niedersteinebach": "Niedersteinebach",
    "niederstetten": "Niederstetten",
    "niederstotzingen": "Niederstotzingen",
    "niedert": "Niedert",
    "niedertaufkirchen": "Niedertaufkirchen",
    "niedertiefenbach": "Niedertiefenbach",
    "niedertrebra": "Niedertrebra",
    "niederviehbach": "Niederviehbach",
    "niederwallmenach": "Niederwallmenach",
    "niederwambach": "Niederwambach",
    "niederweiler-eifel": "Niederweiler/Eifel",
    "niederweiler-hunsrueck": "Niederweiler/Hunsrück",
    "niederweis": "Niederweis",
    "niederwerrn": "Niederwerrn",
    "niederwerth": "Niederwerth",
    "niederwiesa": "Niederwiesa",
    "nieder-wiesen": "Nieder-Wiesen",
    "niederwinkling": "Niederwinkling",
    "niederwoerresbach": "Niederwörresbach",
    "niederwuerschnitz": "Niederwürschnitz",
    "niederzier": "Niederzier",
    "niederzimmern": "Niederzimmern",
    "niederzissen": "Niederzissen",
    "niefern-oeschelbronn": "Niefern-Öschelbronn",
    "nieheim": "Nieheim",
    "niehl": "Niehl",
    "niemegk": "Niemegk",
    "niemetal": "Niemetal",
    "nienborstel": "Nienborstel",
    "nienburg-saale": "Nienburg (Saale)",
    "nienburg-weser": "Nienburg/Weser",
    "nienbuettel": "Nienbüttel",
    "niendorf-berkenthin": "Niendorf b. Berkenthin",
    "niendorf-schoen": "Niendorf b. Schönb.",
    "niendorf-stecknitz": "Niendorf/Stecknitz",
    "nienhagen-celle": "Nienhagen b. Celle",
    "nienhagen-ostsee": "Nienhagen/Ostsee",
    "nienstaedt": "Nienstädt",
    "nienwohld": "Nienwohld",
    "niepars": "Niepars",
    "niersbach": "Niersbach",
    "nierstein": "Nierstein",
    "niesgrau": "Niesgrau",
    "niesky": "Niesky",
    "nieste": "Nieste",
    "niestetal": "Niestetal",
    "nievern": "Nievern",
    "nimritz": "Nimritz",
    "nimshuscheid": "Nimshuscheid",
    "nimsreuland": "Nimsreuland",
    "nindorf-meldorf": "Nindorf b. Meldorf",
    "nindorf-neumuenster": "Nindorf b. Neumünster",
    "nister-westerwald": "Nister/Westerwald",
    "nisterau": "Nisterau",
    "nisterberg": "Nisterberg",
    "nister-moehrendorf": "Nister-Möhrendorf",
    "nistertal": "Nistertal",
    "nittel": "Nittel",
    "nittenau": "Nittenau",
    "nittendorf": "Nittendorf",
    "nitz": "Nitz",
    "noebdenitz": "Nöbdenitz",
    "nobitz": "Nobitz",
    "nochern": "Nochern",
    "noeda": "Nöda",
    "noer": "Noer",
    "nohen": "Nohen",
    "nohfelden": "Nohfelden",
    "nohn": "Nohn",
    "nohra-nordhausen": "Nohra b. Nordhausen",
    "nohra-weimar": "Nohra b. Weimar",
    "nomborn": "Nomborn",
    "nonnenhorn": "Nonnenhorn",
    "nonnweiler": "Nonnweiler",
    "norath": "Norath",
    "norddeich": "Norddeich",
    "norddorf": "Norddorf auf Amrum",
    "norden": "Norden",
    "nordendorf": "Nordendorf",
    "nordenham": "Nordenham",
    "norderbrarup": "Norderbrarup",
    "norderfriedrichskoog": "Norderfriedrichskoog",
    "norderheistedt": "Norderheistedt",
    "nordermeldorf": "Nordermeldorf",
    "norderney": "Norderney",
    "norderstapel": "Norderstapel",
    "norderstedt": "Norderstedt",
    "norderwoehrden": "Norderwöhrden",
    "nordhackstedt": "Nordhackstedt",
    "nordhalben": "Nordhalben",
    "nordharz": "Nordharz",
    "nordhastedt": "Nordhastedt",
    "nordhausen": "Nordhausen",
    "nordheim-main": "Nordheim a. Main",
    "nordheim-rhoen": "Nordheim v.d. Rhön",
    "nordheim-wuerttemberg": "Nordheim/Württemberg",
    "nordhofen": "Nordhofen",
    "nordhorn": "Nordhorn",
    "nordkirchen": "Nordkirchen",
    "nordleda": "Nordleda",
    "noerdlingen": "Nördlingen",
    "nordrach": "Nordrach",
    "nordsehl": "Nordsehl",
    "nordstemmen": "Nordstemmen",
    "nordstrand-gemeinde": "Nordstrand (Gemeinde)",
    "nordwalde": "Nordwalde",
    "nordwestuckermark": "Nordwestuckermark",
    "norheim": "Norheim",
    "norken": "Norken",
    "norstedt": "Norstedt",
    "noerten-hardenberg": "Nörten-Hardenberg",
    "noertershausen": "Nörtershausen",
    "northeim": "Northeim",
    "nortmoor": "Nortmoor",
    "nortorf-neumuenster": "Nortorf b. Neumünster",
    "nortorf-wilster": "Nortorf b. Wilster",
    "nortrup": "Nortrup",
    "noervenich": "Nörvenich",
    "nossen": "Nossen",
    "nossendorf": "Nossendorf",
    "nossentiner-huette": "Nossentiner Hütte",
    "nostorf": "Nostorf",
    "nothweiler": "Nothweiler",
    "nottensdorf": "Nottensdorf",
    "nottertal-heilinger-hoehen": "Nottertal-Heilinger Höhen",
    "nottfeld": "Nottfeld",
    "nottleben": "Nottleben",
    "nottuln": "Nottuln",
    "notzingen": "Notzingen",
    "nuebbel": "Nübbel",
    "nuebel": "Nübel",
    "nuedlingen": "Nüdlingen",
    "nufringen": "Nufringen",
    "nuembrecht": "Nümbrecht",
    "nuenchritz": "Nünchritz",
    "nuenschweiler": "Nünschweiler",
    "nuerburg": "Nürburg",
    "nuernberg": "Nürnberg",
    "nuertingen": "Nürtingen",
    "nusbaum": "Nusbaum",
    "nusplingen": "Nusplingen",
    "nussbach": "Nußbach",
    "nussbaum": "Nußbaum",
    "nussdorf-inn": "Nußdorf a. Inn",
    "nussdorf-chiemgau": "Nußdorf/Chiemgau",
    "nusse": "Nusse",
    "nussloch": "Nußloch",
    "nustrow": "Nustrow",
    "nuesttal": "Nüsttal",
    "nuthetal": "Nuthetal",
    "nuthe-urstromtal": "Nuthe-Urstromtal",
    "nutteln": "Nutteln",
    "nuetzen": "Nützen",
    "ober-kostenz": "Ober Kostenz",
    "oberahr": "Oberahr",
    "oberalben": "Oberalben",
    "oberammergau": "Oberammergau",
    "oberarnbach": "Oberarnbach",
    "oberasbach": "Oberasbach",
    "oberau": "Oberau",
    "oberaudorf": "Oberaudorf",
    "oberaula": "Oberaula",
    "oberaurach": "Oberaurach",
    "oberbachheim": "Oberbachheim",
    "oberbarnim": "Oberbarnim",
    "oberbergkirchen": "Oberbergkirchen",
    "oberbettingen": "Oberbettingen",
    "oberbillig": "Oberbillig",
    "oberbodnitz": "Oberbodnitz",
    "oberboihingen": "Oberboihingen",
    "oberboesa": "Oberbösa",
    "oberbrombach": "Oberbrombach",
    "oberdachstetten": "Oberdachstetten",
    "oberderdingen": "Oberderdingen",
    "oberdiebach": "Oberdiebach",
    "oberding": "Oberding",
    "oberdischingen": "Oberdischingen",
    "oberdolling": "Oberdolling",
    "oberdreis": "Oberdreis",
    "oberduerenbach": "Oberdürenbach",
    "obere-warnow": "Obere Warnow",
    "oberehe-stroheich": "Oberehe-Stroheich",
    "oberelbert": "Oberelbert",
    "oberelsbach": "Oberelsbach",
    "oberelz": "Oberelz",
    "obererbach-montabaur": "Obererbach b. Montabaur",
    "obererbach-westerwald": "Obererbach b. Westerwald",
    "oberfell": "Oberfell",
    "oberfischbach": "Oberfischbach",
    "ober-floersheim": "Ober-Flörsheim",
    "obergeckler": "Obergeckler",
    "obergriesbach": "Obergriesbach",
    "obergroeningen": "Obergröningen",
    "oberguenzburg": "Obergünzburg",
    "obergurig": "Obergurig",
    "oberhaching": "Oberhaching",
    "oberhaid-oberfranken": "Oberhaid/Oberfranken",
    "oberhaid-westerwald": "Oberhaid/Westerwald",
    "oberhain": "Oberhain",
    "oberhambach": "Oberhambach",
    "oberharmersbach": "Oberharmersbach",
    "oberharz-am-brocken": "Oberharz am Brocken",
    "oberhausen-rheinland": "Oberhausen",
    "oberhausen-nahe": "Oberhausen a.d. Nahe",
    "oberhausen-an-der-appel": "Oberhausen an der Appel",
    "oberhausen-kirn": "Oberhausen b. Kirn",
    "oberhausen-neuburg": "Oberhausen b. Neuburg",
    "oberhausen-bergzabern": "Oberhausen/b. Bergzabern",
    "oberhausen-oberbayern": "Oberhausen/Oberbayern",
    "oberhausen-rheinhausen": "Oberhausen-Rheinhausen",
    "oberheimbach": "Oberheimbach",
    "oberheldrungen": "Oberheldrungen",
    "ober-hilbersheim": "Ober-Hilbersheim",
    "oberhof": "Oberhof",
    "oberhonnefeld-gierend": "Oberhonnefeld-Gierend",
    "oberhosenbach": "Oberhosenbach",
    "oberickelsheim": "Oberickelsheim",
    "oberirsen": "Oberirsen",
    "oberkail": "Oberkail",
    "oberkatz": "Oberkatz",
    "oberkirch": "Oberkirch",
    "oberkirn": "Oberkirn",
    "oberkochen": "Oberkochen",
    "oberkotzau": "Oberkotzau",
    "oberkraemer": "Oberkrämer",
    "oberlahr": "Oberlahr",
    "oberlangen": "Oberlangen",
    "oberlascheid": "Oberlascheid",
    "oberlauch": "Oberlauch",
    "oberleichtersbach": "Oberleichtersbach",
    "oberlungwitz": "Oberlungwitz",
    "obermaiselstein": "Obermaiselstein",
    "obermarchtal": "Obermarchtal",
    "obermassfeld-grimmenthal": "Obermaßfeld-Grimmenthal",
    "obermehler": "Obermehler",
    "obermeitingen": "Obermeitingen",
    "obermichelbach": "Obermichelbach",
    "ober-moerlen": "Ober-Mörlen",
    "obermoschel": "Obermoschel",
    "obernau": "Obernau",
    "obernbreit": "Obernbreit",
    "obernburg-main": "Obernburg a. Main",
    "oberndorf-lech": "Oberndorf a. Lech",
    "oberndorf-am-neckar": "Oberndorf am Neckar",
    "oberndorf-oste": "Oberndorf/Oste",
    "oberndorf-pfalz": "Oberndorf/Pfalz",
    "oberneisen": "Oberneisen",
    "oberneukirchen": "Oberneukirchen",
    "obernfeld": "Obernfeld",
    "obernheim-wuerttemberg": "Obernheim/Württemberg",
    "obernheim-kirchenarnbach": "Obernheim-Kirchenarnbach",
    "obernhof": "Obernhof",
    "obernholz": "Obernholz",
    "obernkirchen": "Obernkirchen",
    "obernzell": "Obernzell",
    "obernzenn": "Obernzenn",
    "oberoefflingen": "Oberöfflingen",
    "ober-olm": "Ober-Olm",
    "oberoppurg": "Oberoppurg",
    "oberostendorf": "Oberostendorf",
    "oberotterbach": "Oberotterbach",
    "oberottmarshausen": "Oberottmarshausen",
    "oberpframmern": "Oberpframmern",
    "oberpierscheid": "Oberpierscheid",
    "oberpleichfeld": "Oberpleichfeld",
    "oberpoering": "Oberpöring",
    "oberraden": "Oberraden",
    "ober-ramstadt": "Ober-Ramstadt",
    "oberreichenbach-herzogenaurach": "Oberreichenbach b. Herzogenaurach",
    "oberreichenbach-wuerttemberg": "Oberreichenbach/Württemberg",
    "oberreidenbach": "Oberreidenbach",
    "oberreute": "Oberreute",
    "oberried": "Oberried",
    "oberrieden": "Oberrieden",
    "oberriexingen": "Oberriexingen",
    "oberrod": "Oberrod",
    "oberrossbach": "Oberroßbach",
    "oberrot": "Oberrot",
    "oberroth": "Oberroth",
    "oberscheidweiler": "Oberscheidweiler",
    "oberscheinfeld": "Oberscheinfeld",
    "oberschleissheim": "Oberschleißheim",
    "oberschlettenbach": "Oberschlettenbach",
    "oberschneiding": "Oberschneiding",
    "oberschoena": "Oberschöna",
    "oberschoenau": "Oberschönau",
    "oberschoenegg": "Oberschönegg",
    "oberschwarzach": "Oberschwarzach",
    "oberschweinbach": "Oberschweinbach",
    "obersimten": "Obersimten",
    "obersinn": "Obersinn",
    "obersoechering": "Obersöchering",
    "obersontheim": "Obersontheim",
    "oberstadion": "Oberstadion",
    "oberstadt": "Oberstadt",
    "oberstadtfeld": "Oberstadtfeld",
    "oberstaufen": "Oberstaufen",
    "oberstaufenbach": "Oberstaufenbach",
    "oberstdorf": "Oberstdorf",
    "oberstedem": "Oberstedem",
    "obersteinebach": "Obersteinebach",
    "oberstenfeld": "Oberstenfeld",
    "oberstreit": "Oberstreit",
    "oberstreu": "Oberstreu",
    "obersulm": "Obersulm",
    "obersuelzen": "Obersülzen",
    "obersuessbach": "Obersüßbach",
    "obertaufkirchen": "Obertaufkirchen",
    "oberteuringen": "Oberteuringen",
    "oberthal": "Oberthal",
    "oberthulba": "Oberthulba",
    "obertiefenbach": "Obertiefenbach",
    "obertraubling": "Obertraubling",
    "obertrebra": "Obertrebra",
    "obertrubach": "Obertrubach",
    "obertshausen": "Obertshausen",
    "oberuckersee": "Oberuckersee",
    "oberursel-taunus": "Oberursel/Taunus",
    "oberviechtach": "Oberviechtach",
    "oberwallmenach": "Oberwallmenach",
    "oberwambach": "Oberwambach",
    "oberweid": "Oberweid",
    "oberweiler-im-tal": "Oberweiler im Tal",
    "oberweiler-eifel": "Oberweiler/Eifel",
    "oberweiler-tiefenbach": "Oberweiler-Tiefenbach",
    "oberweis": "Oberweis",
    "oberweissbach-thueringer-wald": "Oberweißbach/Thüringer Wald",
    "oberwesel": "Oberwesel",
    "oberweser": "Oberweser",
    "oberwiera": "Oberwiera",
    "oberwies": "Oberwies",
    "oberwiesen": "Oberwiesen",
    "oberwiesenthal": "Oberwiesenthal",
    "oberwolfach": "Oberwolfach",
    "oberwoerresbach": "Oberwörresbach",
    "oberzent": "Oberzent",
    "oberzissen": "Oberzissen",
    "obhausen": "Obhausen",
    "obing": "Obing",
    "obrigheim-baden": "Obrigheim/Baden",
    "obrigheim-pfalz": "Obrigheim/Pfalz",
    "ochsenfurt": "Ochsenfurt",
    "ochsenhausen": "Ochsenhausen",
    "ochtendung": "Ochtendung",
    "ochtersum": "Ochtersum",
    "ochtrup": "Ochtrup",
    "ockenfels": "Ockenfels",
    "ockenheim": "Ockenheim",
    "ockfen": "Ockfen",
    "ockholm": "Ockholm",
    "odderade": "Odderade",
    "odelzhausen": "Odelzhausen",
    "odenbach": "Odenbach",
    "odenthal": "Odenthal",
    "oderaue": "Oderaue",
    "oderberg": "Oderberg",
    "odernheim-am-glan": "Odernheim am Glan",
    "oderwitz": "Oderwitz",
    "odisheim": "Odisheim",
    "oebisfelde-weferlingen": "Oebisfelde-Weferlingen",
    "oechsen": "Oechsen",
    "oederan": "Oederan",
    "oederquart": "Oederquart",
    "oedheim": "Oedheim",
    "oelde": "Oelde",
    "oelixdorf": "Oelixdorf",
    "oelsberg": "Oelsberg",
    "oelsnitz-erzgebirge": "Oelsnitz/Erzgebirge",
    "oelsnitz-vogtland": "Oelsnitz/Vogtland",
    "oepfershausen": "Oepfershausen",
    "oerel": "Oerel",
    "oer-erkenschwick": "Oer-Erkenschwick",
    "oering": "Oering",
    "oerlenbach": "Oerlenbach",
    "oerlinghausen": "Oerlinghausen",
    "oersberg": "Oersberg",
    "oersdorf": "Oersdorf",
    "oeschebuettel": "Oeschebüttel",
    "oesterdeichstrich": "Oesterdeichstrich",
    "oesterwurth": "Oesterwurth",
    "oestrich-winkel": "Oestrich-Winkel",
    "oettern": "Oettern",
    "oettersdorf": "Oettersdorf",
    "oettingen-bayern": "Oettingen i. Bayern",
    "oetzen": "Oetzen",
    "oevenum": "Oevenum",
    "oeversee": "Oeversee",
    "offenau": "Offenau",
    "offenbach-an-der-queich": "Offenbach an der Queich",
    "offenbach-am-main": "Offenbach/Main",
    "offenbach-hundheim": "Offenbach-Hundheim",
    "offenberg": "Offenberg",
    "offenburg": "Offenburg",
    "offenbuettel": "Offenbüttel",
    "offenhausen": "Offenhausen",
    "offenheim": "Offenheim",
    "offingen": "Offingen",
    "offstein": "Offstein",
    "ofterdingen": "Ofterdingen",
    "ofterschwang": "Ofterschwang",
    "oftersheim": "Oftersheim",
    "oggelshausen": "Oggelshausen",
    "ohlenhard": "Ohlenhard",
    "ohlsbach": "Ohlsbach",
    "ohlstadt": "Ohlstadt",
    "ohlweiler": "Ohlweiler",
    "ohmbach": "Ohmbach",
    "ohmden": "Ohmden",
    "ohne": "Ohne",
    "oehningen": "Öhningen",
    "ohorn": "Ohorn",
    "ohrdruf": "Ohrdruf",
    "ohrenbach": "Ohrenbach",
    "oehringen": "Öhringen",
    "ohrum": "Ohrum",
    "olbernhau": "Olbernhau",
    "olbersdorf": "Olbersdorf",
    "olbersleben": "Olbersleben",
    "oelbronn-duerrn": "Ölbronn-Dürrn",
    "olching": "Olching",
    "oldenborstel": "Oldenborstel",
    "oldenburg-oldenburg": "Oldenburg",
    "oldenburg-in-holstein": "Oldenburg in Holstein",
    "oldenbuettel": "Oldenbüttel",
    "oldendorf-stade": "Oldendorf Kr. Stade",
    "oldendorf-holstein": "Oldendorf/Holstein",
    "oldendorf-luhe": "Oldendorf/Luhe",
    "oldenhuetten": "Oldenhütten",
    "oldenswort": "Oldenswort",
    "oldersbek": "Oldersbek",
    "olderup": "Olderup",
    "oldisleben": "Oldisleben",
    "oldsum": "Oldsum",
    "olfen": "Olfen",
    "ollendorf": "Ollendorf",
    "oellingen": "Öllingen",
    "ollmuth": "Ollmuth",
    "olmscheid": "Olmscheid",
    "olpe": "Olpe",
    "olsberg": "Olsberg",
    "olsbruecken": "Olsbrücken",
    "olsdorf": "Olsdorf",
    "oelsen": "Ölsen",
    "olzheim": "Olzheim",
    "onsdorf": "Onsdorf",
    "opfenbach": "Opfenbach",
    "oepfingen": "Öpfingen",
    "oppach": "Oppach",
    "oppenau": "Oppenau",
    "oppenheim": "Oppenheim",
    "oppenweiler": "Oppenweiler",
    "oppershausen": "Oppershausen",
    "oppertshausen": "Oppertshausen",
    "oppurg": "Oppurg",
    "oranienbaum-woerlitz": "Oranienbaum-Wörlitz",
    "oranienburg": "Oranienburg",
    "orbis": "Orbis",
    "orenhofen": "Orenhofen",
    "orfgen": "Orfgen",
    "orlamuende": "Orlamünde",
    "orlenbach": "Orlenbach",
    "ormont": "Ormont",
    "ornbau": "Ornbau",
    "orsfeld": "Orsfeld",
    "orsingen-nenzingen": "Orsingen-Nenzingen",
    "ortenberg-baden": "Ortenberg/Baden",
    "ortenberg-hessen": "Ortenberg/Hessen",
    "ortenburg": "Ortenburg",
    "ortrand": "Ortrand",
    "osann-monzel": "Osann-Monzel",
    "osburg": "Osburg",
    "oschatz": "Oschatz",
    "oschersleben-bode": "Oschersleben/Bode",
    "osdorf-kiel": "Osdorf b. Kiel",
    "osloss": "Osloß",
    "osnabrueck": "Osnabrück",
    "ossling": "Oßling",
    "ostbevern": "Ostbevern",
    "osteel": "Osteel",
    "ostelsheim": "Ostelsheim",
    "osten": "Osten",
    "ostenfeld-husum": "Ostenfeld/Husum",
    "ostenfeld-rendsburg": "Ostenfeld/Rendsburg",
    "osterberg": "Osterberg",
    "osterbruch": "Osterbruch",
    "osterburg-altmark": "Osterburg (Altmark)",
    "osterburken": "Osterburken",
    "osterby-eckernfoerde": "Osterby b. Eckernförde",
    "osterby-medelby": "Osterby b. Medelby",
    "ostercappeln": "Ostercappeln",
    "ostereistedt": "Ostereistedt",
    "osterfeld": "Osterfeld",
    "osterheide": "Osterheide gemfr. Bezirk",
    "osterhever": "Osterhever",
    "osterhofen": "Osterhofen",
    "osterholz-scharmbeck": "Osterholz-Scharmbeck",
    "osterhorn": "Osterhorn",
    "osternienburger-land": "Osternienburger Land",
    "osterode-am-harz": "Osterode am Harz",
    "oster-ohrstedt": "Oster-Ohrstedt",
    "osterrade": "Osterrade",
    "osterroenfeld": "Osterrönfeld",
    "osterspai": "Osterspai",
    "osterstedt": "Osterstedt",
    "osterwald": "Osterwald",
    "osterwieck": "Osterwieck",
    "osterzell": "Osterzell",
    "ostfildern": "Ostfildern",
    "osthausen-wuelfershausen": "Osthausen-Wülfershausen",
    "ostheim-rhoen": "Ostheim v.d. Rhön",
    "osthofen": "Osthofen",
    "ostrach": "Ostrach",
    "ostramondra": "Ostramondra",
    "ostrau-sachsen": "Ostrau/Sachsen",
    "ostrhauderfehn": "Ostrhauderfehn",
    "oestringen": "Östringen",
    "ostritz": "Ostritz",
    "ostrohe": "Ostrohe",
    "oststeinbek": "Oststeinbek",
    "oetigheim": "Ötigheim",
    "oetisheim": "Ötisheim",
    "ottenbach": "Ottenbach",
    "ottenbuettel": "Ottenbüttel",
    "ottendorf-kiel": "Ottendorf b. Kiel",
    "ottendorf-stadtroda": "Ottendorf b. Stadtroda",
    "ottendorf-okrilla": "Ottendorf-Okrilla",
    "ottenhofen": "Ottenhofen",
    "ottenhoefen-im-schwarzwald": "Ottenhöfen im Schwarzwald",
    "ottensoos": "Ottensoos",
    "ottenstein": "Ottenstein",
    "otter": "Otter",
    "otterbach": "Otterbach",
    "otterberg": "Otterberg",
    "otterfing": "Otterfing",
    "otterndorf": "Otterndorf",
    "ottersberg": "Ottersberg",
    "ottersheim-kirchheimbolanden": "Ottersheim b. Kirchheimbolanden",
    "ottersheim-landau": "Ottersheim b. Landau",
    "otterstadt": "Otterstadt",
    "ottersweier": "Ottersweier",
    "otterwisch": "Otterwisch",
    "otting": "Otting",
    "ottobeuren": "Ottobeuren",
    "ottobrunn": "Ottobrunn",
    "ottrau": "Ottrau",
    "ottstedt-berge": "Ottstedt a. Berge",
    "ottweiler": "Ottweiler",
    "otzberg": "Otzberg",
    "otzing": "Otzing",
    "oetzingen": "Ötzingen",
    "otzweiler": "Otzweiler",
    "ovelgoenne": "Ovelgönne",
    "overath": "Overath",
    "owen": "Owen",
    "owingen": "Owingen",
    "owschlag": "Owschlag",
    "oy-mittelberg": "Oy-Mittelberg",
    "oyten": "Oyten",
    "padenstedt": "Padenstedt",
    "paderborn": "Paderborn",
    "paehl": "Pähl",
    "pahlen": "Pahlen",
    "painten": "Painten",
    "paitzdorf": "Paitzdorf",
    "palling": "Palling",
    "palma-ballermann": "Palma-Ballermann",
    "palzem": "Palzem",
    "pampow-schwerin": "Pampow b. Schwerin",
    "panker": "Panker",
    "panketal": "Panketal",
    "panschwitz-kuckau": "Panschwitz-Kuckau",
    "pantelitz": "Pantelitz",
    "panten": "Panten",
    "pantenburg": "Pantenburg",
    "panzweiler": "Panzweiler",
    "papenburg": "Papenburg",
    "papendorf-pasewalk": "Papendorf b. Pasewalk",
    "papendorf-rostock": "Papendorf b. Rostock",
    "papenhagen": "Papenhagen",
    "pappenheim": "Pappenheim",
    "parchim": "Parchim",
    "parchtitz": "Parchtitz",
    "parkstein": "Parkstein",
    "parkstetten": "Parkstetten",
    "parsau": "Parsau",
    "parsberg": "Parsberg",
    "parsteinsee": "Parsteinsee",
    "partenheim": "Partenheim",
    "partenstein": "Partenstein",
    "parthenstein": "Parthenstein",
    "paschel": "Paschel",
    "pasewalk": "Pasewalk",
    "paska": "Paska",
    "passade": "Passade",
    "passau": "Passau",
    "passee": "Passee",
    "passow": "Passow",
    "passow-luebz": "Passow b. Lübz",
    "pastetten": "Pastetten",
    "patersberg": "Patersberg",
    "patersdorf": "Patersdorf",
    "paetow-steegen": "Pätow-Steegen",
    "pattensen": "Pattensen",
    "patzig": "Patzig",
    "paulinenaue": "Paulinenaue",
    "paunzhausen": "Paunzhausen",
    "pausa-muehltroff": "Pausa-Mühltroff",
    "paewesin": "Päwesin",
    "pechbrunn": "Pechbrunn",
    "peenehagen": "Peenehagen",
    "peenemuende": "Peenemünde",
    "peffingen": "Peffingen",
    "pegau": "Pegau",
    "pegestorf": "Pegestorf",
    "pegnitz": "Pegnitz",
    "peine": "Peine",
    "peissen-kreis-steinburg": "Peissen",
    "peissenberg": "Peißenberg",
    "peiting": "Peiting",
    "peitz": "Peitz",
    "pellingen": "Pellingen",
    "pellworm": "Pellworm",
    "pelm": "Pelm",
    "pemfling": "Pemfling",
    "penig": "Penig",
    "penkow": "Penkow",
    "penkun": "Penkun",
    "pennewitz": "Pennewitz",
    "pennigsehl": "Pennigsehl",
    "pentling": "Pentling",
    "penzberg": "Penzberg",
    "penzin": "Penzin",
    "penzing": "Penzing",
    "penzlin": "Penzlin",
    "perach": "Perach",
    "perasdorf": "Perasdorf",
    "perkam": "Perkam",
    "perl": "Perl",
    "perleberg": "Perleberg",
    "perlesreut": "Perlesreut",
    "perlin": "Perlin",
    "perscheid": "Perscheid",
    "pessin": "Pessin",
    "petersaurach": "Petersaurach",
    "petersberg-eisenberg": "Petersberg b. Eisenberg",
    "petersberg-halle": "Petersberg b. Halle",
    "petersberg-fulda": "Petersberg Kr. Fulda",
    "petersberg-pfalz": "Petersberg/Pfalz",
    "petersdorf-aichach": "Petersdorf b. Aichach",
    "petersdorf-strasburg": "Petersdorf b. Strasburg",
    "petershagen-eggersdorf": "Petershagen/Eggersdorf",
    "petershagen-weser": "Petershagen/Weser",
    "petershausen": "Petershausen",
    "peterslahr": "Peterslahr",
    "peterswald-loeffelscheid": "Peterswald-Löffelscheid",
    "petriroda": "Petriroda",
    "pettendorf": "Pettendorf",
    "petting": "Petting",
    "pettstadt": "Pettstadt",
    "peuschen": "Peuschen",
    "pfaffenhausen": "Pfaffenhausen",
    "pfaffenhofen-glonn": "Pfaffenhofen a.d. Glonn",
    "pfaffenhofen-ilm": "Pfaffenhofen a.d. Ilm",
    "pfaffenhofen-roth": "Pfaffenhofen a.d. Roth",
    "pfaffenhofen-wuerttemberg": "Pfaffenhofen/Württemberg",
    "pfaffen-schwabenheim": "Pfaffen-Schwabenheim",
    "pfaffenweiler": "Pfaffenweiler",
    "pfaffing": "Pfaffing",
    "pfaffroda": "Pfaffroda",
    "pfaffschwende": "Pfaffschwende",
    "pfakofen": "Pfakofen",
    "pfalzfeld": "Pfalzfeld",
    "pfalzgrafenweiler": "Pfalzgrafenweiler",
    "pfarrkirchen": "Pfarrkirchen",
    "pfarrweisach": "Pfarrweisach",
    "pfatter": "Pfatter",
    "pfedelbach": "Pfedelbach",
    "pfeffelbach": "Pfeffelbach",
    "pfeffenhausen": "Pfeffenhausen",
    "pferdingsleben": "Pferdingsleben",
    "pfinztal": "Pfinztal",
    "pfofeld": "Pfofeld",
    "pfoerring": "Pförring",
    "pforzen": "Pforzen",
    "pforzheim": "Pforzheim",
    "pfreimd": "Pfreimd",
    "pfronstetten": "Pfronstetten",
    "pfronten": "Pfronten",
    "pfullendorf": "Pfullendorf",
    "pfullingen": "Pfullingen",
    "pfungstadt": "Pfungstadt",
    "philippsburg": "Philippsburg",
    "philippsheim": "Philippsheim",
    "philippsreut": "Philippsreut",
    "philippsthal-werra": "Philippsthal/Werra",
    "picher": "Picher",
    "pickliessem": "Pickließem",
    "piding": "Piding",
    "pielenhofen": "Pielenhofen",
    "piesau": "Piesau",
    "piesport": "Piesport",
    "pillig": "Pillig",
    "pilsach": "Pilsach",
    "pilsting": "Pilsting",
    "pingelshagen": "Pingelshagen",
    "pinneberg": "Pinneberg",
    "pinnow-angermuende": "Pinnow b. Angermünde",
    "pinnow-schwerin": "Pinnow b. Schwerin",
    "pintesfeld": "Pintesfeld",
    "pinzberg": "Pinzberg",
    "pirk": "Pirk",
    "pirmasens": "Pirmasens",
    "pirna": "Pirna",
    "pirow": "Pirow",
    "pittenbach": "Pittenbach",
    "pittenhart": "Pittenhart",
    "plaaz": "Plaaz",
    "plaidt": "Plaidt",
    "planebruch": "Planebruch",
    "planegg": "Planegg",
    "planetal": "Planetal",
    "plankenfels": "Plankenfels",
    "plankstadt": "Plankstadt",
    "plascheid": "Plascheid",
    "plate": "Plate",
    "platten": "Platten",
    "plattenburg": "Plattenburg",
    "plattling": "Plattling",
    "plau-am-see": "Plau am See",
    "plaue": "Plaue",
    "plauen": "Plauen",
    "plech": "Plech",
    "pleckhausen": "Pleckhausen",
    "pleidelsheim": "Pleidelsheim",
    "plein": "Plein",
    "pleinfeld": "Pleinfeld",
    "pleiskirchen": "Pleiskirchen",
    "pleisweiler-oberhofen": "Pleisweiler-Oberhofen",
    "pleitersheim": "Pleitersheim",
    "pleizenhausen": "Pleizenhausen",
    "pless": "Pleß",
    "plessa": "Plessa",
    "plettenberg": "Plettenberg",
    "pleystein": "Pleystein",
    "pliening": "Pliening",
    "pliezhausen": "Pliezhausen",
    "plochingen": "Plochingen",
    "ploen": "Plön",
    "ploessberg": "Plößberg",
    "plothen": "Plothen",
    "ploetzkau": "Plötzkau",
    "ploewen": "Plöwen",
    "pluederhausen": "Plüderhausen",
    "plueschow": "Plüschow",
    "pluetscheid": "Plütscheid",
    "pluwig": "Pluwig",
    "pockau-lengefeld": "Pockau-Lengefeld",
    "pocking": "Pocking",
    "poecking": "Pöcking",
    "podelzig": "Podelzig",
    "pogeez": "Pogeez",
    "poggensee": "Poggensee",
    "pohl": "Pohl",
    "poehl": "Pöhl",
    "pohle": "Pohle",
    "pohlheim": "Pohlheim",
    "pohnsdorf": "Pohnsdorf",
    "poing": "Poing",
    "pokrent": "Pokrent",
    "polch": "Polch",
    "poelchow": "Pölchow",
    "poelich": "Pölich",
    "poelitz-stormarn": "Pölitz/Kr. Stormarn",
    "polle": "Polle",
    "pollenfeld": "Pollenfeld",
    "pollhagen": "Pollhagen",
    "polling-muehldorf": "Polling Kr. Mühldorf",
    "polling-weilheim": "Polling Kr. Weilheim",
    "polsingen": "Polsingen",
    "poelzig": "Pölzig",
    "polzow": "Polzow",
    "pommelsbrunn": "Pommelsbrunn",
    "pommerby": "Pommerby",
    "pommern": "Pommern",
    "pommersfelden": "Pommersfelden",
    "pomster": "Pomster",
    "ponitz": "Ponitz",
    "poppenbuell": "Poppenbüll",
    "poppendorf": "Poppendorf",
    "poppenhausen-unterfranken": "Poppenhausen/Unterfranken",
    "poppenhausen-wasserkuppe": "Poppenhausen/Wasserkuppe",
    "poppenricht": "Poppenricht",
    "poermitz": "Pörmitz",
    "poernbach": "Pörnbach",
    "porta-westfalica": "Porta Westfalica",
    "poeschendorf": "Pöschendorf",
    "poseritz": "Poseritz",
    "poesing": "Pösing",
    "poessneck": "Pößneck",
    "postau": "Postau",
    "postbauer-heng": "Postbauer-Heng",
    "posterstein": "Posterstein",
    "postfeld": "Postfeld",
    "postlow": "Postlow",
    "postmuenster": "Postmünster",
    "potsdam": "Potsdam",
    "pottenstein": "Pottenstein",
    "pottiga": "Pottiga",
    "poettmes": "Pöttmes",
    "pottum": "Pottum",
    "poxdorf-eisenberg": "Poxdorf b. Eisenberg",
    "poxdorf-oberfranken": "Poxdorf/Oberfranken",
    "poyenberg": "Poyenberg",
    "pracht": "Pracht",
    "prackenbach": "Prackenbach",
    "pragsdorf": "Pragsdorf",
    "prasdorf": "Prasdorf",
    "prath": "Prath",
    "prebberede": "Prebberede",
    "prebitz": "Prebitz",
    "preetz-stralsund": "Preetz b. Stralsund",
    "preetz-holstein": "Preetz/Holstein",
    "preischeid": "Preischeid",
    "preist": "Preist",
    "prem": "Prem",
    "premnitz": "Premnitz",
    "prenzlau": "Prenzlau",
    "prerow": "Prerow",
    "pressath": "Pressath",
    "presseck": "Presseck",
    "pressig": "Pressig",
    "pretzfeld": "Pretzfeld",
    "preussisch-oldendorf": "Preußisch Oldendorf",
    "prezelle": "Prezelle",
    "priborn": "Priborn",
    "prichsenstadt": "Prichsenstadt",
    "prien-chiemsee": "Prien a. Chiemsee",
    "priepert": "Priepert",
    "priesendorf": "Priesendorf",
    "priestewitz": "Priestewitz",
    "prinzenmoor": "Prinzenmoor",
    "prinzhoefte": "Prinzhöfte",
    "pripsleben": "Pripsleben",
    "prisdorf": "Prisdorf",
    "prislich": "Prislich",
    "prittriching": "Prittriching",
    "pritzier": "Pritzier",
    "pritzwalk": "Pritzwalk",
    "probsteierhagen": "Probsteierhagen",
    "probstzella": "Probstzella",
    "prohn": "Prohn",
    "pronsfeld": "Pronsfeld",
    "pronstorf": "Pronstorf",
    "prosselsheim": "Prosselsheim",
    "proetzel": "Prötzel",
    "pruchten": "Pruchten",
    "pruem": "Prüm",
    "pruemzurlay": "Prümzurlay",
    "prutting": "Prutting",
    "puechersreuth": "Püchersreuth",
    "puchheim": "Puchheim",
    "pudagla": "Pudagla",
    "puderbach": "Puderbach",
    "pulheim": "Pulheim",
    "pullach-isartal": "Pullach i. Isartal",
    "pullenreuth": "Pullenreuth",
    "puls": "Puls",
    "pulsnitz": "Pulsnitz",
    "puenderich": "Pünderich",
    "puergen": "Pürgen",
    "puschendorf": "Puschendorf",
    "puschwitz": "Puschwitz",
    "putbus": "Putbus",
    "putgarten": "Putgarten",
    "putlitz": "Putlitz",
    "puettlingen": "Püttlingen",
    "putzbrunn": "Putzbrunn",
    "pyrbaum": "Pyrbaum",
    "quakenbrueck": "Quakenbrück",
    "quarnbek": "Quarnbek",
    "quarnstedt": "Quarnstedt",
    "quaschwitz": "Quaschwitz",
    "quedlinburg": "Quedlinburg",
    "queidersbach": "Queidersbach",
    "quendorf": "Quendorf",
    "querenhorst": "Querenhorst",
    "querfurt": "Querfurt",
    "quernheim": "Quernheim",
    "quickborn-dithmarschen": "Quickborn b. Dithmarschen",
    "quickborn-pinneberg": "Quickborn/Kr. Pinneberg",
    "quiddelbach": "Quiddelbach",
    "quierschied": "Quierschied",
    "quirla": "Quirla",
    "quirnbach-pfalz": "Quirnbach/Pfalz",
    "quirnbach-westerwald": "Quirnbach/Westerwald",
    "quirnheim": "Quirnheim",
    "quitzdorf-am-see": "Quitzdorf am See",
    "raa-besenbek": "Raa-Besenbek",
    "rabel": "Rabel",
    "raben-steinfeld": "Raben Steinfeld",
    "rabenau-hessen": "Rabenau/Hessen",
    "rabenau-sachsen": "Rabenau/Sachsen",
    "rabenholz": "Rabenholz",
    "rabenkirchen-faulueck": "Rabenkirchen-Faulück",
    "rabenstein-flaeming": "Rabenstein/Fläming",
    "raebke": "Räbke",
    "raeckelwitz": "Räckelwitz",
    "racksen": "Racksen",
    "rackwitz": "Rackwitz",
    "radbruch": "Radbruch",
    "raddestorf": "Raddestorf",
    "rade-fitzbek": "Rade b. Fitzbek",
    "rade-hohenwestedt": "Rade b. Hohenwestedt",
    "rade-rendsburg": "Rade b. Rendsburg",
    "radeberg": "Radeberg",
    "radebeul": "Radebeul",
    "radeburg": "Radeburg",
    "radevormwald": "Radevormwald",
    "radibor": "Radibor",
    "radolfzell-am-bodensee": "Radolfzell am Bodensee",
    "raesfeld": "Raesfeld",
    "ragow-merz": "Ragow-Merz",
    "raguhn": "Raguhn-Jeßnitz",
    "rahden": "Rahden",
    "rain-lech": "Rain/Lech",
    "rain-niederbayern": "Rain/Niederbayern",
    "rainau": "Rainau",
    "raisting": "Raisting",
    "raitenbuch": "Raitenbuch",
    "ralbitz-rosenthal": "Ralbitz-Rosenthal",
    "ralingen": "Ralingen",
    "ralswiek": "Ralswiek",
    "ramberg": "Ramberg",
    "rambin": "Rambin",
    "ramerberg": "Ramerberg",
    "ramhusen": "Ramhusen",
    "ramin": "Ramin",
    "rammelsbach": "Rammelsbach",
    "rammenau": "Rammenau",
    "rammingen-schwaben": "Rammingen/Schwaben",
    "rammingen-wuerttemberg": "Rammingen/Württemberg",
    "ramsau-berchtesgaden": "Ramsau b. Berchtesgaden",
    "ramsen": "Ramsen",
    "ramsla": "Ramsla",
    "ramstedt": "Ramstedt",
    "ramstein-miesenbach": "Ramstein-Miesenbach",
    "ramsthal": "Ramsthal",
    "randersacker": "Randersacker",
    "randowtal": "Randowtal",
    "rangendingen": "Rangendingen",
    "rangsdorf": "Rangsdorf",
    "ranis": "Ranis",
    "rankwitz": "Rankwitz",
    "rannstedt": "Rannstedt",
    "rannungen": "Rannungen",
    "ransbach-baumbach": "Ransbach-Baumbach",
    "ranschbach": "Ranschbach",
    "ranstadt": "Ranstadt",
    "ransweiler": "Ransweiler",
    "rantrum": "Rantrum",
    "rantzau": "Rantzau",
    "rappin": "Rappin",
    "raschau-markersbach": "Raschau-Markersbach",
    "rascheid": "Rascheid",
    "rasdorf": "Rasdorf",
    "rastatt": "Rastatt",
    "rastdorf": "Rastdorf",
    "rastede": "Rastede",
    "rastenberg": "Rastenberg",
    "rastorf": "Rastorf",
    "rastow": "Rastow",
    "ratekau": "Ratekau",
    "rathen-kurort": "Rathen Kurort",
    "rathenow": "Rathenow",
    "rathjensdorf": "Rathjensdorf",
    "rathmannsdorf-pirna": "Rathmannsdorf b. Pirna",
    "rathskirchen": "Rathskirchen",
    "rathsweiler": "Rathsweiler",
    "ratingen": "Ratingen",
    "ratshausen": "Ratshausen",
    "rattelsdorf-stadtroda": "Rattelsdorf b. Stadtroda",
    "rattelsdorf-oberfranken": "Rattelsdorf/Oberfranken",
    "rattenberg": "Rattenberg",
    "rattenkirchen": "Rattenkirchen",
    "rattiszell": "Rattiszell",
    "ratzeburg": "Ratzeburg",
    "ratzert": "Ratzert",
    "raetzlingen-uelzen": "Rätzlingen Kr. Uelzen",
    "raubach": "Raubach",
    "raubling": "Raubling",
    "rauda": "Rauda",
    "rauen": "Rauen",
    "rauenberg": "Rauenberg",
    "rauhenebrach": "Rauhenebrach",
    "raumbach": "Raumbach",
    "raunheim": "Raunheim",
    "rauschenberg": "Rauschenberg",
    "rauschwitz": "Rauschwitz",
    "rausdorf-stadtroda": "Rausdorf b. Stadtroda",
    "rausdorf-stormarn": "Rausdorf/Kr. Stormarn",
    "ravengiersburg": "Ravengiersburg",
    "ravensburg": "Ravensburg",
    "ravenstein": "Ravenstein",
    "raversbeuren": "Raversbeuren",
    "rayerschied": "Rayerschied",
    "rech": "Rech",
    "rechberghausen": "Rechberghausen",
    "rechenberg-bienenmuehle": "Rechenberg-Bienenmühle",
    "rechlin": "Rechlin",
    "rechtenbach": "Rechtenbach",
    "rechtenstein": "Rechtenstein",
    "rechtmehring": "Rechtmehring",
    "rechtsupweg": "Rechtsupweg",
    "recke": "Recke",
    "reckendorf": "Reckendorf",
    "reckenroth": "Reckenroth",
    "reckershausen": "Reckershausen",
    "recklinghausen": "Recklinghausen",
    "reddelich": "Reddelich",
    "redefin": "Redefin",
    "rednitzhembach": "Rednitzhembach",
    "redwitz-rodach": "Redwitz a.d. Rodach",
    "rees": "Rees",
    "reesdorf-kiel": "Reesdorf b. Kiel",
    "reessum": "Reeßum",
    "regen": "Regen",
    "regensburg": "Regensburg",
    "regenstauf": "Regenstauf",
    "regesbostel": "Regesbostel",
    "regis-breitingen": "Regis-Breitingen",
    "regnitzlosau": "Regnitzlosau",
    "rehau": "Rehau",
    "rehbach": "Rehbach",
    "rehborn": "Rehborn",
    "rehburg-loccum": "Rehburg-Loccum",
    "rehden": "Rehden",
    "rehe": "Rehe",
    "reher": "Reher",
    "rehfelde": "Rehfelde",
    "rehhorst": "Rehhorst",
    "rehling": "Rehling",
    "rehlingen-lueneburg": "Rehlingen Kr. Lüneburg",
    "rehlingen-siersburg": "Rehlingen-Siersburg",
    "rehm-flehde-bargen": "Rehm-Flehde-Bargen",
    "rehna": "Rehna",
    "rehweiler": "Rehweiler",
    "reich": "Reich",
    "reichartshausen": "Reichartshausen",
    "reichelsheim-odenwald": "Reichelsheim/Odenwald",
    "reichelsheim-wetterau": "Reichelsheim/Wetterau",
    "reichenau": "Reichenau",
    "reichenbach-oberlausitz": "Reichenbach (Oberlausitz)",
    "reichenbach-am-heuberg": "Reichenbach am Heuberg",
    "reichenbach-an-der-fils": "Reichenbach an der Fils",
    "reichenbach-hermsdorf": "Reichenbach b. Hermsdorf",
    "reichenbach-im-vogtland": "Reichenbach im Vogtland",
    "reichenbach-birkenfeld": "Reichenbach Kr. Birkenfeld",
    "reichenbach-kronach": "Reichenbach Kr. Kronach",
    "reichenbach-oberpfalz": "Reichenbach/Oberpfalz",
    "reichenbach-steegen": "Reichenbach-Steegen",
    "reichenberg-rhein-lahn-kreis": "Reichenberg/Rhein-Lahn-Kreis",
    "reichenberg-unterfranken": "Reichenberg/Unterfranken",
    "reichenow-moeglin": "Reichenow-Möglin",
    "reichenschwand": "Reichenschwand",
    "reichenwalde": "Reichenwalde",
    "reichersbeuern": "Reichersbeuern",
    "reichertshausen": "Reichertshausen",
    "reichertsheim": "Reichertsheim",
    "reichertshofen": "Reichertshofen",
    "reichling": "Reichling",
    "reichmannsdorf": "Reichmannsdorf",
    "reichshof": "Reichshof",
    "reichstaedt": "Reichstädt",
    "reichsthal": "Reichsthal",
    "reichweiler": "Reichweiler",
    "reidenhausen": "Reidenhausen",
    "reifenberg": "Reifenberg",
    "reiferscheid": "Reiferscheid",
    "reiff": "Reiff",
    "reiffelbach": "Reiffelbach",
    "reifferscheid": "Reifferscheid",
    "reil": "Reil",
    "reilingen": "Reilingen",
    "reimerath": "Reimerath",
    "reimershagen": "Reimershagen",
    "reimlingen": "Reimlingen",
    "reinbek": "Reinbek",
    "reinfeld-holstein": "Reinfeld/Holstein",
    "reinhardshagen": "Reinhardshagen",
    "reinhardsdorf-schoena": "Reinhardtsdorf-Schöna",
    "reinheim": "Reinheim",
    "reinholterode": "Reinholterode",
    "reinsberg": "Reinsberg",
    "reinsbuettel": "Reinsbüttel",
    "reinsdorf-artern": "Reinsdorf b. Artern",
    "reinsdorf-zwickau": "Reinsdorf b. Zwickau",
    "reinsfeld": "Reinsfeld",
    "reinstaedt": "Reinstädt",
    "reinstorf": "Reinstorf",
    "reipeldingen": "Reipeldingen",
    "reipoltskirchen": "Reipoltskirchen",
    "reisbach": "Reisbach",
    "reischach": "Reischach",
    "reiskirchen": "Reiskirchen",
    "reit-im-winkl": "Reit im Winkl",
    "reitwein": "Reitwein",
    "reitzenhain": "Reitzenhain",
    "reken": "Reken",
    "rellingen": "Rellingen",
    "relsberg": "Relsberg",
    "remagen": "Remagen",
    "remchingen": "Remchingen",
    "remda-teichel": "Remda-Teichel",
    "remlingen-wolfenbuettel": "Remlingen/Kr. Wolfenbüttel",
    "remlingen-unterfranken": "Remlingen/Unterfranken",
    "remlingen-semmenstedt": "Remlingen-Semmenstedt",
    "remmels": "Remmels",
    "remptendorf": "Remptendorf",
    "remscheid": "Remscheid",
    "remse": "Remse",
    "remseck-am-neckar": "Remseck am Neckar",
    "remshalden": "Remshalden",
    "remstaedt": "Remstädt",
    "renchen": "Renchen",
    "rendsburg": "Rendsburg",
    "rendswuehren": "Rendswühren",
    "rengsdorf": "Rengsdorf",
    "renkenberge": "Renkenberge",
    "rennau": "Rennau",
    "rennerod": "Rennerod",
    "rennertshofen": "Rennertshofen",
    "renningen": "Renningen",
    "renquishausen": "Renquishausen",
    "renthendorf": "Renthendorf",
    "rentweinsdorf": "Rentweinsdorf",
    "reppenstedt": "Reppenstedt",
    "rerik": "Rerik",
    "rethem-aller": "Rethem/Aller",
    "rethwisch-laegerdorf": "Rethwisch b. Lägerdorf",
    "rethwisch-stormarn": "Rethwisch/Kr. Stormarn",
    "retschow": "Retschow",
    "rettenbach-am-auerberg": "Rettenbach am Auerberg",
    "rettenbach-guenzburg": "Rettenbach Kr. Günzburg",
    "rettenbach-oberpfalz": "Rettenbach/Oberpfalz",
    "rettenberg": "Rettenberg",
    "retterath": "Retterath",
    "rettersen": "Rettersen",
    "rettershain": "Rettershain",
    "rettert": "Rettert",
    "retzow-nauen": "Retzow b. Nauen",
    "retzstadt": "Retzstadt",
    "reudelsterz": "Reudelsterz",
    "reurieth": "Reurieth",
    "reussenkoege": "Reußenköge",
    "reut": "Reut",
    "reute": "Reute",
    "reuterstadt-stavenhagen": "Reuterstadt Stavenhagen",
    "reuth-erbendorf": "Reuth b. Erbendorf",
    "reuth-plauen": "Reuth b. Plauen",
    "reuth-eifel": "Reuth/Eifel",
    "reutlingen": "Reutlingen",
    "rhade": "Rhade",
    "rhauderfehn": "Rhauderfehn",
    "rhaunen": "Rhaunen",
    "rheda-wiedenbrueck": "Rheda-Wiedenbrück",
    "rhede-ems": "Rhede/Ems",
    "rhede-westfalen": "Rhede/Westfalen",
    "rheden": "Rheden",
    "rheinau": "Rheinau",
    "rheinbach": "Rheinbach",
    "rheinberg": "Rheinberg",
    "rheinboellen": "Rheinböllen",
    "rheinbreitbach": "Rheinbreitbach",
    "rheinbrohl": "Rheinbrohl",
    "rheine": "Rheine",
    "rheinfelden-baden": "Rheinfelden/Baden",
    "rheinhausen": "Rheinhausen",
    "rheinmuenster": "Rheinmünster",
    "rheinsberg": "Rheinsberg",
    "rheinstetten": "Rheinstetten",
    "rheinzabern": "Rheinzabern",
    "rhens": "Rhens",
    "rheurdt": "Rheurdt",
    "rhinow": "Rhinow",
    "rhodt-unter-rietburg": "Rhodt unter Rietburg",
    "rhoenblick": "Rhönblick",
    "rhumspringe": "Rhumspringe",
    "ribbesbuettel": "Ribbesbüttel",
    "ribnitz-damgarten": "Ribnitz-Damgarten",
    "richtenberg": "Richtenberg",
    "rickenbach": "Rickenbach",
    "rickert": "Rickert",
    "rickling": "Rickling",
    "ried": "Ried",
    "riedbach": "Riedbach",
    "riede": "Riede",
    "riedelberg": "Riedelberg",
    "rieden-am-forggensee": "Rieden am Forggensee",
    "rieden-kaufbeuren": "Rieden b. Kaufbeuren",
    "rieden-eifel": "Rieden/Eifel",
    "rieden-oberpfalz": "Rieden/Oberpfalz",
    "riedenberg": "Riedenberg",
    "riedenburg": "Riedenburg",
    "riedenheim": "Riedenheim",
    "riederich": "Riederich",
    "riedering": "Riedering",
    "riedhausen": "Riedhausen",
    "riedlingen": "Riedlingen",
    "riedstadt": "Riedstadt",
    "riegel": "Riegel",
    "riegelsberg": "Riegelsberg",
    "riegenroth": "Riegenroth",
    "riegsee": "Riegsee",
    "riekofen": "Riekofen",
    "rielasingen-worblingen": "Rielasingen-Worblingen",
    "rieneck": "Rieneck",
    "rieps": "Rieps",
    "riepsdorf": "Riepsdorf",
    "riesa": "Riesa",
    "riesbuerg": "Riesbürg",
    "rieschweiler-muehlbach": "Rieschweiler-Mühlbach",
    "rieseby": "Rieseby",
    "rieste": "Rieste",
    "riesweiler": "Riesweiler",
    "rietberg": "Rietberg",
    "rietheim-weilheim": "Rietheim-Weilheim",
    "riethgen": "Riethgen",
    "riethnordhausen-thueringen": "Riethnordhausen/Thüringen",
    "rietschen": "Rietschen",
    "rietz-neuendorf": "Rietz-Neuendorf",
    "rietzneuendorf-staakow": "Rietzneuendorf-Staakow",
    "rimbach-eggenfelden": "Rimbach b. Eggenfelden",
    "rimbach-oberpfalz": "Rimbach/Oberpfalz",
    "rimbach-odenwald": "Rimbach/Odenwald",
    "rimpar": "Rimpar",
    "rimsberg": "Rimsberg",
    "rimsting": "Rimsting",
    "rinchnach": "Rinchnach",
    "ringe": "Ringe",
    "ringelai": "Ringelai",
    "ringgau": "Ringgau",
    "ringleben-artern": "Ringleben b. Artern",
    "ringleben-gebesee": "Ringleben b. Gebesee",
    "ringsberg": "Ringsberg",
    "ringsheim": "Ringsheim",
    "rinnthal": "Rinnthal",
    "rinteln": "Rinteln",
    "rinzenberg": "Rinzenberg",
    "riol": "Riol",
    "rippershausen": "Rippershausen",
    "risum-lindholm": "Risum-Lindholm",
    "ritschenhausen": "Ritschenhausen",
    "ritterhude": "Ritterhude",
    "rittersdorf-weimar": "Rittersdorf b. Weimar",
    "rittersdorf-eifel": "Rittersdorf/Eifel",
    "rittersheim": "Rittersheim",
    "ritzerau": "Ritzerau",
    "ritzerow": "Ritzerow",
    "rivenich": "Rivenich",
    "riveris": "Riveris",
    "roebel-mueritz": "Röbel/Müritz",
    "rochau": "Rochau",
    "rochlitz": "Rochlitz",
    "rockenberg": "Rockenberg",
    "rockenhausen": "Rockenhausen",
    "rockeskyll": "Rockeskyll",
    "rockhausen": "Rockhausen",
    "roeckingen": "Röckingen",
    "rockstedt": "Rockstedt",
    "roeckwitz": "Röckwitz",
    "rodalben": "Rodalben",
    "rodder": "Rodder",
    "rodeberg": "Rodeberg",
    "roedelhausen": "Rödelhausen",
    "roedelmaier": "Rödelmaier",
    "roedelsee": "Rödelsee",
    "roden": "Roden",
    "rodenaes": "Rodenäs",
    "rodenbach-hanau": "Rodenbach b. Hanau",
    "rodenbach-puderbach": "Rodenbach b. Puderbach",
    "rodenbach-kaiserslautern": "Rodenbach Kr. Kaiserslautern",
    "rodenbek": "Rodenbek",
    "rodenberg": "Rodenberg",
    "roedental": "Rödental",
    "roederaue": "Röderaue",
    "roederland": "Röderland",
    "roedermark": "Rödermark",
    "roedern": "Rödern",
    "rodershausen": "Rodershausen",
    "roedersheim-gronau": "Rödersheim-Gronau",
    "rodewald": "Rodewald",
    "rodewisch": "Rodewisch",
    "rodgau": "Rodgau",
    "roding": "Roding",
    "roedinghausen": "Rödinghausen",
    "roduchelstorf": "Roduchelstorf",
    "roes": "Roes",
    "roetgen": "Roetgen",
    "roefingen": "Röfingen",
    "rogaetz": "Rogätz",
    "roggenburg": "Roggenburg",
    "roggendorf": "Roggendorf",
    "roggenstorf": "Roggenstorf",
    "roggentin-rostock": "Roggentin b. Rostock",
    "roegling": "Rögling",
    "roegnitz": "Rögnitz",
    "roehl": "Röhl",
    "rohlstorf": "Rohlstorf",
    "rohr-mittelfranken": "Rohr/Mittelfranken",
    "rohr-niederbayern": "Rohr/Niederbayern",
    "rohr-thueringen": "Rohr/Thüringen",
    "rohrbach-rudolstadt": "Rohrbach b. Rudolstadt",
    "rohrbach-weimar": "Rohrbach b. Weimar",
    "rohrbach-hunsrueck": "Rohrbach/Hunsrück",
    "rohrbach-ilm": "Rohrbach/Ilm",
    "rohrbach-nahe": "Rohrbach/Nahe",
    "rohrbach-pfalz": "Rohrbach/Pfalz",
    "rohrberg-altmark": "Rohrberg (Altmark)",
    "rohrberg-heilberg": "Rohrberg b. Heilberg",
    "rohrdorf-nagold": "Rohrdorf b. Nagold",
    "rohrdorf-rosenheim": "Rohrdorf Kr. Rosenheim",
    "rohrenfels": "Rohrenfels",
    "roehrig": "Röhrig",
    "roehrmoos": "Röhrmoos",
    "roehrnbach": "Röhrnbach",
    "rohrsen": "Rohrsen",
    "roigheim": "Roigheim",
    "roklum": "Roklum",
    "roellbach": "Röllbach",
    "rollshausen": "Rollshausen",
    "rollwitz": "Rollwitz",
    "rom": "Rom",
    "roemerberg": "Römerberg",
    "roemerstein": "Römerstein",
    "roemhild": "Römhild",
    "rommersheim": "Rommersheim",
    "rommerskirchen": "Rommerskirchen",
    "roemnitz": "Römnitz",
    "romrod": "Romrod",
    "roemstedt": "Römstedt",
    "rondeshagen": "Rondeshagen",
    "ronneburg-hessen": "Ronneburg/Hessen",
    "ronneburg-thueringen": "Ronneburg/Thüringen",
    "ronnenberg": "Ronnenberg",
    "ronsberg": "Ronsberg",
    "ronshausen": "Ronshausen",
    "rorodt": "Rorodt",
    "rosa": "Rosa",
    "rosbach": "Rosbach v.d. Höhe",
    "roschbach": "Roschbach",
    "rosche": "Rosche",
    "roscheid": "Roscheid",
    "rosdorf-holstein": "Rosdorf/Holstein",
    "rosdorf-goettingen": "Rosdorf/Kr. Göttingen",
    "roseburg": "Roseburg",
    "rosenau": "Rosenau",
    "rosenbach": "Rosenbach",
    "rosenbach-vogtland": "Rosenbach/Vogtl.",
    "rosenberg-baden": "Rosenberg/Baden",
    "rosenberg-wuerttemberg": "Rosenberg/Württemberg",
    "rosendahl": "Rosendahl",
    "rosendorf": "Rosendorf",
    "rosenfeld": "Rosenfeld",
    "rosengarten-harburg": "Rosengarten Kr. Harburg",
    "rosengarten-wuerttemberg": "Rosengarten/Württemberg",
    "rosenheim-bayern": "Rosenheim",
    "rosenheim-westerwald": "Rosenheim/Westerwald",
    "rosenkopf": "Rosenkopf",
    "rosenow-altentreptow": "Rosenow b. Altentreptow",
    "rosenthal-am-rennsteig": "Rosenthal am Rennsteig",
    "rosenthal-hessen": "Rosenthal/Hessen",
    "rosenthal-bielatal": "Rosenthal-Bielatal",
    "rositz": "Rositz",
    "roskow": "Roskow",
    "roeslau": "Röslau",
    "roesrath": "Rösrath",
    "rossau-mittweida": "Rossau b. Mittweida",
    "rossbach-niederbayern": "Roßbach/Niederbayern",
    "rossbach-westerwald": "Roßbach/Westerwald",
    "rossbach-wied": "Roßbach/Wied",
    "rossdorf-darmstadt": "Roßdorf b. Darmstadt",
    "rossdorf-schmalkalden": "Roßdorf b. Schmalkalden",
    "rosshaupten": "Roßhaupten",
    "rossin": "Rossin",
    "rossleben": "Roßleben",
    "rossleben-wiehe": "Roßleben-Wiehe",
    "rossow-pasewalk": "Rossow b. Pasewalk",
    "rosstal": "Roßtal",
    "rosswein": "Roßwein",
    "rostock": "Rostock",
    "rot-am-see": "Rot am See",
    "rot-an-der-rot": "Rot an der Rot",
    "rotenburg-fulda": "Rotenburg a.d. Fulda",
    "rotenburg-wuemme": "Rotenburg/Wümme",
    "rotenhain": "Rotenhain",
    "roetgesbuettel": "Rötgesbüttel",
    "roth-an-der-our": "Roth an der Our",
    "roth-hamm": "Roth b. Hamm",
    "roth-pruem": "Roth b. Prüm",
    "roth-stromberg": "Roth b. Stromberg",
    "roth-mittelfranken": "Roth/Mittelfranken",
    "roth-rhein-hunsrueck-kreis": "Roth/Rhein-Hunsrück-Kreis",
    "roth-rhein-lahn-kreis": "Roth/Rhein-Lahn-Kreis",
    "roetha": "Rötha",
    "rothemuehl": "Rothemühl",
    "rothenbach": "Rothenbach",
    "roethenbach-pegnitz": "Röthenbach a.d. Pegnitz",
    "roethenbach-allgaeu": "Röthenbach/Allgäu",
    "rothenberg": "Rothenberg",
    "rothenbuch": "Rothenbuch",
    "rothenburg-tauber": "Rothenburg ob der Tauber",
    "rothenburg-oberlausitz": "Rothenburg/Oberlausitz",
    "rothenfels": "Rothenfels",
    "rothenklempenow": "Rothenklempenow",
    "rothenstein": "Rothenstein",
    "roethlein": "Röthlein",
    "rothselberg": "Rothselberg",
    "roetsweiler-nockenthal": "Rötsweiler-Nockenthal",
    "rott-inn": "Rott a. Inn",
    "rott-lech": "Rott/Lech",
    "rott-westerwald": "Rott/Westerwald",
    "rottach-egern": "Rottach-Egern",
    "rottenacker": "Rottenacker",
    "roettenbach-erlangen": "Röttenbach b. Erlangen",
    "roettenbach-schwabach": "Röttenbach b. Schwabach",
    "rottenbuch": "Rottenbuch",
    "rottenburg-laaber": "Rottenburg a.d. Laaber",
    "rottenburg-am-neckar": "Rottenburg am Neckar",
    "rottendorf": "Rottendorf",
    "rotterode": "Rotterode",
    "rotthalmuenster": "Rotthalmünster",
    "roettingen": "Röttingen",
    "rottweil": "Rottweil",
    "roetz": "Rötz",
    "roevershagen": "Rövershagen",
    "roxheim": "Roxheim",
    "rubenow": "Rubenow",
    "rueber": "Rüber",
    "rubkow": "Rubkow",
    "rueckeroth": "Rückeroth",
    "rueckersdorf-doberlug-kirchhain": "Rückersdorf b. Doberlug-Kirchhain",
    "rueckersdorf-gera": "Rückersdorf b. Gera",
    "rueckersdorf-mittelfranken": "Rückersdorf/Mittelfranken",
    "rueckholz": "Rückholz",
    "rueckweiler": "Rückweiler",
    "rudelzhausen": "Rudelzhausen",
    "ruedenau": "Rüdenau",
    "ruedenhausen": "Rüdenhausen",
    "ruderatshofen": "Ruderatshofen",
    "rudersberg": "Rudersberg",
    "rudersdorf": "Rudersdorf",
    "ruedersdorf-berlin": "Rüdersdorf b. Berlin",
    "ruedershausen": "Rüdershausen",
    "ruderting": "Ruderting",
    "ruedesheim-am-rhein": "Rüdesheim am Rhein",
    "ruedesheim-bad-kreuznach": "Rüdesheim Kr. Bad Kreuznach",
    "ruednitz": "Rüdnitz",
    "rudolstadt": "Rudolstadt",
    "rugendorf": "Rugendorf",
    "ruegge": "Rügge",
    "ruegland": "Rügland",
    "ruehen": "Rühen",
    "ruhla": "Ruhla",
    "ruhland": "Ruhland",
    "ruhmannsfelden": "Ruhmannsfelden",
    "ruehn": "Rühn",
    "ruhner-berge": "Ruhner Berge",
    "ruhpolding": "Ruhpolding",
    "ruehstaedt": "Rühstädt",
    "ruhstorf-rott": "Ruhstorf a.d. Rott",
    "ruhwinkel": "Ruhwinkel",
    "rukieten": "Rukieten",
    "rullstorf": "Rullstorf",
    "ruelzheim": "Rülzheim",
    "rumbach": "Rumbach",
    "ruemmelsheim": "Rümmelsheim",
    "ruemmingen": "Rümmingen",
    "rumohr": "Rumohr",
    "ruempel": "Rümpel",
    "runding": "Runding",
    "runkel": "Runkel",
    "ruppach-goldhausen": "Ruppach-Goldhausen",
    "ruppertsberg": "Ruppertsberg",
    "ruppertsecken": "Ruppertsecken",
    "ruppertshofen-schwaebisch-gmuend": "Ruppertshofen b. Schwäbisch Gmünd",
    "ruppertshofen-taunus": "Ruppertshofen/Taunus",
    "ruppertsweiler": "Ruppertsweiler",
    "ruppichteroth": "Ruppichteroth",
    "ruschberg": "Ruschberg",
    "ruescheid": "Rüscheid",
    "ruesselsheim": "Rüsselsheim am Main",
    "ruessingen": "Rüssingen",
    "rust": "Rust",
    "rustenfelde": "Rustenfelde",
    "rutesheim": "Rutesheim",
    "ruethen": "Rüthen",
    "ruethnick": "Rüthnick",
    "ruthweiler": "Ruthweiler",
    "rueting": "Rüting",
    "rutsweiler-am-glan": "Rutsweiler am Glan",
    "rutsweiler-an-der-lauter": "Rutsweiler an der Lauter",
    "ruttersdorf-lotschen": "Ruttersdorf-Lotschen",
    "saal-donau": "Saal a.d. Donau",
    "saal-saale": "Saal a.d. Saale",
    "saal-ribnitz-damgarten": "Saal b. Ribnitz-Damgarten",
    "saalburg-ebersdorf": "Saalburg-Ebersdorf",
    "saaldorf-surheim": "Saaldorf-Surheim",
    "saaleplatte": "Saaleplatte",
    "saalfeld-saale": "Saalfeld/Saale",
    "saalfelder-hoehe": "Saalfelder Höhe",
    "saalstadt": "Saalstadt",
    "saara-greiz": "Saara b. Greiz",
    "saarbruecken": "Saarbrücken",
    "saarburg": "Saarburg",
    "saarlouis": "Saarlouis",
    "saarwellingen": "Saarwellingen",
    "sachsen-ansbach": "Sachsen b. Ansbach",
    "sachsenbrunn": "Sachsenbrunn",
    "sachsenhagen": "Sachsenhagen",
    "sachsenhausen": "Sachsenhausen",
    "sachsenheim": "Sachsenheim",
    "sachsenkam": "Sachsenkam",
    "saerbeck": "Saerbeck",
    "saffig": "Saffig",
    "sagard": "Sagard",
    "sahms": "Sahms",
    "sailauf": "Sailauf",
    "salach": "Salach",
    "salching": "Salching",
    "saldenburg": "Saldenburg",
    "salem-ratzeburg": "Salem b. Ratzeburg",
    "salem-baden": "Salem/Baden",
    "salgen": "Salgen",
    "sallgast": "Sallgast",
    "salm": "Salm",
    "salmtal": "Salmtal",
    "salz-bad-neustadt": "Salz b. Bad Neustadt",
    "salz-westerwald": "Salz/Westerwald",
    "salzatal": "Salzatal",
    "salzbergen": "Salzbergen",
    "salzburg": "Salzburg",
    "salzgitter": "Salzgitter",
    "salzhausen": "Salzhausen",
    "salzhemmendorf": "Salzhemmendorf",
    "salzkotten": "Salzkotten",
    "salzwedel": "Salzwedel",
    "salzweg": "Salzweg",
    "samerberg": "Samerberg",
    "samern": "Samern",
    "samtens": "Samtens",
    "sand-main": "Sand a. Main",
    "sandau-elbe": "Sandau (Elbe)",
    "sandberg": "Sandberg",
    "sandbostel": "Sandbostel",
    "sande": "Sande",
    "sandersdorf": "Sandersdorf",
    "sandesneben": "Sandesneben",
    "sandhausen": "Sandhausen",
    "sangerhausen": "Sangerhausen",
    "sanitz": "Sanitz",
    "sankt-alban": "Sankt Alban",
    "sankt-aldegund": "Sankt Aldegund",
    "sankt-annen": "Sankt Annen",
    "sankt-augustin": "Sankt Augustin",
    "sankt-bernhard": "Sankt Bernhard",
    "sankt-egidien": "Sankt Egidien",
    "sankt-englmar": "Sankt Englmar",
    "sankt-gangloff": "Sankt Gangloff",
    "sankt-goar": "Sankt Goar",
    "sankt-goarshausen": "Sankt Goarshausen",
    "sankt-johann-mayen": "Sankt Johann b. Mayen",
    "sankt-johann-rheinhessen": "Sankt Johann/Rheinhessen",
    "sankt-johann-wuerttemberg": "Sankt Johann/Württemberg",
    "sankt-julian": "Sankt Julian",
    "sankt-katharinen-bad-kreuznach": "Sankt Katharinen Kr. Bad Kreuznach",
    "sankt-katharinen-neuwied": "Sankt Katharinen/Neuwied",
    "sankt-kilian": "Sankt Kilian",
    "sankt-leon-rot": "Sankt Leon-Rot",
    "sankt-margarethen": "Sankt Margarethen",
    "sankt-maergen": "Sankt Märgen",
    "sankt-martin": "Sankt Martin",
    "sankt-michaelisdonn": "Sankt Michaelisdonn",
    "sankt-oswald-riedlhuette": "Sankt Oswald-Riedlhütte",
    "sankt-peter-ording": "Sankt Peter-Ording",
    "sankt-sebastian": "Sankt Sebastian",
    "sankt-thomas": "Sankt Thomas",
    "sankt-wendel": "Sankt Wendel",
    "sankt-wolfgang": "Sankt Wolfgang",
    "sargenroth": "Sargenroth",
    "sarlhusen": "Sarlhusen",
    "sarmersbach": "Sarmersbach",
    "sarmstorf": "Sarmstorf",
    "sarnow": "Sarnow",
    "sarow": "Sarow",
    "sarstedt": "Sarstedt",
    "sarzbuettel": "Sarzbüttel",
    "sasbach-am-kaiserstuhl": "Sasbach am Kaiserstuhl",
    "sasbach-achern": "Sasbach b. Achern",
    "sasbachwalden": "Sasbachwalden",
    "sassen-eifel": "Sassen/Eifel",
    "sassenberg": "Sassenberg",
    "sassenburg": "Sassenburg",
    "sassen-trantow": "Sassen-Trantow",
    "sassnitz": "Sassnitz",
    "saterland": "Saterland",
    "satow": "Satow",
    "satteldorf": "Satteldorf",
    "sauensiek": "Sauensiek",
    "sauerlach": "Sauerlach",
    "sauerthal": "Sauerthal",
    "sauldorf": "Sauldorf",
    "saulgrub": "Saulgrub",
    "saulheim": "Saulheim",
    "saustrup": "Saustrup",
    "sauzin": "Sauzin",
    "saxler": "Saxler",
    "sayda": "Sayda",
    "schaafheim": "Schaafheim",
    "schaalby": "Schaalby",
    "schacht-audorf": "Schacht-Audorf",
    "schachtebich": "Schachtebich",
    "schackendorf": "Schackendorf",
    "schafflund": "Schafflund",
    "schafstedt": "Schafstedt",
    "schaeftlarn": "Schäftlarn",
    "schalkau": "Schalkau",
    "schalkenbach": "Schalkenbach",
    "schalkenmehren": "Schalkenmehren",
    "schalkham": "Schalkham",
    "schalkholz": "Schalkholz",
    "schalksmuehle": "Schalksmühle",
    "schallbach": "Schallbach",
    "schallodenbach": "Schallodenbach",
    "schallstadt": "Schallstadt",
    "schankweiler": "Schankweiler",
    "schapen": "Schapen",
    "schaprode": "Schaprode",
    "scharbeutz": "Scharbeutz",
    "scharfbillig": "Scharfbillig",
    "scharnebeck": "Scharnebeck",
    "schashagen": "Schashagen",
    "schauenburg": "Schauenburg",
    "schauenstein": "Schauenstein",
    "schauerberg": "Schauerberg",
    "schaufling": "Schaufling",
    "schauren-blankenrath": "Schauren b. Blankenrath",
    "schauren-idar-oberstein": "Schauren b. Idar-Oberstein",
    "schechen": "Schechen",
    "schechingen": "Schechingen",
    "scheden": "Scheden",
    "scheer": "Scheer",
    "scheessel": "Scheeßel",
    "schefflenz": "Schefflenz",
    "scheggerott": "Scheggerott",
    "scheibenberg": "Scheibenberg",
    "scheibenhardt": "Scheibenhardt",
    "scheid": "Scheid",
    "scheidegg": "Scheidegg",
    "scheiditz": "Scheiditz",
    "scheidt": "Scheidt",
    "scheinfeld": "Scheinfeld",
    "scheitenkorb": "Scheitenkorb",
    "schelklingen": "Schelklingen",
    "schellerten": "Schellerten",
    "schellhorn": "Schellhorn",
    "schellweiler": "Schellweiler",
    "schemmerhofen": "Schemmerhofen",
    "schenefeld-hamburg": "Schenefeld b. Hamburg",
    "schenefeld-mittelholstein": "Schenefeld/Mittelholstein",
    "schenkelberg": "Schenkelberg",
    "schenkenberg-prenzlau": "Schenkenberg b. Prenzlau",
    "schenkendoebern": "Schenkendöbern",
    "schenkenzell": "Schenkenzell",
    "schenklengsfeld": "Schenklengsfeld",
    "schermbeck": "Schermbeck",
    "schernfeld": "Schernfeld",
    "scherstetten": "Scherstetten",
    "schesslitz": "Scheßlitz",
    "scheuerfeld": "Scheuerfeld",
    "scheuern": "Scheuern",
    "scheuring": "Scheuring",
    "scheyern": "Scheyern",
    "schieder-schwalenberg": "Schieder-Schwalenberg",
    "schieren": "Schieren",
    "schierensee": "Schierensee",
    "schierling": "Schierling",
    "schiersfeld": "Schiersfeld",
    "schiesheim": "Schiesheim",
    "schiffdorf": "Schiffdorf",
    "schifferstadt": "Schifferstadt",
    "schiffweiler": "Schiffweiler",
    "schilda": "Schilda",
    "schildetal": "Schildetal",
    "schillingen": "Schillingen",
    "schillingsfuerst": "Schillingsfürst",
    "schillingstedt": "Schillingstedt",
    "schillsdorf": "Schillsdorf",
    "schiltach": "Schiltach",
    "schiltberg": "Schiltberg",
    "schimberg": "Schimberg",
    "schindhard": "Schindhard",
    "schinkel": "Schinkel",
    "schiphorst": "Schiphorst",
    "schipkau": "Schipkau",
    "schirgiswalde-kirschau": "Schirgiswalde-Kirschau",
    "schirmitz": "Schirmitz",
    "schirnding": "Schirnding",
    "schkeuditz": "Schkeuditz",
    "schkoelen": "Schkölen",
    "schkopau": "Schkopau",
    "schladen-werla": "Schladen-Werla",
    "schladt": "Schladt",
    "schlagsdorf": "Schlagsdorf",
    "schlaitdorf": "Schlaitdorf",
    "schlammersdorf": "Schlammersdorf",
    "schlangen": "Schlangen",
    "schlangenbad": "Schlangenbad",
    "schlat": "Schlat",
    "schlaubetal": "Schlaubetal",
    "schleching": "Schleching",
    "schlechtsart": "Schlechtsart",
    "schlegel-lobenstein": "Schlegel b. Lobenstein",
    "schlehdorf": "Schlehdorf",
    "schleich": "Schleich",
    "schleid-bitburg": "Schleid b. Bitburg",
    "schleid-vacha": "Schleid b. Vacha",
    "schleiden": "Schleiden",
    "schleife": "Schleife",
    "schleifreisen": "Schleifreisen",
    "schleiz": "Schleiz",
    "schlemmin": "Schlemmin",
    "schlepzig": "Schlepzig",
    "schlesen": "Schlesen",
    "schleswig": "Schleswig",
    "schlettau": "Schlettau",
    "schleusegrund": "Schleusegrund",
    "schleusingen": "Schleusingen",
    "schlichting": "Schlichting",
    "schlieben": "Schlieben",
    "schliengen": "Schliengen",
    "schlier": "Schlier",
    "schlierbach": "Schlierbach",
    "schlierschied": "Schlierschied",
    "schliersee": "Schliersee",
    "schlitz": "Schlitz",
    "schloeben": "Schlöben",
    "schloen-dratow": "Schloen-Dratow",
    "schloss-holte-stukenbrock": "Schloß Holte-Stukenbrock",
    "schlossboeckelheim": "Schloßböckelheim",
    "schlossvippach": "Schloßvippach",
    "schlotfeld": "Schlotfeld",
    "schlotheim": "Schlotheim",
    "schluchsee": "Schluchsee",
    "schluechtern": "Schlüchtern",
    "schluesselfeld": "Schlüsselfeld",
    "schmalenberg": "Schmalenberg",
    "schmalensee": "Schmalensee",
    "schmalfeld": "Schmalfeld",
    "schmalkalden": "Schmalkalden",
    "schmallenberg": "Schmallenberg",
    "schmalstede": "Schmalstede",
    "schmatzin": "Schmatzin",
    "schmedeswurth": "Schmedeswurth",
    "schmeheim": "Schmeheim",
    "schmelz": "Schmelz",
    "schmidgaden": "Schmidgaden",
    "schmidmuehlen": "Schmidmühlen",
    "schmidthachenbach": "Schmidthachenbach",
    "schmiechen": "Schmiechen",
    "schmiedefeld-am-rennsteig": "Schmiedefeld am Rennsteig",
    "schmiedefeld-neuhaus": "Schmiedefeld b. Neuhaus",
    "schmiedehausen": "Schmiedehausen",
    "schmieritz": "Schmieritz",
    "schmilau": "Schmilau",
    "schmissberg": "Schmißberg",
    "schmitshausen": "Schmitshausen",
    "schmitt": "Schmitt",
    "schmitten": "Schmitten",
    "schmittweiler": "Schmittweiler",
    "schmogrow-fehrow": "Schmogrow-Fehrow",
    "schmoelln-thueringen": "Schmölln/Thüringen",
    "schmoelln-putzkau": "Schmölln-Putzkau",
    "schmorda": "Schmorda",
    "schnabelwaid": "Schnabelwaid",
    "schnackenburg": "Schnackenburg",
    "schnaitsee": "Schnaitsee",
    "schnaittach": "Schnaittach",
    "schnaittenbach": "Schnaittenbach",
    "schnakenbek": "Schnakenbek",
    "schnarup-thumby": "Schnarup-Thumby",
    "schnaudertal": "Schnaudertal",
    "schneckenhausen": "Schneckenhausen",
    "schneckenlohe": "Schneckenlohe",
    "schneeberg-erzgebirge": "Schneeberg/Erzgebirge",
    "schneeberg-unterfranken": "Schneeberg/Unterfranken",
    "schnega": "Schnega",
    "schneizlreuth": "Schneizlreuth",
    "schnelldorf": "Schnelldorf",
    "schneppenbach": "Schneppenbach",
    "schneverdingen": "Schneverdingen",
    "schnorbach": "Schnorbach",
    "schnuerpflingen": "Schnürpflingen",
    "schoden": "Schoden",
    "schoeffengrund": "Schöffengrund",
    "schoefweg": "Schöfweg",
    "scholen": "Scholen",
    "schollbrunn": "Schollbrunn",
    "schollene": "Schollene",
    "schoellkrippen": "Schöllkrippen",
    "schoellnach": "Schöllnach",
    "schoemberg-balingen": "Schömberg b. Balingen",
    "schoemberg-neuenbuerg": "Schömberg b. Neuenbürg",
    "schoemerich": "Schömerich",
    "schonach-im-schwarzwald": "Schonach im Schwarzwald",
    "schoenaich": "Schönaich",
    "schoenau-koenigssee": "Schönau a. Königssee",
    "schoenau-brend": "Schönau a.d. Brend",
    "schoenau-im-schwarzwald": "Schönau im Schwarzwald",
    "schoenau-niederbayern": "Schönau/Niederbayern",
    "schoenau-odenwald": "Schönau/Odenwald",
    "schoenau-pfalz": "Schönau/Pfalz",
    "schoenau-berzdorf-eigen": "Schönau-Berzdorf a.d. Eigen",
    "schoenbach-loebau": "Schönbach b. Löbau",
    "schoenbach-daun": "Schönbach Kr. Daun",
    "schoenbeck": "Schönbeck",
    "schoenbek": "Schönbek",
    "schoenberg-glauchau": "Schönberg b. Glauchau",
    "schoenberg-hermeskeil": "Schönberg b. Hermeskeil",
    "schoenberg-muehldorf": "Schönberg Kr. Mühldorf",
    "schoenberg-holstein": "Schönberg/Holstein",
    "schoenberg-lauenburg": "Schönberg/Kr. Hzgt. Lauenburg",
    "schoenberg-mecklenburg": "Schönberg/Mecklenburg",
    "schoenberg-niederbayern": "Schönberg/Niederbayern",
    "schoenborn-niederlausitz": "Schönborn (Niederlausitz)",
    "schoenborn-hunsrueck": "Schönborn/Hunsrück",
    "schoenborn-pfalz": "Schönborn/Pfalz",
    "schoenborn-rhein-lahn-kreis": "Schönborn/Rhein-Lahn-Kreis",
    "schoenbrunn-steigerwald": "Schönbrunn i. Steigerwald",
    "schoenbrunn-baden": "Schönbrunn/Baden",
    "schoenburg": "Schönburg",
    "schondorf-am-ammersee": "Schondorf am Ammersee",
    "schoendorf-schleiz": "Schöndorf b. Schleiz",
    "schoendorf-ruwer": "Schöndorf/Ruwer",
    "schondra": "Schondra",
    "schoenebeck-elbe": "Schönebeck (Elbe)",
    "schoeneberg-brandenburg": "Schöneberg/Brandenburg",
    "schoeneberg-hunsrueck": "Schöneberg/Hunsrück",
    "schoeneberg-westerwald": "Schöneberg/Westerwald",
    "schoeneck-hessen": "Schöneck/Hessen",
    "schoeneck-vogtland": "Schöneck/Vogtland",
    "schoenecken": "Schönecken",
    "schoenefeld": "Schönefeld",
    "schoeneiche-bei-berlin": "Schöneiche bei Berlin",
    "schoenenberg-schwarzwald": "Schönenberg/Schwarzwald",
    "schoenenberg-kuebelberg": "Schönenberg-Kübelberg",
    "schoenermark-gransee": "Schönermark b. Gransee",
    "schoenewalde": "Schönewalde",
    "schoenewoerde": "Schönewörde",
    "schoenfeld-demmin": "Schönfeld b. Demmin",
    "schoenfeld-grossenhain": "Schönfeld b. Großenhain",
    "schoenfeld-prenzlau": "Schönfeld b. Prenzlau",
    "schongau": "Schongau",
    "schoengeising": "Schöngeising",
    "schoengleina": "Schöngleina",
    "schoenhagen-heiligenstadt": "Schönhagen b. Heiligenstadt",
    "schoenhausen-elbe": "Schönhausen (Elbe)",
    "schoenhausen-mecklenburg": "Schönhausen/Mecklenburg",
    "schoenheide": "Schönheide",
    "schoenhorst": "Schönhorst",
    "schoeningen": "Schöningen",
    "schoenkirchen": "Schönkirchen",
    "schoensee": "Schönsee",
    "schoenstedt": "Schönstedt",
    "schonstett": "Schonstett",
    "schoental": "Schöntal",
    "schoenteichen": "Schönteichen",
    "schoenthal": "Schönthal",
    "schonungen": "Schonungen",
    "schoenwald-brandenburg": "Schönwald/Brandenburg",
    "schoenwald-oberfranken": "Schönwald/Oberfranken",
    "schoenwald-schwarzwald": "Schönwald/Schwarzwald",
    "schoenwalde-am-bungsberg": "Schönwalde am Bungsberg",
    "schoenwalde-vorpommern": "Schönwalde Vorpommern",
    "schoenwalde-glien": "Schönwalde-Glien",
    "schoenwoelkau": "Schönwölkau",
    "schopfheim": "Schopfheim",
    "schopfloch-freudenstadt": "Schopfloch Kr. Freudstadt",
    "schopfloch-mittelfranken": "Schopfloch/Mittelfranken",
    "schopp": "Schopp",
    "schoeppenstedt": "Schöppenstedt",
    "schoeppingen": "Schöppingen",
    "schoeps": "Schöps",
    "schoepstal": "Schöpstal",
    "schorfheide": "Schorfheide",
    "schorndorf-oberpfalz": "Schorndorf/Oberpfalz",
    "schorndorf-wuerttemberg": "Schorndorf/Württemberg",
    "schornsheim": "Schornsheim",
    "schorssow": "Schorssow",
    "schortens": "Schortens",
    "schossin": "Schossin",
    "schotten": "Schotten",
    "schraden": "Schraden",
    "schramberg": "Schramberg",
    "schraplau": "Schraplau",
    "schrecksbach": "Schrecksbach",
    "schretstaken": "Schretstaken",
    "schriesheim": "Schriesheim",
    "schrobenhausen": "Schrobenhausen",
    "schrozberg": "Schrozberg",
    "schrum": "Schrum",
    "schuby": "Schuby",
    "schuld": "Schuld",
    "schulendorf": "Schulendorf",
    "schuelldorf": "Schülldorf",
    "schueller": "Schüller",
    "schuelp-dithmarschen": "Schülp b. Dithmarschen",
    "schuelp-nortorf": "Schülp b. Nortorf",
    "schuelp-rendsburg": "Schülp b. Rendsburg",
    "schulzendorf-altdorf": "Schulzendorf Altdorf",
    "schuerdt": "Schürdt",
    "schuerensoehlen": "Schürensöhlen",
    "schuttertal": "Schuttertal",
    "schutterwald": "Schutterwald",
    "schuettorf": "Schüttorf",
    "schutz": "Schutz",
    "schutzbach": "Schutzbach",
    "schwaan": "Schwaan",
    "schwaara": "Schwaara",
    "schwabach": "Schwabach",
    "schwabbruck": "Schwabbruck",
    "schwabenheim-an-der-selz": "Schwabenheim an der Selz",
    "schwabhausen-dachau": "Schwabhausen b. Dachau",
    "schwabhausen-gotha": "Schwabhausen b. Gotha",
    "schwaebisch-gmuend": "Schwäbisch Gmünd",
    "schwaebisch-hall": "Schwäbisch Hall",
    "schwabmuenchen": "Schwabmünchen",
    "schwabsoien": "Schwabsoien",
    "schwabstedt": "Schwabstedt",
    "schwafoerden": "Schwaförden",
    "schwaig-nuernberg": "Schwaig b. Nürnberg",
    "schwaigen": "Schwaigen",
    "schwaigern": "Schwaigern",
    "schwaikheim": "Schwaikheim",
    "schwalbach-am-taunus": "Schwalbach am Taunus",
    "schwalbach-saar": "Schwalbach/Saar",
    "schwall": "Schwall",
    "schwallungen": "Schwallungen",
    "schwalmstadt": "Schwalmstadt",
    "schwalmtal-hessen": "Schwalmtal/Hessen",
    "schwalmtal-niederrhein": "Schwalmtal/Niederrhein",
    "schwanau": "Schwanau",
    "schwandorf": "Schwandorf",
    "schwanebeck-oschersleben": "Schwanebeck b. Oschersleben",
    "schwanewede": "Schwanewede",
    "schwanfeld": "Schwanfeld",
    "schwangau": "Schwangau",
    "schwanheide": "Schwanheide",
    "schwanheim": "Schwanheim",
    "schwanstetten": "Schwanstetten",
    "schwarme": "Schwarme",
    "schwarmstedt": "Schwarmstedt",
    "schwartbuck": "Schwartbuck",
    "schwarz": "Schwarz",
    "schwarza": "Schwarza",
    "schwarzach-main": "Schwarzach a. Main",
    "schwarzach-nabburg": "Schwarzach b. Nabburg",
    "schwarzach-niederbayern": "Schwarzach/Niederbayern",
    "schwarzach-odenwald": "Schwarzach/Odenwald",
    "schwarzatal": "Schwarzatal",
    "schwarzbach-gera": "Schwarzbach b. Gera",
    "schwarzbach-ruhland": "Schwarzbach b. Ruhland",
    "schwarzburg": "Schwarzburg",
    "schwarzen": "Schwarzen",
    "schwarzenbach-am-wald": "Schwarzenbach am Wald",
    "schwarzenbach-oberpfalz": "Schwarzenbach/Oberpfalz",
    "schwarzenbach-saale": "Schwarzenbach/Saale",
    "schwarzenbek": "Schwarzenbek",
    "schwarzenberg-erzgebirge": "Schwarzenberg/Erzgebirge",
    "schwarzenborn-eifel": "Schwarzenborn/Eifel",
    "schwarzenborn-knuell": "Schwarzenborn/Knüll",
    "schwarzenbruck": "Schwarzenbruck",
    "schwarzenfeld": "Schwarzenfeld",
    "schwarzerden": "Schwarzerden",
    "schwarzheide": "Schwarzheide",
    "schwarzhofen": "Schwarzhofen",
    "schwasdorf": "Schwasdorf",
    "schwebheim": "Schwebheim",
    "schwedelbach": "Schwedelbach",
    "schwedeneck": "Schwedeneck",
    "schwedt-oder": "Schwedt/Oder",
    "schwegenheim": "Schwegenheim",
    "schweich": "Schweich",
    "schweickershausen": "Schweickershausen",
    "schweigen-rechtenbach": "Schweigen-Rechtenbach",
    "schweighausen": "Schweighausen",
    "schweighofen": "Schweighofen",
    "schweindorf": "Schweindorf",
    "schweinfurt": "Schweinfurt",
    "schweinschied": "Schweinschied",
    "schweisweiler": "Schweisweiler",
    "schweitenkirchen": "Schweitenkirchen",
    "schweix": "Schweix",
    "schwelm": "Schwelm",
    "schwendi": "Schwendi",
    "schwenningen-dillingen": "Schwenningen Kr. Dillingen",
    "schwenningen-baden": "Schwenningen/Baden",
    "schwentinental": "Schwentinental",
    "schwepnitz": "Schwepnitz",
    "schweppenhausen": "Schweppenhausen",
    "schwerbach": "Schwerbach",
    "schwerin-mecklenburg": "Schwerin",
    "schwerin-koenigs-wusterhausen": "Schwerin b. Königs Wusterhausen",
    "schweringen": "Schweringen",
    "schwerinsdorf": "Schwerinsdorf",
    "schwerstedt-soemmerda": "Schwerstedt b. Sömmerda",
    "schwerstedt-weimar": "Schwerstedt b. Weimar",
    "schwerte": "Schwerte",
    "schwesing": "Schwesing",
    "schwetzingen": "Schwetzingen",
    "schwieberdingen": "Schwieberdingen",
    "schwielochsee": "Schwielochsee",
    "schwielowsee": "Schwielowsee",
    "schwienau": "Schwienau",
    "schwifting": "Schwifting",
    "schwindegg": "Schwindegg",
    "schwirzheim": "Schwirzheim",
    "schwissel": "Schwissel",
    "schwobfeld": "Schwobfeld",
    "schwollen": "Schwollen",
    "schwoerstadt": "Schwörstadt",
    "schwuelper": "Schwülper",
    "sebnitz": "Sebnitz",
    "seck": "Seck",
    "seckach": "Seckach",
    "seddiner-see": "Seddiner See",
    "seebach-eisenach": "Seebach b. Eisenach",
    "seebach-baden": "Seebach/Baden",
    "seebad-heringsdorf": "Seebad Heringsdorf",
    "seeblick": "Seeblick",
    "seeon-seebruck": "Seebruck",
    "seeburg-niedersachsen": "Seeburg/Niedersachsen",
    "seedorf-bad-segeberg": "Seedorf b. Bad Segeberg",
    "seedorf-zeven": "Seedorf b. Zeven",
    "seedorf-lauenburg": "Seedorf/Kr. Hgzt. Lauenburg",
    "seefeld-hanerau-hademarschen": "Seefeld b. Hanerau-Hademarschen",
    "seefeld-oberbayern": "Seefeld/Oberbayern",
    "seeg": "Seeg",
    "seegebiet-mansfelder-land": "Seegebiet Mansfelder Land",
    "seehausen-altmark": "Seehausen (Altmark)",
    "seehausen-staffelsee": "Seehausen a. Staffelsee",
    "seeheim-jugenheim": "Seeheim-Jugenheim",
    "seehof": "Seehof",
    "seekirch": "Seekirch",
    "seeland": "Seeland",
    "seelbach-hamm-sieg": "Seelbach b. Hamm/Sieg",
    "seelbach-nassau": "Seelbach b. Nassau",
    "seelbach-schutter": "Seelbach/Schutter",
    "seelbach-westerwald": "Seelbach/Westerwald",
    "seelen": "Seelen",
    "seelingstaedt": "Seelingstädt",
    "seelitz": "Seelitz",
    "seelow": "Seelow",
    "seelze": "Seelze",
    "seesbach": "Seesbach",
    "seesen": "Seesen",
    "seeshaupt": "Seeshaupt",
    "seester": "Seester",
    "seestermuehe": "Seestermühe",
    "seeth-nordfriesland": "Seeth/Nordfriesland",
    "seeth-ekholt": "Seeth-Ekholt",
    "seevetal": "Seevetal",
    "seewald": "Seewald",
    "seffern": "Seffern",
    "sefferweich": "Sefferweich",
    "seggebruch": "Seggebruch",
    "segnitz": "Segnitz",
    "sehestedt": "Sehestedt",
    "sehlde": "Sehlde",
    "sehlem-wittlich": "Sehlem b. Wittlich",
    "sehlem-niedersachsen": "Sehlem/Niedersachsen",
    "sehlen": "Sehlen",
    "sehmatal": "Sehmatal",
    "sehnde": "Sehnde",
    "seibersbach": "Seibersbach",
    "seifen": "Seifen",
    "seiffen": "Seiffen",
    "seifhennersdorf": "Seifhennersdorf",
    "seinsfeld": "Seinsfeld",
    "seinsheim": "Seinsheim",
    "seisla": "Seisla",
    "seitenroda": "Seitenroda",
    "seitingen-oberflacht": "Seitingen-Oberflacht",
    "seiwerath": "Seiwerath",
    "selb": "Selb",
    "selbach-sieg": "Selbach/Sieg",
    "selbitz-oberfranken": "Selbitz/Oberfranken",
    "selchenbach": "Selchenbach",
    "selent": "Selent",
    "selfkant": "Selfkant",
    "seligenstadt": "Seligenstadt",
    "selk": "Selk",
    "selke-aue": "Selke-Aue",
    "sellerich": "Sellerich",
    "sellin": "Sellin",
    "selm": "Selm",
    "selmsdorf": "Selmsdorf",
    "selpin": "Selpin",
    "selsingen": "Selsingen",
    "selters-taunus": "Selters/Taunus",
    "selters-westerwald": "Selters/Westerwald",
    "selzen": "Selzen",
    "sembach": "Sembach",
    "semlow": "Semlow",
    "semmenstedt": "Semmenstedt",
    "senden-iller": "Senden/Iller",
    "senden-westfalen": "Senden/Westfalen",
    "sendenhorst": "Sendenhorst",
    "senftenberg": "Senftenberg",
    "sengenthal": "Sengenthal",
    "sengerich": "Sengerich",
    "senheim": "Senheim",
    "sennfeld": "Sennfeld",
    "sensbachtal": "Sensbachtal",
    "senscheid": "Senscheid",
    "sensweiler": "Sensweiler",
    "serba": "Serba",
    "serrig": "Serrig",
    "sersheim": "Sersheim",
    "sessenbach": "Sessenbach",
    "sessenhausen": "Sessenhausen",
    "sesslach": "Seßlach",
    "seth": "Seth",
    "setzin": "Setzin",
    "setzingen": "Setzingen",
    "seubersdorf-oberpfalz": "Seubersdorf i.d. Oberpfalz",
    "seukendorf": "Seukendorf",
    "seulingen": "Seulingen",
    "sevenig-neuerburg": "Sevenig b. Neuerburg",
    "sevenig-our": "Sevenig/Our",
    "sexau": "Sexau",
    "seybothenreuth": "Seybothenreuth",
    "sibbesse": "Sibbesse",
    "sickerode": "Sickerode",
    "sickte": "Sickte",
    "siebeldingen": "Siebeldingen",
    "siebenbach": "Siebenbach",
    "siebenbaeumen": "Siebenbäumen",
    "siebeneichen": "Siebeneichen",
    "siedenbollentin": "Siedenbollentin",
    "siedenbruenzow": "Siedenbrünzow",
    "siedenburg": "Siedenburg",
    "siefersheim": "Siefersheim",
    "siegbach": "Siegbach",
    "siegburg": "Siegburg",
    "siegelsbach": "Siegelsbach",
    "siegen": "Siegen",
    "siegenburg": "Siegenburg",
    "siegsdorf": "Siegsdorf",
    "siehdichum": "Siehdichum",
    "siek": "Siek",
    "sielenbach": "Sielenbach",
    "siemz-niendorf": "Siemz-Niendorf",
    "sien": "Sien",
    "sienhachenbach": "Sienhachenbach",
    "sierksdorf": "Sierksdorf",
    "sierksrade": "Sierksrade",
    "sierscheid": "Sierscheid",
    "siershahn": "Siershahn",
    "siesbach": "Siesbach",
    "sietow": "Sietow",
    "sieversdorf-hohenofen": "Sieversdorf-Hohenofen",
    "sievershuetten": "Sievershütten",
    "sieverstedt": "Sieverstedt",
    "siggelkow": "Siggelkow",
    "sigmaringen": "Sigmaringen",
    "sigmaringendorf": "Sigmaringendorf",
    "sigmarszell": "Sigmarszell",
    "silberhausen": "Silberhausen",
    "silberstedt": "Silberstedt",
    "silbitz": "Silbitz",
    "silz-mecklenburg": "Silz/Mecklenburg",
    "silz-pfalz": "Silz/Pfalz",
    "silzen": "Silzen",
    "simbach-inn": "Simbach a. Inn",
    "simbach-niederbayern": "Simbach/Niederbayern",
    "simmelsdorf": "Simmelsdorf",
    "simmerath": "Simmerath",
    "simmern-hunsrueck": "Simmern/Hunsrück",
    "simmern-westerwald": "Simmern/Westerwald",
    "simmersfeld": "Simmersfeld",
    "simmershofen": "Simmershofen",
    "simmertal": "Simmertal",
    "simmozheim": "Simmozheim",
    "simonsberg": "Simonsberg",
    "simonswald": "Simonswald",
    "sindelfingen": "Sindelfingen",
    "sindelsdorf": "Sindelsdorf",
    "singen-hohentwiel": "Singen/Hohentwiel",
    "singhofen": "Singhofen",
    "sinn": "Sinn",
    "sinntal": "Sinntal",
    "sinsheim": "Sinsheim",
    "sinspelt": "Sinspelt",
    "sinzheim": "Sinzheim",
    "sinzig": "Sinzig",
    "sinzing": "Sinzing",
    "sippersfeld": "Sippersfeld",
    "sipplingen": "Sipplingen",
    "sirksfelde": "Sirksfelde",
    "sittensen": "Sittensen",
    "sitters": "Sitters",
    "sitzendorf": "Sitzendorf",
    "soechtenau": "Söchtenau",
    "soderstorf": "Soderstorf",
    "soest": "Soest",
    "soegel": "Sögel",
    "sohland-spree": "Sohland a.d. Spree",
    "soehlde": "Söhlde",
    "sohren": "Sohren",
    "soehrewald": "Söhrewald",
    "sohrschied": "Sohrschied",
    "soelden": "Sölden",
    "solingen": "Solingen",
    "solkwitz": "Solkwitz",
    "sollerup": "Sollerup",
    "soellingen": "Söllingen",
    "sollstedt-wipper": "Sollstedt/Wipper",
    "sollwitt": "Sollwitt",
    "solms": "Solms",
    "solnhofen": "Solnhofen",
    "soltau": "Soltau",
    "soltendieck": "Soltendieck",
    "sommerach": "Sommerach",
    "sommerau": "Sommerau",
    "soemmerda": "Sömmerda",
    "sommerhausen": "Sommerhausen",
    "sommerkahl": "Sommerkahl",
    "sommerland": "Sommerland",
    "sommerloch": "Sommerloch",
    "sommersdorf-boerde": "Sommersdorf b. Börde",
    "sommersdorf-demmin": "Sommersdorf b. Demmin",
    "sonderhofen": "Sonderhofen",
    "sondershausen": "Sondershausen",
    "sondheim-rhoen": "Sondheim v.d. Rhön",
    "sonneberg": "Sonneberg",
    "sonneborn": "Sonneborn",
    "soennebuell": "Sönnebüll",
    "sonnefeld": "Sonnefeld",
    "sonnen": "Sonnen",
    "sonnenberg-brandenburg": "Sonnenberg/Brandenburg",
    "sonnenberg-winnenberg": "Sonnenberg-Winnenberg",
    "sonnenbuehl": "Sonnenbühl",
    "sonnenstein": "Sonnenstein",
    "sonnewalde": "Sonnewalde",
    "sonnschied": "Sonnschied",
    "sonsbeck": "Sonsbeck",
    "sontheim-an-der-brenz": "Sontheim an der Brenz",
    "sontheim-memmingen": "Sontheim b. Memmingen",
    "sonthofen": "Sonthofen",
    "sontra": "Sontra",
    "sophienhamm": "Sophienhamm",
    "soeren": "Sören",
    "soergenloch": "Sörgenloch",
    "soerth": "Sörth",
    "soerup": "Sörup",
    "sosberg": "Sosberg",
    "sottrum": "Sottrum",
    "soyen": "Soyen",
    "spabruecken": "Spabrücken",
    "spahnharrenstaette": "Spahnharrenstätte",
    "spaichingen": "Spaichingen",
    "spall": "Spall",
    "spalt": "Spalt",
    "spangdahlem": "Spangdahlem",
    "spangenberg-liebenbachstadt": "Spangenberg Liebenbachstadt",
    "spantekow": "Spantekow",
    "spardorf": "Spardorf",
    "sparneck": "Sparneck",
    "spatzenhausen": "Spatzenhausen",
    "spay": "Spay",
    "spechbach": "Spechbach",
    "speicher": "Speicher",
    "speichersdorf": "Speichersdorf",
    "speinshart": "Speinshart",
    "spelle": "Spelle",
    "spenge": "Spenge",
    "spesenroth": "Spesenroth",
    "spessart-gemeinde": "Spessart (Gemeinde)",
    "speyer": "Speyer",
    "spiegelau": "Spiegelau",
    "spiegelberg": "Spiegelberg",
    "spiekeroog": "Spiekeroog",
    "spiesen-elversberg": "Spiesen-Elversberg",
    "spiesheim": "Spiesheim",
    "spirkelbach": "Spirkelbach",
    "splietsdorf": "Splietsdorf",
    "sponheim": "Sponheim",
    "sponholz": "Sponholz",
    "spornitz": "Spornitz",
    "spraitbach": "Spraitbach",
    "sprakebuell": "Sprakebüll",
    "sprakensehl": "Sprakensehl",
    "spreenhagen": "Spreenhagen",
    "spreetal": "Spreetal",
    "spreewaldheide": "Spreewaldheide",
    "spremberg": "Spremberg",
    "sprendlingen": "Sprendlingen",
    "springe": "Springe",
    "springstille": "Springstille",
    "sprockhoevel": "Sprockhövel",
    "sproetau": "Sprötau",
    "sankt-blasien": "St. Blasien",
    "st-georgen": "St. Georgen/Schwarzwald",
    "sankt-ingbert": "St. Ingbert",
    "st-peter-schwarzwald": "St. Peter Schwarzwald",
    "staebelow": "Stäbelow",
    "stade": "Stade",
    "stadecken-elsheim": "Stadecken-Elsheim",
    "stadelhofen": "Stadelhofen",
    "stadland": "Stadland",
    "stadlern": "Stadlern",
    "stadt-fehmarn": "Stadt Fehmarn",
    "stadtallendorf": "Stadtallendorf",
    "stadtbergen": "Stadtbergen",
    "stadthagen": "Stadthagen",
    "stadtilm": "Stadtilm",
    "stadtkyll": "Stadtkyll",
    "stadtlauringen": "Stadtlauringen",
    "stadtlengsfeld": "Stadtlengsfeld",
    "stadtlohn": "Stadtlohn",
    "stadtoldendorf": "Stadtoldendorf",
    "stadtprozelten": "Stadtprozelten",
    "stadtroda": "Stadtroda",
    "stadtsteinach": "Stadtsteinach",
    "stadum": "Stadum",
    "staffhorst": "Staffhorst",
    "stafstedt": "Stafstedt",
    "stahlberg": "Stahlberg",
    "stahlhofen-westerwald": "Stahlhofen/Westerwald",
    "stahlhofen-wiesensee": "Stahlhofen/Wiesensee",
    "stahnsdorf": "Stahnsdorf",
    "staig": "Staig",
    "stakendorf": "Stakendorf",
    "stallwang": "Stallwang",
    "stammbach": "Stammbach",
    "stammham-inn": "Stammham a. Inn",
    "stammham-ingolstadt": "Stammham b. Ingolstadt",
    "stamsried": "Stamsried",
    "stanau": "Stanau",
    "standenbuehl": "Standenbühl",
    "stangheck": "Stangheck",
    "stapel": "Stapel",
    "stapelfeld": "Stapelfeld",
    "starkenberg": "Starkenberg",
    "starkenburg": "Starkenburg",
    "starnberg": "Starnberg",
    "starzach": "Starzach",
    "stassfurt": "Staßfurt",
    "stauchitz": "Stauchitz",
    "staudach-egerndach": "Staudach-Egerndach",
    "staudernheim": "Staudernheim",
    "staudt": "Staudt",
    "staufen-im-breisgau": "Staufen im Breisgau",
    "staufenberg-hessen": "Staufenberg/Hessen",
    "staufenberg-niedersachsen": "Staufenberg/Niedersachsen",
    "staven": "Staven",
    "stavern": "Stavern",
    "stebach": "Stebach",
    "stechlin": "Stechlin",
    "stechow-ferchesar": "Stechow-Ferchesar",
    "stedesand": "Stedesand",
    "stedesdorf": "Stedesdorf",
    "steenfeld": "Steenfeld",
    "steesow": "Steesow",
    "steffeln": "Steffeln",
    "steffenberg": "Steffenberg",
    "steffenshagen-bad-doberan": "Steffenshagen b. Bad Doberan",
    "stegaurach": "Stegaurach",
    "stegen": "Stegen",
    "steigra": "Steigra",
    "steimbke": "Steimbke",
    "steimel": "Steimel",
    "stein-laboe": "Stein b. Laboe",
    "stein-mittelfanken": "Stein/Mittelfranken",
    "steina": "Steina",
    "steinach-straubing": "Steinach b. Straubing",
    "steinach-baden": "Steinach/Baden",
    "steinach-thueringen": "Steinach/Thüringen",
    "steinalben": "Steinalben",
    "steinau-an-der-strasse": "Steinau an der Straße",
    "steinau-niederelbe": "Steinau/Niederelbe",
    "steinbach-wald": "Steinbach a. Wald",
    "steinbach-am-donnersberg": "Steinbach am Donnersberg",
    "steinbach-am-glan": "Steinbach am Glan",
    "steinbach-heiligenstadt": "Steinbach b. Heiligenstadt",
    "steinbach-hunsrueck": "Steinbach/Hunsrück",
    "steinbach-taunus": "Steinbach/Taunus",
    "steinbach-hallenberg": "Steinbach-Hallenberg",
    "steinberg-am-see": "Steinberg am See",
    "steinberg-flensburg": "Steinberg b. Flensburg",
    "steinberg-sachsen": "Steinberg/Sachsen",
    "steinbergkirche": "Steinbergkirche",
    "stein-bockenheim": "Stein-Bockenheim",
    "steinborn": "Steinborn",
    "steinburg-stormarn": "Steinburg/Kr. Stormarn",
    "steindorf": "Steindorf",
    "steinebach-an-der-wied": "Steinebach an der Wied",
    "steinebach-sieg": "Steinebach/Sieg",
    "steineberg": "Steineberg",
    "steinefrenz": "Steinefrenz",
    "steinen-loerrach": "Steinen Kr. Lörrach",
    "steinen-westerwald": "Steinen/Westerwald",
    "steinenbronn": "Steinenbronn",
    "steineroth": "Steineroth",
    "steinfeld-lohr-main": "Steinfeld b. Lohr a. Main",
    "steinfeld-oldenburg": "Steinfeld/Oldenburg",
    "steinfeld-pfalz": "Steinfeld/Pfalz",
    "steinfeld-schleswig": "Steinfeld/Schleswig",
    "steinfurt": "Steinfurt",
    "steingaden": "Steingaden",
    "steinhagen-buetzow": "Steinhagen b. Bützow",
    "steinhagen-stralsund": "Steinhagen b. Stralsund",
    "steinhagen-westfalen": "Steinhagen/Westfalen",
    "steinhausen-an-der-rottum": "Steinhausen an der Rottum",
    "steinheim-am-albuch": "Steinheim am Albuch",
    "steinheim-an-der-murr": "Steinheim an der Murr",
    "steinheim-westfalen": "Steinheim/Westfalen",
    "steinheuterode": "Steinheuterode",
    "steinhoefel": "Steinhöfel",
    "steinhoering": "Steinhöring",
    "steinhorst-lauenburg": "Steinhorst/Kr. Hzgt. Lauenburg",
    "steinhorst-niedersachsen": "Steinhorst/Niedersachsen",
    "steinigtwolmsdorf": "Steinigtwolmsdorf",
    "steiningen": "Steiningen",
    "steinkirchen-stade": "Steinkirchen Kr. Stade",
    "steinkirchen-holzland": "Steinkirchen/Holzland",
    "steinmauern": "Steinmauern",
    "stein-neukirch": "Stein-Neukirch",
    "steinreich": "Steinreich",
    "steinsberg": "Steinsberg",
    "steinsfeld": "Steinsfeld",
    "steinweiler": "Steinweiler",
    "steinwenden": "Steinwenden",
    "steinwiesen": "Steinwiesen",
    "stein-wingert": "Stein-Wingert",
    "steisslingen": "Steißlingen",
    "stelle-harburg": "Stelle Kr. Harburg",
    "stelle-wittenwurth": "Stelle-Wittenwurth",
    "stelzenberg": "Stelzenberg",
    "stemmen": "Stemmen",
    "stemshorn": "Stemshorn",
    "stemwede": "Stemwede",
    "stendal": "Stendal",
    "stepenitztal": "Stepenitztal",
    "stepfershausen": "Stepfershausen",
    "stephanskirchen": "Stephanskirchen",
    "stephansposching": "Stephansposching",
    "sterley": "Sterley",
    "sternberg": "Sternberg",
    "sternenfels": "Sternenfels",
    "sterup": "Sterup",
    "stetten-am-kalten-markt": "Stetten am kalten Markt",
    "stetten-meersburg": "Stetten b. Meersburg",
    "stetten-pfalz": "Stetten/Pfalz",
    "stetten-schwaben": "Stetten/Schwaben",
    "stettfeld": "Stettfeld",
    "steyerberg": "Steyerberg",
    "stiefenhofen": "Stiefenhofen",
    "stimpfach": "Stimpfach",
    "stinstedt": "Stinstedt",
    "stipsdorf": "Stipsdorf",
    "stipshausen": "Stipshausen",
    "stockach": "Stockach",
    "stockelsdorf": "Stockelsdorf",
    "stockem": "Stockem",
    "stockhausen-illfurth": "Stockhausen-Illfurth",
    "stockheim-oberfranken": "Stockheim/Oberfranken",
    "stockheim-unterfranken": "Stockheim/Unterfranken",
    "stoeckse": "Stöckse",
    "stocksee": "Stocksee",
    "stockstadt-main": "Stockstadt a. Main",
    "stockstadt-am-rhein": "Stockstadt am Rhein",
    "stockum-pueschen": "Stockum-Püschen",
    "stoedtlen": "Stödtlen",
    "stoetze": "Stoetze",
    "stolberg-rheinland": "Stolberg/Rheinland",
    "stolk": "Stolk",
    "stollberg-erzgebirge": "Stollberg/Erzgebirge",
    "stolpe-peene": "Stolpe an der Peene",
    "stolpe-auf-usedom": "Stolpe auf Usedom",
    "stolpe-anklam": "Stolpe b. Anklam",
    "stolpe-neumuenster": "Stolpe b. Neumünster",
    "stolpe-parchim": "Stolpe b. Parchim",
    "stolpen": "Stolpen",
    "stoltebuell": "Stoltebüll",
    "stoltenberg": "Stoltenberg",
    "stolzenau": "Stolzenau",
    "storbeck-frankendorf": "Storbeck-Frankendorf",
    "stoerdorf": "Stördorf",
    "stoerkathen": "Störkathen",
    "storkow-mark": "Storkow (Mark)",
    "stoernstein": "Störnstein",
    "stoessen": "Stößen",
    "stoetten-auerberg": "Stötten a. Auerberg",
    "stoettwang": "Stöttwang",
    "straelen": "Straelen",
    "strahlungen": "Strahlungen",
    "stralendorf-schwerin": "Stralendorf b. Schwerin",
    "stralsund": "Stralsund",
    "strande": "Strande",
    "strasburg-uckermark": "Strasburg/Uckermark",
    "strassberg-hohenz": "Straßberg b. Hohenz",
    "strassenhaus": "Straßenhaus",
    "strasskirchen": "Straßkirchen",
    "strasslach-dingharting": "Straßlach-Dingharting",
    "straubenhardt": "Straubenhardt",
    "straubing": "Straubing",
    "straufhain": "Straufhain",
    "straupitz": "Straupitz",
    "strausberg": "Strausberg",
    "straussfurt": "Straußfurt",
    "strehla": "Strehla",
    "streithausen": "Streithausen",
    "strickscheid": "Strickscheid",
    "striegistal": "Striegistal",
    "strohkirchen": "Strohkirchen",
    "strohn": "Strohn",
    "stromberg": "Stromberg",
    "strotzbuesch": "Strotzbüsch",
    "struebbel": "Strübbel",
    "struckum": "Struckum",
    "strukdorf": "Strukdorf",
    "strullendorf": "Strullendorf",
    "struppen": "Struppen",
    "strueth": "Strüth",
    "struvenhuetten": "Struvenhütten",
    "struxdorf": "Struxdorf",
    "stubben-bad-oldesloe": "Stubben b. Bad Oldesloe",
    "stubbendorf-grimmen": "Stubbendorf b. Grimmen",
    "stubenberg": "Stubenberg",
    "stuedenitz-schoenermark": "Stüdenitz-Schönermark",
    "stuer": "Stuer",
    "stuehlingen": "Stühlingen",
    "stuhr": "Stuhr",
    "stulln": "Stulln",
    "stuerzelbach": "Stürzelbach",
    "stutensee": "Stutensee",
    "stuttgart": "Stuttgart",
    "stuetzengruen": "Stützengrün",
    "stuetzerbach": "Stützerbach",
    "stuvenborn": "Stuvenborn",
    "suckow": "Suckow",
    "suedbrookmerland": "Südbrookmerland",
    "suedeichsfeld": "Südeichsfeld",
    "suederau": "Süderau",
    "suederbrarup": "Süderbrarup",
    "suderburg": "Suderburg",
    "suederdeich": "Süderdeich",
    "suederdorf": "Süderdorf",
    "suederende": "Süderende",
    "suederfahrenstedt": "Süderfahrenstedt",
    "suedergellersen": "Südergellersen",
    "suederhackstedt": "Süderhackstedt",
    "suederhastedt": "Süderhastedt",
    "suederheistedt": "Süderheistedt",
    "suederhoeft": "Süderhöft",
    "suederholz": "Süderholz",
    "suederluegum": "Süderlügum",
    "suedermarsch": "Südermarsch",
    "suederstapel": "Süderstapel",
    "suedharz": "Südharz",
    "suedheide": "Südheide",
    "suedliches-anhalt": "Südliches Anhalt",
    "suedlohn": "Südlohn",
    "suedmueritz": "Südmüritz",
    "sudwalde": "Sudwalde",
    "sugenheim": "Sugenheim",
    "suhl": "Suhl",
    "suhlendorf": "Suhlendorf",
    "sukow-mecklenburg": "Sukow/Meckl.",
    "sukow-levitzow": "Sukow-Levitzow",
    "suelfeld": "Sülfeld",
    "sulingen": "Sulingen",
    "suelm": "Sülm",
    "suelstorf": "Sülstorf",
    "sulz-am-neckar": "Sulz am Neckar",
    "sulza": "Sulza",
    "sulzbach-main": "Sulzbach a. Main",
    "sulzbach-an-der-murr": "Sulzbach an der Murr",
    "sulzbach-idar-oberstein": "Sulzbach b. Idar-Oberstein",
    "sulzbach-rhein-lahn-kreis": "Sulzbach/Rhein-Lahn-Kreis",
    "sulzbach-saar": "Sulzbach/Saar",
    "sulzbach-taunus": "Sulzbach/Taunus",
    "sulzbach-laufen": "Sulzbach-Laufen",
    "sulzbach-rosenberg": "Sulzbach-Rosenberg",
    "sulzbachtal": "Sulzbachtal",
    "sulzberg": "Sulzberg",
    "sulzburg": "Sulzburg",
    "sulzdorf-lederhecke": "Sulzdorf a.d. Lederhecke",
    "sulzemoos": "Sulzemoos",
    "suelzetal": "Sülzetal",
    "suelzfeld": "Sülzfeld",
    "sulzfeld-main": "Sulzfeld a. Main",
    "sulzfeld-grabfeld": "Sulzfeld b. Grabfeld",
    "sulzfeld-baden": "Sulzfeld/Baden",
    "sulzheim-rheinhessen": "Sulzheim/Rheinhessen",
    "sulzheim-unterfranken": "Sulzheim/Unterfranken",
    "sulzthal": "Sulzthal",
    "suenching": "Sünching",
    "sundern-sauerland": "Sundern/Sauerland",
    "sundhagen": "Sundhagen",
    "sundhausen": "Sundhausen",
    "suepplingen": "Süpplingen",
    "suepplingenburg": "Süpplingenburg",
    "surberg": "Surberg",
    "surwold": "Surwold",
    "suesel": "Süsel",
    "suessen": "Süßen",
    "suestedt": "Süstedt",
    "sustrum": "Sustrum",
    "suthfeld": "Suthfeld",
    "swisttal": "Swisttal",
    "sydower-fliess": "Sydower Fließ",
    "syke": "Syke",
    "sylt": "Sylt",
    "syrgenstein": "Syrgenstein",
    "taarstedt": "Taarstedt",
    "tabarz-thueringer-wald": "Tabarz/Thüringer Wald",
    "taben-rodt": "Taben-Rodt",
    "tacherting": "Tacherting",
    "taching-see": "Taching a. See",
    "tackesdorf": "Tackesdorf",
    "taeferrot": "Täferrot",
    "tagmersheim": "Tagmersheim",
    "talheim-tuttlingen": "Talheim Kr. Tuttlingen",
    "talheim-neckar": "Talheim/Neckar",
    "talkau": "Talkau",
    "talling": "Talling",
    "tambach-dietharz-thueringer-wald": "Tambach-Dietharz/Thüringer Wald",
    "tamm": "Tamm",
    "tangerhuette": "Tangerhütte",
    "tangermuende": "Tangermünde",
    "tangstedt-bezirk-hamburg": "Tangstedt Bezirk Hamburg",
    "tangstedt-pinneberg": "Tangstedt/Kr. Pinneberg",
    "tann-niederbayern": "Tann/Niederbayern",
    "tann-rhoen": "Tann/Rhön",
    "tanna": "Tanna",
    "tannenberg": "Tannenberg",
    "taennesberg": "Tännesberg",
    "tannhausen": "Tannhausen",
    "tannheim": "Tannheim",
    "tantow": "Tantow",
    "tapfheim": "Tapfheim",
    "tappenbeck": "Tappenbeck",
    "tappendorf": "Tappendorf",
    "tarbek": "Tarbek",
    "tarmstedt": "Tarmstedt",
    "tarnow": "Tarnow",
    "tarp": "Tarp",
    "tasdorf": "Tasdorf",
    "tastrup": "Tastrup",
    "tastungen": "Tastungen",
    "tating": "Tating",
    "tauberbischofsheim": "Tauberbischofsheim",
    "tauberrettersheim": "Tauberrettersheim",
    "taucha-leipzig": "Taucha b. Leipzig",
    "tauche": "Tauche",
    "tauer": "Tauer",
    "taufkirchen-muehldorf": "Taufkirchen Kr. Mühldorf",
    "taufkirchen-muenchen": "Taufkirchen/Kr. München",
    "taufkirchen-vils": "Taufkirchen/Vils",
    "taunusstein": "Taunusstein",
    "taura": "Taura",
    "tauscha-grossenhain": "Tauscha b. Großenhain",
    "tautenburg": "Tautenburg",
    "tautendorf": "Tautendorf",
    "tautenhain": "Tautenhain",
    "tawern": "Tawern",
    "techelsdorf": "Techelsdorf",
    "techentin": "Techentin",
    "tecklenburg": "Tecklenburg",
    "tegau": "Tegau",
    "tegernheim": "Tegernheim",
    "tegernsee": "Tegernsee",
    "teichland": "Teichland",
    "teichwitz": "Teichwitz",
    "teisendorf": "Teisendorf",
    "teising": "Teising",
    "teisnach": "Teisnach",
    "teistungen": "Teistungen",
    "teldau": "Teldau",
    "telgte": "Telgte",
    "tellig": "Tellig",
    "tellingstedt": "Tellingstedt",
    "teltow": "Teltow",
    "temmels": "Temmels",
    "temmen-ringenwalde": "Temmen-Ringenwalde",
    "temnitzquell": "Temnitzquell",
    "temnitztal": "Temnitztal",
    "templin": "Templin",
    "tengen": "Tengen",
    "teningen": "Teningen",
    "tensbuettel-roest": "Tensbüttel-Röst",
    "tensfeld": "Tensfeld",
    "teschenmoschel": "Teschenmoschel",
    "tespe": "Tespe",
    "tessenow": "Tessenow",
    "tessin-boizenburg": "Tessin b. Boizenburg",
    "tessin-rostock": "Tessin b. Rostock",
    "testorf-steinfort": "Testorf-Steinfort",
    "tetenbuell": "Tetenbüll",
    "tetenhusen": "Tetenhusen",
    "teterow": "Teterow",
    "tettau-ruhland": "Tettau b. Ruhland",
    "tettau-oberfranken": "Tettau/Oberfranken",
    "tettenweis": "Tettenweis",
    "tettnang": "Tettnang",
    "teublitz": "Teublitz",
    "teuchern": "Teuchern",
    "teugn": "Teugn",
    "teunz": "Teunz",
    "teupitz": "Teupitz",
    "teuschnitz": "Teuschnitz",
    "teutschenthal": "Teutschenthal",
    "thaden": "Thaden",
    "thaining": "Thaining",
    "thale": "Thale",
    "thaleischweiler-froeschen": "Thaleischweiler-Fröschen",
    "thalfang": "Thalfang",
    "thalhausen": "Thalhausen",
    "thalheim-erzgebirge": "Thalheim/Erzgebirge",
    "thallichtenberg": "Thallichtenberg",
    "thallwitz": "Thallwitz",
    "thalmassing": "Thalmassing",
    "thalmaessing": "Thalmässing",
    "thalwenden": "Thalwenden",
    "thandorf": "Thandorf",
    "thannhausen": "Thannhausen",
    "thanstein": "Thanstein",
    "tharandt": "Tharandt",
    "thedinghausen": "Thedinghausen",
    "theilenhofen": "Theilenhofen",
    "theilheim": "Theilheim",
    "theisbergstegen": "Theisbergstegen",
    "theisseil": "Theisseil",
    "thelkow": "Thelkow",
    "themar": "Themar",
    "theres": "Theres",
    "thermalbad-wiesenbad": "Thermalbad Wiesenbad",
    "theuma": "Theuma",
    "thiendorf": "Thiendorf",
    "thierhaupten": "Thierhaupten",
    "thierschneck": "Thierschneck",
    "thiersheim": "Thiersheim",
    "thierstein": "Thierstein",
    "thiessow": "Thiessow",
    "tholey": "Tholey",
    "thomasburg": "Thomasburg",
    "thomm": "Thomm",
    "thonhausen": "Thonhausen",
    "thoerlingen": "Thörlingen",
    "thoernich": "Thörnich",
    "thuine": "Thuine",
    "thulendorf": "Thulendorf",
    "thum": "Thum",
    "thumby": "Thumby",
    "thundorf-unterfranken": "Thundorf i. Unterfranken",
    "thuengen": "Thüngen",
    "thuengersheim": "Thüngersheim",
    "thuer": "Thür",
    "thueringenhausen": "Thüringenhausen",
    "thuerkow": "Thürkow",
    "thurmansbang": "Thurmansbang",
    "thurnau": "Thurnau",
    "thyrnau": "Thyrnau",
    "tiddische": "Tiddische",
    "tiefenbach-landshut": "Tiefenbach Kr. Landshut",
    "tiefenbach-passau": "Tiefenbach Kr. Passau",
    "tiefenbach-federsee": "Tiefenbach/Federsee",
    "tiefenbach-hunsrueck": "Tiefenbach/Hunsrück",
    "tiefenbach-oberpfalz": "Tiefenbach/Oberpfalz",
    "tiefenbronn": "Tiefenbronn",
    "tiefenort": "Tiefenort",
    "tiefenthal-pfalz": "Tiefenthal/Pfalz",
    "tiefenthal-rheinhessen": "Tiefenthal/Rheinhessen",
    "tielen": "Tielen",
    "tielenhemme": "Tielenhemme",
    "timmaspe": "Timmaspe",
    "timmendorfer-strand": "Timmendorfer Strand",
    "tinningstedt": "Tinningstedt",
    "tirpersdorf": "Tirpersdorf",
    "tirschenreuth": "Tirschenreuth",
    "tissa": "Tissa",
    "tiste": "Tiste",
    "titisee-neustadt": "Titisee-Neustadt",
    "titting": "Titting",
    "tittling": "Tittling",
    "tittmoning": "Tittmoning",
    "titz": "Titz",
    "toddin": "Toddin",
    "todenbuettel": "Todenbüttel",
    "todendorf": "Todendorf",
    "todenroth": "Todenroth",
    "todesfelde": "Todesfelde",
    "todtenweis": "Todtenweis",
    "todtmoos": "Todtmoos",
    "todtnau": "Todtnau",
    "toeging-inn": "Töging a. Inn",
    "tolk": "Tolk",
    "toemmelsdorf": "Tömmelsdorf",
    "toenisvorst": "Tönisvorst",
    "tonna": "Tonna",
    "tonndorf": "Tonndorf",
    "toenning": "Tönning",
    "toepen": "Töpen",
    "topfstedt": "Topfstedt",
    "toppenstedt": "Toppenstedt",
    "torgau": "Torgau",
    "torgelow-waren": "Torgelow b. Waren",
    "torgelow-vorpommern": "Torgelow/Vorpommern",
    "tornesch": "Tornesch",
    "tostedt": "Tostedt",
    "tosterglope": "Tosterglope",
    "tottleben": "Tottleben",
    "traben-trarbach": "Traben-Trarbach",
    "trabitz": "Trabitz",
    "train": "Train",
    "traisen": "Traisen",
    "traitsching": "Traitsching",
    "tramm-schwerin": "Tramm b. Schwerin",
    "tramm-lauenburg": "Tramm/Kr. Hzgt. Lauenburg",
    "trappenkamp": "Trappenkamp",
    "trappstadt": "Trappstadt",
    "trassem": "Trassem",
    "trassenheide": "Trassenheide",
    "traunreut": "Traunreut",
    "traunstein": "Traunstein",
    "trausnitz": "Trausnitz",
    "trautskirchen": "Trautskirchen",
    "travenbrueck": "Travenbrück",
    "travenhorst": "Travenhorst",
    "traventhal": "Traventhal",
    "trebbin": "Trebbin",
    "trebel": "Trebel",
    "treben": "Treben",
    "trebendorf-weisswasser": "Trebendorf b. Weißwasser",
    "trebgast": "Trebgast",
    "trebra-kyffhaeuser": "Trebra/Kyffhäuser",
    "trebsen-mulde": "Trebsen/Mulde",
    "trebur": "Trebur",
    "trechtingshausen": "Trechtingshausen",
    "treffelstein": "Treffelstein",
    "treffurt": "Treffurt",
    "treia": "Treia",
    "treis-karden": "Treis-Karden",
    "tremsbuettel": "Tremsbüttel",
    "trendelburg": "Trendelburg",
    "trennewurth": "Trennewurth",
    "trent": "Trent",
    "treplin": "Treplin",
    "treuchtlingen": "Treuchtlingen",
    "treuen": "Treuen",
    "treuenbrietzen": "Treuenbrietzen",
    "triberg-im-schwarzwald": "Triberg im Schwarzwald",
    "tribsees": "Tribsees",
    "triebel-vogtland": "Triebel/Vogtland",
    "triefenstein": "Triefenstein",
    "trier": "Trier",
    "trierscheid": "Trierscheid",
    "trierweiler": "Trierweiler",
    "triftern": "Triftern",
    "triglitz": "Triglitz",
    "trimbs": "Trimbs",
    "trimport": "Trimport",
    "trinwillershagen": "Trinwillershagen",
    "trippstadt": "Trippstadt",
    "triptis": "Triptis",
    "trittau": "Trittau",
    "trittenheim": "Trittenheim",
    "troebitz": "Tröbitz",
    "troebnitz": "Tröbnitz",
    "troechtelborn": "Tröchtelborn",
    "trochtelfingen": "Trochtelfingen",
    "trockenborn-wolfersdorf": "Trockenborn-Wolfersdorf",
    "trogen": "Trogen",
    "troisdorf": "Troisdorf",
    "troistedt": "Troistedt",
    "trollenhagen": "Trollenhagen",
    "troendel": "Tröndel",
    "trossin": "Trossin",
    "trossingen": "Trossingen",
    "troestau": "Tröstau",
    "trostberg": "Trostberg",
    "trulben": "Trulben",
    "trunkelsberg": "Trunkelsberg",
    "tschernitz": "Tschernitz",
    "tschirn": "Tschirn",
    "tuebingen": "Tübingen",
    "tuchenbach": "Tuchenbach",
    "tuelau": "Tülau",
    "tuemlauer-koog": "Tümlauer Koog",
    "tunau": "Tunau",
    "tuningen": "Tuningen",
    "tuntenhausen": "Tuntenhausen",
    "tuerkenfeld": "Türkenfeld",
    "tuerkheim": "Türkheim",
    "turnow-preilack": "Turnow-Preilack",
    "tussenhausen": "Tussenhausen",
    "tuessling": "Tüßling",
    "tutow": "Tutow",
    "tuettendorf": "Tüttendorf",
    "tuettleben": "Tüttleben",
    "tuttlingen": "Tuttlingen",
    "tutzing": "Tutzing",
    "tuetzpatz": "Tützpatz",
    "twedt": "Twedt",
    "twieflingen": "Twieflingen",
    "twist": "Twist",
    "twistetal": "Twistetal",
    "twistringen": "Twistringen",
    "tyrlaching": "Tyrlaching",
    "uebach-palenberg": "Übach-Palenberg",
    "uebereisenbach": "Übereisenbach",
    "ueberherrn": "Überherrn",
    "ueberlingen": "Überlingen",
    "uebersee": "Übersee",
    "ubstadt-weiher": "Ubstadt-Weiher",
    "uchte": "Uchte",
    "uechtelhausen": "Üchtelhausen",
    "uckerfelde": "Uckerfelde",
    "ueckeritz": "Ückeritz",
    "uckerland": "Uckerland",
    "udenheim": "Udenheim",
    "uder": "Uder",
    "uedersdorf": "Üdersdorf",
    "udestedt": "Udestedt",
    "udler": "Udler",
    "ueckermuende": "Ueckermünde",
    "uedem": "Uedem",
    "uehlfeld": "Uehlfeld",
    "uehrde": "Uehrde",
    "uelitz": "Uelitz",
    "uelsby": "Uelsby",
    "uelsen": "Uelsen",
    "uelversheim": "Uelversheim",
    "uelvesbuell": "Uelvesbüll",
    "uelzen": "Uelzen",
    "uersfeld": "Uersfeld",
    "uess": "Ueß",
    "uetersen": "Uetersen",
    "uettingen": "Uettingen",
    "uetze": "Uetze",
    "uffenheim": "Uffenheim",
    "uffing-staffelsee": "Uffing a. Staffelsee",
    "uhingen": "Uhingen",
    "uhldingen-muehlhofen": "Uhldingen-Mühlhofen",
    "uhler": "Uhler",
    "uehlingen-birkendorf": "Ühlingen-Birkendorf",
    "uhlstaedt-kirchhasel": "Uhlstädt-Kirchhasel",
    "ulm": "Ulm",
    "ulmen": "Ulmen",
    "ulmet": "Ulmet",
    "ulrichstein": "Ulrichstein",
    "ulsnis": "Ulsnis",
    "umkirch": "Umkirch",
    "ummanz": "Ummanz",
    "ummendorf-biberach": "Ummendorf b. Biberach",
    "ummendorf-boerde": "Ummendorf/Börde",
    "ummern": "Ummern",
    "ummerstadt": "Ummerstadt",
    "umpferstedt": "Umpferstedt",
    "undeloh": "Undeloh",
    "undenheim": "Undenheim",
    "ungerhausen": "Ungerhausen",
    "unkel": "Unkel",
    "unkenbach": "Unkenbach",
    "unlingen": "Unlingen",
    "unna": "Unna",
    "unnau": "Unnau",
    "unsleben": "Unsleben",
    "unstrut-hainich": "Unstrut-Hainich",
    "unstruttal": "Unstruttal",
    "unterammergau": "Unterammergau",
    "unterbodnitz": "Unterbodnitz",
    "unterbreizbach": "Unterbreizbach",
    "unterdiessen": "Unterdießen",
    "unterdietfurt": "Unterdietfurt",
    "unteregg": "Unteregg",
    "untereisesheim": "Untereisesheim",
    "unterensingen": "Unterensingen",
    "unterfoehring": "Unterföhring",
    "untergriesbach": "Untergriesbach",
    "untergruppenbach": "Untergruppenbach",
    "unterhaching": "Unterhaching",
    "unterjeckenbach": "Unterjeckenbach",
    "unterkatz": "Unterkatz",
    "unterkirnach": "Unterkirnach",
    "unterleinleiter": "Unterleinleiter",
    "untermarchtal": "Untermarchtal",
    "untermassfeld": "Untermaßfeld",
    "untermeitingen": "Untermeitingen",
    "untermerzbach": "Untermerzbach",
    "untermuenkheim": "Untermünkheim",
    "unterneukirchen": "Unterneukirchen",
    "unterpleichfeld": "Unterpleichfeld",
    "unterreichenbach": "Unterreichenbach",
    "unterreit": "Unterreit",
    "unterroth": "Unterroth",
    "unterschleissheim": "Unterschleißheim",
    "unterschneidheim": "Unterschneidheim",
    "unterschoenau": "Unterschönau",
    "unterschwaningen": "Unterschwaningen",
    "untershausen": "Untershausen",
    "untersiemau": "Untersiemau",
    "unterspreewald": "Unterspreewald",
    "unterstadion": "Unterstadion",
    "untersteinach": "Untersteinach",
    "unterthingau": "Unterthingau",
    "unterwachingen": "Unterwachingen",
    "unterwaldhausen": "Unterwaldhausen",
    "unterweid": "Unterweid",
    "unterweissbach": "Unterweißbach",
    "unterwellenborn": "Unterwellenborn",
    "unterwoessen": "Unterwössen",
    "untrasried": "Untrasried",
    "unzenberg": "Unzenberg",
    "upahl": "Upahl",
    "upgant-schott": "Upgant-Schott",
    "uphusum": "Uphusum",
    "uplengen": "Uplengen",
    "uppershausen": "Uppershausen",
    "urbach-nordhausen": "Urbach b. Nordhausen",
    "urbach-rems": "Urbach/Rems",
    "urbach-westerwald": "Urbach/Westerwald",
    "urbar-koblenz": "Urbar b. Koblenz",
    "urbar-rhein-hunsrueck": "Urbar Rhein-Hunsrück",
    "urleben": "Urleben",
    "urmersbach": "Urmersbach",
    "urmitz": "Urmitz",
    "urnshausen": "Urnshausen",
    "ursberg": "Ursberg",
    "urschmitt": "Urschmitt",
    "ursensollen": "Ursensollen",
    "urspringen": "Urspringen",
    "uerzig": "Ürzig",
    "usch": "Usch",
    "usedom-gemeinde": "Usedom (Gemeinde)",
    "userin": "Userin",
    "usingen": "Usingen",
    "uslar": "Uslar",
    "ustersbach": "Ustersbach",
    "utarp": "Utarp",
    "utecht": "Utecht",
    "utendorf": "Utendorf",
    "utersum": "Utersum",
    "utscheid": "Utscheid",
    "uttenreuth": "Uttenreuth",
    "uttenweiler": "Uttenweiler",
    "uettfeld": "Üttfeld",
    "utting-am-ammersee": "Utting am Ammersee",
    "utzedel": "Utzedel",
    "utzenfeld": "Utzenfeld",
    "utzenhain": "Utzenhain",
    "utzerath": "Utzerath",
    "uexheim": "Üxheim",
    "vaale": "Vaale",
    "vaalermoor": "Vaalermoor",
    "vacha": "Vacha",
    "vachdorf": "Vachdorf",
    "vachendorf": "Vachendorf",
    "vahlberg": "Vahlberg",
    "vahlbruch": "Vahlbruch",
    "vahlde": "Vahlde",
    "vaihingen-an-der-enz": "Vaihingen an der Enz",
    "vallendar": "Vallendar",
    "valley": "Valley",
    "valwig": "Valwig",
    "varchentin": "Varchentin",
    "varel": "Varel",
    "varrel": "Varrel",
    "vastorf": "Vastorf",
    "vaterstetten": "Vaterstetten",
    "vechelde": "Vechelde",
    "vechta": "Vechta",
    "veelboeken": "Veelböken",
    "veilsdorf": "Veilsdorf",
    "veitsbronn": "Veitsbronn",
    "veitshoechheim": "Veitshöchheim",
    "veitsrodt": "Veitsrodt",
    "velbert": "Velbert",
    "velburg": "Velburg",
    "velden-mittelfranken": "Velden/Mittelfranken",
    "velden-vils": "Velden/Vils",
    "veldenz": "Veldenz",
    "velen": "Velen",
    "velgast": "Velgast",
    "vellahn": "Vellahn",
    "vellberg": "Vellberg",
    "vellmar": "Vellmar",
    "velpke": "Velpke",
    "velten": "Velten",
    "veltheim-ohe": "Veltheim/Ohe",
    "vendersheim": "Vendersheim",
    "venningen": "Venningen",
    "ventschow": "Ventschow",
    "verchen": "Verchen",
    "verden-aller": "Verden/Aller",
    "veringenstadt": "Veringenstadt",
    "verl": "Verl",
    "versmold": "Versmold",
    "vestenbergsgreuth": "Vestenbergsgreuth",
    "vetschau-spreewald": "Vetschau/Spreewald",
    "vettelschoss": "Vettelschoß",
    "vettweiss": "Vettweiß",
    "viechtach": "Viechtach",
    "vielank": "Vielank",
    "vielbach": "Vielbach",
    "vielitzsee": "Vielitzsee",
    "vierden": "Vierden",
    "viereck": "Viereck",
    "viereth-trunstadt": "Viereth-Trunstadt",
    "vierherrenborn": "Vierherrenborn",
    "vierhoefen": "Vierhöfen",
    "vierkirchen-bayern": "Vierkirchen/Bayern",
    "vierkirchen-sachsen": "Vierkirchen/Sachsen",
    "vierlinden": "Vierlinden",
    "viernau": "Viernau",
    "viernheim": "Viernheim",
    "viersen": "Viersen",
    "vilgertshofen": "Vilgertshofen",
    "villenbach": "Villenbach",
    "villingendorf": "Villingendorf",
    "villingen-schwenningen": "Villingen-Schwenningen",
    "villmar": "Villmar",
    "vilsbiburg": "Vilsbiburg",
    "vilseck": "Vilseck",
    "vilsheim": "Vilsheim",
    "vilshofen-an-der-donau": "Vilshofen an der Donau",
    "vinningen": "Vinningen",
    "vioel": "Viöl",
    "vippachedelhausen": "Vippachedelhausen",
    "vipperow": "Vipperow",
    "virneburg": "Virneburg",
    "visbek": "Visbek",
    "visselhoevede": "Visselhövede",
    "vlotho": "Vlotho",
    "voerde-niederrhein": "Voerde/Niederrhein",
    "vogelsang-oder": "Vogelsang a.d. Oder",
    "vogelsang-warsin": "Vogelsang-Warsin",
    "vogelsberg": "Vogelsberg",
    "voegelsen": "Vögelsen",
    "vogt": "Vogt",
    "vogtareuth": "Vogtareuth",
    "vogtei": "Vogtei",
    "vogtlaendisches-oberland": "Vogtländisches Oberland",
    "vogtsburg-im-kaiserstuhl": "Vogtsburg im Kaiserstuhl",
    "vohburg-donau": "Vohburg a.d. Donau",
    "vohenstrauss": "Vohenstrauß",
    "voehl": "Vöhl",
    "voehrenbach": "Vöhrenbach",
    "voehringen-iller": "Vöhringen/Iller",
    "voehringen-wuerttemberg": "Vöhringen/Württemberg",
    "voigtsdorf": "Voigtsdorf",
    "voigtstedt": "Voigtstedt",
    "volkach": "Volkach",
    "volkenschwand": "Volkenschwand",
    "volkerode": "Volkerode",
    "voelkersweiler": "Völkersweiler",
    "volkertshausen": "Volkertshausen",
    "volkerzen": "Volkerzen",
    "volkesfeld": "Volkesfeld",
    "voelklingen": "Völklingen",
    "volkmannsdorf-saale-orla-kreis": "Volkmannsdorf/Saale-Orla-Kreis",
    "volkmarsen": "Volkmarsen",
    "vollersode": "Vollersode",
    "vollersroda": "Vollersroda",
    "vollerwiek": "Vollerwiek",
    "vollmersbach": "Vollmersbach",
    "vollmershain": "Vollmershain",
    "vollmersweiler": "Vollmersweiler",
    "vollrathsruhe": "Vollrathsruhe",
    "vollstedt": "Vollstedt",
    "voelpke": "Völpke",
    "voelschow": "Völschow",
    "volsemenhusen": "Volsemenhusen",
    "voltlage": "Voltlage",
    "volxheim": "Volxheim",
    "vorbach": "Vorbach",
    "vorbeck": "Vorbeck",
    "vorderweidenthal": "Vorderweidenthal",
    "vordorf": "Vordorf",
    "vorra": "Vorra",
    "voerstetten": "Vörstetten",
    "vorwerk": "Vorwerk",
    "vreden": "Vreden",
    "vrees": "Vrees",
    "waabs": "Waabs",
    "waake": "Waake",
    "waakirchen": "Waakirchen",
    "waal": "Waal",
    "wabern": "Wabern",
    "wachau": "Wachau",
    "wachenheim-rheinhessen": "Wachenheim/Rheinhessen",
    "wachenheim-weinstrasse": "Wachenheim/Weinstraße",
    "wachenroth": "Wachenroth",
    "wachstedt": "Wachstedt",
    "wachtberg": "Wachtberg",
    "wachtendonk": "Wachtendonk",
    "waechtersbach": "Wächtersbach",
    "wacken": "Wacken",
    "wackernheim": "Wackernheim",
    "wackerow": "Wackerow",
    "wackersberg": "Wackersberg",
    "wackersdorf": "Wackersdorf",
    "waddeweitz": "Waddeweitz",
    "wadern": "Wadern",
    "wadersloh": "Wadersloh",
    "wadgassen": "Wadgassen",
    "waffenbrunn": "Waffenbrunn",
    "wagenfeld": "Wagenfeld",
    "wagenhausen": "Wagenhausen",
    "wagenhoff": "Wagenhoff",
    "wagersrott": "Wagersrott",
    "waghaeusel": "Waghäusel",
    "waging-see": "Waging a. See",
    "wahlbach": "Wahlbach",
    "wahlenau": "Wahlenau",
    "wahlhausen": "Wahlhausen",
    "wahlheim": "Wahlheim",
    "wahlrod": "Wahlrod",
    "wahlsburg": "Wahlsburg",
    "wahlstedt": "Wahlstedt",
    "wahlstorf-preetz": "Wahlstorf b. Preetz",
    "wahns": "Wahns",
    "wahnwegen": "Wahnwegen",
    "uebigau-wahrenbrueck": "Wahrenbrück",
    "wahrenholz": "Wahrenholz",
    "waiblingen": "Waiblingen",
    "waibstadt": "Waibstadt",
    "waidhaus": "Waidhaus",
    "waidhofen": "Waidhofen",
    "waigandshain": "Waigandshain",
    "waigolshausen": "Waigolshausen",
    "wain": "Wain",
    "waischenfeld": "Waischenfeld",
    "wakendorf-i": "Wakendorf I",
    "wakendorf-ii": "Wakendorf II",
    "walchum": "Walchum",
    "wald-hohenzollern": "Wald/Hohenzollern",
    "wald-oberpfalz": "Wald/Oberpfalz",
    "wald-ostallgaeu": "Wald/Ostallgäu",
    "waldachtal": "Waldachtal",
    "waldalgesheim": "Waldalgesheim",
    "waldaschaff": "Waldaschaff",
    "waldboeckelheim": "Waldböckelheim",
    "waldbreitbach": "Waldbreitbach",
    "waldbroel": "Waldbröl",
    "waldbronn": "Waldbronn",
    "waldbrunn-wuerzburg": "Waldbrunn Kr. Würzburg",
    "waldbrunn-odenwald": "Waldbrunn/Odenwald",
    "waldbrunn-westerwald": "Waldbrunn/Westerwald",
    "waldburg": "Waldburg",
    "waldbuettelbrunn": "Waldbüttelbrunn",
    "walddorfhaeslach": "Walddorfhäslach",
    "waldeck-stadtroda": "Waldeck b. Stadtroda",
    "waldeck-hessen": "Waldeck/Hessen",
    "waldems": "Waldems",
    "waldenbuch": "Waldenbuch",
    "waldenburg-sachsen": "Waldenburg/Sachsen",
    "waldenburg-wuerttemberg": "Waldenburg/Württemberg",
    "walderbach": "Walderbach",
    "waldershof": "Waldershof",
    "waldesch": "Waldesch",
    "waldfeucht": "Waldfeucht",
    "waldfischbach-burgalben": "Waldfischbach-Burgalben",
    "waldgrehweiler": "Waldgrehweiler",
    "waldhambach": "Waldhambach",
    "waldheim": "Waldheim",
    "waldhof-falkenstein": "Waldhof-Falkenstein",
    "waldhufen": "Waldhufen",
    "waldkappel": "Waldkappel",
    "waldkirch": "Waldkirch",
    "waldkirchen-niederbayern": "Waldkirchen/Niederbayern",
    "waldkraiburg": "Waldkraiburg",
    "waldlaubersheim": "Waldlaubersheim",
    "waldleiningen": "Waldleiningen",
    "wald-michelbach": "Wald-Michelbach",
    "waldmohr": "Waldmohr",
    "waldmuehlen": "Waldmühlen",
    "waldmuenchen": "Waldmünchen",
    "waldorf": "Waldorf",
    "waldrach": "Waldrach",
    "waldrohrbach": "Waldrohrbach",
    "waldsassen": "Waldsassen",
    "waldsee": "Waldsee",
    "waldshut-tiengen": "Waldshut-Tiengen",
    "waldsieversdorf": "Waldsieversdorf",
    "waldsolms": "Waldsolms",
    "waldstetten-markt": "Waldstetten Markt",
    "waldstetten-wuerttemberg": "Waldstetten/Württemberg",
    "waldthurn": "Waldthurn",
    "waldweiler": "Waldweiler",
    "walhausen": "Walhausen",
    "walheim": "Walheim",
    "walkendorf": "Walkendorf",
    "walkenried": "Walkenried",
    "walkertshofen": "Walkertshofen",
    "walksfelde": "Walksfelde",
    "wallbach": "Wallbach",
    "walldorf-meiningen": "Walldorf b. Meiningen",
    "walldorf-baden": "Walldorf/Baden",
    "wallduern": "Walldürn",
    "wallen": "Wallen",
    "wallenborn": "Wallenborn",
    "wallendorf-eifel": "Wallendorf/Eifel",
    "wallenfels": "Wallenfels",
    "wallenhorst": "Wallenhorst",
    "wallerfangen": "Wallerfangen",
    "wallerfing": "Wallerfing",
    "wallersdorf": "Wallersdorf",
    "wallersheim": "Wallersheim",
    "wallerstein": "Wallerstein",
    "wallertheim": "Wallertheim",
    "wallgau": "Wallgau",
    "wallhalben": "Wallhalben",
    "wallhausen-sangerhausen": "Wallhausen b. Sangerhausen",
    "wallhausen-bad-kreuznach": "Wallhausen Kr. Bad Kreuznach",
    "wallhausen-wuerttemberg": "Wallhausen/Württemberg",
    "wallmenroth": "Wallmenroth",
    "wallmerod": "Wallmerod",
    "wallmoden": "Wallmoden",
    "wallsbuell": "Wallsbüll",
    "wallscheid": "Wallscheid",
    "wallstawe": "Wallstawe",
    "walluf": "Walluf",
    "walow": "Walow",
    "walpernhain": "Walpernhain",
    "walpertskirchen": "Walpertskirchen",
    "walschleben": "Walschleben",
    "walsdorf-eifel": "Walsdorf/Eifel",
    "walsdorf-oberfranken": "Walsdorf/Oberfranken",
    "walshausen": "Walshausen",
    "walsheim": "Walsheim",
    "walsleben-neuruppin": "Walsleben b. Neuruppin",
    "walsrode": "Walsrode",
    "waltenhausen": "Waltenhausen",
    "waltenhofen": "Waltenhofen",
    "walterschen": "Walterschen",
    "waltersdorf-stadtroda": "Waltersdorf b. Stadtroda",
    "waltershausen": "Waltershausen",
    "walting": "Walting",
    "waltrop": "Waltrop",
    "walzbachtal": "Walzbachtal",
    "wanderup": "Wanderup",
    "wandlitz": "Wandlitz",
    "wanfried": "Wanfried",
    "wang": "Wang",
    "wangelau": "Wangelau",
    "wangelnstedt": "Wangelnstedt",
    "wangels": "Wangels",
    "wangen-im-allgaeu": "Wangen im Allgäu",
    "wangen-goeppingen": "Wangen Kr. Göppingen",
    "wangenheim": "Wangenheim",
    "wangerland": "Wangerland",
    "nordseebad-wangerooge": "Wangerooge",
    "wankendorf": "Wankendorf",
    "wanna": "Wanna",
    "wannweil": "Wannweil",
    "wanzleben": "Wanzleben-Börde",
    "wapelfeld": "Wapelfeld",
    "warberg": "Warberg",
    "warburg": "Warburg",
    "wardenburg": "Wardenburg",
    "warder": "Warder",
    "wardow": "Wardow",
    "waren-mueritz": "Waren/Müritz",
    "warendorf": "Warendorf",
    "warin": "Warin",
    "warlitz": "Warlitz",
    "warlow": "Warlow",
    "warmensteinach": "Warmensteinach",
    "warmsen": "Warmsen",
    "warmsroth": "Warmsroth",
    "warnau-nettelsee": "Warnau b. Nettelsee",
    "warngau": "Warngau",
    "warnkenhagen": "Warnkenhagen",
    "warnow-buetzow": "Warnow b. Bützow",
    "warnow-grevesmuehlen": "Warnow b. Grevesmühlen",
    "warpe": "Warpe",
    "warrenzin": "Warrenzin",
    "warringholz": "Warringholz",
    "warsow-schwerin": "Warsow b. Schwerin",
    "warstein": "Warstein",
    "wartenberg-hessen": "Wartenberg/Hessen",
    "wartenberg-oberbayern": "Wartenberg/Oberbayern",
    "wartenberg-rohrbach": "Wartenberg-Rohrbach",
    "warthausen": "Warthausen",
    "wartmannsroth": "Wartmannsroth",
    "warwerort": "Warwerort",
    "warza": "Warza",
    "wasbek": "Wasbek",
    "wasbuettel": "Wasbüttel",
    "waeschenbeuren": "Wäschenbeuren",
    "wasenbach": "Wasenbach",
    "wassenach": "Wassenach",
    "wassenberg": "Wassenberg",
    "wasserburg-inn": "Wasserburg a. Inn",
    "wasserburg-bodensee": "Wasserburg/Bodensee",
    "wasserliesch": "Wasserliesch",
    "wasserlosen": "Wasserlosen",
    "wasserthaleben": "Wasserthaleben",
    "wassertruedingen": "Wassertrüdingen",
    "wasungen": "Wasungen",
    "wathlingen": "Wathlingen",
    "wattenbek": "Wattenbek",
    "wattendorf": "Wattendorf",
    "wattenheim": "Wattenheim",
    "watzerath": "Watzerath",
    "wawern-eifel": "Wawern/Eifel",
    "wawern-saar": "Wawern/Saar",
    "waxweiler": "Waxweiler",
    "weberstedt": "Weberstedt",
    "wechingen": "Wechingen",
    "wechselburg": "Wechselburg",
    "weddelbrook": "Weddelbrook",
    "weddingstedt": "Weddingstedt",
    "wedel": "Wedel",
    "wedemark": "Wedemark",
    "wedendorfersee": "Wedendorfersee",
    "weede": "Weede",
    "weener": "Weener",
    "weenzen": "Weenzen",
    "wees": "Wees",
    "weesby": "Weesby",
    "weeze": "Weeze",
    "wefensleben": "Wefensleben",
    "wegberg": "Wegberg",
    "wegeleben": "Wegeleben",
    "wegscheid": "Wegscheid",
    "wehingen": "Wehingen",
    "stadt-wehlen": "Wehlen",
    "wehnde": "Wehnde",
    "wehr-baden": "Wehr/Baden",
    "wehr-eifel": "Wehr/Eifel",
    "wehrbleck": "Wehrbleck",
    "wehretal": "Wehretal",
    "wehrheim": "Wehrheim",
    "wehringen": "Wehringen",
    "weibern": "Weibern",
    "weibersbrunn": "Weibersbrunn",
    "weichering": "Weichering",
    "weichs": "Weichs",
    "weida": "Weida",
    "weiden-hottenbach": "Weiden b. Hottenbach",
    "weiden-oberpfalz": "Weiden/Oberpfalz",
    "weidenbach-gerolstein": "Weidenbach b. Gerolstein",
    "weidenbach-mittelfranken": "Weidenbach/Mittelfranken",
    "weidenbach-taunus": "Weidenbach/Taunus",
    "weidenberg": "Weidenberg",
    "weidenhahn": "Weidenhahn",
    "weidenstetten": "Weidenstetten",
    "weidenthal": "Weidenthal",
    "weidhausen-coburg": "Weidhausen b. Coburg",
    "weiding-oberviechtal": "Weiding b. Oberviechtal",
    "weiding-cham": "Weiding/Kr. Cham",
    "weidingen": "Weidingen",
    "weigendorf": "Weigendorf",
    "weigenheim": "Weigenheim",
    "weihenzell": "Weihenzell",
    "weiherhammer": "Weiherhammer",
    "weihmichl": "Weihmichl",
    "weikersheim": "Weikersheim",
    "weil-am-rhein": "Weil am Rhein",
    "weil-der-stadt": "Weil der Stadt",
    "weil-im-schoenbuch": "Weil im Schönbuch",
    "weil-oberbayern": "Weil/Oberbayern",
    "weilar": "Weilar",
    "weilbach": "Weilbach",
    "weilburg": "Weilburg",
    "weilen-unter-den-rinnen": "Weilen unter den Rinnen",
    "weiler-bingen": "Weiler b. Bingen",
    "weiler-gevenich": "Weiler b. Gevenich",
    "weiler-mayen": "Weiler b. Mayen",
    "weiler-monzingen": "Weiler b. Monzingen",
    "weilerbach": "Weilerbach",
    "weilersbach": "Weilersbach",
    "weiler-simmerberg": "Weiler-Simmerberg",
    "weilerswist": "Weilerswist",
    "weilheim-an-der-teck": "Weilheim an der Teck",
    "weilheim-oberbayern": "Weilheim i. Oberbayern",
    "weilheim-waldshut": "Weilheim Kr. Waldshut",
    "weilmuenster": "Weilmünster",
    "weilrod": "Weilrod",
    "weiltingen": "Weiltingen",
    "weimar-thueringen": "Weimar",
    "weimar-lahn": "Weimar (Lahn)",
    "weinaehr": "Weinähr",
    "weinbach": "Weinbach",
    "weinbergen": "Weinbergen",
    "weinboehla": "Weinböhla",
    "weingarten-baden": "Weingarten/Baden",
    "weingarten-pfalz": "Weingarten/Pfalz",
    "weingarten-wuerttemberg": "Weingarten/Württemberg",
    "weinheim": "Weinheim",
    "weinolsheim": "Weinolsheim",
    "weinsberg": "Weinsberg",
    "weinsheim-bad-kreuznach": "Weinsheim Kr. Bad Kreuznach",
    "weinsheim-eifel": "Weinsheim/Eifel",
    "weinstadt": "Weinstadt",
    "weira": "Weira",
    "weischlitz": "Weischlitz",
    "weisel": "Weisel",
    "weisen": "Weisen",
    "weisenbach": "Weisenbach",
    "weisendorf": "Weisendorf",
    "weisenheim-am-berg": "Weisenheim am Berg",
    "weisenheim-am-sand": "Weisenheim am Sand",
    "weiskirchen": "Weiskirchen",
    "weismain": "Weismain",
    "weissach-im-tal": "Weissach im Tal",
    "weissach-wuerttemberg": "Weissach Württemberg",
    "weissbach-stadtroda": "Weißbach b. Stadtroda",
    "weissbach-wuerttemberg": "Weißbach/Württemberg",
    "weissdorf": "Weißdorf",
    "weissenberg": "Weißenberg",
    "weissenborn-eschwege": "Weißenborn b. Eschwege",
    "weissenborn-hermsdorf": "Weißenborn b. Hermsdorf",
    "weissenborn-erzgebirge": "Weißenborn/Erzgebirge",
    "weissenbrunn": "Weißenbrunn",
    "weissenburg-bayern": "Weißenburg i. Bayern",
    "weissendorf": "Weißendorf",
    "weissenfels": "Weißenfels",
    "weissenhorn": "Weißenhorn",
    "weissenohe": "Weißenohe",
    "weissensberg": "Weißensberg",
    "weissensee": "Weißensee",
    "weissenstadt": "Weißenstadt",
    "weissenthurm": "Weißenthurm",
    "weisskeissel": "Weißkeißel",
    "weisswasser-oberlausitz": "Weißwasser/O.L.",
    "weisweil": "Weisweil",
    "weitefeld": "Weitefeld",
    "weitendorf": "Weitendorf",
    "weitenhagen-franzburg": "Weitenhagen b. Franzburg",
    "weitenhagen-greifswald": "Weitenhagen b. Greifswald",
    "weitersbach": "Weitersbach",
    "weitersborn": "Weitersborn",
    "weitersburg": "Weitersburg",
    "weiterstadt": "Weiterstadt",
    "weitersweiler": "Weitersweiler",
    "weitnau": "Weitnau",
    "weitramsdorf": "Weitramsdorf",
    "welcherath": "Welcherath",
    "welchweiler": "Welchweiler",
    "welden": "Welden",
    "welgesheim": "Welgesheim",
    "welkenbach": "Welkenbach",
    "welle": "Welle",
    "wellen-mosel": "Wellen/Mosel",
    "wellendingen": "Wellendingen",
    "wellheim": "Wellheim",
    "welling": "Welling",
    "welmbuettel": "Welmbüttel",
    "welschbillig": "Welschbillig",
    "welschenbach": "Welschenbach",
    "welschneudorf": "Welschneudorf",
    "welt": "Welt",
    "welterod": "Welterod",
    "weltersburg": "Weltersburg",
    "welver": "Welver",
    "welzheim": "Welzheim",
    "welzow": "Welzow",
    "wembach": "Wembach",
    "wemding": "Wemding",
    "wendeburg": "Wendeburg",
    "wendelsheim": "Wendelsheim",
    "wendelstein": "Wendelstein",
    "wenden": "Wenden",
    "wendisch-baggendorf": "Wendisch Baggendorf",
    "wendisch-evern": "Wendisch Evern",
    "wendisch-rietz": "Wendisch Rietz",
    "wendlingen-am-neckar": "Wendlingen am Neckar",
    "wendorf-stralsund": "Wendorf b. Stralsund",
    "wendtorf": "Wendtorf",
    "weng": "Weng",
    "wennbuettel": "Wennbüttel",
    "wennigsen-deister": "Wennigsen (Deister)",
    "wenningstedt-sylt": "Wenningstedt/Sylt",
    "wensin": "Wensin",
    "wentorf-hamburg": "Wentorf b. Hamburg",
    "wentorf-amt-sandesneben": "Wentorf/Amt Sandesneben",
    "wenzenbach": "Wenzenbach",
    "wenzendorf": "Wenzendorf",
    "wenzlow": "Wenzlow",
    "werbach": "Werbach",
    "werben-elbe": "Werben (Elbe)",
    "werben-niederlausitz": "Werben (Niederlausitz)",
    "werda": "Werda",
    "werdau": "Werdau",
    "werder-altentreptow": "Werder b. Altentreptow",
    "werder-luebz": "Werder b. Lübz",
    "werder-havel": "Werder/Havel",
    "werdohl": "Werdohl",
    "werdum": "Werdum",
    "werkhausen": "Werkhausen",
    "werl": "Werl",
    "werlte": "Werlte",
    "wermelskirchen": "Wermelskirchen",
    "wermsdorf": "Wermsdorf",
    "wernau-neckar": "Wernau/Neckar",
    "wernberg-koeblitz": "Wernberg-Köblitz",
    "wernburg": "Wernburg",
    "werne": "Werne",
    "werneck": "Werneck",
    "wernersberg": "Wernersberg",
    "werneuchen": "Werneuchen",
    "wernigerode": "Wernigerode",
    "werningshausen": "Werningshausen",
    "weroth": "Weroth",
    "werpeloh": "Werpeloh",
    "werra-suhl-tal": "Werra-Suhl-Tal",
    "wershofen": "Wershofen",
    "wertach": "Wertach",
    "wertheim": "Wertheim",
    "werther-thueringen": "Werther/Thüringen",
    "werther-westfalen": "Werther/Westfalen",
    "wertingen": "Wertingen",
    "wesel": "Wesel",
    "weselberg": "Weselberg",
    "wesenberg-holstein": "Wesenberg b. Holstein",
    "wesenberg-mecklenburg": "Wesenberg/Mecklenburg",
    "wesendorf-niedersachsen": "Wesendorf/Niedersachsen",
    "wesselburen": "Wesselburen",
    "wesselburener-deichhausen": "Wesselburener Deichhausen",
    "wesselburenerkoog": "Wesselburenerkoog",
    "wesseling": "Wesseling",
    "wesseln": "Wesseln",
    "wessling": "Weßling",
    "wessobrunn": "Wessobrunn",
    "weste": "Weste",
    "westendorf-augsburg": "Westendorf b. Donauw.",
    "westendorf-kaufbeuren": "Westendorf b. Kaufbeuren",
    "westensee": "Westensee",
    "westerau": "Westerau",
    "westerborstel": "Westerborstel",
    "westerburg": "Westerburg",
    "westerdeichstrich": "Westerdeichstrich",
    "westergellersen": "Westergellersen",
    "westerheim-memmingen": "Westerheim b.Memmingen",
    "westerheim-wuerttemberg": "Westerheim/Württemberg",
    "westerhever": "Westerhever",
    "westerholt": "Westerholt",
    "westerholz": "Westerholz",
    "westerhorn": "Westerhorn",
    "westerkappeln": "Westerkappeln",
    "westermoor": "Westermoor",
    "westerngrund": "Westerngrund",
    "westernohe": "Westernohe",
    "wester-ohrstedt": "Wester-Ohrstedt",
    "westerrade": "Westerrade",
    "westerroenfeld": "Westerrönfeld",
    "westerstede": "Westerstede",
    "westerstetten": "Westerstetten",
    "westertimke": "Westertimke",
    "westerwalsede": "Westerwalsede",
    "westfeld": "Westfeld",
    "westgreussen": "Westgreußen",
    "westhausen-gotha": "Westhausen b. Gotha",
    "westhausen-hildburghausen": "Westhausen b. Hildburghausen",
    "westhausen-wuerttemberg": "Westhausen/Württemberg",
    "westheide": "Westheide",
    "westheim-mittelfranken": "Westheim/Mittelfranken",
    "westheim-pfalz": "Westheim/Pfalz",
    "westhofen": "Westhofen",
    "westoverledingen": "Westoverledingen",
    "westre": "Westre",
    "wethau": "Wethau",
    "wetschen": "Wetschen",
    "wettenberg": "Wettenberg",
    "wetter-hessen": "Wetter/Hessen",
    "wetter-ruhr": "Wetter/Ruhr",
    "wetterzeube": "Wetterzeube",
    "wettin-loebejuen": "Wettin-Löbejün",
    "wettlingen": "Wettlingen",
    "wettringen-steinfurt": "Wettringen Kr. Steinfurt",
    "wettringen-mittelfranken": "Wettringen/Mittelfranken",
    "wettrup": "Wettrup",
    "wettstetten": "Wettstetten",
    "wetzlar": "Wetzlar",
    "wewelsfleth": "Wewelsfleth",
    "weyarn": "Weyarn",
    "weyer": "Weyer",
    "weyerbusch": "Weyerbusch",
    "weyhausen": "Weyhausen",
    "weyhe": "Weyhe",
    "weyher-in-der-pfalz": "Weyher in der Pfalz",
    "wichmar": "Wichmar",
    "wickede-ruhr": "Wickede (Ruhr)",
    "wickenrodt": "Wickenrodt",
    "widdern": "Widdern",
    "wiebelsheim": "Wiebelsheim",
    "wieck-darss": "Wieck a. Darß",
    "wied": "Wied",
    "wieda": "Wieda",
    "wiedemar": "Wiedemar",
    "wieden": "Wieden",
    "wiedenborstel": "Wiedenborstel",
    "wiedensahl": "Wiedensahl",
    "wiedergeltingen": "Wiedergeltingen",
    "wiefelstede": "Wiefelstede",
    "wiegendorf": "Wiegendorf",
    "wiehe": "Wiehe",
    "wiehl": "Wiehl",
    "wiek": "Wiek",
    "wielen": "Wielen",
    "wielenbach": "Wielenbach",
    "wiemersdorf": "Wiemersdorf",
    "wiemerstedt": "Wiemerstedt",
    "wiendorf-buetzow": "Wiendorf b. Bützow",
    "wienhausen": "Wienhausen",
    "wiernsheim": "Wiernsheim",
    "wierschem": "Wierschem",
    "wiersdorf": "Wiersdorf",
    "wiershop": "Wiershop",
    "wiesau": "Wiesau",
    "wiesbach": "Wiesbach",
    "wiesbaden": "Wiesbaden",
    "wiesbaum": "Wiesbaum",
    "wiesemscheid": "Wiesemscheid",
    "wiesen": "Wiesen",
    "wiesenau": "Wiesenau",
    "wiesenaue": "Wiesenaue",
    "wiesenbach-baden": "Wiesenbach/Baden",
    "wiesenbach-schwaben": "Wiesenbach/Schwaben",
    "wiesenbronn": "Wiesenbronn",
    "wiesenburg-mark": "Wiesenburg/Mark",
    "wiesenfeld": "Wiesenfeld",
    "wiesenfelden": "Wiesenfelden",
    "wiesengrund": "Wiesengrund",
    "wiesensteig": "Wiesensteig",
    "wiesent": "Wiesent",
    "wiesenthal": "Wiesenthal",
    "wiesenthau": "Wiesenthau",
    "wiesentheid": "Wiesentheid",
    "wiesenttal": "Wiesenttal",
    "wieseth": "Wieseth",
    "wiesloch": "Wiesloch",
    "wiesmoor": "Wiesmoor",
    "wiesthal": "Wiesthal",
    "wiesweiler": "Wiesweiler",
    "wietmarschen": "Wietmarschen",
    "wietze": "Wietze",
    "wietzen": "Wietzen",
    "wietzendorf": "Wietzendorf",
    "wiggensbach": "Wiggensbach",
    "wilburgstetten": "Wilburgstetten",
    "wildau-kreis-dahme-spreewald": "Wildau/Kreis Dahme-Spreewald",
    "wildberg-altentreptow": "Wildberg b. Altentreptow",
    "wildberg-wuerttemberg": "Wildberg/Württemberg",
    "wildeck": "Wildeck",
    "wildenberg": "Wildenberg",
    "wildenboerten": "Wildenbörten",
    "wildenfels": "Wildenfels",
    "wildenspring": "Wildenspring",
    "wildeshausen": "Wildeshausen",
    "wildflecken": "Wildflecken",
    "wildpoldsried": "Wildpoldsried",
    "wildsteig": "Wildsteig",
    "wilgartswiesen": "Wilgartswiesen",
    "wilhelmsburg": "Wilhelmsburg",
    "wilhelmsdorf-poessneck": "Wilhelmsdorf b. Pößneck",
    "wilhelmsdorf-mittelfranken": "Wilhelmsdorf/Mittelfranken",
    "wilhelmsdorf-wuerttemberg": "Wilhelmsdorf/Württemberg",
    "wilhelmsfeld": "Wilhelmsfeld",
    "wilhelmshaven": "Wilhelmshaven",
    "wilhelmsthal": "Wilhelmsthal",
    "wilhermsdorf": "Wilhermsdorf",
    "wilkau-hasslau": "Wilkau-Haßlau",
    "willanzheim": "Willanzheim",
    "willebadessen": "Willebadessen",
    "willenscharen": "Willenscharen",
    "willich": "Willich",
    "willingen-upland": "Willingen/Upland",
    "willingen-westerwald": "Willingen/Westerwald",
    "willingshausen": "Willingshausen",
    "willmars": "Willmars",
    "willmenrod": "Willmenrod",
    "willmering": "Willmering",
    "willroth": "Willroth",
    "willstaett": "Willstätt",
    "willwerscheid": "Willwerscheid",
    "wilnsdorf": "Wilnsdorf",
    "wilsdruff": "Wilsdruff",
    "wilsecker": "Wilsecker",
    "wilstedt": "Wilstedt",
    "wilster": "Wilster",
    "wilsum": "Wilsum",
    "wilthen": "Wilthen",
    "wiltingen": "Wiltingen",
    "wilzenberg-hussweiler": "Wilzenberg-Hußweiler",
    "wimbach": "Wimbach",
    "wimmelburg": "Wimmelburg",
    "wimsheim": "Wimsheim",
    "wincheringen": "Wincheringen",
    "windach": "Windach",
    "windberg": "Windberg",
    "windbergen": "Windbergen",
    "windeby": "Windeby",
    "windeck": "Windeck",
    "windelsbach": "Windelsbach",
    "winden-im-elztal": "Winden im Elztal",
    "winden-pfalz": "Winden/Pfalz",
    "winden-rhein-lahn-kreis": "Winden/Rhein-Lahn-Kreis",
    "windesheim": "Windesheim",
    "windhagen": "Windhagen",
    "windischeschenbach": "Windischeschenbach",
    "windischleuba": "Windischleuba",
    "windorf": "Windorf",
    "windsbach": "Windsbach",
    "wingerode": "Wingerode",
    "wingst": "Wingst",
    "winhoering": "Winhöring",
    "winkel-eifel": "Winkel/Eifel",
    "winkelbach": "Winkelbach",
    "winkelhaid": "Winkelhaid",
    "winkelsett": "Winkelsett",
    "winklarn": "Winklarn",
    "winnemark": "Winnemark",
    "winnen": "Winnen",
    "winnenden": "Winnenden",
    "winnerath": "Winnerath",
    "winnert": "Winnert",
    "winnigstedt": "Winnigstedt",
    "winningen-mosel": "Winningen/Mosel",
    "winnweiler": "Winnweiler",
    "winringen": "Winringen",
    "winseldorf": "Winseldorf",
    "winsen-neumuenster": "Winsen b. Neumünster",
    "winsen-aller": "Winsen/Aller",
    "winsen-luhe": "Winsen/Luhe",
    "winterbach-schorndorf": "Winterbach b. Schorndorf",
    "winterbach-bad-kreuznach": "Winterbach Kr. Bad Kreuznach",
    "winterbach-gruenzburg": "Winterbach/Kr. Grünzburg",
    "winterbach-pfalz": "Winterbach/Pfalz",
    "winterberg": "Winterberg",
    "winterborn": "Winterborn",
    "winterburg": "Winterburg",
    "winterhausen": "Winterhausen",
    "winterlingen": "Winterlingen",
    "winterrieden": "Winterrieden",
    "winterscheid": "Winterscheid",
    "wintersheim": "Wintersheim",
    "winterspelt": "Winterspelt",
    "winterwerb": "Winterwerb",
    "wintrich": "Wintrich",
    "winzenburg": "Winzenburg",
    "winzer": "Winzer",
    "wipfeld": "Wipfeld",
    "wipfratal": "Wipfratal",
    "wipperdorf": "Wipperdorf",
    "wipperfuerth": "Wipperfürth",
    "wippingen": "Wippingen",
    "wirdum": "Wirdum",
    "wirft": "Wirft",
    "wirfus": "Wirfus",
    "wirges": "Wirges",
    "wirsberg": "Wirsberg",
    "wirscheid": "Wirscheid",
    "wirschweiler": "Wirschweiler",
    "wisch-kiel": "Wisch b. Kiel",
    "wisch-nordfriesland": "Wisch/Nordfriesland",
    "wischhafen": "Wischhafen",
    "wismar-mecklenburg": "Wismar",
    "wissen": "Wissen",
    "wissmannsdorf": "Wißmannsdorf",
    "wistedt": "Wistedt",
    "witsum": "Witsum",
    "wittbek": "Wittbek",
    "wittduen": "Wittdün auf Amrum",
    "wittelshofen": "Wittelshofen",
    "witten": "Witten",
    "wittenbeck": "Wittenbeck",
    "wittenberge": "Wittenberge",
    "wittenbergen": "Wittenbergen",
    "wittenborn-segeberg": "Wittenborn/Kr. Segeberg",
    "wittenburg": "Wittenburg",
    "wittendoerp": "Wittendörp",
    "wittenfoerden": "Wittenförden",
    "wittenhagen": "Wittenhagen",
    "witterda": "Witterda",
    "wittgendorf-saalfeld": "Wittgendorf b. Saalfeld",
    "wittgert": "Wittgert",
    "wittibreut": "Wittibreut",
    "wittichenau": "Wittichenau",
    "wittighausen": "Wittighausen",
    "wittingen": "Wittingen",
    "wittislingen": "Wittislingen",
    "wittlich": "Wittlich",
    "wittlingen": "Wittlingen",
    "wittmar": "Wittmar",
    "wittmoldt": "Wittmoldt",
    "wittmund": "Wittmund",
    "wittnau": "Wittnau",
    "wittorf": "Wittorf",
    "wittstock-dosse": "Wittstock/Dosse",
    "witzeeze": "Witzeeze",
    "witzenhausen": "Witzenhausen",
    "witzhave": "Witzhave",
    "witzin": "Witzin",
    "witzleben": "Witzleben",
    "witzmannsberg": "Witzmannsberg",
    "witzwort": "Witzwort",
    "woebbelin": "Wöbbelin",
    "wobbenbuell": "Wobbenbüll",
    "woggersin": "Woggersin",
    "wohlde": "Wohlde",
    "wohlsborn": "Wohlsborn",
    "wohltorf": "Wohltorf",
    "wohnste": "Wohnste",
    "wohratal": "Wohratal",
    "woehrden": "Wöhrden",
    "wokuhl-dabelow": "Wokuhl-Dabelow",
    "wolde": "Wolde",
    "woldegk": "Woldegk",
    "woldert": "Woldert",
    "wolfach": "Wolfach",
    "wolfegg": "Wolfegg",
    "wolfenbuettel": "Wolfenbüttel",
    "woelferlingen": "Wölferlingen",
    "wolferschwenda": "Wolferschwenda",
    "wolfersdorf": "Wolfersdorf",
    "woelfershausen": "Wölfershausen",
    "woelfersheim": "Wölfersheim",
    "wolferstadt": "Wolferstadt",
    "wolfertschwenden": "Wolfertschwenden",
    "wolfhagen": "Wolfhagen",
    "woelfis": "Wölfis",
    "wolframs-eschenbach": "Wolframs-Eschenbach",
    "wolfratshausen": "Wolfratshausen",
    "wolfsberg-thueringen": "Wolfsberg/Thüringen",
    "wolfsburg-niedersachsen": "Wolfsburg",
    "wolfsburg-unkeroda": "Wolfsburg-Unkeroda",
    "wolfschlugen": "Wolfschlugen",
    "wolfsegg": "Wolfsegg",
    "wolfsheim": "Wolfsheim",
    "wolfstein": "Wolfstein",
    "wolgast": "Wolgast",
    "wolken": "Wolken",
    "wolkenstein": "Wolkenstein",
    "wolkramshausen": "Wolkramshausen",
    "wollbach": "Wollbach",
    "wollbrandshausen": "Wollbrandshausen",
    "wollershausen": "Wollershausen",
    "wollin-brandenburg": "Wollin/Brandenburg",
    "wollmerath": "Wollmerath",
    "woellstadt": "Wöllstadt",
    "woellstein": "Wöllstein",
    "wolmersdorf": "Wolmersdorf",
    "woelmersen": "Wölmersen",
    "wolmirsleben": "Wolmirsleben",
    "wolmirstedt": "Wolmirstedt",
    "wolnzach": "Wolnzach",
    "wolpertshausen": "Wolpertshausen",
    "wolpertswende": "Wolpertswende",
    "woelpinghausen": "Wölpinghausen",
    "wolsdorf": "Wolsdorf",
    "wolsfeld": "Wolsfeld",
    "woltersdorf-erkner": "Woltersdorf b. Erkner",
    "woltersdorf-lauenburg": "Woltersdorf/Kr. Hzgt. Lauenburg",
    "woltersdorf-niedersachsen": "Woltersdorf/Niedersachsen",
    "woltershausen": "Woltershausen",
    "womrath": "Womrath",
    "wonfurt": "Wonfurt",
    "wonneberg": "Wonneberg",
    "wonsees": "Wonsees",
    "wonsheim": "Wonsheim",
    "woppenroth": "Woppenroth",
    "woringen": "Woringen",
    "worms": "Worms",
    "woernersberg": "Wörnersberg",
    "woernitz": "Wörnitz",
    "worpswede": "Worpswede",
    "woerrstadt": "Wörrstadt",
    "woert": "Wört",
    "worth": "Worth",
    "woerth-main": "Wörth a. Main",
    "woerth-donau": "Wörth a.d. Donau",
    "woerth-isar": "Wörth a.d. Isar",
    "woerth-am-rhein": "Wörth am Rhein",
    "woerth-erding": "Wörth Kr. Erding",
    "woerthsee": "Wörthsee",
    "wrangelsburg": "Wrangelsburg",
    "wredenhagen": "Wredenhagen",
    "wrestedt": "Wrestedt",
    "wriedel": "Wriedel",
    "wriezen": "Wriezen",
    "wrist": "Wrist",
    "wrixum": "Wrixum",
    "wrohm": "Wrohm",
    "wuelfershausen-saale": "Wülfershausen a.d. Saale",
    "wuelfrath": "Wülfrath",
    "wulfsen": "Wulfsen",
    "wulfsmoor": "Wulfsmoor",
    "wulften-am-harz": "Wulften am Harz",
    "wulkenzin": "Wulkenzin",
    "wuelknitz-sachsen": "Wülknitz/Sachsen",
    "wundersleben": "Wundersleben",
    "wuenschendorf-elster": "Wünschendorf/Elster",
    "wunsiedel": "Wunsiedel",
    "wunstorf": "Wunstorf",
    "wuppertal": "Wuppertal",
    "wurmannsquick": "Wurmannsquick",
    "wurmberg": "Wurmberg",
    "wurmlingen": "Wurmlingen",
    "wurmsham": "Wurmsham",
    "wuerrich": "Würrich",
    "wuerselen": "Würselen",
    "wurster-nordseekueste": "Wurster Nordseeküste",
    "wurzbach": "Wurzbach",
    "wuerzburg": "Würzburg",
    "wurzen": "Wurzen",
    "wuerzweiler": "Würzweiler",
    "wueschheim": "Wüschheim",
    "wuestenrot": "Wüstenrot",
    "wusterhausen-dosse": "Wusterhausen/Dosse",
    "wusterhusen": "Wusterhusen",
    "wustermark": "Wustermark",
    "wusterwitz": "Wusterwitz",
    "wust-fischbeck": "Wust-Fischbeck",
    "wuestheuterode": "Wüstheuterode",
    "wustrow-wesenberg": "Wustrow b. Wesenberg",
    "wustrow-ostseebad": "Wustrow/Ostseebad",
    "wustrow-wendland": "Wustrow/Wendland",
    "wutach": "Wutach",
    "wutha-farnroda": "Wutha-Farnroda",
    "wutoeschingen": "Wutöschingen",
    "wyhl": "Wyhl",
    "wyk-auf-foehr": "Wyk auf Föhr",
    "xanten": "Xanten",
    "zaberfeld": "Zaberfeld",
    "zachenberg": "Zachenberg",
    "zahna-elster": "Zahna-Elster",
    "zahrensdorf": "Zahrensdorf",
    "zaisenhausen": "Zaisenhausen",
    "zandt": "Zandt",
    "zangberg": "Zangberg",
    "zapel": "Zapel",
    "zapfendorf": "Zapfendorf",
    "zarnewanz": "Zarnewanz",
    "zarpen": "Zarpen",
    "zarrendorf": "Zarrendorf",
    "zarrentin-am-schaalsee": "Zarrentin am Schaalsee",
    "zechin": "Zechin",
    "zedlitz": "Zedlitz",
    "zehdenick": "Zehdenick",
    "zehna": "Zehna",
    "zehnhausen-rennerod": "Zehnhausen b. Rennerod",
    "zehnhausen-wallmerod": "Zehnhausen b. Wallmerod",
    "zehrental": "Zehrental",
    "zeil-main": "Zeil a. Main",
    "zeilarn": "Zeilarn",
    "zeiskam": "Zeiskam",
    "zeithain": "Zeithain",
    "zeitlarn": "Zeitlarn",
    "zeitlofs": "Zeitlofs",
    "zeitz": "Zeitz",
    "zell-main": "Zell a. Main",
    "zell-am-harmersbach": "Zell am Harmersbach",
    "zell-fichtelgebirge": "Zell im Fichtelgebirge",
    "zell-im-wiesental": "Zell im Wiesental",
    "zell-unter-aichelberg": "Zell unter Aichelberg",
    "zell-mosel": "Zell/Mosel",
    "zell-oberpfalz": "Zell/Oberpfalz",
    "zella-rhoen": "Zella/Rhön",
    "zella-mehlis": "Zella-Mehlis",
    "zellertal": "Zellertal",
    "zellingen": "Zellingen",
    "zeltingen-rachtig": "Zeltingen-Rachtig",
    "zemitz": "Zemitz",
    "zemmer": "Zemmer",
    "zempin": "Zempin",
    "zendscheid": "Zendscheid",
    "zenting": "Zenting",
    "zepelin": "Zepelin",
    "zepkow": "Zepkow",
    "zerbst": "Zerbst/Anhalt",
    "zerf": "Zerf",
    "zernien": "Zernien",
    "zernitz-lohm": "Zernitz-Lohm",
    "zerrenthin": "Zerrenthin",
    "zeschdorf": "Zeschdorf",
    "zetel": "Zetel",
    "zettemin": "Zettemin",
    "zettingen": "Zettingen",
    "zettlitz": "Zettlitz",
    "zeulenroda-triebes": "Zeulenroda-Triebes",
    "zeuthen": "Zeuthen",
    "zeven": "Zeven",
    "zichow": "Zichow",
    "zickhusen": "Zickhusen",
    "ziegelheim": "Ziegelheim",
    "ziegendorf": "Ziegendorf",
    "ziegenhain": "Ziegenhain",
    "ziegenrueck": "Ziegenrück",
    "zielitz": "Zielitz",
    "ziemetshausen": "Ziemetshausen",
    "zierenberg": "Zierenberg",
    "zierow": "Zierow",
    "ziertheim": "Ziertheim",
    "zierzow": "Zierzow",
    "ziesar": "Ziesar",
    "ziesendorf": "Ziesendorf",
    "ziethen": "Ziethen",
    "ziethen-anklam": "Ziethen b. Anklam",
    "ziethen-ratzeburg": "Ziethen b. Ratzeburg",
    "zilshausen": "Zilshausen",
    "ziltendorf": "Ziltendorf",
    "zimmern-ob-rottweil": "Zimmern ob Rottweil",
    "zimmern-unter-der-burg": "Zimmern unter der Burg",
    "zimmern-thueringen": "Zimmern/Thüringen",
    "zimmernsupra": "Zimmernsupra",
    "zimmerschied": "Zimmerschied",
    "zingst": "Zingst",
    "zinnowitz": "Zinnowitz",
    "zirchow": "Zirchow",
    "zirkow": "Zirkow",
    "zirndorf": "Zirndorf",
    "zirzow": "Zirzow",
    "zislow": "Zislow",
    "zittau": "Zittau",
    "zoeblitz": "Zöblitz",
    "zoelkow": "Zölkow",
    "zolling": "Zolling",
    "zoellnitz": "Zöllnitz",
    "zoerbig": "Zörbig",
    "zorge": "Zorge",
    "zorneding": "Zorneding",
    "zornheim": "Zornheim",
    "zoeschingen": "Zöschingen",
    "zossen": "Zossen",
    "zotzenheim": "Zotzenheim",
    "zschaitz-ottewig": "Zschaitz-Ottewig",
    "zschepplin": "Zschepplin",
    "zschopau": "Zschopau",
    "zschorlau": "Zschorlau",
    "zuelow": "Zülow",
    "zuelpich": "Zülpich",
    "zurow": "Zurow",
    "zusamaltheim": "Zusamaltheim",
    "zuesch": "Züsch",
    "zusmarshausen": "Zusmarshausen",
    "zuesow": "Züsow",
    "zuessow": "Züssow",
    "zuzenhausen": "Zuzenhausen",
    "zweibruecken": "Zweibrücken",
    "zweifelscheid": "Zweifelscheid",
    "zweiflingen": "Zweiflingen",
    "zwenkau": "Zwenkau",
    "zwickau": "Zwickau",
    "zwiefalten": "Zwiefalten",
    "zwiesel": "Zwiesel",
    "zwingenberg-baden": "Zwingenberg/Baden",
    "zwingenberg-bergstrasse": "Zwingenberg/Bergstraße",
    "zwoenitz": "Zwönitz"
  };
}
