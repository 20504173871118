import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { distinctUntilChanged, of, Subject, switchMap, tap } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, filter, map } from 'rxjs/operators';
import { CouponResource } from '@app/@core/@rest/design/coupons-resource.service';
import { Product } from '@app/@core/@models/design/product.model';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentResource } from '@app/@core/@rest/admin/payment-resource.service';
import { PaymentCalculation } from '@app/@core/@models/network/payment.model';
import { Coupon } from '@app/@core/@models/design/coupon.model';
import { Money } from '@app/@core/@models/network/money.model';
import {I18nLangService} from "@app/@i18n/services/i18n-lang.service";

@Component({
  selector: 'app-coupon[product][budget]',
  templateUrl: './campaign-coupon.component.html',
  styleUrls: ['./campaign-coupon.component.scss'],
})
export class CampaignCouponComponent implements OnInit {
  @Input() product!: Product;
  @Input() budget!: Money;
  @Output() discount = new EventEmitter<[Coupon, PaymentCalculation] | undefined>();
  readonly applyCode$ = new Subject<string>();
  readonly couponForm = new UntypedFormGroup({
    coupon: new UntypedFormControl('', Validators.required),
  });
  readonly error$ = new Subject();
  readonly success$ = new Subject();

  constructor(private couponResource: CouponResource, private i18nLangService: I18nLangService, private paymentResource: PaymentResource) {}

  ngOnInit(): void {
    if (this.product && this.budget) {
      this.initApplyCouponProcess(this.product, this.budget);
    }
  }

  onSubmit() {
    this.applyCode$.next(this.couponForm.value.coupon);
  }

  cancelCoupon() {
    this.success$.next('');
    this.error$.next('');
    this.applyCode$.next('');
    this.discount.next(undefined);
  }

  private initApplyCouponProcess(product: Product, budget: Money) {
    this.applyCode$
      .pipe(
        // debounce and trigger when non-empty coupon value is applied
        distinctUntilChanged(),
        filter((code) => Boolean(code)),
        // initialise
        tap(() => {
          this.error$.next('');
          this.success$.next('');
        }),
        // get coupon
        switchMap((code) =>
          this.couponResource.getCoupon(code, product.id).pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.status === 404) {
                const errorMessage = this.i18nLangService.translateWithDefault(code + ' is not a valid coupon code', 'signIn.coupon.invalidCode', {code: code});
                this.error$.next(errorMessage);
                this.couponForm.controls['coupon'].setValue('');
              } else {
                this.error$.next('error checking coupon');
              }
              return of(); // we're done
            })
          )
        ),
        // calculate discount
        switchMap((coupon) =>
          this.paymentResource.calculatePost({ amount: budget, coupon: coupon, product: product.id }).pipe(
            catchError((err) => {
              console.log(err);
              this.error$.next('error calculating discount');
              return of();
            }),
            map((paymentCalculation) => [coupon, paymentCalculation] as [Coupon, PaymentCalculation])
          )
        ),
        // success
        tap(([coupon, paymentCalculation]) => {
          const successMessage = this.i18nLangService.translateWithDefault(coupon.code + ' has been applied', 'campaign.coupon.appliedCode', {code: coupon.code});
          this.success$.next(successMessage);
          this.discount.next([coupon, paymentCalculation]);
        })
      )
      .subscribe();
  }
}
