import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adblock-detected-banner',
  templateUrl: './adblock-detected-banner.component.html',
  styleUrls: ['./adblock-detected-banner.component.scss']
})
export class AdblockDetectedBannerComponent implements OnInit {
  forceOff = false;
  isVisible = false;

  constructor() {}

  ngOnInit(): void {
    if (document.readyState === "complete") {
      this.checkVisibility();
    } else {
      window.onload = () => {
        this.checkVisibility();
      };
    }
  }

  private checkVisibility() {
    // just an extra minor delay to avoid any browser inconsistencies
    window.setTimeout(() => {
      this.isVisible = !this.forceOff && this.adsBlocked();
    }, 100);
  }

  private adsBlocked() {
    const adsbox = window.document.getElementsByClassName("adsbox");
    const faviconAd = window.document.getElementsByClassName("faviconAd");
    return (adsbox.length === 1 && adsbox[0].clientHeight < 20 && faviconAd.length === 1 && faviconAd[0].clientHeight < 20) || !window['canRunAds'] || !window['canRunAds2'];
  }
}
