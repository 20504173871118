type EndPointData = {
  id: string;
  scheme: string;
  host: string;
  port: number;
  basePath?: string;
  version?: string;
  type: string;
  env: string;
  siteKey?: string;
};
type EndPoint = Record<string, Record<string, EndPointData>>;

export const ALL_END_POINTS: EndPoint = {
  localhost: {
    ADN_API: {
      id: 'localhost',
      scheme: 'http',
      host: 'localhost',
      port: 8079,
      basePath: '/api',
      version: 'v1',
      type: 'API',
      env: 'localhost',
      siteKey: '10000000-ffff-ffff-ffff-000000000001',
    },
    ADN_AD_SERVER: {
      id: 'localhost',
      scheme: 'http',
      host: 'localhost',
      port: 8078,
      type: 'ADSERVER',
      env: 'localhost',
    },
    ADN_DATA_SERVER: {
      id: 'localhost',
      scheme: 'http',
      host: 'localhost',
      port: 8078,
      type: 'DATA',
      env: 'localhost',
    },
  },
  vagrant: {
    ADN_API: {
      id: 'vagrant',
      scheme: 'https',
      host: 'api.dev.vgt.test',
      port: 443,
      basePath: '/api',
      version: 'v1',
      type: 'API',
      env: 'vagrant',
      siteKey: '10000000-ffff-ffff-ffff-000000000001',
    },
    ADN_AD_SERVER: {
      id: 'vagrant',
      scheme: 'https',
      host: 'adserver.dev.vgt.test',
      port: 443,
      type: 'ADSERVER',
      env: 'vagrant',
    },
    ADN_DATA_SERVER: {
      id: 'vagrant',
      scheme: 'https',
      host: 'dev.data.vgt.test',
      port: 443,
      type: 'DATA',
      env: 'vagrant',
    },
  },
  coreuidist: {
    ADN_API: {
      id: 'coreuidist',
      scheme: 'http',
      host: 'core-ui-dist',
      port: 8079,
      basePath: '/api',
      version: 'v1',
      type: 'API',
      env: 'coreuidist',
      siteKey: '10000000-ffff-ffff-ffff-000000000001',
    },
    ADN_AD_SERVER: {
      id: 'coreuidist',
      scheme: 'https',
      host: 'core-ui-dist',
      port: 8078,
      type: 'ADSERVER',
      env: 'coreuidist',
    },
    ADN_DATA_SERVER: {
      id: 'coreuidist',
      scheme: 'https',
      host: 'core-ui-dist',
      port: 8078,
      type: 'DATA',
      env: 'coreuidist',
    },
  },
  dev_adn: {
    ADN_API: {
      id: 'dev_adn',
      host: 'api.dev.adnuntius.com',
      scheme: 'https',
      port: 443,
      basePath: '/api',
      version: 'v1',
      type: 'API',
      env: 'dev',
      siteKey: 'f7151f31-8c0b-494f-9c7f-440e06c2879d',
    },
    ADN_AD_SERVER: {
      id: 'dev_adn',
      host: 'adserver.dev.adnuntius.com',
      scheme: 'https',
      port: 443,
      type: 'ADSERVER',
      env: 'dev',
    },
    ADN_DATA_SERVER: {
      id: 'dev_adn',
      host: 'dev.data.adnuntius.com',
      scheme: 'https',
      port: 443,
      type: 'DATA',
      env: 'dev',
    },
  },
  production_adn: {
    ADN_API: {
      id: 'production_adn',
      host: 'api.adnuntius.com',
      scheme: 'https',
      port: 443,
      basePath: '/api',
      version: 'v1',
      type: 'API',
      env: 'production',
      siteKey: '3cc74c22-7589-4f74-9a85-f23e5d8a74e7',
    },
    ADN_AD_SERVER: {
      id: 'production_adn',
      host: 'delivery.adnuntius.com',
      scheme: 'https',
      port: 443,
      type: 'ADSERVER',
      env: 'production',
    },
    ADN_DATA_SERVER: {
      id: 'production_adn',
      host: 'data.adnuntius.com',
      scheme: 'https',
      port: 443,
      type: 'DATA',
      env: 'production',
    },
  },
  staging_adn: {
    ADN_API: {
      id: 'staging_adn',
      host: 'api.staging.adnuntius.com',
      scheme: 'https',
      port: 443,
      basePath: '/api',
      version: 'v1',
      type: 'API',
      env: 'staging',
      siteKey: '951427b9-4db2-4c28-b171-b12a8dbc131d',
    },
    ADN_AD_SERVER: {
      id: 'staging_adn',
      host: 'adserver.staging.adnuntius.com',
      scheme: 'https',
      port: 443,
      type: 'ADSERVER',
      env: 'staging',
    },
    ADN_DATA_SERVER: {
      id: 'staging_adn',
      host: 'staging.data.adnuntius.com',
      scheme: 'https',
      port: 443,
      type: 'DATA',
      env: 'staging',
    },
  },
};
