import { Component, Input, OnInit } from '@angular/core';
import { Product } from '@app/@core/@models/design/product.model';
import { DevicePropertyTarget } from '@app/@core/@models/targeting/device-property-target.model';
import { TargetingForm } from '@app/@core/advertising/campaign/advert/targeting-form';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-device-targeting-options[product][targetingForm]',
  templateUrl: './device-targeting-options.component.html',
  styleUrls: ['./device-targeting-options.component.scss']
})
export class DeviceTargetingOptionsComponent implements OnInit {
  @Input() product!: Product;
  @Input() targetingForm!: TargetingForm;
  uuid: string = Uuid.generate();

  constructor() {
  }

  ngOnInit(): void {
  }

  getSelectedDevicePropertyTargets(): DevicePropertyTarget[] {
    const result: DevicePropertyTarget[] = [];
    for (let i = 0; i < this.targetingForm.value.deviceTargets!.length; i++) {
      if (this.targetingForm.value.deviceTargets![i]) {
        const devicePropertyTarget = this.getDevicePropertyTarget(i);
        if (devicePropertyTarget) {
          result.push(devicePropertyTarget);
        }
      }
    }
    return result;
  }

  getDevices(devicePropertyTarget: DevicePropertyTarget | undefined): string[] {
    const result: string[] = [];
    if (devicePropertyTarget) {
      devicePropertyTarget.targetedDeviceTypes.forEach(deviceType => result.push(deviceType.toString()));
      devicePropertyTarget.targetedOSes.forEach(os => result.push(os.toString()));
      devicePropertyTarget.targetedBrands.forEach(brand => result.push(brand.toString()));
    }
    return result;
  }

  getDevicesFromIndex(index: number): string[] {
    return this.getDevices(this.getDevicePropertyTarget(index));
  }

  private getDevicePropertyTarget(index: number): DevicePropertyTarget | undefined {
    if (!this.product.creativeTargeting.deviceTargets || index >= this.product.creativeTargeting.deviceTargets.length) {
      return undefined;
    } else {
      return this.product.creativeTargeting.deviceTargets[index];
    }
  }

}
