import {LineItem} from '@app/@core/@models/advertising/lineitem.model';
import {LayoutReference, LayoutTag} from '@app/@core/@models/advertising/layout/layout.model';
import { Targeting } from '@app/@core/@models/targeting/targeting.model';
import { ValidationWarningMessage } from '@app/@core/@models/advertising/validation-warning-message.model';
import {ObjectState} from '@app/@core/@models/persistence/object-state.model';
import {UserState} from '@app/@core/@models/advertising/user-state.model';

export type LayoutParameter = ImageParameter | string;

export interface ImageParameter {
  id: string
}

export function isImageParameter(layoutParameter: LayoutParameter): layoutParameter is ImageParameter {
  return (layoutParameter as ImageParameter).id !== undefined;
}

export type LayoutParameters = { [key: LayoutTag]: LayoutParameter | undefined };

export type CopyrightStatsType = 'UNSPECIFIED' | 'ADVERTISER_CONFIRMED' | 'ADVERTISER_UNCONFIRMED';

export type RejectedReason = 'ADULT_CONTENT' | 'BLANK_CONTENT' | 'MALFORMED_CLICK_THROUGH' |
  'DRUG_RELATED_CONTENT' | 'WEAPON_RELATED_CONTENT' | 'ABUSIVE_CONTENT' |
  'HATE_SPEECH_CONTENT' | 'LANDING_PAGE_REJECTED' | 'OFFENSIVE_CONTENT' |
  'UNACCEPTABLE_VISUAL_EFFECTS' | 'DECEPTIVE_CONTENT' | 'GAMBLING_CONTENT' |
  'PROHIBITED_ELECTION_RELATED_CONTENT' | 'SHOCKING_CONTENT' | 'MALWARE_CONTENT';

// ng.model.Creative
export interface Creative {
  copyrightStatus: CopyrightStatsType
  createInterface: string
  createTime: string
  height?: number
  id: string
  isNew: boolean // used ui only
  lineItem: LineItem
  layout?: LayoutReference
  layoutParameters: LayoutParameters
  name: string
  objectState: ObjectState
  rejectedReason?: RejectedReason
  targeting: Targeting
  userState: UserState
  validationWarnings: ValidationWarningMessage[]
  width?: number
}
