import {Injectable} from '@angular/core';
import {Locale} from '@app/@core/@models/common/locale';
import {LocaleMappingService} from '@app/@i18n/services/locale-mapping.service';

/**
 * This service is used to get the locale from the browser.
 */
@Injectable({
  providedIn: 'root'
})
export class NavigatorLocaleService {

  constructor(private localeMappingService: LocaleMappingService) { }

  getNavigatorJavaLocale(): Locale {
    const unicodeLocaleId = this.getNavigatorUnicodeLocaleId();
    return this.getLocaleFromUnicodeLocaleId(unicodeLocaleId);
  }

  // Returns browser UnicodeLocaleId eg. en-AU
  private getNavigatorUnicodeLocaleId(): string {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.language || 'en';
    }
  }

  // Given Unicode Locale Id eg. en-AU, returns Locale eg. en_AU
  private getLocaleFromUnicodeLocaleId(unicodeLocaleId: string): Locale {
    const registeredLocales = this.localeMappingService.getSupportedJavaLocales()
    const localeKey = unicodeLocaleId.replace('-', '_') as Locale;
    if (registeredLocales.includes(localeKey)) {
      return localeKey as Locale;
    }
    const split = localeKey.split("_");
    if (registeredLocales.indexOf(split[0] as Locale) > -1) {
      return split[0] as Locale;
    }
    return "en_GB" as Locale;
  }

}
