<form [formGroup]="couponForm" (ngSubmit)="onSubmit()" class="d-grid gap-2">
  <label for="couponInput" class="form-label">
    {{ 'signIn.coupon.code' | translate }}
  </label>
  <div class="input-group has-validation">
    <input
      style='max-width: 400px'
      type="text"
      class="form-control form-control-sm"
      id="couponInput"
      aria-describedby="inputGroupPrepend"
      required
      formControlName="coupon"
    >
    <button type="submit" [disabled]="!couponForm.valid" class="btn btn-outline-dark">{{'campaign.coupon.applyCoupon' | translate}}</button>
    <div class="invalid-feedback">Please enter a coupon code.</div>
  </div>
  <p>
    <span *ngIf="success$ | async as success" class="badge bg-success">{{ success }}</span>
    <span *ngIf="error$ | async as error" class="badge bg-danger">{{ error }}</span>
    <span *ngIf="success$ | async as success"><a (click)="cancelCoupon()" class="link-primary">{{'campaign.coupon.cancel' | translate}}</a></span>
  </p>
</form>
