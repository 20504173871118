import { Injectable } from '@angular/core';
import {MediaType} from '@app/@core/design/media-type';
import {AssetComponent} from '@app/@core/@models/advertising/layout/asset-component.model';

// List of supported media types, their mime types, file extensions, etc.
// copied from sui src/components/util/media-type-helper.ts
// added mp3 from uii src/components/util/media-type-helper.ts
// comment out other and html media types
const SUPPORTED_MEDIA_TYPES: MediaType[] = [
  {id: 'gif', mimeTypes: ['IMAGE_GIF'], rawMimeTypes: ['image/gif'], fileExts: ['.gif'], image: true},
  {id: 'jpg', mimeTypes: ['IMAGE_JPEG', 'IMAGE_PJPEG'], rawMimeTypes: ['image/jpeg', 'image/pjpeg'], fileExts: ['.jpg', '.jpeg'], image: true},
  {id: 'png', mimeTypes: ['IMAGE_PNG', 'IMAGE_X_PNG'], rawMimeTypes: ['image/png', 'image/x-png'], fileExts: ['.png'], image: true},
  {id: 'svg', mimeTypes: ['IMAGE_SVG'], rawMimeTypes: ['image/svg+xml'], fileExts: ['.svg'], image: true},
  {id: 'webp', mimeTypes: ['IMAGE_WEBP'], rawMimeTypes: ['image/webp'], fileExts: ['.webp'], image: true},
  // {id: 'font', mimeTypes: ['APPLICATION_X_WOFF', 'APPLICATION_X_FONT_TTF', 'APPLICATION_X_FONT_WOFF', 'APPLICATION_MS_FONT_OBJECT', 'APPLICATION_FONT_WOFF'], rawMimeTypes: ['application/x-font-woff', 'application/x-font-ttf', 'application/x-woff', 'application/font-woff', 'application/vnd.ms-fontobject'], other: true},
  // {id: 'css', mimeTypes: ['TEXT_CSS'], rawMimeTypes: ['text/css'], fileExts: ['css'], other: true},
  // {id: 'js', mimeTypes: ['APPLICATION_JAVASCRIPT', 'TEXT_JAVASCRIPT'], rawMimeTypes: ['application/javascript', 'text/javascript'], fileExts: ['js'], other: true},
  // {id: 'subtitle', mimeTypes: ['TEXT_VTT', 'TEXT_SRT'], rawMimeTypes: ['text/vtt', 'text/srt'], fileExts: ['srt'], other: true},
  // {id: 'json', mimeTypes: ['APPLICATION_JSON'], rawMimeTypes: ['application/json'], fileExts: ['json'], other: true},
  // {id: 'txt', mimeTypes: ['TEXT_PLAIN'], rawMimeTypes: ['text/plain'], fileExts: ['txt'], other: true},
  // {id: 'flash', mimeTypes: ['APPLICATION_X_SHOCKWAVE_FLASH', 'APPLICATION_VND_ADOBE_FLASH_MOVIE'], rawMimeTypes: ['application/x-shockwave-flash', 'application/vnd.adobe.flash.movie'], other: true},
  // {id: 'zip', mimeTypes: ['APPLICATION_ZIP', 'APPLICATION_X_ZIP_COMPRESSED', 'MULTIPART_X_ZIP', 'APPLICATION_X_COMPRESSED'], rawMimeTypes: ['application/zip', 'application/x-zip-compressed', 'multipart/x-zip', 'application/x-compressed']},
  // {id: 'xhtml', mimeTypes: ['TEXT_XHTML'], rawMimeTypes: ['application/xhtml+xml']},
  // {id: 'html', mimeTypes: ['TEXT_HTML', 'TEXT_XHTML'], rawMimeTypes: ['text/html', 'application/xhtml+xml']},
  {id: 'mp4', mimeTypes: ['VIDEO_MP4', 'VIDEO_M4V', 'VIDEO_X_M4V'], rawMimeTypes: ['video/mp4', 'video/m4v', 'video/x-mp4'], fileExts: ['.mp4'], video: true},
  {id: 'webm', mimeTypes: ['VIDEO_WEBM'], rawMimeTypes: ['video/webm'], fileExts: ['.webm'], video: true},
  {id: 'mp3', mimeTypes: ['AUDIO_MP3'], rawMimeTypes: ['audio/mpeg'], fileExts: ['.mp3'], audio: true},
];

@Injectable({
  providedIn: 'root'
})
export class MediaTypeHelper {

  constructor() { }

  getSupportedMediaType(mimeType: string): MediaType | undefined {
    return SUPPORTED_MEDIA_TYPES.find(
      assetMediaType => assetMediaType.mimeTypes.includes(mimeType) || assetMediaType.rawMimeTypes.includes(mimeType)
    );
  }

  getSupportedMediaTypes(assetComponent: AssetComponent): MediaType[] {
    const mediaTypes = new Set<MediaType>();
    assetComponent.mimeTypes.forEach(mimeType => {
      const mediaType = this.getSupportedMediaType(mimeType);
      if (mediaType) {
        mediaTypes.add(mediaType);
      }
    })
    return [...mediaTypes];
  }

}
