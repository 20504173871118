import {Component, Input, OnInit} from '@angular/core';
import {RegisterForm} from '@app/sign-in/register/@form/register-form';

@Component({
  selector: 'app-vat-id-field[registerForm][showValidation]',
  templateUrl: './vat-id-field.component.html',
  styleUrls: ['./vat-id-field.component.scss']
})
export class VatIdFieldComponent implements OnInit {
  @Input() registerForm!: RegisterForm;
  @Input() showValidation!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  isInvalid(): boolean {
    return this.showValidation && this.registerForm.controls.vatId.invalid;
  }

}
