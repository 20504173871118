import {Injectable} from '@angular/core';
import {Network} from '@app/@core/@models/network/network.model';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {NetworkData} from '@app/@core/@config/registered-urls-data';
import {User} from '@app/@core/@models/users/user.model';
import {AuthUserPerms} from '@app/@core/@models/auth/authenticated-user.model';
import {UserProfile} from '@app/@core/@models/users/profile/user-profile.model';

export type SignedInContext = {
  network: Network
  networkData?: NetworkData
  user: User
  authUserPerms: AuthUserPerms
  userProfile: UserProfile
};

/**
 * SingedIn Context holds information about the current signed-in user and network.
 * - selected network and networkData
 * - user, user permissions, user profile
 * It as a solution to https://stackoverflow.com/questions/41451375/passing-data-into-router-outlet-child-components
 * Components that appear in the router-outlet of the SignedInShell need to subscribe
 * to the context$ stream, and update accordingly. Any component that modifies user or network data must
 * update the context by calling SignedInContextService.nextEvent() for it to propagate through the application.
 */
@Injectable({
  providedIn: 'root'
})
export class SignedInContextService {
  private context: Subject<SignedInContext> = new ReplaySubject<SignedInContext>(1);
  private event: Subject<Partial<SignedInContext>> = new Subject<Partial<SignedInContext>>();

  constructor() {
  }

  next(context: SignedInContext) {
    this.context.next(context);
  }

  context$(): Observable<SignedInContext> {
    return this.context;
  }

  nextEvent(contextUpdate: Partial<SignedInContext>) {
    this.event.next(contextUpdate);
  }

  event$(): Observable<Partial<SignedInContext>> {
    return this.event;
  }

}
