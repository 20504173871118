<ng-container [formGroup]="advertDraft.advertForm" *ngIf='product'>
  <ng-container *ngIf="layouts">
    <hr *ngIf="layouts.length > 1 && advertDraft.campaignDraft.products.length > 1">
    <h2 [innerHTML]="'campaign.ad.create' | translate"></h2>
    <ngb-alert *ngIf="advertDraft.creative.rejectedReason as rejectedReason" type="danger" [dismissible]="false">
      <p><strong>{{ 'campaign.adRejected.' + rejectedReason + '.explanation' | translate }}</strong></p>
      <div>{{ 'campaign.adRejected.explanation' | translate }}</div>
    </ngb-alert>
    <div class="loading-overlay-parent">
      <div *ngIf="showLoadingOverlays" class="loading-overlay" [ngStyle]="{'cursor':'default'}"></div>
      <app-layout-selector *ngIf="layouts.length > 1" [advertDraft]="advertDraft"></app-layout-selector>
    </div>
    <hr class='subDivider'>
    <div class="row justify-content-between">
      <h3 class="col-auto">{{ 'campaign.ad.title' | translate }}</h3>
      <div
        *ngIf="advertDraft.campaignDraft.numVisibleCreatives(advertDraft.campaignDraft.campaignForm) > 1"
        class="col-auto small"
        [innerHTML]="'campaign.ad.count' | translate: { count: advertDraft.campaignDraft.advertisementNumber(advertDraft.campaignDraft.campaignForm, advertDraft.advertForm) }"
      ></div>
    </div>
    <div class="loading-overlay-parent">
      <div *ngIf="showLoadingOverlays" class="loading-overlay"></div>
      <div *ngIf="advertDraft.isSelectedLayoutValid()" class="row">
        <div class="col-6" *ngIf='assetControls'>
          <div class="mb-3"
               *ngFor="let layoutComponentForm of advertDraft.advertForm.controls.layoutComponents.controls; let i = index">
            <ng-container *ngIf="toAssetComponentForm(layoutComponentForm) as assetComponentForm">
              <app-asset-layout-component [anon]="advertDraft.campaignDraft.anon" [creativeId]="creativeId"
                                          [assetComponentForm]="assetComponentForm"
                                          [assets]="assetControls"
                                          [serverValidationWarnings]="serverValidationWarnings(assetComponentForm)"
              ></app-asset-layout-component>
            </ng-container>
            <ng-container *ngIf="toUrlComponentForm(layoutComponentForm) as urlComponentForm">
              <app-url-layout-component [urlComponentForm]="urlComponentForm"
                                        [serverValidationWarnings]="serverValidationWarnings(urlComponentForm)">
              </app-url-layout-component>
            </ng-container>
            <ng-container *ngIf="toTextComponentForm(layoutComponentForm) as textComponentForm">
              <app-text-component-editor [textComponentForm]="textComponentForm"
                                         [serverValidationWarnings]="serverValidationWarnings(textComponentForm)"></app-text-component-editor>
            </ng-container>
            <ng-container *ngIf="toChoiceComponentForm(layoutComponentForm) as choiceComponentForm">
              <app-choice-layout-component [choiceComponentForm]="choiceComponentForm"
                                           [serverValidationWarnings]="serverValidationWarnings(choiceComponentForm)"
              ></app-choice-layout-component>
            </ng-container>
          </div>
          <app-copyright-declaration *ngIf="hasCreativeCopyrightStatus"
                                     [advertForm]="advertDraft.advertForm"></app-copyright-declaration>
        </div>
        <div class="col-6" *ngIf='selectedLayout'>
          <app-ad-size-selector [product]="product" [advertDraft]="advertDraft"></app-ad-size-selector>
          <app-ad-preview *ngIf="advertDraft.advertForm.controls.creativeSize.value"
                          class="mb-3"
                          [showLabel]="true"
                          [creative]="getCreativeFromForm()"
                          [creativePristine]="advertDraft.advertForm.pristine"
                          [layout]="selectedLayout"
                          [assets]="getAssetArray()"
                          [anon]="advertDraft.campaignDraft.anon"
          ></app-ad-preview>
        </div>
      </div>
    </div>
    <ngb-alert *ngIf='!advertDraft.isSelectedLayoutValid()' type='warning'
               [dismissible]='false'>{{ 'campaign.ad.layoutFirst' | translate }}</ngb-alert>
    <app-site-targeting-options
      *ngIf="product.creativeTargeting.siteTarget && product.creativeTargeting.siteTarget.sites.length"
      [product]="product"
      [targetingForm]="advertDraft.advertForm.controls.targeting"></app-site-targeting-options>
    <app-device-targeting-options
      *ngIf="product.creativeTargeting.deviceTargets && product.creativeTargeting.deviceTargets.length"
      [product]="product" [targetingForm]="advertDraft.advertForm.controls.targeting"></app-device-targeting-options>
    <app-location-targeting-options
      *ngIf="product.creativeTargeting.namedLocationTarget && product.creativeTargeting.namedLocationTarget.locations.length"
      [product]="product"
      [targetingForm]="advertDraft.advertForm.controls.targeting"></app-location-targeting-options>
    <app-category-targeting-options
      *ngIf="product.creativeTargeting.categoryTargets && product.creativeTargeting.categoryTargets.length"
      [product]="product" [targetingForm]="advertDraft.advertForm.controls.targeting"></app-category-targeting-options>
    <app-domain-name-targeting-options
      *ngIf="product.creativeTargeting.domainNameTarget && product.creativeTargeting.domainNameTarget.names.length" [product]="product"
      [targetingForm]="advertDraft.advertForm.controls.targeting"></app-domain-name-targeting-options>
    <app-user-segment-targeting-options
      *ngIf="product.creativeTargeting.userSegmentTargets?.length"
      [product]="product"
      [targetingForm]="advertDraft.advertForm.controls.targeting"></app-user-segment-targeting-options>
  </ng-container>
</ng-container>
