import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';

@Component({
  selector: 'app-submit-campaign[campaignDraft][isSubmitting]',
  templateUrl: './submit-campaign.component.html',
  styleUrls: ['./submit-campaign.component.scss'],
})
export class SubmitCampaignComponent implements OnInit, OnChanges {
  @Input() campaignDraft!: CampaignDraft;
  @Output() isSubmitting = new EventEmitter<boolean>();
  postPayment!: boolean;

  constructor(
    public i18nFormatService: I18nFormatService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.postPayment = this.campaignDraft.lineItemReview.payments
      .filter(
        payment => payment.status === 'AUTHORISED' || payment.status === 'COMPLETED'
      ).length > 0;
  }

}
