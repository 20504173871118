import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DefaultCurrencyCodeService extends String {
  defaultCurrencyCode: string;

  constructor() {
    super();
    this.defaultCurrencyCode = 'EUR';
  }

  override toString() {
    return this.defaultCurrencyCode;
  }

  setDefaultCurrencyCode(code: string) {
    this.defaultCurrencyCode = code;
  }
}
