<div [ngClass]="{' border-danger': invalid}">
  <div style='position: absolute; right: 3px; top: 3px'>
    <div class='btn-group btn-group-xs' role='group'>
      <a *ngIf="asset.cdnId" title='Full-size' [href]='asset.cdnId' target='_blank' type='button' class='btn btn-xs btn-light btn-outline-dark'><i class='fa fa-external-link'></i></a>
    </div>
  </div>
  <div *ngIf="asset" class='asset'>
    <img *ngIf='!asset.base64' [src]='asset.cdnId' alt='asset thumbnail' />
    <img *ngIf='asset.base64' [src]="asset.base64" (load)="onBase64ImageLoad($event)" alt='asset thumbnail' />
  </div>
  <div class='caption text-center'>
    <div class='assetFileName'><strong>{{ asset.fileName }}</strong></div>
    <small>
      <span *ngIf="asset.width && asset.height">{{ asset.width }}x{{ asset.height }},</span>
      <span *ngIf="base64size">{{ base64size }},</span>
      <span>{{ 'media.' + assetMediaTypeId() | translate }},</span>
      <span>{{ asset.fileSizeBytes / 1024 | number : '1.0-1' }}kb</span>
    </small>
  </div>
</div>
