import { Component, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { ConfigService } from '@app/@core/@config/config.service';
import { EMPTY, finalize, Observable, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SignUpProps, SignUpResource } from '@app/@core/@rest/users/sign-up-resource.service';
import { RegisterForm } from '@app/sign-in/register/@form/register-form';
import { isErrorMessage } from '@app/@core/@models/common/error-message';
import { I18nLangService } from '@app/@i18n/services/i18n-lang.service';
import {NavigatorLocaleService} from '@app/@i18n/services/navigator-locale.service';

@Component({
  selector: 'app-register-component',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm!: RegisterForm;
  hasRegistered = false;
  showValidation = false;
  signupRequest$?: Observable<undefined>;
  error?: string;
  hideLocale: boolean = false;
  showCoupons: boolean = false;
  hasSignUpCondition: boolean = false;

  constructor(private fb: NonNullableFormBuilder,
              public configService: ConfigService,
              private signUpResource: SignUpResource,
              private i18nLangService: I18nLangService,
              private navigatorLocaleService: NavigatorLocaleService) {
  }

  ngOnInit(): void {
    this.registerForm = this.createForm();
    this.hideLocale = !!this.configService.getNetworkData()?.fixedLocale;
    this.showCoupons = this.configService.getNetworkData()?.showCoupons || false;
    this.hasSignUpCondition = this.configService.getNetworkData()?.hasSignUpCondition || false;
  }

  onSubmit() {
    this.showValidation = true;
    if (this.registerForm.valid) {
      this.doSignUp();
    }
  }

  private doSignUp() {
    this.error = undefined;
    this.signupRequest$ = this.signUpResource.formSignup(this.getSignUpProps()).pipe(
      // success
      tap(() => this.hasRegistered = true),
      // error
      catchError((err) => {
        if (isErrorMessage(err.error)) {
          this.error = this.i18nLangService.translateWithDefault(err.error.text, 'validation.' + err.error.code, err.error.params);
        } else {
          this.error = err.statusText || err;
        }
        return EMPTY;
      }),
      // cleanup
      finalize(() => this.signupRequest$ = undefined)
    );
  }

  private createForm(): RegisterForm {
    return this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      companyName: ['', this.configService.getNetworkData()?.advertiser?.companyName === 'mandatory' ? Validators.required : Validators.nullValidator],
      vatId: ['', this.configService.getNetworkData()?.advertiser?.vatId === 'mandatory' ? Validators.required : Validators.nullValidator],
      phoneNumber: ['', this.configService.getNetworkData()?.advertiser?.phoneNumber === 'mandatory' ? Validators.required : Validators.nullValidator],
      locale: [this.configService.getNetworkData()?.fixedLocale || this.navigatorLocaleService.getNavigatorJavaLocale() as string, Validators.required],
      hCaptchaResponse: ['', Validators.required],
      signUpCondition: [false, !!this.configService.getNetworkData()?.hasSignUpCondition ? Validators.requiredTrue : Validators.nullValidator],
      couponFormGroup: this.fb.group({
        couponCode: ['']
      })
    });
  }

  private getSignUpProps() {
    return {
      emailAddress: this.registerForm.value.email,
      name: this.registerForm.value.name,
      advertiserName: this.registerForm.value.companyName,
      vatId: this.registerForm.value.vatId,
      phoneNumber: this.registerForm.value.phoneNumber,
      couponCode: this.registerForm.value.couponFormGroup?.couponCode,
      displayName: this.registerForm.value.name,
      hCaptchaResponse: this.registerForm.value.hCaptchaResponse,
      locale: this.registerForm.value.locale
    } as SignUpProps;
  }

}
