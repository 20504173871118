import {Injectable} from '@angular/core';
import {Layout} from '@app/@core/@models/advertising/layout/layout.model';
import {ProductSelectionData} from '@app/@core/@models/network/network-data.model';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ProductResource} from '@app/@core/@rest/design/product-resource.service';
import {ConfigService} from '@app/@core/@config/config.service';
import {NetworkDataResource} from '@app/@core/@rest/admin/network-data-resource.service';
import {LayoutsResource} from '@app/@core/@rest/design/layouts-resource.service';
import {Product} from '@app/@core/@models/design/product.model';
import {ResultList} from '@app/@core/@models/common/result-list';
import {AnonSelfServiceResource} from '@app/@core/@rest/anon/anon-self-service-resource.service';
import {PersistenceQueryOptions} from '@app/@core/@models/persistence/persistence-query-options';
import {CouponResource} from '@app/@core/@rest/design/coupons-resource.service';
import {Coupon} from '@app/@core/@models/design/coupon.model';

export type ProductData = {
  products: ResultList<Product>,
  layouts: ResultList<Layout>,
  productSelectionData?: ProductSelectionData,
  signUpCoupons?: ResultList<Coupon>
};

@Injectable({
  providedIn: 'root'
})
export class ProductDataHelper {

  constructor(private productResource: ProductResource,
              private configService: ConfigService,
              private networkDataResource: NetworkDataResource,
              private layoutsResource: LayoutsResource,
              private anonSelfServiceResource: AnonSelfServiceResource,
              private couponResource: CouponResource) {
  }

  getProductData$(props: { anon: boolean }): Observable<ProductData> {
    return forkJoin([
      this.getProducts$(props),
      this.getProductFilter$(props),
      this.signUpCoupons(props.anon)
    ]).pipe(
      switchMap(([products, filterList, signUpCoupons]) => this.getLayouts$(props, products).pipe(
          map((layouts) =>
            ({products, productSelectionData: this.getFilter(filterList), layouts, signUpCoupons}))
        )
      )
    );
  }

  private getProducts$(props: { anon: boolean }): Observable<ResultList<Product>> {
    const productQueryParams = {
      context: this.configService.getNetworkData()!.id,
      excludeInvalid: true,
      includeActive: true,
      includeHidden: false,
      includeInactive: false,
      orderBy: 'name',
      pageSize: 301,
    };
    return props.anon ? this.anonSelfServiceResource.getProducts$(productQueryParams) : this.productResource.get(productQueryParams);
  }

  private getProductFilter$(props: { anon: boolean }): Observable<ResultList<ProductSelectionData>> {
    return props.anon ? this.anonSelfServiceResource.getProductFilters$() : this.networkDataResource.getProductSelectionData();
  }

  private getLayouts$(props: { anon: boolean }, products: ResultList<Product>): Observable<ResultList<Layout>> {
    const options = {
      includeActive: true,
      includeHidden: false,
      includeInactive: false,
      orderBy: 'name',
    } as PersistenceQueryOptions;
    const layoutIdSet = new Set(products.results.flatMap(product => product.layouts.map(layout => layout.id)));
    options.pageSize = layoutIdSet.size;
    return props.anon ?
      this.anonSelfServiceResource.getLayouts$(options, [...layoutIdSet])
      : this.layoutsResource.getLayouts$(options, [...layoutIdSet]);
  }

  private getFilter(filters: ResultList<ProductSelectionData>): ProductSelectionData | undefined {
    return filters.results.find((filter) => filter.type === 'productSelection');
  }

  private signUpCoupons(anon: boolean): Observable<ResultList<Coupon> | undefined> {
    if (anon) {
      return of(undefined);
    } else {
      return this.couponResource.signupCoupon();
    }
  }
}
