import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/@core/@config/config.service';
import { ActivatedRoute } from '@angular/router';

export enum ResetStep {
  EmailEntry,
  EmailSent,
  EnterPassword,
}

export interface PasswordResetUpdateEvent {
  nextStep: ResetStep,
  errorKey?: string
}

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  currentStep!: ResetStep;
  token!: string | null;
  prefillUsername!: string | null;
  errorKey?: string;

  constructor(
    private configService: ConfigService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.prefillUsername = this.route.snapshot.paramMap.get('username');
    if (!this.token) {
      this.currentStep = ResetStep.EmailEntry;
    } else {
      this.currentStep = ResetStep.EnterPassword;
    }
  }

  handleUpdate(updateEvent: PasswordResetUpdateEvent) {
    this.currentStep = updateEvent.nextStep;
    this.errorKey = updateEvent.errorKey;
  }

  public get ResetStep() {
    return ResetStep;
  }

}
