<ng-container [formGroup]="targetingForm">
  <hr class='subDivider'>
  <h3>{{ 'campaign.category.title' | translate }}</h3>
  <div formArrayName="categoryTargets" class="d-flex flex-column align-items-center">
    <div [ngClass]="{ 'is-invalid': isInvalid()}"></div>
    <div class="text-center" [ngClass]="{ 'invalid-feedback': isInvalid()}">
      <span [innerHTML]="'campaign.category.descriptionPrefix' | translate"></span><strong
      *ngIf="categoryOutputs as selCategoryOutput"><span
      *ngIf="selCategoryOutput.length === 0">{{ 'campaign.category.any' | translate }}</span><span
      *ngIf="selCategoryOutput.length > 0"><span
      *ngFor="let selectedItem of selCategoryOutput; let isLast=last"><span
      *ngIf="!showSearch">{{ selectedItem.output }}</span><button title="Remove"
                                                                  *ngIf="showSearch" class="btn btn-outline-dark mb-1" type="button" (click)="entryChanged(selectedItem.id, false)">{{ selectedItem.output }} <i class="fas fa-circle-xmark"></i></button><span
      *ngIf="!showSearch && !isLast" translate="grammar.listSeparator"></span> </span></span></strong>
    </div>
    <p *ngIf="showSearch" [ngClass]="{'invalid-feedback': isInvalid()}" class="form-text text-center">{{ 'campaign.category.instructions' | translate }}</p>
    <div *ngIf="showSearch" class="col-5">
      <p>
        <input placeholder="{{'campaign.category.searchOptions' | translate}}" class="form-control" type="text" [ngModel]="searchTerm" (ngModelChange)='searchTermChanged($event)' [ngModelOptions]="{standalone: true}">
      </p>
      <div *ngIf="searchTerm.length > 0">
        <div *ngFor="let catResult of pagedSearchResults">
          <button class="btn btn-link px-0" [disabled]="quickFindIndex[catResult.id]" type="button" (click)="entryChanged(catResult.id, true)" title="Add"><i class="fas fa-plus-circle"></i></button>
          <button class="btn btn-link px-0" [disabled]="!quickFindIndex[catResult.id]" type="button" (click)="entryChanged(catResult.id, false)" title="Remove"><i class="fas fa-minus-circle"></i></button>
          {{catResult.output}}
        </div>
        <p *ngIf="!pagedSearchResults.length" translate="campaign.category.noResults"></p>
        <button class="btn btn-link px-0" type="button" *ngIf="showMoreLink" (click)="showMoreResults()"><i class="fas fa-arrow-down"></i> <span translate="campaign.category.more"></span></button>
      </div>
    </div>
    <div *ngIf="!showSearch" class="text-center">
      <ng-container *ngFor="let categoryTargetControl of targetingForm.controls.categoryTargets.controls; let i = index">
        <div class="form-check form-check-inline" *ngIf="'categoryTarget_'+uuid+i as id">
          <input
            (change)="selectionHappened()"
            class="form-check-input"
            type="checkbox"
            [formControlName]="i"
            [id]="id"
          />
          <label class="form-check-label" [for]="id" *ngIf="categoryTargets[i] as categoryTarget">
            <ng-container *ngFor="let category of categoryTarget.categories">{{category}}</ng-container>
            <ng-container *ngFor="let notCategory of categoryTarget.notCategories">{{notCategory}}</ng-container>
          </label>
        </div>
      </ng-container>
      <div class="small form-text">{{ 'campaign.category.instructions' | translate }}</div>
    </div>
  </div>
</ng-container>
