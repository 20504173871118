/**
 * Copyright © 2023 Adnuntius AS.
 */

export const TRANSLATIONS_IS = {
  adn: {
    copyright: 'Höfundarréttur © 2023 Adnuntius AS',
    releaseId: 'Kennitala útgáfu: {{id}}',
    poweredBy: 'Knúið af Adnuntius AS',
  },
  grammar: {
    listSeparator: ', ',
    titleSeparator: ': ',
    booleanOr: 'OR',
  },
  general: {
    new: 'Nýtt',
  },
  button: {
    upload: {
      asset: 'Hlaða upp',
      drop: 'Dragðu skjal hingað eða notaðu takkann til að hlaða upp',
    },
    archive: 'Safnvista',
    activate: 'Virkja',
    applyCustomRange: 'Nota sérsniðið svið',
    cancel: 'Hætta við',
    clear: 'Hreinsa',
    close: 'Loka',
    newCampaign: 'Byrja nýja auglýsingaherferð',
    save: 'Vista',
    undo: 'Afturkalla',
    today: 'Í dag',
  },
  locale: {
    ar: 'Arabíska - عربى',
    ar_JO: 'Arabíska (Jórdanía) - العربية (الأردن)',
    ar_EG: 'Arabíska (Egyptaland) - العربية (مصر)',
    ar_AE: 'Arabíska (Sameinuðu arabísku furstadæmin) - العربية (الإمارات العربية المتحدة)',
    my: 'Búrmanska - ဗမာ',
    en: 'English',
    en_AU: 'Enska (Ástralía) - English (Australia)',
    en_CA: 'Enska (Kanada) - English (Canada)',
    en_US: 'Enska (Bandaríkin) - English (USA)',
    en_GB: 'Enska (Bretland) - English (Great Britain)',
    en_PH: 'Enska (Filippseyjar) - English (Philippines)',
    es: 'Spænska - español',
    es_ES: 'Spænska (Spánn) - español (españa)',
    es_AR: 'Spænska (Argentína) - español (argentina)',
    et: 'Eistneska - eesti keel',
    fi: 'Finnska - Suomalainen',
    id: 'Indónesíska - bahasa Indonesia',
    is: 'Íslenska',
    de: 'Þýska - Deutsch',
    de_DE: 'Þýska (Þýskaland) - Deutsch (Deutschland)',
    de_AT: 'Þýska (Austurríki) - Deutsch (Österreich)',
    de_CH: 'Þýska (Sviss) - Deutsch (Schweiz)',
    lt: 'Litháíska - Lietuvis',
    lb: 'Lúxemborgska - Lëtzebuergesch',
    ms: 'Malajíska - Bahasa Melayu',
    ms_BN: 'Malajíska (Brúnei) - Bahasa Melayu (Brunei)',
    ms_MY: 'Malajíska (Malasía) - Bahasa Melayu (Malaysia)',
    nl: 'Hollenska',
    nl_BE: 'Hollenska (Belgía) - Nederlands (België)',
    nl_NL: 'Hollenska (Holland) - Nederlands (Nederland)',
    no: 'Norska - norsk',
    sv: 'Sænska - svenska',
    sv_SE: 'Sænska (Svíþjóð) - svenska (Sverige)',
    sv_FI: 'Sænska (Finnland) - svenska (Finland)',
    pl: 'Pólska - Polskie',
    pt: 'Portúgalska - Português',
    pt_BR: 'Portúgalska (Brasilía) - Português (Brasil)',
    pt_PT: 'Portúgalska (Portúgal) - Português (Portugal)',
    ro: 'Rúmenska - Română',
    ru: 'Rússneska - русский',
    el: 'Gríska - Ελληνικά',
    fr: 'Franska - français',
    da: 'Danska - Dansk',
    th: 'Tælenska - ไทย',
    fil: 'Tagalog - Tagalog',
    tr: 'Tyrkneska - Türk',
  },
  language: {
    language: 'Tungumál',
  },
  campaign: {
    archivedHeading: 'Safnvistaðar auglýsingaherferðir',
    archivedNoResults: 'Engar safnvistaðar auglýsingaherferðir fundust.',
    archivedShowLink: 'Sýna safnvistaðar auglýsingaherferðir',
    adRejected: {
      budgetUnchanged: 'Ekki er hægt að breyta fjármagni þar sem auglýsingin þín stóðst ekki skoðun.',
      productUnchanged: 'Ekki er hægt að breyta vörum þar sem auglýsingin þín stóðst ekki skoðun.',
      explanationGeneral:
        'Auglýsing #{{id}} stóðst ekki skoðun og lokað hefur verið á að auglýsingaherferð þín skili áhrifum. Sjáið frekari upplýsingar hér að neðan.',
      explanation:
        'Lagfærðu auglýsinguna þína og leggðu auglýsingaherferðina þína aftur fram til að hún geti skilað áhrifum miðað við fjármagn.',
      ADULT_CONTENT: {
        name: 'Fullorðinsefni',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún inniheldur eða leiðir til heimasíðu sem inniheldur efni sem er ætlað fullorðnum.',
      },
      BLANK_CONTENT: {
        name: 'Tómt efni',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún skilast auð. Gættu að því að allt nauðsynlegt efni hafi skilað sér rétt.',
      },
      MALFORMED_CLICK_THROUGH: {
        name: 'Gallað veffang (URL)',
        explanation: 'Auglýsingunni var hafnað vegna þess að veffang (URL) áfangastaðarins virkar ekki rétt.',
      },
      DRUG_RELATED_CONTENT: {
        name: 'Efni tengt ávana- og fíkniefnum',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún eða heimasíðan sem hún leiðir til auglýsir ávana- og/eða fíkniefnatengt efni eða vörur. Þetta gæti átt við tóbaksvörur, áfengi, læknislyf eða önnur lögleg eða ólögleg lyf.',
      },
      WEAPON_RELATED_CONTENT: {
        name: 'Vopnatengt efni',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún eða heimasíðan sem hún leiðir til auglýsir vopn eða vopnatengt efni. Þetta gæti átt við efni sem auglýsir hættulega hnífa, skotvopn, í- og aukahluti fyrir skotvopn og annað tengt efni.',
      },
      HATE_SPEECH_CONTENT: {
        name: 'Efni tengt hatursáróðri',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún eða heimasíðan sem hún leiðir til inniheldur efni tengt hatursáróðri. Þetta gæti átt við efni sem stuðlar að ofbeldi, áreitni, kynþáttahatri, hatri og umburðaleysi.',
      },
      LANDING_PAGE_REJECTED: {
        name: 'Heimasíðu hafnað',
        explanation:
          'Auglýsingunni var hafnað vegna þess að heimasíðan sem hún leiðir til var í lágum gæðaflokki eða erfið notkunar. Þetta gæti átt við heimasíður sem gera ákveðna eiginleika vafrans óvirka, sem innihalda lítilsgilt efni eða sem leiða til netfangs eða skjals, svo sem ljósmyndar, hljóðskjals, myndskeiðs eða ritskjals, sem krefst annars forrits til að opnast.',
      },
      OFFENSIVE_CONTENT: {
        name: 'Móðgandi efni',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún inniheldur eða leiðir til heimasíðu sem inniheldur móðgandi eða óviðeigandi efni. Gættu að því að auglýsingarnar þínar og heimasíður sýni einungis fjölskylduvænt efni.',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        name: 'Óviðunandi sjónbrellur',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún notar óviðunandi sjónbrellur. Þetta á einnig við um hreifimyndir (GIF) með hærri endurnýjunartíðni en 5 myndir á sekúndu.',
      },
      DECEPTIVE_CONTENT: {
        name: 'Villandi efni',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún inniheldur villandi staðhæfingar eða upplýsingar. Sem dæmi má nefna auglýsingar sem innihalda staðhæfingar sem eru vísindalega ómögulegar, sem ábyrgjast niðurstöður en bjóða ekki upp á skilarétt eða sem sýna meðmæli sem staðhæfa ákveðnar niðurstöður án þess að vísa í sannprófun þriðja aðila. AUglýsingum gæti líka verið hafnað ef grafíkin er hönnuð til að líkjast niðurhalstökkum, opinberum varúðartilkynningum stýrikerfis eða eiginleikum heimasíðu, svo sem valmyndum, leitarniðurstöðum eða öðru heimasíðuefni.',
      },
      GAMBLING_CONTENT: {
        name: 'Efni tengt fjárhættuspilum',
        explanation: 'Auglýsingunni var hafnað vegna þess að hún inniheldur efni tengt fjárhættuspilum.',
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        name: 'Óheimilt kosningatengt efni',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún inniheldur óheimilt efni tengt ríkisstjórnarkosningum eða fólki í ríkisstjórnarsæti.',
      },
      SHOCKING_CONTENT: {
        name: 'Sláandi efni',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún inniheldur sláandi efni svo sem gróft orðbragð, hroðalegt eða viðbjóðslegt myndefni eða auglýsingar sem eru hannaðar til að hneyksla fólk eða hræða það.',
      },
      MALWARE_CONTENT: {
        name: 'Spilliforrit',
        explanation:
          'Auglýsingunni var hafnað vegna þess að hún eða heimasíðan sem hún leiðir til inniheldur óæskilegt efni, svo sem spilliforrit.',
      },
    },
    anon: {
      signInText: 'Búa til auglýsingaherferð án þess að skrá sig inn',
      heading: 'Búðu til auglýsingaherferð hér að neðan.',
      beforeText:
        'Verið er að vista auglýsingaherferðina þína í þessum vafra. Áður en hægt er að skila auglýsingaherferðinni annað eða fá aðgang að henni annars staðar frá þarftu að&nbsp;',
      linkText: 'búa til aðgang og skrá þig inn',
      postText: '.',
    },
    layout: {
      title: 'Velja auglýsingahönnun',
      missing: 'Áður valið útlit er ekki í boði fyrir þennan auglýsingavalkost.',
      missingPreview: 'Velja þarf gilt útlit áður en hægt er að forskoða grafík.',
    },
    device: {
      title: 'Valkostir um marktæki',
      descriptionPrefix: 'Auglýsingin þín mun birtast í:&nbsp;',
      instructions: 'Veldu eitt eða fleiri tæki til að marka hvar auglýsingin þín muni birtast',
      any: 'hvaða tæki sem er',
    },
    domainName: {
      title: 'Valkostir um marklénnafn',
      descriptionPrefix: 'Auglýsingin þín mun birtast á:&nbsp;',
      instructions: 'Veldu eitt eða fleiri lén til að marka hvar auglýsingin þín muni birtast',
      any: 'hvaða lén sem er',
    },
    location: {
      title: 'Valkostir um markstaðsetningu',
      descriptionPrefix: 'Auglýsingin þín mun birtast á/í:&nbsp;',
      instructions: 'Veldu eina eða fleiri staðsetningu/ar til að marka hvar auglýsingin þín muni birtast',
      any: 'hvar sem er',
    },
    userSegment: {
      title: 'Valkostir um marknotendahóp',
      descriptionPrefix: 'Auglýsingin þín mun birtast:&nbsp;',
      instructions: 'Veldu einn eða fleiri notendahóp til að marka hverjum auglýsingin þín muni birtast',
      any: 'hvaða notanda sem er',
    },
    product: {
      title: 'Veldu auglýsingavalkost',
      adDesignChoice: '<strong>{{count}} auglýsingahannanir</strong> eru í boði í þessum stærðum:',
      cpm: '<strong>{{value}}</strong> á hverjar þúsund birtingar',
      minSpend: '<strong>{{value}}</strong> lágmarksverð',
      maxSpend: '<strong>{{value}}</strong> hámarks eyðsla',
    },
    ad: {
      title: 'Hannaðu auglýsinguna þína',
      defaultName: 'Grafík fyrir {{campaignName}}',
      layoutFirst: 'Veldu auglýsingahönnun áður en þú setur inn upplýsingar um auglýsinguna þína.',
      deletedAsset: 'Þessi hluti vísar í mynd sem hefur verið eytt. Hladdu upp nýrri mynd.',
      imageHint: '{{mediaTypes}}, Hámark: {{maxSize}}kb',
      imageHintOptional: '{{mediaTypes}}, Hámark: {{maxSize}}kb (Valfrjálst)',
      adSizeTitle: 'Stærð auglýsingar',
      create: 'Búðu til auglýsinguna þína',
      add: 'Bættu við annari auglýsingu',
      count: 'Auglýsing <strong>#{{count}}</strong>',
      maximum: 'Þú mátt bæta að hámarki þremur auglýsingum við auglýsingaherferðina þína',
      choiceOptional: '(Ekkert valið)',
      remove: 'Fjarlæga auglýsingu',
      runningPreviewTitle: '{{width}}&times;{{height}} auglýsingin þín',
      previewTitle: 'Forskoðun auglýsingar',
      previewText: 'Forskoðaðu hvernig auglýsingin lítur út hér að neðan.',
      border: 'Sýna brúnir á auglýsingu',
      previewWithExample: 'Þetta sýnishorn inniheldur dæmi um gögn í kynningartilgangi.',
      textCount: 'talning: {{count}}',
      textHintChars: '{{min}}&ndash;{{max}} tákn',
      textHintCharsOptional: '{{min}}&ndash;{{max}} tákn (Valfrjálst)',
      urlHint: 'URL',
      urlHintOptional: 'URL (Valfrjálst)',
      copyrightMessage: 'Ég lýsi því yfir að ekkert efni sem ég nota brjóti höfundarrétt eða vernd vörumerkis',
    },
    addNewCampaign: `
      <h3>Búðu til auglýsingaherferð til að koma skilaboðunum þínum á framfæri</h3>
      <p>Auglýsingaherferðir eru það sem skila auglýsingum í gegn um auglýsingaþjónustu okkar.</p>
      <p>Búðu til nýja auglýsingaherferð með takkanum hér að ofan og komdu auglýsingunum þínum til skila.</p>
      <p>Þú getur nálgast auglýsingaherferðirnar þínar hér eftir að þú ert búin/n að búa þær til og fylgst með þeim meðan þær eru að koma auglýsingunum þínum til skila.</p>
    `,
    campaign: 'Auglýsingaherferð',
    campaignPlural: 'Auglýsingaherferðir',
    copy: {
      copyText: 'Það tókst að gera afrit af þessari auglýsingaherferð.',
      copyTitle: 'Afrit gert af auglýsingaherferð',
      copyPartialText:
        'Það var gert afrit af þessari auglýsingaherferð en ekki var hægt að gera afrit af öllu auglýsingaefninu.',
      copyPartialTitle: 'Afrit gert af auglýsingaherferð en ekki öllu auglýsingaefni',
    },
    filter: {
      message: 'Mark eftir {{option}}:',
    },
    copyAfterwards: 'Fara í nýja auglýsingaherferð eftir gerð afrits',
    defaultName: 'Auglýsingaherferð fyrir {{productName}}',
    detailsTitle: 'Tilgreinið atriði auglýsingaherferðar',
    draftedHeading: 'Uppköst að auglýsingaherferðum',
    draftedNoResults: 'Engin uppköst að auglýsingaherferðum fundust.',
    endedHeading: 'Loknar auglýsingaherferðir',
    endedNoResults: 'Engar loknar auglýsingaherferðir fundust.',
    expectedImpressions: 'Samsvarar um það bil {{imps}} birtingum',
    cpmBoost:
      'Kostnaður á þúsund birtingar yfir {{minPrice}} lágmarkið eykur líkurnar á að allar kostaðar birtingar komist til skila',
    maxedOutCampaigns:
      '<p>Í augnablikinu geturðu ekki búið til fleiri auglýsingaherferðir.</p><p>Aðeins er hægt að gera uppdrög að að hámarki {{max}} auglýsingaherferðir í einu.</p><p>Safnvistaðu auglýsingaherferð eða láttu hana skila birtingum til að geta búið til nýja auglýsingaherferð.</p>',
    payment: {
      title: 'Borgun',
      missing: 'Engin borgun tengist þessari auglýsingaherferð.',
      cancelled: 'Hætt var við bókunina þína og auglýsingaherferðin þín var ekki sett til skoðunar.',
      infoComplete: '{{price}} borgun var gerð klukkan {{time}}.',
      refunded: '{{price}} endurgreiðsla var gerð klukkan {{time}}.',
      infoAuthorised: '{{price}} borgun var heimiluð klukkan {{time}}.',
      receiptLink: 'Kvittun fyrir <a href="{{link}}" target="_blank">borgun er ekki til staðar</a>.',
      registering: 'Skráset færslu...',
      reserveTitle: 'Bóka borgun og senda auglýsingaherferðina þína til skoðunar',
      reserveButton: 'Bóka borgun og senda auglýsingaherferðina þína til skoðunar',
      reserveParagraph1:
        'Við setjum <strong>hald á aðganginn þinn</strong> fyrir upphæð <strong>{{amount}}</strong>, sem gerir auglýsingaherferðinni þinni kleift að vera skoðuð og birt.',
      reserveParagraph2:
        'Þegar auglýsingaherferðin er á enda tökum við annað hvort {{amount}} af aðganginum þínum eða hlutfallslega upphæð miðað við raunbirtingar samanborið við væntanlegar <strong>{{imps}} birtingar</strong>.',
      failure:
        'Það kom upp villa við skrásetningu viðskipta. Reyndu aftur og hafðu samband við okkur ef vandamálið heldur áfram.',
      saveFirst: 'Þú þarft að vista breytingar áður en þú getur haldið áfram með bókun borgunar',
      waitingForRedirect:
        'Við erum að senda upplýsingar um færslu til {{paymentProvider}} og sendum þig áfram þangað innan skamms.',
      prepay: {
        detailsInfo: `
          <div class="small">
            <p>Auglýsingaherferðin þín verður birt eftir að bókun borgunar hefur verið gerð og innihald auglýsingaherferðinnar hefur verið skoðað.</p>
            <p>Borgun verður þá fyrst gerð þegar auglýsingaherferðin er á enda. Verð miðast við fjölda birtinga sem auglýsingaherferðin skilar en er aldrei hærra en það sem þú tókst fram. Lágmarksgjald að upphæð {{minimumFee}} gildir þó.</p>
            <p>Þegar auglýsingaherferðin þín hefur tekið enda og borgun hefur verið gerð verður búin til skýrsla um afköst sem og kvittun.</p>
            <p>Þú getur stöðvað birtingu auglýsingaherferðinnar hvenær sem er en {{minimumFee}} lágmarksverð á við jafnvel þótt engum birtingum hafi verið komið til skila.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Auglýsingaherferðin þín verður birt eftir að bókun borgunar hefur verið gerð og innihald auglýsingaherferðinnar hefur verið skoðað.</p>
            <p>Borgun verður þá fyrst gerð þegar auglýsingaherferðin er á enda. Verð miðast við fjölda birtinga sem auglýsingaherferðin skilar en er aldrei hærra en það sem þú tókst fram.</p>
            <p>Þegar auglýsingaherferðin þín hefur tekið enda og borgun hefur verið gerð verður búin til skýrsla um afköst sem og kvittun.</p>
            <p>Þú getur stöðvað birtingu auglýsingaherferðinnar hvenær sem er.</p>
          </div>
        `,
      },
      manual: {
        reserveTitle: 'Senda auglýsingaherferð til skoðunar',
        reserveButton: 'Senda auglýsingaherferð til skoðunar',
        reserveParagraph1: 'Áður en hægt er að birta auglýsingaherferðina þína þarf að skoða hana.',
        reserveParagraph2:
          'Þegar auglýsingaherferðin er á enda sendum við þér reikning upp á <strong>{{amount}}</strong> eða hlutfallslega upphæð miðað við raunbirtingar samanborið við væntanlegar <strong>{{imps}} birtingar</strong>.',
        detailsInfo: `
          <div class="small">
            <p>Auglýsingaherferðin verður birt eftir að innihald hennar hefur verið skoðað og samþykkt.</p>
            <p>Þegar auglýsingaherferðin þín hefur tekið enda verður þér sendur reikningur. Verð miðast við fjölda birtinga sem auglýsingaherferðin skilar en er aldrei hærra en það sem þú tókst fram. Lágmarksgjald að upphæð {{minimumFee}} gildir þó.</p>
            <p>Þegar auglýsingaherferðin þín hefur tekið enda verður búin til skýrsla um afköst.</p>
            <p>Þú getur stöðvað birtingu auglýsingaherferðinnar hvenær sem er en {{minimumFee}} lágmarksverð á við jafnvel þótt engum birtingum hafi verið komið til skila.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Auglýsingaherferðin verður birt eftir að innihald hennar hefur verið skoðað og samþykkt.</p>
            <p>Þegar auglýsingaherferðin þín hefur tekið enda verður þér sendur reikningur. Verð miðast við fjölda birtinga sem auglýsingaherferðin skilar en er aldrei hærra en það sem þú tókst fram.</p>
            <p>Þegar auglýsingaherferðin þín hefur tekið enda verður búin til skýrsla um afköst.</p>
            <p>Þú getur stöðvað birtingu auglýsingaherferðinnar hvenær sem er.</p>
          </div>
        `,
      },
      status: {
        CREATED: 'Búið til',
        NOT_AUTHORISED: 'Ekki heimilað',
        AUTHORISED: 'Heimilað',
        COMPLETED: 'Lokið',
        PAUSED: 'Sett í hvíld',
        CANCELLED: 'Hætt við',
      },
    },
    postPayment: {
      reserveParagraph1: 'Borgun fyrir auglýsingaherferð þína hefur verið heimiluð.',
      reserveParagraph2:
        'Ef þú ert ánægð/ur með auglýsingaherferðina þína geturðu sent hana til skoðunar hér að neðan.',
      saveFirst:
        'Þú þarft að vista breytingarnar áður en þú getur haldið áfram og sent auglýsingaherferðina til skoðunar.',
      failed:
        'Villa kom upp við sendingu auglýsingaherferðar til skoðunar. Reyndu aftur og ef vandamálið heldur áfram skaltu hafa samband við okkur.',
      submitButton: 'Senda auglýsingaherferð til skoðunar',
    },
    rejectedHeading: 'Hafnaðar auglýsingaherferðir',
    report: {
      title: 'Skýrsla',
      ungenerated: 'Skýrsla verður aðgengileg til að hlaða niður innan skamms.',
      generated: 'Skýrsla varðandi <a href="{{link}}" target="_blank">er aðgengileg fyrir þig til að hlaða niður</a>.',
    },
    runningHeading: 'Auglýsingaherferðir í skoðun og birtingu',
    runningNoResults: 'Engar auglýsingaherferðir í birtingu fundust.',
    running: {
      budgetHeading: 'Staða fjármagns',
      budgetPosition:
        '<strong>{{budgetSpent}}</strong> af <strong>{{budget}} fjármagni þínu</strong> hefur verið notað.',
      cancelledPaymentParagraph1: 'Hætt hefur verið við bókun borgunar.',
      cancelledPaymentParagraph2:
        'Ef þú vilt halda áfram með auglýsingaherferðina geturðu gert nýja færslu hér að neðan.',
      datesHeading: 'Dagsetningar birtinga',
      dates: '<strong>{{startDate}}</strong> til <strong>{{endDate}}</strong>',
      datesExplanation:
        'Auglýsingaherferðin þín er búin að vera í birtingu í <strong>{{days}} daga</strong> sem er <strong>{{percentage}}</strong> af áætluðum hámarkslífdaga hennar.',
      datesLeftExplanation:
        'Auglýsingaherferðin þín hættir í birtingu eftir <strong>{{days}} daga</strong> ef ráðstafaður fjöldi birtinga næst ekki.',
      datesSubmittedExplanation:
        'Auglýsingaherferðin þín verður í birtingu í <strong>{{days}} daga</strong> ef ráðstafaður fjöldi birtinga næst ekki.',
      impressions:
        'Auglýsingaherferðin þín hefur skilað <strong>{{imps}} bitingum</strong> sem er <strong>{{percentage}} af ráðstafaðum fjölda birtinga</strong>.',
      impressionsRemaining: 'Það á eftir að skila aum það bil <strong>{{imps}} birtingum</strong>.',
      statusPrefix: 'Staða auglýsingaherferðar: ',
      successfulPaymentParagraph1: 'Borgun hefur verið bókuð og auglýsingaherferðin þín hefur verið send til skoðunar.',
      successfulPaymentParagraph2: 'Þegar auglýsingaherferðin þín stenst skoðun mun hún birta auglýsingarnar þínar!',
      prepay: {
        stoppedParagraph1: 'Auglýsingaherferðin þín hefur tekið enda.',
        stoppedParagraph2: 'Ónýtt herferðarfjárhagsáætlun verður endurgreitt og skýrsla mynduð fyrir herferð þína.',
      },
      manual: {
        stoppedParagraph1: 'Auglýsingaherferðin þín hefur tekið enda.',
        stoppedParagraph2: 'Þér verður sendur reikningur og skýrsla verður búin til fyrir auglýsingaherferðina þína.',
      },
      submittedParagraph1: 'Auglýsingaherferðin þín hefur verið send til skoðunar.',
      submittedParagraph2: 'Þegar auglýsingaherferðin þín stenst skoðun mun hún birta auglýsingarnar þínar!',
      unexpectedPaymentParagraph1: 'Bókun borgunar gekk ekki sem skyldi.',
      unexpectedPaymentParagraph2:
        'Á þessu stigi er líklega best að taka afrit af þessari auglýsingaherferð og byrja ferlið við að búa til bókun borgunar upp á nýtt.',
    },
    save: {
      button: 'Vista vinnu',
      messageTitle: 'Auglýsingaherferð vistuð',
      messageText: 'Vistun auglýsingaherferðar tókst.',
    },
    smoothing: {
      title: 'Aðferð birtingar auglýsinga',
      true: 'Jafnt',
      trueExplanation: 'Auglýsingin verður birt jafnt og þétt yfir lífstíma auglýsingaherferðarinnar þinnar.',
      trueField:
        '<strong>Jafnt</strong> - skila ráðstöfuðum birtingum jafnt og þétt yfir lífstíma auglýsingaherferðarinnar',
      false: 'Fljótt',
      falseExplanation: 'Auglýsingin verður birt eins fljótt og auðið er.',
      falseField: '<strong>Fljótt</strong> - skila ráðstöfuðum birtingum eins fljótt og auðið er',
    },
    status: {
      STOPPED: 'Stöðvað',
      PROPOSED: 'Uppköst gerð',
      SUBMITTED: 'Í skoðun',
      NOT_READY: 'Þarfnast frekari skoðunar',
      READY: 'Tilbúið til birtingar',
      REJECTED: 'Hafnað',
      SUBMISSION_READY: 'Tilbúið til skilunar',
      RUNNING: 'Í birtingu',
      ENDED: 'Lokið',
    },
    statsHeading: 'Tölfræði um birtingu',
    stop: {
      button: 'Stöðva birtingu auglýsingaherferðar',
      cancelButton: 'Hætta við stöðvun auglýsingaherferðar',
      confirmButton: 'Staðfesta stöðvun auglýsingaherferðar',
      failParagraph1: 'Það kom upp villa og við gátum ekki stöðvað auglýsingaherferðina þína.',
      failParagraph2: 'Reyndu að stöðva auglýsingaherferðina aftur og láttu okkur vita ef vandamálið heldur áfram.',
      prepay: {
        info1:
          'Ekki er hægt að setja auglýsingaherferð aftur í gang eftir að hún hefur verið stöðvuð en hægt er að taka afrit af hvaða auglýsingaherferð sem er og birta sem nýja auglýsingaherferð.',
        info2:
          'Þegar herferð þinni er hætt verður ónotað fjárhagsáætlun endurgreitt. Athugið: lágmarksgjald að upphæð {{money}} á við.',
        info2NoMinFee: 'Þegar auglýsingaherferð er stöðvuð greiðir þú núverandi auglýsingafjármagn þitt.',
      },
      manual: {
        info1:
          'Ekki er hægt að setja auglýsingaherferð aftur í gang eftir að hún hefur verið stöðvuð en hægt er að taka afrit af hvaða auglýsingaherferð sem er og birta sem nýja auglýsingaherferð.',
        info2:
          'Þegar auglýsingaherferð er stöðvuð færðu sendan reikning upp á annað hvort lágmarksupphæðina {{money}} eða núverandi auglýsingafjármagn þitt, eftir því hvor upphæðin er hærri.',
        info2NoMinFee:
          'Þegar auglýsingaherferð er stöðvuð færðu sendan reikning upp á núverandi auglýsingafjármagn þitt.',
      },
      intro: 'Ef þú vilt einhvern tímann stöðva auglýsingaherferðina þína geturðu gert það hér að neðan.',
    },
    validationProblemsPresent: `
      <p>Villa kom upp við gildingu auglýsingaherferðarinnar þinnar.</p>
      <p>Þegar vandamálið hefur verið leyst getum við haldið áfram ferlinu til að birta auglýsingaherferðina þína.</p>
    `,
  },
  devices: {
    ACER: 'Acer',
    APPLE: 'Apple-tæki',
    ASUS: 'Asus',
    DESKTOP: 'Borðtölva',
    FUJITSU: 'Fujitsu',
    HTC: 'HTC',
    HUAWEI: 'Huawei',
    KYOCERA: 'Kyocera',
    LG: 'LG',
    MOTOROLA: 'Motorola',
    NEC: 'NEC',
    NOKIA: 'Nokia',
    ONE_PLUS: 'OnePlus',
    OPPO: 'Oppo',
    PANASONIC: 'Panasonic',
    RIM: 'RIM',
    SAMSUNG: 'Samsung',
    SHARP: 'Sharp',
    SONY_ERICSSON: 'Sony Ericsson',
    UNKNOWN: 'Óþekkt',
    VIVO: 'Vivo',
    XIAOMI: 'Xiaomi',
    ZTE: 'ZTE',
    ANDROID: 'Android',
    BLACKBERRY: 'Blackberry',
    BSD: 'BSD',
    IOS: 'iOS',
    LINUX: 'Linux',
    MACINTOSH: 'Apple Mac OS',
    SUNOS: 'SunOS',
    SYMBIAN: 'Symbian',
    WINDOWS: 'Windows',
    WINDOWS_PHONE: 'Windows-sími',
    CONSOLE: 'Leikjatölva',
    E_READER: 'Lestölva',
    MEDIA_HUB: 'Miðlamiðstöð',
    MOBILE: 'Snjallsími',
    SMART_WATCH: 'Snjallúr',
    TABLET: 'Spjaldtölva',
    TV: 'Sjónvarp',
  },
  emailPreferences: {
    CREATIVE_REJECTED: '<strong>Lagfæringar krafist:</strong> skoðandi krefst breytingar á auglýsingaherferð',
    REPORT_COMPLETE:
      '<strong>Skýrsla auglýsingaherferðar tilbúin:</strong> auglýsingaherferðin er á enda og skýrsla varðandi afköst hennar hefur verið búin til',
    LINE_ITEM_RUNNING:
      '<strong>Auglýsingaherferð er í birtingu:</strong> auglýsingaherferðin hefur staðist skoðun og er að skila birtingum',
  },
  media: {
    gif: 'GIF',
    png: 'PNG',
    svg: 'SVG',
    jpg: 'JPG',
    mp4: 'MP4',
    webm: 'WebM',
  },
  chart: {
    countType: {
      singular: 'Stefna',
      cumulative: 'Heild',
    },
    yAxisOption: {
      shared: 'Sameiginlegur y-ás',
      multiple: 'Margir y-ásar',
    },
    dateOption: {
      last1Hour: 'Síðasti klukkutími',
      last12Hours: 'Síðustu 12 klukkutímar',
      last7Days: 'Síðustu 7 dagar',
      last30Days: 'Síðustu 30 dagar',
      today: 'Í dag',
      yesterday: 'Í gær',
      campaignDays: 'Dagsetningar birtingar: {{startDate}}-{{endDate}}',
    },
  },
  layout: {
    fieldSummary: {
      image: 'mynd',
      imagePlural: 'myndir',
      url: 'veffang',
      urlPlural: 'vefföng',
      code: 'kóðasvæði',
      codePlural: 'kóðasvæði',
      text: 'textasvæði',
      textPlural: 'textasvæði',
      option: 'valkostur',
      optionPlural: 'valkostir',
      video: 'myndband',
      videoPlural: 'myndbönd',
    },
  },
  label: {
    copy: 'Afrit',
    copyLineItem: 'Taka afrit af auglýsingaherferð',
    paginationAllShown: 'Sýni allar <strong>{{total}}</strong> niðurstöður',
    paginationWithPages: 'Sýni <strong>{{start}}-{{end}}</strong> af <strong>{{total}}</strong> niðurstöðum',
    paginationLoading: 'Sýni <span class="fa fa-spin fa-cog"></span> niðurstöður',
  },
  report: {
    downloadXlsComma: 'Hlaða niður XLS-skjali (tugatákn: komma)',
    downloadXlsPoint: 'Hlaða niður XLS-skjali (tugatákn: punktur)',
    fieldCountPlural: '{{count}} reitir',
    fieldCount: '{{count}} reitur',
    report: 'Skýrsla',
    reportPlural: 'Skýrslur',
    noReport: `
      <h3>Engar skýrslur fundust</h3>
      <p>Þegar auglýsingaherferðin er á enda verður búin til skýrsla sem sýnir afköst hennar.</p>
      <p>Hér finnurðu lista yfir allar skýrslur sem verða gerðar fyrir allar auglýsingaherferðirnar þínar.</p>
    `,
    status: {
      PENDING: 'Í bið',
      PROCESSING: 'Í vinnslu',
      COMPLETED: 'Lokið',
      REJECTED: 'Hafnað',
      FAILED: 'Misheppnað',
      CANCELLED: 'Stöðvað',
      QUEUED: 'Í biðröð',
      TIMED_OUT: 'Rann út á tíma',
    },
    field: {
      rendered: 'Birtingar',
      visibles: 'Sýnilegar birtingar',
      visibility: 'Sýnileiki',
      viewables: 'Sjáanlegar birtingar',
      viewability: 'Sjáanleiki',
      uniqueUsers: 'Eintakir notendur',
      clicks: 'Smellir',
      ctr: 'Tíðni smella',
      cost: 'Kostnaður',
    },
  },
  errors: {
    standard: {
      unregistered: {
        title: 'Þú ert ekki með skráðan aðgang hérna',
        p1: 'Þú ert ekki skráð/ur á þessa auglýsingagátt.',
        p2: '',
        p2LinkText: 'Skráðu þig á þessa auglýsingagátt',
        p2Suffix: '&nbsp;áður en þú heldur áfram.',
        p3: 'Að öðrum kosti,&nbsp;',
        p3LinkText: 'skráðu þig inn á annan aðgang',
        p3Suffix: '.',
      },
      dataNotFoundTitle: 'Gögn vantar',
      dataNotFoundParagraph1: 'Við fundum engin gögn á eftirfarandi staðsetningu: <strong>{{link}}</strong>.',
      dataNotFoundParagraph2: 'Þetta gæti hafa gerst af því að undirliggjandi gögn eru ekki lengur aðgengileg þér.',
      dataNotFoundParagraph3: 'Veldu hvaða hlekk sem er í valmyndinni til að koma þér aftur á sporið.',
      pageNotFoundTitle: 'Síða fannst ekki',
      pageNotFoundParagraph1: 'Við gátum ekki fundið síðuna sem þú baðst um.',
      pageNotFoundParagraph2: 'Veldu hvaða hlekk sem er í valmyndinni til að koma þér aftur á sporið.',
      objectNotFoundTitle: 'Hlutur fannst ekki',
      objectNotFoundParagraph1:
        'Við gátum ekki fundið nein gögn fyrir eftirfarandi kennitölu: <strong>{{id}}</strong>.',
      objectNotFoundParagraph2: 'Þetta gæti hafa gerst af því að hluturinn er ekki lengur aðgengilegur þér.',
      objectNotFoundParagraph3: 'Veldu hvaða hlekk sem er í valmyndinni til að koma þér aftur á sporið.',
    },
    api: {
      stats:
        'Við gátum ekki sótt tölfræði frá forritaskilunum. Ef vandamálið heldur áfram skaltu hafa samband við þjónustumiðstöð.',
      title: 'Því miður virðist hafa komið upp vandamál',
      noConnection: {
        title: 'Tenging rofnaði',
        noConnection:
          '<p>Tenging rofnaði við bakþjón. Ef vandamálið heldur áfram skaltu hafa samband við þjónustumiðstöð.</p>',
      },
      generic:
        '<p>Við eigum í erfiðleikum með að tengjast bakkerfi okkar. Ef vandamálið heldur áfram skaltu hafa samband við þjónustumiðstöð.</p>',
      unauthorised: {
        title: 'Óheimil aðgerð',
        unauthorised: '<p>Þú hefur ekki heimild til að framkvæma aðgerðina sem þú baðst um.</p>',
      },
    },
  },
  field: {
    advertiserName: 'Nafn fyrirtækis',
    address1: 'Heimilisfang',
    selfServiceBudget: 'Fjármagn',
    city: 'Bær',
    confirmPassword: 'Staðfestu lykilorð',
    state: 'Fylki',
    postCode: 'Póstnúmer',
    country: 'Land',
    invoiceEmail: 'Netfang fyrir reikninga',
    phoneNumber: 'Símanúmer',
    email: 'Netfang',
    emailPreferences: 'Kjörstillingar netfangs',
    password: 'Lykilorð',
    locale: 'Staður',
    smoothing: 'Birtingarmáti auglýsinga',
    startDate: 'Byrjun',
    status: 'Staða',
    userState: 'Staða',
    vatId: 'Kennitala virðisaukaskatts',
    displayName: 'Skjánafn',
    displayNameHint: 'Skjánafn er notað til að vísa til þín í forritinu',
    endDate: 'Endir',
    executionStartTime: 'Dagsetning birtinga',
    name: 'Nafn',
    cpm: 'Verð á þúsund birtingar (CPM)',
    required: 'Nauðsynlegt',
    malformedEmail: 'Netfang þarf að vera gilt',
    optional: 'Valkvætt',
  },
  settings: {
    settingsPlural: 'Stillingar',
    about: 'Um',
    signOut: 'Skrá út',
  },
  signIn: {
    accountLocked:
      'Aðganginum þínum hefur verið læst vegna of margra misheppnaðra tilrauna til að skrá þig inn. Þér hefur verið sendur tölvupóstur sem þú getur notað til þess að endurstilla lykilorðið þitt, aflæsa aðganginum þínum og skrá þig inn.',
    alreadyUser: 'Ertu nú þegar notandi?',
    confirmPasswordMismatch: 'Lykilorðin passa ekki. Gættu þess að skrifa sama lykilorðið í bæði skiptin.',
    differentAccount: 'Skrá þig inn á annan aðgang',
    emailFirst:
      'Þú þarft að setja inn netfangið sem er tengt við aðganginn þinn áður en þú getur endurstillt lykilorðið.',
    emailRequired: 'Við þurfum að fá netfangið sem er tengt við aðganginn þinn áður en við getum skráð þig inn.',
    expiredLinkExplanation:
      'Þú virðist hafa smellt á ógildan eða útrunninn hlekk til að endurstilla lykilorð. Reyndu að endurstilla lykilorðið þitt aftur.',
    forgotPassword: 'Gleymt lykilorð?',
    humanConfirmationFailure:
      'Það er vandamál við að staðfesta skráningu aðgangs. Reyndu aftur og láttu okkur vita ef vandamálið heldur áfram.',
    nameRequired: 'Við þurfum nafn til að geta skráð þig sem nýjan notanda.',
    passwordResetTooMuchHeading: 'Lykilorð endurstillt of oft',
    passwordResetTooMuchExplanation:
      'Þú hefur reynt að endurstilla lykilorðið þitt of oft á of stuttum tíma. Þú getur ekki endurstillt lykilorðið á næstunni.',
    passwordResetEmailSentHeading: 'Tölvupóstur sendur varðandi endurstillingu lykilorðs',
    passwordResetEmailSentExplanation:
      'Skoðaðu tölvupóstinn þinn til að fá hlekk til að endurstilla lykilorðið þitt. Ef tölvupósturinn skilar sér ekki innan fárra mínútna skaltu kíkja í ruslpósthólfið.',
    passwordHint: 'Lykilorð þarf að vera 8 stafir eða lengra',
    passwordLinkExpired:
      'Hlekkur til að endurstilla lykilorð hefur runnið út. Settu inn netfangið þitt til að búa til nýjan hlekk.',
    passwordRequired: 'Útvega lykilorð til að skrá þig inn.',
    passwordSimple: 'Of auðvelt er að giska á útvegað lykilorð. Það ætti að vera eitthvað óalgengara.',
    regError: 'Villa við staðfestingu. Sjáið nánar hér að ofan.',
    registerAsUserHeading: 'Skrá sem notanda',
    registerAsUserButton: 'Skrá notanda',
    registerEmailRequired: 'Við þurfum netfang til að skrá þig sem nýjan notanda.',
    registerNewUser: 'Skrá sem nýjan notanda',
    registerSuccessHighlight: 'Það tókst að skrá þig sem nýjan notanda!',
    registerSuccessParagraph1: 'Þér hefur verið sendur tölvupóstur með frekari upplýsingum.',
    registerSuccessParagraph2:
      'Smelltu á hlekkinn í tölvupóstinum þínum til að búa til lykilorð fyrir aðganginn þinn og skrá þig inn.',
    registerTerms: `Með því að velja <strong>Skrá notanda</strong> staðfestir þú að hafa lesið og samþykkt <a target="_blank" href="https://adnuntius.com/resources/tc-self-service-advertising/">notkunarskilmálana</a>.`,
    resetPasswordHeading: 'Endurstilla lykilorð',
    resetPasswordButton: 'Senda tölvupóst til að endurstilla lykilorð',
    resetPasswordInfo: 'Settu inn netfangið þitt og við sendum þér hlekk til að endurstilla lykilorðið þitt.',
    signInButton: 'Skrá inn',
    signInHeading: 'Skrá inn',
    updatePasswordHeading: 'Uppfæra lykilorð',
    updatePasswordButton: 'Uppfæra lykilorð',
  },
  adBlockerParagraph1:
    'Við höfum tekið eftir því að þú hefur sett upp hugbúnað sem lokar á auglýsingar. Slíkur hugbúnaður hamlar virkni forritsins.',
  adBlockerParagraph2: 'Slökktu á hugbúnaðinum til að vera viss um að forritið virki sem skyldi.',
  adBlockerTurnOff: 'Slökktu á þessari viðvörun',
  validation: {
    required: 'Nauðsynlegt',
    pattern: 'Texti þarf að vera {{minLength}}-{{maxLength}} stafir á lengd.',
    maxSize: 'Skjalið sem var hlaðið upp er {{actual}}kb á stærð sem er stærra en {{maximum}}kb hámarkið',
    fileType: 'Skjalið þarf að vera af einni af eftirfarandi gerðum: {{allowedTypes}}',
    minPrice: 'Lágmarksgildið er {{minValue}}',
    maxPrice: 'Hámarksgildið er {{maxValue}}',
    number: 'Ógild tala',
    url: 'Veffangið er ekki á gildu formi.',
    parse: 'Innihaldið passar ekki við snið sem búist var við',
    endDateStartDate: 'Lokadagsetning þarf að vera á eftir upphafsdagsetningu.',
    email: 'Þarf gilt netfang',
    error: {
      validation: '<strong>Ógild umsókn:</strong> Gættu að öllum atriðum og reyndu aftur.',
      'must.be.greater.than.or.equal': '{{value}} þarf að vera hærra en {{minValue}}.',
      'non.empty': 'Nauðsynlegt',
      'auth.failed': 'Við gátum ekki skráð þig inn. Kennin passa ekki við neinn aðgang.',
      'sign.in.blocked': 'Þú ert ekki skráður notandi hér. Skráðu þig inn á {{uiAddress}}.',
    },
    warning: {
      'creative.invalid.product.dimensions': 'Velja þarf gilda stærð fyrir auglýsinguna',
      'lineItem.start.date.before.end.date':
        'Upphafsdagsetning auglýsingaherferðar þarf að vera á undan lokadagsetningu hennar',
      'lineItem.end.date.before.today':
        'Lokadagsetning auglýsingaherferðar þarf að vera eftir núverandi dag- og tímasetningu',
      'lineItem.invalid.budget.enddate': 'Auglýsingaherferðin þarf að taka enda innan 100 daga',
      'payment.gateway.error': 'Vandamálið hefur með tengingu okkar að gera.',
    },
  },
};
