<div [formGroup]="registerForm" class="has-validation">
  <ngb-alert *ngIf="isInvalid()" type='danger' [dismissible]="false">{{ 'signIn.humanConfirmationFailure' | translate }}</ngb-alert>
  <ngb-alert *ngIf='errorKey' type='danger' [dismissible]='false'>{{ errorKey | translate }}</ngb-alert>
  <input type="text" formControlName="hCaptchaResponse" [hidden]="true"/>
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column has-validation">
      <div #captchaContainer class="h-captcha" [ngClass]="{ 'is-invalid': isInvalid() }"></div>
    </div>
  </div>
</div>
