import { Injectable } from '@angular/core';
import { EphemeralLocalObjectStore } from '@shared/storage/local/ephemeral-local-object-store';
import { CacheRegistry } from '@shared/storage/cache-registry.service';
import { UserProfile } from '../@models/users/profile/user-profile.model';
import { Observable, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProfileResource } from '@app/@core/@rest/users/user-profile-resource.service';
import { User } from '@app/@core/@models/users/user.model';
import {updateDelta} from '@shared/utils/update-utils';
import {AuthUserService} from '@app/@core/auth/auth-user.service';

/**
 * Loads and caches UserProfile in local storage, flushed on sign-in / sign-out.
 */
@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  private readonly localUserProfile: EphemeralLocalObjectStore<UserProfile>;

  constructor(
    cacheRegistry: CacheRegistry,
    private authUserService: AuthUserService,
    private userProfileResource: UserProfileResource
  ) {
    this.localUserProfile = new EphemeralLocalObjectStore<UserProfile>(cacheRegistry, 'user.profile');
  }

  getUserProfile(): Observable<UserProfile> {
    const userProfile = this.localUserProfile.getObject();
    if (userProfile) {
      return of(userProfile);
    } else {
      return this.getUserProfileFromServer();
    }
  }

  updateUserProfile(user: User, update: UserProfile): Observable<UserProfile> {
    const serverUpdate: UserProfile = updateDelta(this.localUserProfile.getObject() || {}, update);
    return this.userProfileResource.update(user.id, serverUpdate)
      .pipe(
        tap((profile) => this.localUserProfile.setObject(profile))
      );
  }

  private getUserProfileFromServer(): Observable<UserProfile> {
    return this.authUserService.getAuthUser().pipe(
      map((authUser) => authUser.profile),
      tap((profile) => this.localUserProfile.setObject(profile))
    );
  }
}
