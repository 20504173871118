import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@app/@core/@config/config.service';
import {AuthUserPermsService} from '@app/@core/users/auth-user-perms.service';
import {SignedInContextService} from '@app/@shells/signed-in-shell/signed-in-context.service';
import {AuthUserPerms} from '@app/@core/@models/auth/authenticated-user.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  // Inputs from signed-in shell
  authUserPerms!: AuthUserPerms;
  signedInContextSubscription!: Subscription;

  constructor(
    private authUserPermsService: AuthUserPermsService,
    private router: Router,
    private configService: ConfigService,
    private signedInContextService: SignedInContextService
  ) {}

  ngOnInit(): void {
    this.signedInContextSubscription = this.signedInContextService.context$().subscribe(context => {
      this.authUserPerms = context.authUserPerms;
    })
    const isSelfService = this.isSelfService();
    if (isSelfService) {
      this.router.navigate([this.configService.getUrl('/campaigns')], { replaceUrl: true });
    }
  }

  ngOnDestroy(): void {
    this.signedInContextSubscription.unsubscribe();
  }

  isSelfService() {
    return this.authUserPermsService.isSelfService(this.authUserPerms);
  }

}
