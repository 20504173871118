import { Injectable } from '@angular/core';
import { NetworkData, REGISTERED_URLS } from '@app/@core/@config/registered-urls-data';
import { ApiConfig } from '@app/@core/@config/api-config';
import _filter from 'lodash-es/filter';
import _trim from 'lodash-es/trim';
import _find from 'lodash-es/find';
import { Uuid } from '@shared/utils/uuid';

const BROWSER_TEST_URL_PREFIX = 'browser-test';
const BROWSER_TEST_ENVS = ['localhost', 'coreuidist'];

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor() {}

  getNetworkData(): NetworkData | undefined {
    return ConfigService.getRegisteredUrl();
  }

  networkKnownFromDomain(): boolean {
    const registeredUrl = ConfigService.getRegisteredUrl();
    return !!registeredUrl && (window.location.host === registeredUrl.domain);
  }

  getUrl(suffix: string): string {
    const registeredUrl = ConfigService.getRegisteredUrl();
    return registeredUrl ? window.location.host === registeredUrl.domain
        ? suffix : '/' + registeredUrl.urlSection + suffix : 'invalid-url-to-redirect-to-404' + Uuid.generate();
  }

  getVersionedUri(path: string | Array<string>): string {
    return ApiConfig.obtain().getVersionedUri(path);
  }

  getUri(path: string) {
    return ApiConfig.obtain().getUri(path);
  }

  getSiteKey(): string {
    return ApiConfig.obtain().getSiteKey();
  }

  apiConfig(): ApiConfig {
    return ApiConfig.obtain();
  }

  private static getBrowserTestData(idSuffix: string): NetworkData {
    return {
      id: 'puppet-ss-network' + idSuffix,
      interface: 'BROWSER_TEST' + idSuffix,
      urlSection: BROWSER_TEST_URL_PREFIX + idSuffix,
      env: BROWSER_TEST_ENVS,
      hasAnonymousCampaigns: true,
      showCoupons: true,
    };
  }

  private static getRegisteredUrl(): NetworkData | undefined {
    const url: string = window.location.pathname;
    const domain: string = window.location.host;
    const envId = ApiConfig.obtain().getEnv();
    if (url.indexOf('/' + BROWSER_TEST_URL_PREFIX) === 0 && BROWSER_TEST_ENVS.indexOf(envId) > -1) {
      const slashIndex = url.indexOf('/', 1);
      const browserIdSuffix = url
        .substring(1, slashIndex > -1 ? slashIndex : url.length)
        .replace(BROWSER_TEST_URL_PREFIX, '');
      return this.getBrowserTestData(browserIdSuffix);
    }

    const urlSections = _filter(url.split('/'), function (url) {
      return _trim(url);
    });
    const networkPath = urlSections.length > 0 ? urlSections[0] : '---invalid---';
    return <NetworkData>_find(REGISTERED_URLS, (v) => {
      return (domain === v.domain || networkPath === v.urlSection) && v.env.indexOf(envId) > -1;
    });
  }

}
