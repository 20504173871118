<form [formGroup]='campaignDraft.campaignForm' (ngSubmit)="campaignDraft.doSave('general.saveSuccess')"
      class='mb-1' novalidate>
  <ng-container *ngIf="campaignDraft.rejectedAdverts() as rejectedNums">
    <ngb-alert *ngIf="rejectedNums.length" type="danger" [dismissible]="false">
      <div *ngFor="let num of rejectedNums">
        {{ 'campaign.adRejected.explanationGeneral' | translate : {'id': num} }}
      </div>
    </ngb-alert>
  </ng-container>
  <ng-container *ngIf="campaignDraft.products.length > 1">
    <h2>{{ 'campaign.product.title' | translate }}</h2>
    <ngb-alert *ngIf='campaignDraft.wasProductDeleted()' [dismissible]='false'
               [innerHTML]="'campaign.product.deleted' | translate"></ngb-alert>
    <div class="loading-overlay-parent">
      <div *ngIf="showLoadingOverlays()" class="loading-overlay" [ngStyle]="{'cursor':'default'}"></div>
      <app-product-selection [campaignDraft]="campaignDraft"></app-product-selection>
    </div>
  </ng-container>
  <div class="loading-overlay-parent">
    <div *ngIf="showLoadingOverlays()" class="loading-overlay"></div>
    <ng-container *ngIf='campaignDraft.getProduct() as product'>
      <ng-container *ngFor="let adDraft of campaignDraft.advertDrafts">
        <app-creative-editor [advertDraft]="adDraft" [showLoadingOverlays]="showLoadingOverlays()"></app-creative-editor>
        <hr class='subDivider'>
        <div class="loading-overlay-parent">
          <div *ngIf="showLoadingOverlays()" class="loading-overlay"></div>
          <div class='row justify-content-end mb-3'>
            <div class='col-auto'>
              <app-remove-creative [advertDraft]="adDraft"></app-remove-creative>
              <app-add-creative [advertDraft]="adDraft"></app-add-creative>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf='campaignDraft.numVisibleCreatives(campaignDraft.campaignForm) > 2'
           class='row justify-content-end mb-3 small'>
        {{ 'campaign.ad.maximum' | translate }}
      </div>
      <hr>
      <div class="loading-overlay-parent">
        <div *ngIf="showLoadingOverlays()" class="loading-overlay"></div>
        <app-campaign-details [campaignDraft]="campaignDraft"></app-campaign-details>
        <ngb-alert
          *ngIf='campaignDraft.lineItemReview.lineItem.objectState === "ACTIVE" && !showSubmitCampaignComponent() && campaignDraft.campaignForm.pristine'
          [dismissible]='false'>
          <div [innerHTML]="'campaign.validationProblemsPresent' | translate"></div>
        </ngb-alert>
        <app-submit-campaign *ngIf="showSubmitCampaignComponent()" [campaignDraft]="campaignDraft"
                             (isSubmitting)="isSubmitting=$event">
        </app-submit-campaign>
        <ngb-alert *ngIf="campaignDraft.anon" type='info' [dismissible]="false">
          <span [innerHTML]="'campaign.anon.beforeText' | translate"></span><a
            [routerLink]="configService.getUrl('/sign-in/register')"
            [innerHTML]="'campaign.anon.linkText' | translate"
          ></a><span [innerHTML]="'campaign.anon.postText' | translate"></span>
        </ngb-alert>
        <div class='text-center'>
          <button type='submit' name='save-your-progress' class='btn btn-outline-dark btnSaveCampaign'
                  [disabled]='campaignDraft.campaignForm.pristine'>
            {{ 'campaign.save.button' | translate }}
          </button>
          <button type='button' class='btn btn-link' (click)='campaignDraft.onUndo()'
                  [disabled]='campaignDraft.campaignForm.pristine'>
            {{ 'button.undo' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf='createMissingCreative$ | async as result'>
    <ngb-alert *ngIf="result.errorKey" type='danger' [dismissible]="false">{{ result.errorKey | translate }}
    </ngb-alert>
  </ng-container>
</form>


