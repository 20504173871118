import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-masquerade-banner[masqueraderUsername][username]',
  templateUrl: './masquerade-banner.component.html',
  styleUrls: ['./masquerade-banner.component.scss']
})
export class MasqueradeBannerComponent implements OnInit {
  @Input() masqueraderUsername!: string;
  @Input() username!: string;
  constructor() { }

  ngOnInit(): void {
  }

}

