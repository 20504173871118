import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { AdvertDraft } from '@app/@core/advertising/campaign/advert/advert-draft';

@Component({
  selector: 'app-remove-creative[advertDraft]',
  templateUrl: './remove-creative.component.html',
  styleUrls: ['./remove-creative.component.scss'],
})
export class RemoveCreativeComponent implements OnInit, OnChanges {
  @Input() advertDraft!: AdvertDraft;
  isDisabled = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.isDisabled = this.advertDraft.campaignDraft.numVisibleCreatives(this.advertDraft.campaignDraft.campaignForm) === 1;
  }

  onClick() {
    this.advertDraft.removeAdvert();
  }
}
