import {Component, Input, OnInit} from '@angular/core';
import {RegisterForm} from '@app/sign-in/register/@form/register-form';
import {Locale} from '@app/@core/@models/common/locale';
import {LocaleMappingService} from '@app/@i18n/services/locale-mapping.service';

@Component({
  selector: 'app-locale-field[registerForm]',
  templateUrl: './locale-field.component.html',
  styleUrls: ['./locale-field.component.scss']
})
export class LocaleFieldComponent implements OnInit {
  @Input() registerForm!: RegisterForm;
  locales!: Locale[]

  constructor(private localeMappingService: LocaleMappingService) {
  }

  ngOnInit(): void {
    this.locales = this.localeMappingService.getSupportedJavaLocales()
  }

}
