/**
 * Copyright © 2023 Adnuntius AS.
 */

export const TRANSLATIONS_EN = {
  adn: {
    copyright: 'Copyright © 2023 Adnuntius AS',
    releaseId: 'Release ID: {{id}}',
    poweredBy: 'Powered by Adnuntius AS'
  },
  grammar: {
    listSeparator: ', ',
    titleSeparator: ': ',
    booleanOr: 'OR'
  },
  general: {
    new: 'New',
    saveSuccess: 'Your updates were saved successfully.',
    timezone: 'All dates and times are in the {{timezone}} timezone.'
  },
  timezone: {
  },
  button: {
    upload: {
      asset: 'Upload',
      drop: 'Drop a file here or use the upload button'
    },
    archive: 'Archive',
    activate: 'Activate',
    applyCustomRange: 'Apply custom range',
    cancel: 'Cancel',
    clear: 'Clear',
    close: 'Close',
    newCampaign: 'Create New Campaign',
    save: 'Save',
    undo: 'Undo',
    today: 'Today',
  },
  locale: {
    ar: 'Arabic - عربى',
    ar_JO: 'Arabic (Jordan) - العربية (الأردن)',
    ar_EG: 'Arabic (Egypt) - العربية (مصر)',
    ar_AE: 'Arabic (UAE) - العربية (الإمارات العربية المتحدة)',
    my: 'Burmese - ဗမာ',
    en: 'English',
    en_AU: 'English (Australia)',
    en_CA: 'English (Canada)',
    en_US: 'English (USA)',
    en_GB: 'English (Great Britain)',
    en_PH: 'English (Philippines)',
    es: 'Spanish - español',
    es_ES: 'Spanish (Spain) - español (españa)',
    es_AR: 'Spanish (Argentina) - español (argentina)',
    et: 'Estonian - eesti keel',
    fi: 'Finnish - Suomalainen',
    id: 'Indonesian - bahasa Indonesia',
    is: 'Icelandic - íslensku',
    de: 'German - Deutsch',
    de_DE: 'German (Germany) - Deutsch (Deutschland)',
    de_AT: 'German (Austria) - Deutsch (Österreich)',
    de_CH: 'German (Switzerland) - Deutsch (Schweiz)',
    lt: 'Lithuanian - Lietuvis',
    lb: 'Luxembourgish - Lëtzebuergesch',
    ms: 'Malay - Bahasa Melayu',
    ms_BN: 'Malay (Brunei) - Bahasa Melayu (Brunei)',
    ms_MY: 'Malay (Malaysia) - Bahasa Melayu (Malaysia)',
    nl: 'Dutch',
    nl_BE: 'Dutch (Belgium) - Nederlands (België)',
    nl_NL: 'Dutch (Netherlands) - Nederlands (Nederland)',
    no: 'Norwegian - norsk',
    sv: 'Swedish - svenska',
    sv_SE: 'Swedish (Sweden) - svenska (Sverige)',
    sv_FI: 'Swedish (Finland) - svenska (Finland)',
    pl: 'Polish - Polskie',
    pt: 'Portuguese - Português',
    pt_BR: 'Portuguese (Brazil) - Português (Brasil)',
    pt_PT: 'Portuguese (Portugal) - Português (Portugal)',
    ro: 'Romanian - Română',
    ru: 'Russian - русский',
    el: 'Greek - Ελληνικά',
    fr: 'French - français',
    da: 'Danish - Dansk',
    th: 'Thai - ไทย',
    fil: 'Tagalog - Tagalog',
    tr: 'Turkish - Türk'
  },
  language: {
    text: 'Language:',
    is: 'Íslenska',
    isShort: 'IS',
    en: 'English',
    enShort: 'EN',
    el: 'Ελληνικά',
    elShort: 'EΛ',
    es: 'Español',
    esShort: 'ES',
    id:'Bahasa',
    idShort: 'ID',
    de: 'Deutsch',
    deShort: 'DE',
    th: 'ไทย',
    thShort: 'ไทย',
    fr: 'Français',
    frShort: 'FR',
    no: 'Norsk',
    noShort: 'NO'
  },
  campaign: {
    archivedHeading: 'Archived Campaigns',
    archivedNoResults: 'No archived campaigns found.',
    archivedShowLink: 'Show archived campaigns',
    adRejected: {
      budgetUnchanged: 'Changing your budget is disabled when your advertising has failed its review.',
      productUnchanged: 'Changing products is disabled when your advertising has failed its review.',
      explanationGeneral: 'Advertisement #{{id}} failed review and your campaign is blocked from delivering impressions. See below for more details.',
      explanation: 'Adjust your ad and resubmit your campaign for review to have it deliver your budgeted impressions.',
      ADULT_CONTENT: {
        name: 'Adult Content',
        explanation: 'The ad was rejected because it contains or leads to a landing page that contains adult-oriented content.'
      },
      BLANK_CONTENT: {
        name: 'Blank Content',
        explanation: 'The ad was rejected because it renders as blank. Please ensure that the required content have been provided correctly.'
      },
      MALFORMED_CLICK_THROUGH: {
        name: 'Malformed Destination URL',
        explanation: 'The ad was rejected because the click destination URL does not function properly.'
      },
      DRUG_RELATED_CONTENT: {
        name: 'Drug-Related Content',
        explanation: 'The ad was rejected because it or its landing page promotes drug-related content or products. This may include tobacco, alcohol, pharmaceutical or other legal or illegal drugs.'
      },
      WEAPON_RELATED_CONTENT: {
        name: 'Weapon-Related Content',
        explanation: 'The ad was rejected because it or its landing page promotes weapons or weapon-related content. This can include content that promotes dangerous knives, guns, gun parts and other related content.'
      },
      HATE_SPEECH_CONTENT: {
        name: 'Hate-Speech Content',
        explanation: 'The ad was rejected because it contains or leads to a landing page that contains hate speech content. This may include content promoting violence, harassment, racism, hatred and intolerance.'
      },
      LANDING_PAGE_REJECTED: {
        name: 'Landing Page Rejected',
        explanation: 'The ad was rejected because the landing page was low quality or difficult to interact with. This can include pages that disable browser navigation functions, include low value content, or lead to an email address or file, such as image, audio, video, or document, that requires an additional application to open.'
      },
      OFFENSIVE_CONTENT: {
        name: 'Offensive Content',
        explanation: 'The ad was rejected because it contains or leads to a landing page that contains offensive or inappropriate content. Ensure that your ads and landing pages only show family safe content.',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        name: 'Unacceptable Visual Effects',
        explanation: 'The ad was rejected because it uses unacceptable visual effects. This includes animated GIFs with a frame rate higher than 5 frames per second.'
      },
      DECEPTIVE_CONTENT: {
        name: 'Deceptive Content',
        explanation: 'The ad was rejected because it contains misleading claims or information. Examples include ads that make scientifically impossible claims, offer guaranteed results without a refund policy, or show testimonials claiming specific results without a link to third-party verification. Ads may also be rejected if the creative is designed to look like software download buttons, official system warnings, or webpage features like menus, search results, or other site content.'
      },
      GAMBLING_CONTENT: {
        name: 'Gambling Content',
        explanation: 'The ad was rejected because it contains gambling-related content.'
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        name: 'Prohibited Election-Related Content',
        explanation: 'The ad is rejected because it contains prohibited content related to government elections or currently elected government officeholders.'
      },
      SHOCKING_CONTENT: {
        name: 'Shocking Content',
        explanation: 'The ad was rejected because it contains shocking content such as profane language, gruesome or disgusting imagery, or promotions that are designed to shock or scare.'
      },
      MALWARE_CONTENT: {
        name: 'Malware Content',
        explanation: 'The ad was rejected because it or its landing page contains or leads to unacceptable content such as malware.'
      },
      POOR_QUALITY: {
        name: 'Poor Quality',
        explanation: 'The ad was rejected due to poor quality. Possible reasons for this can be graphics with insufficient resolution, unclear and washed-out imagery or poor legibility of content.'
      }
    },
    anon: {
      signInText: 'Create a campaign now!',
      heading: 'Create a campaign below.',
      beforeText: 'Your campaign details are being saved to your current browser. Before your campaign can be delivered and accessed elsewhere, you will need to&nbsp;',
      linkText: 'create an account and sign in',
      postText: '.',
      savingTitle: 'We are in the process of saving your campaign to our database',
      savingHtml: 'Your campaign should be available shortly. <span class="fa fa-cog fa-spin"></span>',
      saveTitle: 'Your campaign has been saved to our database',
      saveWithMinorError: 'We did have some problems trying to save everything to our database, but now that you have signed in, that should not be a problem as you continue.',
      saveCampaignLinkPrefix: 'Take a look at ',
      saveCampaignLinkText: 'your saved campaign and submit it for review',
      saveCampaignLinkPostfix: '.',
      failSaveTitle: 'Your campaign could not be saved to our database.',
      failSaveCampaignLinkPrefix: '',
      failSaveCampaignLinkText: 'Create a campaign directly with our database',
      failSaveCampaignLinkPostfix: ' now that you have signed in.',
      username: 'Anonymous',
      signUp: 'Sign Up'
    },
    layout: {
      title: 'Select an Ad Design',
      missing: 'The layout you selected previously is not available for the current advertising option.',
      missingPreview: "A valid layout needs to be selected before a creative can be previewed."
    },
    site: {
      title: 'Site Targeting Options',
      descriptionPrefix: 'Your ad will show on:&nbsp;',
      instructions: 'Specify one or more sites to target where your ad will appear',
      any: 'any site',
    },
    device: {
      title: 'Device Targeting Options',
      descriptionPrefix: 'Your ad will show in:&nbsp;',
      instructions: 'Specify one or more device options to target where your ad will appear',
      any: 'any device',
    },
    domainName: {
      title: 'Domain Name Targeting Options',
      descriptionPrefix: 'Your ad will show in:&nbsp;',
      instructions: 'Specify one or more domains to target where your ad will appear',
      any: 'any available domain'
    },
    location: {
      title: 'Location Targeting Options',
      descriptionPrefix: 'Your ad will show in:&nbsp;',
      instructions: 'Specify one or more locations to target where your ad will appear',
      moreGranular: 'Show more granular locations for targeting',
      any: 'any location'
    },
    kvs: {
      title: 'Section Targeting Options',
      descriptionPrefix: 'Your ad will show in:&nbsp;',
      instructions: 'Specify one or more sections to target where your ad will appear',
      any: 'any section'
    },
    category: {
      title: 'Category Targeting Options',
      descriptionPrefix: 'Your ad will show for:&nbsp;',
      instructions: 'Specify one or more categories to target where your ad will appear',
      any: 'any category',
      more: 'Show more results',
      noResults: 'No results found',
      searchOptions: 'Search through options'
    },
    userSegment: {
      title: 'User Segment Targeting Options',
      descriptionPrefix: 'Your ad will show to:&nbsp;',
      instructions: 'Specify one or more user segments to target where your ad will appear',
      any: 'any user'
    },
    product: {
      title: 'Select an Advertising Option',
      adDesignChoice: '<strong>{{count}} ad designs</strong> available at these sizes:',
      cpm: '<strong>{{value}}</strong> per thousand impressions',
      minSpend: '<strong>{{value}}</strong> minimum spend',
      maxSpend: '<strong>{{value}}</strong> maximum spend',
      deleted: 'The previously selected advertising option is no longer available. Please choose another to proceed.',
      examples: ''
    },
    ad: {
      title: 'Design Your Ad',
      defaultName: 'Creative for {{campaignName}}',
      layoutFirst: 'Select an ad design before entering your ad\'s details.',
      deletedAsset: 'This component references a deleted image. Upload a new one.',
      imageHint: '{{mediaTypes}}, Max: {{maxSize}}kb',
      imageHintOptional: '{{mediaTypes}}, Max: {{maxSize}}kb (Optional)',
      adSizeTitle: 'Ad Size',
      create: 'Create Your Advertisement',
      createNumbered: 'Create Your Advertisement #{{count}}',
      add: 'Add Another Advertisement',
      count: 'Advertisement <strong>#{{count}}</strong>',
      maximum: 'A maximum of three ads may be added to your campaign',
      choiceOptional: '(No choice)',
      remove: 'Remove Advertisement',
      runningPreviewTitle: 'Your {{width}}&times;{{height}} Advertisement',
      previewTitle: 'Ad Preview',
      previewExampleField: 'Example ',
      previewWithExample: 'This ad preview includes example data for demonstration purposes.',
      textCount: 'count: {{count}}',
      textHintChars: '{{min}}&ndash;{{max}} characters',
      textHintCharsOptional: '{{min}}&ndash;{{max}} characters (Optional)',
      urlHint: 'URL',
      urlHintOptional: 'URL (Optional)',
      copyrightMessage: 'I declare that no content I am using violates any copyright or trademark protections',
      stretchable: 'Your ad is designed to be stretchable. Use the toggle below to see your ad either at a fixed size or stretchable to suit the size of its container.',
      stretched: 'Your ad is designed to be stretchable. Your ad will expand and contract in size to suit the container it is placed within.',
      stretchableFixedOption: 'Fixed',
      border: 'Show border to demonstrate ad size',
      stretchableStretchableOption: 'Stretchable'
    },
    reachEstimate: {
      disabled: 'Save your campaign and ensure your budget is specified to check the chances of impression delivery',
      trigger: 'Check Chances of Delivery',
      rangeLowerUpper: 'Another {{lower}} to {{upper}} impressions are projected to be available without facing competition.',
      rangeUpper: 'Up to another {{upper}} impressions are projected to be available without facing competition.',
      VERY_GOOD: 'Your booked impressions have a very high chance of being delivered',
      GOOD: 'Your booked impressions have a high chance of being delivered',
      POSSIBLE: 'Your booked impressions will face some competition for delivery',
      COMPETITION: 'Your booked impressions will face some competition for delivery and potentially not all of your impressions will deliver.',
      MUCH_COMPETITION: 'Your booked impressions will face some competition for delivery and potentially only some of your impressions will deliver.',
      LOTS_COMPETITION: 'Your booked impressions will face heavy competition for delivery and potentially none of your impressions will deliver.',
    },
    addNewCampaign: `
      <h3>Create a Campaign to Deliver Your Message</h3>
      <p>Campaigns are what deliver ads on our advertising portal.</p>
      <p>Create a new campaign with the button above and deliver your ads.</p>
      <p>You will be able to access your campaigns here after creating them and follow them along as they deliver.</p>
    `,
    campaign: 'Campaign',
    campaignPlural: 'Campaigns',
    campaignInfo: '',
    campaignSubInfo: '',
    copy: {
      copyText: "A copy of this campaign was successfully created.",
      copyTitle: "Campaign Copied",
      copyPartialText: "This campaign has been copied, but not all of the advertising material could be copied along with it.",
      copyPartialTitle: "Campaign Copied but not All Advertising Material"
    },
    filter: {
      preMessage: '',
      postMessage: '',
      message: 'Target by {{option}}:'
    },
    copyAfterwards: 'Go to new campaign after copy',
    defaultName: "{{productName}} Campaign",
    detailsTitle: 'Specify Your Campaign Details',
    draftedHeading: 'Drafted Campaigns',
    draftedNoResults: 'No drafted campaigns found.',
    endedHeading: 'Ended Campaigns',
    endedNoResults: 'No ended campaigns found.',
    expectedImpressions: 'Corresponds to about {{imps}} impressions',
    cpmBoost: 'A CPM above the {{minPrice}} minimum increases your chances of delivering all of your budgeted impressions',
    maxedOutCampaigns: '<p>You cannot create further campaigns currently.</p><p>Only a maximum of {{max}} campaigns can be drafted at any one time.</p><p>Archive an existing campaign or have it delivering impressions to be able to create another campaign.</p>',
    coupon: {
      applyCoupon: 'Apply Coupon',
      appliedCode: '{{code}} has been applied',
      cancel: '(cancel)'
    },
    payment: {
      title: 'Payment',
      missing: 'No payment is associated with this campaign.',
      cancelled: 'Your reservation was cancelled and your campaign was not submitted for review.',
      infoComplete: 'A {{price}} payment was completed {{time}}.',
      couponCalculation: '<strong>{{amount}}</strong> will be charged after the coupon discount is applied.',
      infoCompleteWithCoupon: 'A {{price}} payment, which applied a {{discount}} discount, was completed {{time}}.',
      refunded: 'A {{price}} refund was made {{time}}.',
      infoAuthorised: 'A {{price}} payment was authorised {{time}}.',
      receiptLink: 'Your <a href="{{link}}" target="_blank">payment receipt is available</a>.',
      registering: 'Registering payment...',
      reserveTitle: 'Make Payment and Submit Your Campaign for Review',
      reserveButton: 'Make Payment and Submit Your Campaign for Review',
      reserveParagraph0Coupon: 'A <strong>{{amount}}</strong> coupon discount has been applied to your <strong>{{budget}}</strong> budget.',
      reserveParagraph1: 'We will <strong>charge you</strong> the sum of <strong>{{amount}}</strong>, which will enable your campaign to be reviewed and run.',
      reserveParagraph1Free: '<strong>No charge will be applied</strong> and your campaign will be reviewed before it can run.',
      reserveParagraph2: 'If your budgeted <strong>{{imps}} impressions</strong> are not delivered, you will be refunded the leftover amount.',
      failure: 'We encountered a problem trying to register your payment. Try again, and if a problem persists, please contact us.',
      saveFirst: 'You will need to save your changes before proceeding with reserving payment',
      PIN_PAYMENTS: {
        waitingForRedirect: 'We are passing on the details of your transaction to our credit card provider and will redirect you there shortly.',
      },
      PAYPAL: {
        waitingForRedirect: 'We are passing on the details of your transaction to PayPal and will redirect you there shortly.',
        reserveButton: 'Pay via PayPal and Submit Your Campaign for Review'
      },
      prepay: {
        detailsInfo: `
          <div class="small">
            <p>Your campaign will deliver once payment is made and the content of your campaign reviewed.</p>
            <p>If your campaign does not deliver your budget's worth of impressions, you will be refunded the leftover amount. A minimum fee of {{minimumFee}} does apply, however.</p>
            <p>Once your campaign has ended, a performance report and receipt will be generated.</p>
            <p>You will be able to stop the delivery of your campaign at any time, but the minimum fee of {{minimumFee}} will apply even if no impressions have been delivered.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Your campaign will deliver once payment is made and the content of your campaign reviewed.</p>
            <p>If your campaign does not deliver your budget's worth of impressions, you will be refunded the leftover amount.</p>
            <p>Once your campaign has ended, a performance report and receipt will be generated.</p>
            <p>You will be able to stop the delivery of your campaign at any time.</p>
          </div>
        `
      },
      manual: {
        reserveTitle: 'Submit Your Campaign for Review',
        reserveButton: 'Submit Campaign for Review',
        reserveParagraph0Coupon: 'A <strong>{{amount}}</strong> coupon discount has been applied to your <strong>{{budget}}</strong> budget.',
        reserveParagraph1: 'Before your campaign can run, we need to review it.',
        reserveParagraph2: 'Once your campaign has ended, we will issue you an invoice for <strong>{{amount}}</strong> or the pro-rata amount based on the actual number of impressions delivered versus the expected <strong>{{imps}} impressions</strong>.',
        detailsInfo: `
          <div class="small">
            <p>Your campaign will deliver once the content of your campaign is reviewed and approved.</p>
            <p>An invoice will be sent to you after your campaign has ended. You will be charged according to how many impressions your campaign delivers and never more than your specified budget. A minimum fee of {{minimumFee}} does apply, however.</p>
            <p>Once your campaign has ended, a performance report will be generated.</p>
            <p>You will be able to stop the delivery of your campaign at any time, but the minimum fee of {{minimumFee}} will apply even if no impressions have been delivered.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Your campaign will deliver once the content of your campaign is reviewed and approved.</p>
            <p>An invoice will be sent to you after your campaign has ended. You will be charged according to how many impressions your campaign delivers and never more than your specified budget.</p>
            <p>Once your campaign has ended, a performance report will be generated.</p>
            <p>You will be able to stop the delivery of your campaign at any time.</p>
          </div>
        `
      },
      status: {
        CREATED: 'Created',
        NOT_AUTHORISED: 'Not Authorised',
        AUTHORISED: 'Authorised',
        COMPLETED: 'Completed',
        PAUSED: 'Paused',
        CANCELLED: 'Cancelled'
      }
    },
    postPayment: {
      reserveParagraph1: 'Payment for your campaign has been authorised.',
      reserveParagraph2: 'If you\'re happy with your campaign, you can submit it for review below.',
      saveFirst: 'You will need to save your changes before proceeding with submitting your campaign for review.',
      failed: 'Submitting your campaign for review encountered a failure. Try again and if the problem persists, please contact us.',
      submitButton: 'Submit Your Campaign for Review'
    },
    rejectedHeading: 'Rejected Campaigns',
    report: {
      title: 'Report',
      ungenerated: 'A report will be available for you to download shortly.',
      generated: 'A <a href="{{link}}" target="_blank">report is available for you to download</a>.'
    },
    runningHeading: 'In-Review and Running Campaigns',
    runningNoResults: 'No running campaigns found.',
    running: {
      budgetHeading: 'Budget Position',
      budgetPosition: '<strong>{{budgetSpent}}</strong> of your <strong>{{budget}} budget</strong> has been spent.',
      cancelledPaymentParagraph1: 'Your payment reservation has been cancelled.',
      cancelledPaymentParagraph2: 'If you still wish to proceed with your campaign, you can can attempt to pay again below.',
      datesHeading: 'Running Dates',
      dates: '<strong>{{startDate}}</strong> to <strong>{{endDate}}</strong>',
      datesExplanation: 'Your campaign has run for <strong>{{days}} days</strong>, which is <strong>{{percentage}}</strong> of its maximum scheduled life.',
      datesLeftExplanation: 'Your campaign will stop running in <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      datesSubmittedExplanation: 'Your campaign will run for <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      impressions: 'Your campaign has delivered <strong>{{imps}} impressions</strong>, which is <strong>{{percentage}} of your budgeted impressions</strong>.',
      impressionsRemaining: 'You have about <strong>{{imps}} impressions</strong> left to deliver.',
      statusPrefix: 'Campaign status: ',
      successfulPaymentParagraph1: 'Payment has been made and your campaign has been submitted for review.',
      successfulPaymentParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      prepay: {
        stoppedParagraph1: 'Your campaign has been stopped.',
        stoppedParagraph2: 'Any unused campaign budget will be refunded and a report generated for your campaign.',
      },
      manual: {
        successfulPaymentParagraph1: 'Your campaign has been submitted for review.',
        stoppedParagraph1: 'Your campaign has been stopped.',
        stoppedParagraph2: 'An invoice will be issued to you and a report generated for your campaign.',
      },
      submittedParagraph1: 'Your campaign has been submitted for review.',
      submittedParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      unexpectedPaymentParagraph1: 'Your payment did not proceed as planned.',
      unexpectedPaymentParagraph2: 'At this stage, it\'s probably best to copy this campaign and start the process again of creating a payment reservation.'
    },
    save: {
      button: 'Save Your Progress',
      messageTitle: 'Campaign Saved',
      messageText: 'Your campaign was saved successfully.'
    },
    smoothing: {
      title: 'Ad Delivery Style',
      true: 'Smooth',
      trueExplanation: 'Your advertisement will deliver smoothly over the life of your campaign.',
      trueField: '<strong>Smooth</strong> - deliver your budgeted ad impressions smoothly over the life of your campaign',
      false: 'Fast',
      falseExplanation: 'Your advertisement will be delivered as quickly as possible.',
      falseField: '<strong>Fast</strong> - deliver your budgeted ad impressions as quickly as possible'
    },
    status: {
      STOPPED: 'Stopped',
      PROPOSED: 'Drafted',
      SUBMITTED: 'In Review',
      NOT_READY: 'Needs Further Review',
      READY: 'Ready to Deliver',
      PAUSED: 'Paused',
      REJECTED: 'Rejected',
      SUBMISSION_READY: 'Ready for Submission',
      RUNNING: 'Delivering',
      ENDED: 'Ended'
    },
    statsHeading: 'Delivery Statistics',
    stop: {
      button: 'Stop Campaign Delivery',
      cancelButton: 'Cancel Campaign Stop',
      confirmButton: 'Confirm Campaign Stop',
      failParagraph1: 'We encountered a problem and were not able to stop your campaign.',
      failParagraph2: 'Please try stopping your campaign again, and if the problem persists, let us know.',
      prepay: {
        info1: 'Stopping a campaign cannot be reversed, although any campaign can be copied and the new campaign delivered.',
        info2: 'Upon stopping your campaign, any unused budget will be refunded. Note: a minimum fee of {{money}} does apply.',
        info2NoMinFee: 'Upon stopping your campaign, you will be charged your current advertising spend.',
      },
      manual: {
        info1: 'Stopping a campaign cannot be reversed, although any campaign can be copied and the new campaign delivered.',
        info2: 'Upon stopping your campaign, you will be sent an invoice valued at either the minimum fee of {{money}} or your current advertising spend, whichever figure is higher.',
        info2NoMinFee: 'Upon stopping your campaign, you will be issued with an invoice valued at your current advertising spend.',
      },
      intro: 'If at any time you wish to stop your campaign, you may do so below.'
    },
    validationProblemsPresent: `
      <p>There are some validation problems with your campaign.</p>
      <p>Once the validation problems are sorted out, we can proceed with the process of getting your campaign live.</p>
    `,
  },
  devices: {
    ACER: 'Acer',
    APPLE: 'Apple devices',
    ASUS: 'Asus',
    DESKTOP: 'Desktop',
    FUJITSU: 'Fujitsu',
    HTC: 'HTC',
    HUAWEI: 'Huawei',
    KYOCERA: 'Kyocera',
    LG: 'LG',
    MOTOROLA: 'Motorola',
    NEC: 'NEC',
    NOKIA: 'Nokia',
    ONE_PLUS: 'OnePlus',
    OPPO: 'Oppo',
    PANASONIC: 'Panasonic',
    RIM: 'RIM',
    SAMSUNG: 'Samsung',
    SHARP: 'Sharp',
    SONY_ERICSSON: 'Sony Ericsson',
    UNKNOWN: 'Unknown',
    VIVO: 'Vivo',
    XIAOMI: 'Xiaomi',
    ZTE: 'ZTE',
    ANDROID: 'Android',
    BLACKBERRY: 'Blackberry',
    BSD: 'BSD',
    IOS: 'iOS',
    LINUX: 'Linux',
    MACINTOSH: 'Apple Mac OS',
    SUNOS: 'SunOS',
    SYMBIAN: 'Symbian',
    WINDOWS: 'Windows',
    WINDOWS_PHONE: 'Windows Phone',
    CONSOLE: 'Console',
    E_READER: 'E Reader',
    MEDIA_HUB: 'Media Hub',
    MOBILE: 'Smart Phone',
    SMART_WATCH: 'Smart Watch',
    TABLET: 'Tablet',
    TV: 'Television'
  },
  emailPreferences: {
    CREATIVE_REJECTED: '<strong>Adjustments required:</strong> a reviewer requests a change to my campaign',
    REPORT_COMPLETE: '<strong>Campaign report is ready:</strong> my campaign has finished and its performance report has been generated',
    LINE_ITEM_RUNNING: '<strong>Campaign is delivering:</strong> my campaign has passed review and is now delivering impressions'
  },
  media: {
    gif: 'GIF',
    png: 'PNG',
    svg: 'SVG',
    jpg: 'JPG',
    mp4: 'MP4',
    webm: 'WebM',
  },
  chart: {
    countType: {
      singular: 'Trend',
      cumulative: 'Cumulative'
    },
    yAxisOption: {
      shared: 'Shared y-axis',
      multiple: 'Multiple y-axes'
    },
    dateOption: {
      last1Hour: 'Last hour',
      last12Hours: 'Last 12 hours',
      last7Days: 'Last 7 days',
      last30Days: 'Last 30 days',
      today: 'Today',
      yesterday: 'Yesterday',
      campaignDays: 'Running dates: {{startDate}}-{{endDate}}'
    }
  },
  layout: {
    fieldSummary: {
      image: 'image',
      imagePlural: 'images',
      url: 'URL',
      urlPlural: 'URLs',
      code: 'code field',
      codePlural: 'code fields',
      text: 'text field',
      textPlural: 'text fields',
      option: 'option',
      optionPlural: 'options',
      video: 'video',
      videoPlural: 'videos'
    }
  },
  label: {
    copy: 'Copy',
    copyLineItem: 'Copy Campaign',
    paginationAllShown: 'Showing all <strong>{{total}}</strong> results',
    paginationWithPages: 'Showing <strong>{{start}}-{{end}}</strong> of <strong>{{total}}</strong> results',
    paginationLoading: 'Showing <span class="fa fa-spin fa-cog"></span> results'
  },
  report: {
    downloadXlsComma: 'Download as XLS (decimal comma)',
    downloadXlsPoint: 'Download as XLS (decimal point)',
    fieldCountPlural: '{{count}} fields',
    fieldCount: '{{count}} field',
    report: 'Report',
    reportPlural: 'Reports',
    noReport: `
      <h3>No Reports Found</h3>
      <p>Once your campaign has ended, a report will be generated showing its performance.</p>
      <p>Here you will find a list of all the reports generated for all of your campaigns.</p>
    `,
    noReportExample: '',
    status: {
      PENDING: 'Pending',
      PROCESSING: 'Processing',
      COMPLETED: 'Completed',
      REJECTED: 'Rejected',
      FAILED: 'Failed',
      CANCELLED: 'Cancelled',
      QUEUED: 'Queued',
      TIMED_OUT: 'Timed Out'
    },
    field: {
      rendered: 'Impressions',
      visibles: 'Visible Impressions',
      visibility: 'Visibility',
      viewables: 'Viewable Impressions',
      viewability: 'Viewability',
      uniqueUsers: 'Unique Users',
      clicks: 'Clicks',
      ctr: 'Click-Through Rate',
      cost: 'Cost'
    }
  },
  errors: {
    standard: {
      unregistered: {
        title: 'Your Account is Not Registered Here',
        p1: 'You are not registered to this advertising portal.',
        p2: '',
        p2LinkText: 'Register to this advertising portal',
        p2Suffix: '&nbsp;before proceeding.',
        p3: 'Alternatively,&nbsp;',
        p3LinkText: 'sign in with another account',
        p3Suffix: '.'
      },
      dataNotFoundTitle: 'Missing Data',
      dataNotFoundParagraph1: 'We could not find any data at the following location: <strong>{{link}}</strong>.',
      dataNotFoundParagraph2: 'This could have happened because the underlying data is no longer available to you.',
      dataNotFoundParagraph3: 'Access any of the links in the menu to get back on track.',
      pageNotFoundTitle: 'Page Not Found',
      pageNotFoundParagraph1: 'We could not find the page you requested.',
      pageNotFoundParagraph2: 'Access any of the links in the menu to get back on track.',
      objectNotFoundTitle: 'Object Not Found',
      objectNotFoundParagraph1: 'We could not find any data for the following id: <strong>{{id}}</strong>.',
      objectNotFoundParagraph2: 'This could have happened because the object is no longer available to you.',
      objectNotFoundParagraph3: 'Access any of the links in the menu to get back on track.'
    },
    api: {
      stats: 'We were unable to retrieve statistics from the API. If the problem persists, please contact support.',
      title: 'Sorry, there appears to be a problem',
      noConnection: {
        title: "Connection Loss",
        noConnection: "<p>We've lost connection to our back-end server. If the problem persists, please contact support.</p>"
      },
      generic: "<p>We're experiencing problems interacting with our back-end system. If the problem persists, please contact support.</p>",
      unauthorised: {
        title: "Unauthorised Operation",
        unauthorised: "<p>You do not have the permissions to perform the operation you requested.</p>"
      }
    }
  },
  field: {
    advertiserName: 'Company Name',
    address1: 'Address',
    selfServiceBudget: 'Budget',
    city: 'City',
    confirmPassword: 'Confirm Password',
    state: 'State',
    postCode: 'Postcode',
    country: 'Country',
    invoiceEmail: 'Email for Invoices',
    phoneNumber: 'Phone Number',
    email: 'Email',
    emailPreferences: 'Email Preferences',
    password: 'Password',
    locale: 'Locale',
    smoothing: 'Ad Delivery Style',
    startDate: 'Start',
    status: 'Status',
    userState: 'Status',
    vatId: 'VAT ID',
    displayName: 'Display Name',
    displayNameHint: 'The display name is used to refer to you throughout the application',
    endDate: 'End',
    executionStartTime: 'Run Date',
    name: 'Name',
    cpm: 'Cost per 1000 Impressions (CPM)',
    required: 'Required',
    malformedEmail: 'Must be a valid email',
    optional: 'Optional'
  },
  settings: {
    settingsPlural: 'Settings',
    about: 'About',
    signOut: 'Sign Out'
  },
  signIn: {
    accountLocked: 'Your account has been locked due to too many failed login attempts. You have been sent an email with which you can reset your password, unlock your account and sign in.',
    alreadyUser: 'Already a user?',
    confirmPasswordMismatch: "The passwords you entered do not match. Ensure you've entered the same password both times.",
    differentAccount: 'Sign in with a different account',
    coupon: {
      code: 'Coupon Code',
      checkCode: 'Check Coupon Code',
      validCode: '{{code}} is a valid coupon code',
      invalidCode: '{{code}} is not a valid coupon code'
    },
    emailFirst: "Your account's email address must be entered before its password can be reset.",
    emailRequired: "We need your account's email address before we can sign you in.",
    expiredLinkExplanation: 'It looks like you clicked on an invalid or expired password reset link. Please try resetting your password again.',
    forgotPassword: 'Forgot your password?',
    returnHome: 'Go to sign-in page',
    humanConfirmationFailure: "There's a problem obtaining registration confirmation for your account. Try again and let us know if the problem persists.",
    nameRequired: "We need a name to register you as a new user.",
    passwordResetTooMuchHeading: 'Too Many Password Resets',
    passwordResetTooMuchExplanation: 'You have tried to reset your password too often in a short space of time. You cannot reset your password for the next little while.',
    passwordResetEmailSentHeading: 'Password Reset Email Sent',
    passwordResetEmailSentExplanation: 'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.',
    passwordHint: 'Your password must be at least 8 characters in length',
    passwordLinkExpired: 'Your reset password link has expired. Enter your email address to generate another link.',
    passwordRequired: "Supply a password to sign in.",
    passwordSimple: 'The supplied password is too easily guessed and should be something less common.',
    regError: 'Validation issues found. View details above.',
    registerAsUserHeading: 'Register as a User',
    registerAsUserButton: 'Register',
    registerEmailRequired: 'We need an email address to register you as a new user.',
    registerNewUser: 'Register as a new user',
    registerSuccessHighlight: 'You have successfully registered as a new user!',
    registerSuccessParagraph1: 'You have been sent an email with more information.',
    registerSuccessParagraph2: 'Click on the link in your email to create your password for your account and sign in.',
    registerTerms: `By selecting <strong>Register</strong>, you agree that you've read and accepted our <a target="_blank" href="https://adnuntius.com/resources/tc-self-service-advertising/">terms and conditions</a>.`,
    resetPasswordHeading: 'Reset Your Password',
    resetPasswordButton: 'Send Password Reset Email',
    resetPasswordInfo: 'Enter your email address and we will send you a link to reset your password.',
    signInButton: 'Sign in',
    signInHeading: 'Sign in',
    updatePasswordHeading: 'Update Your Password',
    updatePasswordButton: 'Update Password'
  },
  adBlockerParagraph1: "We've detected that your browser is blocking scripts that are necessary for this application.",
  adBlockerParagraph2: "You may need to disable any ad blocking and/or privacy browser extensions for this site and refresh the page to ensure the application works as expected.",
  adBlockerTurnOff: "Turn this warning off",
  validation: {
    required: 'Required',
    archived: 'This campaign is archived. You can still interact with the campaign but it cannot deliver any advertising.',
    pattern: "Text must be {{minLength}}-{{maxLength}} characters long.",
    maxSize: "The uploaded file is {{actual}}kb in size, which is greater than the {{maximum}}kb maximum",
    fileType: "The uploaded file must be one of the following types: {{allowedTypes}}",
    fileUploadFail: "The following files couldn't be uploaded:",
    fileUploadTimeout: "The upload failed to complete. Try uploading again or, if possible, refreshing the page to see which resources have been uploaded.",
    anonymousFileSizeLarge: 'The file must be less than {{fileSize}}kb in size when you have not signed in.',
    anonymousFileSizeSuperLarge: 'The file upload failed for an unspecified reason, more than likely because the file was too large for us to analyse. Sign in to increase the file upload limits and have better analysis provided.',
    minPrice: "The minimum value is {{minValue}}",
    maxPrice: "The maximum value is {{maxValue}}",
    number: "Invalid number",
    url: "The URL is not in a valid form.",
    parse: "The content does not match the expected format",
    endDateStartDate: 'The end date must be after the start date.',
    email: 'Need a valid email address',
    error: {
      validation: '<strong>Invalid submission:</strong> Please check the details above and try again.',
      'must.be.greater.than.or.equal': '{{value}} must be greater than {{minValue}}.',
      'non.empty': 'Required',
      'user.sui.user.network.exists.code': 'Your email address is already registered here. Sign in with your email address.',
      "auth.failed": "We couldn't sign you in. The credentials don't match any account.",
      "sign.in.blocked": "You are not registered as a user here. Sign in at {{uiAddress}}.",
    },
    warning: {
      "creative.invalid.product.dimensions": "Valid dimensions need to be selected for your ad",
      "lineItem.start.date.before.end.date": "The campaign's start date must be before its end date",
      "lineItem.end.date.before.today": "The campaign's end date must be after the current date and time",
      "lineItem.invalid.budget.enddate": "The campaign must end within 100 days",
      "payment.gateway.error": "The problem relates to our connection to payment provider."
    }
  }
};
