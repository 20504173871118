export class Uuid {
  static readonly ALPHABET = '012356789bcdfghjklmnpqrstvwxyz';
  static readonly ID_LENGTH = 16;

  static generate() {
    let rtn = '';
    for (let i = 0; i < this.ID_LENGTH; i++) {
      rtn += this.ALPHABET.charAt(Math.floor(Math.random() * this.ALPHABET.length));
    }
    return rtn;
  }
}
