/**
 * Copyright © 2023 Adnuntius AS.
 */

export const TRANSLATIONS_ID = {
  adn: {
    copyright: 'Copyright © 2023 Adnuntius AS',
    releaseId: 'Release ID: {{id}}',
    poweredBy: 'Powered by Adnuntius AS',
  },
  grammar: {
    listSeparator: ', ',
    titleSeparator: ': ',
  },
  button: {
    upload: {
      //asset: 'Upload',
      asset: 'Unggah',
      //drop: 'Drop a file here or use the upload button'
      drop: 'Taruh file disini atau gunakan tombol unggah',
    },
    //archive: 'Archive',
    archive: 'Arsip',
    //activate: 'Activate',
    activate: 'Aktifkan',
    //applyCustomRange: 'Apply custom range',
    applyCustomRange: 'Terapkan Rentang Waktu',
    //clear: 'Clear',
    clear: 'Hapus',
    //close: 'Close',
    close: 'Tutup',
    //newCampaign: 'Create New Campaign',
    newCampaign: 'Buat Kampanye Iklan Baru',
    //save: 'Save',
    save: 'Simpan',
    //undo: 'Undo',
    undo: 'Batalkan',
    //today: 'Today',
    today: 'Hari Ini',
  },
  locale: {
    ar: 'Arabic - عربى',
    ar_JO: 'Arabic (Jordan) - العربية (الأردن)',
    ar_EG: 'Arabic (Egypt) - العربية (مصر)',
    ar_AE: 'Arabic (UAE) - العربية (الإمارات العربية المتحدة)',
    my: 'Burmese - ဗမာ',
    en: 'English',
    en_AU: 'English (Australia)',
    en_CA: 'English (Canada)',
    en_US: 'English (USA)',
    en_GB: 'English (Great Britain)',
    es: 'Spanish - español',
    es_ES: 'Spanish (Spain) - español (españa)',
    es_AR: 'Spanish (Argentina) - español (argentina)',
    et: 'Estonian - eesti keel',
    fi: 'Finnish - Suomalainen',
    id: 'Indonesia - Bahasa',
    is: 'Icelandic - íslensku',
    de: 'German - Deutsch',
    de_DE: 'German (Germany) - Deutsch (Deutschland)',
    de_AT: 'German (Austria) - Deutsch (Österreich)',
    de_CH: 'German (Switzerland) - Deutsch (Schweiz)',
    nl: 'Dutch',
    nl_BE: 'Dutch (Belgium) - Nederlands (België)',
    nl_NL: 'Dutch (Netherlands) - Nederlands (Nederland)',
    no: 'Norwegian - norsk',
    sv: 'Swedish - svenska',
    sv_SE: 'Swedish (Sweden) - svenska (Sverige)',
    sv_FI: 'Swedish (Finland) - svenska (Finland)',
    pl: 'Polish - Polskie',
    pt: 'Portuguese - Português',
    pt_BR: 'Portuguese (Brazil) - Português (Brasil)',
    pt_PT: 'Portuguese (Portugal) - Português (Portugal)',
    ru: 'Russian - русский',
    el: 'Greek - Ελληνικά',
    fr: 'French - français',
    da: 'Danish - Dansk',
    th: 'Thai - ไทย',
  },
  language: {
    text: 'Language:',
  },
  campaign: {
    //archivedHeading: 'Archived Campaigns',
    archivedHeading: 'Arsip Kampanye Iklan',
    //archivedNoResults: 'No archived campaigns found.',
    archivedNoResults: 'Arsip Kampanye Iklan tidak ditemukan',
    //archivedShowLink: 'Show archived campaigns',
    archivedShowLink: 'Tampilkan Arsip Kampanye Iklan',
    adRejected: {
      //explanation: 'Adjust your ad and resubmit your campaign for review to have it deliver your budgeted impressions.',
      explanation:
        'Sesuaikan iklan Anda dan kirim ulang materi promosi Anda untuk ditinjau agar dapat berjalan sesuai dengan anggaran',
      ADULT_CONTENT: {
        //name: 'Adult Content',
        name: 'Konten Dewasa',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains adult-oriented content.'
        explanation: 'Iklan ditolak karena berisi atau mengarah ke halaman yang berisi konten dewasa',
      },
      BLANK_CONTENT: {
        //name: 'Blank Content',
        name: 'Konten kosong',
        //explanation: 'The ad was rejected because it renders as blank. Please ensure that the required content have been provided correctly.'
        explanation:
          'Iklan ditolak karena ketika ditampilkan kosong. Harap pastikan konten yang diperlukan telah disiapkan dengan benar',
      },
      MALFORMED_CLICK_THROUGH: {
        //name: 'Malformed Destination URL',
        name: 'URL tujuan rusak',
        //explanation: 'The ad was rejected because the click destination URL does not function properly.'
        explanation: 'Iklan ditolak karena URL tujuan tidak berfungsi dengan baik.',
      },
      DRUG_RELATED_CONTENT: {
        //name: 'Drug-Related Content',
        name: 'Konten Terkait Obat Terlarang',
        //explanation: 'The ad was rejected because it or its landing page promotes drug-related content or products. This may include tobacco, alcohol, pharmaceutical or other legal or illegal drugs.'
        explanation:
          'Iklan ditolak karena halaman tujuan mengandung konten arau produk obat terlarang. Ini termasuk tembakau, alkohol, farmasi atau obat-obatan legal atau ilegal lainnya.',
      },
      WEAPON_RELATED_CONTENT: {
        //name: 'Weapon-Related Content',
        name: 'Konten Terkait Senjata',
        //explanation: 'The ad was rejected because it or its landing page promotes weapons or weapon-related content. This can include content that promotes dangerous knives, guns, gun parts and other related content.'
        explanation:
          'Iklan ditolak karena halaman tujuan mengandung konten terkait senjata. Ini dapat mencakup  konten yang mempromosikan pisau berbahaya, senjata api, suku cadang senjata, dan konten terkait lainnya.',
      },
      HATE_SPEECH_CONTENT: {
        //name: 'Hate-Speech Content',
        name: 'Konten Ujaran Kebencian',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains hate speech content. This may include content promoting violence, harassment, racism, hatred and intolerance.'
        explanation:
          'Iklan ditolak karena berisi atau halaman tujuan berisi konten perkataan yang mendorong kebencian. Ini mungkin termasuk konten yang mempromosikan kekerasan, pelecehan, rasisme, kebencian, dan intoleransi.',
      },
      LANDING_PAGE_REJECTED: {
        //name: 'Landing Page Rejected',
        name: 'Halaman Tujuan Ditolak',
        //explanation: 'The ad was rejected because the landing page was low quality or difficult to interact with. This can include pages that disable browser navigation functions, include low value content, or lead to an email address or file, such as image, audio, video, or document, that requires an additional application to open.'
        explanation:
          'Iklan ditolak karena halaman tujuan berkualitas rendah atau tidak dapat berfungsi. Ini dapat mencakup halaman yang menonaktifkan fungsi navigasi browser,menyertakan konten bernilai rendah, atau mengarah ke alamat email atau file, seperti gambar, audio, video, atau dokumen, yang memerlukan aplikasi tambahan untuk membukanya.',
      },
      OFFENSIVE_CONTENT: {
        //name: 'Offensive Content',
        name: 'Konten yang menyinggung',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains offensive or inappropriate content. Ensure that your ads and landing pages only show family safe content.',
        explanation:
          'Iklan ditolak karena mengandung konten yang tidak pantas. Pastikan iklan dan halaman tujuan Anda hanya menampilkan konten yang aman bagi keluarga.',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        //name: 'Unacceptable Visual Effects',
        name: 'Efek Visual Yang Tidak Dapat Diterima',
        //explanation: 'The ad was rejected because it uses unacceptable visual effects. This includes animated GIFs with a frame rate higher than 5 frames per second.'
        explanation:
          'Iklan ditolak karena menggunakan efek visual yang tidak bisa diterima. Ini termasuk GIF Animasi dengan kecepatan bingkai lebih tinggi dari 5 bingkai per detik',
      },
      DECEPTIVE_CONTENT: {
        //name: 'Deceptive Content',
        name: 'Konten Yang Menipu',
        //explanation: 'The ad was rejected because it contains misleading claims or information. Examples include ads that make scientifically impossible claims, offer guaranteed results without a refund policy, or show testimonials claiming specific results without a link to third-party verification. Ads may also be rejected if the creative is designed to look like software download buttons, official system warnings, or webpage features like menus, search results, or other site content.'
        explanation:
          'Iklan ditolak karena mengandung klaim atau informasi yang menyesatkan. Contohnya termasuk iklan yang membuat klaim tidak mungkin secara ilmiah, menawarkan hasil yang dijamin tanpa kebijakan pengembalian dana, atau menampilkan testimonial yang mengklaim hasil tertentu tanpa tautan ke verifikasi pihak ketiga. Iklan juga dapat ditolak jika materi iklan dirancang agar terlihat seperti tombol unduhan perangkat lunak, peringatan sistem resmi, atau fitur halaman web seperti menu, hasil penelusuran, atau konten situs lainnya.',
      },
      GAMBLING_CONTENT: {
        //name: 'Gambling Content',
        name: 'Konten Perjudian',
        //explanation: 'The ad was rejected because it contains gambling-related content.'
        explanation: 'Iklan ditolak karena mengandung konten yang berhubungan dengan perjudian.',
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        //name: 'Prohibited Election-Related Content',
        name: 'Konten Terkait Pemilu Yang Dilarang ',
        //explanation: 'The ad is rejected because it contains prohibited content related to government elections or currently elected government officeholders.'
        explanation:
          'Iklan ditolak karena berisi konten terlarang terkait dengan pemilihan pemerintah atau pejabat pemerintah yang sedang dipilih.',
      },
      SHOCKING_CONTENT: {
        //name: 'Shocking Content',
        name: 'Konten Mengejutkan',
        //explanation: 'The ad was rejected because it contains shocking content such as profane language, gruesome or disgusting imagery, or promotions that are designed to shock or scare.'
        explanation:
          'Iklan ditolak karena berisi konten yang mengerikan seperti bahasa yang tidak senonoh, gambar yang mengerikan atau menjijikkan, atau promosi yang dirancang untuk mengejutkan atau menakut-nakuti.',
      },
      MALWARE_CONTENT: {
        //name: 'Malware Content',
        name: 'Konten Malware',
        //explanation: 'The ad was rejected because it or its landing page contains or leads to unacceptable content such as malware.'
        explanation:
          'Iklan ditolak karena berisi konten malware atau halaman yang dituju berisi atau mengarah ke konten yang tidak dapat diterima seperti malware.',
      },
    },
    layout: {
      //title: 'Select an Ad Design',
      title: 'Pilih Desain Iklan',
      //missing: 'The layout you selected previously is not available for the current advertising option.',
      missing: 'Tata letak yang Anda pilih sebelumnya tidak tersedia untuk opsi periklanan saat ini.',
      //missingPreview: "A valid layout needs to be selected before a creative can be previewed."
      missingPreview: 'Tata letak harus dipilih terlebih dahulu sebelum materi iklan dapat dipratinjau.',
    },
    product: {
      //title: 'Select an Advertising Option',
      title: 'Pilih Opsi Periklanan',
      //adDesignChoice: '<strong>{{count}} ad designs</strong> available at these sizes:',
      adDesignChoice: '<strong>{{count}} desain iklan</strong> tersedia dalam ukuran berikut:',
      //cpm: '<strong>{{value}}</strong> per thousand impressions',
      cpm: '<strong>{{value}}</strong> per seribu tayangan',
      //minSpend: '<strong>{{value}}</strong> minimum spend'
      minSpend: '<strong>{{value}}</strong> pembelanjaan minimum',
      maxSpend: '<strong>{{value}}</strong> perbelanjaan maksimum',
    },
    ad: {
      //title: 'Design Your Ad',
      title: 'Rancang Iklan Anda',
      //defaultName: 'Creative for {{campaignName}}',
      defaultName: 'Kreatif untuk {{campaignName}}',
      //layoutFirst: 'Select an ad design before entering your ad\'s details.',
      layoutFirst: 'Pilih desain iklan sebelum memasukkan rincian iklan Anda',
      //deletedAsset: 'This component references a deleted image. Upload a new one.',
      deletedAsset: 'Komponen ini mereferensikan gambar yang dihapus. Unggah yang baru.',
      imageHint: '{{mediaTypes}}, Max: {{maxSize}}kb',
      imageHintOptional: '{{mediaTypes}}, Max: {{maxSize}}kb (Optional)',
      //adSizeTitle: 'Ad Size',
      adSizeTitle: 'Ukuran Iklan',
      //choiceOptional: '(No choice)',
      choiceOptional: '(Tidak Ada Pilihan)',
      //runningPreviewTitle: 'Your {{width}}&times;{{height}} Advertisement',
      runningPreviewTitle: 'Iklan {{width}}&times;{{height}} Anda',
      //previewTitle: 'Ad Preview',
      previewTitle: 'Pratinjau Iklan',
      //border: 'Show border on advertisement',
      border: 'Tampilkan garis batas di Iklan',
      //previewWithExample: 'This ad preview includes example data for demonstration purposes.',
      previewWithExample: 'Pratinjau iklan ini menyertakan data contoh untuk tujuan demonstrasi',
      //textCount: 'count: {{count}}',
      textCount: 'jumlah: {{count}}',
      textHintChars: '{{min}}&ndash;{{max}} characters',
      textHintCharsOptional: '{{min}}&ndash;{{max}} characters (Optional)',
      urlHint: 'URL',
      urlHintOptional: 'URL (Optional)',
    },
    //campaign: 'Campaign',
    campaign: 'Kampanye Iklan',
    //campaignPlural: 'Campaigns',
    campaignPlural: 'Kampanye Iklan',
    copy: {
      //copyText: "A copy of this campaign was successfully created.",
      copyText: 'Salinan Kampanye Iklan ini berhasil dibuat',
      //copyTitle: "Campaign Copied",
      copyTitle: 'Kampanye Iklan Disalin',
      //copyPartialText: "This campaign has been copied, but not all of the advertising material could be copied along with it.",
      copyPartialText: 'Kampanye Iklan ini telah disalin, tetapi tidak semua materi iklan dapat disalin bersamanya.',
      //copyPartialTitle: "Campaign Copied but not All Advertising Material"
      copyPartialTitle: 'Kampanye Iklan Disalin tetapi Tidak Semua Materi Iklan Tersebut',
    },
    //copyAfterwards: 'Go to new campaign after copy',
    copyAfterwards: 'Lanjut ke Kampanye Iklan baru setelah menyalin',
    //defaultName: "{{productName}} Campaign",
    defaultName: '{{productName}} Kampanye Iklan',
    //detailsTitle: 'Specify Your Campaign Details',
    detailsTitle: 'Detail Kampanye Iklan Anda',
    //draftedHeading: 'Drafted Campaigns',
    draftedHeading: 'Draf Kampanye Iklan',
    //draftedNoResults: 'No drafted campaigns found.',
    draftedNoResults: 'Draf Kampanye Iklan Tidka ditemukan.',
    //endedHeading: 'Ended Campaigns',
    endedHeading: 'Kampanye Iklan Berakhir',
    //endedNoResults: 'No ended campaigns found.',
    endedNoResults: 'Kampanye Iklan Berakhir tidak ditemukan.',
    //expectedImpressions: 'Corresponds to about {{imps}} impressions',
    expectedImpressions: 'Sesuai dengan {{imps}} impressions',
    payment: {
      //title: 'Payment',
      title: 'Pembayaran',
      //missing: 'No payment is associated with this campaign.',
      missing: 'Tidak ada pembayaran yang diasosiasikan dengan kampanye iklan ini.',
      //cancelled: 'Your reservation was cancelled and your campaign was not submitted for review.',
      cancelled: 'Reservasi Anda dibatalkan dan kampanye iklan Anda tidak dikirim untuk ditinjau.',
      //infoComplete: 'A {{price}} payment was completed {{time}}.',
      infoComplete: 'Pembayaran sebesar {{price}} telah selesai {{time}}.',
      //infoAuthorised: 'A {{price}} payment was authorised {{time}}.',
      infoAuthorised: 'Pembayaran sebesar {{price}} telah diotorisasi {{time}}.',
      //receiptLink: 'Your <a href="{{link}}" target="_blank">payment receipt is available</a>.',
      receiptLink: '<a href="{{link}}" target="_blank">Tanda Terima Pembayaran Anda</a>.',
      //registering: 'Registering transaction...',
      registering: 'Mendaftarkan Transaksi Anda.',
      //reserveTitle: 'Reserve Payment and Submit Your Campaign for Review',
      reserveTitle: 'Lanjutkan Pembayaran dan Kirim Kampanye Iklan Anda untuk diperiksa',
      //reserveButton: 'Reserve Payment and Submit Your Campaign for Review',
      reserveButton: 'Lanjutkan Pembayaran dan Kirim Kampanye Iklan Anda untuk di periksa',
      //reserveParagraph1: 'We will place a <strong>hold on your account</strong> for the budgeted sum of <strong>{{amount}}</strong>, which will enable your campaign to be reviewed and run.',
      reserveParagraph1:
        'Kami akan menangguhkan <strong>Akun Anda</strong> sebesar <strong>{{amount}}</strong>, Kampanye Iklan Anda akan direview dan segera diaktifkan.',
      //reserveParagraph2: 'Once your campaign has ended, we will take either {{amount}} from your account or the pro-rata amount based on the actual number of impressions delivered versus the expected <strong>{{imps}} impressions</strong>.',
      reserveParagraph2:
        'Setelah kampanye Anda berakhir, kami akan mengambil {{amount}} dari akun Anda atau jumlah prorata berdasarkan jumlah tayangan sebenarnya yang ditayangkan versus <strong>{{imps}} tayangan</strong>.',
      //failure: 'We encountered a problem trying to register your transaction. Try again, and if a problem persists, please contact us.',
      failure:
        'Kami mengalami masalah saat mencoba mendaftarkan transaksi Anda. Coba lagi, dan jika masalah tetap muncul, harap hubungi kami.',
      //saveFirst: 'You will need to save your changes before proceeding with reserving payment',
      saveFirst: 'Anda harus menyimpan perubahan Anda sebelum melanjutkan pembayaran',
      //waitingForRedirect: 'We are passing on the details of your transaction to {{paymentProvider}} and will redirect you there shortly.',
      waitingForRedirect:
        'Kami menyampaikan rincian transaksi Anda ke {{paymentProvider}} dan akan segera mengarahkan Anda ke sana.',
      status: {
        //CREATED: 'Created',
        CREATED: 'Dibuat',
        //NOT_AUTHORISED: 'Not Authorised',
        NOT_AUTHORISED: 'Tidak Diotorisasi',
        //AUTHORISED: 'Authorised',
        AUTHORISED: 'Otorisasi',
        //COMPLETED: 'Completed',
        COMPLETED: 'Selesai',
        //CANCELLED: 'Cancelled'
        CANCELLED: 'Dibatalkan',
      },
    },
    postPayment: {
      //reserveParagraph1: 'Payment for your campaign has been authorised.',
      reserveParagraph1: 'Pembayaran untuk Kampanye Iklan Anda telah diotorisasi.',
      //reserveParagraph2: 'If you\'re happy with your campaign, you can submit it for review below.',
      reserveParagraph2: 'Jika Anda sudah senang dengan Kampanye Iklan Anda, silahkan dikirim untuk ditinjau.',
      //saveFirst: 'You will need to save your changes before proceeding with submitting your campaign for review.',
      saveFirst:
        'Anda harus menyimpan perubahan Anda sebelum melanjutkan dengan mengirimkan Kampanye Iklan Anda untuk ditinjau.',
      //submitButton: 'Submit Your Campaign for Review'
      submitButton: 'Kirimkan Kampanye Iklan Anda untuk Diperiksa',
    },
    //rejectedHeading: 'Rejected Campaigns',
    rejectedHeading: 'Kampanye Iklan Ditolak',
    report: {
      //title: 'Report',
      title: 'Laporan',
      //ungenerated: 'A report will be available for you to download shortly.',
      ungenerated: 'Laporan akan tersedia untuk Anda unduh segera.',
      //generated: 'A <a href="{{link}}" target="_blank">report is available for you to download</a>.'
      generated: 'Sebuah <a href="{{link}}" target="_blank">laporan tersedia untuk Anda Unduh</a>.',
    },
    //runningHeading: 'In-Review and Running Campaigns',
    runningHeading: 'Kanpanye Iklan Dalam Tinjauan Dan Kampanye Iklan Yang Berjalan',
    //runningNoResults: 'No running campaigns found.',
    runningNoResults: 'Tidak Ada Kampanye Iklan Yang Berjalan',
    running: {
      //budgetHeading: 'Budget Position',
      budgetHeading: 'Anggaran Kampanye Iklan',
      //budgetPosition: '<strong>{{budgetSpent}}</strong> of your <strong>{{budget}} budget</strong> has been spent.',
      budgetPosition:
        '<strong>{{budgetSpent}}</strong> dari <strong>{{budget}} anggaran Kampanye Iklan </strong> Anda telah dihabiskan .',
      //cancelledPayment1: 'Your payment reservation has been cancelled.',
      cancelledPaymentParagraph1: 'Reservasi Pembayaran Anda telah dibatalkan',
      //cancelledPayment2: 'If you still wish to proceed with your campaign, you can generate a new transaction below.',
      cancelledPaymentParagraph2:
        'Jika Anda masih ingin melanjutkan Kampanye Iklan, Anda dapat membuat transaksi baru di bawah.',
      //datesHeading: 'Running Dates',
      datesHeading: 'Tanggal Berjalan',
      //dates: '<strong>{{startDate}}</strong> to <strong>{{endDate}}</strong>',
      dates: '<strong>{{startDate}}</strong> samapai <strong>{{endDate}}</strong>',
      //datesExplanation: 'Your campaign has run for <strong>{{days}} days</strong>, which is <strong>{{percentage}}</strong> of its maximum scheduled life.',
      datesExplanation:
        'Kampanye Iklan Anda telah berjalan selama <strong>{{days}} hari</strong>, yang merupakan <strong>{{percentage}}</strong> dari jadwal maksimum masa pakainya.',
      //datesLeftExplanation: 'Your campaign will stop running in <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      datesLeftExplanation:
        'Kampanye Iklan Anda akan berhenti berjalan dalam <strong>{{days}} hari</strong> jika tayangan yang dianggarkan tidak tercapai.',
      //datesSubmittedExplanation: 'Your campaign will run for <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      datesSubmittedExplanation:
        'Kampanye Iklan Anda akan berjalan dalam <strong>{{days}} hari</strong> jika tayangan yang dianggarkan tidak tercapai.',
      //impressions: 'Your campaign has delivered <strong>{{imps}} impressions</strong>, which is <strong>{{percentage}} of your budgeted impressions</strong>.',
      impressions:
        'Kampanye Iklan Anda telah menghasilkan <strong>{{imps}} impressions</strong>, yang merupakan <strong>{{percentage}} tayangan yang dianggarkan</strong>.',
      //impressionsRemaining: 'You have about <strong>{{imps}} impressions</strong> left to deliver.',
      impressionsRemaining: 'Anda memiliki sekitar <strong>{{imps}} impressions</strong> tersisa untuk ditayangkan.',
      //statusPrefix: 'Your campaign is ',
      statusPrefix: 'Kampanye Iklan Anda adalah ',
      //successfulPaymentParagraph1: 'Payment has been reserved and your campaign has been submitted for review.',
      successfulPaymentParagraph1:
        'Pembayaran Anda telah dipesan dan Kampanye Iklan Anda telah dikirim untuk ditinjau.',
      //successfulPaymentParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      successfulPaymentParagraph2: 'Setelah Kampanye Iklan Anda lolos tinjauan, iklan Anda akan ditayangkan',
      //stoppedParagraph1: 'Your campaign has been stopped.',
      stoppedParagraph1: 'Kampanye Iklan Anda telah dihentikan.',
      //stoppedParagraph2: 'Any unused campaign budget will be refunded and a report generated for your campaign.',
      stoppedParagraph2:
        'Semua anggaran kampanye yang tidak terpakai akan dikembalikan dan laporan dibuat untuk kampanye Anda.',
      //submittedParagraph1: 'Your campaign has been submitted for review.',
      submittedParagraph1: 'Kampanye Iklan Anda telah dikirim untuk ditinjau.',
      //submittedParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      submittedParagraph2: 'Setelah Kampanye Iklan Anda lolos tinjauan, iklan Anda akan berjalan!',
      //unexpectedPaymentParagraph1: 'Your payment reservation did not proceed as planned.',
      unexpectedPaymentParagraph1: 'Reservasi pembayaran Anda tidak berjalan sesuai rencana.',
      //unexpectedPaymentParagraph2: 'At this stage, it\'s probably best to copy this campaign and start the process again of creating a payment reservation.'
      unexpectedPaymentParagraph2:
        'Pada tahap ini, mungkin yang terbaik adalah menyalin Kampanye Iklan ini dan memulai kembali proses membuat reservasi pembayaran.',
    },
    save: {
      //button: 'Save Your Progress',
      button: 'Simpan Kemajuan Anda',
      //messageTitle: 'Campaign Saved',
      messageTitle: 'Kampanye Iklan disimpan',
      //messageText: 'Your campaign was saved successfully.'
      messageText: 'Kampanye Iklan Anda telah berhasil disimpan.',
    },
    smoothing: {
      //title: 'Ad Delivery Style',
      title: 'Gaya Penayangan Iklan',
      //true: 'Smooth',
      true: 'Maksimalkan Waktu',
      //trueExplanation: 'Your advertisement will deliver smoothly over the life of your campaign.',
      trueExplanation: 'Iklan Anda akan ditayangkan dengan memaksimalkan waktu selama masa Kampanye Iklan Anda.',
      //trueField: '<strong>Smooth</strong> - deliver your budgeted ad impressions smoothly over the life of your campaign',
      trueField:
        '<strong>Maksimalkan Waktu</strong> - berikan tayangan iklan yang dianggarkan dengan memaksimalkan waktu selama masa Kampanye Iklan Anda.',
      //false: 'Fast',
      false: 'Cepat',
      //falseExplanation: 'Your advertisement will be delivered as quickly as possible.',
      falseExplanation: 'Iklan Anda akan berjalan secepat mungkin dari waktu yang direncanakan.',
      //falseField: '<strong>Fast</strong> - deliver your budgeted ad impressions as quickly as possible'
      falseField: '<strong>Cepat</strong> - tayangkan iklan Anda dengan secepat mungkin dari waktu yang direnacakan.',
    },
    status: {
      //STOPPED: 'Stopped',
      STOPPED: 'Dihentikan',
      //PROPOSED: 'Drafted',
      PROPOSED: 'Draf',
      //SUBMITTED: 'In Review',
      SUBMITTED: 'Dalam Tinjauan',
      //NOT_READY: 'Needs Further Review',
      NOT_READY: 'Membutuhkan Tinjauan Lebih Lanjut',
      //READY: 'Ready to Deliver',
      READY: 'Siap Dikirim',
      //REJECTED: 'Rejected',
      REJECTED: 'Ditolak',
      //SUBMISSION_READY: 'Ready for Submission',
      SUBMISSION_READY: 'Siap Untuk Dikirim',
      //RUNNING: 'Delivering',
      RUNNING: 'Berjalan',
      //ENDED: 'Ended'
      ENDED: 'Berakhir',
    },
    //statsHeading: 'Delivery Statistics',
    statsHeading: 'Statistik Pengiriman',
    stop: {
      //button: 'Stop Campaign Delivery',
      button: 'Hentikan Kampanye Iklan',
      //cancelButton: 'Cancel Campaign Stop',
      cancelButton: 'Batalkan Kampanye Iklan',
      //confirmButton: 'Confirm Campaign Stop',
      confirmButton: 'Konfirmasi Penghentian Kampanye Iklan',
      //failParagraph1: 'We encountered a problem and were not able to stop your campaign.',
      failParagraph1: 'Kami mengalami masalah dan tidak dapat menghentikan Kampanye Iklan Anda',
      //failParagraph2: 'Please try stopping your campaign again, and if the problem persists, let us know.',
      failParagraph2: 'Silahkan coba hentikan Kampanye Iklan Anda lagi, dan jika masalah tetap ada, beri tahu kami',
      //info1: 'Stopping a campaign cannot be reversed, although any campaign can be copied and the new campaign delivered.',
      info1:
        'Kampanye Iklan tidak dapat dibatalkan, meskipun Kampanye Iklan dapat disalin dan Kampanye Iklan baru dikirimkan.',
      //info2: 'Upon stopping your campaign, any unused budget will be refunded. Note: a minimum fee of {{money}} does apply.',
      info2:
        'Setelah menghentikan kampanye Anda, semua anggaran yang tidak terpakai akan dikembalikan. Catatan: biaya minimum {{money}} berlaku.',
      //intro: 'If at any time you wish to stop your campaign, you may do so below.'
      intro: 'Jika suatu saat Anda ingin menghentikan Kampanye Iklan Anda, Anda dapat melakukannya di bawah.',
    },
  },
  emailPreferences: {
    //CREATIVE_REJECTED: '<strong>Adjustments required:</strong> a reviewer requests a change to my campaign',
    CREATIVE_REJECTED: '<strong> Diperlukan penyesuaian: </strong> peninjau meminta perubahan pada kampanye saya',
    //REPORT_COMPLETE: '<strong>Campaign report is ready:</strong> my campaign has finished and its performance report has been generated',
    REPORT_COMPLETE:
      '<strong> Laporan kampanye sudah siap: </strong> kampanye iklan saya telah selesai dan laporan kinerjanya telah dibuat',
    //LINE_ITEM_RUNNING: '<strong>Campaign is delivering:</strong> my campaign has passed review and is now delivering impressions'
    LINE_ITEM_RUNNING:
      '<strong> Kampanye Iklan ditayangkan: </strong> kampanye iklan saya telah lulus tinjauan dan sekarang menghasilkan tayangan',
  },
  media: {
    gif: 'GIF',
    png: 'PNG',
    svg: 'SVG',
    jpg: 'JPG',
  },
  chart: {
    countType: {
      singular: 'Trend',
      cumulative: 'Cumulative',
    },
    yAxisOption: {
      shared: 'Shared y-axis',
      multiple: 'Multiple y-axes',
    },
    dateOption: {
      //last1Hour: 'Last hour',
      last1Hour: '1 Jam Terakhir',
      //last12Hours: 'Last 12 hours',
      last12Hours: '12 Jam Terakhir',
      //last7Days: 'Last 7 days',
      last7Days: '7 Hari Terakhir',
      //last30Days: 'Last 30 days',
      last30Days: '30 Hari Terakhir',
      //today: 'Today',
      today: 'Hari Ini',
      //yesterday: 'Yesterday',
      yesterday: 'Kemarin',
      //campaignDays: 'Running dates: {{startDate}}-{{endDate}}'
      campaignDays: 'Tanggal Berjalan: {{startDate}}-{{endDate}}',
    },
  },
  layout: {
    fieldSummary: {
      image: 'image',
      imagePlural: 'images',
      url: 'URL',
      urlPlural: 'URLs',
      code: 'code field',
      codePlural: 'code fields',
      text: 'text field',
      textPlural: 'text fields',
      option: 'option',
      optionPlural: 'options',
    },
  },
  label: {
    //copy: 'Copy',
    copy: 'Salin',
    //copyLineItem: 'Copy Campaign',
    copyLineItem: 'Salin Kampanye Iklan',
    //paginationAllShown: 'Showing all <strong>{{total}}</strong> results',
    paginationAllShown: 'Tampilkan semua <strong>{{total}}</strong> hasil',
    //paginationWithPages: 'Showing <strong>{{start}}-{{end}}</strong> of <strong>{{total}}</strong> results',
    paginationWithPages: 'Tampilkan <strong>{{start}}-{{end}}</strong> dari <strong>{{total}}</strong> hasil',
    //paginationLoading: 'Showing <span class="fa fa-spin fa-cog"></span> results'
    paginationLoading: 'Tampilkan <span class="fa fa-spin fa-cog"></span> hasil',
  },
  report: {
    //downloadXlsComma: 'Download as XLS (decimal comma)',
    downloadXlsComma: 'Unduh format XLS (decimal comma)',
    //downloadXlsPoint: 'Download as XLS (decimal point)',
    downloadXlsPoint: 'Unduh format XLS (decimal point)',
    fieldCountPlural: '{{count}} fields',
    fieldCount: '{{count}} field',
    //report: 'Report',
    report: 'Laporan',
    //reportPlural: 'Reports',
    reportPlural: 'Laporan',
    status: {
      //PENDING: 'Pending',
      PENDING: 'Tertunda',
      //PROCESSING: 'Processing',
      PROCESSING: 'Memproses',
      //COMPLETED: 'Completed',
      COMPLETED: 'Selesai',
      //REJECTED: 'Rejected',
      REJECTED: 'Ditolak',
      //FAILED: 'Failed',
      FAILED: 'Gagal',
      //CANCELLED: 'Cancelled',
      CANCELLED: 'Dibatalkan',
      //QUEUED: 'Queued',
      QUEUED: 'Antrian',
      //TIMED_OUT: 'Timed Out'
      TIMED_OUT: 'Waktu Habis',
    },
    field: {
      rendered: 'Impressions',
      visibles: 'Visible Impressions',
      visibility: 'Visibility',
      viewables: 'Viewable Impressions',
      viewability: 'Viewability',
      uniqueUsers: 'Unique Users',
      clicks: 'Clicks',
      ctr: 'Click-Through Rate',
      cost: 'Cost',
    },
  },
  errors: {
    standard: {
      //dataNotFoundTitle: 'Missing Data',
      dataNotFoundTitle: 'Data Hilang',
      //dataNotFoundParagraph1: 'We could not find any data at the following location: <strong>{{link}}</strong>.',
      dataNotFoundParagraph1: 'Kami tidak dapat menemukan data apa pun di lokasi berikut: <strong>{{link}}</strong>.',
      //dataNotFoundParagraph2: 'This could have happened because the underlying data is no longer available to you.',
      dataNotFoundParagraph2: 'Ini bisa terjadi karena data yang dicari tidak lagi tersedia untuk Anda.',
      //dataNotFoundParagraph3: 'Access any of the links in the menu to get back on track.',
      dataNotFoundParagraph3: 'Akses salah satu link di menu untuk kembali ke jalur.',
      //pageNotFoundTitle: 'Page Not Found',
      pageNotFoundTitle: 'Halaman tidak ditemukan',
      //pageNotFoundParagraph1: 'We could not find the page you requested.',
      pageNotFoundParagraph1: 'Kami tidak dapat menemukan halaman yang Anda minta.',
      //pageNotFoundParagraph2: 'Access any of the links in the menu to get back on track.',
      pageNotFoundParagraph2: 'Akses salah satu tautan di menu untuk kembali.',
      //objectNotFoundTitle: 'Object Not Found',
      objectNotFoundTitle: 'Objek tidak dapat ditemukan',
      //objectNotFoundParagraph1: 'We could not find any data for the following id: <strong>{{id}}</strong>.',
      objectNotFoundParagraph1: 'Kami tidak dapat menemukan data apa pun untuk id berikut: <strong>{{id}}</strong>.',
      //objectNotFoundParagraph2: 'This could have happened because the object is no longer available to you.',
      objectNotFoundParagraph2: 'Ini bisa terjadi karena objek tidak lagi tersedia untuk Anda.',
      //objectNotFoundParagraph3: 'Access any of the links in the menu to get back on track.'
      objectNotFoundParagraph3: 'Akses salah satu tautan di menu untuk kembali.',
    },
    api: {
      //stats: 'We were unable to retrieve statistics from the API. If the problem persists, please contact support.',
      stats: 'Kami tidak dapat mengambil statistik dari API. Jika masalah tetap muncul, harap hubungi dukungan.',
      //title: 'Sorry, there appears to be a problem',
      title: 'Maaf, sepertinya ada masalah',
      noConnection: {
        //title: "Connection Loss",
        title: 'Koneksi Terputus',
        //noConnection: "<p>We've lost connection to our back-end server. If the problem persists, please contact support.</p>"
        noConnection:
          '<p>Kami telah kehilangan koneksi ke server back-end kami. Jika masalah terus berlanjut, silakan hubungi dukungan.</p>',
      },
      //generic: "<p>We're experiencing problems interacting with our back-end system. If the problem persists, please contact support.</p>",
      generic:
        '<p>Kami mengalami masalah saat berinteraksi dengan sistem back-end kami. Jika masalah tetap muncul, harap hubungi dukungan.</p>',
      unauthorised: {
        //title: "Unauthorised Operation",
        title: 'Operasi Tidak Sah',
        //unauthorised: "<p>You do not have the permissions to perform the operation you requested.</p>"
        unauthorised: '<p>Anda tidak memiliki izin untuk melakukan operasi yang Anda minta.</p>',
      },
    },
  },
  field: {
    //selfServiceBudget: 'Budget',
    selfServiceBudget: 'Anggaran',
    email: 'Email',
    //emailPreferences: 'Email Preferences',
    emailPreferences: 'Preferensi Email',
    //password: 'Password',
    password: 'Kata Sandi',
    //confirmPassword: 'Confirm Password',
    confirmPassword: 'Konfirmasi Kata Sandi',
    //locale: 'Locale',
    locale: 'Domisili',
    //smoothing: 'Ad Delivery Style',
    smoothing: 'Gaya Penayangan Iklan',
    //startDate: 'Start',
    startDate: 'mulai',
    status: 'Status',
    userState: 'Status',
    //displayName: 'Display Name',
    displayName: 'Tampilan Nama',
    //displayNameHint: 'The display name is used to refer to you throughout the application',
    displayNameHint: 'Tampilan Nama digunakan untuk merujuk Anda di seluruh aplikasi',
    //endDate: 'End',
    endDate: 'Berakhir',
    //executionStartTime: 'Run Date',
    executionStartTime: 'Tanggal Berjalan',
    //name: 'Name'
    name: 'Nama',
  },
  settings: {
    //settingsPlural: 'Settings',
    settingsPlural: 'Pengaturan',
    //about: 'About',
    about: 'Tentang',
    //signOut: 'Sign Out'
    signOut: 'Keluar',
  },
  signIn: {
    //accountLocked: 'Your account has been locked due to too many failed login attempts. You have been sent an email with which you can reset your password, unlock your account and sign in.',
    accountLocked:
      'Akun Anda telah dikunci karena terlalu banyak upaya login yang gagal. Anda telah dikirimi email untuk mengatur ulang kata sandi, membuka kunci akun Anda dan masuk. ',
    //alreadyUser: 'Already a user?',
    alreadyUser: 'Sudah menjadi pengguna?',
    //confirmPasswordMismatch: "The passwords you entered do not match. Ensure you've entered the same password both times.",
    confirmPasswordMismatch:
      'Kata sandi yang Anda masukkan tidak cocok. Pastikan Anda telah memasukkan kata sandi yang sama dua kali.',
    //differentAccount: 'Sign in with a different account',
    differentAccount: 'Masuk dengan akun lain',
    //emailFirst: "Your account's email address must be entered before its password can be reset.",
    emailFirst: 'Masukkan alamt email Anda sebelum kata sandinya dapat disetel ulang.',
    //emailRequired: "We need your account's email address before we can sign you in.",
    emailRequired: 'Kami membutuhkan alamat email akun Anda sebelum kami dapat memasukkan Anda.',
    //expiredLinkExplanation: 'It looks like you clicked on an invalid or expired password reset link. Please try resetting your password again.',
    expiredLinkExplanation:
      'Sepertinya Anda mengklik link setel ulang sandi yang tidak valid atau kedaluwarsa. Silakan coba setel ulang sandi Anda lagi.',
    //forgotPassword: 'Forgot your password?',
    forgotPassword: 'Lupa kata sandi anda?',
    //humanConfirmationFailure: "There's a problem obtaining registration confirmation for your account. Try again and let us know if the problem persists.",
    humanConfirmationFailure:
      'Ada masalah saat mendapatkan konfirmasi pendaftaran untuk akun Anda. Coba lagi dan beri tahu kami jika masalah terus berlanjut.',
    //nameRequired: "We need a name with which to register you as a new user.",
    nameRequired: 'Kami membutuhkan nama yang dapat digunakan untuk mendaftarkan Anda sebagai pengguna baru.',
    //passwordResetTooMuchHeading: 'Too Many Password Resets',
    passwordResetTooMuchHeading: 'Terlalu Banyak Pengaturan Ulang Kata Sandi',
    //passwordResetTooMuchExplanation: 'You have tried to reset your password too often in a short space of time. You cannot reset your password for the next little while.',
    passwordResetTooMuchExplanation:
      'Anda terlalu sering mencoba mengatur ulang kata sandi dalam waktu singkat. Anda tidak dapat mengatur ulang kata sandi Anda untuk sementara waktu. ',
    //passwordResetEmailSentHeading: 'Password Reset Email Sent',
    passwordResetEmailSentHeading: 'Email Reset Password Terkirim',
    //passwordResetEmailSentExplanation: 'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.',
    passwordResetEmailSentExplanation:
      'Periksa email Anda untuk mencari tautan untuk mengatur ulang kata sandi Anda. Jika tidak muncul dalam beberapa menit, periksa folder spam Anda. ',
    //passwordHint: 'Your password must be at least 8 characters in length',
    passwordHint: 'Password Anda harus terdiri dari setidaknya 8 karakter',
    //passwordLinkExpired: 'Your reset password link has expired. Enter your email address to generate another link.',
    passwordLinkExpired:
      'Tautan setel ulang kata sandi Anda telah kedaluwarsa. Masukkan alamat email Anda untuk mengirim ulang.',
    //passwordRequired: "Supply a password to sign in.",
    passwordRequired: 'Berikan password untuk masuk.',
    //passwordSimple: 'The supplied password is too easily guessed and should be something less common.',
    passwordSimple: 'Password yang diberikan terlalu mudah ditebak dan seharusnya menjadi sesuatu yang tidak umum.',
    //registerAsUserHeading: 'Register as a User',
    registerAsUserHeading: 'Daftar sebagai Pengguna',
    //registerAsUserButton: 'Register',
    registerAsUserButton: 'Daftar',
    //registerEmailRequired: 'We need an email address to register you as a new user.',
    registerEmailRequired: 'Kami memerlukan alamat email untuk mendaftarkan Anda sebagai pengguna baru.',
    //registerNewUser: 'Register as a new user',
    registerNewUser: 'Daftar sebagai pengguna baru',
    //registerSuccessHighlight: 'You have successfully registered as a new user!',
    registerSuccessHighlight: 'Anda telah berhasil terdaftar sebagai pengguna baru!',
    //registerSuccessParagraph1: 'You have been sent an email with more information.',
    registerSuccessParagraph1: 'Anda telah dikirimi email dengan informasi lebih lanjut.',
    //registerSuccessParagraph2: 'Click on the link in your email to create your password for your account and sign in.',
    registerSuccessParagraph2: 'Klik pada link di email Anda untuk membuat kata sandi untuk akun Anda dan masuk.',
    //resetPasswordHeading: 'Reset Your Password',
    resetPasswordHeading: 'Setel ulang kata sandi Anda',
    //resetPasswordButton: 'Send Password Reset Email',
    resetPasswordButton: 'Kirim Email Setel Ulang Kata Sandi',
    //resetPasswordInfo: 'Enter your email address and we will send you a link to reset your password.',
    resetPasswordInfo:
      'Masukkan alamat email Anda dan kami akan mengirimkan link untuk menyetel ulang kata sandi Anda.',
    //signInButton: 'Sign in',
    signInButton: 'Masuk',
    //signInHeading: 'Sign in',
    signInHeading: 'Masuk',
    //updatePasswordHeading: 'Update Your Password',
    updatePasswordHeading: 'Perbarui Kata Sandi Anda',
    //updatePasswordButton: 'Update Password'
    updatePasswordButton: 'Perbarui Kata Sandi',
  },
  validation: {
    //required: 'Required',
    required: 'Wajib',
    //pattern: "Text must be {{minLength}}-{{maxLength}} characters long.",
    pattern: 'Teks harus terdiri dari {{minLength}} - {{maxLength}} karakter.',
    //maxSize: "The uploaded file is {{actual}}kb in size, which is greater than the {{maximum}}kb maximum",
    maxSize: 'File yang diunggah berukuran {{actual}} kb, yang lebih besar dari maksimum {{maximum}} kb',
    //fileType: "The uploaded file must be one of the following types: {{allowedTypes}}",
    fileType: 'File yang diunggah harus salah satu dari jenis berikut: {{allowedTypes}}',
    //minPrice: "The minimum value is {{minValue}}",
    minPrice: 'Nilai minimum adalah {{minValue}}',
    maxPrice: 'Nilai maksimum ialah {{maxValue}}',
    number: 'Nombor tidak sah',
    //url: "The URL is not in a valid form.",
    url: 'URL tidak dalam bentuk yang valid.',
    //endDateStartDate: 'The end date must be after the start date.',
    endDateStartDate: 'Tanggal akhir harus setelah tanggal mulai.',
    error: {
      //validation: '<strong>Invalid submission:</strong> Please check the details above and try again.',
      validation: '<strong> Pengiriman tidak valid: </strong> Harap periksa detail di atas dan coba lagi.',
      //'must.be.greater.than.or.equal': '{{value}} must be greater than {{minValue}}.',
      'must.be.greater.than.or.equal': '{{value}} harus lebih besar dari {{minValue}}.',
      //'non.empty': 'Required'
      'non.empty': 'Wajib',
      'auth.failed': 'Kami tidak dapat membuat Anda masuk. Kredensial tidak cocok dengan akun mana pun.',
    },
    warning: {
      //"lineItem.start.date.before.end.date": "The campaign's start date must be before its end date",
      'lineItem.start.date.before.end.date': 'Tanggal mulai Kampany Iklan harus sebelum tanggal akhirnya',
      //"lineItem.end.date.before.today": "The campaign's end date must be after the current date and time",
      'lineItem.end.date.before.today': 'Tanggal akhir Kampanye Iklan harus setelah tanggal dan waktu sekarang',
      //"lineItem.invalid.budget.enddate": "The campaign must end within 30 days"
      'lineItem.invalid.budget.enddate': 'Kampanye Iklan harus berakhir dalam 100 hari',
    },
  },
};
