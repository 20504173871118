import {Component, OnInit} from '@angular/core';
import {UserResource} from '@app/@core/@rest/users/user-resource.service';
import {combineLatest, EMPTY, Observable, switchMap, tap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {LocaleIdService} from '@app/@i18n/services/locale-id.service';
import {DefaultCurrencyCodeService} from '@app/@i18n/services/default-currency-code.service';
import {I18nLangService} from '@app/@i18n/services/i18n-lang.service';
import {TimeZoneService} from '@app/@i18n/services/time-zone.service';
import {AccessTokenStore} from '@app/@core/auth/access-token-store.service';
import {UserService} from '@app/@core/users/user.service';
import {AuthUserPermsService} from '@app/@core/users/auth-user-perms.service';
import {UserProfileService} from '@app/@core/users/user-profile.service';
import {NetworkService} from '@app/@core/admin/network.service';
import {SignedInContext, SignedInContextService} from '@app/@shells/signed-in-shell/signed-in-context.service';
import {LocaleDataLoaderService} from '@app/@i18n/services/locale-data-loader.service';

@Component({
  selector: 'app-shell',
  templateUrl: './signed-in-shell.component.html',
  styleUrls: ['./signed-in-shell.component.scss'],
})
export class SignedInShellComponent implements OnInit {
  errorKey?: string;
  signedInContext$!: Observable<SignedInContext>;
  masqueraderUsername?: string;
  username?: string;

  constructor(
    private userResource: UserResource,
    private localeIdService: LocaleIdService,
    private userService: UserService,
    private authUserPermsService: AuthUserPermsService,
    private networkService: NetworkService,
    private defaultCurrencyCodeService: DefaultCurrencyCodeService,
    private userProfileService: UserProfileService,
    private i18nLangService: I18nLangService,
    private timeZoneService: TimeZoneService,
    private accessTokenStore: AccessTokenStore,
    private signedInContextService: SignedInContextService,
    private localeDataLoaderService: LocaleDataLoaderService,
  ) {}

  ngOnInit() {
    this.signedInContext$ = this.getSignedInContext$();
    const accessToken = this.accessTokenStore.getAccessToken();
    this.masqueraderUsername = accessToken?.masquerader_username;
    this.username = accessToken?.username;
    this.signedInContextService.event$().subscribe(_context => {
        this.signedInContext$ = this.getSignedInContext$();
      }
    );
  }

  private getSignedInContext$(): Observable<SignedInContext> {
    return combineLatest({
      network: this.networkService.getNetwork(),
      user: this.userService.getUser(),
      authUserPerms: this.authUserPermsService.getAuthUserPerms(),
      userProfile: this.userProfileService.getUserProfile(),
    }).pipe(
      // load and register locale data
      switchMap(({network, user, authUserPerms, userProfile}) =>
        this.localeDataLoaderService.localeDataLoader(user.locale)
          .pipe(map(() => ({network, user, authUserPerms, userProfile})))
      ),
      // configure i18n services
      tap(({network, user, authUserPerms, userProfile}) => {
        this.defaultCurrencyCodeService.setDefaultCurrencyCode(network.defaultCurrency);
        this.localeIdService.setLocale(user.locale);
        this.i18nLangService.setLanguage(userProfile.lang);
        this.timeZoneService.setZoneName(network.timeZone);
      }),
      map(({network, user, authUserPerms, userProfile}) =>
        ({  network, networkData: undefined, user, userProfile, authUserPerms})),
      tap((context) => {
        this.signedInContextService.next(context);
      }),
      catchError((error) => {
        this.errorKey = error.error?.code || error.statusText || error;
        return EMPTY;
      }),
    );
  }

}
