
// ng.model.message.ErrorMessage
export interface ErrorMessage {
  code: string
  text: string
  type: 'ErrorMessage'
}


export function isErrorMessage(message: any): message is ErrorMessage {
  return message.type === 'ErrorMessage' || message.type === 'ValidationErrorMessage';
}
