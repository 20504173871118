<ngb-alert [dismissible]="false" type="info">
  <div class="mb-2">{{ 'campaign.postPayment.reserveParagraph1' | translate }}</div>
  <div class="mb-2">{{ 'campaign.postPayment.reserveParagraph2' | translate }}</div>
  <div>
    <button type='button' class='btn btn-primary btnPayment' (click)='onSubmit()'
            [disabled]="campaignDraft.campaignForm.dirty || (submit$ && !errorKey)">
      <i *ngIf="!submit$ || errorKey" class='fas fa-thumbs-up'></i>
      <i *ngIf="submit$ && !errorKey" class='fas fa-cog fa-spin'></i>
      <span>{{ 'campaign.postPayment.submitButton' | translate }}</span>
    </button>
  </div>
  <ngb-alert *ngIf='errorKey' class='mt-2 mb-0' type='danger' [dismissible]='false'>
    {{ 'campaign.postPayment.failed' | translate }}
  </ngb-alert>
  <ngb-alert *ngIf='campaignDraft.campaignForm.dirty' class='mt-2 mb-0' type='warning' [dismissible]='false'>
    {{ 'campaign.postPayment.saveFirst' | translate }}
  </ngb-alert>
</ngb-alert>
<ng-container *ngIf="submit$ | async"></ng-container>
