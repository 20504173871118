<div class='card'>
  <form name='registerForm' (ngSubmit)='onSubmit()' [formGroup]='registerForm' novalidate autocomplete='off'>
    <div class='card-header'>
      <h3 translate='signIn.registerAsUserHeading'></h3>
    </div>
    <div class='card-body'>
      <ngb-alert *ngIf='error' type='danger' [dismissible]='false'>{{ error }}</ngb-alert>
      <div *ngIf='!hasRegistered' class='d-grid gap-2'>
        <app-email-field [registerForm]='registerForm' [showValidation]='showValidation'></app-email-field>
        <app-name-field [registerForm]='registerForm' [showValidation]='showValidation'></app-name-field>
        <app-company-field *ngIf='configService.getNetworkData()?.advertiser?.companyName'
                           [registerForm]='registerForm' [showValidation]='showValidation'></app-company-field>
        <app-vat-id-field *ngIf='configService.getNetworkData()?.advertiser?.vatId'
                          [registerForm]='registerForm' [showValidation]='showValidation'></app-vat-id-field>
        <app-phone-number-field *ngIf='configService.getNetworkData()?.advertiser?.phoneNumber'
                                [registerForm]='registerForm'
                                [showValidation]='showValidation'></app-phone-number-field>
        <app-locale-field [registerForm]='registerForm' *ngIf='!hideLocale'></app-locale-field>
        <app-sign-up-coupon [couponForm]='registerForm.controls.couponFormGroup' *ngIf='showCoupons'></app-sign-up-coupon>
        <app-captcha-field [registerForm]='registerForm' [showValidation]='showValidation'></app-captcha-field>
        <div>
          <span translate='signIn.alreadyUser'></span>
          <a [routerLink]="configService.getUrl('/sign-in')" translate='signIn.signInButton'></a>
        </div>
      </div>
      <div *ngIf='hasRegistered' class='d-grid gap-1'>
        <ngb-alert type='success' [dismissible]='false' [innerHTML]="'signIn.registerSuccessHighlight' | translate">
        </ngb-alert>
        <p translate='signIn.registerSuccessParagraph1'></p>
        <p translate='signIn.registerSuccessParagraph2'></p>
      </div>
    </div>
    <div class='card-footer'>
      <ng-container *ngIf='!hasRegistered'>
        <div *ngIf='hasSignUpCondition'>
          <app-sign-up-condition-field [registerForm]='registerForm' [showValidation]='showValidation'></app-sign-up-condition-field>
        </div>
        <div *ngIf='showValidation && registerForm.invalid' class='text-center my-2 text-danger'>
          {{ 'signIn.regError' | translate}}
        </div>
        <div [innerHTML]="'signIn.registerTerms' | translate"></div>
        <div class='text-end mb-2'>
          <button class='btn btn-primary' type='submit' name='register' [disabled]='signupRequest$'>
            <i *ngIf='signupRequest$' class='fas fa-cog fa-spin'></i>
            <span translate='signIn.registerAsUserButton'></span>
          </button>
        </div>
      </ng-container>
      <div class='text-end small'><span translate='adn.poweredBy'></span></div>
    </div>
  </form>
</div>
<ng-container *ngIf='signupRequest$ | async'></ng-container>
