<ng-template #modal_new_session let-modal>
  <app-sign-in-form [modal]="modal" [isMasquerade]="hasMasqueradeUser()"
                    [canChangeUser]="true" [goToSignInLink]="true"
                    [canAnonCampaign]="false"
                    [canResetPassword]="false"
                    [canNavToSignIn]="true"
                    [canRegNewUser]="false"
                    [canUpdateLang]="false"
  >
  </app-sign-in-form>
</ng-template>
<ng-template #modal_current_session let-modal>
  <app-sign-in-form [modal]="modal" [isMasquerade]="hasMasqueradeUser()"
                    [canChangeUser]="false" [goToSignInLink]="true"
                    [canAnonCampaign]="false"
                    [canResetPassword]="false"
                    [canNavToSignIn]="true"
                    [canRegNewUser]="false"
                    [canUpdateLang]="false"
  >
  </app-sign-in-form>
</ng-template>
<ng-container *ngIf="signInCommand$ | async">
</ng-container>
