/**
 * Copyright © 2023 Adnuntius AS.
 */

export const TRANSLATIONS_EL = {
  adn: {
    copyright: 'Πνευματική Ιδιοκτησία © 2023 Adnuntius AS',
    releaseId: 'Αναγνωριστικό Έκδοση: {{id}}',
    poweredBy: 'Με την υποστήριξη του Adnuntius AS',
  },
  grammar: {
    booleanOr: 'Ή',
  },
  button: {
    upload: {
      asset: 'Μεταφόρτωση',
      drop: 'Βάλτε το αρχείο σας εδώ ή πατήστε το κουμπί μεταφόρτωσης',
    },
    archive: 'Αρχειοθέτηση',
    activate: 'Ενεργοποίηση',
    applyCustomRange: 'Εφαρμογή',
    cancel: 'Ακύρωση',
    clear: 'Διαγραφή',
    close: 'Κλείσημο',
    save: 'Αποθήκευση',
    undo: 'Αναίρεση',
    today: 'Σήμερα',
    newCampaign: 'Δημιουργία Καμπάνιας',
  },
  locale: {
    ar: 'Αραβικά - Arabic - عربى',
    ar_JO: 'Αραβικά (Ιορδανία) - Arabic (Jordan) - العربية (الأردن)',
    ar_EG: 'Αραβικά (Αίγυπτος) - Arabic (Egypt) - العربية (مصر)',
    ar_AE: 'Αραβικά (UAE) - Arabic (UAE) - العربية (الإمارات العربية المتحدة)',
    my: 'Βιρμανικά - Burmese - ဗမာ',
    en: 'Αγγλικά - English',
    en_AU: 'Αγγλικά (Αυστραλία) - English (Australia)',
    en_CA: 'Αγγλικά (Καναδάς) - English (Canada)',
    en_US: 'Αγγλικά (ΗΠΑ) - English (USA)',
    en_GB: 'Αγγλικά (Μεγάλη Βρετανία) - English (Great Britain)',
    es: 'Iσπανικά - Spanish - español',
    es_ES: 'Ισπανικά (Ησπανία) - Spanish (Spain) - español (españa)',
    es_AR: 'Ισπανικά (Αργεντινή) - Spanish (Argentina) - español (argentina)',
    et: 'Εσθονικά - Estonian - eesti keel',
    fi: 'Φινλανδικά - Finnish - Suomalainen',
    id: 'Ινδονησιακά - Indonesian - bahasa Indonesia',
    is: 'Ισλανδικά - Icelandic - íslensku',
    de: 'Γερμανικά - German - Deutsch',
    de_DE: 'Γερμανικά (Γερμανία) - German (Germany) - Deutsch (Deutschland)',
    de_AT: 'Γερμανικά (Αυστρία) - German (Austria) - Deutsch (Österreich)',
    de_CH: 'Γερμανικά (Ελβετία) - German (Switzerland) - Deutsch (Schweiz)',
    ms: 'Μαλαισίας - Malay - Bahasa Melayu',
    ms_BN: 'Μαλαισίας (Μπρουνέι) - Malay (Brunei) - Bahasa Melayu (Brunei)',
    ms_MY: 'Μαλαισίας (Μαλαισία) - Malay (Malaysia) - Bahasa Melayu (Malaysia)',
    nl: 'Ολλανδικά - Dutch',
    nl_BE: 'Ολλανδικά (Βελγία) - Dutch (Belgium) - Nederlands (België)',
    nl_NL: 'Ολλανδικά (Ολλανδία) - Dutch (Netherlands) - Nederlands (Nederland)',
    no: 'Νορβηγικά - Norwegian - norsk',
    sv: 'Σουηδικά - svenska',
    sv_SE: 'Σουηδικά (Σουηδία) - Swedish (Sweden) - svenska (Sverige)',
    sv_FI: 'Σουηδικά (Φινλανδία) - Swedish (Finland) - svenska (Finland)',
    pl: 'Πολωνικά - Polish - Polskie',
    pt: 'Πορτογαλικά - Portuguese - Português',
    pt_BR: 'Πορτογαλικά (Βραζιλία) - Portuguese (Brazil) - Português (Brasil)',
    pt_PT: 'Πορτογαλικά (Πορτογαλία) - Portuguese (Portugal) - Português (Portugal)',
    ru: 'Ρωσικά - Russian - русский',
    el: 'Ελληνικά - Greek',
    fr: 'Γαλλικά - French - français',
    da: 'Δανικά - Danish - Dansk',
    th: 'Ταϊλανδέζικα - Thai - ไทย',
  },
  campaign: {
    archivedHeading: 'Αρχειοθετημένες Καμπάνιες',
    archivedNoResults: 'Δεν βρέθηκαν αρχειοθετημένες καμπάνιες.',
    archivedShowLink: 'Δείξτε αρχειοθετημένες καμπάνιες',
    adRejected: {
      explanation:
        'Προσαρμόστε τη διαφήμισή σας και υποβάλετε εκ νέου την καμπάνια σας για έλεγχο, προκειμένου να εμφανίσει τις προϋπολογισμένες εμφανίσεις.',
      explanationGeneral:
        'Η διαφήμιση #{{id}} απέτυχε με έλεγχο και η καμπάνια σας αποκλείεται από την εμφάνιση εμφανίσεων. Δείτε παρακάτω για περισσότερες λεπτομέρειες.',
      ADULT_CONTENT: {
        name: 'Περιεχόμενο για Ενήλικες',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή περιέχει ή οδηγεί σε μια σελίδα προορισμού που περιέχει περιεχόμενο για ενήλικες.',
      },
      BLANK_CONTENT: {
        name: 'Κενό Περιεχόμενο',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή αποδίδεται ως κενή. Βεβαιωθείτε ότι το απαιτούμενο περιεχόμενο έχει παρασχεθεί σωστά.',
      },
      MALFORMED_CLICK_THROUGH: {
        name: 'Διεύθυνση URL Προορισμού με Λανθασμένη Μορφή',
        explanation: 'Η διαφήμιση απορρίφθηκε επειδή η διεύθυνση URL προορισμού κλικ δεν λειτουργεί σωστά.',
      },
      DRUG_RELATED_CONTENT: {
        name: 'Περιεχόμενο που Σχετίζεται με Ναρκωτικά',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή αυτή ή η σελίδα προορισμού της προωθεί περιεχόμενο ή προϊόντα που σχετίζονται με ναρκωτικά. Αυτό μπορεί να περιλαμβάνει καπνό, αλκοόλ, φαρμακευτικά ή άλλα νομικά ή παράνομα ναρκωτικά.',
      },
      WEAPON_RELATED_CONTENT: {
        name: 'Περιεχόμενο που Σχετίζεται με Όπλα',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή η σελίδα προορισμού της προωθεί όπλα ή περιεχόμενο που σχετίζεται με όπλα. Αυτό μπορεί να περιλαμβάνει περιεχόμενο που προωθεί επικίνδυνα μαχαίρια, όπλα, ανταλλακτικά όπλων και άλλο σχετικό περιεχόμενο.',
      },
      HATE_SPEECH_CONTENT: {
        name: 'Περιεχόμενο Μίσους',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή περιέχει ή οδηγεί σε μια σελίδα προορισμού που περιέχει περιεχόμενο ρητορικής μίσους. Αυτό μπορεί να περιλαμβάνει περιεχόμενο που προωθεί τη βία, την παρενόχληση, το ρατσισμό, το μίσος και τη μισαλλοδοξία.',
      },
      LANDING_PAGE_REJECTED: {
        name: 'Η Σελίδα Προορισμού Απορρίφθηκε',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή η σελίδα προορισμού ήταν χαμηλής ποιότητας ή δύσκολη η αλληλεπίδρασή της. Αυτό μπορεί να περιλαμβάνει σελίδες που απενεργοποιούν τις λειτουργίες πλοήγησης του προγράμματος περιήγησης, περιλαμβάνουν περιεχόμενο χαμηλής αξίας ή οδηγούν σε διεύθυνση email ή αρχείο, όπως εικόνα, ήχο, βίντεο ή έγγραφο, το οποίο απαιτεί άνοιγμα μιας πρόσθετης εφαρμογής.',
      },
      OFFENSIVE_CONTENT: {
        name: 'Προσβλητικό Περιεχόμενο',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή περιέχει ή οδηγεί σε μια σελίδα προορισμού που περιέχει προσβλητικό ή ακατάλληλο περιεχόμενο. Βεβαιωθείτε ότι οι διαφημίσεις και οι σελίδες προορισμού σας εμφανίζουν μόνο περιεχόμενο κατάλληλο για ανηλίκους.',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        name: 'Μη Aποδεκτά Oπτικά Eφέ',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή χρησιμοποιεί μη αποδεκτά οπτικά εφέ. Αυτό περιλαμβάνει κινούμενα GIF με ρυθμό καρέ υψηλότερο από 5 καρέ ανά δευτερόλεπτο.',
      },
      DECEPTIVE_CONTENT: {
        name: 'Παραπλανητικό Περιεχόμενο',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή περιέχει παραπλανητικούς ισχυρισμούς ή πληροφορίες. Στα παραδείγματα περιλαμβάνονται διαφημίσεις που κάνουν επιστημονικά αδύνατες αξιώσεις, προσφέρουν εγγυημένα αποτελέσματα χωρίς πολιτική επιστροφής χρημάτων ή εμφανίζουν μαρτυρίες που ισχυρίζονται συγκεκριμένα αποτελέσματα χωρίς σύνδεσμο για επαλήθευση τρίτου μέρους. Οι διαφημίσεις ενδέχεται επίσης να απορριφθούν εάν το δημιουργικό έχει σχεδιαστεί έτσι ώστε να μοιάζει με κουμπιά λήψης λογισμικού, επίσημες προειδοποιήσεις συστήματος ή λειτουργίες ιστοσελίδων όπως μενού, αποτελέσματα αναζήτησης ή άλλο περιεχόμενο ιστότοπου.',
      },
      GAMBLING_CONTENT: {
        name: 'Περιεχόμενο Τζόγου',
        explanation: 'Η διαφήμιση απορρίφθηκε επειδή περιέχει περιεχόμενο που σχετίζεται με τον τζόγο.',
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        name: 'Απαγορευμένο Περιεχόμενο που Σχετίζεται με τις Εκλογές',
        explanation:
          'Η διαφήμιση απορρίπτεται επειδή περιέχει απαγορευμένο περιεχόμενο που σχετίζεται με κυβερνητικές εκλογές ή εκλεγμένους κυβερνητικούς αξιωματούχους.',
      },
      SHOCKING_CONTENT: {
        name: 'Συγκλονιστικό Περιεχόμενο',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή περιέχει συγκλονιστικό περιεχόμενο, όπως βωμολοχίες, φρικτές ή αηδιαστικές εικόνες, ή προωθήσεις που έχουν σχεδιαστεί για να σοκάρουν ή να τρομάξουν.',
      },
      MALWARE_CONTENT: {
        name: 'Περιεχόμενο Κακόβουλου Λογισμικού',
        explanation:
          'Η διαφήμιση απορρίφθηκε επειδή αυτή ή η σελίδα προορισμού της περιέχει ή οδηγεί σε απαράδεκτο περιεχόμενο όπως κακόβουλο λογισμικό.',
      },
    },
    anon: {
      signInText: 'Δημιουργήστε μια καμπάνια χωρίς να συνδεθείτε',
      heading: 'Δημιουργήστε μια καμπάνια παρακάτω.',
      beforeText:
        'Τα στοιχεία της καμπάνιας σας αποθηκεύονται στο τρέχον πρόγραμμα περιήγησής σας. Πριν από την παράδοση και την πρόσβαση της καμπάνιας σας αλλού, θα πρέπει&nbsp;',
      linkText: 'να δημιουργήστε έναν λογαριασμό και να συνδεθείτε',
      postText: '.',
    },
    layout: {
      title: 'Επιλέξτε Ένα Σχέδιο Διαφήμισης',
      missing: 'Η διάταξη που επιλέξατε προηγουμένως δεν είναι διαθέσιμη για την τρέχουσα επιλογή διαφήμισης.',
      missingPreview: 'Πρέπει να επιλέξετε μια έγκυρη διάταξη για να γίνει προεπισκόπηση ενός δημιουργικού.',
    },
    device: {
      title: 'Επιλογές Στόχευσης Συσκευής',
      descriptionPrefix: 'Η διαφήμισή σας θα εμφανιστεί:&nbsp;',
      instructions:
        'Καθορίστε μία ή περισσότερες επιλογές συσκευής για να περιορίσετε το πού θα εμφανίζεται η διαφήμισή σας',
      any: 'οποιαδήποτε συσκευή',
    },
    location: {
      title: 'Επιλογές Στόχευσης Τοποθεσίας',
      descriptionPrefix: 'Η διαφήμισή σας θα εμφανιστεί:&nbsp;',
      instructions: 'Καθορίστε μία ή περισσότερες τοποθεσίες για να περιορίσετε το πού θα εμφανίζεται η διαφήμισή σας',
      any: 'οποιαδήποτε τοποθεσία',
    },
    product: {
      title: 'Επιλέξτε Μια Επιλογή Διαφήμισης',
      adDesignChoice: 'Διατίθενται <strong>{{count}} σχέδια διαφημίσεων</strong> σε αυτά τα μεγέθη:',
      cpm: '<strong>{{value}}</strong> ανά χίλιες εμφανίσεις',
      minSpend: 'Ελάχιστη δαπάνη <strong>{{value}}</strong>',
      maxSpend: '<strong>{{value}}</strong> μέγιστη δαπάνη',
    },
    ad: {
      title: 'Σχεδιάστε τη Διαφήμισή Σας',
      defaultName: 'Διαφήμισή για {{campaignName}}',
      layoutFirst: 'Επιλέξτε ένα σχέδιο διαφήμισης πριν εισαγάγετε τα στοιχεία της διαφήμισής σας',
      deletedAsset: 'Αυτό το στοιχείο αναφέρεται σε μια διαγραμμένη εικόνα. Ανεβάστε ένα νέο.',
      imageHint: '{{mediaTypes}}, Μέγιστο: {{maxSize}}kb',
      imageHintOptional: '{{mediaTypes}}, Μέγιστο: {{maxSize}}kb (Προαιρετικός)',
      adSizeTitle: 'Μέγεδος Διαφήμισης',
      create: 'Δημιουργήστε Τη Διαφήμισή Σας',
      add: 'Προσθέστε Άλλη Διαφήμιση',
      count: 'Διαφήμιση <strong>#{{count}}</strong>',
      maximum: 'Μπορούν να προστεθούν έως τρεις διαφημίσεις στην καμπάνια σας',
      choiceOptional: '(Καμία επιλογή)',
      remove: 'Κατάργηση Διαφήμισης',
      runningPreviewTitle: 'Η Διαφήμισή Σου, Μέγεθος {{width}}&times;{{height}}',
      previewTitle: 'Προεπισκόπηση Διαγφημίσεων',
      border: 'Εμφάνιση περιγράμματος στη διαφήμιση',
      previewWithExample: 'Αυτή η προεπισκόπηση διαφήμισης περιλαμβάνει παραδείγματα δεδομένων για σκοπούς επίδειξης.',
      textCount: 'μέτρηση: {{count}}',
      textHintChars: '{{min}}&ndash;{{max}} χαρακτήρες',
      textHintCharsOptional: '{{min}}&ndash;{{max}} χαρακτήρες (Προαιρετικός)',
      urlHint: 'URL',
      urlHintOptional: 'URL (Προαιρετικός)',
    },
    addNewCampaign: `
      <h3>Δημιουργήστε Μια Καμπάνια Για Την Παράδοση Του Μηνύματός Σας</h3>
      <p>Καμπάνιες προβάλλουν διαφημίσεις στη διαφημιστική μας πύλη.</p>
      <p>Δημιουργήστε μια νέα καμπάνια με το παραπάνω κουμπί και προβάλλετε τις διαφημίσεις σας.</p>
      <p>Θα μπορείτε να δείτε τις καμπάνιες σας εδώ και να τις ακολουθήσετε κατά την παράδοσή τους.</p>
    `,
    campaign: 'Καμπάνια',
    campaignPlural: 'Καμπάνιες',
    copy: {
      copyText: 'Ένα αντίγραφο αυτής της καμπάνιας δημιουργήθηκε με επιτυχία.',
      copyTitle: 'Η καμπάνια αντιγράφηκε',
      copyPartialText:
        'Αυτή η καμπάνια έχει αντιγραφεί, αλλά δεν μπορούσε να αντιγραφεί όλο το διαφημιστικό υλικό μαζί της.',
      copyPartialTitle: 'Η Kαμπάνια Aντιγράφηκε Aλλά Όχι Όλο το Διαφημιστικό Υλικό',
    },
    copyAfterwards: 'Μετάβαση σε νέα καμπάνια μετά την αντιγραφή',
    defaultName: '{{productName}} Καμπάνια',
    detailsTitle: 'Καθορίστε Τα Στοιχεία Της Καμπάνιας Σας',
    draftedHeading: 'Πρόχειρες Καμπάνιες',
    draftedNoResults: 'Δεν βρέθηκαν πρόχειρες καμπάνιες.',
    endedHeading: 'Τελειωμένες Καμπάνιες',
    endedNoResults: 'Δεν βρέθηκαν τελειωμένες καμπάνιες.',
    expectedImpressions: 'Αντιστοιχεί σε περίπου {{imps}} εμφανίσεις',
    payment: {
      title: 'Πληρωμή',
      missing: 'Δεν σχετίζεται καμία πληρωμή με αυτήν την καμπάνια.',
      cancelled: 'Η κράτησή σας στο ακυρώθηκε και η καμπάνια σας δεν υποβλήθηκε για έλεγχο.',
      infoComplete: 'Μια πληρωμή {{price}} ολοκληρώθηκε {{time}}.',
      infoAuthorised: 'Μια πληρωμή {{price}} εγκρίθηκε {{time}}.',
      receiptLink: '<a href="{{link}}" target="_blank">Η απόδειξη πληρωμής σας είναι διαθέσιμη</a>.',
      registering: 'Εγγραφή συναλλαγής...',
      reserveTitle: 'Κάντε Κράτηση Πληρωμής και Υποβάλετε την Καμπάνια Σας για Έλεγχο',
      reserveButton: 'Κάντε Κράτηση Πληρωμής και Υποβάλετε την Καμπάνια Σας για Έλεγχο',
      reserveParagraph1:
        'Θα τοποθετήσουμε μια <strong>παρακράτηση στο λογαριασμό σας</strong> για το προϋπολογισμένο ποσό <strong>{{amount}}</strong>, το οποίο θα επιτρέψει τον έλεγχο και την εκτέλεση της καμπάνιας σας.',
      reserveParagraph2:
        'Μόλις ολοκληρωθεί η καμπάνια σας, θα λάβουμε είτε <strong>{{amount}}</strong> από τον λογαριασμό σας είτε το αναλογικό ποσό βάσει του πραγματικού αριθμού εμφανίσεων που προβάλλονται έναντι των αναμενόμενων <strong>{{imps}} εμφανίσεων</strong>.',
      failure:
        'Αντιμετωπίσαμε ένα πρόβλημα κατά την εγγραφή της συναλλαγής σας. Δοκιμάστε ξανά, και εάν το πρόβλημα παραμένει, επικοινωνήστε μαζί μας.',
      saveFirst: 'Θα πρέπει να αποθηκεύσετε τις αλλαγές σας προτού προχωρήσετε στην κράτηση πληρωμής',
      waitingForRedirect:
        'Μεταβιβάζουμε τις λεπτομέρειες της συναλλαγής σας στο {{paymentProvider}} και θα σας ανακατευθύνουμε εκεί σύντομα.',
      prepay: {
        detailsInfo: `
          <div class = "small">
            <p>Η καμπάνια σας θα παραδοθεί μόλις δεσμευτεί η πληρωμή και θα ελεγχθεί το περιεχόμενο της καμπάνιας σας.</p>
            <p>Η πληρωμή θα πραγματοποιηθεί μόνο μετά τη λήξη της καμπάνιας σας. Θα χρεωθείτε ανάλογα με τον αριθμό των εμφανίσεων που προσφέρει η καμπάνια σας και ποτέ περισσότερο από τον καθορισμένο προϋπολογισμό σας. Ισχύει, ωστόσο, ένα ελάχιστο τέλος {{minimumFee}}.</p>
            <p>Μόλις ολοκληρωθεί η καμπάνια σας και λήξει η πληρωμή, θα δημιουργηθεί μια αναφορά απόδοσης και μια απόδειξη.</p>
            <p>Θα μπορείτε να διακόψετε την προβολή της καμπάνιας σας ανά πάσα στιγμή, αλλά θα ισχύει το ελάχιστο τέλος {{minimumFee}}, ακόμη και αν δεν έχουν εμφανιστεί εμφανίσεις.</p>
          </div>
        `,
      },
      status: {
        CREATED: 'Δημιουργήθηκε',
        NOT_AUTHORISED: 'Χωρίς Εξουσιοδότηση',
        AUTHORISED: 'Εξουσιοδοτημένο',
        COMPLETED: 'Ολοκληρωμένο',
        CANCELLED: 'Ακυρωμένο',
      },
    },
    postPayment: {
      reserveParagraph1: 'Η πληρωμή για την καμπάνια σας έχει εγκριθεί.',
      reserveParagraph2: 'Εάν είστε ικανοποιημένοι με την καμπάνια σας, μπορείτε να την υποβάλετε για έλεγχο παρακάτω.',
      saveFirst:
        'Θα πρέπει να αποθηκεύσετε τις αλλαγές σας προτού προχωρήσετε στην υποβολή της καμπάνιας σας για έλεγχο.',
      failed:
        'Η υποβολή της καμπάνιας σας για έλεγχο αντιμετώπισε αποτυχία. Κάντε άλλη προσπάθεια ξανά και εάν το πρόβλημα παραμένει, επικοινωνήστε μαζί μας.',
      submitButton: 'Υποβάλετε την Καμπάνια Σας για Έλεγχο',
    },
    rejectedHeading: 'Απορριφθείσες Καμπάνιες',
    report: {
      title: 'Αναφορά',
      ungenerated: 'Μια αναφορά θα είναι διαθέσιμη για λήψη σύντομα.',
      generated: 'Μια <a href="{{link}}" target="_blank">αναφορά είναι διαθέσιμη για λήψη</a>.',
    },
    runningHeading: 'Καμπάνιες Σε Έλεγχο Ή Προβολή',
    runningNoResults: 'Δεν βρέθηκαν καμπάνιες σε έλευχο ή προβολή.',
    running: {
      budgetHeading: 'Θέση Προϋπολογισμού',
      budgetPosition:
        'Έχουν δαπανηθεί <strong>{{budgetSpent}}</strong> από <strong>τον προϋπολογισμό των {{budget}}</strong>.',
      cancelledPaymentParagraph1: 'Η κράτηση πληρωμής σας ακυρώθηκε.',
      cancelledPaymentParagraph2:
        'Εάν ακόμα θέλετε να συνεχίσετε με την καμπάνια σας, μπορείτε να δημιουργήσετε μια νέα συναλλαγή παρακάτω.',
      datesHeading: 'Ημερομηνίες Εκτέλεσης',
      dates: '<strong>{{startDate}}</strong> - <strong>{{endDate}}</strong>',
      datesExplanation:
        'Η καμπάνια σας εκτελείται για <strong>{{days}} ημέρες</strong>, που είναι το <strong>{{percentage}}</strong> της μέγιστης προγραμματισμένης διάρκειας ζωής της.',
      datesLeftExplanation:
        'Η καμπάνια σας θα σταματήσει να προβάλλεται σε <strong>{{days}} ημέρες</strong> εάν δεν επιτευχθούν οι προϋπολογισμένες εμφανίσεις σας.',
      datesSubmittedExplanation:
        'Η καμπάνια σας θα εκτελεστεί για <strong>{{days}} ημέρες</strong> εάν δεν επιτευχθούν οι προϋπολογισμένες εμφανίσεις σας.',
      impressions:
        'Η καμπάνια σας έχει προβάλει <strong>{{imps}} εμφανίσεις</strong>, που είναι <strong>{{percentage}} των προϋπολογισμένων εμφανίσεων</strong>.',
      impressionsRemaining: 'Απομένουν περίπου <strong>{{imps}} εμφανίσεις</strong> για προβολή.',
      statusPrefix: 'Η καμπάνια σου είναι ',
      successfulPaymentParagraph1: 'Η πληρωμή έχει δεσμευτεί και η καμπάνια σας έχει υποβληθεί για έλεγχο.',
      successfulPaymentParagraph2: 'Μόλις η καμπάνια σας περάσει τον έλεγχο, θα προβάλει τις διαφημίσεις σας!',
      stoppedParagraph1: 'Η καμπάνια σας έχει σταματήσει.',
      stoppedParagraph2:
        'Τυχόν αχρησιμοποίητος προϋπολογισμός καμπάνιας θα επιστραφεί και θα δημιουργηθεί μια αναφορά για την καμπάνια σας.',
      submittedParagraph1: 'Η καμπάνια σας έχει υποβληθεί για έλεγχο.',
      submittedParagraph2: 'Μόλις η καμπάνια σας περάσει τον έλεγχο, θα προβάλει τις διαφημίσεις σας!',
      unexpectedPaymentParagraph1: 'Η κράτηση πληρωμής σας δεν προχώρησε όπως είχε προγραμματιστεί.',
      unexpectedPaymentParagraph2:
        'Σε αυτό το στάδιο, είναι ίσως καλύτερα να αντιγράψετε αυτήν την καμπάνια και να ξεκινήσετε ξανά τη διαδικασία δημιουργίας μιας κράτησης πληρωμής.',
    },
    save: {
      button: 'Αποθηκεύστε τις Αλλαγές Σας',
      messageTitle: 'Η Καμπάνια Αποθηκεύτηκε',
      messageText: 'Η καμπάνια σας αποθηκεύτηκε με επιτυχία.',
    },
    smoothing: {
      title: 'Μέθοδος Προβολής Διαφημίσεων',
      true: 'Ομαλά',
      trueExplanation: "Η διαφήμισή σας θα προβληθεί ομαλά καθ 'όλη τη διάρκεια της καμπάνιας σας.",
      trueField:
        "<strong>Ομαλά</strong> - προβάλλετε ομαλά τις εμφανίσεις διαφημίσεων καθ 'όλη τη διάρκεια της καμπάνιας σας",
      false: 'Γρήγορα',
      falseExplanation: 'Η διαφήμισή σας θα παραδοθεί το συντομότερο.',
      falseField: '<strong>Γρήγορα</strong> - προβάλλετε τις προβολές διαφημίσεων το συντομότερο',
    },
    status: {
      STOPPED: 'Σταματησμένο',
      PROPOSED: 'Πρόχειρο',
      SUBMITTED: 'Σε Έλεγχο',
      NOT_READY: 'Χρειάζεται Περαιτέρω Αναθεώρηση',
      READY: 'Έτοιμο για Παράδοση',
      REJECTED: 'Απορριπτόμενο',
      SUBMISSION_READY: 'Έτοιμο για Υποβολή',
      RUNNING: 'Σε Προβολή',
      ENDED: 'Τελειωμένο',
    },
    statsHeading: 'Στατιστικά Παράδοσης',
    stop: {
      button: 'Διακοπή Παράδοσης Καμπάνιας',
      cancelButton: 'Ακύρωση Διακοπής Καμπάνιας',
      confirmButton: 'Επιβεβαίωση Διακοπής Καμπάνιας',
      failParagraph1: 'Αντιμετωπίσαμε ένα πρόβλημα και δεν μπορέσαμε να σταματήσουμε την καμπάνια σας.',
      failParagraph2: 'Προσπαθήστε να σταματήσετε ξανά την καμπάνια σας και αν το πρόβλημα παραμένει, ενημερώστε μας.',
      info1:
        'Η διακοπή μιας καμπάνιας δεν μπορεί να αντιστραφεί, αν και οποιαδήποτε καμπάνια μπορεί να αντιγραφεί και να παραδοθεί η νέα καμπάνια.',
      info2:
        'Με τη διακοπή της καμπάνιας σας, θα επιστραφεί τυχόν αχρησιμοποίητος προϋπολογισμός. Σημείωση: ισχύει μια ελάχιστη χρέωση {{money}}.',
      intro: 'Εάν σε οποιαδήποτε στιγμή θέλετε να διακόψετε την καμπάνια σας, μπορείτε να το κάνετε παρακάτω.',
    },
    validationProblemsPresent: `
      <p>Υπάρχουν ορισμένα προβλήματα επικύρωσης με την καμπάνια σας.</p>
      <p>Μόλις επιλυθούν τα προβλήματα επικύρωσης, μπορούμε να προχωρήσουμε στην κράτηση του ποσού του προϋπολογισμού σας στο λογαριασμό σας.</p>
      <p>Μετά από αυτό, η καμπάνια σας θα υποβληθεί για έλεγχο πριν από τη ζωντανή προβολή και την εμφάνιση εμφανίσεων.</p>
    `,
  },
  devices: {
    APPLE: 'Apple',
    UNKNOWN: 'Άγνωστο',
    CONSOLE: 'Κονσόλας',
    E_READER: 'E Reader',
    MOBILE: 'Έξυπνο Tηλέφωνο',
    SMART_WATCH: 'Έξυπνο Ρολόι',
    TABLET: 'Δισκίο',
    TV: 'Τηλεόραση',
  },
  emailPreferences: {
    CREATIVE_REJECTED: '<strong>Απαιτούνται προσαρμογές:</strong> ένας κριτικός ζητά μια αλλαγή στην καμπάνια μου',
    REPORT_COMPLETE:
      '<strong>Η αναφορά καμπάνιας είναι έτοιμη:</strong> η καμπάνια μου τελείωσε και δημιουργήθηκε η αναφορά απόδοσης',
    LINE_ITEM_RUNNING:
      '<strong>Η καμπάνια προβάλλει:</strong> η καμπάνια μου έχει περάσει κριτική και προσφέρει τώρα εμφανίσεις',
  },
  media: {
    gif: 'GIF',
    png: 'PNG',
    svg: 'SVG',
    jpg: 'JPG',
  },
  chart: {
    countType: {
      singular: 'Τάση',
      cumulative: 'Σωρευτικός',
    },
    yAxisOption: {
      shared: 'Κοινόχρηστος άξονας y',
      multiple: 'Πολλαπλοί άξονες y',
    },
    dateOption: {
      last1Hour: 'Τελευταία ώρα',
      last12Hours: 'Τελευταίες 12 ώρες',
      last7Days: 'Τελευταίες 7 μέρες',
      last30Days: 'Τελευταίες 30 μέρες',
      today: 'Σήμερα',
      yesterday: 'Εχθές',
      campaignDays: 'Τρέχουσες ημπερομηνίες: {{startDate}}-{{endDate}}',
    },
  },
  layout: {
    fieldSummary: {
      image: 'εικόνα',
      imagePlural: 'εικόνες',
      url: 'URL',
      urlPlural: 'URLs',
      code: 'πεδίο κώδικα',
      codePlural: 'πεδία κώδικα',
      text: 'πεδίο κειμένου',
      textPlural: 'πεδία κειμένου',
      option: 'επιλογή',
      optionPlural: 'επιλογές',
    },
  },
  label: {
    copy: 'Αντιγραφή',
    copyLineItem: 'Αντιγραφή Καμπάνιας',
    paginationAllShown: 'Εμφάνιση και των <strong>{{total}}</strong> αποτελεσμάτων',
    paginationWithPages: 'Εμφάνιση <strong>{{start}}-{{end}}</strong> από τα <strong>{{total}}</strong> αποτελέσματα',
    paginationLoading: 'Εμφάνιση <span class="fa fa-spin fa-cog"></span> αποτελέσματα',
  },
  language: {
    text: 'Γλώσσα:',
  },
  report: {
    fieldCount: '{{count}} πεδίο',
    fieldCountPlural: '{{count}} πεδία',
    downloadXlsComma: 'Λήψη ως XLS (δεκαδικό κόμμα)',
    downloadXlsPoint: 'Λήψη ως XLS (δεκαδικό σημείο)',
    noReport: `
      <h3>Δεν Βρέθηκαν Αναφορές</h3>
      <p>Μόλις ολοκληρωθεί η καμπάνια σας, θα δημιουργηθεί μια αναφορά που θα δείχνει την απόδοσή της.</p>
      <p>Εδώ θα βρείτε μια λίστα με όλες τις αναφορές για όλες τις καμπάνιες σας.</p>
    `,
    report: 'Αναφορά',
    reportPlural: 'Αναφορές',
    status: {
      PENDING: 'Εκκρεμής',
      PROCESSING: 'Σε Επεξεργασία',
      COMPLETED: 'Ολοκληρωμένο',
      REJECTED: 'Απορριπτόμενο',
      FAILED: 'Απετυχημένο',
      CANCELLED: 'Ακυρωμένο',
      QUEUED: 'Σε Ουρά',
      TIMED_OUT: 'Έληξε ο Χρόνος',
    },
    field: {
      rendered: 'Εμφάνισεις',
      visibles: 'Ορατές Εμφάνισεις',
      visibility: 'Ορατότητα',
      viewables: 'Εμφάνισεις με Δυνατότητα Προβολής',
      viewability: 'Ορατότητα (με Δυνατότητα Προβολής)',
      uniqueUsers: 'Μοναδικοί Χρήστες',
      clicks: 'Κλικ',
      ctr: 'Αναλογία κλικ προς αριθμό εμφανίσεων (CTR)',
      cost: 'Κόστος',
    },
  },
  errors: {
    standard: {
      unregistered: {
        title: 'Ο Λογαριασμός Σας Δεν Είναι Εγγεγραμμένος Εδώ',
        p1: 'Δεν έχετε εγγραφεί σε αυτήν την πύλη διαφήμισης.',
        p2: '',
        p2LinkText: 'Εγγραφείτε σε αυτήν την πύλη διαφήμισης',
        p2Suffix: '&nbsp;πριν συνεχίσετε.',
        p3: 'Εναλλακτικά,&nbsp;',
        p3LinkText: 'συνδεθείτε με άλλο λογαριασμό',
        p3Suffix: '.',
      },
      dataNotFoundTitle: 'Δεδομένα Λείπουν',
      dataNotFoundParagraph1: 'Δεν βρήκαμε δεδομένα στην ακόλουθη τοποθεσία: <strong>{{link}}</strong>.',
      dataNotFoundParagraph2:
        'Αυτό θα μπορούσε να συμβεί επειδή τα υποκείμενα δεδομένα δεν είναι πλέον διαθέσιμα σε εσάς.',
      dataNotFoundParagraph3:
        'Αποκτήστε πρόσβαση σε οποιονδήποτε από τους συνδέσμους του μενού για να επιστρέψετε στο προσκήνιο.',
      pageNotFoundTitle: 'Η Σελίδα Δεν Βρέθηκε',
      pageNotFoundParagraph1: 'Δεν βρήκαμε τη σελίδα που ζητήσατε',
      pageNotFoundParagraph2:
        'Αποκτήστε πρόσβαση σε οποιονδήποτε από τους συνδέσμους του μενού για να επιστρέψετε στο προσκήνιο.',
      objectNotFoundTitle: 'Το Αντικείμενο Δεν Βρέθηκε',
      objectNotFoundParagraph1: 'Δεν βρήκαμε δεδομένα με το ακόλουθο αναγνωριστικό: <strong>{{id}}</strong>.',
      objectNotFoundParagraph2: 'Αυτό θα μπορούσε να συμβεί επειδή το αντικείμενο δεν είναι πλέον διαθέσιμο σε εσάς.',
      objectNotFoundParagraph3:
        'Αποκτήστε πρόσβαση σε οποιονδήποτε από τους συνδέσμους του μενού για να επιστρέψετε στο προσκήνιο.',
    },
    api: {
      stats:
        'Δεν μπορούσαμε να ανακτούμε στατιστικά από το API. Εάν το πρόβλημα παραμένει, επικοινωνήστε με την υποστήριξη.',
      title: 'Δυστυχώς, φαίνεται να υπάρχει πρόβλημα',
      noConnection: {
        title: 'Απώλεια Σύνδεσης',
        noConnection:
          '<p>Χάσαμε σύνδεση με τη διακομιστή. Εάν το πρόβλημα παραμένει, επικοινωνήστε με την υποστήριξη.</p>',
      },
      generic:
        '<p>Αντιμετωπίζουμε προβλήματα αλληλεπίδρασης με το σύστημα. Εάν το πρόβλημα παραμένει, επικοινωνήστε με την υποστήριξη.</p>',
      unauthorised: {
        title: 'Μη Εξουσιοδοτημένη Λειτουργία',
        unauthorised: '<p>Δεν έχετε τα δικαιώματα να εκτελέσετε τη λειτουργία που ζητήσατε.</p>',
      },
    },
  },
  field: {
    selfServiceBudget: 'Προϋπολογισμός',
    email: 'Email',
    emailPreferences: 'Προτιμήσεις Email',
    password: 'Κωδικός Πρόσβασης',
    confirmPassword: 'Επιβεβαίωση Κωδικού',
    locale: 'Μικρός Λοβός',
    smoothing: 'Μέθοδος Προβολής Διαφημίσεων',
    startDate: 'Έναρξης',
    status: 'Κατάσταση',
    userState: 'Κατάσταση',
    displayName: 'Εμφανιζόμενο Όνομα',
    displayNameHint: 'Το εμφανιζόμενο όνομα χρησιμοποιείται για να σας παραπέμπει σε όλη την εφαρμογή',
    endDate: 'Λήξης',
    executionStartTime: 'Ημερομηνία Εκτέλεσης',
    name: 'Όνομα',
  },
  settings: {
    settingsPlural: 'Ρυθμίσεις',
    about: 'Σχετικά',
    signOut: 'Αποσύνδεση',
  },
  signIn: {
    accountLocked:
      'Ο λογαριασμός σας κλειδώθηκε λόγω πάρα πολλών αποτυχημένων προσπαθειών σύνδεσης. Σας έχει σταλεί ένα email με το οποίο μπορείτε να επαναφέρετε τον κωδικό πρόσβασής σας, να ξεκλειδώσετε τον λογαριασμό σας και να συνδεθείτε.',
    alreadyUser: 'Είστε ήδη χρήστης;',
    confirmPasswordMismatch:
      'Οι κωδικοί πρόσβασης που εισάγατε δεν ταιριάζουν. Βεβαιωθείτε ότι έχετε εισαγάγει τον ίδιο κωδικό πρόσβασης και τις δύο φορές.',
    differentAccount: 'Συνδεθείτε με διαφορετικό λογαριασμό',
    emailFirst:
      'Η διεύθυνση email του λογαριασμού σας πρέπει να εισαχθεί για να γίνει επαναφορά του κωδικού πρόσβασης.',
    emailRequired: 'Χρειαζόμαστε τη διεύθυνση email του λογαριασμού σας για να μπορέσουμε να σας συνδέσουμε.',
    expiredLinkExplanation:
      'Φαίνεται ότι κάνατε κλικ σε έναν μη έγκυρο ή έληξε σύνδεσμο επαναφοράς κωδικού πρόσβασης. Προσπαθήστε να επαναφέρετε ξανά τον κωδικό πρόσβασής σας.',
    forgotPassword: 'Ξεχάσατε τον κωδικό σας?',
    returnHome: 'Μεταβείτε στη σελίδα σύνδεσης',
    humanConfirmationFailure:
      'Παρουσιάστηκε πρόβλημα κατά τη λήψη επιβεβαίωσης εγγραφής για τον λογαριασμό σας. Προσπαθήστε ξανά και ενημερώστε μας εάν το πρόβλημα παραμένει.',
    nameRequired: 'Χρειαζόμαστε ένα όνομα για να εγγραφείτε ως νέος χρήστης.',
    passwordResetTooMuchHeading: 'Πάρα Πολλές Επαναφορές Κωδικού Πρόσβασης',
    passwordResetTooMuchExplanation:
      'Προσπαθήσατε να επαναφέρετε τον κωδικό πρόσβασής σας πολύ συχνά σε σύντομο χρονικό διάστημα. Δεν μπορείτε να επαναφέρετε τον κωδικό πρόσβασής σας για την επόμενη στιγμή.',
    passwordResetEmailSentHeading: 'Αποστολή email Επαναφορά Kωδικού  Πρόσβασης',
    passwordResetEmailSentExplanation:
      'Κοιτάξτε το email σας για έναν σύνδεσμο για να επαναφέρετε τον κωδικό πρόσβασης. Εάν δεν εμφανιστεί μέσα σε λίγα λεπτά, ελέγξτε το φάκελο ανεπιθύμητων μηνυμάτων.',
    passwordHint: 'Ο κωδικός πρόσβασής σας πρέπει να έχει τουλάχιστον 8 χαρακτήρες',
    passwordLinkExpired:
      'Ο σύνδεσμος επαναφοράς κωδικού πρόσβασης έχει λήξει. Εισαγάγετε τη διεύθυνση email σας για να δημιουργήσετε έναν άλλο σύνδεσμο.',
    passwordRequired: 'Δώστε έναν κωδικό πρόσβασης για να συνδεθείτε.',
    passwordSimple:
      'Ο κωδικός πρόσβασης που έχει εισαχθεί είναι πολύ εύκολο να μαντέψει και θα πρέπει να είναι κάτι λιγότερο κοινό.',
    registerAsUserHeading: 'Εγγραφείτε Ως Χρήστης',
    registerAsUserButton: 'Εγγραφή',
    registerEmailRequired: 'Χρειαζόμαστε μια διεύθυνση email για να εγγραφείτε ως νέος χρήστης.',
    registerNewUser: 'Εγγραφείτε ως νέος χρήστης',
    registerSuccessHighlight: 'Έχετε εγγραφεί επιτυχώς ως νέος χρήστης!',
    registerSuccessParagraph1: 'Σας έχει σταλεί ένα email με περισσότερες πληροφορίες.',
    registerSuccessParagraph2:
      'Κάντε κλικ στον σύνδεσμο στο email σας για να δημιουργήσετε τον κωδικό πρόσβασης για τον λογαριασμό σας και να συνδεθείτε.',
    resetPasswordHeading: 'Επαναφορά του Κωδικού Πρόσβασής Σας',
    resetPasswordButton: 'Αποστολή Email Επαναφορά Κωδικού Πρόσβασης',
    resetPasswordInfo:
      'Εισαγάγετε τη διεύθυνση email σας και θα σας στείλουμε έναν σύνδεσμο για να επαναφέρετε τον κωδικό πρόσβασής σας.',
    signInButton: 'Σύνδεση',
    signInHeading: 'Σύνδεση',
    updatePasswordHeading: 'Ενημερώστε τον Κωδικό Πρόσβασής Σας',
    updatePasswordButton: 'Ενημέρωση Κωδικού Πρόσβασης',
  },
  validation: {
    required: 'Υποχρεωτικό',
    pattern: 'Το κείμενο πρέπει να έχει μήκος {{minLength}}-{{maxLength}} χαρακτήρες.',
    maxSize:
      'Το μεταφορτωμένο αρχείο έχει μέγεθος {{actual}}kb, το οποίο είναι μεγαλύτερο από το μέγιστο {{maximum}}kb',
    fileType: 'Το μεταφορτωμένο αρχείο πρέπει να είναι ένας από τους ακόλουθους τύπους: {{allowedTypes}}',
    minPrice: 'Η ελάχιστη τιμή είναι {{minValue}}',
    maxPrice: 'Η μέγιστη τιμή είναι {{maxValue}}',
    number: 'Μη έγκυρος αριθμός',
    url: 'Η διεύθυνση URL δεν είναι έγκυρη',
    endDateStartDate: 'Η ημερομηνία λήξης πρέπει να είναι μετά την ημερομηνία έναρξης.',
    error: {
      validation: '<strong>Μη Έγκυρη Υποβολή:</strong> Ελέγξτε τις παραπάνω λεπτομέρειες και δοκιμάστε ξανά.',
      'must.be.greater.than.or.equal': '{{value}} πρέπει να είναι μεγαλύτερο από {{minValue}}.',
      'non.empty': 'Υποχρεωτικό',
      'auth.failed': 'Δεν μπορούσσαμε να σας συνδέσουμε. Τα διαπιστευτήρια δεν αντιστοιχούν σε κανένα λογαριασμό.',
    },
    warning: {
      'lineItem.start.date.before.end.date':
        'Η ημερομηνία έναρξης της καμπάνιας πρέπει να είναι πριν από την ημερομηνία λήξης της',
      'lineItem.end.date.before.today':
        'Η ημερομηνία λήξης της καμπάνιας πρέπει να είναι μετά την τρέχουσα ημερομηνία και ώρα',
      'lineItem.invalid.budget.enddate': 'Η καμπάνια πρέπει να λήξει εντός 100 ημερών',
    },
  },
};
