import {Injectable} from '@angular/core';
import {Observable, tap} from 'rxjs';
import {ResultList} from '@app/@core/@models/common/result-list';
import {HttpClient, HttpParams} from '@angular/common/http';
import {convertJsonStringFieldToObject} from '@shared/utils/json-response-util';
import {ConfigService} from '@app/@core/@config/config.service';
import {ProductSelectionData} from '@app/@core/@models/network/network-data.model';

@Injectable({
  providedIn: 'root'
})
export class NetworkDataResource {

  constructor(private configService: ConfigService,
              private httpClient: HttpClient) {
  }

  getProductSelectionData(): Observable<ResultList<ProductSelectionData>> {
    const networkId = this.configService.getNetworkData()?.id!;
    return this.httpClient.get<ResultList<ProductSelectionData>>(
      this.configService.getVersionedUri('networkdata'),
      {
        params: new HttpParams()
          .set('context', networkId)
          .set('filterBy', 'type')
          .set('filterByValue', 'productSelection')
      }
    ).pipe(
      tap((resultList) => {
        resultList.results.forEach(model => {
          model.data = convertJsonStringFieldToObject(model, "data").data;
        })
      })
    );
  }

}
