<div [formGroup]='signInForm'>
  <label for='masqueradeUser' class='form-label'>{{ 'field.email' | translate }}</label>
  <div class='input-group has-validation'>
    <input type='text' class='form-control' id='masqueradeUser' formControlName='masqueradeUser'
           [ngClass]="{ 'is-invalid': showInvalidFeedback && signInForm.controls.masqueradeUser.invalid && signInForm.controls.masqueradeUser.touched }"
    />
    <span class='input-group-text'><i class='fas fa-envelope'></i></span>
    <div class='invalid-feedback'>{{ 'validation.required' | translate }}</div>
  </div>
</div>
