import {Component, Input, OnInit} from '@angular/core';
import {SignInForm} from '@shared/sign-in/@form/sign-in-form';
import {finalize, Observable, of, switchMap, tap} from 'rxjs';
import {NonNullableFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@app/@core/auth/auth.service';
import {ConfigService} from '@app/@core/@config/config.service';
import {AnonProfileStore} from '@app/@core/anon/anon-profile-store.service';
import {catchError, map} from 'rxjs/operators';
import {UserService} from '@app/@core/users/user.service';
import {AuthUserPermsService} from '@app/@core/users/auth-user-perms.service';
import {SignInModalLauncher} from '../launcher/sign-in-modal-launcher.service';
import {isErrorMessage} from '@app/@core/@models/common/error-message';
import {I18nLangService} from '@app/@i18n/services/i18n-lang.service';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import {UserProfileService} from '@app/@core/users/user-profile.service';
import {UserProfile} from '@app/@core/@models/users/profile/user-profile.model';
import {User} from '@app/@core/@models/users/user.model';

@Component({
  selector: 'app-sign-in-form[isMasquerade][canChangeUser][modal][goToSignInLink][canAnonCampaign][canResetPassword][canNavToSignIn][canRegNewUser][canUpdateLang]',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent implements OnInit {
  @Input() isMasquerade?: boolean;
  @Input() canChangeUser!: boolean;
  @Input() modal?: any; // NgbModalRef
  @Input() goToSignInLink!: boolean;
  @Input() canAnonCampaign!: boolean;
  @Input() canResetPassword!: boolean;
  @Input() canNavToSignIn!: boolean;
  @Input() canRegNewUser!: boolean;
  @Input() canUpdateLang!: boolean;
  signInForm!: SignInForm;
  signIn$?: Observable<any>;
  error?: string;
  showInvalidFeedback: boolean = false;
  hasAnonCampaignLink = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private fb: NonNullableFormBuilder,
              private authService: AuthService,
              public configService: ConfigService,
              private anonProfileStore: AnonProfileStore,
              private authUserPermsService: AuthUserPermsService,
              private userService: UserService,
              private i18nLangService: I18nLangService,
              private signInModalLauncher: SignInModalLauncher,
              private i18nFormatService: I18nFormatService,
              private userProfileService: UserProfileService) {
  }

  ngOnInit(): void {
    this.signInForm = this.newSignInForm();
    this.hasAnonCampaignLink = this.canAnonCampaign && (this.configService.getNetworkData()!.hasAnonymousCampaigns || false);
  }

  doSignIn() {
    this.error = undefined;
    if (this.signInForm.controls.username.errors) {
      this.error = this.i18nFormatService.translate('signIn.emailRequired');
      return;
    }
    if (this.signInForm.controls.password.errors) {
      this.error = this.i18nFormatService.translate('signIn.passwordRequired');
      return;
    }
    this.showInvalidFeedback = true;
    this.signIn$ = of(undefined).pipe(
      // authenticate
      switchMap(() => this.authService.authenticate(
          this.signInForm.value.username!,
          this.signInForm.value.password!,
          this.signInForm.value.masqueradeUser).pipe(
          map((accessToken) => ({accessToken}))
        )
      ),
      // get user
      switchMap(({accessToken}) => this.userService.getUser()
        .pipe(map((user) => ({user, accessToken})))
      ),
      // update user profile lang
      switchMap(({user, accessToken}) => this.updateUserProfileLangIfNeeded(user)
        .pipe(map(() => ({user, accessToken})))
      ),
      // success
      tap(({user, accessToken}) => {
        // redirect to /sign-in/sign-in-other if user doesn't have a role in this network
        if (user.userRoles.rolesByNetwork.find(se => se.network.id === this.configService.getNetworkData()?.id) === undefined) {
          this.abandonSignInAndNavTo('/sign-in/sign-in-other', undefined, true);
          this.anonProfileStore.clearAnonProfile();
        } else if (this.modal) {
          this.modal.close();
          this.signInModalLauncher.signInSuccess(accessToken);
        } else {
          this.navigateTo(this.configService.getUrl("/campaigns"))
        }
      }),
      // handle errors
      catchError((err) => {
        if (isErrorMessage(err.error)) {
          this.error = this.i18nLangService.translateWithDefault(err.error.text, 'validation.' + err.error.code, err.error.params);
        } else {
          this.error = err.statusText || err;
        }
        return of(undefined);
      }),
      // cleanup
      finalize(() => {
        this.signIn$ = undefined
      })
    );
  }

  updateUserProfileLangIfNeeded(user: User): Observable<UserProfile | undefined> {
    const anonLang = this.anonProfileStore.getAnonProfile()?.lang;
    if (this.canUpdateLang && anonLang) {
      return this.updateUserProfileLang(user, anonLang);
    } else {
      return of(undefined);
    }
  }

  updateUserProfileLang(user: User, anonLang: string): Observable<UserProfile> {
    // get user profile
    return this.userProfileService.getUserProfile().pipe(
      // update lang if necessary
      switchMap((userProfile) => {
          if (userProfile.lang === anonLang) {
            // no update necessary
            return of(userProfile);
          } else {
            // update profile lang
            return this.userProfileService.updateUserProfile(user, {
              lang: anonLang
            })
          }
        }
      )
    );
  }

  changeUser() {
    this.anonProfileStore.clearAnonProfile();
    this.signInForm = this.newSignInForm();
  }

  navigateToSignInPage(): boolean {
    return this.abandonSignInAndNavTo("/sign-in");
  }

  goToPasswordReset(): boolean {
    // pass username to password reset page as optional parameter
    const args = this.signInForm.value.username ? {username: this.signInForm.value.username} : undefined;
    return this.abandonSignInAndNavTo('/sign-in/password-reset', args);
  }

  goToRegisterNewUser(): boolean {
    return this.abandonSignInAndNavTo("/sign-in/register", undefined);
  }

  private abandonSignInAndNavTo(pathSuffix: string, args?: any, forceSignOut: boolean = false): boolean {
    const path = this.configService.getUrl(pathSuffix);
    if (this.modal || forceSignOut) {
      this.authService.signOut();
    }
    if (this.modal) {
      this.modal.close();
      this.signInModalLauncher.signInAbandoned("navigating to " + path);
    }
    return this.navigateTo(path, args);
  }

  private navigateTo(path: string, args?: any) {
    if (this.modal) {
      window.location.pathname = path;
    } else {
      const commands: any[] = [path];
      if (args) {
        commands.push(args);
      }
      this.router.navigate(commands, {replaceUrl: true});
    }
    return false;
  }

  private newSignInForm(): SignInForm {
    let defaultUsername = this.anonProfileStore.getAnonProfile().username || '';
    let defaultMasqueradeUser = this.isMasquerade ? this.anonProfileStore.getAnonProfile().masqueradeUser || '' : '';
    return this.fb.group({
      username: [defaultUsername, [Validators.required, Validators.email]],
      usernameProvided: [Boolean(defaultUsername)],
      password: ['', Validators.required],
      masqueradeUser: [defaultMasqueradeUser, this.isMasquerade ? Validators.required : Validators.nullValidator],
    });
  }
}
