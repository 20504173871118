import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payment, PaymentCalculation, PaymentCalculationInput } from '@app/@core/@models/network/payment.model';
import { ConfigService } from '@app/@core/@config/config.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentResource {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {}

  get(networkId: string, paymentId: string): Observable<Payment> {
    const endPoint: string = this.configService.getVersionedUri('payment');
    return this.httpClient.get<Payment>(`${endPoint}/${paymentId}?context=${networkId}`);
  }

  post(payment: Payment): Observable<Payment> {
    const endPoint: string = this.configService.getVersionedUri('payment');
    return this.httpClient.post<Payment>(
      `${endPoint}/${payment.id}?context=${payment.network.id}`,
      payment,
      { headers: new HttpHeaders().set('Content-Type', 'text/plain') }
    );
  }

  calculatePost(input: PaymentCalculationInput): Observable<PaymentCalculation> {
    const endPoint = this.configService.getVersionedUri('payment/calculate');
    const networkId = this.configService.getNetworkData()!.id;
    return this.httpClient.post<PaymentCalculation>(
      endPoint,
      input,
      {
        headers: new HttpHeaders().set('Content-Type', 'text/plain'),
        params: { context: networkId }
      });
  }
}
