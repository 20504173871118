<div class="card">
  <form name="passwordForm" (ngSubmit)="doResetSequence()" [formGroup]="formGroup" novalidate>
    <div class="card-header">
      <h3>{{ 'signIn.updatePasswordHeading' | translate }}</h3>
    </div>
    <div class="card-body">
      <div class="vstack gap-3 mt-2 mb-3">
        <div *ngIf='errorKey' class='alert alert-danger mb-0'>
          <ng-container [ngSwitch]="errorKey">
            <span *ngSwitchCase="'validation.error.invalid.password'">{{ 'signIn.passwordSimple' | translate }}</span>
            <span *ngSwitchCase="'error.validation'">{{ 'signIn.passwordSimple' | translate }}</span>
            <span *ngSwitchDefault>{{ errorKey | translate }}</span>
          </ng-container>
        </div>
        <div>
          <label for="passwordInput" class="form-label"><strong>{{ 'field.password' | translate }}</strong></label>
          <div class="input-group">
            <input type="password" class="form-control" id="passwordInput" formControlName="password" autofocus/>
            <span class="input-group-text"><i class="fas fa-lock"></i></span>
          </div>
          <div class="form-text">{{ 'signIn.passwordHint' | translate }}</div>
        </div>
        <div>
          <label for="passwordConfirmInput"
                 class="form-label"><strong>{{ 'field.confirmPassword' | translate }}</strong></label>
          <div class="input-group">
            <input type="password" class="form-control" id="passwordConfirmInput" formControlName="passwordConfirm"/>
            <span class="input-group-text"><i class="fas fa-lock"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="text-end mt-1 mb-2 d-flex">
        <div class="vstack gap-2 ms-auto flex-grow-0">
          <button class="btn btn-primary" type="submit" [disabled]="formGroup.invalid || resetSequence$">
            <i *ngIf='resetSequence$' class='fas fa-cog fa-spin'></i>
            <span>{{ 'signIn.updatePasswordButton' | translate }}</span>
          </button>
          <div class="text-end"><small>{{ 'adn.poweredBy' | translate }}</small></div>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-container *ngIf="resetSequence$ | async"></ng-container>
