<label for="couponInput" class="form-label" translate="signIn.coupon.code"></label>
<form [formGroup]="couponForm" (ngSubmit)="onCheckCouponCode()" class="d-grid gap-1">
  <div class="input-group">
    <input type="text" class="form-control" id="couponInput" formControlName="couponCode"/>
    <button [disabled]="!couponForm.value.couponCode || loading" type="submit" name="check-coupon-code"
            class="btn btn-outline-dark">
      <span translate="signIn.coupon.checkCode"></span>
    </button>
  </div>
  <div *ngIf="couponCodeCheck$ | async as validCouponCode; else loadingOrError">
    <span class="badge bg-success">{{ 'signIn.coupon.validCode' | translate : {code: validCouponCode} }}</span>
  </div>
  <ng-template #loadingOrError>
    <div *ngIf="loading"><i class="fas fa-cog fa-spin"></i></div>
    <app-error *ngIf="errorKey" [errorKey]="errorKey" [objectId]="undefined"></app-error>
    <div *ngIf="invalidCouponCode">
      <span class="badge bg-danger">{{ 'signIn.coupon.invalidCode' | translate : {code: invalidCouponCode} }}</span>
    </div>
  </ng-template>
</form>
