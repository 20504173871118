import { Injectable } from '@angular/core';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Title} from '@angular/platform-browser';
import { ConfigService } from '@app/@core/@config/config.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TitleService extends TitleStrategy {
  private pageTitleKey?: string;

  constructor(private readonly title: Title,
              private configService: ConfigService,
              private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Called when route is established or changes.
   */
  override updateTitle(snapshot: RouterStateSnapshot): void {
    this.pageTitleKey = this.buildTitle(snapshot);
    this.setHtmlPageTitle();
  }

  /**
   * Called by I18nLang service when language is set/changed.
   */
  languageSet() {
    this.setHtmlPageTitle();
  }

  private setHtmlPageTitle() {
    const networkPageTitle = this.configService.getNetworkData()?.pageTitle || 'Self-Service Advertising';
    let newTitle: string;
    if (this.pageTitleKey) {
      const translatedPageTitle = this.translateService.instant(this.pageTitleKey);
      newTitle = `${translatedPageTitle} - ${networkPageTitle}`;
    } else {
      newTitle = networkPageTitle;
    }
    this.title.setTitle(newTitle);
  }

}
