<div class='mb-0'>
  <label class='form-label'>{{ labelKey | translate }}</label>
  <div class='row'>
    <div class='col-sm-7'>
      <div class='input-group has-validation'>
        <input [id]="'date'+uuid" [formControl]='dateControl' class='form-control' name='dp' ngbDatepicker #d='ngbDatepicker'
               [ngClass]="{'is-invalid': isDateControlInvalid()}">
        <button class='btn btn-outline-secondary calendar' (click)='d.toggle()' type='button'>
          <i class='fa far fa-calendar-days'></i>
        </button>
        <div class='invalid-feedback'>
          <div *ngIf='dateControl.errors && dateControl.errors["ngbDate"]?.invalid'>{{'validation.parse' | translate}}</div>
          <ng-container *ngIf='dateControl.pristine'>
            <div *ngFor='let validationWarning of validationWarnings'>
              {{ validationWarning.text }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class='col-sm-5' *ngIf='!hideDateTimes'>
      <div class='has-validation' [ngClass]="{'border': timeControl.invalid, 'border-danger': timeControl.invalid}">
        <ngb-timepicker [id]="'time'+uuid" [spinners]='false' [formControl]='timeControl'
                        [ngClass]="{'is-invalid': timeControl.invalid, 'is-valid': timeControl.valid}"></ngb-timepicker>
        <div class='invalid-feedback'>
          <div *ngIf='timeControl.errors && timeControl.errors["ngbDate"]?.invalid'>{{'validation.parse' | translate}}</div>
          <ng-container *ngIf='timeControl.pristine'>
            <div *ngFor='let validationWarning of validationWarnings'>
              {{ validationWarning.text }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
