import {ComponentRequired, LayoutTag} from '@app/@core/@models/advertising/layout/layout.model';
import {LayoutComponent} from '@app/@core/@models/advertising/layout/layout-component.model';

// ng.model.layout.AssetComponent
export interface AssetComponent {
  tag: LayoutTag
  type: 'ASSET'
  mimeTypes: string[]
  maxFileSizeBytes: number
  name?: string
  required: ComponentRequired
}

export function isAssetComponent(layoutComponent:LayoutComponent): layoutComponent is AssetComponent {
  return (layoutComponent.type === 'ASSET');
}

