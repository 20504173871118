import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-in-404',
  templateUrl: './sign-in-404.component.html',
  styleUrls: ['./sign-in-404.component.scss'],
})
export class SignIn404Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
