import {AbstractControl, ValidationErrors} from '@angular/forms';

const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
export function urlValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value && !urlPattern.test(control.value)) {
    return { url: { value: control.value }}
  } else {
    return null;
  }
}
