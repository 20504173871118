export const COMPANY_TRANSLATIONS_DE = {
  russ: {
    frontPage: `
    <div>
      <p><a href="http://russmedia.com"><img src="https://regalia.adnuntius.com/label/russmedia/201209_Russmedia_logo_200x40.png" width="200"></a></p>
    </div>
    <h2>Werbeportal</h2>
    <ol>
        <li>Erstelle deinen Account</li>
        <li>Lege dein Budget fest</li>
        <li>Erstelle deine Anzeige</li>
        <li>Starte deine Kampagne</li>
    </ol>
    <p>Erreichen Sie Ihre Zielgruppe treffgenau über das ganze Russmedia Netzwerk. Von <a href="http://vol.at">VOL.AT</a>, über Ländleanzeiger bis hin zu <a href="http://vienna.at">VIENNA.AT</a>. Über 70% der Vorarlberger*innen oder über 4 Millionen Österreicher*innen warten auf Ihre Botschaft.</p>
    `
  },
  vpg3: {
    emailFooter: 'onlinebuchung@az-muenchen.de',
    frontPage: `
    <div>
       <p><a href="https://www.abendzeitung-muenchen.de/"><img src="https://regalia.adnuntius.com/label/az/image.png" width="100%"></a></p>
    </div>
    <h3>Digitales Werbeportal – Ihre Anzeige auf abendzeitung-muenchen.de</h3>
    <ul><li>Loggen Sie sich ein oder starten Sie ohne Login</li>
    <li>Wählen Sie Ihre gewünschte Werbeform aus</li>
    <li>Erstellen Sie Ihre Anzeige</li>
    <li>Legen Sie Ihr Budget fest</li>
    <li>Starten Sie Ihre Kampagne</li>
    </ul>
    <p>Mit der Abendzeitung Digital erreichen Sie genau Ihre lokale Zielgruppe.</p>
    <p>Wir platzieren Ihre Anzeige im redaktionellen Umfeld Ihres Stadtbezirks oder im Ressort „Essen und Trinken“</p>
    <p>Rund 1 Millionen Münchner:innen besuchen das Newsportal der Abendzeitung regelmäßig und freuen sich auf Ihre Werbebotschaft.</p>
    `,
    campaign: {
      ad: {
        copyrightMessage: 'Ich erkläre, keine urheberrechtlich geschützten Inhalte (wie insbesondere Texte, Bilder, Grafiken, Stadtpläne und Landkarten) hochzuladen bzw. zu verwenden. Außerdem werden durch das Einstellen meiner Anzeige keine wettbewerbs-, marken- oder jugendschutzschutzrechtlichen Vorschriften verletzt sowie Datenschutz- und Persönlichkeitsrechte Dritter eingehalten. Insbesondere ist es mir ausdrücklich untersagt, entsprechende Inhalte aus Webseiten wie Google oder Wikipedia zu kopieren und hochzuladen. Ich stelle die Abendzeitung Digital GmbH & Co KG von allen Ansprüchen Dritter frei, die aus einer unzulässigen Nutzung entstehen.'
      }
    }
  },
  fmy0: {
    campaign: {
      ad: {
        create: `<a class="headingLink" target="_blank" href="https://regalia.adnuntius.com/label/reflex/Whitepaper-TBE-neu.pdf">Anleitung zur Gestaltung des Werbemittels</a> Gestalte das Werbemittel`
      },
      adRejected: {
        explanationGeneral: 'Werbemittel #{{id}} wurde abgelehnt, daher kann die Kampagne keine Werbeeinblendungen ausliefern. Siehe unten für mehr Details.',
        explanation: 'Werbemittel anpassen und zur Überprüfung senden um die budgetierten Werbeeinblendungen auszuliefern'
      },
      product: {
        title: 'Werbeoption auswählen',
        adDesignChoice: '<strong>{{count}} Anzeigendesigns</strong> verfügbar für diese Größenverhältnisse:',
        cpm: '<strong>{{value}}</strong> pro tausend Werbeeinblendungen',
      },
      device: {
        title: 'Device-Targeting-Optionen',
        instructions: '',
        any: 'jedem Endgerät',
      },
      domainName: {
        title: 'Domain-Targeting-Optionen',
        instructions: ''
      },
      kvs: {
        title: 'Bereich-Targeting-Optionen',
        instructions: '',
      },
      category: {
        title: 'URL-Kategorien-Targeting-Optionen',
        instructions: ''
      },
      location: {
        title: 'Location-Targeting-Optionen',
        instructions: ''
      },
      expectedImpressions: 'Entspricht etwa {{imps}} Werbeeinblendungen',
      payment: {
        reserveParagraph2: 'Falls nicht alle <strong>{{imps}} Werbeeinblendungen</strong> ausgeliefert werden, wird der Restbetrag über PayPal zurückgebucht.',
        paypal: {
          detailsInfo: `
          <div class="small">
            <p>Die Kampagne kann ausliefern, nachdem die Zahlung über PayPal durchgeführt und das Werbemittel freigegeben wurde.</p>
            <p>Falls nicht alle Werbeeinblendungen ausgeliefert werden, wird die Restsumme des Budgets über PayPal zurückerstattet. Der Minimalbetrag von {{minimumFee}} fällt jedoch an.</p>
            <p>Nach Beendigung der Kampagne werden ein Performancereport und eine PayPal-Rechnung erstellt.</p>
            <p>Die Kampagne kann auch während der Laufzeit jederzeit wieder gestoppt werden, jedoch fällt die Minimalgebühr von {{minimumFee}} an.</p>
            <p>Impressionen sind Einblendungen der Anzeigen auf den Werbeplätzen der Webseiten.</p>
          </div>
        `,
          detailsInfoNoMinFee: `
          <div class="small">
            <p>Die Kampagne kann ausliefern, nachdem die Zahlung über PayPal durchgeführt und das Werbemittel freigegeben wurde.</p>
            <p>Falls nicht alle Werbeeinblendungen ausgeliefert werden, wird die Restsumme des Budgets über PayPal zurückerstattet.</p>
            <p>Nach Beendigung der Kampagne werden ein Performancereport und eine PayPal-Rechnung erstellt.</p>
            <p>Die Kampagne kann auch während der Laufzeit jederzeit wieder gestoppt werden.</p>
            <p>Impressionen sind Einblendungen der Anzeigen auf den Werbeplätzen der Webseiten.</p>
          </div>
        `
        },
        manual: {
          reserveParagraph2: 'Nach Beendigung der Kampagne, werden wir eine Rechnung über <strong>{{amount}}</strong> oder anteilig, basierend auf den tatsächlich ausgelieferten Werbeeinblendungen ausstellen.</strong>.',
          detailsInfo: `
            <div class="small">
              <p>Die Kampagne kann ausliefern sobald die Überprüfung des Werbemittels abgeschlossen ist.</p>
              <p>Nach Kampagnenende wird eine Rechnung erstellt. Der Betrag berücksichtigt die Anzahl der gelieferten Werbeeinblendungen und wird das maximale Budget nicht übersteigen. Der Minimalbetrag von {{minimumFee}} fällt jedoch an.</p>
              <p>Nach Kampagnenende wird ein Performancereport erstellt.</p>
              <p>Die Auslieferung kann jederzeit gestoppt werden, jedoch fällt die Minimalgebühr von {{minimumFee}} an.</p>
            </div>
          `,
          detailsInfoNoMinFee: `
            <div class="small">
              <p>Die Kampagne kann ausliefern sobald die Überprüfung des Werbemittels abgeschlossen ist.</p>
              <p>Nach Kampagnenende wird eine Rechnung erstellt. Der Betrag berücksichtigt die Anzahl der gelieferten Werbeeinblendungen und wird das maximale Budget nicht übersteigen.</p>
              <p>Nach Kampagnenende wird ein Performancereport erstellt.</p>
              <p>Die Auslieferung kann jederzeit gestoppt werden.</p>
            </div>
          `
        }
      },
      running: {
        impressions: 'Die Kampagne lieferte <strong>{{imps}} Werbeeinblendungen</strong>, das entspricht <strong>{{percentage}} der gebuchten Werbeeinblendungen</strong>.',
        impressionsRemaining: 'Es sind etwa <strong>{{imps}} Werbeeinblendungen</strong> übrig, für die Auslieferung.',
      },
      manual: {
        stoppedParagraph2: 'Die Kampagne wird anhand der bereits ausgelieferten Werbeeinblendungen abgerechnet.',
      },
      smoothing: {
        trueField: '<strong>gleichverteilt</strong> - liefere die gebuchten Werbeeinblendungen gleichverteilt über den Kampagnenzeitraum aus.',
        falseField: '<strong>schnellstmöglich</strong> - liefere die gebuchten Werbeeinblendungen so schnell wie möglich aus.'
      },
      stop: {
        paypal: {
          info2NoMinFee: 'Die Kampagne wird anhand der bereits ausgelieferten Werbeeinblendungen abgerechnet.'
        },
        manual: {
          info2NoMinFee: 'Die Kampagne wird anhand der bereits ausgelieferten Werbeeinblendungen abgerechnet.'
        }
      }
    },
    report: {
      field: {
        rendered: 'Werbeeinblendungen',
        visibles: 'Visible Werbeeinblendungen',
        viewables: 'Viewable Werbeeinblendungen',
      }
    },
    field: {
      cpm: 'Kosten pro 1000 Werbeeinblendungen (CPM)',
    },
    emailFooter: 'Kontaktieren Sie uns <strong> support@reflex-media.net</strong>.',
    frontPage: `
      <div>
        <p><img width="100%" src="https://regalia.adnuntius.com/label/reflex/reflex-logo-2.png"></p>
      </div>
      <h3>Self-Service Advertising</h3>
      <p>Das Anzeigenselbstbuchungstool, das KMU und Medien zusammenbringt.</p>
      <ul>
        <li>Erstellen Sie Ihren Account</li>
        <li>Legen Sie Ihr Budget fest</li>
        <li>Erstellen Sie Ihre Anzeige</li>
        <li>Starten Sie Ihre Kampagne</li>
      </ul>
    `
  },
  '1v9y': {
    emailFooter: 'Kontaktieren Sie uns <strong>werbelokal@mopo.de</strong>.',
    report: {
      noReportExample: 'Ein <a href="https://assets.adnuntius.com/70vN3w68p7-Tmnc0jGHQnedDOkEiA7FwIheigltPwCI.html" target="_blank">Beispielbericht ist verfügbar</a>, um zu demonstrieren, wie Ihr Bericht nach Ende Ihrer Kampagne aussehen wird.'
    },
    campaign: {
      filter: {
        preMessage: `
          <div class="row postMessageChoices">
            <div class="col-md-6">
              <p><strong>Werbebotschaft als Bild</strong></p>
              <img src="https://regalia.adnuntius.com/label/mopo/mopo_image_preview-bild.png">
            </div>
            <div class="col-md-6">
              <p><strong>Werbebotschaft als Bild-Text</strong></p>
              <img src="https://regalia.adnuntius.com/label/mopo/mopo_native_preview-bild-text.png">
            </div>
          </div>
        `
      },
      device: {
        title: 'Geräteauswahl'
      },
      product: {
        title: 'Werbeobjekt auswählen',
        adDesignChoice: '<strong>Werbeobjekt</strong> verfügbar für diese Größen:',
        cpm: '<strong>{{value}}</strong> pro tausend Impressions/Einblendungen',
      }
    },
    signIn: {
      condition: `Zustimmung zur Weiterverarbeitung der Daten im CRM der Hamburger Morgenpost`,
      conditionError: 'Sie müssen unseren Bedingungen zustimmen, bevor Sie sich registrieren können'
    },
    frontPage: `
      <div>
        <p><img src="https://regalia.adnuntius.com/label/mopo/MOPO-logo-there.png" width="100%"></p>
      </div>
      <h3>Das MOPO WERBELOKAL</h3>
      <ol>
        <li>Erstellen Sie Ihren Account</li>
        <li>Legen Sie Ihr Budget fest</li>
        <li>Erstellen Sie selbst ganz einfach Ihre Anzeige</li>
        <li>Starten Sie Ihre Kampagne</li>
      </ol>
      <p>Erreichen Sie Ihre Kunden hier in Hamburg und Umgebung treffsicher auf <a target="_blank" href="https://mopo.de">mopo.de</a>: Mit 24,7 Mio. Visits und 6,42 Mio. Unique Usern sind wir DIE Werbeplattform im Norden!</p>
    `
  },
  qlzz: {
    campaign: {
      ad: {
        create: `<a class="headingLink" target="_blank" href="https://regalia.adnuntius.com/label/atten/leitfaden.pdf">Anleitung zur Gestaltung des Werbemittels</a> Gestalte das Werbemittel`
      },
      category: {
        descriptionPrefix: 'Das Werbemittel wird angezeigt:&nbsp;',
        instructions: 'Bitte wählen Sie die Regionen Ihrer Zielgruppe aus, denen das Werbemittel angezeigt werden soll',
        any: 'allen Besuchern der Startseite'
      },
    },
    frontPage: `
      <div style="margin-bottom: 40px;">
        <p><img src="https://regalia.adnuntius.com/label/atten/menu.png" width="50%"></p>
      </div>
      <h3>Digitales Werbeportal – Ihre Anzeige auf idowa.de</h3>
      <ul>
        <li>Loggen Sie sich ein oder starten Sie ohne Login</li>
        <li>Wählen Sie Ihre gewünschte Werbeform aus</li>
        <li>Legen Sie Ihre regionale Zielgruppe fest</li>
        <li>Erstellen Sie Ihre Anzeige</li>
        <li>Legen Sie Ihr Budget fest</li>
        <li>Starten Sie Ihre Kampagne</li>
      </ul>
      <p>Wir platzieren Ihre Anzeige im redaktionellen Umfeld der Startseite.</p>
      <p>Profitieren Sie von unseren hohen Reichweiten und sprechen Sie die gewünschten Kunden aus der Region an!</p>
      <p style="margin-top: 40px;"><img src="https://regalia.adnuntius.com/label/atten/atten-smaller-logo.png" width="50%"></p>
    `,
    emailFooter: 'Kontakt: <strong>portale@idowa.de</strong>.',
  },
  burda: {
    campaign: {
      ad: {
        stretched: ''
      }
    },
    frontPage: `
      <div>
        <p style="text-align: center"><img src="https://regalia.adnuntius.com/label/burda/front-page-logo.png" style="max-width: 250px"></p>
      </div>
      <h3>WERBEN AUF NEBENAN.DE</h3>
      <ol>
        <li><strong>Hochwertiges Umfeld</strong><br>Nur bei uns sind Nutzer:innen adressverifiziert.</li>
        <li><strong>Neuer Touchpoint</strong><br>Nur bei uns stehst du im Rampenlicht lebendiger Nachbarschaften.</li>
        <li><strong>Gute Performance</strong><br>Nur bei uns kommt die Resonanz über dauerhaft hohe Aktivität.</li>
        <li><strong>Hohe Glaubwürdigkeit</strong><br>Nur bei uns ist Werbung eine Sache fürs Herz.</li>
        <li><strong>Lokale Expertise</strong><br>Nur bei uns führen dich Nachbarschafts-Expert:innen zum Erfolg.</li>
      </ol>
    `,
    emailFooter: 'Kontakt: <strong>kampagnen@nebenan.de</strong>',
  },
  nxts: {
    frontPage: `
      <div>
        <p style="text-align: center"><img src="https://regalia.adnuntius.com/label/epp/eppsteiner_logo.jpg" width="75%"></p>
      </div>
      <h3>Digitales Werbeportal - Ihre Anzeige auf eppsteiner-zeitung.de</h3>
      <ul>
        <li>Loggen Sie sich ein oder starten Sie ohne Login</li>
        <li>Wählen Sie Ihre gewünschte Werbeform aus</li>
        <li>Erstellen Sie Ihre Anzeige</li>
        <li>Legen Sie Ihr Budget fest</li>
        <li>Starten Sie Ihre Kampagne</li>
      </ul>
      <p>Mit der Eppsteiner Zeitung digital erreichen Sie genau Ihre lokale Zielgruppe.</p>
      <p>Wir platzieren Ihre Anzeige im redaktionellen Umfeld. Unsere Nutzerinnen und Nutzer besuchen das Portal der Eppsteiner Zeitung regelmäßig und freuen sich auf Ihre Werbebotschaft.</p>
    `
  },
  t2s0: {
    adn: {
      poweredBy: 'In Zusammenarbeit mit Adnuntius AS',
    },
    frontPage: `
      <div>
        <p><img src="https://regalia.adnuntius.com/label/infranken/logo4.png" width="90%"></p>
      </div>
      <p>Egal ob du über deine neuesten <strong>Angebote informieren</strong> oder deine <strong>Bekanntheit steigern</strong> möchtest - mit einer Kampagne auf inFranken.de erreichst du die <strong>Menschen in deiner Region</strong> und machst sie auf dich <strong>aufmerksam</strong>.</p>
      <p>Erstelle in nur drei einfachen Schritten selbständig deine Online-Kampagne auf inFranken.de:</p>
      <ol>
        <li><strong>Region auswählen</strong></li>
        <li><strong>Werbemittel gestalten</strong></li>
        <li><strong>Laufzeitbudget festlegen</strong></li>
      </ol>
      <p>Dein Werbemittel wird ausschließlich in dem von dir ausgewählten <strong>regionalen Umfeld</strong> des News - und Serviceportals <strong>inFranken.de</strong> angezeigt. Mit der aktuellen Kampagnenübersicht behältst du den Fortschritt deiner Kampagne <strong>jederzeit im Blick</strong>.</p>
      <p>Auch schon <strong>ohne Login</strong> kannst du ausprobieren, wie einfach du eine Kampagne erstellen kannst.</p>
      <p>Los geht’s mit deiner <strong>eigenen Kampagne auf inFranken.de!</strong></p>
    `,
    anon: {
      heading: 'Beginne nun mit der Gestaltung deiner Kampagne. Alle Details werden im Browser zwischengespeichert. Um den Prozess abzuschließen, musst du dich zuvor jedoch registrieren.',
      saveCampaignLinkPrefix: 'Du bist jetzt eingeloggt und ',
      saveCampaignLinkText: 'kannst die Buchung deiner Kampagne abschließen',
      saveCampaignLinkPostfix: '. Den letzten Stand findest du unten als Entwurf gespeichert.',
    },
    signIn: {
      registerAsUserHeading: 'Registrieren',
      registerSuccessHighlight: 'Vielen Dank für deine Registrierung. In deinem Email-Postfach solltest du nun eine Nachricht finden, mit der du ein Passwort vergeben und damit die Registrierung abschließen kannst.',
      registerSuccessParagraph1: '',
      registerSuccessParagraph2: 'Bitte überprüfe auch den Spam-Ordner.',
      updatePasswordHeading: 'Passwort vergeben',
      updatePasswordButton: 'Passwort festlegen',
      registerNewUser: 'Registrieren',
      registerAsUserButton: 'Registrieren',
      registerTerms: 'Durch die Registrierung bestätigst du, dass du unsere <a target="_ blank" href="https://adnuntius.com/resources/tc-self-service-advertising/">Allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiert hast.'
    },
    report: {
      field: {
        rendered: 'Einblendungen',
        clicks: 'Klicks',
        ctr: 'Klickrate'
      }
    },
    field: {
      emailPreferences: 'Email-Benachrichtungen: Worüber möchtest du von uns per Email informiert werden?',
    },
    emailPreferences: {
      CREATIVE_REJECTED: '<strong>Anpassung der Kampagne erforderlich:</strong> Die Kampagne wurde überprüft und es müssen Änderungen vorgenommen werden',
      REPORT_COMPLETE: '<strong>Die Kampagne ist beendet und das Reporting ist verfügbar:</strong> Das Ende der Laufzeit wurde erreicht und die Kennzahlen zur Performance sind jetzt im Reporting einsehbar',
      LINE_ITEM_RUNNING: '<strong>Die Kampagne wird ausgeliefert:</strong> Die Kampagne wurde erfolgreich geprüft und läuft nun an'
    },
    campaign: {
      adRejected: {
        explanationGeneral: 'Die Kampagne hat die Überprüfung leider nicht bestanden und muss angepasst werden, bevor sie ausgeliefert werden kann. Weiter unten erhältst du weitere Details.'
      },
      ad: {
        create: '<a class="headingLink" target="_blank" href="https://www.infranken.de/werben-auf-infrankende/lokalwerben/#faq">Tipps zur Anzeigengestaltung in unseren FAQs</a> Wie soll das Werbemittel aussehen?',
        runningPreviewTitle: 'Werbemittelvorschau',
        border: 'Rahmen anzeigen, um die Werbemittelgröße zu demonstrieren',
        copyrightMessage: 'Ich versichere, keine Inhalte hochzuladen, die gegen gesetzliche Bestimmungen, insbesondere Wettbewerbs- und Datenschutzgesetze, Persönlichkeitsrechte und/oder Schutzrechte Dritter (z.B. Urheber- und Markenrechte) oder gegen die guten Sitten verstoßen. Ich stelle die inFranken.de GmbH & Co. KG von allen Ansprüchen Dritter frei, die aus einer unzulässigen Nutzung entstehen.'
      },
      expectedImpressions: 'Entspricht etwa {{imps}} Einblendungen',
      product: {
        title: '',
        adDesignChoice: '{{count}} Anzeigenvariante verfügbar für folgende Größen'
      },
      stop: {
        prepay: {
          info1: 'Das Stoppen der Kampagne kann nicht rückgängig gemacht werden. Du kannst jedoch diese Kampagne kopieren, gegebenenfalls Änderungen daran vornehmen, und dann neu starten lassen.',
          info2NoMinFee: 'Solltest du die Kampagne vorzeitig abbrechen, erhältst du natürlich in Kürze eine Gutschrift über das nicht verwendete Budget.'
        },
        manual: {
          info1: 'Das Stoppen der Kampagne kann nicht rückgängig gemacht werden. Du kannst jedoch diese Kampagne kopieren, gegebenenfalls Änderungen daran vornehmen, und dann neu starten lassen.',
          info2NoMinFee: 'Solltest du die Kampagne vorzeitig abbrechen, erhältst du natürlich in Kürze eine Gutschrift über das nicht verwendete Budget.'
        }
      },
      addNewCampaign: `
        <h3>Herzlich willkommen bei <a href="https://lokalwerben.inFranken.de">lokalwerben.inFranken.de</a>!</h3>
        <p>Starte jetzt mit deiner ersten eigenen Kampagne indem du rechts oben auf "Neue Kampagne anlegen" klickst.</p>
        <p>Weitere Tipps und Infos findest du in unseren <a href="https://www.infranken.de/werben-auf-infrankende/lokalwerben/#faq">Fragen &amp; Antworten</a>.</p>
      `,
      payment: {
        reserveParagraph1: 'Damit deine Kampagne optimale Ergebnisse erzielt, wird sie zunächst von einem unserer Experten geprüft. Dies dauert in der Regel nur wenige Stunden. Führe jetzt die Bezahlung von <strong>{{amount}}</strong> durch, um die Kampagne zu buchen und um den Überprüfungs-Prozess anzustoßen.',
        reserveParagraph2: 'Für die Zahlung mit PayPal brauchst du nicht unbedingt ein PayPal-Konto. Auch ohne Registrierung kannst du hier per Kreditkarte bezahlen.',
        infoComplete: "Die Zahlung über {{price}} wurde am {{time}} erfolgreich durchgeführt.",
        prepay: {
          detailsInfoNoMinFee: `
            <p>Um die Buchung abzuschließen, bezahlst du über PayPal mit deinem <strong>PayPal-Konto</strong> oder per <strong>Kreditkarte</strong>. Im Anschluss wird die Kampagne von uns <strong>überprüft</strong> und <strong>startet</strong> nach Freigabe automatisch.</p>
            <p>So geht es dann weiter:</p>
            <ul>
                <li>Den <strong>Fortschritt</strong> deiner Kampagne kannst du jederzeit unter <a href="https://lokalwerben.infranken.de/campaigns">Kampagnen</a> einsehen, wo du auch die dazugehörige <strong>Rechnung</strong> findest</li>
                <li>Zum Ende der Kampagne wird für dich ein abschließender <strong>Report</strong> erstellt</li>
                <li>Auch während der Laufzeit kann die Kampagne <strong>jederzeit von dir gestoppt</strong> werden</li>
            </ul>
            <p>Wir halten dich zukünftig per E-Mail über aktuelle Angebote von <a href="https://lokalwerben.infranken.de/">lokalwerben.inFranken.de</a> auf dem Laufenden. Dieses Einverständnis kannst du jederzeit <a href="https://www.infranken.de/datenschutz/datenschutz-art-3144">hier</a> bei der inFranken.de GmbH & Co. KG widerrufen.</p>
            <p>Viel Erfolg mit deiner Kampagne auf inFranken.de!</p>
          `
        },
        PAYPAL: {
          reserveButton: "Jetzt Kampagne via PayPal bezahlen"
        }
      },
      filter: {
        postMessage: `
          <div class="row postMessageChoices">
            <div class="col-md-4">
              <img src="https://regalia.adnuntius.com/label/infranken/bildText-100.png">
            </div>
            <div class="col-md-4">
              <img src="https://regalia.adnuntius.com/label/infranken/bild-100.png">
            </div>
          </div>
        `
      },
      running: {
        datesExplanation: 'Die Kampagne läuft seit <strong>{{days}} Tagen</strong> (<strong>{{percentage}}</strong> der Kampagnenlaufzeit).',
        datesLeftExplanation: 'Die Kampagne endet in <strong>{{days}} Tagen</strong>.',
        budgetHeading: 'Laufzeitbudget',
        successfulPaymentParagraph1: 'Deine Zahlung wurde akzeptiert und die Kampagne zur Überprüfung geschickt. Sobald sie freigegeben wurde bekommst du eine E-Mail Benachrichtigung. Die Kampagne wird dann ab dem angegebenen Startdatum ausliefern.',
        successfulPaymentParagraph2: '',
        impressions: 'Die Kampagne lieferte <strong>{{imps}} Werbeeinblendungen</strong>, das entspricht <strong>{{percentage}} der gebuchten Werbeeinblendungen</strong>.',
        impressionsRemaining: 'Es sind etwa <strong>{{imps}} Werbeeinblendungen</strong> übrig, für die Auslieferung.',
      },
      validationProblemsPresent: `
    <p>Leider musst du noch Anpassungen vornehmen, bevor du die Kampagne buchen kannst. Bitte überprüfe die rot hinterlegten Felder.</p>
      `
    },
    emailFooter: `
      <p>Du hast Fragen? Dann wirf gerne einen Blick in unsere <a href="https://www.infranken.de/werben-auf-infrankende/lokalwerben/#faq" target="_blank">Fragen &amp; Antworten</a>.</p>
      <p>Natürlich helfen wir dir gerne auch persönlich weiter:</p>
      <ul class="text-start">
        <li>lokalwerben@infranken.de</li>
        <li>0951 - 18 89 09 (Montag bis Donnerstag von 9:00 bis 16:00 und Freitag von 9:00 bis 12:00)</li>
      </ul>
      <p class="small">Für die Nutzung der Plattform "<a href="http://lokalwerben.infranken.de/" target="_blank">lokalwerben.inFranken.de</a>" gelten die <a href="https://www.infranken.de/agb/geschaeftsbedingungen-fuer-werbekunden-art-5192563/" target="_blank">AGB</a> und die <a href="https://adnuntius.com/resources/privacy-policy" target="_blank">Datenschutzerklärung</a> von <a href="https://adnuntius.com/" target="_blank">Adnuntius AS</a>.<br>
        Für die Ausspielung der Kampagne gelten die <a href="https://www.infranken.de/agb/geschaeftsbedingungen-fuer-werbekunden-art-5192563" target="_blank">AGB</a> und die <a href="https://www.infranken.de/datenschutz/datenschutz-art-3144" target="_blank">Datenschutzerklärung</a> von <a href="https://www.infranken.de/impressum/impressum-art-3145" target="_blank">inFranken.de</a>.</p>
    `,
    validation: {
      archived: 'Die Kampagne wurde archiviert. Du kannst sie im Bereich "Kampagnen" noch einsehen und für eine neue Kampagne kopieren.',
      error: {
        'user.sui.user.network.exists.code': 'Für diese Email-Adresse ist bereits ein Nutzer registriert. Bitte fordere ein neues Passwort an.'
      }
    }
  },
  "2f7b": {
    frontPage: `
      <div>
        <p><img src="https://regalia.adnuntius.com/label/meinestadt/ms-logo.svg" width="90%"></p>
      </div>
      <p>Buchen Sie jetzt <strong>schon ab 50 Euro</strong> Budget Ihre lokale Werbekampagne in drei einfachen Schritten:</p>
      <ol>
        <li>Bannerformat auswählen</li>
        <li>Banner hochladen oder mit Firmenlogo und Text selbst erstellen</li>
        <li>Region, Laufzeit und Budget wählen</li>
      </ol>
      <p>Ihre Werbekampagne wird auf dem <a href="https://meinestadt.de" target="_blank">meinestadt.de</a> Portal in den von Ihnen gewünschten Städten ausgespielt.</p>
      <p>Erreichen Sie Kunden vor Ort mit aktuellen Angeboten oder steigern die Bekanntheit Ihres Unternehmens in Ihrer Stadt.</p>
      <p><strong>Jetzt direkt starten, auch ohne Registrierung</strong></p>
      <p class="small">Diesen Service stellt Ihnen <a href="https://meinestadt.de" target="_blank">meinestadt.de</a> in Zusammenarbeit mit Adnuntius AS zur Verfügung.</p>
    `,
    emailFooter: `
      <p>Mehr Informationen finden Sie auf unserer <a href="https://www.meinestadt.de/unternehmen/b2b/werbemoeglichkeiten/lokal-werben" target="_blank">Übersichtsseite</a> und in den <a href="https://www.meinestadt.de/unternehmen/b2b/werbemoeglichkeiten/lokal-werben#fragen" target="_blank">Fragen & Antworten</a>.</p>
      <p>Für weitere Fragen erreichen Sie uns unter <a href="mailto:lokalwerben@meinestadt.de">lokalwerben@meinestadt.de</a></p>
      <p>Diese Plattform wird betrieben von Adnuntius AS. Für die Nutzung gelten die <a href="https://adnuntius.com/resources/tc-self-service-advertising-de" target="_blank">AGB</a> und <a href="https://adnuntius.com/privacy-policy-de" target="_blank">Datenschutzbestimmungen</a> von Adnuntius AS <a href="https://adnuntius.com/impressum" target="_blank">Impressum</a></p>
    `,
    emailFooter2: `
      <p>lokalwerben@meinestadt.de<p>
      <p><a href="https://www.meinestadt.de/unternehmen/b2b/werbemoeglichkeiten/lokal-werben#fragen" target="_blank">FAQs</a> | <a href="https://adnuntius.com/resources/tc-self-service-advertising-de" target="_blank">AGB</a> | <a href="https://adnuntius.com/privacy-policy-de" target="_blank">Datenschutzbestimmungen</a> | <a href="https://adnuntius.com/impressum" target="_blank">Impressum</a></p>
    `,
    campaign: {
      detailsTitle: 'Kampagnendetails festlegen',
      product: {
        title: 'Format auswählen'
      },
      category: {
        title: 'Region wählen',
        descriptionPrefix: 'Die Kampagne wird ausgespielt in:&nbsp;',
        instructions: 'Wählen Sie mindestens eine Stadt aus',
        any: 'jeder Stadt',
        more: 'Weitere Ergebnisse anzeigen',
        noResults: 'Keine Ergebnisse gefunden',
        searchOptions: 'Stadtname eingeben'
      },
      ad: {
        create: 'Werbemittel hochladen / gestalten',
        copyrightMessage: 'Hiermit versichere ich, keine Inhalte (wie insbesondere Texte oder Bilddateien) hochzuladen bzw. zu verwenden, die gegen gesetzliche Bestimmungen, insbesondere Wettbewerbs- und Datenschutzgesetze, Persönlichkeitsrechte und/oder Schutzrechte Dritter (z.B. Urheber- und Markenrechte) oder gegen die guten Sitten verstoßen. Ich stelle die meinestadt.de GmbH von allen Ansprüchen Dritter frei, die aus einer unzulässigen Nutzung entstehen.'
      }
    }
  },
};
