import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { convertJsonStringFieldToObject } from '@shared/utils/json-response-util';
import { AuthenticatedUser, AuthUser } from '@app/@core/@models/auth/authenticated-user.model';
import { ConfigService } from '@app/@core/@config/config.service';
import { AccessToken } from '@app/@core/@models/auth/access-token.model';
import {NavigatorLocaleService} from '@app/@i18n/services/navigator-locale.service';
import {Locale} from '@app/@core/@models/common/locale';

@Injectable({ providedIn: 'root' })
export class UserResource {
  constructor(
    private httpClient: HttpClient,
    private navigatorLocaleService: NavigatorLocaleService,
    private configService: ConfigService
  ) {}

  resetPassword(auth_token: string, password: string): Observable<AccessToken> {
    return this.httpClient.post<AccessToken>(
      this.configService.getVersionedUri('user/reset-password'),
      new HttpParams().set('password', password).toString(),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        params: new HttpParams().set('app', 'SELF_SERVICE').set('interface', '').set('auth_token', auth_token),
      }
    );
  }

  get(): Observable<AuthUser> {
    return this.httpClient.get<AuthenticatedUser>(this.configService.getVersionedUri('user'))
      .pipe(
        map((data) => convertJsonStringFieldToObject(data, 'profile') as AuthUser),
        tap((authUser) => {
          authUser.user.locale = this.upgradeUserLocale(authUser.user.locale as string);
        })
      );
  }

  private upgradeUserLocale(locale: string): Locale {
    if (!locale) {
      return this.navigatorLocaleService.getNavigatorJavaLocale();
    } else if (locale === 'in') {
      // see changes in https://angular-doc.ru/guide/i18n
      // Indonesian changed from 'in' to 'id'
      return 'id';
    } else if (locale === 'tl') {
      // see changes in https://angular-doc.ru/guide/i18n
      // Filipino changed from 'tl' to 'fil'
      return 'fil';
    } else {
      return locale as Locale;
    }
  }
}
