import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {finalize, Observable, Subject, switchMap, take, tap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ConfigService} from '@app/@core/@config/config.service';
import {AccessToken} from '@app/@core/@models/auth/access-token.model';
import {AuthService} from '@app/@core/auth/auth.service';
import {SignInModalLauncher} from '@shared/sign-in/launcher/sign-in-modal-launcher.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private accessToken$?: Subject<AccessToken>;
  private successfulRequests = 0;

  constructor(
    private authenticationService: AuthService,
    private router: Router,
    private configService: ConfigService,
    private signInModalLauncher: SignInModalLauncher
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request);
    return next.handle(request).pipe(
      tap((httpEvent) => {
        if (httpEvent.type === HttpEventType.Response) {
          // since we had a successful request go through, this is no longer a new session
          this.successfulRequests++;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleResponseError(error, request, next);
      }),
    );
  }

  private handleResponseError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (error.status !== 401) {
      // propagate non 401 errors
      return throwError(() => error);
    } else if (!this.authenticationService.getAccessToken()) {
      // we don't have a token to refresh, so just return error
      return throwError(() => error);
    } else {
      // token is invalid, refresh it and retry request
      return this.refreshTokenAndRetryRequest(request, next);
    }
  }

  private refreshTokenAndRetryRequest(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // refresh token
    return this.refreshToken().pipe(
      switchMap(() => {
        // re-try request
        return next.handle(this.addAuthHeader(request)).pipe(
          tap((httpEvent) => {
            if (httpEvent.type === HttpEventType.Response) {
              this.successfulRequests++;
            }
          }),
        );
      }),
      catchError((err) => {
        // if refresh fails or re-try fails, just propagate error
        return throwError(() => err);
      })
    )
  }

  private refreshToken(): Observable<AccessToken> {
    if (!this.accessToken$) {
      // first request that triggered refresh token processing
      this.accessToken$ = new Subject<AccessToken>();
      return this.authenticationService.refreshToken().pipe(
        // token refresh success, notify parallel requests to attempt a re-try
        tap((accessToken) => this.accessToken$!.next(accessToken)),
        // error refreshing token
        catchError(() => this.handleRefreshTokenError()),
        // cleanup
        finalize(() => {
          this.accessToken$ = undefined;
        })
      );
    } else {
      // parallel requests to wait for refresh token processing to complete
      return this.accessToken$.pipe(
        take(1)   // complete after 1 token comes through, or on error
      );
    }
  }

  private handleRefreshTokenError(): Observable<AccessToken> {
    // use modal sign-in to refresh token
    return this.signInModalLauncher.requestSignIn({isNewSession: this.successfulRequests === 0})
      .pipe(
        tap((accessToken) => {
          this.accessToken$!.next(accessToken);
        }),
        catchError((err) => {
          // modal sign-in failed, notify parallel requests of error and sign-out
          this.accessToken$!.error(err);
          this.signOut();
          return throwError(() => err);
        }),
      );
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const url = request.url;
    if (!url.includes('/api/') && !url.includes('preview') && !url.includes('synchronous')) {
      return request;
    } else if (url.startsWith('https:') && this.authenticationService.isAuthenticated()) {
      const token = this.authenticationService.getAccessToken()!;
      return request.clone({
        setParams: {auth_token: token.access_token},
      });
    } else if (!url.startsWith('https:') && this.authenticationService.isAuthenticated()) {
      const token = this.authenticationService.getAccessToken()!;
      return request.clone({
        setHeaders: {Authorization: 'Bearer ' + token.access_token},
      });
    } else {
      return request;
    }
  }

  private signOut() {
    this.authenticationService.signOut();
    this.router.navigate([this.configService.getUrl('/sign-in')]);
  }

}
