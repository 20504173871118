import {Component, Input, OnInit} from '@angular/core';
import {I18nLangService} from '@app/@i18n/services/i18n-lang.service';
import {AnonProfileStore} from '@app/@core/anon/anon-profile-store.service';
import {EMPTY, Subject} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {UserProfile} from '@app/@core/@models/users/profile/user-profile.model';
import {HttpErrorResponse} from '@angular/common/http';
import {UserProfileService} from '@app/@core/users/user-profile.service';
import {SignedInContext} from '@app/@shells/signed-in-shell/signed-in-context.service';

@Component({
  selector: 'app-language-selector[signedInContext][inNav]',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() signedInContext?: SignedInContext;
  @Input() inNav!: boolean;
  langUpdate$!: Subject<string>;
  errorKey?: string;
  language: string = "";
  supportedLanguages: string[] = [];

  constructor(
    private i18nService: I18nLangService,
    private i18nLangService: I18nLangService,
    private anonProfileStore: AnonProfileStore,
    private userProfileService: UserProfileService
  ) {
  }

  ngOnInit() {
    this.initLangUpdateStream();
    this.language = this.i18nLangService.getLanguage();
    this.supportedLanguages = this.i18nService.supportedLanguages;
  }

  setLanguage(language: string) {
    this.i18nLangService.setLanguage(language);
    this.language = language;
    if (this.signedInContext) {
      this.errorKey = undefined;
      this.langUpdate$.next(this.i18nLangService.getLanguage());
    }
    this.anonProfileStore.updateAnonProfile({lang: this.i18nLangService.getLanguage()});
  }

  private initLangUpdateStream() {
    this.langUpdate$ = new Subject<string>();
    this.langUpdate$
      .pipe(
        // update user profile on server
        switchMap((lang) =>
          this.userProfileService.updateUserProfile(this.signedInContext!.user, {lang: lang} as UserProfile).pipe(
            catchError((error: HttpErrorResponse) => {
              this.errorKey = error.error?.code || error.statusText || error;
              return EMPTY; // we're done
            })
          )
        )
      )
      .subscribe();
  }
}
