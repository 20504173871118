import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Asset} from '@app/@core/@models/advertising/asset.model';
import {AssetComponent} from '@app/@core/@models/advertising/layout/asset-component.model';
import {EMPTY, Subject, tap} from 'rxjs';
import {AssetResource} from '@app/@core/@rest/advertising/asset-resource.service';
import {catchError, switchMap} from 'rxjs/operators';
import {Uuid} from '@shared/utils/uuid';
import {HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {MediaTypeHelper} from '@app/@core/design/media-type-helper.service';

@Component({
  selector: 'app-upload-asset[creativeId][anon][invalid][assetComponent][newAsset]',
  templateUrl: './upload-asset.component.html',
  styleUrls: ['./upload-asset.component.scss']
})
export class UploadAssetComponent implements OnInit {
  @Input() creativeId!: string;
  @Input() anon!: boolean;
  @Input() invalid!: boolean;
  @Input() assetComponent!: AssetComponent;
  @Output() newAsset = new EventEmitter<Asset>();
  fileInput$ = new Subject<File>();
  highlightDropzone = false;
  uploading: boolean = false;
  uploadPercentage: number = 0;
  errorCode?: string;
  uuid: string = Uuid.generate();

  constructor(private assetResource: AssetResource,
              private mediaTypeHelper: MediaTypeHelper) {}

  ngOnInit(): void {
    if (this.anon) {
      this.initFileHandling_Anon();
    } else {
      this.initFileHandling_LoggedIn();
    }
  }

  handleFileInput(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileInput$.next(file);
    }
  }

  onDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  onDragEnter(e: DragEvent) {
    this.highlightDropzone = true;
    e.preventDefault();
    e.stopPropagation();
  }

  onDragLeave(e: DragEvent) {
    this.highlightDropzone = false;
    e.preventDefault();
    e.stopPropagation();
  }

  onDrop(e: DragEvent) {
    this.highlightDropzone = false;
    e.preventDefault();
    e.stopPropagation();
    const file: File = e.dataTransfer!.files[0];
    if (file) {
      this.fileInput$.next(file);
    }
  }

  private initFileHandling_Anon() {
    this.fileInput$
      .pipe(
        tap(() => {
          this.uploading = true;
          this.uploadPercentage = 0;
        }),
        // load file into an Asset object
        switchMap((file) =>
          this.assetResource.base64Asset(file).pipe(
            catchError((err) => {
              this.errorCode = err;
              this.uploading = false;
              return EMPTY; // we're done
            })
          )
        ),
        // success
        tap((asset) => {
          this.uploading = false;
          this.newAsset.next(asset);
        })
      )
      .subscribe();
  }

  private initFileHandling_LoggedIn() {
    this.fileInput$
      .pipe(
        tap(() => {
          this.uploading = true;
          this.uploadPercentage = 0;
        }),
        // save file to backend
        switchMap((file) =>
          this.assetResource.post(this.creativeId, Uuid.generate(), file).pipe(
            catchError((error: HttpErrorResponse) => {
              this.errorCode = error.error?.code || error.statusText;
              return EMPTY;
            })
          )
        ),
        tap((httpEvent: HttpEvent<Asset>) => {
          if (httpEvent instanceof HttpResponse) {
            if (httpEvent.body) {
              // success
              this.newAsset.next(httpEvent.body);
              this.uploading = false;
            }
          } else if (httpEvent.type === HttpEventType.UploadProgress) {
            this.uploadPercentage = httpEvent.total ? Math.round((100 * httpEvent.loaded) / httpEvent.total) : 50;
          }
        })
      )
      .subscribe();
  }

  getAcceptMediaTypesCS(): string {
    return this.mediaTypeHelper.getSupportedMediaTypes(this.assetComponent).flatMap(mediaType => mediaType.rawMimeTypes).join(',')
  }

}
