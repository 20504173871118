import { AdvertForm } from "@app/@core/advertising/campaign/advert/advert-form";
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {Creative} from '@app/@core/@models/advertising/creative.model';
import {assertNonNullable} from '@shared/utils/asserts';
import {Product} from '@app/@core/@models/design/product.model';

export class AdvertDraft {

  campaignDraft: CampaignDraft;
  advertForm: AdvertForm;
  creative: Creative;
  count: number;

  constructor(campaignDraft: CampaignDraft, advertForm: AdvertForm, count: number) {
    this.campaignDraft = campaignDraft;
    this.advertForm = advertForm;
    assertNonNullable(advertForm.controls.creative.value);
    this.creative = advertForm.controls.creative.value;
    this.count = count;
  }

  layoutChanged() {
    this.campaignDraft.layoutChanged(this);
  }

  addAnotherAdvert() {
    this.campaignDraft.addAnotherAdvert(this);
  }

  removeAdvert() {
    this.advertForm.controls.objectState.setValue("HIDDEN");
    this.advertForm.controls.objectState.markAsDirty();
    this.campaignDraft.advertHidden(this);
  }

  isSelectedLayoutValid(): boolean {
    const layouts = this.campaignDraft.layoutsForSelectedProduct();
    return layouts.find(layout => layout.id === this.advertForm.value.layoutId) !== undefined;
  }

  productChanged(product: Product) {
    // when product is first selected, we want to make sure the advert renders asap to make things look snappy
    if (!this.isSelectedLayoutValid()) {
      const layouts = this.campaignDraft.layoutsForSelectedProduct();
      const layout = this.campaignDraft.campaignDraftFactory.validatedLayoutOrDefault(product,
        this.advertForm.value.layoutId || "",
        layouts);
      if (layout) {
        this.advertForm.controls.layoutId.setValue(layout.id);
        this.advertForm.controls.layoutId.markAsDirty();
        if (this.advertForm.value.layoutComponents && this.advertForm.value.layoutComponents.length === 0) {
          this.advertForm.controls.layoutComponents = this.campaignDraft.campaignDraftFactory.newLayoutComponentFormArray(this.campaignDraft.mediaTypeHelper, this.campaignDraft.fb, layout);
          this.advertForm.controls.layoutComponents.markAsDirty();
        }
        if (!this.advertForm.value.creativeSize) {
          const creativeSize = this.campaignDraft.campaignDraftFactory.validatedCreativeSizeOrDefault("", product);
          if (creativeSize) {
            this.advertForm.controls.creativeSize.setValue(creativeSize);
            this.advertForm.controls.creativeSize.markAsDirty();
          }
        }
      }
    }
  }

}
