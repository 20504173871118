import {Component, Input, OnInit} from '@angular/core';
import {Product} from '@app/@core/@models/design/product.model';
import {CategoryTarget} from '@app/@core/@models/targeting/category-target.model';
import {TargetingForm} from '@app/@core/advertising/campaign/advert/targeting-form';
import {Uuid} from '@shared/utils/uuid';
import {debounceTime, distinctUntilChanged, Subject} from "rxjs";

import _filter from 'lodash-es/filter';
import _map from 'lodash-es/map';
import _orderBy from 'lodash-es/orderBy';
import _flatten from "lodash-es/flatten";
import {getMeinestadtLocations} from "../../../../../scripts/meinestadt-locations";
import {ConfigService} from "@app/@core/@config/config.service";

type CategoryOutput = {
  output: string,
  id: string
};

@Component({
  selector: 'app-category-targeting-options[product][targetingForm]',
  templateUrl: './category-targeting-options.component.html',
  styleUrls: ['./category-targeting-options.component.scss']
})
export class CategoryTargetingOptionsComponent implements OnInit {
  readonly PAGE_SIZE = 15;

  @Input() product!: Product;
  @Input() targetingForm!: TargetingForm;
  uuid: string = Uuid.generate();
  categoryTargets: CategoryTarget[] = [] as CategoryTarget[];
  categoryOutputs: CategoryOutput[] = [] as CategoryOutput[];
  showSearch: boolean = false;
  searchTerm: string = "";
  searchTermChangedHandler: Subject<string> = new Subject<string>();
  pagedSearchResults: CategoryOutput[] = [] as CategoryOutput[];
  showMoreLink = false;
  quickFindIndex: Record<string, boolean> = {};
  storedLocations: Record<string, string> = {};

  private moreResults = 0;

  constructor(private configService: ConfigService) {
    this.searchTermChangedHandler
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((val) => {
        this.moreResults = 0;
        this.searchTerm = val;
        this.obtainSearchResults(this.searchTerm);
      });
  }

  searchTermChanged(text: string) {
    this.searchTermChangedHandler.next(text);
  }

  ngOnInit(): void {
    this.showSearch = this.targetingForm.controls.categoryTargets.controls.length > 1000;
    if (this.showSearch) {
      this.storedLocations = getMeinestadtLocations();
    }
    this.categoryTargets = this.product.creativeTargeting.categoryTargets as CategoryTarget[];
    this.updateCategoriesAsString();
    this.obtainSearchResults();
  }

  showMoreResults(): void {
    this.moreResults = this.moreResults + this.PAGE_SIZE;
    this.obtainSearchResults(this.searchTerm);
  }

  isInvalid(): boolean {
    return (this.configService.getNetworkData()?.mandatoryCategories || false) && this.categoryOutputs.length < 1;
  }

  private obtainSearchResults(val?: string) {
    if (!this.showSearch) {
      return;
    }
    const cats = _flatten(_map(this.categoryTargets, (cat) => {
      return cat.categories;
    }));
    const data = val ? _filter(_map(cats, (cat) => {
      const catDisplay = (this.storedLocations[cat] || cat).toLowerCase();
      return {
        cat: cat,
        val: catDisplay.indexOf(val.toLowerCase())
      };
    }), (catResult) => {
      return catResult.val > -1;
    }) : _map(cats, function (cat) {
      return {cat: cat, val: cat.charAt(0).toLowerCase().charCodeAt(0)};
    });
    const searchResults = _orderBy(data, ['val']);
    this.pagedSearchResults = _map(searchResults.slice(0, val || this.moreResults > 0 ? (this.PAGE_SIZE + this.moreResults) : 3), (category) => {
      return {
        id: category.cat,
        output: this.storedLocations[category.cat] || category.cat
      }
    });
    this.showMoreLink = searchResults.length > (this.moreResults + this.PAGE_SIZE);
  }

  entryChanged(entry: string, newValue: boolean) {
    for (let i = 0; i < this.categoryTargets.length; i++) {
      if (this.categoryTargets[i].categories[0] === entry) {
        this.targetingForm.value.categoryTargets![i] = newValue;
        this.updateCategoriesAsString();
        this.targetingForm.markAsDirty();
        break;
      }
    }
  }

  selectionHappened(): void {
    this.updateCategoriesAsString();
  }

  private updateCategoriesAsString(): void {
    this.categoryOutputs = [];
    this.quickFindIndex = {};
    for (let i = 0; i < this.targetingForm.value.categoryTargets!.length; i++) {
      if (this.targetingForm.value.categoryTargets![i]) {
        const categoryTarget = this.categoryTargets[i];
        if (categoryTarget) {
          this.categoryOutputs.push(this.categoryOutput(categoryTarget));
          this.quickFindIndex[categoryTarget.categories[0]] = true;
        }
      }
    }
  }

  private categoryOutput(categoryTarget: CategoryTarget): CategoryOutput {
    const categoryId = categoryTarget.categories[0];
    return {
      id: categoryId,
      output: this.storedLocations[categoryId] || categoryId
    };
  }
}
