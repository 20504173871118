/**
 * Copyright © 2023 Adnuntius AS.
 */

export const TRANSLATIONS_DE = {
  adn: {
    copyright: 'Copyright © 2023 Adnuntius AS',
    releaseId: 'Release ID: {{id}}',
    poweredBy: 'Powered by Adnuntius AS',
  },
  grammar: {
    listSeparator: ', ',
    titleSeparator: ': ',
    booleanOr: 'ODER',
  },
  general: {
    new: 'Neu',
    saveSuccess: 'Die Änderungen wurden erfolgreich gespeichert.',
    timezone: 'Alle Daten und Uhrzeiten sind in der Zeitzone {{timezone}} angegeben.'
  },
  timezone: {
    'Europe/Berlin': 'Europa/Berlin'
  },
  button: {
    upload: {
      //asset: 'Upload',
      asset: 'Hochladen',
      //drop: 'Drop a file here or use the upload button'
      drop: 'Datei hineinziehen oder über den Upload Button hochladen',
    },
    cancel: 'Stornieren',
    //archive: 'Archive',
    archive: 'Archivieren',
    //activate: 'Activate',
    activate: 'Aktivieren',
    //applyCustomRange: 'Apply custom range',
    applyCustomRange: 'Range auswählen',
    //clear: 'Clear',
    clear: 'Löschen',
    //close: 'Close',
    close: 'Schließen',
    //newCampaign: 'Create New Campaign',
    newCampaign: 'Neue Kampagne anlegen',
    //save: 'Save',
    save: 'Speichern',
    //undo: 'Undo',
    undo: 'Rückgängig',
    //today: 'Today',
    today: 'Heute',
  },
  locale: {
    ar: 'Arabic - عربى',
    ar_JO: 'Arabic (Jordan) - العربية (الأردن)',
    ar_EG: 'Arabic (Egypt) - العربية (مصر)',
    ar_AE: 'Arabic (UAE) - العربية (الإمارات العربية المتحدة)',
    my: 'Burmese - ဗမာ',
    en: 'English',
    en_AU: 'English (Australia)',
    en_CA: 'English (Canada)',
    en_US: 'English (USA)',
    en_GB: 'English (Great Britain)',
    es: 'Spanish - español',
    es_ES: 'Spanish (Spain) - español (españa)',
    es_AR: 'Spanish (Argentina) - español (argentina)',
    et: 'Estonian - eesti keel',
    fi: 'Finnish - Suomalainen',
    id: 'Indonesia - Bahasa',
    is: 'Icelandic - íslensku',
    de: 'German - Deutsch',
    de_DE: 'German (Germany) - Deutsch (Deutschland)',
    de_AT: 'German (Austria) - Deutsch (Österreich)',
    de_CH: 'German (Switzerland) - Deutsch (Schweiz)',
    nl: 'Dutch',
    nl_BE: 'Dutch (Belgium) - Nederlands (België)',
    nl_NL: 'Dutch (Netherlands) - Nederlands (Nederland)',
    no: 'Norwegian - norsk',
    sv: 'Swedish - svenska',
    sv_SE: 'Swedish (Sweden) - svenska (Sverige)',
    sv_FI: 'Swedish (Finland) - svenska (Finland)',
    pl: 'Polish - Polskie',
    pt: 'Portuguese - Português',
    pt_BR: 'Portuguese (Brazil) - Português (Brasil)',
    pt_PT: 'Portuguese (Portugal) - Português (Portugal)',
    ru: 'Russian - русский',
    el: 'Greek - Ελληνικά',
    fr: 'French - français',
    da: 'Danish - Dansk',
    th: 'Thai - ไทย'
  },
  language: {
    text: 'Sprache:'
  },
  devices: {
    MOBILE: 'Smartphone',
  },
  campaign: {
    //archivedHeading: 'Archived Campaigns',
    archivedHeading: 'Archivierte Kampagnen',
    //archivedNoResults: 'No archived campaigns found.',
    archivedNoResults: 'Keine archivierten Kampagnen gefunden',
    //archivedShowLink: 'Show archived campaigns',
    archivedShowLink: 'Archivierte Kampagnen anzeigen',
    adRejected: {
      budgetUnchanged: 'Das Budget kann nicht angepasst werden, wenn bei Überprüfung abgelehnt wurde.',
      productUnchanged: 'Das Product kann nicht geändert werden, wenn bei Überprüfung abgelehnt wurde.',
      explanationGeneral: 'Werbemittel #{{id}} wurde abgelehnt, daher kann die Kampagne keine Impressions ausliefern. Siehe unten für mehr Details.',
      //explanation: 'Adjust your ad and resubmit your campaign for review to have it deliver your budgeted impressions.',
      explanation: 'Werbemittel anpassen und zur Überprüfung senden um die budgetierten Impressions auszuliefern',
      ADULT_CONTENT: {
        //name: 'Adult Content',
        name: 'Nicht-jugendfreier Inhalt',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains adult-oriented content.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es nicht jugendfrei ist oder auf eine nicht-jugendfreie Seite verlinkt'
      },
      BLANK_CONTENT: {
        //name: 'Blank Content',
        name: 'Leerer Inhalt',
        //explanation: 'The ad was rejected because it renders as blank. Please ensure that the required content have been provided correctly.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es nicht vollständig zu sein scheint und Inhalte fehlen'
      },
      MALFORMED_CLICK_THROUGH: {
        //name: 'Malformed Destination URL',
        name: 'Fehlerhafte Ziel-URL',
        //explanation: 'The ad was rejected because the click destination URL does not function properly.'
        explanation: 'Das Werbemittel wurde abgelehnt, da die Ziel-URL nicht funktioniert'
      },
      DRUG_RELATED_CONTENT: {
        //name: 'Drug-Related Content',
        name: 'Betäubungsmittel-Inhalte',
        //explanation: 'The ad was rejected because it or its landing page promotes drug-related content or products. This may include tobacco, alcohol, pharmaceutical or other legal or illegal drugs.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es selbst oder die Ziel-URL Informationen über Drogen, wie beispielsweise Tabak, Alkohol, pharmazeutische Mittel oder andere legale und illegale Drogen, beinhaltet'
      },
      WEAPON_RELATED_CONTENT: {
        //name: 'Weapon-Related Content',
        name: 'Waffenbezogene-Inhalte',
        //explanation: 'The ad was rejected because it or its landing page promotes weapons or weapon-related content. This can include content that promotes dangerous knives, guns, gun parts and other related content.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es selbst oder die Ziel-URL Informationen über Waffen beinhaltet'
      },
      HATE_SPEECH_CONTENT: {
        //name: 'Hate-Speech Content',
        name: 'Hassreden-Ihalte',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains hate speech content. This may include content promoting violence, harassment, racism, hatred and intolerance.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es selbst oder die Ziel-URL Hassreden beinhaltet'
      },
      LANDING_PAGE_REJECTED: {
        //name: 'Landing Page Rejected',
        name: 'Landing-page abgelehnt',
        //explanation: 'The ad was rejected because the landing page was low quality or difficult to interact with. This can include pages that disable browser navigation functions, include low value content, or lead to an email address or file, such as image, audio, video, or document, that requires an additional application to open.'
        explanation: 'Das Werbemittel wurde abgelehnt, da die Ziel-URL nicht den Qualitätsanforderungen entspricht. Dazu zählen u.a. die Browsernavigation oder der Seiteninhalt, außerdem ein Link zu einer E-Mail Adresse, oder einer Datei wie Video- oder Audio-files, die eine zusätzliche Anwendung starten'
      },
      OFFENSIVE_CONTENT: {
        //name: 'Offensive Content',
        name: 'Beleidigende Inhalte',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains offensive or inappropriate content. Ensure that your ads and landing pages only show family safe content.',
        explanation: 'Das Werbemittel wurde abgelehnt, da es selbst oder die Ziel-URL beleidigende oder unangemessende Inahlte enthält',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        //name: 'Unacceptable Visual Effects',
        name: 'Unangemessene visuelle Effekte',
        //explanation: 'The ad was rejected because it uses unacceptable visual effects. This includes animated GIFs with a frame rate higher than 5 frames per second.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es unangemessene visuelle Effekte, wie z.B. animierte Gifs mit Frame-Raten >5 Frames pro Sekunde, enthält'
      },
      DECEPTIVE_CONTENT: {
        //name: 'Deceptive Content',
        name: 'Irreführende Inhalte',
        //explanation: 'The ad was rejected because it contains misleading claims or information. Examples include ads that make scientifically impossible claims, offer guaranteed results without a refund policy, or show testimonials claiming specific results without a link to third-party verification. Ads may also be rejected if the creative is designed to look like software download buttons, official system warnings, or webpage features like menus, search results, or other site content.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es irreführende Informationen enthält'
      },
      GAMBLING_CONTENT: {
        //name: 'Gambling Content',
        name: 'Glücksspiel-Inhalte',
        //explanation: 'The ad was rejected because it contains gambling-related content.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es Informationen zu Glücksspielen enthält'
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        //name: 'Prohibited Election-Related Content',
        name: 'Verbotene Inhalte zu Wahlen',
        //explanation: 'The ad is rejected because it contains prohibited content related to government elections or currently elected government officeholders.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es verbotene oder unbestätigte Informationen bezüglich politischen Wahlen enthält'
      },
      SHOCKING_CONTENT: {
        //name: 'Shocking Content',
        name: 'Schockierende Inhalte',
        //explanation: 'The ad was rejected because it contains shocking content such as profane language, gruesome or disgusting imagery, or promotions that are designed to shock or scare.'
        explanation: 'Das Werbemittel wurde aufgrund schockierender Inhalte, vulgärer Sprache oder Bildern abgelehnt'
      },
      MALWARE_CONTENT: {
        //name: 'Malware Content',
        name: 'Malware-Inhalte',
        //explanation: 'The ad was rejected because it or its landing page contains or leads to unacceptable content such as malware.'
        explanation: 'Das Werbemittel wurde abgelehnt, da es selbst oder die Zielseite Malware enthält'
      },
      POOR_QUALITY: {
        name: 'Unzureichende Qualität des Werbemittels',
        explanation: 'Das Werbemittel wurde wegen zu geringer Qualität abgelehnt. Mögliche Gründe dafür können Grafiken in zu geringer Auflösung, eine undeutliche und verwaschene Darstellung oder die schlechte Lesbarkeit von Inhalten sein.'
      }
    },
    anon: {
      signInText: 'Ohne Login starten',
      heading: '',
      beforeText: 'Beginne nun mit der Gestaltung deiner Kampagne. Alle Details werden im Browser zwischengespeichert. Um den Prozess abzuschließen, musst du dich zuvor jedoch&nbsp;',
      linkText: 'registrieren',
      postText: '.',
      saveTitle: 'Ihre Kampagne wurde in unserer Datenbank gespeichert',
      saveWithMinorError: 'Wir hatten einige Probleme, alles in unserer Datenbank zu speichern, aber jetzt, da Sie sich angemeldet haben, sollte das kein Problem sein, wenn Sie fortfahren.',
      saveCampaignLinkPrefix: 'Sehen Sie sich ',
      saveCampaignLinkText: 'Ihre gespeicherte Kampagne an und senden Sie sie zur Überprüfung',
      saveCampaignLinkPostfix: '.',
      failSaveTitle: 'Ihre Kampagne konnte nicht in unserer Datenbank gespeichert werden.',
      failSaveCampaignLinkPrefix: 'Erstellen Sie jetzt, nachdem Sie sich angemeldet haben, ',
      failSaveCampaignLinkText: 'eine Kampagne direkt mit unserer Datenbank',
      failSaveCampaignLinkPostfix: '.',
      username: 'Unbekannt',
      signUp: 'Anmelden'
    },
    layout: {
      //title: 'Select an Ad Design',
      title: 'Ad Design auswählen',
      //missing: 'The layout you selected previously is not available for the current advertising option.',
      missing: 'Das ausgewählte Layout ist für diese Advertising Option nicht verfügbar.',
      //missingPreview: "A valid layout needs to be selected before a creative can be previewed."
      missingPreview: 'Ein zulässiges Layout muss ausgewählt sein, bevor die Werbemittelvorschau verfügbar ist.'
    },
    device: {
      title: 'Device Targeting Optionen',
      descriptionPrefix: 'Das Werbemittel wird angezeigt auf:&nbsp;',
      instructions: 'Wähle Device Optionen aus',
      any: 'jedem Endgerät',
    },
    domainName: {
      title: 'Domain Name Targeting Optionen',
      descriptionPrefix: 'Das Werbemittel wird angezeigt auf:&nbsp;',
      instructions: 'Wähle Domain Name Optionen aus',
      any: 'jeder verfügbaren Domain'
    },
    kvs: {
      title: 'Bereich Targeting Optionen',
      descriptionPrefix: 'Das Werbemittel wird angezeigt in:&nbsp;',
      instructions: 'Bitte wähle die Bereiche, in denen das Werbemittel angezeigt werden soll',
      any: 'allen Bereichen'
    },
    category: {
      title: 'Kategorien Targeting Optionen',
      descriptionPrefix: 'Das Werbemittel wird angezeigt auf:&nbsp;',
      instructions: 'Bitte wähle die Kategorien, auf denen das Werbemittel angezeigt werden soll',
      any: 'allen Kategorien'
    },
    location: {
      title: 'Location Targeting Optionen',
      descriptionPrefix: 'Das Werbemittel wird angezeigt in:&nbsp;',
      instructions: 'Wähle eine oder mehrere Locations aus',
      any: 'jeder Ort'
    },
    userSegment: {
      title: 'User Segment Targeting Optionen',
      descriptionPrefix: 'Das Werbemittel wird angezeigt an:&nbsp;',
      instructions: 'Wähle ein oder mehrere Segmente aus',
      any: 'jeder Benutzer'
    },
    product: {
      //title: 'Select an Advertising Option',
      title: 'Advertising Option auswählen',
      //adDesignChoice: '<strong>{{count}} ad designs</strong> available at these sizes:',
      adDesignChoice: '<strong>{{count}} ad designs</strong> verfügbar für diese Größen:',
      //cpm: '<strong>{{value}}</strong> per thousand impressions',
      cpm: '<strong>{{value}}</strong> pro tausend Impressions',
      //minSpend: '<strong>{{value}}</strong> minimum spend'
      minSpend: '<strong>{{value}}</strong> minimales Budget',
      maxSpend: '<strong>{{value}}</strong> maximale Budget'
    },
    ad: {
      stretchable: 'Ihre Anzeige ist dehnbar. Verwenden Sie den Schalter unten, um Ihre Anzeige entweder in einer festen Größe oder dehnbar entsprechend der Größe des Containers anzuzeigen.',
      stretched: 'Ihre Anzeige ist dehnbar. Ihre Anzeige wird entsprechend dem Container, in dem sie platziert wird, vergrößert und verkleinert.',
      stretchableFixedOption: 'Fest',
      stretchableStretchableOption: 'Dehnbar',
      //title: 'Design Your Ad',
      title: '',
      //defaultName: 'Creative for {{campaignName}}',
      defaultName: 'Werbemittel für {{campaignName}}',
      //layoutFirst: 'Select an ad design before entering your ad\'s details.',
      layoutFirst: 'Wähle ein Ad Design vor der Eingabe der Werbemittel Details',
      //deletedAsset: 'This component references a deleted image. Upload a new one.',
      deletedAsset: 'Diese Komponente referenziert auf ein gelöschtes Bild. Bitte ein neues hochladen.',
      imageHint: '{{mediaTypes}}, Max: {{maxSize}}kb',
      imageHintOptional: '{{mediaTypes}}, Max: {{maxSize}}kb (Optional)',
      //adSizeTitle: 'Ad Size',
      adSizeTitle: 'Werbemittelgröße',
      create: 'Gestalte das Werbemittel',
      add: 'Werbemittel hinzufügen',
      count: 'Werbemittel <strong>#{{count}}</strong>',
      maximum: 'Es können maximal 3 Werbemittel hinzugefügt werden',
      //choiceOptional: '(No choice)',
      choiceOptional: '(keine Auswahl)',
      //runningPreviewTitle: 'Your {{width}}&times;{{height}} Advertisement',
      runningPreviewTitle: 'Deine {{width}}&times;{{height}} Anzeige',
      remove: 'Werbemittel löschen',
      //previewTitle: 'Ad Preview',
      previewTitle: 'Werbemittelvorschau',
      previewExampleField: 'Beispiel ',
      //border: 'Show border on advertisement',
      border: 'Rahmen anzeigen, um die Anzeigengröße zu demonstrieren',
      //previewWithExample: 'This ad preview includes example data for demonstration purposes.',
      previewWithExample: 'Diese Vorschau enthält Beispiele zu Demonstrationszwecken',
      //textCount: 'count: {{count}}',
      textCount: 'Anzahl: {{count}}',
      textHintChars: '{{min}}&ndash;{{max}} Zeichen',
      textHintCharsOptional: '{{min}}&ndash;{{max}} Zeichen (Optional)',
      urlHint: 'URL',
      urlHintOptional: 'URL (Optional)',
      copyrightMessage: 'Ich erkläre, dass kein Inhalt, den ich verwende, gegen Urheber- oder Markenschutz verstößt'
    },
    reachEstimate: {
      disabled: 'Bitte speichere die Kampagne nach der Eingabe des Budgets um die Verfügbarkeit zu prüfen',
      trigger: 'Verfügbarkeit überprüfen',
      rangeLowerUpper: 'Weitere {{lower}} bis {{upper}} Impressionen werden voraussichtlich ohne Konkurrenz verfügbar sein.',
      rangeUpper: 'Bis zu {{upper}} weitere Impressionen werden voraussichtlich ohne Konkurrenz verfügbar sein.',
      VERY_GOOD: 'Die gebuchten Impressions können mit größter Wahrscheinlichkeit ausgeliefert werden.',
      GOOD: 'Die gebuchten Impressions können sehr wahrscheinlich ausgeliefert werden.',
      POSSIBLE: 'Die gebuchten Impressions sind im Wettbewerb mit anderen Kampagnen.',
      COMPETITION: 'Die gebuchten Impressions sind im Wettbewerb mit anderen Kampagnen. Eventuell können nicht alle Impressions ausgeliefert werden.',
      MUCH_COMPETITION: 'Die gebuchten Impressions sind im Wettbewerb mit anderen Kampagnen. Eventuell können nur wenige Impressions ausgeliefert werden.',
      LOTS_COMPETITION: 'Die gebuchten Impressions sind im Wettbewerb mit anderen Kampagnen. Eventuell können keine Impressions ausgeliefert werden.',
    },
    addNewCampaign: `
      <h3>Werbekampagne anlegen</h3>
      <p>Werbekampagnen liefern die Werbemittel aus.</p>
      <p>Neue Werbekampagnen mit dem oberen Button anlegen.</p>
      <p>Hier kann auf bereits angelegte Kampagnen zugriffen werden.</p>
  `,
    //campaign: 'Campaign',
    campaign: 'Kampagne',
    //campaignPlural: 'Campaigns',
    campaignPlural: 'Kampagnen',
    copy: {
      //copyText: "A copy of this campaign was successfully created.",
      copyText: "Eine Kopie der Kampagne wurde angelegt",
      //copyTitle: "Campaign Copied",
      copyTitle: "Kampagne kopiert",
      //copyPartialText: "This campaign has been copied, but not all of the advertising material could be copied along with it.",
      copyPartialText: "Die Kampagne wurde kopiert, jedoch konnten nicht alle Werbemittel kopiert werden.",
      //copyPartialTitle: "Campaign Copied but not All Advertising Material"
      copyPartialTitle: "Kampagne kopiert, jedoch nicht alle Werbemittel"
    },
    filter: {
      message: '{{option}}:'
    },
    //copyAfterwards: 'Go to new campaign after copy',
    copyAfterwards: 'Gehe zur neuen Kampagne nach dem Kopieren',
    //defaultName: "{{productName}} Campaign",
    defaultName: "{{productName}} Kampagne",
    //detailsTitle: 'Specify Your Campaign Details',
    detailsTitle: 'Eingabe der Kampagnendetails',
    //draftedHeading: 'Drafted Campaigns',
    draftedHeading: 'Kampagnenentwürfe',
    //draftedNoResults: 'No drafted campaigns found.',
    draftedNoResults: 'Keine Kampagnenentwürfe gefunden.',
    //endedHeading: 'Ended Campaigns',
    endedHeading: 'Beendete Kampagnen',
    //endedNoResults: 'No ended campaigns found.',
    endedNoResults: 'Keine beendeten Kampagnen gefunden.',
    //expectedImpressions: 'Corresponds to about {{imps}} impressions',
    expectedImpressions: 'Entspricht etwa {{imps}} Impressions',
    cpmBoost: 'Ein höherer TKP als {{minPrice}} vergrößert die Auslieferungschancen',
    maxedOutCampaigns: '<p>Momentan können keine neuen angelegt werden.</p><p>Maximal können {{max}} Kampagnenentwürfe erstellt werden.</p><p>Bitte vorhandene Entwürfe archivieren um neue Kampagnen erstellen zu können.</p>',
    coupon: {
      applyCoupon: 'Gutschein Anwenden',
      appliedCode: '{{code}} wurde hinzugefügt',
      cancel: '(stornieren)'
    },
    payment: {
      //title: 'Payment',
      title: 'Zahlung',
      //missing: 'No payment is associated with this campaign.',
      missing: 'Mit dieser Kampagne ist keine Zahlung verknüpft.',
      //cancelled: 'Your reservation was cancelled and your campaign was not submitted for review.',
      cancelled: 'Die Zahlung wurde gecancelt und die Kampagne nicht zur Überprüfung gesendet.',
      //infoComplete: 'A {{price}} payment was completed {{time}}.',
      infoComplete: 'Die Zahlung über {{price}} war erfolgreich {{time}}.',
      //infoAuthorised: 'A {{price}} payment was authorised {{time}}.',
      infoAuthorised: 'Die Zahlung über {{price}} wurde authorisiert {{time}}.',
      infoCompleteWithCoupon: 'Die Zahlung von {{price}} wurde durchgeführt, {{discount}} Rabatt wurden berücksichtigt.',
      //receiptLink: 'Your <a href="{{link}}" target="_blank">payment receipt is available</a>.',
      receiptLink: '<a href="{{link}}" target="_blank">Die Rechnung ist verfügbar</a>.',
      //registering: 'Registering transaction...',
      registering: 'Die Zahlung wird durchgeführt...',
      //reserveTitle: 'Reserve Payment and Submit Your Campaign for Review',
      reserveTitle: 'Zahlung durchführen und Kampagne zur Überprüfung schicken',
      //reserveButton: 'Reserve Payment and Submit Your Campaign for Review',
      reserveButton: 'Zahlung durchführen und Kampagne zur Überprüfung schicken',
      reserveParagraph0Coupon: 'Der Gutschein über <strong>{{amount}}</strong> wird bei dem Budget von <strong>{{budget}}</strong> berücksichtigt.',
      //reserveParagraph1: 'We will place a <strong>hold on your account</strong> for the budgeted sum of <strong>{{amount}}</strong>, which will enable your campaign to be reviewed and run.',
      reserveParagraph1: 'Wir <strong>belasten das Konto </strong>mit der gebuchten Summe von <strong>{{amount}}</strong>, dadurch wird die Kampagne zur Überprüfung gesendet und nach Bestätigung ausliefern.',
      //reserveParagraph2: 'Once your campaign has ended, we will take either {{amount}} from your account or the pro-rata amount based on the actual number of impressions delivered versus the expected <strong>{{imps}} impressions</strong>.',
      reserveParagraph2: 'Falls nicht alle <strong>{{imps}} Impressions</strong> ausgeliefert werden, wird der Restbetrag zurückgebucht.',
      refunded: 'Es wurde eine {{price}} Rückerstattung {{time}} vorgenommen.',
      //failure: 'We encountered a problem trying to register your transaction. Try again, and if a problem persists, please contact us.',
      failure: 'Währen der Buchung wurde ein Problem festgestellt. Bitte erneut versuchen.',
      //saveFirst: 'You will need to save your changes before proceeding with reserving payment',
      saveFirst: 'Vor der Buchung bitte die Eingaben speichern',
      //waitingForRedirect: 'We are passing on the details of your transaction to {{paymentProvider}} and will redirect you there shortly.',
      PIN_PAYMENTS: {
        waitingForRedirect: 'Die Transaktionsdetails werden an {{paymentProvider}} weitergegeben und die Weiterleitung folgt.',
      },
      PAYPAL: {
        reserveButton: 'Zahlen Sie über PayPal und senden Sie Ihre Kampagne zur Überprüfung',
        waitingForRedirect: 'Die Transaktionsdetails werden an PayPal weitergegeben und die Weiterleitung folgt.',
      },
      prepay: {
        detailsInfo: `
          <div class="small">
            <p>Die Kampagne kann ausliefern, nachdem die Zahlung durchgeführt und das Werbemittel freigegeben wurde.</p>
            <p>Falls nicht alle Impressions ausgeliefert werden, wird die Restsumme des Budgets zurückerstattet. Der Minimalbetrag von {{minimumFee}} fällt jedoch an.</p>
            <p>Nach Beendigung der Kampagne werden ein Performancereport und eine Rechnung erstellt.</p>
            <p>Die Kampagne kann auch während der Laufzeit jederzeit wieder gestoppt werden, jedoch fällt die Minimalgebühr von {{minimumFee}} an.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Die Kampagne kann ausliefern, nachdem die Zahlung durchgeführt und das Werbemittel freigegeben wurde.</p>
            <p>Falls nicht alle Impressions ausgeliefert werden, wird die Restsumme des Budgets zurückerstattet.</p>
            <p>Nach Beendigung der Kampagne werden ein Performancereport und eine Rechnung erstellt.</p>
            <p>Die Kampagne kann auch während der Laufzeit jederzeit wieder gestoppt werden.</p>
          </div>
        `
      },
      manual: {
        reserveTitle: 'Kampagne zum Überprüfen senden',
        reserveButton: 'Zum Überprüfen senden',
        reserveParagraph1: 'Die Kampagne wird vor Aktivierung von uns geprüft. Die Prüfung findet nur an Werktagen statt, nicht an Wochenenden oder Feiertagen.',
        reserveParagraph2: 'Nach Beendigung der Kampagne, werden wir eine Rechnung über <strong>{{amount}}</strong> oder anteilig, basierend auf den tatsächlich ausgelieferten Impressions ausstellen.</strong>.',
        detailsInfo: `
          <div class="small">
            <p>Die Kampagne kann ausliefern sobald die Überprüfung des Werbemittels abgeschlossen ist.</p>
            <p>Nach Kampagnenende wird eine Rechnung erstellt. Der Betrag berücksichtigt die Anzahl der gelieferten Impressions und wird das maximale Budget nicht übersteigen. Der Minimalbetrag von {{minimumFee}} fällt jedoch an.</p>
            <p>Nach Kampagnenende wird ein Performancereport erstellt.</p>
            <p>Die Auslieferung kann jederzeit gestoppt werden, jedoch fällt die Minimalgebühr von {{minimumFee}} an.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Die Kampagne kann ausliefern sobald die Überprüfung des Werbemittels abgeschlossen ist.</p>
            <p>Nach Kampagnenende wird eine Rechnung erstellt. Der Betrag berücksichtigt die Anzahl der gelieferten Impressions und wird das maximale Budget nicht übersteigen.</p>
            <p>Nach Kampagnenende wird ein Performancereport erstellt.</p>
            <p>Die Auslieferung kann jederzeit gestoppt werden.</p>
          </div>
        `
      },
      status: {
        //CREATED: 'Created',
        CREATED: 'Erstellt',
        //NOT_AUTHORISED: 'Not Authorised',
        NOT_AUTHORISED: 'Nicht Authorisiert',
        //AUTHORISED: 'Authorised',
        AUTHORISED: 'Authorisiert',
        //COMPLETED: 'Completed',
        COMPLETED: 'Durchgeführt',
        PAUSED: 'Pause',
        //CANCELLED: 'Cancelled'
        CANCELLED: 'Gecancelt'
      }
    },
    postPayment: {
      //reserveParagraph1: 'Payment for your campaign has been authorised.',
      reserveParagraph1: 'Die Zahlung für die Kampagne wurde authorisiert.',
      //reserveParagraph2: 'If you\'re happy with your campaign, you can submit it for review below.',
      reserveParagraph2: 'Nach Eingabe aller Details bitte die Kampagne zur Überprüfung senden.',
      //saveFirst: 'You will need to save your changes before proceeding with submitting your campaign for review.',
      saveFirst: 'Bitte die Änderungen speichern, bevor die Kampagne zur Überprüfung gesendet wird.',
      failed: 'Beim Übertragen zum Überprüfen ist ein Problem aufgetreten. Bitte erneut versuchen.',
      //submitButton: 'Submit Your Campaign for Review'
      submitButton: 'Kampagne zur Überprüfung senden'
    },
    //rejectedHeading: 'Rejected Campaigns',
    rejectedHeading: 'Abgelehnte Kampagne',
    report: {
      //title: 'Report',
      title: 'Report',
      //ungenerated: 'A report will be available for you to download shortly.',
      ungenerated: 'Der Report wird in Kürze zur Verfügung stehen.',
      //generated: 'A <a href="{{link}}" target="_blank">report is available for you to download</a>.'
      generated: 'Der <a href="{{link}}" target="_blank">Report ist zum Download verfügbar</a>.'
    },
    //runningHeading: 'In-Review and Running Campaigns',
    runningHeading: 'Kampagnen in Überprüfung und aktive Kampagnen',
    //runningNoResults: 'No running campaigns found.',
    runningNoResults: 'Keine aktiven Kampagnen gefunden',
    running: {
      //budgetHeading: 'Budget Position',
      budgetHeading: 'Budget Position',
      //budgetPosition: '<strong>{{budgetSpent}}</strong> of your <strong>{{budget}} budget</strong> has been spent.',
      budgetPosition: '<strong>{{budgetSpent}}</strong> des <strong>{{budget}} Budgets </strong> wurde bereits verwendet.',
      //cancelledPayment1: 'Your payment reservation has been cancelled.',
      cancelledPaymentParagraph1: 'Die Reservierung der Zahlung wurde gecancelt',
      //cancelledPayment2: 'If you still wish to proceed with your campaign, you can generate a new transaction below.',
      cancelledPaymentParagraph2: 'Bitte die Transaktion erneut starten, falls die Kampagne weiterhin verwendet werden soll.',
      //datesHeading: 'Running Dates',
      datesHeading: 'Laufzeit',
      //dates: '<strong>{{startDate}}</strong> to <strong>{{endDate}}</strong>',
      dates: '<strong>{{startDate}}</strong> bis <strong>{{endDate}}</strong>',
      //datesExplanation: 'Your campaign has run for <strong>{{days}} days</strong>, which is <strong>{{percentage}}</strong> of its maximum scheduled life.',
      datesExplanation: 'Die Kampagne lief <strong>{{days}} Tage</strong>, das entspricht <strong>{{percentage}}</strong> der Maximallaufzeit.',
      //datesLeftExplanation: 'Your campaign will stop running in <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      datesLeftExplanation: 'Die Kampagne wird in <strong>{{days}} Tagen stoppen </strong> falls das Budget vorher nicht erreicht wird.',
      //datesSubmittedExplanation: 'Your campaign will run for <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      datesSubmittedExplanation: 'Die Kampagne wird <strong>{{days}} Tage liefern</strong> falls das Budget vorher nicht erreicht wird.',
      //impressions: 'Your campaign has delivered <strong>{{imps}} impressions</strong>, which is <strong>{{percentage}} of your budgeted impressions</strong>.',
      impressions: 'Die Kampagne lieferte <strong>{{imps}} Impressions</strong>, das entspricht <strong>{{percentage}} der gebuchten Impressions</strong>.',
      //impressionsRemaining: 'You have about <strong>{{imps}} impressions</strong> left to deliver.',
      impressionsRemaining: 'Es sind etwa <strong>{{imps}} Impressions</strong> übrig, für die Auslieferung.',
      //statusPrefix: 'Your campaign is ',
      statusPrefix: 'Die Kampagne ist ',
      //successfulPaymentParagraph1: 'Payment has been reserved and your campaign has been submitted for review.',
      successfulPaymentParagraph1: 'Die Zahlung wurde getätigt und Ihre Kampagne wurde zur Überprüfung eingereicht.',
      //successfulPaymentParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      successfulPaymentParagraph2: 'Sobald die Kampagne freigegeben wurde wird sie anfangen auszuliefern!',
      prepay: {
        //stoppedParagraph1: 'Your campaign has been stopped.',
        stoppedParagraph1: 'Die Kampagne wurde gestoppt.',
        //stoppedParagraph2: 'Any unused campaign budget will be refunded and a report generated for your campaign.',
        stoppedParagraph2: 'Nicht verwendetes Kampagnenbudget wird zurückerstattet und ein Bericht für Ihre Kampagne erstellt.'
      },
      manual: {
        successfulPaymentParagraph1: 'Ihre Kampagne wurde zur Überprüfung eingereicht.',
        //stoppedParagraph1: 'Your campaign has been stopped.',
        stoppedParagraph1: 'Die Kampagne wurde gestoppt.',
        //stoppedParagraph2: 'An invoice will be issued to you and a report generated for your campaign.',
        stoppedParagraph2: 'Die Kampagne wird anhand der bereits ausgelieferten Impressionen abgerechnet.',
      },
      //submittedParagraph1: 'Your campaign has been submitted for review.',
      submittedParagraph1: 'Die Kampagne wurde zur Überprüfung gesendet.',
      //submittedParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      submittedParagraph2: 'Sobald die Kampagne freigegeben wurde wird sie anfangen auszuliefern!',
      //unexpectedPaymentParagraph1: 'Your payment reservation did not proceed as planned.',
      unexpectedPaymentParagraph1: 'Ihre Zahlung wurde nicht wie geplant durchgeführt',
      //unexpectedPaymentParagraph2: 'At this stage, it\'s probably best to copy this campaign and start the process again of creating a payment reservation.'
      unexpectedPaymentParagraph2: 'Wir empfehlen die Kampagne zu kopieren und den Zahlungsprozess erneut zu starten.'
    },
    save: {
      //button: 'Save Your Progress',
      button: 'Kampagne speichern',
      //messageTitle: 'Campaign Saved',
      messageTitle: 'Kampagne gespeichert',
      //messageText: 'Your campaign was saved successfully.'
      messageText: 'Kampagne wurde gespeichert.'
    },
    smoothing: {
      //title: 'Ad Delivery Style',
      title: 'Auslieferungsart',
      //true: 'Smooth',
      true: 'gleichverteilt',
      //trueExplanation: 'Your advertisement will deliver smoothly over the life of your campaign.',
      trueExplanation: 'Die Kampagne wird über die Laufzeit gleichverteilt ausliefern.',
      //trueField: '<strong>Smooth</strong> - deliver your budgeted ad impressions smoothly over the life of your campaign',
      trueField: '<strong>gleichverteilt</strong> - liefere die gebuchten Impressions gleichverteilt über den Kampagnenzeitraum aus.',
      //false: 'Fast',
      false: 'schnellstmöglich',
      //falseExplanation: 'Your advertisement will be delivered as quickly as possible.',
      falseExplanation: 'Die Kampagne liefert schnellstmöglich aus.',
      //falseField: '<strong>Fast</strong> - deliver your budgeted ad impressions as quickly as possible'
      falseField: '<strong>schnellstmöglich</strong> - liefere die gebuchten Impressions so schnell wie möglich aus.'
    },
    status: {
      //STOPPED: 'Stopped',
      STOPPED: 'Gestoppt',
      //PROPOSED: 'Drafted',
      PROPOSED: 'Entwurf',
      //SUBMITTED: 'In Review',
      SUBMITTED: 'In Überprüfung',
      //NOT_READY: 'Needs Further Review',
      NOT_READY: 'Weitere Überprüfung notwendig',
      //READY: 'Ready to Deliver',
      READY: 'Fertig für die Auslieferung',
      PAUSED: 'Pausiert',
      //REJECTED: 'Rejected',
      REJECTED: 'Zurückgewiesen',
      //SUBMISSION_READY: 'Ready for Submission',
      SUBMISSION_READY: 'Fertig zum Einreichen',
      //RUNNING: 'Delivering',
      RUNNING: 'Aktive Auslieferung',
      //ENDED: 'Ended'
      ENDED: 'Beendet'
    },
    //statsHeading: 'Delivery Statistics',
    statsHeading: 'Auslieferungsstatistik',
    stop: {
      //button: 'Stop Campaign Delivery',
      button: 'Kampagnenauslieferung stoppen',
      //cancelButton: 'Cancel Campaign Stop',
      cancelButton: 'Cancel Kampagnenstop',
      //confirmButton: 'Confirm Campaign Stop',
      confirmButton: 'Kampagnenstop bestätigen',
      //failParagraph1: 'We encountered a problem and were not able to stop your campaign.',
      failParagraph1: 'Die Kampagne konnte aufgrund eines Problems nicht gestoppt werden',
      //failParagraph2: 'Please try stopping your campaign again, and if the problem persists, let us know.',
      failParagraph2: 'Bitte versuche erneut, die Kampagne zu stoppen. Kontaktiere uns bitte, falls das Problem weiterhin besteht',
      prepay: {
        //info1: 'Stopping a campaign cannot be reversed, although any campaign can be copied and the new campaign delivered.',
        info1: 'Der Kampagnenstop kann nicht rückgängig gemacht werden jedoch kann die Kampagne kopiert und dann wieder aktiviert werden',
        //info2: 'Upon stopping your campaign, any unused budget will be refunded. Note: a minimum fee of {{money}} does apply.',
        info2: 'Nach Beendigung Ihrer Kampagne wird nicht verwendetes Budget zurückerstattet. Hinweis: Es fällt eine Mindestgebühr von {{money}} an.',
        info2NoMinFee: 'Die Kampagne wird anhand der bereits ausgelieferten Impressionen abgerechnet.'
      },
      manual: {
        //info1: 'Stopping a campaign cannot be reversed, although any campaign can be copied and the new campaign delivered.',
        info1: 'Der Kampagnenstop kann nicht rückgängig gemacht werden jedoch kann die Kampagne kopiert und dann wieder aktiviert werden',
        //info2: 'Upon stopping your campaign, either the minimum fee of {{money}} or your current advertising spend will be taken from your account, whichever figure is higher.',
        info2: 'Bis zum Kampagnenstop wird entweder der Minimalbetrag von {{money}} oder das bereits verbrauchte Budget eingezogen - je nachdem welcher Betrag höher ist.',
        info2NoMinFee: 'Die Kampagne wird anhand der bereits ausgelieferten Impressionen abgerechnet.'
      },
      //intro: 'If at any time you wish to stop your campaign, you may do so below.'
      intro: 'Die Kampagne kann jederzeit über den unteren Button gestoppt werden.'
    },
    validationProblemsPresent: `
    <p>Bei der Kampagne gibt es Validierungsprobleme, da noch nicht alle notwendigen Informationen (z.B. Werbemittelbild, Link URL, etc.) zur Verfügung gestellt wurden.</p>
    <p>Sobald die Validierungsprobleme behoben sind, können wir mit dem Prozess fortfahren, Ihre Kampagne live zu schalten.</p>
  `
  },
  emailPreferences: {
    //CREATIVE_REJECTED: '<strong>Adjustments required:</strong> a reviewer requests a change to my campaign',
    CREATIVE_REJECTED: '<strong> Anpassungen erforderlich: </strong> der Reviewer wünscht eine Änderung an der Kampagne',
    //REPORT_COMPLETE: '<strong>Campaign report is ready:</strong> my campaign has finished and its performance report has been generated',
    REPORT_COMPLETE: '<strong> Der Kampagnenreport ist fertig: </strong> die Kampagne ist ausgelaufen und der Performancereport wurde generiert',
    //LINE_ITEM_RUNNING: '<strong>Campaign is delivering:</strong> my campaign has passed review and is now delivering impressions'
    LINE_ITEM_RUNNING: '<strong> Die Kampagne liefert: </strong> die Kampagne wurde überprüft und liefert jetzt aus'
  },
  media: {
    gif: 'GIF',
    png: 'PNG',
    svg: 'SVG',
    jpg: 'JPG'
  },
  chart: {
    countType: {
      singular: 'Trend',
      cumulative: 'Kumulativ'
    },
    yAxisOption: {
      shared: 'Geteilte y-Achse',
      multiple: 'Verschiedene y-Achsen'
    },
    dateOption: {
      //last1Hour: 'Last hour',
      last1Hour: 'letzte Stunde',
      //last12Hours: 'Last 12 hours',
      last12Hours: 'letzte 12 Stunden',
      //last7Days: 'Last 7 days',
      last7Days: 'letzte 7 Tage',
      //last30Days: 'Last 30 days',
      last30Days: 'letzte 30 Tage',
      //today: 'Today',
      today: 'Heute',
      //yesterday: 'Yesterday',
      yesterday: 'Gestern',
      //campaignDays: 'Running dates: {{startDate}}-{{endDate}}'
      campaignDays: 'Laufzeit: {{startDate}}-{{endDate}}'
    }
  },
  layout: {
    fieldSummary: {
      image: 'image',
      imagePlural: 'images',
      url: 'URL',
      urlPlural: 'URLs',
      code: 'code field',
      codePlural: 'code fields',
      text: 'text field',
      textPlural: 'text fields',
      option: 'option',
      optionPlural: 'options'
    }
  },
  label: {
    //copy: 'Copy',
    copy: 'Kopie',
    //copyLineItem: 'Copy Campaign',
    copyLineItem: 'Kampagne kopieren',
    //paginationAllShown: 'Showing all <strong>{{total}}</strong> results',
    paginationAllShown: 'Zeige alle <strong>{{total}}</strong> Ergebnisse',
    //paginationWithPages: 'Showing <strong>{{start}}-{{end}}</strong> of <strong>{{total}}</strong> results',
    paginationWithPages: 'Zeige <strong>{{start}}-{{end}}</strong> von <strong>{{total}}</strong> Ergebnissen',
    //paginationLoading: 'Showing <span class="fa fa-spin fa-cog"></span> results'
    paginationLoading: 'Zeige <span class="fa fa-spin fa-cog"></span> Ergebnisse'
  },
  report: {
    //downloadXlsComma: 'Download as XLS (decimal comma)',
    downloadXlsComma: 'Download als XLS (decimal comma)',
    //downloadXlsPoint: 'Download as XLS (decimal point)',
    downloadXlsPoint: 'Download als XLS (decimal point)',
    fieldCountPlural: '{{count}} Felder',
    fieldCount: '{{count}} Feld',
    //report: 'Report',
    report: 'Report',
    //reportPlural: 'Reports',
    reportPlural: 'Reports',
    noReport: `
    <h3>Keine Reports gefunden</h3>
    <p>Sobald die Kampagne beendet ist wird ein Report generiert.</p>
    <p>Hier ist die Liste aller generierten Reports der bereits ausgelieferten Kampagnen.</p>
  `,
    status: {
      //PENDING: 'Pending',
      PENDING: 'Ausstehend',
      //PROCESSING: 'Processing',
      PROCESSING: 'Processing',
      //COMPLETED: 'Completed',
      COMPLETED: 'Fertig',
      //REJECTED: 'Rejected',
      REJECTED: 'Zurückgewiesen',
      //FAILED: 'Failed',
      FAILED: 'Failed',
      //CANCELLED: 'Cancelled',
      CANCELLED: 'Gecancelt',
      //QUEUED: 'Queued',
      QUEUED: 'Queued',
      //TIMED_OUT: 'Timed Out'
      TIMED_OUT: 'Timed Out'
    },
    field: {
      rendered: 'Impressions',
      visibles: 'Visible Impressions',
      visibility: 'Visibility',
      viewables: 'Viewable Impressions',
      viewability: 'Viewability',
      uniqueUsers: 'Unique Users',
      clicks: 'Clicks',
      ctr: 'Click-Through Rate',
      cost: 'Cost'
    }
  },
  country: {
    AT: 'Österreich',
    DE: 'Deutschland',
    CH: 'Schweiz'
  },
  errors: {
    standard: {
      unregistered: {
        title: 'Ihr Konto ist hier nicht registriert',
        p1: 'Sie sind nicht in diesem Werbeportal registriert.',
        p2: '',
        p2LinkText: 'Registrieren Sie sich auf diesem Werbeportal',
        p2Suffix: '&nbsp;bevor Sie fortfahren.',
        p3: 'Alternative,&nbsp;',
        p3LinkText: 'Melden Sie sich mit einem anderen Konto an',
        p3Suffix: '.'
      },
      //dataNotFoundTitle: 'Missing Data',
      dataNotFoundTitle: 'Datum fehlt',
      //dataNotFoundParagraph1: 'We could not find any data at the following location: <strong>{{link}}</strong>.',
      dataNotFoundParagraph1: 'An dieser Stelle konnten keine Daten gefunden werden: <strong>{{link}}</strong>.',
      //dataNotFoundParagraph2: 'This could have happened because the underlying data is no longer available to you.',
      dataNotFoundParagraph2: 'Das kann passieren wenn die zugehörigen Daten für den Nutzer nicht mehr verfügbar sind.',
      //dataNotFoundParagraph3: 'Access any of the links in the menu to get back on track.',
      dataNotFoundParagraph3: 'Bitte nutze einen der links im Menü.',
      //pageNotFoundTitle: 'Page Not Found',
      pageNotFoundTitle: 'Die Seite wurde nicht gefunden',
      //pageNotFoundParagraph1: 'We could not find the page you requested.',
      pageNotFoundParagraph1: 'Die gewählte Seite konnte nicht gefunden werden.',
      //pageNotFoundParagraph2: 'Access any of the links in the menu to get back on track.',
      pageNotFoundParagraph2: 'Bitte nutze einen der links im Menü.',
      //objectNotFoundTitle: 'Object Not Found',
      objectNotFoundTitle: 'Objekt wurde nicht gefunden',
      //objectNotFoundParagraph1: 'We could not find any data for the following id: <strong>{{id}}</strong>.',
      objectNotFoundParagraph1: 'Für die folgende ID konnten keine Daten gefunden werden: <strong>{{id}}</strong>.',
      //objectNotFoundParagraph2: 'This could have happened because the object is no longer available to you.',
      objectNotFoundParagraph2: 'Das kann passieren wenn das zugehörige Objekt für den Nutzer nicht mehr verfügbar ist.',
      //objectNotFoundParagraph3: 'Access any of the links in the menu to get back on track.'
      objectNotFoundParagraph3: 'Bitte nutze einen der links im Menü.'
    },
    api: {
      //stats: 'We were unable to retrieve statistics from the API. If the problem persists, please contact support.',
      stats: 'Wir konnten über die API keine Daten beziehen. Bitte kontaktiere den Support falls das Problem weiterhin besteht.',
      //title: 'Sorry, there appears to be a problem',
      title: 'Es scheint ein Problem vorzuliegen, wir bitten um Entschuldigung',
      noConnection: {
        //title: "Connection Loss",
        title: "Verbindung verloren",
        //noConnection: "<p>We've lost connection to our back-end server. If the problem persists, please contact support.</p>"
        noConnection: "<p>Wir haben die Verbindung zum Backend verloren. Bitte kontaktiere den Support falls das Problem weiterhin besteht.</p>"
      },
      //generic: "<p>We're experiencing problems interacting with our back-end system. If the problem persists, please contact support.</p>",
      generic: "<p>Wir haben die Verbindung zum Backend verloren. Bitte kontaktiere den Support falls das Problem weiterhin besteht.</p>",
      unauthorised: {
        //title: "Unauthorised Operation",
        title: "Nicht-authorisierte Operation",
        //unauthorised: "<p>You do not have the permissions to perform the operation you requested.</p>"
        unauthorised: "<p>Für die gewählte Operation fehlt die notwendige Berechtigung.</p>"
      }
    }
  },
  field: {
    advertiserName: 'Name des Werbetreibenden',
    address1: 'Adresse',
    city: 'Stadt',
    postCode: 'Postleitzahl',
    state: 'Zustand',
    country: 'Land',
    invoiceEmail: 'E-Mail für Rechnungen',
    phoneNumber: 'Telefonnummer',
    required: 'Erforderlich',
    malformedEmail: 'Muss eine gültige E-Mail sein',
    cpm: 'Kosten pro 1000 Impressionen (CPM)',
    //selfServiceBudget: 'Budget',
    selfServiceBudget: 'Budget',
    email: 'Email',
    //emailPreferences: 'Email Preferences',
    emailPreferences: 'Email Einstellungen',
    //password: 'Password',
    password: 'Passwort',
    //confirmPassword: 'Confirm Password',
    confirmPassword: 'Passwort bestätigen',
    //locale: 'Locale',
    locale: 'Gebietsschema',
    //smoothing: 'Ad Delivery Style',
    smoothing: 'Auslieferungsart',
    //startDate: 'Start',
    startDate: 'Start',
    status: 'Status',
    userState: 'Status',
    //displayName: 'Display Name',
    displayName: 'Angezeigter Name',
    //displayNameHint: 'The display name is used to refer to you throughout the application',
    displayNameHint: 'Der angezeigte Name wird innerhalb der Anwendung verwendet',
    //endDate: 'End',
    endDate: 'Ende',
    //executionStartTime: 'Run Date',
    executionStartTime: 'Laufzeit',
    //name: 'Name'
    name: 'Name'
  },
  location: {
    "New Zealand": "Neuseeland",
    Austria: "Österreich",
    Switzerland: "Schweiz",
    Germany: "Deutschland"
  },
  settings: {
    //settingsPlural: 'Settings',
    settingsPlural: 'Einstellungen',
    //about: 'About',
    about: 'Über',
    //signOut: 'Sign Out'
    signOut: 'Ausloggen'
  },
  signIn: {
    //accountLocked: 'Your account has been locked due to too many failed login attempts. You have been sent an email with which you can reset your password, unlock your account and sign in.',
    accountLocked: 'Der Zugang wurde aufgrund zu vieler flascher Passworteingaben gesperrt. Zum Zurücksetzen des Passwortes wurde eine Mail an die verwendete E-Mail Adresse geschickt.',
    //alreadyUser: 'Already a user?',
    alreadyUser: 'Bereits ein User?',
    //confirmPasswordMismatch: "The passwords you entered do not match. Ensure you've entered the same password both times.",
    confirmPasswordMismatch: "Die Passwörter stimmen nicht miteinander überein.",
    //differentAccount: 'Sign in with a different account',
    differentAccount: 'Mit einem anderen User einloggen',
    coupon: {
      code: 'Gutscheincode',
      checkCode: 'Gutscheincode prüfen',
      validCode: '{{code}} ist ein gültiger Gutscheincode',
      invalidCode: '{{code}} ist kein gültiger Gutscheincode'
    },
    //emailFirst: "Your account's email address must be entered before its password can be reset.",
    emailFirst: "Die verwendete E-Mail Adresse muss eingegeben werden, bevor das Passwort zurückgesetzt werden kann.",
    //emailRequired: "We need your account's email address before we can sign you in.",
    emailRequired: "Die genutzte E-Mail Adresse wird vor dem Einloggen benötigt.",
    //expiredLinkExplanation: 'It looks like you clicked on an invalid or expired password reset link. Please try resetting your password again.',
    expiredLinkExplanation: 'Der Link zum Zurücksetzen des Passwortes ist nicht mehr gültig. Bitte das Zurücksetzen erneut starten',
    //forgotPassword: 'Forgot your password?',
    forgotPassword: 'Passwort vergessen?',
    returnHome: 'Gehen Sie zur Anmeldeseite',
    //humanConfirmationFailure: "There's a problem obtaining registration confirmation for your account. Try again and let us know if the problem persists.",
    humanConfirmationFailure: "Es gibt ein Problem bei der Registrierung des Nutzers. Bitte versuche es erneut und kontaktiere unseren Support falls das Problem bestehen bleibt",
    //nameRequired: "We need a name with which to register you as a new user.",
    nameRequired: "Für die Registrierung benötigen wir einen Nutzernamen.",
    //passwordResetTooMuchHeading: 'Too Many Password Resets',
    passwordResetTooMuchHeading: 'Zuviele Passwort-Resets',
    //passwordResetTooMuchExplanation: 'You have tried to reset your password too often in a short space of time. You cannot reset your password for the next little while.',
    passwordResetTooMuchExplanation: 'Es wurde zu häufig innerhalb kurzer Zeit versucht das Passwort zurückzusetzen. ',
    //passwordResetEmailSentHeading: 'Password Reset Email Sent',
    passwordResetEmailSentHeading: 'Eine Mail mit dem Reset-Link wurde versendet.',
    //passwordResetEmailSentExplanation: 'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.',
    passwordResetEmailSentExplanation: 'Bitte schaue in der Mailbox nach der Mail mit dem Reset-Link, bitte prüfe auch den SPAM Ordner. ',
    //passwordHint: 'Your password must be at least 8 characters in length',
    passwordHint: 'Das Passwort muss mindestens 8 Zeichen lang sein',
    //passwordLinkExpired: 'Your reset password link has expired. Enter your email address to generate another link.',
    passwordLinkExpired: 'Der Link zum Zurücksetzen des Passwortes ist abgelaufen. Bitte starte den Vorgang erneut',
    //passwordRequired: "Supply a password to sign in.",
    passwordRequired: "Bitte gebe zum Einloggen ein Passwort ein.",
    //passwordSimple: 'The supplied password is too easily guessed and should be something less common.',
    passwordSimple: 'Das Passwort ist zu gewöhnlich, bitte eine andere Zeichenkombination wählen.',
    regError: 'Validierungsprobleme gefunden. Details oben anzeigen.',
    //registerAsUserHeading: 'Register as a User',
    registerAsUserHeading: 'Nutzer anlegen',
    //registerAsUserButton: 'Register',
    registerAsUserButton: 'Anlegen',
    //registerEmailRequired: 'We need an email address to register you as a new user.',
    registerEmailRequired: 'Für die Registrierung benötigen wir eine E-Mail Adresse.',
    //registerNewUser: 'Register as a new user',
    registerNewUser: 'Einen neuen Nutzer anlegen',
    //registerSuccessHighlight: 'You have successfully registered as a new user!',
    registerSuccessHighlight: 'Der Nutzer wurde erfolgreich angelegt!',
    //registerSuccessParagraph1: 'You have been sent an email with more information.',
    registerSuccessParagraph1: 'Eine E-Mail mit weiteren Informationen wurde versendet.',
    //registerSuccessParagraph2: 'Click on the link in your email to create your password for your account and sign in.',
    registerSuccessParagraph2: 'Bitte nutze den Link in der E-Mail um das Passwort zu bestimmen, bitte prüfe auch den SPAM Ordner.',
    registerTerms: `Durch Auswahl von <strong>Anlegen</strong> erklären Sie sich damit einverstanden, dass Sie unsere <a target="_ blank" href="https://adnuntius.com/resources/tc-self-service-advertising/">Allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiert haben.`,
    //resetPasswordHeading: 'Reset Your Password',
    resetPasswordHeading: 'Passwort zurücksetzen',
    //resetPasswordButton: 'Send Password Reset Email',
    resetPasswordButton: 'E-Mail zum Zurücksetzen des Passortes versenden',
    //resetPasswordInfo: 'Enter your email address and we will send you a link to reset your password.',
    resetPasswordInfo: 'Gebe die E-Mail ein um einen Link zum Zurücksetzen des Passwortes zu bekommen.',
    //signInButton: 'Sign in',
    signInButton: 'Einloggen',
    //signInHeading: 'Sign in',
    signInHeading: 'Einloggen',
    //updatePasswordHeading: 'Update Your Password',
    updatePasswordHeading: 'Passwort erneuern',
    //updatePasswordButton: 'Update Password'
    updatePasswordButton: 'Passwort erneuern'
  },
  adBlockerParagraph1: "Ein AdBlocker wurde erkannt. Diese Anwendung funktioniert nicht bei aktiven AdBlockern.",
  adBlockerParagraph2: "Bitte deaktiviere den AdBlocker um die Anwendung nutzen zu können.",
  adBlockerTurnOff: "Schalten Sie diese Warnung aus",
  validation: {
    //required: 'Required',
    required: 'benötigt',
    archived: 'Diese Kampagne ist archiviert. Sie können weiterhin mit der Kampagne interagieren, aber keine Werbung schalten.',
    //pattern: "Text must be {{minLength}}-{{maxLength}} characters long.",
    pattern: "Der Text muss {{minLength}} - {{maxLength}} Zeichen lang sein.",
    //maxSize: "The uploaded file is {{actual}}kb in size, which is greater than the {{maximum}}kb maximum",
    maxSize: "Die hochgeladene Datei ist {{actual}} kb groß, das ist größer als das erlaubte Maximum von {{maximum}} kb",
    //fileType: "The uploaded file must be one of the following types: {{allowedTypes}}",
    fileType: "Folgene Dateitypen sind erlaubt: {{allowedTypes}}",
    //minPrice: "The minimum value is {{minValue}}",
    minPrice: "Der Minimalbetrag ist {{minValue}}",
    maxPrice: "Der maximale Wert beträgt {{maxValue}}",
    number: "Ungültige Nummer",
    parse: "Der Inhalt entspricht nicht dem erwarteten Format",
    //url: "The URL is not in a valid form.",
    url: "Die URL ist ungültig.",
    //endDateStartDate: 'The end date must be after the start date.',
    endDateStartDate: 'Das Enddatum muss nach dem Startdatum liegen.',
    fileUploadFail: "Die folgenden Dateien konnten nicht hochgeladen werden:",
    fileUploadTimeout: "Der Upload konnte nicht beendet werden. Bitte versuchen Sie es nach einem Refresh der Seite erneut.",
    anonymousFileSizeLarge: 'Die Datei muss kleiner als {{fileSize}}kb sein für Nutzer, die nicht eingeloggt sind.',
    anonymousFileSizeSuperLarge: 'Das Hochladen der Datei hat nicht funktioniert, wahrscheinlich war die Datei zu groß für die Analyse. Bitte loggen Sie sich ein um größere Dateien und eine bessere Analyse zu ermöglichen.',
    email: 'Benötigen Sie eine gültige E-Mail-Adresse',
    error: {
      //validation: '<strong>Invalid submission:</strong> Please check the details above and try again.',
      validation: '<strong> Ungültige Eingabe: </strong> Bitte prüfe die Details und versuche erneut.',
      //'must.be.greater.than.or.equal': '{{value}} must be greater than {{minValue}}.',
      'must.be.greater.than.or.equal': '{{value}} muss größer sein als {{minValue}}.',
      //'non.empty': 'Required'
      'non.empty': 'Notwendig',
      "auth.failed": "Login fehlgeschlagen, die eingegebenen Daten konnten keinem Nutzer zugeordnet werden.",
      "sign.in.blocked": "Der Nutzer ist nicht registriert, bitte über die folgende URL anmelden {{uiAddress}}.",
    },
    warning: {
      "creative.invalid.product.dimensions": "Bitte wähle zulässige Ad-Dimensionen",
      //"lineItem.start.date.before.end.date": "The campaign's start date must be before its end date",
      "lineItem.start.date.before.end.date": "Das Kampagnen Startdatum muss vor dem Enddatum liegen",
      //"lineItem.end.date.before.today": "The campaign's end date must be after the current date and time",
      "lineItem.end.date.before.today": "Das Kampagnenenddatum muss nach dem heutigen Tag liegen",
      //"lineItem.invalid.budget.enddate": "The campaign must end within 30 days"
      "lineItem.invalid.budget.enddate": "Die Kampagne muss innerhalb von 100 Tagen enden",
      "payment.gateway.error": "Es besteht ein Verbindungsproblem."
    }
  }
};
