import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignedInShellComponent } from './signed-in-shell/signed-in-shell.component';
import { HeaderComponent } from './@common/header/header.component';
import { AnonShellComponent } from '@app/@shells/anon-shell/anon-shell.component';
import { AuthShellComponent } from '@app/@shells/auth-shell/auth-shell.component';
import { I18nModule } from '@app/@i18n/i18n.module';
import { LanguageSelectorComponent } from '@app/@shells/@common/language-selector/language-selector.component';
import { LocalhostEnvBannerComponent } from '@app/@shells/@common/localhost-env-banner/localhost-env-banner.component';
import { FooterBarComponent } from '@app/@shells/@common/footer-bar/footer-bar.component';
import { FooterOverlayComponent } from '@app/@shells/@common/footer-overlay/footer-overlay.component';
import {
  AdblockDetectedBannerComponent
} from '@app/@shells/@common/adblock-detected-banner/adblock-detected-banner.component';
import {SignInModalComponent} from '@app/@shells/signed-in-shell/sign-in-modal/sign-in-modal.component';
import {MasqueradeBannerComponent} from '@app/@shells/signed-in-shell/masquerader-banner/masquerade-banner.component';
import {SharedModule} from '@shared';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule, I18nModule, RouterModule, SharedModule],
  declarations: [
    HeaderComponent,
    SignedInShellComponent,
    AnonShellComponent,
    AuthShellComponent,
    LanguageSelectorComponent,
    LocalhostEnvBannerComponent,
    FooterBarComponent,
    FooterOverlayComponent,
    AdblockDetectedBannerComponent,
    SignInModalComponent,
    MasqueradeBannerComponent
  ],
})
export class ShellModule {}
