<div id="app" class='signInContainer auth-shell-container d-flex flex-column overflow-auto'
     [ngClass]="backgroundClass">
  <app-localhost-env-banner></app-localhost-env-banner>
  <app-adblock-detected-banner></app-adblock-detected-banner>
  <ng-container *ngIf='networkData; else noNetworkData'>
    <div *ngIf="i18nLangService.hasTranslationKey('frontPageTopContent');"
         [innerHTML]="'frontPageTopContent' | translate: { urlSection: networkData.urlSection, id: networkData.id } | safeHtml">
    </div>
  </ng-container>
  <div class="signInFlexContainer mb-auto mt-auto">
    <div class='container frontPageContent'>
      <div class='row align-items-center'>
        <div class='col-sm-6'>
          <ng-container *ngIf='networkData; else noNetworkData'>
            <div *ngIf="i18nLangService.hasTranslationKey('frontPage'); else noFrontPageTranslation"
                 [innerHTML]="'frontPage' | translate: { urlSection: networkData.urlSection, id: networkData.id } | safeHtml">
            </div>
            <ng-template #noFrontPageTranslation>
              <h1>{{ networkData.urlSection }} ({{ networkData.id }}) Portal for Advertising</h1>
              <p>Do your advertising here!</p>
            </ng-template>
          </ng-container>
          <ng-template #noNetworkData>
            <div class='signInFail'>
              <h1>Unrecognised Portal</h1>
              <p>Missing a portal here.</p>
            </div>
          </ng-template>
        </div>
        <div class='col-sm-6'>
          <router-outlet></router-outlet>
          <div class='d-flex justify-content-end'>
            <app-language-selector [signedInContext]='undefined' [inNav]='false'></app-language-selector>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-overlay></app-footer-overlay>
</div>
