import { Injectable } from '@angular/core';
import { UserResource } from '@app/@core/@rest/users/user-resource.service';
import { Observable, of, tap } from 'rxjs';
import { EphemeralLocalObjectStore } from '@shared/storage/local/ephemeral-local-object-store';
import { map } from 'rxjs/operators';
import { CacheRegistry } from '@shared/storage/cache-registry.service';
import { LocaleIdService } from '@app/@i18n/services/locale-id.service';
import { User } from '@app/@core/@models/users/user.model';
import {AuthUserService} from '@app/@core/auth/auth-user.service';
import { RollbarService } from '@app/@shared/logging/rollbar.service';

/**
 * Loads and caches User in local storage, flushed on sign-in / sign-out.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private localUserStore: EphemeralLocalObjectStore<User>;
  constructor(
    localStorageService: CacheRegistry,
    private userResource: UserResource,
    private i18nLocaleService: LocaleIdService,
    private authUserService: AuthUserService,
    private rollbarService: RollbarService
  ) {
    this.localUserStore = new EphemeralLocalObjectStore<User>(localStorageService, 'user');
  }

  getUser(): Observable<User> {
    const user = this.localUserStore.getObject();
    if (user) {
      this.rollbarService.setUserId(user.id)
      return of(user);
    } else {
      return this.getUserFromServer();
    }
  }

  setUser(user: User) {
    this.localUserStore.setObject(user);
    this.authUserService.flush();
  }

  private getUserFromServer(): Observable<User> {
    return this.authUserService.getAuthUser().pipe(
      map((authUser) => authUser.user),
      tap((user) => {
        this.rollbarService.setUserId(user.id);
        this.localUserStore.setObject(user);
      })
    );
  }
}
