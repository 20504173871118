import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Product} from '@app/@core/@models/design/product.model';
import {SiteTargetSite} from '@app/@core/@models/targeting/site-target.model';
import { TargetingForm } from '@app/@core/advertising/campaign/advert/targeting-form';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-site-targeting-options[product][targetingForm]',
  templateUrl: './site-targeting-options.component.html',
  styleUrls: ['./site-targeting-options.component.scss']
})
export class SiteTargetingOptionsComponent implements OnInit, OnChanges {
  @Input() product!: Product;
  @Input() targetingForm!: TargetingForm;
  options: { controlIndex: number, name: string }[] = [];
  uuid: string = Uuid.generate();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options = [];
    for (let i = 0; i < this.targetingForm.controls.siteTargets.length; i++) {
      const siteTarget = this.getSiteTargetSiteFromIndex(i);
      if (siteTarget && this.isSiteVisibleToUser(siteTarget)) {
        this.options.push({ controlIndex: i, name: siteTarget.name });
      }
    }
    this.options.sort((a,b) => a.name.localeCompare(b.name));
  }

  selectedSites(): string[] {
    const result: string[] = [];
    this.options.forEach(option => {
      if (this.targetingForm.value.siteTargets![option.controlIndex]) {
        result.push(option.name);
      }
    });
    return result;
  }

  private getSiteTargetSiteFromIndex(index: number): SiteTargetSite | undefined {
    const siteTarget = this.product.creativeTargeting.siteTarget;
    if (siteTarget && index < siteTarget.sites.length) {
      return siteTarget.sites[index];
    }
    return undefined;
  }

  private isSiteVisibleToUser(siteTarget: SiteTargetSite): boolean {
    return 'name' in siteTarget;
  }

}
