<ng-container [formGroup]='targetingForm'>
  <hr class='subDivider'>
  <h3>{{ 'campaign.location.title' | translate }}</h3>
  <div formArrayName='namedLocationTargets' class='d-flex flex-column align-items-center text-center'>
    <div class='mb-1'>
      <span [innerHTML]="'campaign.location.descriptionPrefix' | translate"></span><strong
      *ngIf='selectedNamedLocationsInnerHtml() as namedLocationInnerHtmlList'><span
      *ngIf='namedLocationInnerHtmlList.length === 0'>{{ 'campaign.location.any' | translate }}</span><span
      *ngIf='namedLocationInnerHtmlList.length > 0'><ng-container
      *ngFor='let namedLocationInnerHtml of namedLocationInnerHtmlList; let isLast=last'><span
      [innerHTML]='namedLocationInnerHtml'></span><span
            *ngIf='!isLast' translate='grammar.listSeparator'></span> </ng-container></span></strong>
    </div>
    <div>
      <ng-container *ngFor='let option of options; let i = index'>
        <span *ngIf="'namedLocationTarget_'+uuid+i as id" class='form-check form-check-inline'>
          <input
            class='form-check-input'
            type='checkbox'
            [formControlName]='option.controlIndex'
            [id]='id'
          />
          <label class='form-check-label' [for]='id' [innerHTML]='option.innerHtml'></label>
        </span>
      </ng-container>
    </div>
    <div *ngIf='granularOptions.length'>
      <div class='small'><a class='ngClick' (click)='collapse.toggle()' [attr.aria-expanded]='!granularLinkCollapsed'>
        <i class='fas fa-chevron-right small' [ngClass]='{"fa-rotate-90": !granularLinkCollapsed}'></i>
        <span translate='campaign.location.moreGranular'></span>
      </a>
      </div>
      <div #collapse='ngbCollapse' [(ngbCollapse)]='granularLinkCollapsed'>
        <ng-container *ngFor='let option of granularOptions; let i = index'>
        <span *ngIf="'namedGranularLocationTarget_'+uuid+i as id" class='form-check form-check-inline'>
          <input
            class='form-check-input'
            type='checkbox'
            [formControlName]='option.controlIndex'
            [id]='id'
          />
          <label class='form-check-label' [for]='id' [innerHTML]='option.innerHtml'></label>
        </span>
        </ng-container>
      </div>
    </div>
    <div class='form-text mt-0'>{{ 'campaign.location.instructions' | translate }}</div>
  </div>
</ng-container>
