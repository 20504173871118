import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Creative, isImageParameter, LayoutParameters } from '@app/@core/@models/advertising/creative.model';
import { environment } from '@env/environment';
import { ConfigService } from '@app/@core/@config/config.service';
import { Asset } from '@app/@core/@models/advertising/asset.model';
import { Layout } from '@app/@core/@models/advertising/layout/layout.model';
import { Uuid } from '@shared/utils/uuid';
import { I18nFormatService } from '@app/@i18n/services/i18n-format.service';

@Component({
  selector: 'app-adn-preview-creative-data[creative][assets][layout][updateStretchy][updateIsUsingExampleData]',
  templateUrl: './adn-preview-creative-data.component.html',
  styleUrls: ['./adn-preview-creative-data.component.scss']
})
export class AdnPreviewCreativeDataComponent implements OnInit, OnDestroy, OnChanges {
  @Input() creative!: Creative;
  @Input() layout!: Layout;
  @Input() assets!: Asset[];
  @Output() updateStretchy = new EventEmitter<boolean>();
  @Output() updateIsUsingExampleData = new EventEmitter<boolean>();
  @ViewChild('previewElement') previewElementRef?: ElementRef;
  previewTimeout: any;
  previewId = 'preview-' + Uuid.generate();

  constructor(private configService: ConfigService, public i18nFormat: I18nFormatService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('creative')) {
      const prevCreative = changes['creative'].previousValue;
      const currCreative = changes['creative'].currentValue;
      if (prevCreative?.width !== currCreative.width || prevCreative?.height !== currCreative.height || JSON.stringify(prevCreative.layoutParameters) !== JSON.stringify(currCreative.layoutParameters)) {
        clearTimeout(this.previewTimeout);
        this.previewTimeout = setTimeout(() => {
          this.getPreview();
        }, 1000);
      }
    }
  }

  ngOnDestroy() {
    clearTimeout(this.previewTimeout);
  }

  private getPreview() {
    if (this.previewElementRef) {
      const element = this.previewElementRef.nativeElement;
      window.adn.preview({
        networkId: this.configService.getNetworkData()!.id,
        env: environment.ADN_ENV_KEY,
        onPageLoad: () => {
          const iframe = element.getElementsByTagName('iframe')[0];
          if (iframe) {
            this.updateStretchy.next(iframe.style.width === '100%');
          }
        },
        creatives: [{
          creativeData: {
            creativeWidth: this.creative.width!,
            creativeHeight: this.creative.height!,
            layoutParameters: this.layoutParametersWithHydratedAssets(),
            layoutId: this.creative.layout?.id!
          },
          targetId: element.id
        }]
      });
    }
  }

  private layoutParametersWithHydratedAssets(): LayoutParameters {
    const creativeLayoutParameters = {};
    let isUsingExampleData = false;
    this.layout.layoutComponents.forEach((layoutComponent) => {
      if (layoutComponent.type === 'ASSET') {
        const assetRef = this.creative.layoutParameters[layoutComponent.tag];
        const asset = assetRef && isImageParameter(assetRef) ? this.assets.find(asset => asset.id === assetRef.id) : null;
        if (asset || layoutComponent.required === 'MANDATORY') {
          creativeLayoutParameters[layoutComponent.tag] = {
            cdnId: asset?.cdnId || asset?.base64 || 'https://regalia.adnuntius.com/ui/portal.png',
            width: asset?.width || 250,
            height: asset?.height || 200
          };
          if (!asset) {
            isUsingExampleData = true;
          }
        }
      } else {
        creativeLayoutParameters[layoutComponent.tag] = this.creative.layoutParameters[layoutComponent.tag];
        if (!creativeLayoutParameters[layoutComponent.tag] && layoutComponent.required === 'MANDATORY') {
          creativeLayoutParameters[layoutComponent.tag] = layoutComponent.type === 'URL' ? 'http://example.com' : this.i18nFormat.translate('campaign.ad.previewExampleField') + (layoutComponent.name || layoutComponent.tag);
          isUsingExampleData = true;
        }
      }
    });
    this.updateIsUsingExampleData.next(isUsingExampleData);
    return creativeLayoutParameters;
  }
}
