<ng-container *ngIf='paymentCalculationData$ | async as data; else loadingOrError'>
  <div class='mb-3'>
    <h3>{{ 'campaign.payment.reserveTitle' | translate }}</h3>
    <div *ngIf='data.campaignCouponAvailable.hasCoupon' class='col-5 mb-3'>
      <app-coupon [product]='campaignDraft.getProduct()!' [budget]='campaignDraft.lineItemReview.lineItem.selfServiceBudget!'
                  (discount)='handleCouponDiscount($event)'></app-coupon>
    </div>
    <div class='alert alert-info vstack gap-3' role='alert'>
      <app-manual-payment *ngIf="campaignDraft.network.paymentProvider === 'MANUAL_INVOICING'"
                          [campaignDraft]="campaignDraft" [coupon]="data.coupon"
                          [paymentCalculation]="data.paymentCalculation" (isSubmitting)="isSubmitting.next($event)">
      </app-manual-payment>
      <app-paypal-payment *ngIf="campaignDraft.network.paymentProvider.startsWith('PAYPAL')"
                          [campaignDraft]="campaignDraft" [coupon]="data.coupon"
                          [paymentCalculation]="data.paymentCalculation"
                          (isSubmitting)="isSubmitting.next($event)">
      </app-paypal-payment>
      <ngb-alert *ngIf='campaignDraft.campaignForm.dirty' class='mb-0' type='warning' [dismissible]='false'>
        {{ 'campaign.payment.saveFirst' | translate }}
      </ngb-alert>
      <ngb-alert *ngIf='errorKey' class='mb-0' type='danger' (closed)="errorKey = ''">
        {{ errorKey | translate }}
      </ngb-alert>
    </div>
  </div>
</ng-container>
<ng-template #loadingOrError>
  <div class='text-center'>
    <i *ngIf='!errorKey' class='fas fa-cog fa-spin'></i>
    <span *ngIf='errorKey'>{{ errorKey | translate }}</span>
  </div>
</ng-template>
