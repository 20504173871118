import {Component, OnInit} from '@angular/core';
import {ConfigService} from '@app/@core/@config/config.service';
import {I18nLangService} from '@app/@i18n/services/i18n-lang.service';
import {AnonProfileStore} from '@app/@core/anon/anon-profile-store.service';

@Component({
  selector: 'app-anon-shell',
  templateUrl: './anon-shell.component.html',
  styleUrls: ['./anon-shell.component.scss'],
})
export class AnonShellComponent implements OnInit {
  menuHidden = true;

  constructor(public configService: ConfigService,
              private i18nLangService: I18nLangService,
              private anonProfileStore: AnonProfileStore) {
  }

  ngOnInit(): void {
    this.i18nLangService.setLanguage(this.anonProfileStore.getAnonProfile()?.lang);
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

}
