import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from '@app/@core/@config/config.service';

export interface SignUpProps {
  emailAddress: string;
  name: string;
  advertiserName: string;
  vatId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
  advertiserEmail: string;
  phoneNumber: string;
  couponCode: string;
  displayName: string;
  locale: string;
  signUpCondition: boolean;
  hCaptchaResponse: string;
}

@Injectable({
  providedIn: 'root',
})
export class SignUpResource {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {}

  formSignup(props: SignUpProps): Observable<undefined> {
    let httpParams = new HttpParams();
    httpParams = this.addToHttpParams(httpParams, props, 'emailAddress')
    httpParams = this.addToHttpParams(httpParams, props, 'name');
    httpParams = this.addToHttpParams(httpParams, props, 'advertiserName');
    httpParams = this.addToHttpParams(httpParams, props, 'vatId');
    httpParams = this.addToHttpParams(httpParams, props, 'addressLine1');
    httpParams = this.addToHttpParams(httpParams, props, 'addressLine2');
    httpParams = this.addToHttpParams(httpParams, props, 'city');
    httpParams = this.addToHttpParams(httpParams, props, 'state');
    httpParams = this.addToHttpParams(httpParams, props, 'postCode');
    httpParams = this.addToHttpParams(httpParams, props, 'country');
    httpParams = this.addToHttpParams(httpParams, props, 'emailAddress', 'advertiserEmail');
    httpParams = this.addToHttpParams(httpParams, props, 'phoneNumber');
    httpParams = this.addToHttpParams(httpParams, props, 'couponCode');
    httpParams = this.addToHttpParams(httpParams, props, 'displayName');
    httpParams = this.addToHttpParams(httpParams, props, 'locale');
    httpParams = this.addToHttpParams(httpParams, props, 'hCaptchaResponse', 'h-captcha-response');

    return this.httpClient.post<undefined>(
      this.configService.getVersionedUri('signup'),
      httpParams.toString(),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        params: new HttpParams().set('app', 'SELF_SERVICE').set('context', this.configService.getNetworkData()?.id!),
      }
    );
  }

  private addToHttpParams(httpParams: HttpParams, props: SignUpProps, param: string, httpParam?: string): HttpParams {
    if (props[param]) {
      return httpParams.set(httpParam || param, props[param]);
    }
    return httpParams;
  }
}
