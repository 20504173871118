<ng-container [formGroup]='targetingForm'>
  <hr class='subDivider'>
  <h3>{{ 'campaign.site.title' | translate }}</h3>
  <div formArrayName='siteTargets' class='d-flex flex-column align-items-center'>
    <div class='mb-1'>
      <span [innerHTML]="'campaign.site.descriptionPrefix' | translate"></span><strong
      *ngIf='selectedSites() as selectedList'><span
      *ngIf='selectedList.length === 0'>{{ 'campaign.site.any' | translate }}</span><span
      *ngIf='selectedList.length > 0'><span
      *ngFor='let selectedItem of selectedList; let isLast=last'>{{ selectedItem }}<span
      *ngIf='!isLast' translate='grammar.listSeparator'></span> </span></span></strong>
    </div>
    <div>
      <ng-container *ngFor='let option of options; let i = index'>
        <span *ngIf="'siteTarget_'+uuid+i as id" class='form-check form-check-inline'>
          <input class='form-check-input' type='checkbox' [formControlName]='option.controlIndex' [id]='id' />
          <label class='form-check-label' [for]='id'>
            {{ option.name }}
          </label>
        </span>
      </ng-container>
    </div>
    <div class='form-text mt-0'>{{ 'campaign.site.instructions' | translate }}</div>
  </div>
</ng-container>
