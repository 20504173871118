import { Injectable } from '@angular/core';
import { EphemeralLocalObjectStore } from '@shared/storage/local/ephemeral-local-object-store';
import { Network } from '@app/@core/@models/network/network.model';
import { CacheRegistry } from '@shared/storage/cache-registry.service';
import { Observable, of, shareReplay, tap } from 'rxjs';
import { NetworkResource } from '@app/@core/@rest/admin/network-resource.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private readonly localNetwork: EphemeralLocalObjectStore<Network>;
  loader$?: Observable<Network>;

  constructor(cacheRegistry: CacheRegistry, private networkResource: NetworkResource) {
    this.localNetwork = new EphemeralLocalObjectStore<Network>(
      cacheRegistry,
      'network',
      () => { this.loader$ = undefined }
    );
  }

  getNetwork(): Observable<Network> {
    const network = this.localNetwork.getObject();
    if (network) {
      return of(network);
    } else if (this.loader$) {
      return this.loader$;
    } else {
      this.loader$ = this.networkResource.getNetwork$().pipe(
        tap((network) => this.localNetwork.setObject(network)),
        shareReplay(1)
      );
      return this.loader$;
    }
  }

}
