import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Product } from '@app/@core/@models/design/product.model';
import { NamedLocation } from '@app/@core/@models/targeting/named-location-target.model';
import { TargetingForm } from '@app/@core/advertising/campaign/advert/targeting-form';
import { ConfigService } from '@app/@core/@config/config.service';
import { I18nFormatService } from '@app/@i18n/services/i18n-format.service';
import { I18nLangService } from '@app/@i18n/services/i18n-lang.service';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-location-targeting-options[product][targetingForm]',
  templateUrl: './named-location-targeting-options.component.html',
  styleUrls: ['./named-location-targeting-options.component.scss']
})
export class NamedLocationTargetingOptionsComponent implements OnInit, OnChanges {
  @Input() product!: Product;
  @Input() targetingForm!: TargetingForm;
  options: { controlIndex: number, innerHtml: string }[] = [];
  granularOptions: { controlIndex: number, innerHtml: string }[] = [];
  granularLinkCollapsed: boolean = true;
  uuid: string = Uuid.generate();

  constructor(private configService: ConfigService,
              private i18nLangService: I18nLangService,
              private i18nFormatService: I18nFormatService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options = [];
    this.granularOptions = [];
    const granLocations = this.configService.getNetworkData()!.moreGranularLocations || [];
    for (let i = 0; i < this.targetingForm.controls.namedLocationTargets.length; i++) {
      const namedLocation = this.getNamedLocationFromControlIndex(i);
      if (namedLocation) {
        const loc = this.translatedNamedLocation(namedLocation);
        const locationEntry = { controlIndex: i, innerHtml:  loc};
        if (granLocations.length == 0 || granLocations.indexOf(loc) > -1) {
          this.options.push(locationEntry);
        } else {
          this.granularOptions.push(locationEntry);
        }
      }
    }
    if (this.configService.getNetworkData()!.locationOrder === 'alpha') {
      this.options.sort((a, b) =>
          a.innerHtml.localeCompare(b.innerHtml)
      );
      this.granularOptions.sort((a, b) =>
        a.innerHtml.localeCompare(b.innerHtml)
      );
    }
  }

  selectedNamedLocationsInnerHtml(): string[] {
    const result: string[] = [];
    this.options.forEach(option => {
      if (this.targetingForm.value.namedLocationTargets![option.controlIndex]) {
        result.push(option.innerHtml);
      }
    });
    this.granularOptions.forEach(option => {
      if (this.targetingForm.value.namedLocationTargets![option.controlIndex]) {
        result.push(option.innerHtml);
      }
    });
    return result;
  }

  private getNamedLocationFromControlIndex(index: number): NamedLocation | undefined {
    const namedLocationTarget = this.product.creativeTargeting.namedLocationTarget;
    if (namedLocationTarget && index < namedLocationTarget.locations.length) {
      return namedLocationTarget.locations[index];
    }
    return undefined;
  }

  translatedNamedLocation(namedLocation: NamedLocation): string {
    const key = 'location.' + namedLocation.name;
    if (this.i18nLangService.hasTranslationKey(key)) {
      return this.i18nFormatService.translate(key);
    } else {
      return namedLocation.name;
    }
  }
}
