/**
 * Copyright © 2023 Adnuntius AS.
 */
import _mapValues from 'lodash-es/mapValues';
import _forEach from 'lodash-es/forEach';
import _get from 'lodash-es/get';
import _set from 'lodash-es/set';
import _cloneDeep from 'lodash-es/cloneDeep';
import _isPlainObject from 'lodash-es/isPlainObject';

export const convertJsonStringFieldToObject = function (data: any, objName: string) {
  const workingData = _cloneDeep(data);
  const fromJson = function (theObj: any, param: string) {
    const jsonData = {};
    _set(
      jsonData,
      param,
      _mapValues(theObj[param], function (val) {
        try {
          return JSON.parse(val);
        } catch (ex) {
          return val;
        }
      })
    );
    return jsonData[param];
  };

  const theSplit = objName.split('.');
  if (theSplit.length < 1 || !Array.isArray(_get(data, theSplit[0]))) {
    const obj = _get(workingData, objName);
    if (_isPlainObject(obj)) {
      workingData[objName] = fromJson(data, objName);
    }
  } else {
    _forEach(data[theSplit[0]], function (d) {
      const obj = _get(d, theSplit[1]);
      if (_isPlainObject(obj)) {
        d[theSplit[1]] = fromJson(d, theSplit[1]);
      }
    });
  }
  return workingData;
};
