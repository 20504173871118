import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '@app/@core/@config/config.service';
import {convertJsonStringFieldToObject} from '@shared/utils/json-response-util';
import {Product} from '@app/@core/@models/design/product.model';
import {ResultList} from '@app/@core/@models/common/result-list';

// TODO: should use PersistenceQueryOptions and addToHttpParams(...)
export interface ProductQueryParams {
  context: string;
  excludeInvalid: boolean;
  includeActive: boolean;
  includeHidden: boolean;
  includeInactive: boolean;
  orderBy: string;
  pageSize: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProductResource {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {}

  get(params: ProductQueryParams): Observable<ResultList<Product>> {
    return this.httpClient
      .get<ResultList<Product>>(this.configService.getVersionedUri('product'), {
        params: new HttpParams()
          .set('context', params.context)
          .set('excludeInvalid', params.excludeInvalid)
          .set('includeActive', params.includeActive)
          .set('includeHidden', params.includeHidden)
          .set('includeInactive', params.includeInactive)
          .set('orderBy', params.orderBy)
          .set('pageSize', params.pageSize),
      })
      .pipe(
        map((queryResult) => {
          queryResult.results.forEach(product => {
            product.data = convertJsonStringFieldToObject(product,"data").data;
          })
          return queryResult;
        })
      );
  }

}
