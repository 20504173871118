import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AdvertDraft} from '@app/@core/advertising/campaign/advert/advert-draft';

@Component({
  selector: 'app-add-creative[advertDraft]',
  templateUrl: './add-creative.component.html',
  styleUrls: ['./add-creative.component.scss'],
})
export class AddCreativeComponent implements OnInit, OnChanges {
  @Input() advertDraft!: AdvertDraft;
  isDisabled = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    const visibleCreatives = this.advertDraft.campaignDraft.numVisibleCreatives(this.advertDraft.campaignDraft.campaignForm);
    const adNum = this.advertDraft.campaignDraft.advertisementNumber(this.advertDraft.campaignDraft.campaignForm, this.advertDraft.advertForm);
    this.isDisabled = (adNum >= 3 || adNum < visibleCreatives);
  }

  onAddClicked() {
    this.advertDraft.addAnotherAdvert();
  }
}
