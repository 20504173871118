import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Coupon} from '@app/@core/@models/design/coupon.model';
import {Payment, PaymentCalculation} from '@app/@core/@models/network/payment.model';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {Money} from '@app/@core/@models/network/money.model';
import {EMPTY, finalize, Observable, of, tap} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {PaymentResource} from '@app/@core/@rest/admin/payment-resource.service';
import {Uuid} from '@shared/utils/uuid';
import {Network} from '@app/@core/@models/network/network.model';
import {LineItem} from '@app/@core/@models/advertising/lineitem.model';
import {LineItemResource} from '@app/@core/@rest/advertising/line-item-resource.service';

@Component({
  selector: 'app-manual-payment[campaignDraft][coupon][paymentCalculation][isSubmitting]',
  templateUrl: './manual-payment.component.html',
  styleUrls: ['./manual-payment.component.scss']
})
export class ManualPaymentComponent implements OnInit {
  @Input() campaignDraft!: CampaignDraft;
  @Input() coupon?: Coupon;
  @Input() paymentCalculation!: PaymentCalculation;
  @Output() isSubmitting = new EventEmitter<boolean>();
  submit$?: Observable<any>;

  constructor(public i18nFormatService: I18nFormatService,
              private paymentResource: PaymentResource,
              private lineItemResource: LineItemResource) { }

  ngOnInit(): void {
  }

  public onSubmit() {
    this.isSubmitting.next(true);
    this.submit$ = of(undefined)
      .pipe(
        // create payment
        switchMap(() =>
          this.paymentResource.post(this.createPaymentMsg())
        ),
        // mark line-item as submitted
        switchMap(() =>
          this.lineItemResource.update({
            id: this.campaignDraft.lineItemReview.lineItem.id,
            userState: 'SUBMITTED',
          } as LineItem)
        ),
        // success, notify parent of payment success
        tap(() => {
          this.campaignDraft.sendUpdate({
            successKeyParagraph1: 'campaign.running.manual.successfulPaymentParagraph1',
            successKeyParagraph2: 'campaign.running.successfulPaymentParagraph2',
            reloadLineItemReview: true,
          });
        }),
        // error handling
        catchError(() => {
          this.campaignDraft.sendUpdate({
            errorKey: 'campaign.payment.failure'
          })
          return EMPTY;
        }),
        finalize(() => {
          this.isSubmitting.next(false);
          this.submit$ = undefined
        })
      );
  }

  getChargeAmount(paymentCalculation: PaymentCalculation): Money {
    if (paymentCalculation.amountWithDiscountApplied) {
      return paymentCalculation.amountWithDiscountApplied;
    } else {
      return this.campaignDraft.lineItemReview.lineItem.selfServiceBudget!;
    }
  }

  getBudgetedImpressions(): number {
    const product = this.campaignDraft.getProduct()!;
    return (1000 * this.campaignDraft.lineItemReview.lineItem.selfServiceBudget!.amount) / product.minimumCpm.amount;
  }

  private createPaymentMsg(): Payment {
    const paymentId = Uuid.generate();
    const baseUrl = window.location.href.split('?')[0]
    const payment = {
      cancelUrl: baseUrl + `?payment=cancelled&paymentId=${paymentId}`,
      id: paymentId,
      network: {id: this.campaignDraft.network.id,} as Network,
      lineItem: this.campaignDraft.lineItemReview.lineItem.id,
      returnUrl: baseUrl + `?payment=approved&paymentId=${paymentId}`,
    } as Payment;
    if (this.coupon) {
      payment.coupon = this.coupon.id;
    }
    return payment;
  }
}
