<div [formGroup]="form">
  <label class="form-label">
    <strong>{{ 'campaign.smoothing.title' | translate }}</strong>
  </label>
  <div class="form-check">
    <input class="form-check-input" type="radio" name="smoothed" id="smoothRadioInput" formControlName="smoothed"
           [value]="true"/>
    <label class="form-check-label" for="smoothRadioInput"
           [innerHTML]="'campaign.smoothing.trueField' | translate"></label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="radio" name="smoothed" id="fastRadioInput" formControlName="smoothed"
           [value]="false"/>
    <label class="form-check-label" for="fastRadioInput"
           [innerHTML]="'campaign.smoothing.falseField' | translate"></label>
  </div>
</div>
