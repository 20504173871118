import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Product} from '@app/@core/@models/design/product.model';
import {UserSegment, UserSegmentTarget} from '@app/@core/@models/targeting/user-segment-targets.model';
import {TargetingForm} from '@app/@core/advertising/campaign/advert/targeting-form';
import {ConfigService} from '@app/@core/@config/config.service';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-user-segment-targeting-options[product][targetingForm]',
  templateUrl: './user-segment-targeting-options.component.html',
  styleUrls: ['./user-segment-targeting-options.component.scss']
})
export class UserSegmentTargetingOptionsComponent implements OnInit, OnChanges {
  @Input() product!: Product;
  @Input() targetingForm!: TargetingForm;
  uuid: string = Uuid.generate();
  options: { controlName: number, userSegments: UserSegment[] }[] = [];

  constructor(private configService: ConfigService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options = [];
    for (let i = 0; i < this.targetingForm.controls.userSegmentTargets.length; i++) {
      const userSegmentTarget = this.getUserSegments(this.getUserSegmentTargetFromIndex(i));
      if (userSegmentTarget.filter(userSegment => userSegment.name).length) {
        this.options.push({
          controlName: i,
          userSegments: userSegmentTarget
        });
      }
    }
    if (this.configService.getNetworkData()!.segmentOrder === 'alpha') {
      this.options.sort((a, b) => {
        let i = 0;
        while (i < a.userSegments.length && i < b.userSegments.length) {
          const nameDiff = a.userSegments[i].name.localeCompare(b.userSegments[i].name);
          if (nameDiff !== 0) {
            return nameDiff;
          }
        }
        return a.userSegments.length - b.userSegments.length;
      })
    }
  }

  selectedUserSegments(): UserSegment[][] {
    const result: UserSegment[][] = [];
    for (let option of this.options) {
      if (this.targetingForm.controls.userSegmentTargets.at(option.controlName).value) {
        result.push(option.userSegments);
      }
    }
    return result;
  }

  private getUserSegments(userSegmentTarget?: UserSegmentTarget): UserSegment[] {
    const result: UserSegment[] = [];
    if (userSegmentTarget) {
      userSegmentTarget.userSegments.forEach(userSegmentTarget => {
        result.push(userSegmentTarget);
      })
    }
    return result;
  }

  private getUserSegmentTargetFromIndex(index: number): UserSegmentTarget | undefined {
    if (!this.product.creativeTargeting.userSegmentTargets || index >= this.product.creativeTargeting.userSegmentTargets.length) {
      return undefined;
    } else {
      return this.product.creativeTargeting.userSegmentTargets[index];
    }
  }
}
