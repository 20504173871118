import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Layout} from '@app/@core/@models/advertising/layout/layout.model';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import {LayoutComponent} from '@app/@core/@models/advertising/layout/layout-component.model';
import {MediaTypeHelper} from '@app/@core/design/media-type-helper.service';
import {AdvertDraft} from '@app/@core/advertising/campaign/advert/advert-draft';
import { Uuid } from '@shared/utils/uuid';

type LayoutSummaryTypes = 'image' | 'text' | 'url' | 'option' | 'flash' | 'video' | 'thirdParty' | 'code' | 'html';

@Component({
  selector: 'app-layout-selector[advertDraft]',
  templateUrl: './layout-selector.component.html',
  styleUrls: ['./layout-selector.component.scss']
})
export class LayoutSelectorComponent implements OnInit, OnChanges {
  @Input() advertDraft!: AdvertDraft;
  layouts!: Layout[];
  id: string = Uuid.generate();

  constructor(private i18nFormatService: I18nFormatService, private mediaTypeHelper: MediaTypeHelper) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.layouts = this.advertDraft.campaignDraft.layoutsForSelectedProduct();
  }

  getLayoutSummary(layout: Layout): Array<string> {
    return Object.entries(this.getLayoutSummaryFromComponents(layout.layoutComponents))
      .filter(([_, count]) => count > 0)
      .map(([key, val]) =>
        "" + val + " " + this.i18nFormatService.translate('layout.fieldSummary.' + key + (val > 1 ? 'Plural' : '')))
  }

  onLayoutChange(layoutId: string) {
    this.advertDraft.advertForm.controls.layoutId.setValue(layoutId);
    this.advertDraft.advertForm.controls.layoutId.markAsDirty();
    this.advertDraft.layoutChanged();
  }

  previouslySelectedLayoutNotAvailable(): boolean {
    return !!this.advertDraft.advertForm.value.layoutId && (this.layouts.find(layout => layout.id === this.advertDraft.advertForm.value.layoutId) === undefined);
  }

  // copied from sui src/components/util/layout-component-helper.ts
  getLayoutSummaryFromComponents(layoutComponents: LayoutComponent[]): Record<LayoutSummaryTypes, number> {
    const counts: Record<LayoutSummaryTypes, number> = {
      image: 0,
      text: 0,
      url: 0,
      option: 0,
      flash: 0,
      video: 0,
      thirdParty: 0,
      code: 0,
      html: 0
    };
    layoutComponents.forEach((layoutComponent) => {
      if (layoutComponent.type === 'ASSET') {
        const mediaType = this.mediaTypeHelper.getSupportedMediaType(layoutComponent.mimeTypes[0]);
        if (!mediaType) {
        } else if (mediaType.image) {
          counts.image++;
        } else if (mediaType.video) {
          counts.video++;
        } else if (mediaType.id === 'html') {
          counts.html++;
        } else if (mediaType.id === 'flash') {
          counts.flash++;
        }
      } else if (layoutComponent.type === 'URL') {
        counts.url++;
      } else if (layoutComponent.type === 'CHOICE') {
        counts.option++;
      } else if (layoutComponent.type === 'TEXT') {
        if (layoutComponent.tag === 'thirdParty') {
          counts.thirdParty++;
        } else if (layoutComponent.textType === 'CODE') {
          counts.code++;
        } else {
          counts.text++;
        }
      }
    });
    return counts;
  }
}
