import {ComponentRequired, LayoutTag} from '@app/@core/@models/advertising/layout/layout.model';
import {LayoutComponent} from '@app/@core/@models/advertising/layout/layout-component.model';

// ng.model.layout.TextComponent
export interface TextComponent {
  maxLength: number
  minLength: number
  name?: string
  required: ComponentRequired
  tag: LayoutTag | "thirdParty"
  textType: 'TEXT' | 'CODE'
  type: 'TEXT'
}

export function isTextComponent(layoutComponent:LayoutComponent): layoutComponent is TextComponent {
  return (layoutComponent.type === 'TEXT');
}
