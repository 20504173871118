import {Component, Input, OnInit} from '@angular/core';
import {Network} from '@app/@core/@models/network/network.model';

@Component({
  selector: 'app-timezone-warning',
  templateUrl: './timezone-warning.component.html',
  styleUrls: ['./timezone-warning.component.scss']
})
export class TimezoneWarningComponent implements OnInit {
  @Input() network!: Network
  showWarning!: boolean
  timezoneString!: string
  constructor() { }

  ngOnInit(): void {
    this.timezoneString = this.network.timeZone.replace("_", " ");
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.showWarning = browserTimezone !== this.timezoneString;
  }

}
