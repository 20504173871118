<div class='loading-overlay-parent'>
  <div *ngIf='signIn$' class='loading-overlay'></div>
  <ng-container *ngIf='signIn$ | async'></ng-container>
  <form name='signInForm' (ngSubmit)='doSignIn()' [formGroup]='signInForm' novalidate>
    <div [ngClass]='{ "card-header": !modal, "modal-header": modal }' class="d-flex flex-row">
      <h3>{{ 'signIn.signInHeading' | translate }}</h3>
      <span  *ngIf='hasAnonCampaignLink' class='anonLink ms-auto'>
        <a
           [routerLink]="configService.getUrl('/campaigns/campaign/anonymous-campaign')"
        >{{ 'campaign.anon.signInText' | translate }}</a>
      </span>
    </div>
    <div class='vstack gap-3' [ngClass]="{'card-body': !modal, 'modal-body': modal }">
      <div *ngIf='error' class='alert alert-danger'>
        {{ error }}
      </div>
      <app-username [signInForm]='signInForm' [showInvalidFeedback]="showInvalidFeedback"></app-username>
      <app-password-input [signInForm]='signInForm' [showInvalidFeedback]="showInvalidFeedback"></app-password-input>
      <app-masquerade-user-input *ngIf='isMasquerade' [signInForm]='signInForm'
                                 [showInvalidFeedback]="showInvalidFeedback"></app-masquerade-user-input>
      <div class='vstack'>
        <button *ngIf="canResetPassword" id='password-reset' type='button' class='btn btn-link d-inline-flex px-0'
                (click)='goToPasswordReset()'>
          {{ 'signIn.forgotPassword' | translate }}
        </button>
        <button *ngIf="canRegNewUser" id='register-new-user' type='button'
                class='btn btn-link d-inline-flex px-0'
                (click)='goToRegisterNewUser()'>
          {{ 'signIn.registerNewUser' | translate }}
        </button>
        <button *ngIf="canNavToSignIn" id='return-home' type='button' class='btn btn-link d-inline-flex px-0'
                (click)='navigateToSignInPage()'>
          {{ 'signIn.returnHome' | translate }}
        </button>
      </div>
    </div>
    <div class='vstack align-items-stretch' [ngClass]="{'card-footer': !modal, 'modal-footer': modal}">
      <div class='my-1 d-flex flex-row-reverse justify-content-between'>
        <button class='btn btn-primary' type='submit' [disabled]='signIn$'>
          <i *ngIf='signIn$' class='fas fa-cog fa-spin'></i>
          <span>{{ 'signIn.signInButton' | translate }}</span>
        </button>
        <button *ngIf='canChangeUser && signInForm.value.usernameProvided' id='change-user' type='button'
                class='btn btn-link px-0'
                (click)='changeUser()'>
          {{ 'signIn.differentAccount' | translate }}
        </button>
      </div>
      <div class='text-end'>
        <small>{{ 'adn.poweredBy' | translate }}</small>
      </div>
    </div>
  </form>
</div>
