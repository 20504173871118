<div class='selectionContainer'>
  <div *ngIf='filters' class='row'>
    <div class='text-center mb-2' [innerHTML]="'campaign.filter.preMessage' | translate"></div>
    <div *ngFor='let filterEntry of filters; let i=index' class='mb-3'>
      <span class='me-2' [innerHTML]="'campaign.filter.message' | translate: { 'option': filterEntry.key}"></span>
      <ng-container *ngFor='let option of filterEntry.options; let j=index'>
        <span class='form-check form-check-inline'>
          <input class='form-check-input btn-check' type='radio' name='filterOption' [id]="'option_'+i+'_'+j"
                 [checked]='selectedFilter === option'
                 (change)='handleFilterSelection(option)'>
          <label class='form-check-label filterOption' [for]="'option_'+i+'_'+j"
                 [ngClass]="{'filterOptionSelected': selectedFilter === option}">
            <span class='fa-regular fa-square-check' *ngIf='selectedFilter === option'></span>
            <span class='fa-regular fa-square' *ngIf='selectedFilter !== option'></span>
            {{ option.value }}
          </label>
        </span>
      </ng-container>
    </div>
  </div>
  <div class='row'>
    <div *ngIf='products.length > 0'>
      <div class='text-center mb-2' [innerHTML]="'campaign.filter.postMessage' | translate"></div>
    </div>
    <div *ngFor='let product of products' class='col-sm-4 thumbnailColumn'>
      <div class='thumbnailEntry' [ngClass]="{'itemSelected': product.id === selectedProductId}" (click)='selectedProductId = product.id;onProductChange(product)'>
        <input type='radio' class='btn-check' id='{{ product.id }}' name='product'
               [checked]='product.id === selectedProductId'
               value='{{ product.id }}' (change)='onProductChange(product)'
               [disabled]='!campaignDraft.layouts || campaignDraft.rejectedAdverts().length'
        />
        <label class='caption'>
          <div class='float-end selectorIconContainer'>
            <span *ngIf='product.id === selectedProductId' class='selectedIcon fa-regular fa-square-check'></span>
            <span *ngIf='product.id !== selectedProductId' class='unselectedIcon fa-regular fa-square'></span>
          </div>
          <h3>
            {{ product.name }}
          </h3>
          <p *ngIf='product.description'>{{ product.description }}</p>
          <div
            [innerHTML]="'campaign.product.adDesignChoice' | translate: { count: product.layouts.length }"
          ></div>
          <div class='mb-3'>
              <span *ngFor='let dim of product.allowedDimensions' class='badge bg-primary me-1'>
                {{ dim[0] }}x{{ dim[1] }}
              </span>
          </div>
          <div *ngIf='!product.data.presentationOptions?.hideCpm'
               [innerHTML]="'campaign.product.cpm' | translate: { value: i18nFormatService.currency(product.minimumCpm) }"
          ></div>
          <div
            *ngIf='product.minimumBudget'
            [innerHTML]="
              'campaign.product.minSpend' | translate: { value: i18nFormatService.currency(product.minimumBudget) }
            "
          ></div>
          <div
            *ngIf='product.maximumBudget'
            [innerHTML]="
              'campaign.product.maxSpend' | translate: { value: i18nFormatService.currency(product.maximumBudget) }
            "
          ></div>
          <ng-container *ngIf="campaignDraft.signUpCoupons">
            <ng-container *ngFor="let coupon of campaignDraft.signUpCoupons.results">
              <span *ngIf="couponHasProduct(coupon, product)">
                <span *ngIf="coupon.type === 'PERCENTAGE'" class="badge bg-success mt-2 me-1">{{coupon.discountPercentage / 100 | percent }} discount available</span>
                <span *ngIf="coupon.type === 'MONETARY'" class="badge bg-success mt-2 me-1">{{i18nFormatService.currency(coupon.discountMonetary)}} discount available</span>
              </span>
            </ng-container>
          </ng-container>
        </label>
      </div>
    </div>
  </div>
  <div *ngIf='campaignDraft.rejectedAdverts().length' class='row mb-4'>
    <div>
      {{ 'campaign.adRejected.productUnchanged' | translate }}
    </div>
  </div>
</div>
