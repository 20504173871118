/**
 * Stores objects of type T in local storage.
 * Key is prefixed with environment.
 */
import {environment} from '@env/environment';
import {ObjectStore} from '@shared/storage/cache-registry.service';

export abstract class BaseLocalObjectStore<T> implements ObjectStore<T> {
  private readonly key: string;

  protected constructor(key: string) {
    this.key = 'adn.suing.' + environment.ADN_ENV_KEY + '.' + key;
  }

  getObject(): T | undefined {
    const val = localStorage.getItem(this.key);
    if (val === null) {
      return undefined;
    } else {
      try {
        return JSON.parse(val);
      } catch (e) {
        return undefined;
      }
    }
  }

  setObject(val: T) {
    localStorage.setItem(this.key, JSON.stringify(val));
  }

  removeObject(): void {
    localStorage.removeItem(this.key);
  }
}
