<p>
  <button [disabled]="isReachEstimateTriggerDisabled()" type="button" class="btn btn-small btn-outline-dark"
          (click)="makeReachEstimate()" translate="campaign.reachEstimate.trigger"></button>
</p>
<p *ngIf="isReachEstimateTriggerDisabled()"
   class="small form-text" translate="campaign.reachEstimate.disabled"></p>
<ng-container *ngIf="reachEstimate$ | async as reachEstimate; else loadingOrError">
  <ngb-alert [dismissible]="false" [type]="getAlertType()" class="vstack gap-3">
    <div>{{ 'campaign.reachEstimate.' + reachEstimateType | translate }}</div>
    <div *ngIf="reachEstimateRangeLower > 0">
      {{ 'campaign.reachEstimate.rangeLowerUpper' | translate: {
      lower: reachEstimateRangeLower | number,
      upper: reachEstimateRangeUpper | number
    } }}</div>
    <div *ngIf="reachEstimateRangeLower <= 0 && reachEstimateRangeLower > 0">
      {{ 'campaign.reachEstimate.rangeUpper' | translate: {upper: reachEstimateRangeUpper | number} }}
    </div>
  </ngb-alert>
</ng-container>
<ng-template #loadingOrError>
  <span *ngIf="doingReachEstimate" class="fa fa-cog fa-spin"></span>
  <ngb-alert *ngIf='error' type='danger' (closed)='error = undefined'>{{ error }}</ngb-alert>
</ng-template>


