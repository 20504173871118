import {Component, Input, OnInit} from '@angular/core';
import {SignInForm} from '@shared/sign-in/@form/sign-in-form';

@Component({
  selector: 'app-masquerade-user-input[signInForm][showInvalidFeedback]',
  templateUrl: './masquerade-user-input.component.html',
  styleUrls: ['./masquerade-user-input.component.scss']
})
export class MasqueradeUserInputComponent implements OnInit {
  @Input() signInForm!: SignInForm;
  @Input() showInvalidFeedback!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
