import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Network } from '@app/@core/@models/network/network.model';
import { ConfigService } from '@app/@core/@config/config.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkResource {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {}

  getNetwork$(): Observable<Network> {
    const endPoint = this.configService.getVersionedUri('networks');
    const networkId = this.configService.getNetworkData()!.id;
    return this.httpClient.get<Network>(`${endPoint}/${networkId}?context=${networkId}`);
  }
}
