import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormDebugComponent } from '@shared/form-debug/form-debug.component';
import { PageNotFoundComponent } from '@shared/page-not-found/page-not-found.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LogBufferViewComponent } from '@shared/logging/log-buffer-view/log-buffer-view.component';
import { HeaderCellComponent } from '@shared/header-cell/header-cell.component';
import {UsernameComponent} from '@shared/sign-in/username/username.component';
import {PasswordInputComponent} from '@shared/sign-in/password-input/password-input.component';
import {SignInFormComponent} from '@shared/sign-in/sign-in-form/sign-in-form.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MasqueradeUserInputComponent} from '@shared/sign-in/masquerade-user-input/masquerade-user-input.component';
import {SafeHtmlPipe} from '@shared/@pipes/safe-html.pipe';
import {ErrorComponent} from '@shared/error/error.component';
import { TimezoneWarningComponent } from './timezone-warning/timezone-warning.component';
import {SignInModalFormComponent} from '@shared/sign-in/sign-in-modal-form/sign-in-modal-form.component';

@NgModule({
  imports: [TranslateModule, CommonModule, ReactiveFormsModule, RouterModule, NgbModule],
  declarations: [
    FormDebugComponent,
    PageNotFoundComponent,
    LogBufferViewComponent,
    HeaderCellComponent,
    UsernameComponent,
    PasswordInputComponent,
    SignInFormComponent,
    MasqueradeUserInputComponent,
    SafeHtmlPipe,
    ErrorComponent,
    TimezoneWarningComponent,
    SignInModalFormComponent
  ],
  exports: [FormDebugComponent, PageNotFoundComponent, LogBufferViewComponent, HeaderCellComponent,
    UsernameComponent, PasswordInputComponent, SignInFormComponent, MasqueradeUserInputComponent, SafeHtmlPipe,
    ErrorComponent, TimezoneWarningComponent, SignInModalFormComponent],
})
export class SharedModule {}
