import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {LineItem} from '@app/@core/@models/advertising/lineitem.model';
import {CampaignForm} from '@app/@core/advertising/campaign/campaign-form';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import {ValidationWarningMessage} from "@app/@core/@models/advertising/validation-warning-message.model";

@Component({
  selector: 'app-budget[lineItem][campaignForm][anon][campaignDraft]',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent implements OnInit, OnChanges {
  @Input() lineItem!: LineItem;
  @Input() campaignForm!: CampaignForm;
  @Input() anon!: boolean;
  @Input() campaignDraft!: CampaignDraft;
  expectedImpressions: string | null = null;
  budgetValidationWarnings: Array<ValidationWarningMessage> = [];
  isInvalid = false;
  budgetMinValue = "";
  budgetMaxValue = "";
  hasRejectedAdverts = false;


  constructor(private i18nFormatService: I18nFormatService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.getBudgetValidationWarnings()
    this.budgetUpdated();
    this.budgetMinValue = this.i18nFormatService.currency(this.campaignDraft.getProduct()!.minimumBudget) || "";
    this.budgetMaxValue = this.i18nFormatService.currency(this.campaignDraft.getProduct()!.maximumBudget) || "";
    this.hasRejectedAdverts = this.campaignDraft.rejectedAdverts().length > 0;
  }

  budgetUpdated(): void {
    this.isInvalid = this.campaignForm.controls.budgetAmount.invalid ||
      (this.campaignForm.pristine && this.budgetValidationWarnings.length > 0);
    this.calcExpectedImpressions();
  }

  private getBudgetValidationWarnings() {
    this.budgetValidationWarnings = this.lineItem.validationWarnings.filter(
      (message) => {
        return message.code !== 'validation.warning.lineItem.requires.budget' &&
          message.code.match("validation\.warning\.lineItem.*\.budget")
      }
    )
  }

  private calcExpectedImpressions() {
    this.expectedImpressions = null;
    if (this.campaignForm.value.budgetAmount && this.campaignForm.value.budgetCurrency && this.lineItem.bidSpecification.cpm?.amount) {
      if (this.campaignForm.value.budgetCurrency === this.lineItem.bidSpecification.cpm.currency) {
        const imps = this.campaignForm.value.budgetAmount / this.lineItem.bidSpecification.cpm.amount * 1000;
        this.expectedImpressions = this.i18nFormatService.number(imps, '1.0-0');
      }
    }
  }
}
