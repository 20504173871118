import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ApiPrefixInterceptor, SharedModule} from '@shared';
import {ShellModule} from './@shells/shell.module';
import {AppRoutingModule} from './app-routing.module';
import {TokenInterceptor} from '@app/@core/http/token.interceptor';
import {SignInModule} from '@app/sign-in/sign-in.module';
import {AppComponent} from '@app/app.component';
import {HomeComponent} from './home/home.component';
import {HttpErrorInterceptor} from '@shared/http/http-error.interceptor';
import { RollbarTestComponent } from './rollbartest/rollbar-test.component';
import {RollbarErrorHandler} from '@shared/logging/rollbar-error-handler';
import {ROLLBAR_INSTANCE, rollbarFactory} from '@shared/logging/rollbar';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot(),
    NgbModule,
    SharedModule,
    ShellModule,
    SignInModule,
    AppRoutingModule,
    // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent, HomeComponent, RollbarTestComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true,},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true,},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true,},
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: ROLLBAR_INSTANCE, useFactory: rollbarFactory}
  ],
  bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {
}
