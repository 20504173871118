<div id="app" class='overflow-hidden d-flex flex-column flex-fill'>
  <app-localhost-env-banner></app-localhost-env-banner>
  <app-adblock-detected-banner></app-adblock-detected-banner>
  <app-header [signedInContext]="undefined"></app-header>
  <article class='container-fluid d-flex flex-column px-0 flex-fill'>
    <div class="mainContainer container">
      <router-outlet></router-outlet>
    </div>
  </article>
  <app-footer-bar></app-footer-bar>
</div>
