import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rollbar-test',
  templateUrl: './rollbar-test.component.html',
  styleUrls: ['./rollbar-test.component.scss']
})
export class RollbarTestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.methodA();
  }

  methodA() {
    this.methodB()
  }

  methodB() {
    const a = { } as any;
    console.log("a="+a);
    const b = a.x;
    console.log("b="+b);
    const c = b.y;
    console.log("c="+c);
  }

}
