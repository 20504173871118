import {Injectable} from '@angular/core';
import {Observable, tap} from 'rxjs';
import {LineItemReview} from '@app/@core/@models/advertising/line-item-review.model';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {addToHttpParams, PersistenceQueryOptions} from '@app/@core/@models/persistence/persistence-query-options';
import {LineItem} from '@app/@core/@models/advertising/lineitem.model';
import {ConfigService} from '@app/@core/@config/config.service';
import {UserState} from '@app/@core/@models/advertising/user-state.model';
import {ObjectState} from '@app/@core/@models/persistence/object-state.model';
import {ResultList} from '@app/@core/@models/common/result-list';


export interface LineItemsQueryParams extends PersistenceQueryOptions {
  rejected?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LineItemResource {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  getReview(lineItemId: string): Observable<LineItemReview> {
    return this.httpClient.get<LineItemReview>(
      this.configService.getVersionedUri(['lineitems', 'review', lineItemId]),
      {params: new HttpParams().set('context', this.configService.getNetworkData()!.id)}
    ).pipe(
      tap(lineItemReview => {
        // creatives come in unsorted, so sort by creation time
        lineItemReview.creatives.sort((a, b) => a.creative.createTime.localeCompare(b.creative.createTime));
      })
    );
  }

  query(queryParams: LineItemsQueryParams): Observable<ResultList<LineItem>> {
    let httpParams = new HttpParams().set('context', this.configService.getNetworkData()!.id);
    if (queryParams.rejected !== undefined) {
      httpParams = httpParams.set('rejected', queryParams.rejected);
    }
    httpParams = addToHttpParams(queryParams, httpParams);
    return this.httpClient.get<ResultList<LineItem>>(
      this.configService.getVersionedUri('lineitems'),
      {params: httpParams}
    );
  }

  update(lineItem: LineItem): Observable<LineItem> {
    return this.httpClient.post<LineItem>(
      this.configService.getVersionedUri('lineitems/' + lineItem.id),
      lineItem,
      {
        headers: new HttpHeaders().set('Content-Type', 'text/plain'),
        params: new HttpParams().set('context', this.configService.getNetworkData()!.id)
      }
    );
  }

  copy(params: {
    lineItemId: string,
    orderId?: string,
    newLineItemId?: string,
    copyTargeting?: boolean,
    copyCreatives?: boolean,
    newLineItemName?: string
    userState?: UserState,
    objectState?: ObjectState
  }): Observable<LineItem> {
    let httpParams = new HttpParams().set('context', this.configService.getNetworkData()!.id);
    if (params.orderId !== undefined) {
      httpParams = httpParams.set('orderId', params.orderId);
    }
    if (params.newLineItemId !== undefined) {
      httpParams = httpParams.set('newLineItemId', params.newLineItemId);
    }
    if (params.copyTargeting !== undefined) {
      httpParams = httpParams.set('copyTargeting', params.copyTargeting);
    }
    if (params.copyCreatives !== undefined) {
      httpParams = httpParams.set('copyCreatives', params.copyCreatives);
    }
    if (params.newLineItemName !== undefined) {
      httpParams = httpParams.set('name', params.newLineItemName);
    }
    if (params.userState !== undefined) {
      httpParams = httpParams.set('userState', params.userState);
    }
    if (params.objectState !== undefined) {
      httpParams = httpParams.set('objectState', params.objectState);
    }
    return this.httpClient.post<LineItem>(
      this.configService.getVersionedUri(['lineitems', params.lineItemId, 'copy']),
      null,
      {params: httpParams}
    );
  }

}
