<footer>
  <div class='container containerManual justify-content-end'>
    <div class='row'>
      <div class='col-md-6'>
        <div *ngIf="i18nLangService.hasTranslationKey('emailFooter') && !i18nLangService.hasTranslationKey('emailFooter2')" [innerHTML]="'emailFooter' | translate"></div>
        <div *ngIf="i18nLangService.hasTranslationKey('emailFooter2')" [innerHTML]="'emailFooter2' | translate"></div>
      </div>
      <div class='col-md-6'>
        <div class='text-end small'>
          <span>{{ 'adn.releaseId' | translate: { id: version } }}</span>
          <span class='mx-2'>|</span>
          <span>{{ 'adn.copyright' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</footer>
