<ng-container [formGroup]="choiceComponentForm">
  <ng-container *ngIf="choiceComponent().display === 'COMBO'">
    <label [for]="'choice'+uuid" class="form-label">{{ choiceComponent().name || choiceComponent().tag }}</label>
    <select class="form-select" [id]="'choice'+uuid"
            formControlName="componentValue"
            [ngClass]="{ 'is-invalid': isInvalid()}">
      <option *ngIf="choiceComponent().required === 'OPTIONAL'" [value]="">--</option>
      <ng-container *ngFor="let opt of choiceComponent().options">
        <option [value]="opt">{{ opt }}</option>
      </ng-container>
    </select>
  </ng-container>
  <ng-container *ngIf="choiceComponent().display === 'RADIO'">
    <div class="form-label">{{ choiceComponent().name || choiceComponent().tag }}</div>
    <ng-container *ngFor="let opt of choiceComponent().options; let i = index">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" formControlName="componentValue" [id]="'choice_'+uuid+i" [value]="opt">
        <label class="form-check-label" [for]="'choice_'+uuid+i">{{ opt }}</label>
      </div>
    </ng-container>
    <div *ngIf="choiceComponent().required === 'OPTIONAL'" class="form-check form-check-inline">
      <input class="form-check-input" type="radio" formControlName="componentValue" [id]="'choice_optional'+uuid" [value]="">
      <label class="form-check-label" [for]="'choice_optional'+uuid" translate="campaign.ad.choiceOptional"></label>
    </div>
  </ng-container>
  <div *ngIf="isInvalid()" class="invalid-feedback">
    <div *ngFor="let err of choiceControl().errors | keyvalue">
      {{ 'validation.' + err.key | translate }}
    </div>
    <ng-container *ngIf="showServerValidationWarning()">
      <ng-container *ngFor="let warning of serverValidationWarnings">
        <div *ngIf="warning.code !== 'validation.warnings.creative.missing.component'">{{ i18nFormat.translateValidationWarningMessage(warning) }}</div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
