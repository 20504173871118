
function isObject(item: any) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function updateDelta(original: any, update: any): any {
  const result: any = {};
  for (let key in update) {
    if (typeof update[key] === 'object') {
      result[key] = deepUpdate({}, original[key], update[key]);
    } else if (typeof update[key] !== 'undefined') {
      result[key] = update[key];
    }
  }
  return result;
}

export function deepUpdate(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {
          [key]: {}
        });
        deepUpdate(target[key], source[key]);
      } else {
        if (source[key] === undefined) {
          delete target[key];
        } else {
          Object.assign(target, {
            [key]: source[key]
          });
        }
      }
    }
  }
  return deepUpdate(target, ...sources);
}
