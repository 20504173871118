import {ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {Asset} from '@app/@core/@models/advertising/asset.model';
import {Creative} from '@app/@core/@models/advertising/creative.model';
import {Layout} from '@app/@core/@models/advertising/layout/layout.model';
import {Uuid} from '@shared/utils/uuid';

@Component({
  selector: 'app-ad-preview[showLabel][creative][creativePristine][layout][assets][anon]',
  templateUrl: './ad-preview.component.html',
  styleUrls: ['./ad-preview.component.scss']
})
export class AdPreviewComponent implements OnInit, OnChanges {
  @Input() showLabel!: boolean;
  @Input() creative!: Creative;
  @Input() creativePristine!: boolean;
  @Input() layout!: Layout;
  @Input() assets!: Asset[];
  @Input() anon!: boolean;
  showBorder: boolean = true;
  stretchy: boolean = false;
  stretched: boolean = false;
  isUsingExampleData: boolean = false;
  uuid: string = Uuid.generate();
  width: number | undefined;
  height: number | undefined;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.setDimensions();
  }

  onShowBorderChanged(event: any) {
    this.showBorder = event.target.checked;
  }

  setStretched(value: boolean) {
    if (this.stretched !== value) {
      this.stretched = value;
      this.setDimensions();
      this.changeDetectorRef.detectChanges();
    }
  }

  setStretchy(value: boolean) {
    if (this.stretchy !== value) {
      this.stretchy = value;
      this.setDimensions();
      this.changeDetectorRef.detectChanges();
    }
  }

  setUsingExampleDataEventFired(value: boolean) {
    if (this.isUsingExampleData !== value) {
      this.isUsingExampleData = value;
      this.setDimensions();
      this.changeDetectorRef.detectChanges();
    }
  }

  private setDimensions() {
    if (this.stretchy && this.stretched) {
      this.width = undefined;
      this.height = undefined;
    } else {
      this.width = this.creative.width;
      this.height = this.creative.height;
    }
  }
}
