<div class='card'>
  <app-sign-in-form [isMasquerade]="true" [modal]="undefined"
                    [canChangeUser]="true" [goToSignInLink]="true"
                    [canAnonCampaign]="false"
                    [canResetPassword]="false"
                    [canNavToSignIn]="false"
                    [canRegNewUser]="false"
                    [canUpdateLang]="false"
  >
  </app-sign-in-form>
</div>
