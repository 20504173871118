import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/@core/auth/auth.service';
import { ConfigService } from '@app/@core/@config/config.service';
import {NetworkData} from '@app/@core/@config/registered-urls-data';
import {AuthUserPermsService} from '@app/@core/users/auth-user-perms.service';
import {SignedInContext} from '@app/@shells/signed-in-shell/signed-in-context.service';
import {I18nLangService} from "@app/@i18n/services/i18n-lang.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-header[signedInContext]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() signedInContext?: SignedInContext;
  menuHidden = true;
  displayName: string = '';
  networkData: NetworkData;
  isAnon: boolean = false;
  isUserSelfService: boolean = false;

  constructor(private translateService: TranslateService,
    private router: Router,
    private authService: AuthService,
    public configService: ConfigService,
    private i18nLangService: I18nLangService,
    private authUserPermsService: AuthUserPermsService
  ) {
    this.networkData = this.configService.getNetworkData()!;
  }

  ngOnInit() {
    this.displayName = this.signedInContext ? this.signedInContext.user.displayName : this.translateService.instant('campaign.anon.username');
    this.isAnon = !this.signedInContext;
    this.isUserSelfService = !!this.signedInContext && this.authUserPermsService.isSelfService(this.signedInContext.authUserPerms);
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  logout() {
    const redirectRoute = this.authService.isMasquerade() ? '/sign-in/masquerade' : '/sign-in';
    this.authService.signOut();
    this.router.navigate([this.configService.getUrl(redirectRoute)]);
  }
}
