<div class="vstack gap-3">
  <div *ngIf='paymentCalculation.discountAmount as discount'
       [innerHTML]="'campaign.payment.reserveParagraph0Coupon' | translate: {
                  amount: i18nFormatService.currency(discount),
                  budget: i18nFormatService.currency(campaignDraft.lineItemReview.lineItem.selfServiceBudget) }">
  </div>
  <ng-container *ngIf="getChargeAmount(paymentCalculation) as chargeAmount">
    <div *ngIf="chargeAmount.amount" [innerHTML]="'campaign.payment.reserveParagraph1' | translate: {
                amount: i18nFormatService.currency(chargeAmount) }"></div>
    <div *ngIf="!chargeAmount.amount" [innerHTML]="'campaign.payment.reserveParagraph1Free' | translate"></div>
    <div *ngIf="chargeAmount.amount" [innerHTML]="'campaign.payment.reserveParagraph2' | translate: {
              imps: i18nFormatService.number(getBudgetedImpressions(), '1.0-0') }">
    </div>
    <div *ngIf="!submit$">
      <button [disabled]='submit$ || campaignDraft.campaignForm.dirty' name='submit-and-review'
              type='button' class='btn btn-primary btnPayment' (click)='onSubmit()'>
        <i *ngIf="chargeAmount.amount" class='fab fa-paypal'></i>
        <span *ngIf="chargeAmount.amount">{{ 'campaign.payment.PAYPAL.reserveButton' | translate }}</span>
        <span *ngIf="!chargeAmount.amount">{{ 'campaign.payment.manual.reserveButton' | translate }}</span>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="submit$">
    <ng-container *ngIf="submit$ | async"></ng-container>
    <ngb-alert type='primary' [dismissible]="false">
      <span>{{ 'campaign.payment.PAYPAL.waitingForRedirect' | translate }}</span>
    </ngb-alert>
  </ng-container>
</div>
