import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

import {TRANSLATIONS_EN} from '@app/@i18n/translations/translations-sui-en';
import {TRANSLATIONS_ES} from '@app/@i18n/translations/translations-sui-es';
import {TRANSLATIONS_DE} from '@app/@i18n/translations/translations-sui-de';
import {TRANSLATIONS_EL} from '@app/@i18n/translations/translations-sui-el';
import {TRANSLATIONS_FR} from '@app/@i18n/translations/translations-sui-fr';
import {TRANSLATIONS_ID} from '@app/@i18n/translations/translations-sui-id';
import {TRANSLATIONS_IS} from '@app/@i18n/translations/translations-sui-is';
import {TRANSLATIONS_TH} from '@app/@i18n/translations/translations-sui-th';
import {TRANSLATIONS_NO} from '@app/@i18n/translations/translations-sui-no';
import {TRANSLATIONS_SUING_EN} from '@app/@i18n/translations/translations-suing-en';
import {TRANSLATIONS_SUING_ES} from '@app/@i18n/translations/translations-suing-es';
import {TRANSLATIONS_SUING_DE} from '@app/@i18n/translations/translations-suing-de';
import {TRANSLATIONS_SUING_EL} from '@app/@i18n/translations/translations-suing-el';
import {TRANSLATIONS_SUING_FR} from '@app/@i18n/translations/translations-suing-fr';
import {TRANSLATIONS_SUING_ID} from '@app/@i18n/translations/translations-suing-id';
import {TRANSLATIONS_SUING_IS} from '@app/@i18n/translations/translations-suing-is';
import {TRANSLATIONS_SUING_TH} from '@app/@i18n/translations/translations-suing-th';
import {ConfigService} from '@app/@core/@config/config.service';
import {COMPANY_TRANSLATIONS_DE} from '@app/@i18n/translations/translations-company-de';
import {COMPANY_TRANSLATIONS_ES} from '@app/@i18n/translations/translations-company-es';
import {COMPANY_TRANSLATIONS_FR} from '@app/@i18n/translations/translations-company-fr';
import {COMPANY_TRANSLATIONS_EN} from '@app/@i18n/translations/translations-company-en';
import {TitleService} from '@app/@shells/title/title.service';

const translationByLang = {
  de: TRANSLATIONS_DE,
  el: TRANSLATIONS_EL,
  en: TRANSLATIONS_EN,
  es: TRANSLATIONS_ES,
  fr: TRANSLATIONS_FR,
  id: TRANSLATIONS_ID,
  is: TRANSLATIONS_IS,
  th: TRANSLATIONS_TH,
  no: TRANSLATIONS_NO
};
const translationsSuingByLang = {
  de: TRANSLATIONS_SUING_DE,
  el: TRANSLATIONS_SUING_EL,
  en: TRANSLATIONS_SUING_EN,
  es: TRANSLATIONS_SUING_ES,
  fr: TRANSLATIONS_SUING_FR,
  id: TRANSLATIONS_SUING_ID,
  is: TRANSLATIONS_SUING_IS,
  th: TRANSLATIONS_SUING_TH,
}
export const TRANSLATED_LANGS = Object.keys(translationByLang);
const companyTranslationsByLang = {
  en: COMPANY_TRANSLATIONS_EN,
  de: COMPANY_TRANSLATIONS_DE,
  es: COMPANY_TRANSLATIONS_ES,
  fr: COMPANY_TRANSLATIONS_FR,
};

@Injectable({
  providedIn: 'root',
})
export class I18nLangService {
  readonly defaultLanguage: string;
  readonly supportedLanguages: string[];
  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService,
              private configService: ConfigService,
              private titleService: TitleService
  ) {
    const networkData = this.configService.getNetworkData();
    const networkId = networkData?.id;
    this.defaultLanguage = networkData?.lang || 'en';
    this.supportedLanguages = networkData?.langs || [this.defaultLanguage];
    const workingLanguages = Object.assign([], this.supportedLanguages);
    if (workingLanguages.indexOf("en") < 0) {
      // always want to push the English language translations into the mix
      workingLanguages.push('en');
    }
    workingLanguages.forEach((lang) => {
      translateService.setTranslation(lang, translationByLang[lang]);
      translateService.setTranslation(lang, translationsSuingByLang[lang], true);
      if (networkId && companyTranslationsByLang[lang] && companyTranslationsByLang[lang][networkId]) {
        translateService.setTranslation(lang, companyTranslationsByLang[lang][networkId], true);
      }
    });
    translateService.setDefaultLang('en');
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the current language or selects a default language. If language is not
   * provided, will attempt to use browser language, and fall back to language
   * provided by networkData or 'en'.
   * @param language The IETF language code to set, or undefined.
   */
  setLanguage(language?: string) {
    let newLanguage = language || this.configService.getNetworkData()?.lang || this.translateService.getBrowserCultureLang() || '';
    // localStorage.getItem(languageKey)
    let isSupportedLanguage = this.supportedLanguages.includes(newLanguage);
    // If no exact match is found, search without the region
    if (newLanguage && !isSupportedLanguage) {
      newLanguage = newLanguage.split('-')[0];
      newLanguage =
        this.supportedLanguages.find((supportedLanguage) => supportedLanguage.startsWith(newLanguage)) || '';
      isSupportedLanguage = Boolean(newLanguage);
    }
    // Fallback if language is not supported
    if (!newLanguage || !isSupportedLanguage) {
      newLanguage = this.defaultLanguage;
    }
    this.translateService.use(newLanguage);
    this.titleService.languageSet();
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  getLanguage(): string {
    return this.translateService.currentLang;
  }

  hasTranslationKey(key: string): boolean {
    return this.translateService.instant(key) !== key;
  }

  translateWithDefault(defaultString: string, key: string, interpolateParams?: Object): string {
    return this.hasTranslationKey(key) ? this.translateService.instant(key, interpolateParams) : defaultString;
  }
}
