import { Injectable } from '@angular/core';
import { UserResource } from '@app/@core/@rest/users/user-resource.service';
import { Observable, shareReplay } from 'rxjs';
import { AuthUser } from '@app/@core/@models/auth/authenticated-user.model';
import { CacheRegistry, FlushableCache } from '@shared/storage/cache-registry.service';

/**
 * Loads and caches AuthUser in memory, flushed on sign-in / sign-out.
 * To access authUser.permissions use AuthUserPermsService
 * To access authUser.profile use UserProfileService
 * To access autUser.user use UserService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthUserService implements FlushableCache {
  private cache$?: Observable<AuthUser>;

  constructor(private userResource: UserResource, cacheRegistry: CacheRegistry) {
    cacheRegistry.registerFlushable('CachedAuthUser', this);
  }

  getAuthUser(): Observable<AuthUser> {
    if (!this.cache$) {
      this.cache$ = this.userResource.get().pipe(shareReplay(1));
    }
    return this.cache$;
  }

  flush() {
    this.cache$ = undefined;
  }
}
