/**
 * Copyright © 2023 Adnuntius AS.
 */

export const TRANSLATIONS_TH = {
  adn: {
    copyright: 'Copyright © 2023 Adnuntius AS',
    releaseId: 'Release ID: {{id}}',
    poweredBy: 'Powered by Adnuntius AS',
  },
  grammar: {
    listSeparator: ', ',
    titleSeparator: ': ',
  },
  general: {
    new: 'ใหม่',
    saveSuccess: 'บันทึกการอัปเดตของคุณสำเร็จแล้ว',
  },
  button: {
    upload: {
      //asset: 'Upload',
      asset: 'อัพโหลด',
      //drop: 'Drop a file here or use the upload button'
      drop: 'วางไฟล์ที่นี่หรือใช้ปุ่มอัปโหลด',
    },
    cancel: 'ยกเลิก',
    //archive: 'Archive',
    archive: 'เก็บถาวร',
    //activate: 'Activate',
    activate: 'เปิดใช้งาน',
    //applyCustomRange: 'Apply custom range',
    applyCustomRange: 'ใช้ช่วงที่กำหนดเอง',
    //clear: 'Clear',
    clear: 'เคลียร์',
    //close: 'Close',
    close: 'ปิด',
    //newCampaign: 'Create New Campaign',
    newCampaign: 'สร้างแคมเปญใหม่',
    //save: 'Save',
    save: 'บันทึก',
    //undo: 'Undo',
    undo: 'เลิกทำ',
    //today: 'Today',
    today: 'วันนี้',
  },
  locale: {
    ar: 'Arabic - عربى',
    ar_JO: 'Arabic (Jordan) - العربية (الأردن)',
    ar_EG: 'Arabic (Egypt) - العربية (مصر)',
    ar_AE: 'Arabic (UAE) - العربية (الإمارات العربية المتحدة)',
    my: 'Burmese - ဗမာ',
    en: 'English',
    en_AU: 'English (Australia)',
    en_CA: 'English (Canada)',
    en_US: 'English (USA)',
    en_GB: 'English (Great Britain)',
    en_PH: 'English (Philippines)',
    es: 'Spanish - español',
    es_ES: 'Spanish (Spain) - español (españa)',
    es_AR: 'Spanish (Argentina) - español (argentina)',
    et: 'Estonian - eesti keel',
    fi: 'Finnish - Suomalainen',
    id: 'Indonesian - bahasa Indonesia',
    is: 'Icelandic - íslensku',
    de: 'German - Deutsch',
    de_DE: 'German (Germany) - Deutsch (Deutschland)',
    de_AT: 'German (Austria) - Deutsch (Österreich)',
    de_CH: 'German (Switzerland) - Deutsch (Schweiz)',
    lt: 'Lithuanian - Lietuvis',
    lb: 'Luxembourgish - Lëtzebuergesch',
    ms: 'Malay - Bahasa Melayu',
    ms_BN: 'Malay (Brunei) - Bahasa Melayu (Brunei)',
    ms_MY: 'Malay (Malaysia) - Bahasa Melayu (Malaysia)',
    nl: 'Dutch',
    nl_BE: 'Dutch (Belgium) - Nederlands (België)',
    nl_NL: 'Dutch (Netherlands) - Nederlands (Nederland)',
    no: 'Norwegian - norsk',
    sv: 'Swedish - svenska',
    sv_SE: 'Swedish (Sweden) - svenska (Sverige)',
    sv_FI: 'Swedish (Finland) - svenska (Finland)',
    pl: 'Polish - Polskie',
    pt: 'Portuguese - Português',
    pt_BR: 'Portuguese (Brazil) - Português (Brasil)',
    pt_PT: 'Portuguese (Portugal) - Português (Portugal)',
    ro: 'Romanian - Română',
    ru: 'Russian - русский',
    el: 'Greek - Ελληνικά',
    fr: 'French - français',
    da: 'Danish - Dansk',
    th: 'Thai - ไทย',
    fil: 'Tagalog - Tagalog',
    tr: 'Turkish - Türk',
  },
  language: {
    text: 'ภาษา',
  },

  // เหตุผล Reject Case ต่างๆ
  ///
  ///

  campaign: {
    //archivedHeading: 'Archived Campaigns',
    archivedHeading: 'แคมเปญที่เก็บถาวร',
    //archivedNoResults: 'No archived campaigns found.',
    archivedNoResults: 'ไม่พบแคมเปญที่เก็บถาวร',
    //archivedShowLink: 'Show archived campaigns',
    archivedShowLink: 'แสดงแคมเปญที่เก็บถาวร',
    adRejected: {
      budgetUnchanged: 'งบประมาณที่เพิ่มขึ้น',
      productUnchanged: 'ผลิตภัณฑ์ที่ไม่เปลี่ยนแปลง',
      explanationGeneral: 'คำอธิบายทั่วไป',
      //explanation: 'Adjust your ad and resubmit your campaign for review to have it deliver your budgeted impressions.',
      explanation: 'ปรับโฆษณาของคุณและส่งแคมเปญของคุณอีกครั้งเพื่อรับการตรวจทานเพื่อให้สามารถแสดงผลตามงบประมาณ',
      ADULT_CONTENT: {
        //name: 'Adult Content',
        name: 'เนื้อหาสำหรับผู้ใหญ่',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains adult-oriented content.'
        explanation: 'โฆษณาถูกปฏิเสธเนื่องจากมีหรือนำไปสู่หน้า Landing Page ที่มีเนื้อหาสำหรับผู้ใหญ่',
      },
      BLANK_CONTENT: {
        //name: 'Blank Content',
        name: 'เนื้อหาว่างเปล่า',
        //explanation: 'The ad was rejected because it renders as blank. Please ensure that the required content have been provided correctly.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากแสดงเป็นค่าว่าง โปรดตรวจสอบให้แน่ใจว่าได้ระบุเนื้อหาที่ต้องการอย่างถูกต้อง',
      },
      MALFORMED_CLICK_THROUGH: {
        //name: 'Malformed Destination URL',
        name: 'URL ปลายทางผิดรูปแบบ',
        //explanation: 'The ad was rejected because the click destination URL does not function properly.'
        explanation: 'โฆษณาถูกปฏิเสธเนื่องจาก URL ปลายทางของการคลิกทำงานไม่ถูกต้อง',
      },
      DRUG_RELATED_CONTENT: {
        //name: 'Drug-Related Content',
        name: 'เนื้อหาเกี่ยวกับสิงเสพติด',
        //explanation: 'The ad was rejected because it or its landing page promotes drug-related content or products. This may include tobacco, alcohol, pharmaceutical or other legal or illegal drugs.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากโฆษณาหรือหน้า Landing Page ส่งเสริมเนื้อหาหรือผลิตภัณฑ์ที่เกี่ยวข้องกับยา ซึ่งอาจรวมถึงยาสูบแอลกอฮอล์ยาหรือยาอื่น ๆ ที่ถูกกฎหมายหรือผิดกฎหมาย',
      },
      WEAPON_RELATED_CONTENT: {
        //name: 'Weapon-Related Content',
        name: 'เนื้อหาเกี่ยวกับอาวุธ',
        //explanation: 'The ad was rejected because it or its landing page promotes weapons or weapon-related content. This can include content that promotes dangerous knives, guns, gun parts and other related content.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากโฆษณาหรือหน้า Landing Page ส่งเสริมอาวุธหรือเนื้อหาเกี่ยวกับอาวุธ ซึ่งอาจรวมถึงเนื้อหาที่ส่งเสริมมีดอันตรายปืนชิ้นส่วนปืนและเนื้อหาอื่น ๆ ที่เกี่ยวข้อง',
      },
      HATE_SPEECH_CONTENT: {
        //name: 'Hate-Speech Content',
        name: 'เนื้อหาที่แสดงความเกลียดชัง',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains hate speech content. This may include content promoting violence, harassment, racism, hatred and intolerance.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากมีหรือนำไปสู่หน้า Landing Page ที่มีเนื้อหาเกี่ยวกับคำพูดแสดงความเกลียดชัง ซึ่งอาจรวมถึงเนื้อหาที่ส่งเสริมความรุนแรงการล่วงละเมิดการเหยียดสีผิวความเกลียดชังและการไม่ยอมรับ',
      },
      LANDING_PAGE_REJECTED: {
        //name: 'Landing Page Rejected',
        name: 'หน้า Landing Page ถูกปฏิเสธ',
        //explanation: 'The ad was rejected because the landing page was low quality or difficult to interact with. This can include pages that disable browser navigation functions, include low value content, or lead to an email address or file, such as image, audio, video, or document, that requires an additional application to open.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากหน้า Landing Page มีคุณภาพต่ำหรือโต้ตอบได้ยาก ซึ่งอาจรวมถึงหน้าที่ปิดใช้งานฟังก์ชันการนำทางของเบราว์เซอร์รวมถึงเนื้อหาที่มีมูลค่าต่ำหรือนำไปสู่ที่อยู่อีเมลหรือไฟล์เช่นรูปภาพเสียงวิดีโอหรือเอกสารที่ต้องใช้แอปพลิเคชันเพิ่มเติมในการเปิด',
      },
      OFFENSIVE_CONTENT: {
        //name: 'Offensive Content',
        name: 'เนื้อหาที่ไม่เหมาะสม',
        //explanation: 'The ad was rejected because it contains or leads to a landing page that contains offensive or inappropriate content. Ensure that your ads and landing pages only show family safe content.',
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากมีหรือนำไปสู่หน้า Landing Page ที่มีเนื้อหาที่น่ารังเกียจหรือไม่เหมาะสม ตรวจสอบให้แน่ใจว่าโฆษณาและหน้า Landing Page ของคุณแสดงเฉพาะเนื้อหาที่เหมาะสำหรับเยาวชนเท่านั้น',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        //name: 'Unacceptable Visual Effects',
        name: 'วิชวลเอฟเฟกต์ที่ไม่สามารถยอมรับได้',
        //explanation: 'The ad was rejected because it uses unacceptable visual effects. This includes animated GIFs with a frame rate higher than 5 frames per second.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากใช้เอฟเฟ็กต์ภาพที่ไม่สามารถยอมรับได้ ซึ่งรวมถึง GIF แบบเคลื่อนไหวที่มีอัตราเฟรมสูงกว่า 5 เฟรมต่อวินาที',
      },
      DECEPTIVE_CONTENT: {
        //name: 'Deceptive Content',
        name: 'เนื้อหาหลอกลวง',
        //explanation: 'The ad was rejected because it contains misleading claims or information. Examples include ads that make scientifically impossible claims, offer guaranteed results without a refund policy, or show testimonials claiming specific results without a link to third-party verification. Ads may also be rejected if the creative is designed to look like software download buttons, official system warnings, or webpage features like menus, search results, or other site content.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากมีการอ้างสิทธิ์หรือข้อมูลที่ทำให้เข้าใจผิด ตัวอย่างเช่นโฆษณาที่อ้างว่าเป็นไปไม่ได้ทางวิทยาศาสตร์เสนอผลลัพธ์ที่รับประกันโดยไม่มีนโยบายการคืนเงินหรือแสดงคำรับรองที่อ้างว่าได้ผลลัพธ์ที่เฉพาะเจาะจงโดยไม่มีลิงก์ไปยังการยืนยันจากบุคคลที่สาม โฆษณาอาจถูกปฏิเสธหากโฆษณาได้รับการออกแบบให้มีลักษณะเหมือนปุ่มดาวน์โหลดซอฟต์แวร์คำเตือนของระบบอย่างเป็นทางการหรือคุณลักษณะของหน้าเว็บเช่นเมนูผลการค้นหาหรือเนื้อหาไซต์อื่น ๆ',
      },
      GAMBLING_CONTENT: {
        //name: 'Gambling Content',
        name: 'เนื้อหาการพนัน',
        //explanation: 'The ad was rejected because it contains gambling-related content.'
        explanation: 'โฆษณาถูกปฏิเสธเนื่องจากมีเนื้อหาเกี่ยวกับการพนัน',
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        //name: 'Prohibited Election-Related Content',
        name: 'เนื้อหาเกี่ยวกับการเลือกตั้งต้องห้าม',
        //explanation: 'The ad is rejected because it contains prohibited content related to government elections or currently elected government officeholders.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากมีเนื้อหาต้องห้ามที่เกี่ยวข้องกับการเลือกตั้งของรัฐบาลหรือผู้ดำรงตำแหน่งของรัฐบาลที่มาจากการเลือกตั้งในปัจจุบัน',
      },
      SHOCKING_CONTENT: {
        //name: 'Shocking Content',
        name: 'เนื้อหาที่น่าตกใจ',
        //explanation: 'The ad was rejected because it contains shocking content such as profane language, gruesome or disgusting imagery, or promotions that are designed to shock or scare.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากมีเนื้อหาที่น่าตกใจเช่นภาษาที่หยาบคายภาพที่น่าสยดสยองหรือน่าขยะแขยงหรือการโปรโมตที่ออกแบบมาเพื่อสร้างความตกใจหรือทำให้ตกใจ',
      },
      MALWARE_CONTENT: {
        //name: 'Malware Content',
        name: 'เนื้อหามัลแวร์',
        //explanation: 'The ad was rejected because it or its landing page contains or leads to unacceptable content such as malware.'
        explanation:
          'โฆษณาถูกปฏิเสธเนื่องจากโฆษณาหรือหน้า Landing Page มีหรือนำไปสู่เนื้อหาที่ไม่สามารถยอมรับได้เช่นมัลแวร์',
      },
    },
    anon: {
      heading: 'สร้างแคมเปญของคุณที่นี่',
      beforeText:
        'รายละเอียดแคมเปญของคุณจะถูกบันทึกไว้ในอุปกรณ์ปัจจุบันของคุณ ก่อนที่แคมเปญของคุณจะสามารถส่งมอบและเข้าถึงได้จากอุปกรณ์อื่น ๆ',
      linkText: 'สร้างบัญชีและเข้าสู่ระบบ',
      postText: '.',
    },
    layout: {
      //title: 'Select an Ad Design',
      title: 'เลือกการออกแบบโฆษณา',
      //missing: 'The layout you selected previously is not available for the current advertising option.',
      missing: 'เค้าโครงที่คุณเลือกไว้ก่อนหน้านี้ไม่สามารถใช้ได้กับตัวเลือกการโฆษณาปัจจุบัน',
      //missingPreview: "A valid layout needs to be selected before a creative can be previewed."
      missingPreview: 'ต้องเลือกเค้าโครงที่ถูกต้องก่อนจึงจะสามารถดูตัวอย่างโฆษณาได้',
    },
    device: {
      title: 'อุปกรณ์ที่คุณต้องการให้โฆษณาของคุณปรากฏ',
      descriptionPrefix: 'อุปกรณ์ที่มีให้มีดังต่อไปนี้',
      instructions: 'เลือกอุปกรณ์',
      any: 'ทั้งหมด',
    },
    domainName: {
      title: 'ไซต์ที่คุณต้องการให้โฆษณาของคุณปรากฏ',
      descriptionPrefix: 'ไซต์ที่มีอยู่มีดังต่อไปนี้',
      instructions: 'เลือกไซต์',
      any: 'ทั้งหมด',
    },
    location: {
      title: 'พื้นที่ทางภูมิศาสตร์ของแคมเปญของคุณ',
      descriptionPrefix: 'พื้นที่ทางภูมิศาสตร์ที่มีอยู่มีดังต่อไปนี้',
      instructions: 'เลือกพื้นที่ทางภูมิศาสตร์ของคุณ',
      any: 'ทั้งหมด',
    },
    userSegment: {
      title: 'ผู้ใช้ที่คุณต้องการกำหนดเป้าหมาย',
      descriptionPrefix: 'ประเภทของผู้ใช้มีดังนี้',
      instructions: 'เลือกประเภทผู้ใช้ของคุณ',
      any: 'ทั้งหมด',
    },
    product: {
      //title: 'Select an Advertising Option',
      title: 'ประเภทโฆษณาที่คุณต้องการแสดง',
      //adDesignChoice: '<strong>{{count}} ad designs</strong> available at these sizes:',
      adDesignChoice: '<strong>{{count}} รูปแบบการโฆษณา</strong> มีให้เลือกหลายขนาด:',
      //cpm: '<strong>{{value}}</strong> per thousand impressions',
      cpm: '<strong>{{value}}</strong> ต่อการเข้าชมไซต์ของคุณ',
      //minSpend: '<strong>{{value}}</strong> minimum spend'
      minSpend: '<strong>{{value}}</strong> การลงทุนโฆษณาขั้นต่ำ',
      maxSpend: '<strong>{{value}}</strong> การใช้จ่ายสูงสุด',
    },
    ad: {
      //title: 'Design Your Ad',
      title: 'ออกแบบโฆษณาของคุณ',
      //defaultName: 'Creative for {{campaignName}}',
      defaultName: 'การสร้างโฆษณาสำหรับ {{campaignName}}',
      //layoutFirst: 'Select an ad design before entering your ad\'s details.',
      layoutFirst: 'เลือกรูปแบบโฆษณาก่อนเข้าสู่เนื้อหาของคุณ',
      //deletedAsset: 'This component references a deleted image. Upload a new one.',
      deletedAsset: 'ไม่มีรูปภาพ. อัปโหลดภาพใหม่',
      imageHint: '{{mediaTypes}}, Max: {{maxSize}}kb',
      imageHintOptional: '{{mediaTypes}}, Max: {{maxSize}}kb (Optional)',
      //adSizeTitle: 'Ad Size',
      adSizeTitle: 'ขนาดโฆษณาของคุณ',
      create: 'เลือกขนาดสำหรับโฆษณาของคุณ',
      add: 'เพิ่มองค์ประกอบโฆษณา',
      count: 'องค์ประกอบการโฆษณา <strong>#{{count}}</strong>',
      maximum: 'เพิ่มได้สูงสุด 3 รายการ',
      //choiceOptional: '(No choice)',
      choiceOptional: '(ไม่มีลือก)',
      //runningPreviewTitle: 'Your {{width}}&times;{{height}} Advertisement',
      runningPreviewTitle: 'ดูตัวอย่างโฆษณาของคุณ {{width}}&times;{{height}}',
      remove: 'ยกเลิก',
      //previewTitle: 'Ad Preview',
      previewTitle: 'ดูตัวอย่างโฆษณาของคุณ',
      //border: 'Show border on advertisement',
      border: 'เพิ่มกรอบให้กับโฆษณา',
      //previewWithExample: 'This ad preview includes example data for demonstration purposes.',
      previewWithExample: 'ตัวอย่างโฆษณานี้มีข้อมูลตัวอย่างเพื่อการสาธิต',
      //textCount: 'count: {{count}}',
      textCount: 'ขีดจำกัดอักขระ: {{count}}',
      textHintChars: '{{min}}&ndash;{{max}} อักขระ',
      textHintCharsOptional: '{{min}}&ndash;{{max}} อักขระ (ไม่จำเป็น)',
      urlHint: 'URL',
      urlHintOptional: 'URL (ไม่จำเป็น)',
    },
    addNewCampaign: `
    <h3สร้างแคมเปญโฆษณาใหม่</h3>
    <p>ค้นหาและเลือกองค์ประกอบโฆษณา</p>
    <p>สร้างแคมเปญโฆษณาใหม่ด้วยปุ่มด้านบน</p>
    <p>คุณสามารถเข้าถึงแคมเปญที่สร้างไว้แล้วได้ที่นี่</p>
  `,
    //campaign: 'Campaign',
    campaign: 'แคมเปญ',
    //campaignPlural: 'Campaigns',
    campaignPlural: 'แคมเปญ',
    copy: {
      //copyText: "A copy of this campaign was successfully created.",
      copyText: 'สร้างสำเนาของแคมเปญนี้สำเร็จแล้ว',
      //copyTitle: "Campaign Copied",
      copyTitle: 'คัดลอกแคมเปญแล้ว',
      //copyPartialText: "This campaign has been copied, but not all of the advertising material could be copied along with it.",
      copyPartialText: 'มีการคัดลอกแคมเปญนี้ แต่ไม่สามารถคัดลอกเนื้อหาโฆษณาทั้งหมดไปด้วยได้',
      //copyPartialTitle: "Campaign Copied but not All Advertising Material"
      copyPartialTitle: 'คัดลอกแคมเปญ แต่ไม่ใช่สื่อโฆษณาทั้งหมด',
    },
    filter: {
      message: '{{option}}:',
    },
    //copyAfterwards: 'Go to new campaign after copy',
    copyAfterwards: 'ไปที่แคมเปญใหม่หลังจากคัดลอก',
    //defaultName: "{{productName}} Campaign",
    defaultName: 'แคมเปญ {{productName}}',
    //detailsTitle: 'Specify Your Campaign Details',
    detailsTitle: 'ระบุรายละเอียดแคมเปญของคุณ',
    //draftedHeading: 'Drafted Campaigns',
    draftedHeading: 'แคมเปญร่าง',
    //draftedNoResults: 'No drafted campaigns found.',
    draftedNoResults: 'ไม่พบแคมเปญร่าง',
    //endedHeading: 'Ended Campaigns',
    endedHeading: 'แคมเปญที่สิ้นสุด',
    //endedNoResults: 'No ended campaigns found.',
    endedNoResults: 'ไม่พบแคมเปญที่สิ้นสุดแล้ว',
    //expectedImpressions: 'Corresponds to about {{imps}} impressions',
    expectedImpressions: 'สอดคล้องเกี่ยวกับ {{imps}} การแสดงผล',
    cpmBoost: 'CPM ที่สูงขึ้น {{minPrice}} จะเพิ่มโอกาสในการแสดงผลสำหรับแคมเปญของคุณ',
    maxedOutCampaigns:
      '<p>ไม่สามารถสร้างแคมเปญใหม่ได้ในขณะนี้</p><p>จำนวนสูงสุด {{max}} สามารถสร้างแบบร่างได้</p><p>โปรดเก็บแบบร่างที่มีอยู่เพื่อให้คุณสามารถสร้างแคมเปญใหม่ได้</p>',
    payment: {
      //title: 'Payment',
      title: 'การชำระเงิน',
      //missing: 'No payment is associated with this campaign.',
      missing: 'ไม่มีการชำระเงินที่เกี่ยวข้องกับแคมเปญนี้',
      //cancelled: 'Your PayPal reservation was cancelled and your campaign was not submitted for review.',
      cancelled: 'การจอง PayPal ของคุณถูกยกเลิกและแคมเปญของคุณไม่ได้ถูกส่งเข้ารับการตรวจสอบ',
      //infoComplete: 'A {{price}} payment was completed {{time}}.',
      infoComplete: 'การชำระเงินของคุณ {{price}} ได้ดำเนินการที่ {{time}}.',
      //infoAuthorised: 'A {{price}} payment was authorised {{time}}.',
      infoAuthorised: 'การชำระเงินของ {{price}} ได้รับอนุญาตให้ {{time}}.',
      //receiptLink: 'Your <a href="{{link}}" target="_blank">payment receipt is available</a>.',
      receiptLink: '<a href="{{link}}" target="_blank">ใบเสร็จรับเงิน</a>.',
      //registering: 'Registering PayPal transaction...',
      registering: 'กำลังทำธุรกรรม PayPal...',
      //reserveTitle: 'Reserve Payment and Submit Your Campaign for Review',
      reserveTitle: 'บันทึกการชำระเงินการจองของคุณและส่งแคมเปญของคุณเพื่อตรวจสอบก่อนอัปโหลด',
      //reserveButton: 'Reserve Payment and Submit Your Campaign for Review',
      reserveButton: 'บันทึกการชำระเงินการจองของคุณและส่งแคมเปญของคุณเพื่อตรวจสอบก่อนอัปโหลด',
      //reserveParagraph1: 'We will place a <strong>hold on your PayPal account</strong> for the budgeted sum of <strong>{{amount}}</strong>, which will enable your campaign to be reviewed and run.',
      reserveParagraph1:
        'เราดำเนินการ <strong> จองผ่านบัญชี Paypal </strong> สำหรับจำนวนเงินที่จองไว้ <strong> {{amount}} </strong>, สิ่งนี้ช่วยให้สามารถตรวจสอบและส่งมอบแคมเปญได้',
      //reserveParagraph2: 'Once your campaign has ended, we will take either {{amount}} from your account or the pro-rata amount based on the actual number of impressions delivered versus the expected <strong>{{imps}} impressions</strong>.',
      reserveParagraph2:
        'ทันทีที่แคมเปญสิ้นสุดลงเราจะหักเงินจำนวนนั้น {{amount}} ของบัญชีหรือจำนวนตามสัดส่วนตามจำนวนการโพสต์ <strong> {{imps}} ดำเนินการจริง </strong>.',
      refunded: 'มีจำนวนเงิน {{price}} การชำระเงินคืนของ {{time}} ได้ทำ.',
      //failure: 'We encountered a problem trying to register your transaction with PayPal. Try again, and if a problem persists, please contact us.',
      failure: 'เกิดปัญหาในการจองผ่าน PayPal ลองอีกครั้ง.',
      //saveFirst: 'You will need to save your changes before proceeding with reserving payment',
      saveFirst: 'บันทึกการเปลี่ยนแปลงของคุณก่อนชำระเงินสำหรับการจอง',
      //waitingForRedirect: 'We are passing on the details of your transaction to PayPal and will redirect you there shortly.',
      waitingForRedirect: 'เราส่งต่อรายละเอียดธุรกรรมของคุณไปยัง {{paymentProvider}} และจะนำคุณไปที่นั่นในไม่ช้า',
      prepay: {
        detailsInfo: `
          <div class="small">
            <p>แคมเปญของคุณจะส่งมอบเมื่อชำระเงินผ่าน PayPal และตรวจสอบเนื้อหาของแคมเปญแล้ว</p>
            <p>หากแคมเปญของคุณไม่แสดงผลตามงบประมาณของคุณบัญชี PayPal ของคุณจะได้รับเงินคืนตามจำนวนเงินที่เหลือ อย่างไรก็ตามจะมีค่าธรรมเนียมขั้นต่ำ {{minimumFee}}</p>
            <p>เมื่อแคมเปญของคุณสิ้นสุดลงจะมีการสร้างรายงานประสิทธิภาพและใบเสร็จของ PayPal</p>
            <p>คุณจะสามารถหยุดการแสดงผลแคมเปญของคุณได้ตลอดเวลา แต่จะมีการเรียกเก็บค่าธรรมเนียมขั้นต่ำ {{minimumFee}} แม้ว่าจะไม่มีการแสดงผลก็ตาม</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>แคมเปญของคุณจะส่งมอบเมื่อชำระเงินผ่าน PayPal และตรวจสอบเนื้อหาของแคมเปญแล้ว</p>
            <p>หากแคมเปญของคุณไม่แสดงผลตามงบประมาณของคุณบัญชี PayPal ของคุณจะได้รับเงินคืนตามจำนวนเงินที่เหลือ</p>
            <p>เมื่อแคมเปญของคุณสิ้นสุดลงจะมีการสร้างรายงานประสิทธิภาพและใบเสร็จของ PayPal</p>
            <p>คุณจะสามารถหยุดการแสดงแคมเปญของคุณได้ตลอดเวลา</p>
          </div>
        `,
      },
      manual: {
        reserveTitle: 'ส่งแคมเปญเพื่อตรวจสอบ',
        reserveButton: 'ส่งเพื่อตรวจสอบ',
        reserveParagraph1:
          'เราจะตรวจสอบแคมเปญก่อนที่จะเปิดใช้งาน การสอบจะเกิดขึ้นในวันทำการเท่านั้นไม่ใช่วันหยุดสุดสัปดาห์หรือวันหยุดนักขัตฤกษ์',
        reserveParagraph2:
          'ในตอนท้ายของแคมเปญเราจะออกใบแจ้งหนี้สำหรับ <strong> {{amount}} </strong> หรือตามสัดส่วนขึ้นอยู่กับการแสดงผลที่จัดส่งจริง </strong>.',
        detailsInfo: `
          <div class="small">
            <p>แคมเปญสามารถออกอากาศได้ทันทีที่การตรวจสอบเนื้อหาโฆษณาเสร็จสมบูรณ์</p>
            <p>จะมีการออกใบแจ้งหนี้เมื่อสิ้นสุดแคมเปญ จำนวนเงินจะพิจารณาจากจำนวนการแสดงผลที่จัดส่งและจะไม่เกินงบประมาณสูงสุด อย่างไรก็ตามจำนวนเงินขั้นต่ำของ {{minimumFee}}</p>
            <p>รายงานประสิทธิภาพจะถูกสร้างขึ้นหลังจากสิ้นสุดแคมเปญ</p>
            <p>การจัดส่งอาจถูกขัดจังหวะได้ตลอดเวลา แต่ต้องเสียค่าบริการขั้นต่ำ {{minimumFee}}</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>แคมเปญสามารถออกอากาศได้ทันทีที่การตรวจสอบเนื้อหาโฆษณาเสร็จสมบูรณ์</p>
            <p>จะมีการออกใบแจ้งหนี้เมื่อสิ้นสุดแคมเปญ จำนวนเงินจะพิจารณาจากจำนวนการแสดงผลที่จัดส่งและจะไม่เกินงบประมาณสูงสุด</p>
            <p>รายงานประสิทธิภาพจะถูกสร้างขึ้นหลังจากสิ้นสุดแคมเปญ</p>
            <p>การจัดส่งอาจถูกขัดจังหวะได้ตลอดเวลา</p>
          </div>
        `,
      },
      status: {
        //CREATED: 'Created',
        CREATED: 'สร้างแล้ว',
        //NOT_AUTHORISED: 'Not Authorised',
        NOT_AUTHORISED: 'ไม่ได้รับอนุญาต',
        //AUTHORISED: 'Authorised',
        AUTHORISED: 'ได้รับอนุญาต',
        //COMPLETED: 'Completed',
        COMPLETED: 'เสร็จเรียบร้อย',
        PAUSED: 'หยุดพัก',
        //CANCELLED: 'Cancelled'
        CANCELLED: 'ยกเลิก',
      },
    },
    postPayment: {
      //reserveParagraph1: 'Payment for your campaign has been authorised.',
      reserveParagraph1: 'การชำระเงินสำหรับแคมเปญของคุณได้รับอนุญาตแล้ว',
      //reserveParagraph2: 'If you\'re happy with your campaign, you can submit it for review below.',
      reserveParagraph2: 'หากคุณพอใจกับแคมเปญของคุณคุณสามารถส่งเพื่อตรวจสอบด้านล่าง',
      //saveFirst: 'You will need to save your changes before proceeding with submitting your campaign for review.',
      saveFirst: 'คุณจะต้องบันทึกการเปลี่ยนแปลงของคุณก่อนที่จะดำเนินการส่งแคมเปญของคุณเพื่อรับการตรวจสอบ',
      failed: 'เกิดปัญหาในการส่งเพื่อตรวจสอบ ลองอีกครั้ง.',
      //submitButton: 'Submit Your Campaign for Review.'
      submitButton: 'ส่งแคมเปญของคุณเพื่อรับการตรวจสอบ',
    },
    //rejectedHeading: 'Rejected Campaigns',
    rejectedHeading: 'แคมเปญที่ถูกปฏิเสธ',
    report: {
      //title: 'Report',
      title: 'รายงาน',
      //ungenerated: 'A report will be available for you to download shortly.',
      ungenerated: 'รายงานจะพร้อมให้คุณดาวน์โหลดในไม่ช้า',
      //generated: 'A <a href="{{link}}" target="_blank">report is available for you to download</a>.'
      generated: '<a href="{{link}}" target="_blank">มีรายงานให้คุณดาวน์โหลด</a>.',
    },
    //runningHeading: 'In-Review and Running Campaigns',
    runningHeading: 'กำลังตรวจทานและกำลังดำเนินการแคมเปญ',
    //runningNoResults: 'No running campaigns found.',
    runningNoResults: 'ไม่พบแคมเปญที่ทำงานอยู่',
    running: {
      //budgetHeading: 'Budget Position',
      budgetHeading: 'ตำแหน่งงบประมาณ',
      //budgetPosition: '<strong>{{budgetSpent}}</strong> of your <strong>{{budget}} budget</strong> has been spent.',
      budgetPosition: '<strong>{{budgetSpent}}</strong> ของคุณ <strong>{{budget}} งบประมาณ </strong> ถูกใช้ไป',
      //cancelledPayment1: 'Your payment reservation has been cancelled.',
      cancelledPaymentParagraph1: 'การจองการชำระเงินของคุณถูกยกเลิก',
      //cancelledPayment2: 'If you still wish to proceed with your campaign, you can generate a new PayPal transaction below.',
      cancelledPaymentParagraph2:
        'หากคุณยังคงต้องการดำเนินการกับแคมเปญของคุณคุณสามารถสร้างธุรกรรม PayPal ใหม่ได้ด้านล่าง',
      //datesHeading: 'Running Dates',
      datesHeading: 'วันที่แสดงผล',
      //dates: '<strong>{{startDate}}</strong> to <strong>{{endDate}}</strong>',
      dates: 'จาก <strong>{{startDate}}</strong> ถึง <strong>{{endDate}}</strong>',
      //datesExplanation: 'Your campaign has run for <strong>{{days}} days</strong>, which is <strong>{{percentage}}</strong> of its maximum scheduled life.',
      datesExplanation:
        'แคมเปญทำงานเพื่อ <strong> {{jours}} วัน </strong>, ซึ่งสอดคล้องกับ <strong> {{pourcentage}} </strong> ของระยะเวลาสูงสุด.',
      //datesLeftExplanation: 'Your campaign will stop running in <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      datesLeftExplanation: 'แคมเปญจะสิ้นสุดใน <strong>{{days}} วัน</strong> หากงบประมาณไม่ถึงก่อนวันที่นี้',
      //datesSubmittedExplanation: 'Your campaign will run for <strong>{{days}} days</strong> if your budgeted impressions are not reached.',
      datesSubmittedExplanation:
        'แคมเปญของคุณจะทำงานเพื่อ  <strong>{{days}} วัน </strong> หากการแสดงผลตามงบประมาณของคุณไม่ถึง',
      //impressions: 'Your campaign has delivered <strong>{{imps}} impressions</strong>, which is <strong>{{percentage}} of your budgeted impressions</strong>.',
      impressions:
        'แคมเปญของคุณได้แสดงผลแล้ว <strong>{{imps}} impressions</strong>, ซึ่งเป็น <strong>{{pourcentage}} ของการแสดงผลตามงบประมาณของคุณ </strong>.',
      //impressionsRemaining: 'You have about <strong>{{imps}} impressions</strong> left to deliver.',
      impressionsRemaining: 'คุณมีประมาณ <strong>{{imps}} impressions </strong> เหลือที่จะส่งมอบ',
      //statusPrefix: 'Your campaign is ',
      statusPrefix: 'แคมเปญของคุณคือ ',
      //successfulPaymentParagraph1: 'Payment has been reserved and your campaign has been submitted for review.',
      successfulPaymentParagraph1: 'จองการชำระเงินแล้วและแคมเปญของคุณถูกส่งไปตรวจสอบแล้ว',
      //successfulPaymentParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      successfulPaymentParagraph2: 'เมื่อแคมเปญของคุณผ่านการตรวจสอบแคมเปญจะแสดงโฆษณาของคุณ!',
      prepay: {
        //stoppedParagraph1: 'Your campaign has been stopped.',
        stoppedParagraph1: 'แคมเปญของคุณหยุดแล้ว',
        //stoppedParagraph2: 'Payment will be taken from your PayPal account and a report generated for your campaign.',
        stoppedParagraph2: 'การชำระเงินจะถูกหักออกจากบัญชี PayPal ของคุณและรายงานที่สร้างขึ้นสำหรับแคมเปญของคุณ',
      },
      manual: {
        //stoppedParagraph1: 'Your campaign has been stopped.',
        stoppedParagraph1: 'แคมเปญของคุณหยุดแล้ว',
        //stoppedParagraph2: 'Payment will be taken from your PayPal account and a report generated for your campaign.',
        stoppedParagraph2: 'การชำระเงินจะถูกหักออกจากบัญชี PayPal ของคุณและรายงานที่สร้างขึ้นสำหรับแคมเปญของคุณ',
      },
      //submittedParagraph1: 'Your campaign has been submitted for review.',
      submittedParagraph1: 'แคมเปญของคุณถูกส่งเข้ารับการตรวจสอบแล้ว',
      //submittedParagraph2: 'Once your campaign passes review, it will deliver your advertising!',
      submittedParagraph2: 'เมื่อแคมเปญของคุณผ่านการตรวจสอบแคมเปญจะแสดงโฆษณาของคุณ!',
      //unexpectedPaymentParagraph1: 'Your payment reservation did not proceed as planned.',
      unexpectedPaymentParagraph1: 'การจองการชำระเงินของคุณไม่ดำเนินการตามแผนที่วางไว้',
      //unexpectedPaymentParagraph2: 'At this stage, it\'s probably best to copy this campaign and start the process again of creating a payment reservation.'
      unexpectedPaymentParagraph2: 'ในขั้นตอนนี้ควรคัดลอกแคมเปญนี้และเริ่มกระบวนการสร้างการจองการชำระเงินอีกครั้ง',
    },
    save: {
      //button: 'Save Your Progress',
      button: 'บันทึกความคืบหน้าของคุณ',
      //messageTitle: 'Campaign Saved',
      messageTitle: 'บันทึกแคมเปญแล้ว',
      //messageText: 'Your campaign was saved successfully.'
      messageText: 'บันทึกแคมเปญของคุณสำเร็จแล้ว',
    },
    smoothing: {
      //title: 'Ad Delivery Style',
      title: 'รูปแบบการแสดงโฆษณา',
      //true: 'Smooth',
      true: 'ราบลื่น',
      //trueExplanation: 'Your advertisement will deliver smoothly over the life of your campaign.',
      trueExplanation: 'โฆษณาของคุณจะแสดงได้อย่างราบรื่นตลอดอายุแคมเปญของคุณ',
      //trueField: '<strong>Smooth</strong> - deliver your budgeted ad impressions smoothly over the life of your campaign',
      trueField: '<strong>ราบลื่น</strong>: แสดงโฆษณาตามงบประมาณของคุณได้อย่างราบรื่นตลอดอายุแคมเปญของคุณ.',
      //false: 'Fast',
      false: 'รวดเร็ว',
      //falseExplanation: 'Your advertisement will be delivered as quickly as possible.',
      falseExplanation: 'โฆษณาของคุณจะถูกส่งโดยเร็วที่สุด',
      //falseField: '<strong>Fast</strong> - deliver your budgeted ad impressions as quickly as possible'
      falseField: '<strong>รวดเร็ว</strong> - แสดงโฆษณาตามงบประมาณของคุณโดยเร็วที่สุด',
    },
    status: {
      //STOPPED: 'Stopped',
      STOPPED: 'หยุดแล้ว',
      //PROPOSED: 'Drafted',
      PROPOSED: 'ร่าง',
      //SUBMITTED: 'In Review',
      SUBMITTED: 'อยู่ระหว่างตรวจสอบ',
      //NOT_READY: 'Needs Further Review',
      NOT_READY: 'ต้องการตรวจสอบเพิ่มเติม',
      //READY: 'Ready to Deliver',
      READY: 'พร้อมส่ง',
      //REJECTED: 'Rejected',
      REJECTED: 'ถูกปฏิเสธ',
      //SUBMISSION_READY: 'Ready for Submission',
      SUBMISSION_READY: 'พร้อมสำหรับการส่ง',
      //RUNNING: 'Delivering',
      RUNNING: 'กำลังแสดง',
      //ENDED: 'Ended'
      ENDED: 'สิ้นสุดแล้ว',
    },
    //statsHeading: 'Delivery Statistics',
    statsHeading: 'สถิติการจัดส่ง',
    stop: {
      //button: 'Stop Campaign Delivery',
      button: 'หยุดการแสดงแคมเปญ',
      //cancelButton: 'Cancel Campaign Stop',
      cancelButton: 'ยกเลิกแคมเปญ',
      //confirmButton: 'Confirm Campaign Stop',
      confirmButton: 'ยืนยันการหยุดแคมเปญ',
      //failParagraph1: 'We encountered a problem and were not able to stop your campaign.',
      failParagraph1: 'เราพบปัญหาและไม่สามารถหยุดแคมเปญของคุณได้',
      //failParagraph2: 'Please try stopping your campaign again, and if the problem persists, let us know.',
      failParagraph2: 'โปรดลองหยุดแคมเปญของคุณอีกครั้งและหากปัญหายังคงมีอยู่โปรดแจ้งให้เราทราบ',
      prepay: {
        //info1: 'Stopping a campaign cannot be reversed, although any campaign can be copied and the new campaign delivered.',
        info1: 'การหยุดแคมเปญไม่สามารถย้อนกลับได้แม้ว่าแคมเปญใด ๆ จะสามารถคัดลอกและแคมเปญใหม่ได้',
        //info2: 'Upon stopping your campaign, either the minimum fee of {{money}} or your current advertising spend will be taken from your PayPal account, whichever figure is higher.',
        info2:
          'เมื่อหยุดแคมเปญของคุณอาจมีค่าธรรมเนียมขั้นต่ำ {{money}} หรือค่าโฆษณาปัจจุบันของคุณจะถูกหักออกจากบัญชี PayPal ของคุณแล้วแต่จำนวนใดจะสูงกว่า',
        info2NoMinFee: 'แคมเปญจะถูกเรียกเก็บเงินตามการโพสต์ที่ทำไว้แล้ว',
      },
      manual: {
        //info1: 'Stopping a campaign cannot be reversed, although any campaign can be copied and the new campaign delivered.',
        info1: 'การหยุดแคมเปญไม่สามารถย้อนกลับได้แม้ว่าแคมเปญใด ๆ จะสามารถคัดลอกและแคมเปญใหม่ได้',
        //info2: 'Upon stopping your campaign, either the minimum fee of {{money}} or your current advertising spend will be taken from your PayPal account, whichever figure is higher.',
        info2:
          'เมื่อหยุดแคมเปญของคุณอาจมีค่าธรรมเนียมขั้นต่ำ {{money}} หรือค่าโฆษณาปัจจุบันของคุณจะถูกหักออกจากบัญชี PayPal ของคุณแล้วแต่จำนวนใดจะสูงกว่า',
        info2NoMinFee: 'แคมเปญจะถูกเรียกเก็บเงินตามการโพสต์ที่ทำไว้แล้ว',
      },
      //intro: 'If at any time you wish to stop your campaign, you may do so below.'
      intro: 'สามารถหยุดแคมเปญได้ทุกเมื่อโดยใช้ปุ่มด้านล่าง',
    },
    validationProblemsPresent: `
    <p>มีปัญหาในการตรวจสอบความถูกต้องกับแคมเปญ</p>
    <p>เมื่อแก้ไขปัญหาการตรวจสอบความถูกต้องแล้วเราสามารถดำเนินการจองการชำระเงินได้</p>
    <p>จากนั้นแคมเปญจะถูกส่งไปตรวจสอบและเปิดใช้งานเมื่อได้รับการอนุมัติ</p>
  `,
  },
  emailPreferences: {
    //CREATIVE_REJECTED: '<strong>Adjustments required:</strong> a reviewer requests a change to my campaign',
    CREATIVE_REJECTED: '<strong> ต้องมีการปรับเปลี่ยน </strong>: ผู้ตรวจสอบขอเปลี่ยนแปลงแคมเปญของฉัน',
    //REPORT_COMPLETE: '<strong>Campaign report is ready:</strong> my campaign has finished and its performance report has been generated',
    REPORT_COMPLETE: '<strong> รายงานแคมเปญพร้อมแล้ว: </strong> แคมเปญของฉันเสร็จสิ้นและมีการสร้างรายงานประสิทธิภาพ',
    //LINE_ITEM_RUNNING: '<strong>Campaign is delivering:</strong> my campaign has passed review and is now delivering impressions'
    LINE_ITEM_RUNNING: '<strong> แคมเปญกำลังแสดงผล: </strong> แคมเปญของฉันได้ผ่านการตรวจสอบและกำลังแสดงผล',
  },
  media: {
    gif: 'GIF',
    png: 'PNG',
    svg: 'SVG',
    jpg: 'JPG',
  },
  chart: {
    countType: {
      singular: 'แนวโน้ม',
      cumulative: 'ยอดรวมสะสม',
    },
    yAxisOption: {
      shared: 'แผนก',
      multiple: 'การสะสม',
    },
    dateOption: {
      //last1Hour: 'Last hour',
      last1Hour: 'ชั่วโมงสุดท้าย',
      //last12Hours: 'Last 12 hours',
      last12Hours: '12 ชั่วโมงล่าสุด',
      //last7Days: 'Last 7 days',
      last7Days: '7 วันที่ผ่านมา',
      //last30Days: 'Last 30 days',
      last30Days: '30 วันที่ผ่านมา',
      //today: 'Today',
      today: 'วันนี้',
      //yesterday: 'Yesterday',
      yesterday: 'เมื่อวานนี้',
      //campaignDays: 'Running dates: {{startDate}}-{{endDate}}'
      campaignDays: 'วันที่ทำงาน: {{startDate}}-{{endDate}}',
    },
  },
  layout: {
    fieldSummary: {
      image: 'ภาพ',
      imagePlural: 'ภาพ',
      url: 'URL',
      urlPlural: 'URLs',
      code: 'ฟิลด์รหัส',
      codePlural: 'ฟิลด์รหัส',
      text: 'ช่องข้อความ',
      textPlural: 'ช่องข้อความ',
      option: 'ตัวเลือก',
      optionPlural: 'ตัวเลือก',
    },
  },
  label: {
    //copy: 'Copy',
    copy: 'สำเนา',
    //copyLineItem: 'Copy Campaign',
    copyLineItem: 'คัดลอกแคมเปญ',
    //paginationAllShown: 'Showing all <strong>{{total}}</strong> results',
    paginationAllShown: 'กำลังแสดงทั้งหมด <strong>{{total}}</strong>',
    //paginationWithPages: 'Showing <strong>{{start}}-{{end}}</strong> of <strong>{{total}}</strong> results',
    paginationWithPages: 'กำลังแสดง <strong>{{start}}-{{end}}</strong> ของ <strong>{{total}}</strong>',
    //paginationLoading: 'Showing <span class="fa fa-spin fa-cog"></span> results'
    paginationLoading: 'กำลังแสดง <span class="fa fa-spin fa-cog"></span>',
  },
  report: {
    //downloadXlsComma: 'Download as XLS (decimal comma)',
    downloadXlsComma: 'ดาวน์โหลดเป็นไฟล์ XLS (avec virgule)',
    //downloadXlsPoint: 'Download as XLS (decimal point)',
    downloadXlsPoint: 'ดาวน์โหลดเป็นไฟล์ XLS (avec point)',
    fieldCountPlural: '{{count}} Champs',
    fieldCount: '{{count}} Champ',
    //report: 'Report',
    report: 'รายงาน',
    //reportPlural: 'Reports',
    reportPlural: 'รายงาน',
    noReport: `
    <h3>ไม่พบรายงาน</h3>
    <p>ทันทีที่แคมเปญสิ้นสุดลงรายงานจะถูกสร้างขึ้น</p>
    <p>นี่คือรายการรายงานทั้งหมดที่สร้างขึ้นสำหรับแคมเปญที่แจกจ่ายไปแล้ว</p>
  `,
    status: {
      //PENDING: 'Pending',
      PENDING: 'รอดำเนินการ',
      //PROCESSING: 'Processing',
      PROCESSING: 'กำลังประมวลผล',
      //COMPLETED: 'Completed',
      COMPLETED: 'เสร็จเรียบร้อย',
      //REJECTED: 'Rejected',
      REJECTED: 'ถูกปฏิเสธ',
      //FAILED: 'Failed',
      FAILED: 'ล้มเหลว',
      //CANCELLED: 'Cancelled',
      CANCELLED: 'ยกเลิก',
      //QUEUED: 'Queued',
      QUEUED: 'อยู่ในคิว',
      //TIMED_OUT: 'Timed Out'
      TIMED_OUT: 'หมดเวลา',
    },
    field: {
      rendered: 'แสดง',
      visibles: 'การแสดงผลที่มองเห็นได้',
      visibility: 'การมองเห็น',
      viewables: 'การแสดงผลที่มองเห็นได้',
      viewability: 'การมองเห็น',
      uniqueUsers: 'ผู้เยี่ยมชมที่ไม่ซ้ำกัน',
      clicks: 'คลิก',
      ctr: 'CTR',
      cost: 'ต้นทุน',
    },
  },
  country: {
    TH: 'Thai',
  },
  errors: {
    standard: {
      unregistered: {
        //        title: 'Your Account is Not Registered Here',
        title: 'บัญชีของคุณไม่ได้ลงทะเบียนที่นี่',
        //        p1: 'You are not registered to this advertising portal.',
        p1: 'คุณไม่ได้ลงทะเบียนกับพอร์ทัลโฆษณานี้',
        p2: '',
        //        p2LinkText: 'Register to this advertising portal',
        p2LinkText: 'ลงทะเบียนกับพอร์ทัลโฆษณานี้',
        //        p2Suffix: '&nbsp;before proceeding.',
        p2Suffix: '&nbsp;ก่อนดำเนินการต่อ',
        //        p3: 'Alternatively,&nbsp;',
        p3: 'หรืออีกทางหนึ่ง&nbsp;',
        //        p3LinkText: 'sign in with another account',
        p3LinkText: 'ลงชื่อเข้าใช้ด้วยบัญชีอื่น',
        p3Suffix: '',
      },
      //dataNotFoundTitle: 'Missing Data',
      dataNotFoundTitle: 'ไม่มีข้อมูล',
      //dataNotFoundParagraph1: 'We could not find any data at the following location: <strong>{{link}}</strong>.',
      dataNotFoundParagraph1: 'เราไม่พบข้อมูลใด ๆ ในตำแหน่งต่อไปนี้: <strong>{{link}}</strong>.',
      //dataNotFoundParagraph2: 'This could have happened because the underlying data is no longer available to you.',
      dataNotFoundParagraph2: 'สิ่งนี้อาจเกิดขึ้นเนื่องจากคุณไม่สามารถใช้ข้อมูลพื้นฐานได้อีกต่อไป',
      //dataNotFoundParagraph3: 'Access any of the links in the menu to get back on track.',
      dataNotFoundParagraph3: 'เข้าไปที่ลิงค์ใดก็ได้ในเมนูเพื่อกลับมาใช้งานได้',
      //pageNotFoundTitle: 'Page Not Found',
      pageNotFoundTitle: 'ไม่พบหน้านี้',
      //pageNotFoundParagraph1: 'We could not find the page you requested.',
      pageNotFoundParagraph1: 'เราไม่พบหน้าที่คุณร้องขอ',
      //pageNotFoundParagraph2: 'Access any of the links in the menu to get back on track.',
      pageNotFoundParagraph2: 'เข้าไปที่ลิงค์ใดก็ได้ในเมนูเพื่อกลับมาใช้งานได้',
      //objectNotFoundTitle: 'Object Not Found',
      objectNotFoundTitle: 'ไม่พบวัตถุ',
      //objectNotFoundParagraph1: 'We could not find any data for the following id: <strong>{{id}}</strong>.',
      objectNotFoundParagraph1: 'เราไม่พบข้อมูลใด ๆ สำหรับสิ่งต่อไปนี้คือ: <strong>{{id}}</strong>.',
      //objectNotFoundParagraph2: 'This could have happened because the object is no longer available to you.',
      objectNotFoundParagraph2: 'สิ่งนี้อาจเกิดขึ้นเนื่องจากคุณไม่สามารถใช้วัตถุได้อีกต่อไป',
      //objectNotFoundParagraph3: 'Access any of the links in the menu to get back on track.'
      objectNotFoundParagraph3: 'เข้าไปที่ลิงค์ใดก็ได้ในเมนูเพื่อกลับมาใช้งานได้',
    },
    api: {
      //stats: 'We were unable to retrieve statistics from the API. If the problem persists, please contact support.',
      stats: 'เราไม่สามารถเรียกข้อมูลสถิติจาก API หากปัญหายังคงมีอยู่โปรดติดต่อฝ่ายสนับสนุน',
      //title: 'Sorry, there appears to be a problem',
      title: 'ดูเหมือนจะมีปัญหาเราต้องขออภัย',
      noConnection: {
        //title: "Connection Loss",
        title: 'การสูญเสียการเชื่อมต่อ',
        //noConnection: "<p>We've lost connection to our back-end server. If the problem persists, please contact support.</p>"
        noConnection: '<p>เราขาดการเชื่อมต่อกับเซิร์ฟเวอร์ส่วนหลังของเรา หากปัญหายังคงมีอยู่โปรดติดต่อฝ่ายสนับสนุน</p>',
      },
      //generic: "<p>We're experiencing problems interacting with our back-end system. If the problem persists, please contact support.</p>",
      generic: '<p>เรากำลังประสบปัญหาในการโต้ตอบกับระบบส่วนหลังของเรา หากปัญหายังคงมีอยู่โปรดติดต่อฝ่ายสนับสนุน</p>',
      unauthorised: {
        //title: "Unauthorised Operation",
        title: 'การดำเนินการโดยไม่ได้รับอนุญาต',
        //unauthorised: "<p>You do not have the permissions to perform the operation you requested.</p>"
        unauthorised: '<p>คุณไม่มีสิทธิ์ในการดำเนินการตามที่คุณร้องขอ</p>',
      },
    },
  },
  field: {
    advertiserName: 'ชื่อผู้ลงโฆษณา',
    address1: 'ที่อยู่',
    city: 'เมือง',
    postCode: 'รหัสไปรษณีย์',
    state: 'รัฐ',
    country: 'ประเทศ',
    invoiceEmail: 'อีเมลสำหรับการออกใบแจ้งหนี้',
    phoneNumber: 'โทรศัพท์',
    required: 'บังคับ',
    malformedEmail: 'ต้องเป็นอีเมลที่ถูกต้อง',
    cpm: 'ค่าใช้จ่ายต่อการแสดง 1,000 ครั้ง (CPM)',
    //selfServiceBudget: 'Budget',
    selfServiceBudget: 'งบประมาณ',
    email: 'อีเมล์',
    //emailPreferences: 'Email Preferences',
    emailPreferences: 'การตั้งค่าอีเมล',
    //password: 'Password',
    password: 'รหัสผ่าน',
    //confirmPassword: 'Confirm Password',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    //locale: 'Locale',
    locale: 'สถานที่',
    //smoothing: 'Ad Delivery Style',
    smoothing: 'รูปแบบการแสดงโฆษณา',
    //startDate: 'Start',
    startDate: 'เริ่ม',
    status: 'สถานะ',
    userState: 'สถานะ',
    //displayName: 'Display Name',
    displayName: 'ชื่อที่แสดง',
    //displayNameHint: 'The display name is used to refer to you throughout the application',
    displayNameHint: 'ชื่อที่แสดงใช้เพื่ออ้างถึงคุณตลอดทั้งแอปพลิเคชัน',
    //endDate: 'End',
    endDate: 'สิ้นสุด',
    //executionStartTime: 'Run Date',
    executionStartTime: 'วันที่เริ่มต้น',
    //name: 'Name'
    name: 'ชื่อ',
  },
  settings: {
    //settingsPlural: 'Settings',
    settingsPlural: 'การตั้งค่า',
    //about: 'About',
    about: 'เกี่ยวกับ',
    //signOut: 'Sign Out'
    signOut: 'ออกจากระบบ',
  },
  signIn: {
    coupon: {
      code: 'รหัสคูปอง',
      checkCode: 'ตรวจสอบรหัสคูปอง',
    },
    //accountLocked: 'Your account has been locked due to too many failed login attempts. You have been sent an email with which you can reset your password, unlock your account and sign in.',
    accountLocked:
      'บัญชีของคุณถูกล็อกเนื่องจากการพยายามเข้าสู่ระบบล้มเหลวหลายครั้งเกินไป คุณได้รับอีเมลที่คุณสามารถรีเซ็ตรหัสผ่านปลดล็อกบัญชีของคุณและลงชื่อเข้าใช้',
    //alreadyUser: 'Already a user?',
    alreadyUser: 'เป็นผู้ใช้อยู่แล้ว?',
    //confirmPasswordMismatch: "The passwords you entered do not match. Ensure you've entered the same password both times.",
    confirmPasswordMismatch: 'รหัสผ่านที่คุณป้อนไม่ตรงกัน ตรวจสอบว่าคุณได้ป้อนรหัสผ่านเดียวกันทั้งสองครั้ง',
    //differentAccount: 'Sign in with a different account',
    differentAccount: 'ลงชื่อเข้าใช้ด้วยบัญชีอื่น',
    //emailFirst: "Your account's email address must be entered before its password can be reset.",
    emailFirst: 'ต้องป้อนที่อยู่อีเมลของบัญชีของคุณก่อนจึงจะสามารถรีเซ็ตรหัสผ่านได้',
    //emailRequired: "We need your account's email address before we can sign you in.",
    emailRequired: 'เราต้องการที่อยู่อีเมลของคุณเพื่อเข้าสู่ระบบ',
    //expiredLinkExplanation: 'It looks like you clicked on an invalid or expired password reset link. Please try resetting your password again.',
    expiredLinkExplanation:
      'ดูเหมือนว่าคุณคลิกลิงก์รีเซ็ตรหัสผ่านที่ไม่ถูกต้องหรือหมดอายุ โปรดลองรีเซ็ตรหัสผ่านของคุณอีกครั้ง',
    //forgotPassword: 'Forgot your password?',
    forgotPassword: 'ลืมรหัสผ่านหรือไม่?',
    //humanConfirmationFailure: "There's a problem obtaining registration confirmation for your account. Try again and let us know if the problem persists.",
    humanConfirmationFailure:
      'มีปัญหาในการรับการยืนยันการลงทะเบียนสำหรับบัญชีของคุณ ลองอีกครั้งและแจ้งให้เราทราบหากปัญหายังคงมีอยู่',
    //nameRequired: "We need a name with which to register you as a new user.",
    nameRequired: 'เราต้องการชื่อเพื่อใช้ในการลงทะเบียนคุณเป็นผู้ใช้ใหม่',
    //passwordResetTooMuchHeading: 'Too Many Password Resets',
    passwordResetTooMuchHeading: 'รีเซ็ตรหัสผ่านมากเกินไป',
    //passwordResetTooMuchExplanation: 'You have tried to reset your password too often in a short space of time. You cannot reset your password for the next little while.',
    passwordResetTooMuchExplanation:
      'คุณพยายามรีเซ็ตรหัสผ่านบ่อยเกินไปในช่วงเวลาสั้น ๆ คุณไม่สามารถรีเซ็ตรหัสผ่านของคุณในอีกสักครู่ ',
    //passwordResetEmailSentHeading: 'Password Reset Email Sent',
    passwordResetEmailSentHeading: 'ส่งอีเมลรีเซ็ตรหัสผ่านแล้ว',
    //passwordResetEmailSentExplanation: 'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.',
    passwordResetEmailSentExplanation:
      'ตรวจสอบอีเมลของคุณเพื่อดูลิงก์สำหรับรีเซ็ตรหัสผ่านของคุณ หากไม่ปรากฏขึ้นภายในสองสามนาทีให้ตรวจสอบโฟลเดอร์สแปมของคุณ ',
    //passwordHint: 'Your password must be at least 8 characters in length',
    passwordHint: 'รหัสผ่านของคุณต้องมีความยาวอย่างน้อย 8 อักขระ',
    //passwordLinkExpired: 'Your reset password link has expired. Enter your email address to generate another link.',
    passwordLinkExpired: 'ลิงก์รีเซ็ตรหัสผ่านของคุณหมดอายุแล้ว ป้อนที่อยู่อีเมลของคุณเพื่อสร้างลิงก์อื่น',
    //passwordRequired: "Supply a password to sign in.",
    passwordRequired: 'ระบุรหัสผ่านเพื่อลงชื่อเข้าใช้',
    //passwordSimple: 'The supplied password is too easily guessed and should be something less common.',
    passwordSimple: 'รหัสผ่านที่ให้มานั้นเดาได้ง่ายเกินไปและน่าจะไม่ค่อยธรรมดา',
    regError: 'ตรวจพบปัญหาการตรวจสอบความถูกต้อง ดูรายละเอียดด้านบน',
    //registerAsUserHeading: 'Register as a User',
    registerAsUserHeading: 'ลงทะเบียนเป็นผู้ใช้',
    //registerAsUserButton: 'Register',
    registerAsUserButton: 'ลงทะเบียน',
    //registerEmailRequired: 'We need an email address to register you as a new user.',
    registerEmailRequired: 'เราต้องการที่อยู่อีเมลเพื่อลงทะเบียนคุณเป็นผู้ใช้ใหม่',
    //registerNewUser: 'Register as a new user',
    registerNewUser: 'ลงทะเบียนเป็นผู้ใช้ใหม่',
    //registerSuccessHighlight: 'You have successfully registered as a new user!',
    registerSuccessHighlight: 'คุณได้ลงทะเบียนเป็นผู้ใช้ใหม่เรียบร้อยแล้ว!',
    //registerSuccessParagraph1: 'You have been sent an email with more information.',
    registerSuccessParagraph1: 'คุณได้รับอีเมลพร้อมข้อมูลเพิ่มเติม',
    //registerSuccessParagraph2: 'Click on the link in your email to create your password for your account and sign in.',
    registerSuccessParagraph2: 'คลิกที่ลิงค์ในอีเมลของคุณเพื่อสร้างรหัสผ่านสำหรับบัญชีของคุณและลงชื่อเข้าใช้',
    //resetPasswordHeading: 'Reset Your Password',
    resetPasswordHeading: 'รีเซ็ตรหัสผ่านของคุณ',
    //resetPasswordButton: 'Send Password Reset Email',
    resetPasswordButton: 'ส่งอีเมลรีเซ็ตรหัสผ่าน',
    //resetPasswordInfo: 'Enter your email address and we will send you a link to reset your password.',
    resetPasswordInfo: 'ป้อนที่อยู่อีเมลของคุณและเราจะส่งลิงก์เพื่อรีเซ็ตรหัสผ่านของคุณ',
    registerTerms: `การเลือก ลงทะเบียน นั้นหมายถึงคุณได้อ่านและยอมรับข้อกำหนดและเงื่อนไขของเราแล้ว`,
    //signInButton: 'Sign in',
    signInButton: 'เข้าสู่ระบบ',
    //signInHeading: 'Sign in',
    signInHeading: 'เข้าสู่ระบบ',
    //updatePasswordHeading: 'Update Your Password',
    updatePasswordHeading: 'อัปเดตรหัสผ่านของคุณ',
    //updatePasswordButton: 'Update Password'
    updatePasswordButton: 'อัปเดตรหัสผ่าน',
  },
  adBlockerParagraph1: 'ตรวจพบตัวบล็อกโฆษณา แอพนี้ไม่ทำงานกับตัวบล็อกโฆษณาที่ใช้งานอยู่',
  adBlockerParagraph2: 'โปรดปิดการใช้งาน AdBlocker เพื่อให้สามารถใช้แอพได้',
  adBlockerTurnOff: 'ปิดคำเตือนนี้',
  validation: {
    //required: 'Required',
    required: 'จำเป็น',
    //pattern: "Text must be {{minLength}}-{{maxLength}} characters long.",
    pattern: 'ข้อความต้องยาว {{minLength}} - {{maxLength}} อักขระ.',
    //maxSize: "The uploaded file is {{actual}}kb in size, which is greater than the {{maximum}}kb maximum",
    maxSize: 'ไฟล์ที่อัปโหลดคือ {{réel}} kb, ซึ่งมากกว่าค่าสูงสุดที่ได้รับอนุญาตของ {{maximum}} kb',
    //fileType: "The uploaded file must be one of the following types: {{allowedTypes}}",
    fileType: 'ไฟล์ที่อัปโหลดต้องเป็นประเภทใดประเภทหนึ่งต่อไปนี้: {{allowedTypes}}',
    //minPrice: "The minimum value is {{minValue}}",
    minPrice: 'ค่าต่ำสุดคือ {{minValue}}',
    maxPrice: 'ค่าสูงสุดคือ {{maxValue}}',
    number: 'หมายเลขไม่ถูกต้อง',
    //url: "The URL is not in a valid form.",
    url: 'URL ไม่อยู่ในรูปแบบที่ถูกต้อง',
    //endDateStartDate: 'The end date must be after the start date.',
    endDateStartDate: 'วันที่สิ้นสุดต้องอยู่หลังวันที่เริ่มต้น',
    email: 'คุณต้องมีที่อยู่อีเมลที่ถูกต้อง',
    fileUploadFail: 'ไม่สามารถอัปโหลดไฟล์ต่อไปนี้:',
    fileUploadTimeout:
      'การอัปโหลดล้มเหลว ลองอัปโหลดอีกครั้งหรือถ้าเป็นไปได้ให้รีเฟรชหน้าเพื่อดูว่ามีการอัปโหลดทรัพยากรใดบ้าง',
    anonymousFileSizeLarge: 'ไฟล์ต้องมีขนาดเล็กกว่า {{fileSize}} kb เมื่อคุณไม่ได้ลงชื่อเข้าใช้',
    anonymousFileSizeSuperLarge:
      'การอัปโหลดไฟล์ล้มเหลวเนื่องจากสาเหตุที่ไม่ระบุมากกว่าที่เป็นไปได้เนื่องจากไฟล์มีขนาดใหญ่เกินกว่าที่เราจะวิเคราะห์ได้ ลงชื่อเข้าใช้เพื่อเพิ่มขีด จำกัด การอัปโหลดไฟล์และมีการวิเคราะห์ที่ดีขึ้น',
    error: {
      //validation: '<strong>Invalid submission:</strong> Please check the details above and try again.',
      validation: '<strong> การส่งไม่ถูกต้อง: </strong> โปรดตรวจสอบรายละเอียดด้านบนและลองอีกครั้ง.',
      //'must.be.greater.than.or.equal': '{{value}} must be greater than {{minValue}}.',
      'must.be.greater.than.or.equal': '{{value}} ต้องมากกว่า {{minValue}}.',
      //'non.empty': 'Required'
      'non.empty': 'จำเป็น',
      'auth.failed': 'การเชื่อมต่อล้มเหลวไม่สามารถกำหนดข้อมูลที่ป้อนให้กับผู้ใช้',
      'sign.in.blocked': 'ผู้ใช้ไม่ได้ลงทะเบียนกรุณาเข้าสู่ระบบโดยใช้ url ต่อไปนี้{{uiAddress}}.',
    },
    warning: {
      'creative.invalid.product.dimensions': 'โปรดเลือกขนาดโฆษณาที่อนุญาต',
      //"lineItem.start.date.before.end.date": "The campaign's start date must be before its end date",
      'lineItem.start.date.before.end.date': 'วันที่เริ่มต้นแคมเปญต้องอยู่ก่อนวันที่สิ้นสุด',
      //"lineItem.end.date.before.today": "The campaign's end date must be after the current date and time",
      'lineItem.end.date.before.today': 'วันที่สิ้นสุดของแคมเปญต้องช้ากว่าวันนี้',
      //"lineItem.invalid.budget.enddate": "The campaign must end within 30 days"
      'lineItem.invalid.budget.enddate': 'แคมเปญต้องสิ้นสุดภายใน 100 วัน',
      'payment.gateway.error': 'มีปัญหากับการเชื่อมต่อกับ PayPal',
    },
  },
};
