import {Component, Input, OnInit} from '@angular/core';
import {
  ChoiceComponentForm
} from '@app/@core/advertising/campaign/layout/choice-comoponent-form';
import {ChoiceComponent} from '@app/@core/@models/advertising/layout/choice-component.model';
import {FormControl} from '@angular/forms';
import {ValidationWarningMessage} from '@app/@core/@models/advertising/validation-warning-message.model';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-choice-layout-component[choiceComponentForm][serverValidationWarnings]',
  templateUrl: './choice-layout-component.component.html',
  styleUrls: ['./choice-layout-component.component.scss']
})
export class ChoiceLayoutComponentComponent implements OnInit {
  @Input() choiceComponentForm!: ChoiceComponentForm;
  @Input() serverValidationWarnings?: ValidationWarningMessage[];
  uuid: string = Uuid.generate();

  constructor(public i18nFormat: I18nFormatService) { }

  ngOnInit(): void {
  }

  choiceComponent(): ChoiceComponent {
    return this.choiceComponentForm.value.layoutComponent as ChoiceComponent;
  }

  choiceControl(): FormControl<string> {
    return this.choiceComponentForm.controls.componentValue;
  }

  isInvalid(): boolean {
    return this.choiceControl().invalid || this.showServerValidationWarning();
  }

  showServerValidationWarning(): boolean {
    return this.choiceControl().pristine && !!this.serverValidationWarnings?.length;
  }

}
