<h3>{{ 'campaign.layout.title' | translate }}</h3>
<ngb-alert *ngIf='previouslySelectedLayoutNotAvailable()' type='warning'
           [dismissible]='false'>{{ 'campaign.layout.missing' | translate }}</ngb-alert>
<div class='selectionContainer'>
  <div [formGroup]='advertDraft.advertForm' class='row'>
    <div *ngFor='let layout of layouts;' class='col-sm-4 thumbnailColumn'>
      <div class='thumbnailEntry' *ngIf="'layout_' + layout.id + id as inputId" (click)='advertDraft.advertForm.controls.layoutId.setValue(layout.id);onLayoutChange(layout.id)'
           [ngClass]="{'itemSelected': layout.id === advertDraft.advertForm.controls.layoutId.value}">
        <input
          type='radio'
          class='btn-check'
          [id]="inputId"
          [name]="'name-' + layout.id + id"
          [value]='layout.id'
          (change)='onLayoutChange(layout.id)'
        />
        <label class='caption'>
          <div class='float-end selectorIconContainer'>
            <span *ngIf='layout.id === advertDraft.advertForm.controls.layoutId.value'
                  class='selectedIcon fa-regular fa-square-check'></span>
            <span *ngIf='layout.id !== advertDraft.advertForm.controls.layoutId.value'
                  class='unselectedIcon fa-regular fa-square'></span>
          </div>
          <h3>
            {{ layout.name }}
          </h3>
          <img *ngIf='layout.thumbnailUrl' [src]='layout.thumbnailUrl' class='layoutThumbnail' alt='' />
          <p *ngIf='layout.description'>{{ layout.description }}</p>
          <p>
            <ng-container *ngFor='let entry of getLayoutSummary(layout); let isLast=last'>
              <span>{{ entry }}</span><span *ngIf='!isLast'>,</span>
            </ng-container>
          </p>
        </label>
      </div>
    </div>
  </div>
</div>
