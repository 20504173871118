declare var hcaptcha: any

import {Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {ConfigService} from '@app/@core/@config/config.service';
import {RegisterForm} from '@app/sign-in/register/@form/register-form';
import {I18nLangService} from '@app/@i18n/services/i18n-lang.service';

@Component({
  selector: 'app-captcha-field[registerForm][showValidation]',
  templateUrl: './captcha-field.component.html',
  styleUrls: ['./captcha-field.component.scss']
})
export class CaptchaFieldComponent implements OnInit {
  @Input() registerForm!: RegisterForm;
  @Input() showValidation!: boolean;
  @ViewChild('captchaContainer') el!: ElementRef;
  errorKey?: string;

  constructor(private configService: ConfigService,
              private i18nLang: I18nLangService,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    let sitekey = location.host.startsWith('localhost') ? '10000000-ffff-ffff-ffff-000000000001' : this.configService.getSiteKey();
    this.loadHCaptcha(sitekey);
  }

  isInvalid(): boolean {
    return this.showValidation && this.registerForm.controls.hCaptchaResponse.invalid;
  }

  private loadHCaptcha(sitekey: string) {
    const script = document.createElement('script');
    script.src = 'https://hcaptcha.com/1/api.js?render=explicit';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      hcaptcha.render(this.el.nativeElement, {
        sitekey: sitekey,
        hl: this.i18nLang.getLanguage(),
        callback: (token: string) => this.programmaticChangeHCaptchaResponseValue(token),
        'chalexpired-callback': () => this.programmaticChangeHCaptchaResponseValue(''),
        'expired-callback': () => this.programmaticChangeHCaptchaResponseValue(''),
        'error-callback': (_: any) => this.errorKey = 'signIn.humanConfirmationFailure'
      });
    };
    script.onerror = () => {
      this.errorKey = 'signIn.humanConfirmationFailure';
    };
    document.head.appendChild(script);
  }

  private programmaticChangeHCaptchaResponseValue(token: string) {
    // run in ng zone otherwise angular doesn't detect our changes because they happened in hcaptcha callback
    this.ngZone.run(() => {
      const control = this.registerForm.controls.hCaptchaResponse;
      control.setValue(token);
      control.markAsDirty();
    })
  }
}
