import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UrlComponent} from '@app/@core/@models/advertising/layout/url-component.model';
import {UrlComponentForm} from '@app/@core/advertising/campaign/layout/url-compnent-form';
import {ValidationWarningMessage} from '@app/@core/@models/advertising/validation-warning-message.model';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-url-layout-component[urlComponentForm][serverValidationWarnings]',
  templateUrl: './url-layout-component.component.html',
  styleUrls: ['./url-layout-component.component.scss']
})
export class UrlLayoutComponentComponent implements OnInit, OnChanges {
  @Input() urlComponentForm!: UrlComponentForm
  @Input() serverValidationWarnings?: ValidationWarningMessage[];
  uuid: string = Uuid.generate();

  constructor(public i18nFormat: I18nFormatService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  urlComponent(): UrlComponent {
    return this.urlComponentForm.value.layoutComponent as UrlComponent;
  }

  urlControl(): FormControl<string> {
    return this.urlComponentForm.controls.componentValue;
  }

  isInvalid(): boolean {
    return this.urlControl().invalid || this.showServerValidationWarning();
  }

  showServerValidationWarning(): boolean {
    return this.urlControl().pristine && !!this.serverValidationWarnings?.length;
  }

  prefixHttps(input: string) {
    if (input && !/^(https?):\/\//i.test(input) && 'https://'.indexOf(input) === -1 && 'http://'.indexOf(input) === -1) {
      this.urlControl().setValue('https://' + input);
    }
  }
}
