import {Injectable} from '@angular/core';
import {Locale} from '@app/@core/@models/common/locale';

interface LocaleMapping {
  javaLocales: Locale[]
  angularLocaleId: string
}

@Injectable({
  providedIn: 'root'
})
export class LocaleMappingService {

  localeMappings: LocaleMapping[] = [
    { javaLocales: ['ar'], angularLocaleId: 'ar' },
    { javaLocales: ['ar_EG'], angularLocaleId: 'ar-EG' },
    { javaLocales: ['ar_JO'], angularLocaleId: 'ar-JO' },
    { javaLocales: ['my'], angularLocaleId: 'my' },
    { javaLocales: ['da'], angularLocaleId: 'da' },
    { javaLocales: ['en', 'en_US'], angularLocaleId: 'en' },
    { javaLocales: ['en_GB'], angularLocaleId: 'en-GB' },
    { javaLocales: ['en_CA'], angularLocaleId: 'en-CA' },
    { javaLocales: ['en_AU'], angularLocaleId: 'en-AU'},
    { javaLocales: ['en_PH'], angularLocaleId: 'en-PH'},
    { javaLocales: ['et'], angularLocaleId: 'et'},
    { javaLocales: ['fr'], angularLocaleId: 'fr'},
    { javaLocales: ['fi'], angularLocaleId: 'fi'},
    { javaLocales: ['de', 'de_DE'], angularLocaleId: 'de'}, // there is no de-DE in angular
    { javaLocales: ['de_AT'], angularLocaleId: 'de-AT'},
    { javaLocales: ['de_CH'], angularLocaleId: 'de-CH'},
    { javaLocales: ['el'], angularLocaleId: 'el'},
    { javaLocales: ['id'], angularLocaleId: 'id'},
    { javaLocales: ['is'], angularLocaleId: 'is'},
    { javaLocales: ['lt'], angularLocaleId: 'lt'},
    { javaLocales: ['lb'], angularLocaleId: 'lb'},
    { javaLocales: ['ms', 'ms_MY'], angularLocaleId: 'ms'}, // no ms-MY in angular
    { javaLocales: ['ms_BN'], angularLocaleId: 'ms-BN'},
    { javaLocales: ['nb'], angularLocaleId: 'nb'},
    { javaLocales: ['no'], angularLocaleId: 'no'},
    { javaLocales: ['pl'], angularLocaleId: 'pl'},
    { javaLocales: ['pt', 'pt_BR'], angularLocaleId: 'pt'}, // no pt-BR in angular
    { javaLocales: ['pt_PT'], angularLocaleId: 'pt-PT'},
    { javaLocales: ['ro'], angularLocaleId: 'ro'},
    { javaLocales: ['ru'], angularLocaleId: 'ru'},
    { javaLocales: ['es', 'es_ES'], angularLocaleId: 'es'}, // no es-ES in angular
    { javaLocales: ['es_AR'], angularLocaleId: 'es-AR'},
    { javaLocales: ['sv', 'sv_SE'], angularLocaleId: 'sv'}, // no sv-SE in angular
    { javaLocales: ['sv_FI'], angularLocaleId: 'sv-FI'},
    { javaLocales: ['th'], angularLocaleId: 'th'},
    { javaLocales: ['fil'], angularLocaleId: 'fil'}, // tl changed to fil
    { javaLocales: ['tr'], angularLocaleId: 'tr'},
  ]

  constructor() { }

  getAngularLocaleId(javaLocale: Locale): string {
    const angularLocaleId = this.localeMappings.find(mapping => mapping.javaLocales.includes(javaLocale))?.angularLocaleId;
    if (!angularLocaleId) {
      throw Error("failed to find angular locale id for java locale " + javaLocale)
    }
    return angularLocaleId;
  }

  getSupportedJavaLocales(): Locale[] {
    return this.localeMappings.flatMap(mapping => mapping.javaLocales)
  }

}
