export const COMPANY_TRANSLATIONS_FR = {
  nkjm: {
    frontPageTopContent: `
        <div style="position:absolute;top: 50px; left: 50px;"><img src="https://assets.adnuntius.com/ZxrPk71X0QRHz9VjoaGyubjZyPRrXl8CMd0SZJMwy7c.png"></div>
    `,
    frontPage: `
      <h2 style="font-size: 35px">La plus grande place de marché pour accéder aux média Français</h2>
      <p><img src="https://www.mediasquare.fr/wp-content/themes/mediasquare/assets/point-gris-little.svg" style="margin: 10px 0;"></p>
      <p style="font-size: 15px; color: #555;">Media Square est une société créée par des éditeurs Français pour faciliter la diffusion de campagnes publicitaires sur leurs sites Internet et Mobile.
Notre mission est de rendre la publicité digitale dans les Medias simple, accessible et efficace.</p>
    `
  }
};
