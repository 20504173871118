<label *ngIf="showLabel" class="form-label">
  <strong>{{ 'campaign.ad.previewTitle' | translate }}</strong>
</label>
<div *ngIf="isUsingExampleData" class="alert alert-warning"
     role="alert">{{ 'campaign.ad.previewWithExample' | translate }}</div>
<div class="form-check">
  <input class="form-check-input" type="checkbox" [checked]="showBorder" [id]="'showBorderCheckbox'+uuid"
         (change)="onShowBorderChanged($event)"/>
  <label class="form-check-label" [for]="'showBorderCheckbox'+uuid">{{'campaign.ad.border' | translate}}</label>
</div>
<ngb-alert *ngIf="stretchy" [dismissible]="false" type="info">
  <p translate="campaign.ad.stretchable"></p>
  <div class="btn-group" role="group">
    <input type="radio" class="btn-check" name="stretchy-choice" [id]="'stretchy-fixed'+uuid" autocomplete="off"
           checked
           (change)="setStretched(false)">
    <label class="btn btn-outline-secondary" [for]="'stretchy-fixed'+uuid"
           translate="campaign.ad.stretchableFixedOption"></label>
    <input type="radio" class="btn-check" name="stretchy-choice" [id]="'stretchy-stretchable'+uuid"
           autocomplete="off"
           (change)="setStretched(true)">
    <label class="btn btn-outline-secondary" [for]="'stretchy-stretchable'+uuid"
           translate="campaign.ad.stretchableStretchableOption"></label>
  </div>
</ngb-alert>
<div *ngIf="layout && creative.width && creative.height"
     class="bg-gradient mb-2 mw-100 overflow-auto" [style.max-height.px]="2000">
  <div class="overflow-auto" style='box-sizing: content-box' [ngClass]="{ previewBorder: showBorder }" [style.width.px]="width" [style.height.px]="height">
      <app-adn-preview-creative-data [creative]="creative"
                                     [layout]="layout"
                                     [assets]="assets"
                                     (updateStretchy)="setStretchy($event)"
                                     (updateIsUsingExampleData)="setUsingExampleDataEventFired($event)"
      ></app-adn-preview-creative-data>
  </div>
</div>
