import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {Product} from '@app/@core/@models/design/product.model';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import {
  ProductFilterEntry,
  ProductFilterOption,
} from '@app/@core/@models/network/network-data.model';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {Coupon} from '@app/@core/@models/design/coupon.model';

@Component({
  selector: 'app-product-selection[campaignDraft]',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductSelectionComponent implements OnInit, OnChanges {
  @Input() campaignDraft!: CampaignDraft;
  filters?: ProductFilterEntry[];
  selectedFilter?: ProductFilterOption;
  selectedProductId?: string;
  products: Product[] = [];

  constructor(public i18nFormatService: I18nFormatService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.campaignDraft.productSelectionData && this.campaignDraft.productSelectionData.data.filter.length > 0) {
      this.filters = this.campaignDraft.productSelectionData.data.filter;
      this.selectedProductId = this.campaignDraft.getProductId();
      if (this.selectedProductId) {
        this.selectedFilter = this.getSelectedFilterOption(this.filters, this.selectedProductId);
      } else {
        this.selectedFilter = undefined;
      }
    } else {
      this.filters = undefined;
      this.selectedFilter = undefined;
      this.selectedProductId = this.campaignDraft.getProductId();
    }
    this.setProductsToDisplay();
  }

  onProductChange(product: Product) {
    this.campaignDraft.productChanged(product);
    this.selectedProductId = product.id;
  }

  handleFilterSelection(filterOption: ProductFilterOption) {
    this.selectedFilter = filterOption;
    this.setProductsToDisplay();
  }

  private setProductsToDisplay() {
    if (!this.filters) {
      this.products = this.campaignDraft.products;
    } else if (!this.selectedFilter) {
      this.products = [];
    } else {
      this.products = this.campaignDraft.products.filter(
        (product) => this.selectedFilter!.products.includes(product.id)
      );
    }
  }

  getSelectedFilterOption(filters: ProductFilterEntry[], selectedProductId: string): ProductFilterOption | undefined {
    for (let filterEntry of filters) {
      for (let option of filterEntry.options) {
        if (option.products.includes(selectedProductId)) {
          return option;
        }
      }
    }
    return undefined;
  }

  couponHasProduct(coupon: Coupon, product: Product): boolean {
    return !!coupon.products.find(p => p.id === product.id);
  }
}
