import {Component, Input, OnInit} from '@angular/core';
import {RegisterForm} from '@app/sign-in/register/@form/register-form';

@Component({
  selector: 'app-sign-up-condition-field',
  templateUrl: './sign-up-condition-field.component.html',
  styleUrls: ['./sign-up-condition-field.component.scss']
})
export class SignUpConditionFieldComponent implements OnInit {
  @Input() registerForm!: RegisterForm;
  @Input() showValidation!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  isInvalid(): boolean {
    return this.showValidation && this.registerForm.controls.signUpCondition.invalid;
  }
}
