<div *ngIf="signInForm.value.usernameProvided" [formGroup]='signInForm' class="vstack gap-1 py-3 input-group-text">
  <div class="text-center avatarContainer">
    <div class="micon">{{signInForm.value.username!.substring(0, 1).toUpperCase()}}</div>
  </div>
  <span class="form-text">{{ signInForm.value.username }}</span>
  <input type="text" formControlName='username' hidden/>
</div>
<div *ngIf="!signInForm.value.usernameProvided" [formGroup]='signInForm'>
  <label for='usernameInput' class='form-label'>{{ 'field.email' | translate }}</label>
  <div class='input-group has-validation'>
    <input type='text' class='form-control' id='usernameInput' formControlName='username'
           [ngClass]="{ 'is-invalid': showInvalidFeedback && signInForm.controls.username.invalid && signInForm.controls.username.touched }"
    />
    <span class='input-group-text'><i class='fas fa-envelope'></i></span>
    <div *ngFor='let err of signInForm.controls.username.errors | keyvalue' class='invalid-feedback'>
      {{ 'validation.' + err.key | translate }}
    </div>
  </div>
</div>
