<div class="card">
  <form name="emailForm" (ngSubmit)="onSubmit()" [formGroup]="resetEmailForm" novalidate>
    <div class="card-header">
      <h3>{{ 'signIn.resetPasswordHeading' | translate }}</h3>
    </div>
    <div class="card-body">
      <div class="vstack gap-3">
        <ngb-alert class="mb-0" [dismissible]="false" type="secondary">
          {{ 'signIn.resetPasswordInfo' | translate }}
        </ngb-alert>
        <div *ngIf='errorKey' class='alert alert-danger mb-0'>
          {{ errorKey | translate }}
        </div>
        <div>
          <label for="emailInput" class="form-label">{{ 'field.email' | translate }}</label>
          <div class="input-group has-validation">
            <input class="form-control" [ngClass]="{ 'is-invalid': isEmailInvalid() }"
                   type="text" id="emailInput" formControlName="email" autofocus>
            <span class="input-group-text"><i class="fas fa-envelope"></i></span>
            <div *ngIf="isEmailInvalid()" class="invalid-feedback">
              <div *ngFor="let err of resetEmailForm.controls.email.errors | keyvalue">
                {{ 'validation.' + err.key | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <a [routerLink]="configService.getUrl('/sign-in')">{{ 'signIn.signInButton' | translate }}</a>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="text-end mt-1 mb-2">
        <button class="btn btn-primary" type="submit" [disabled]="resetEmailRequest$">
          <i *ngIf='resetEmailRequest$' class='fas fa-cog fa-spin'></i>
          <span>{{ 'signIn.resetPasswordButton' | translate }}</span>
        </button>
      </div>
      <div class="text-end"><small>{{ 'adn.poweredBy' | translate }}</small></div>
    </div>
  </form>
</div>
<ng-container *ngIf="resetEmailRequest$ | async"></ng-container>

