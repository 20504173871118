import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Creative} from '@app/@core/@models/advertising/creative.model';
import {ConfigService} from '@app/@core/@config/config.service';

@Injectable({
  providedIn: 'root',
})
export class CreativeResource {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  update(creative: Creative): Observable<Creative> {
    return this.httpClient.post<Creative>(
      this.configService.getVersionedUri('creatives/' + creative.id),
      creative,
      {
        headers: new HttpHeaders().set('Content-Type', 'text/plain'),
        params: new HttpParams().set('context', this.configService.getNetworkData()!.id)
      }
    );
  }

}
