<div class='card'>
  <app-sign-in-form [isMasquerade]="false" [modal]="undefined"
                    [canChangeUser]="true" [goToSignInLink]="false"
                    [canAnonCampaign]="true"
                    [canResetPassword]="true"
                    [canNavToSignIn]="false"
                    [canRegNewUser]="true"
                    [canUpdateLang]="true"
  >
  </app-sign-in-form>
</div>
