import {Injectable} from '@angular/core';
import {Money} from '@app/@core/@models/network/money.model';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {TranslateParser, TranslatePipe} from '@ngx-translate/core';
import {ValidationWarningMessage} from '@app/@core/@models/advertising/validation-warning-message.model';
import {LuxonDatePipe} from '@app/@i18n/pipes/luxon-date.pipe';

export type FormatOptions = 'short' | 'medium' | 'long' | 'full' |
  'shortDate' |  'mediumDate' | 'longDate' | 'fullDate' | 'shortTime' |
  'mediumTime' | 'longTime' | 'fullTime';

@Injectable({
  providedIn: 'root',
})
export class I18nFormatService {
  constructor(
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private datePipe: LuxonDatePipe,
    private translatePipe: TranslatePipe,
    private percentPipe: PercentPipe,
    private translateParser: TranslateParser
  ) {
  }

  currency(money?: Money | null): string | null {
    if (money) {
      return this.currencyPipe.transform(money.amount, money.currency);
    } else {
      return null;
    }
  }

  number(num: string | number, digitsInfo?: string | undefined): string | null {
    return this.decimalPipe.transform(num, digitsInfo);
  }

  date(date: string | number | Date, format?: FormatOptions): string | null {
    return this.datePipe.transform(date, format);
  }

  translate(query: string, interpolateParams?: Object): any {
    return this.translatePipe.transform(query, interpolateParams);
  }

  percent(value: number | string): string | null {
    return this.percentPipe.transform(value, '1.0-1');
  }

  translateValidationWarningMessage(validationWarningMessage: ValidationWarningMessage): string | undefined {
    return this.translateParser.interpolate(validationWarningMessage.text, validationWarningMessage.parameters);
  }

}
