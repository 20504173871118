import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/@core/@config/config.service';
import { I18nLangService } from '@app/@i18n/services/i18n-lang.service';
import { AnonProfileStore } from '@app/@core/anon/anon-profile-store.service';
import {CacheRegistry} from '@shared/storage/cache-registry.service';
import {NetworkData} from "@app/@core/@config/registered-urls-data";

@Component({
  selector: 'app-auth-shell',
  templateUrl: './auth-shell.component.html',
  styleUrls: ['./auth-shell.component.scss'],
})
export class AuthShellComponent implements OnInit {
  backgroundClass: string;
  networkData: NetworkData | undefined;

  constructor(
    private configService: ConfigService,
    public i18nLangService: I18nLangService,
    private anonProfileStore: AnonProfileStore,
    private cacheRegistry: CacheRegistry
  ) {
    this.backgroundClass = this.getBackgroundClass();
  }

  ngOnInit(): void {
    this.cacheRegistry.flushAll();
    this.i18nLangService.setLanguage(this.anonProfileStore.getAnonProfile()?.lang);
    this.networkData = this.configService.getNetworkData();
  }

  private getBackgroundClass() {
    const bgs = this.configService.getNetworkData()?.bgs;
    return bgs ? bgs[Math.floor(Math.random() * bgs.length)] : '';
  }

}
