<div [formGroup]='signInForm'>
  <label for='passwordInput' class='form-label'>{{ 'field.password' | translate }}</label>
  <div class='input-group has-validation'>
    <input type='password' class='form-control' id='passwordInput' formControlName='password' ngbAutoFocus
           [ngClass]="{ 'is-invalid': showInvalidFeedback && signInForm.controls.password.invalid && signInForm.controls.password.touched }"
    />
    <span class='input-group-text'><i class='fas fa-lock'></i></span>
    <div class='invalid-feedback'>{{ 'signIn.passwordRequired' | translate }}</div>
  </div>
</div>
