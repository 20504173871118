import { Injectable } from '@angular/core';
import {DateTime, Settings} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService extends String {

  private datePipeDefaultTimezone!: string;   // angular DatePipe timezone, eg. "+1000" for "Australia/Melbourne"
  public timezoneOffset!: number;             // highcharts timezoneOffset, eg. -600 for "Australia/Melbourne"

  constructor() {
    super();
    this.setZoneName(DateTime.local().zoneName);
  }

  override toString() {
    return this.datePipeDefaultTimezone;
  }

  // input is Adnuntius Network.timeZone, eg. "Australia/Melbourne"
  setZoneName(zoneName: string) {
    const dt = DateTime.local({ zone: zoneName});
    this.timezoneOffset = -1 * dt.offset;     // highcharts timezone offset
    this.datePipeDefaultTimezone = zoneName;  // angular pipe timezone
    Settings.defaultZone = zoneName;          // luxon default timezone
  }

}
