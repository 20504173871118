import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigService} from '@app/@core/@config/config.service';
import {ReachEstimate} from '@app/@core/@models/advertising/reach-estimate.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReachEstimateResource {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  post(lineItemId: string): Observable<ReachEstimate> {
    return this.httpClient.post<ReachEstimate>(
      this.configService.getVersionedUri('reachestimate/' + lineItemId),
      {},
      {
        headers: new HttpHeaders().set('Content-Type', 'text/plain'),
        params: new HttpParams()
          .set('context', this.configService.getNetworkData()!.id)
          .set('excludeCompetitors', true)
      });
  }

}
