<label class='form-label'>{{assetComponent().name || assetComponent().tag}}</label>
<div class='assets' [ngClass]="{'is-invalid': isInvalid()}">
  <div class='thumbnail'>
    <app-upload-asset
      [anon]='anon'
      [creativeId]='creativeId'
      [invalid]='isInvalid()'
      [assetComponent]='assetComponent()'
      (newAsset)='fileUploaded($event)'
    ></app-upload-asset>
  </div>
  <ng-container *ngFor='let asset of assets.controls; let i=index'>
    <div *ngIf='assetIdControl().value === asset.value.id' class='thumbnail'>
      <app-existing-asset [anon]='anon' [asset]='asset.value' [creativeId]='creativeId' [invalid]='isInvalid()'
                          [assetComponent]='assetComponent()'
      ></app-existing-asset>
    </div>
  </ng-container>
</div>
<div [ngClass]="{ 'invalid-feedback': isInvalid()}" class="form-text">
  <span *ngIf="assetComponent().required === 'MANDATORY'" [innerHTML]="'campaign.ad.imageHint' | translate:
  { mediaTypes: getMediaTypeTranslationsCS(), maxSize: assetComponent().maxFileSizeBytes / 1024 }">
  </span>
  <span *ngIf="assetComponent().required === 'OPTIONAL'" [innerHTML]="'campaign.ad.imageHintOptional' | translate:
  { mediaTypes: getMediaTypeTranslationsCS(), maxSize: assetComponent().maxFileSizeBytes / 1024 }">
  </span>
  <ng-container *ngIf="isInvalid()">
    <ng-container *ngIf='assetIdControl().invalid'>
      <ng-container *ngFor='let err of assetIdControl().errors | keyvalue'>
        <ng-container [ngSwitch]='err.key'>
          <div *ngSwitchCase="'required'" translate='field.required'></div>
          <div *ngSwitchDefault [translate]='err.key'></div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div *ngIf='invalidSize() && getAsset() as asset'>
    <span>{{ 'validation.maxSize' | translate: {
      actual: asset.fileSizeBytes / 1024 | number : '1.0-0',
      maximum: assetComponent().maxFileSizeBytes / 1024 | number :  '1.0-0'
    } }}</span>
    </div>
    <ng-container *ngIf="showServerValidationWarning()">
      <ng-container *ngFor="let warning of serverValidationWarnings">
        <div *ngIf="warning.code !== 'validation.warnings.creative.missing.component'">{{ i18nFormat.translateValidationWarningMessage(warning) }}</div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
