/**
 * Copyright © 2023 Adnuntius AS.
 */

export const TRANSLATIONS_NO = {
  adn: {
    copyright: 'Copyright © 2023 Adnuntius AS',
    releaseId: 'Utgivelses-ID: {{id}}',
    poweredBy: 'Drevet av Adnuntius AS'
  },
  grammar: {
    listSeparator: ', ',
    titleSeparator: ': ',
    booleanOr: 'OR'
  },
  general: {
    new: 'Ny',
    saveSuccess: 'Din oppdatering ble lagret.',
    timezone: 'Alle datoer og tider er i {{timezone}} tidssone.'
  },
  timezone: {},
  button: {
    upload: {
      asset: 'Hent nye',
      drop: 'Hent filen hit og trykk på oppdater knappen'
    },
    archive: 'Lagre',
    activate: 'Aktiver',
    applyCustomRange: 'Bruk tilpasset område',
    cancel: 'Kanseler',
    clear: 'Klar',
    close: 'Lukk',
    newCampaign: 'Lag en ny kampanje',
    save: 'Lagre',
    undo: 'Angre',
    today: 'I dag',
  },
  locale: {
    ar: 'Arabic - عربى',
    ar_JO: 'Arabic (Jordan) - العربية (الأردن)',
    ar_EG: 'Arabic (Egypt) - العربية (مصر)',
    ar_AE: 'Arabic (UAE) - العربية (الإمارات العربية المتحدة)',
    my: 'Burmese - ဗမာ',
    en: 'English',
    en_AU: 'English (Australia)',
    en_CA: 'English (Canada)',
    en_US: 'English (USA)',
    en_GB: 'English (Great Britain)',
    en_PH: 'English (Philippines)',
    es: 'Spanish - español',
    es_ES: 'Spanish (Spain) - español (españa)',
    es_AR: 'Spanish (Argentina) - español (argentina)',
    et: 'Estonian - eesti keel',
    fi: 'Finnish - Suomalainen',
    id: 'Indonesian - bahasa Indonesia',
    is: 'Icelandic - íslensku',
    de: 'German - Deutsch',
    de_DE: 'German (Germany) - Deutsch (Deutschland)',
    de_AT: 'German (Austria) - Deutsch (Österreich)',
    de_CH: 'German (Switzerland) - Deutsch (Schweiz)',
    lt: 'Lithuanian - Lietuvis',
    lb: 'Luxembourgish - Lëtzebuergesch',
    ms: 'Malay - Bahasa Melayu',
    ms_BN: 'Malay (Brunei) - Bahasa Melayu (Brunei)',
    ms_MY: 'Malay (Malaysia) - Bahasa Melayu (Malaysia)',
    nl: 'Dutch',
    nl_BE: 'Dutch (Belgium) - Nederlands (België)',
    nl_NL: 'Dutch (Netherlands) - Nederlands (Nederland)',
    no: 'Norwegian - norsk',
    sv: 'Swedish - svenska',
    sv_SE: 'Swedish (Sweden) - svenska (Sverige)',
    sv_FI: 'Swedish (Finland) - svenska (Finland)',
    pl: 'Polish - Polskie',
    pt: 'Portuguese - Português',
    pt_BR: 'Portuguese (Brazil) - Português (Brasil)',
    pt_PT: 'Portuguese (Portugal) - Português (Portugal)',
    ro: 'Romanian - Română',
    ru: 'Russian - русский',
    el: 'Greek - Ελληνικά',
    fr: 'French - français',
    da: 'Danish - Dansk',
    th: 'Thai - ไทย',
    tl: 'Tagalog - Tagalog',
    tr: 'Turkish - Türk'
  },
  language: {
    text: 'Språk:',
    is: 'Íslenska',
    isShort: 'IS',
    en: 'Engelsk',
    enShort: 'EN',
    el: 'Ελληνικά',
    elShort: 'EΛ',
    es: 'Español',
    esShort: 'ES',
    id: 'Bahasa',
    idShort: 'ID',
    de: 'Deutsch',
    deShort: 'DE',
    th: 'ไทย',
    thShort: 'ไทย',
    fr: 'Français',
    frShort: 'FR'
  },
  campaign: {
    archivedHeading: 'Arkiverte kampanjer',
    archivedNoResults: 'Ingen arkiverte kampanjer funnet.',
    archivedShowLink: 'Vis arkiverte kampanjer',
    adRejected: {
      budgetUnchanged: 'Endring av budsjettet er deaktivert når annonsen din ikke har bestått godkjenning.',
      productUnchanged: 'Endring av produkter er deaktivert når annonsen din ikke har bestått godkjenning.',
      explanationGeneral: 'Annonsering  #{{id}} mislykket gjennomgang og kampanjen din er blokkert fra å levere visninger. Se nedenfor for flere detaljer.',
      explanation: 'Juster annonsen og send inn kampanjen på nytt for vurdering for å få den til å levere de budsjetterte visningene.',
      ADULT_CONTENT: {
        name: 'Voksent innhold',
        explanation: 'Annonsen ble avvist fordi den inneholder eller fører til en landingsside som inneholder voksent innhold.'
      },
      BLANK_CONTENT: {
        name: 'Ikke innhold',
        explanation: 'Annonsen ble avvist fordi den gjengis som tom. Sørg for at det nødvendige innholdet er riktig.'
      },
      MALFORMED_CLICK_THROUGH: {
        name: 'Feilaktig destinasjons adresse URL',
        explanation: 'Annonsen ble avvist fordi destinasjons adressen ikke fungerer som den skal.'
      },
      DRUG_RELATED_CONTENT: {
        name: 'Narkotikarelatert innhold',
        explanation: 'Annonsen ble avvist fordi den eller dens landingsside promoterer narkotikarelatert innhold eller produkter. Dette kan inkludere tobakk, alkohol, farmasøytiske eller andre lovlige eller ulovlige rusmidler.'
      },
      WEAPON_RELATED_CONTENT: {
        name: 'Våpenrelatert innhold',
        explanation: 'Annonsen ble avvist fordi den eller dens landingsside promoterer våpen eller våpenrelatert innhold. Dette kan inkludere innhold som promoterer farlige kniver, våpen, våpendeler og annet relatert innhold.'
      },
      HATE_SPEECH_CONTENT: {
        name: 'Innhold med hatefulle ytringer',
        explanation: 'Annonsen ble avvist fordi den inneholder eller fører til en landingsside som inneholder hatefulle ytringer. Dette kan inkludere innhold som fremmer vold, trakassering, rasisme, hat og intoleranse.'
      },
      LANDING_PAGE_REJECTED: {
        name: 'Landingsside avvist',
        explanation: 'Annonsen ble avvist fordi landingssiden var av lav kvalitet eller vanskelig å samhandle med. Dette kan inkludere sider som deaktiverer nettlesernavigasjonsfunksjoner, inneholder lavverdiinnhold eller fører til en e-postadresse eller fil, for eksempel bilde, lyd, video eller dokument, som krever en ekstra applikasjon for å åpne.'
      },
      OFFENSIVE_CONTENT: {
        name: 'Støtende innhold',
        explanation: 'Annonsen ble avvist fordi den inneholder eller fører til en landingsside som inneholder støtende eller upassende innhold. Sørg for at annonsene og landingssidene dine bare viser familiesikkert innhold.',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        name: 'Uakseptable visuelle effekter',
        explanation: 'Annonsen ble avvist fordi den bruker uakseptable visuelle effekter. Dette inkluderer animerte GIF-er med en bildefrekvens høyere enn 5 bilder pr sekund.'
      },
      DECEPTIVE_CONTENT: {
        name: 'Villedende innhold',
        explanation: 'Annonsen ble avvist fordi den inneholder villedende påstander eller informasjon. Eksempler inkluderer annonser som fremsetter vitenskapelig umulige påstander, tilbyr garanterte resultater uten refusjonspolicy, eller viser attester som hevder spesifikke resultater uten en kobling til tredjepartsbekreftelse. Annonser kan også bli avvist hvis reklamen er utformet for å se ut som programvarenedlastingsknapper, offisielle systemadvarsler eller nettsidefunksjoner som menyer, søkeresultater eller annet nettstedinnhold.'
      },
      GAMBLING_CONTENT: {
        name: 'Gamblinginnhold',
        explanation: 'Annonsen ble avvist fordi den inneholder gamblingrelatert innhold.'
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        name: 'Forbudt valgrelatert innhold',
        explanation: 'Annonsen er avvist fordi den inneholder forbudt innhold relatert til politisk vedtatte regler.'
      },
      SHOCKING_CONTENT: {
        name: 'Sjokkerende innhold',
        explanation: 'Annonsen ble avvist fordi den inneholder sjokkerende innhold som støtende språk, grusomme eller ekle bilder, eller kampanjer som er laget for å sjokkere eller skremme.'
      },
      MALWARE_CONTENT: {
        name: 'Skadelig programvareinnhold',
        explanation: 'Annonsen ble avvist fordi den eller dens landingsside inneholder eller fører til uakseptabelt innhold som skadelig programvare.'
      }
    },
    anon: {
      signInText: 'Opprett en kampanje nå!',
      heading: 'Opprett en kampanje nedenfor.',
      beforeText: 'Kampanjedetaljene dine lagres i din nåværende nettleser. Før kampanjen din kan leveres og få tilgang til andre steder, trenger du to&nbsp;',
      linkText: 'opprett en konto og logg på',
      postText: '.',
      saveTitle: 'Kampanjen din er lagret i databasen vår',
      saveWithMinorError: 'Vi hadde noen problemer med å lagre alt i databasen vår, men nå som du har logget på, burde det ikke være noe problem å fortsetter.',
      saveCampaignLinkPrefix: 'Se på',
      saveCampaignLinkText: 'Lagre din kampanje og send den inn for godkjenning',
      saveCampaignLinkPostfix: '.',
      failSaveTitle: 'Kampanjen din kunne ikke lagres i databasen vår.',
      failSaveCampaignLinkPrefix: '',
      failSaveCampaignLinkText: 'Opprett en kampanje direkte i vår database',
      failSaveCampaignLinkPostfix: 'nå som du har logget på.',

    },
    layout: {
      title: 'Velg et annonsedesign',
      missing: 'Annonselayout du valgte tidligere er ikke tilgjengelig for det gjeldende annonseringsalternativ.'
    },
    site: {
      title: 'Alternativer for device målretting',
      descriptionPrefix: 'Annonsen din vises på:&nbsp;',
      instructions: 'Spesifiser ett eller flere nettsteder hvor annonsen din skal vises',
    },
    device: {
      title: 'Alternativer for device målretting',
      descriptionPrefix: 'Annonsen din vises i:&nbsp;',
      instructions: 'Velg ett eller flere device alternativer for å målrette annonsen din',
    },
    domainName: {
      title: 'Alternativer for målretting mot domene',
      descriptionPrefix: 'Din annonse vil bli vist på:&nbsp;',
      instructions: 'Spesifiser en eller flere nettsteder for å målrette hvor annonsen din skal vises'
    },
    location: {
      title: 'Alternativer for målretting mot lokasjon',
      descriptionPrefix: 'Din annonse vil bli vist i:&nbsp;',
      instructions: 'Spesifiser ett eller flere steder for å målrette annonsen din mot'
    },
    kvs: {
      title: 'Alternativer for målretting mot seksjoner',
      descriptionPrefix: 'Din annonse vil bli vist i:&nbsp;',
      instructions: 'Spesifiser en eller flere seksjoner for å målrette annonsen din mot',
      any: 'alle tilgjengelige seksjoner'
    },
    category: {
      title: 'Alternativer for målretting mot kategorier',
      descriptionPrefix: 'Din annonse vil bli vist for:&nbsp;',
      instructions: 'Spesifiser en eller flere kategorier for å målrette annonsen din mot',
      any: 'alle tilgjengelige kategorier'
    },
    userSegment: {
      title: 'Alternativer for brukersegmentmålretting',
      descriptionPrefix: 'Din annonse vil bli vist til:&nbsp;',
      instructions: 'Spesifiser ett eller flere brukersegmenter for å målrette annonsen din mot',
      any: 'alle tilgjengelige brukere'
    },
    product: {
      title: 'Velg et annonseringsalternativ',
      adDesignChoice: '<strong>{{count}} annonse design</strong> tilgjengleing for disse størrelser:',
      cpm: '<strong>{{value}}</strong> pr tusen annonsevisninger',
      minSpend: '<strong>{{value}}</strong> minimum budsjett',
      maxSpend: '<strong>{{value}}</strong> maksimalt budsjett',
      deleted: 'Det tidligere valgte annonseringsalternativet er ikke lenger tilgjengelig. Velg et annen alternativ for å fortsette.',
      examples: ''
    },
    ad: {
      title: 'Lag din annonse',
      defaultName: 'Annonse for {{campaignName}}',
      layoutFirst: 'Velg et annonsedesign før du legger inn annonsens detaljer.',
      deletedAsset: 'Denne komponenten refererer til et slettet bilde. Last opp et nytt bilde.',
      imageHint: '{{mediaTypes}}, Maksimalt: {{maxSize}}kb',
      imageHintOptional: '{{mediaTypes}}, Maksimalt: {{maxSize}}kb (Optional)',
      adSizeTitle: 'Annonsestørrelse',
      create: 'Lag din annonse',
      createNumbered: 'Lag din annonse #{{count}}',
      add: 'Legg til en annen annonse',
      count: 'Annonse <strong>#{{count}}</strong>',
      maximum: 'Maksimalt tre annonser kan legges til kampanjen din',
      choiceOptional: '(Intet valg)',
      remove: 'Fjern annonsen',
      runningPreviewTitle: 'Din {{width}}&times;{{height}} annonse',
      previewTitle: 'Forhåndsvisning av annonse',
      previewExampleField: 'Eksempel ',
      previewWithExample: 'Denne forhåndsvisningen av annonsen inneholder eksempeldata for demonstrasjonsformål.',
      textCount: 'antall: {{count}}',
      textHintChars: '{{min}}&ndash;{{max}} tegn',
      textHintCharsOptional: '{{min}}&ndash;{{max}} karakterer (Optional)',
      urlHint: 'URL',
      urlHintOptional: 'URL (Optional)',
      copyrightMessage: 'Jeg erklærer at intet innhold jeg bruker bryter med opphavsrett eller varemerkebeskyttelse',
      stretchable: 'Annonsen din er utformet for å kunne strekkes i størrelse. Bruk knappen nedenfor for å se annonsen din enten med en fast størrelse eller en strekkbar for å plassere størrelsen in i rammen.',
      stretched: 'Annonsen din er utformet for å kunne strekkes i størrelse. Annonsen din vil utvides og trekke seg sammen i størrelse for å passe til rammen den er plassert i.',
      stretchableFixedOption: 'Fast',
      border: 'Vis rammekant for å demonstrere annonsestørrelse',
      stretchableStretchableOption: 'Strekkbar'
    },
    addNewCampaign: `
      <h3>Lag en kampanje for å levere budskapet ditt</h3>
      <p>Kampanjer er det som leverer annonser på vår annonseportal.</p>
      <p>Opprett en ny kampanje med knappen ovenfor og lever annonsene dine.</p>
      <p>Du vil kunne få tilgang til kampanjene dine her etter å ha opprettet dem og følge dem mens de leverer.</p>
    `,
    campaign: 'Kampanje',
    campaignPlural: 'Kampanjer',
    campaignInfo: '',
    campaignSubInfo: '',
    copy: {
      copyText: "En kopi av denne kampanjen ble opprettet.",
      copyTitle: "Kampanje kopiert",
      copyPartialText: "Denne kampanjen har blitt kopiert, men ikke alt reklamematerialet kunne kopieres sammen med den.",
      copyPartialTitle: "Kampanje kopiert, men ikke alt reklamemateriell"
    },
    filter: {
      preMessage: '',
      message: 'Målrettet etter {{option}}:'
    },
    copyAfterwards: 'Gå til ny kampanje etter kopi',
    defaultName: "{{productName}} Kampanje",
    detailsTitle: 'Spesifiser kampanjedetaljene dine',
    draftedHeading: 'Utformede kampanjer',
    draftedNoResults: 'Fant ingen utkast til kampanjer.',
    endedHeading: 'Avsluttede kampanjer',
    endedNoResults: 'Ingen avsluttede kampanjer funnet.',
    expectedImpressions: 'Tilsvarer ca {{imps}} visninger',
    cpmBoost: 'En CPM over {{minPrice}} minimum øker sjansene dine for å levere alle budsjetterte visninger',
    maxedOutCampaigns: '<p>Du kan ikke opprette flere kampanjer for øyeblikket.</p><p>Bare maximum {{max}} kampanjer kan utarbeides når som helst.</p><p>AArkiver en eksisterende kampanje eller få den til å levere visninger for å kunne opprette en annen kampanje.</p>',
    coupon: {
      applyCoupon: 'Bruk kupong',
      appliedCode: '{{code}} har blitt anvendt',
      cancel: '(avbryt)'
    },
    reachEstimate: {
      disabled: 'Lagre kampanjen og sørg for at budsjettet ditt er spesifisert for å sjekke sjansene for leverte visninger',
      trigger: 'Sjekk sjansen for levering av annonser',
      VERY_GOOD: 'Dine bestilte visninger har en veldig stor sjanse for å bli levert',
      GOOD: 'Dine bestilte visninger har stor sjanse for å bli levert',
      POSSIBLE: 'Dine bestilte visninger vil møte en viss konkurranse om levering',
      COMPETITION: 'De bestilte visningene dine vil møte en viss konkurranse om levering, og potensielt vil ikke alle visningene dine bli levert',
      MUCH_COMPETITION: 'De bestilte visningene dine vil møte en viss konkurranse om levering, og potensielt vil bare noen av visningene dine bli levert',
      LOTS_COMPETITION: 'De bestilte visningene dine vil møte hard konkurranse om levering, og potensielt vil ingen av visningene dine bli levert',
    },
    payment: {
      title: 'Betaling',
      missing: 'Ingen betaling er knyttet til denne kampanjen.',
      cancelled: 'Bestillingen din ble kansellert og kampanjen din ble ikke sendt inn for vurdering.',
      infoComplete: 'En betaling på {{price}} ble godkjent {{time}}.',
      couponCalculation: '<strong>{{amount}}</strong> vil bli belastet etter at kupongrabatten er brukt.',
      infoCompleteWithCoupon: 'En betaling på {{price}}, som gjaldt en {{discount}} rabatt, ble godkjent {{time}}.',
      refunded: '{{price}} ble refundert {{time}}.',
      infoAuthorised: 'En betaling på {{price}} ble autorisert {{time}}.',
      receiptLink: 'Din <a href="{{link}}" target="_blank">betalingskvibtering er tilgjengelig</a>.',
      registering: 'Registrert betaling...',
      reserveTitle: 'Foreta betaling og send inn kampanjen din for gjennomgang og godkjenning',
      reserveButton: 'Foreta betaling og send inn kampanjen din for gjennomgang',
      reserveParagraph0Coupon: 'En <strong>{{amount}}</strong> kupongrabatt har blitt brukt på ditt <strong>{{budget}}</strong> budsjett.',
      reserveParagraph1: 'Vi vil <strong>relate deg</strong> et beløp stort <strong>{{amount}}</strong>, wsom gjør at kampanjen din kan gjennomgås og kjøres.',
      reserveParagraph1Free: '<strong>Ingen kostnad vil bli belastet</strong> og kampanjen din vil bli vurdert før den kan kjøres.',
      reserveParagraph2: 'Hvis du har budsjettert  <strong>{{imps}} visninger</strong> ikke leveres, vil du få refundert restbeløpet.',
      failure: 'Vi har hatt et problem under forsøket på å registrere betalingen din. Prøv igjen, og hvis et problem vedvarer, vennligst kontakt oss.',
      saveFirst: 'Du må lagre endringene dine før du fortsetter med å reservere betaling',
      PIN_PAYMENTS: {
        waitingForRedirect: 'Vi videreformidler detaljene om transaksjonen din til kredittkortleverandøren vår og vil omdirigere deg dit snart.',
      },
      PAYPAL: {
        waitingForRedirect: 'Vi videreformidler detaljene om transaksjonen din til PayPal og vil omdirigere deg dit snart.',
        reserveButton: 'Betal via PayPal og send inn kampanjen din for gjennomgang'
      },
      prepay: {
        detailsInfo: `
          <div class="small">
            <p>Kampanjen din leveres når betalingen er foretatt og innholdet i kampanjen din er gjennomgått og godkjent.</p>
            <p>Hvis kampanjen din ikke leverer budsjettets verdi av visninger, vil du få refundert restbeløpet. Et minimumsgebyr på {{minimumFee}} gjelder imidlertid.</p>
            <p>Når kampanjen er avsluttet, genereres en resultatrapport og kvittering.</p>
            <p>Du vil kunne stoppe leveringen av kampanjen din når som helst, men minimumsavgiften på {{minimumFee}} gjelder selv om det ikke er levert noen visninger.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Kampanjen din leveres når betalingen er foretatt og innholdet i kampanjen din er gjennomgått og godkjent.</p>
            <p>Hvis kampanjen din ikke leverer budsjettets verdi av visninger, vil du få refundert restbeløpet.</p>
            <p>Når kampanjen er avsluttet, genereres en resultatrapport og kvittering.</p>
            <p>Du vil når som helst kunne stoppe leveringen av kampanjen din.</p>
          </div>
        `
      },
      manual: {
        reserveTitle: 'Send inn kampanjen din for gjennomgang',
        reserveButton: 'Send inn kampanje for gjennomgang',
        reserveParagraph0Coupon: 'En <strong>{{amount}}</strong> kupongrabatt har blitt brukt på ditt <strong>{{budget}}</strong> budsjett.',
        reserveParagraph1: 'Før kampanjen din kan kjøres, må vi vurdere den.',
        reserveParagraph2: 'Når kampanjen din er avsluttet, vil vi utstede en faktura for <strong>{{amount}}</strong> eller forholdsmessig beløp basert på det faktiske antallet leverte visninger kontra forventet <strong>{{imps}} annonsevisninger</strong>.',
        detailsInfo: `
          <div class="small">
            <p>Kampanjen din leveres når innholdet i kampanjen din er gjennomgått og godkjent.</p>
            <p>En faktura vil bli sendt til deg etter at kampanjen er avsluttet. Du vil bli belastet i henhold til hvor mange visninger kampanjen din gir og aldri mer enn det angitte budsjettet. Et minimumsgebyr på {{minimumFee}} gjelder imidlertid.</p>
            <p>Når kampanjen din er avsluttet, genereres en resultatrapport.</p>
            <p>Du vil kunne stoppe leveringen av kampanjen din når som helst, men minimumsavgiften på {{minimumFee}} gjelder selv om det ikke er levert noen visninger.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Kampanjen din leveres når innholdet i kampanjen din er gjennomgått og godkjent.</p>
            <p>En faktura vil bli sendt til deg etter at kampanjen er avsluttet. Du vil bli belastet i henhold til hvor mange visninger kampanjen din gir og aldri mer enn det angitte budsjettet.</p>
            <p>Når kampanjen din er avsluttet, genereres en resultatrapport.</p>
            <p>Du vil når som helst kunne stoppe leveringen av kampanjen din.</p>
          </div>
        `
      },
      status: {
        CREATED: 'Opprettet',
        NOT_AUTHORISED: 'Ikke autorisert',
        AUTHORISED: 'Autorisert',
        COMPLETED: 'Fullført',
        PAUSED: 'Satt på pause',
        CANCELLED: 'Avbrutt'
      }
    },
    postPayment: {
      reserveParagraph1: 'Betaling for kampanjen din er godkjent.',
      reserveParagraph2: 'Hvis du er fornøyd med kampanjen din, kan du sende den inn for vurdering nedenfor.',
      saveFirst: 'Du må lagre endringene dine før du fortsetter med å sende inn kampanjen for vurdering.',
      failed: 'Det oppsto en feil ved å sende inn kampanjen din til vurdering. Prøv igjen, og hvis problemet vedvarer, vennligst kontakt oss.',
      submitButton: 'Send inn kampanjen din for gjennomgang'
    },
    rejectedHeading: 'Avviste kampanjer',
    report: {
      title: 'Rapport',
      ungenerated: 'En rapport vil være tilgjengelig for deg å laste ned snart.',
      generated: 'En <a href="{{link}}" target="_blank">rapport er tilgjengelig for deg å laste ned</a>.'
    },
    runningHeading: 'Kampanjer under vurdering og kjørende kampanjer',
    runningNoResults: 'Fant ingen kjørende kampanjer.',
    running: {
      budgetHeading: 'Budsjettstatus',
      budgetPosition: '<strong>{{budgetSpent}}</strong> av ditt <strong>{{budget}} budsjett</strong> er brukt.',
      cancelledPaymentParagraph1: 'Din betalingsreservasjon er kansellert.',
      cancelledPaymentParagraph2: 'Hvis du fortsatt ønsker å fortsette med kampanjen, kan du prøve å betale på nytt nedenfor.',
      datesHeading: 'Kampanjedatoer',
      dates: '<strong>{{startDate}}</strong> til <strong>{{endDate}}</strong>',
      datesExplanation: 'Kampanjen din har kjørt I <strong>{{days}} dager</strong>, som er <strong>{{percentage}}</strong> av sin maksimale planlagte levetid.',
      datesLeftExplanation: 'Kampanjen din slutter å kjøre innen <strong>{{days}} dager</strong> hvis de budsjetterte visningene ikke nås.',
      datesSubmittedExplanation: 'Kampanjen din kjøres i <strong>{{days}} dager</strong> hvis de budsjetterte visningene ikke nås.',
      impressions: 'Kampanjen din har levert <strong>{{imps}} visninger</strong>, som er <strong>{{percentage}} av dine budsjetterte visninger </strong>.',
      impressionsRemaining: 'Du har omlag <strong>{{imps}} visninger</strong> igjen for å bli levert.',
      statusPrefix: 'Kampanje status: ',
      successfulPaymentParagraph1: 'Betalingen er utført og kampanjen din er sendt inn for vurdering.',
      successfulPaymentParagraph2: 'Når kampanjen din er godkjent, vil den levere annonseringen din!!',
      prepay: {
        stoppedParagraph1: 'Kampanjen din er stoppet.',
        stoppedParagraph2: 'Eventuelt ubrukt kampanjebudsjett vil bli refundert og en rapport generert for kampanjen din.',
      },
      manual: {
        successfulPaymentParagraph1: 'Kampanjen din er sendt inn til vurdering.',
        stoppedParagraph1: 'Kampanjen din er stoppet.',
        stoppedParagraph2: 'En faktura vil bli utstedt til deg og en rapport generert for kampanjen din.',
      },
      submittedParagraph1: 'Kampanjen din er sendt inn til vurdering.',
      submittedParagraph2: 'Når kampanjen din er godkjent, vil den levere annonseringen din!',
      unexpectedPaymentParagraph1: 'Betalingen din gikk ikke som planlagt.',
      unexpectedPaymentParagraph2: 'På dette stadiet er det sannsynligvis best å kopiere denne kampanjen og starte prosessen på nytt med å opprette en betalingsreservasjon.'
    },
    save: {
      button: 'Lagre arbeidet gjort',
      messageTitle: 'Kampanjen lagret',
      messageText: 'Kampanjen din ble lagret.'
    },
    smoothing: {
      title: 'Annonselevering',
      true: 'Jevnt',
      trueExplanation: 'Annonsen din vil levere jevnt i løpet av kampanjens levetid.',
      trueField: '<strong>Smooth</strong> - levere de budsjetterte annonsevisningene jevnt i løpet av kampanjens levetid',
      false: 'Raskt',
      falseExplanation: 'Annonsen din vil bli levert så raskt som mulig.',
      falseField: '<strong>Fast</strong> - levere budsjetterte annonsevisninger så raskt som mulig'
    },
    status: {
      STOPPED: 'Stoppet',
      PROPOSED: 'Utkast',
      SUBMITTED: 'Under vurdering',
      NOT_READY: 'Trenger ytterligere vurdering',
      READY: 'Klar til å leverere',
      PAUSED: 'Pauset',
      REJECTED: 'Avvist',
      SUBMISSION_READY: 'Klar for innlevering',
      RUNNING: 'Leverer',
      ENDED: 'Avsluttet'
    },
    statsHeading: 'Leveringsstatistikk',
    stop: {
      button: 'Stopp kampanjelevering',
      cancelButton: 'Avbryt kampanjestopp',
      confirmButton: 'Bekreft kampanjestopp',
      failParagraph1: 'Vi har støtt på et problem og kunne ikke stoppe kampanjen din.',
      failParagraph2: 'Prøv å stoppe kampanjen på nytt, og gi oss beskjed hvis problemet vedvarer.',
      prepay: {
        info1: 'Å stoppe en kampanje kan ikke reverseres, selv om enhver kampanje kan kopieres og den nye kampanjen leveres.',
        info2: 'Når du stopper kampanjen, vil ubrukt budsjett bli refundert. Merk: et minimumsgebyr på {{money}} bli lagt til.',
        info2NoMinFee: 'Når du stopper kampanjen, vil du bli belastet for gjeldende annonseringsutgifter.',
      },
      manual: {
        info1: 'Å stoppe en kampanje kan ikke reverseres, selv om enhver kampanje kan kopieres og den nye kampanjen leveres.',
        info2: 'Når du stopper kampanjen, vil du få tilsendt en faktura verdsatt til enten minimumsgebyr på {{money}} eller ditt nåværende annonseringsforbruk, avhengig av hva som er høyere.',
        info2NoMinFee: 'Når du stopper kampanjen, vil du bli utstedt med en faktura verdsatt til gjeldende annonseringsforbruk.',
      },
      intro: 'Hvis du på noe tidspunkt ønsker å stoppe kampanjen, kan du gjøre det nedenfor.'
    },
    validationProblemsPresent: `
      <p>Det er noen valideringsproblemer med kampanjen.</p>
      <p>Så snart valideringsproblemene er løst, kan vi fortsette med prosessen med å få kampanjen din live.</p>
    `,
  },
  devices: {
    ACER: 'Acer',
    APPLE: 'Apple devices',
    ASUS: 'Asus',
    DESKTOP: 'Desktop',
    FUJITSU: 'Fujitsu',
    HTC: 'HTC',
    HUAWEI: 'Huawei',
    KYOCERA: 'Kyocera',
    LG: 'LG',
    MOTOROLA: 'Motorola',
    NEC: 'NEC',
    NOKIA: 'Nokia',
    ONE_PLUS: 'OnePlus',
    OPPO: 'Oppo',
    PANASONIC: 'Panasonic',
    RIM: 'RIM',
    SAMSUNG: 'Samsung',
    SHARP: 'Sharp',
    SONY_ERICSSON: 'Sony Ericsson',
    UNKNOWN: 'Unknown',
    VIVO: 'Vivo',
    XIAOMI: 'Xiaomi',
    ZTE: 'ZTE',
    ANDROID: 'Android',
    BLACKBERRY: 'Blackberry',
    BSD: 'BSD',
    IOS: 'iOS',
    LINUX: 'Linux',
    MACINTOSH: 'Apple Mac OS',
    SUNOS: 'SunOS',
    SYMBIAN: 'Symbian',
    WINDOWS: 'Windows',
    WINDOWS_PHONE: 'Windows telefon',
    CONSOLE: 'Konsoll',
    E_READER: 'E leser',
    MEDIA_HUB: 'Media Hub',
    MOBILE: 'Smart telefon',
    SMART_WATCH: 'Smart klokke',
    TABLET: 'Lesebrett',
    TV: 'TV'
  },
  emailPreferences: {
    CREATIVE_REJECTED: '<strong>Justeringer nødvendig:</strong> en anmelder ber om en endring i kampanjen min',
    REPORT_COMPLETE: '<strong>Kampanjerapporten er klar:</strong> kampanjen min er ferdig og resultatrapporten er generert',
    LINE_ITEM_RUNNING: '<strong>Kampanjen leverer:</strong> kampanjen min har bestått gjennomgangen og leverer nå visninger'
  },
  media: {
    gif: 'GIF',
    png: 'PNG',
    svg: 'SVG',
    jpg: 'JPG',
    mp4: 'MP4',
    webm: 'WebM',
  },
  chart: {
    countType: {
      singular: 'Trend',
      cumulative: 'Akkumulert'
    },
    yAxisOption: {
      shared: 'Delt y-akse',
      multiple: 'Flere y-akser'
    },
    dateOption: {
      last1Hour: 'Siste time',
      last12Hours: 'Siste 12 timer',
      last7Days: 'Siste 7 dager',
      last30Days: 'Siste 30 dager',
      today: 'I dag',
      yesterday: 'I går',
      campaignDays: 'Dato fra-til: {{startDate}}-{{endDate}}'
    }
  },
  layout: {
    fieldSummary: {
      image: 'bilde',
      imagePlural: 'bilde',
      url: 'URL',
      urlPlural: 'URLs',
      code: 'kodefelt',
      codePlural: 'kodefelt',
      text: 'tekstfelt',
      textPlural: 'tekstfelt',
      option: 'alternativ',
      optionPlural: 'alternativer',
      video: 'video',
      videoPlural: 'videoer'
    }
  },
  label: {
    copy: 'Kopier',
    copyLineItem: 'Kopier kampanje',
    paginationAllShown: 'Viser alle <strong>{{total}}</strong> resultat',
    paginationWithPages: 'Viser <strong>{{start}}-{{end}}</strong> av <strong>{{total}}</strong> resultat',
    paginationLoading: 'Viser <span class="fa fa-spin fa-cog"></span> resultat'
  },
  report: {
    downloadXlsComma: 'Last ned som XLS (kommaseparert)',
    downloadXlsPoint: 'Last ned som XLS (punktumseparert)',
    fieldCountPlural: '{{count}} felt',
    fieldCount: '{{count}} felt',
    report: 'Rapport',
    reportPlural: 'Rapport',
    noReport: `
      <h3>Ingen rapporter funnet</h3>
      <p>Når kampanjen din er avsluttet, genereres en rapport som viser resultatene.</p>
      <p>Her finner du en liste over alle rapportene som er generert for alle kampanjene dine.</p>
    `,
    noReportExample: '',
    status: {
      PENDING: 'Avventer',
      PROCESSING: 'Behandling',
      COMPLETED: 'Fullført',
      REJECTED: 'Avvist',
      FAILED: 'Feilet',
      CANCELLED: 'Kanselert',
      QUEUED: 'I kø',
      TIMED_OUT: 'Tidsavbrudd'
    },
    field: {
      rendered: 'Visninger',
      visibles: 'Viste visninger',
      visibility: 'Vislighet',
      viewables: 'Synlige visninger',
      viewability: 'Synlighet',
      uniqueUsers: 'Unike brukere',
      clicks: 'Klikk',
      ctr: 'Klikkrate',
      cost: 'Kostnad'
    }
  },
  errors: {
    standard: {
      unregistered: {
        title: 'Kontoen din er ikke registrert her',
        p1: 'Du er ikke registrert på denne annonseportalen.',
        p2: '',
        p2LinkText: 'Registrer deg på denne annonseportalen',
        p2Suffix: '&nbsp;før du fortsetter.',
        p3: 'Alternativt,&nbsp;',
        p3LinkText: 'logge på med en annen konto',
        p3Suffix: '.'
      },
      dataNotFoundTitle: 'Manglende data',
      dataNotFoundParagraph1: 'Vi kunne ikke finne noen data på følgende sted: <strong>{{link}}</strong>.',
      dataNotFoundParagraph2: 'Dette kan ha skjedd fordi de underliggende dataene ikke lenger er tilgjengelige for deg.',
      dataNotFoundParagraph3: 'Få tilgang til en av koblingene i menyen for å komme tilbake på spore.',
      pageNotFoundTitle: 'Side ikke funnet',
      pageNotFoundParagraph1: 'Vi kunne ikke finne siden du ba om.',
      pageNotFoundParagraph2: 'Få tilgang til en av koblingene i menyen for å komme tilbake på sporet.',
      objectNotFoundTitle: 'Objekt ikke funnet',
      objectNotFoundParagraph1: 'Vi kunne ikke finne noen data for følgende id: <strong>{{id}}</strong>.',
      objectNotFoundParagraph2: 'Dette kan ha skjedd fordi objektet ikke lenger er tilgjengelig for deg.',
      objectNotFoundParagraph3: 'Få tilgang til en av koblingene i menyen for å komme tilbake på sporet.'
    },
    api: {
      stats: 'Vi kunne ikke hente statistikk fra API. Hvis problemet vedvarer, vennligst kontakt support.',
      title: 'Beklager, det ser ut til å være et problem',
      noConnection: {
        title: "Tilkoblingsfeil",
        noConnection: "<p>Vi har mistet forbindelsen til back-end-serveren vår. Hvis problemet vedvarer, vennligst kontakt support.</p>"
      },
      generic: "<p>Vi har problemer med å samhandle med back-end-systemet vårt. Hvis problemet vedvarer, vennligst kontakt support.</p>",
      unauthorised: {
        title: "Uautorisert operasjon",
        unauthorised: "<p>Du har ikke tillatelsene til å utføre operasjonen du ba om.</p>"
      }
    }
  },
  field: {
    advertiserName: 'Selskapsnavn',
    address1: 'Adresse',
    selfServiceBudget: 'Budsjett',
    city: 'By',
    confirmPassword: 'Bekreft passord',
    state: 'Fylke',
    postCode: 'Postnummer',
    country: 'Land',
    invoiceEmail: 'E-post til fakturering',
    phoneNumber: 'Telefon nummer',
    email: 'E-mail',
    emailPreferences: 'E-post preferanser',
    password: 'Passord',
    locale: 'Språk',
    smoothing: 'Annonseleveranse modell',
    startDate: 'Start',
    status: 'Status',
    userState: 'Status',
    vatId: 'MVA nummer',
    displayName: 'Vist navn',
    displayNameHint: 'Visningsnavnet brukes til å referere til deg gjennom hele applikasjonen',
    endDate: 'Slutt',
    executionStartTime: 'Start datoe',
    name: 'Navn',
    cpm: 'Kostnad pr 1000 visninger (CPM)',
    required: 'Obligatorisk',
    malformedEmail: 'Må være en gyldig e-post',
    optional: 'Frivillig'
  },
  settings: {
    settingsPlural: 'Innstillinger',
    about: 'Om',
    signOut: 'Logg ut'
  },
  signIn: {
    accountLocked: 'Kontoen din har blitt låst på grunn av for mange mislykkede påloggingsforsøk. Du har fått tilsendt en e-post der du kan tilbakestille passordet ditt, låse opp kontoen din og logge på.',
    alreadyUser: 'Allerede en bruker?',
    confirmPasswordMismatch: "Passordene du skrev inn stemmer ikke overens. Sørg for at du har skrevet inn samme passord begge gangene.",
    differentAccount: 'Logg på med en annen konto',
    coupon: {
      code: 'Kupongkode',
      checkCode: 'Sjekk kupongkoden',
      validCode: '{{code}} er en gyldig kupongkode',
      invalidCode: '{{code}} er ikke en gyldig kupongkode'
    },
    emailFirst: "Kontoens e-postadresse må oppgis før passordet kan tilbakestilles.",
    emailRequired: "Vi trenger e-postadressen til kontoen din før vi kan logge deg på.",
    expiredLinkExplanation: 'Det ser ut til at du klikket på en ugyldig eller utløpt lenke for tilbakestilling av passord. Prøv å tilbakestille passordet ditt på nytt.',
    forgotPassword: 'Glemt passordet?',
    returnHome: 'Gå til påloggingssiden',
    humanConfirmationFailure: "Det er et problem med å få registreringsbekreftelse for kontoen din. Prøv på nytt og gi oss beskjed hvis problemet vedvarer.",
    nameRequired: "Vi trenger et navn for å registrere deg som ny bruker.",
    passwordResetTooMuchHeading: 'For mange tilbakestillinger av passord',
    passwordResetTooMuchExplanation: 'Du har prøvd å tilbakestille passordet ditt for ofte på kort tid. Du kan ikke tilbakestille passordet ditt om en liten stund.',
    passwordResetEmailSentHeading: 'Tilbakestilt passord, e-post er sendt',
    passwordResetEmailSentExplanation: 'Sjekk e-posten din for en lenke for å tilbakestille passordet ditt. Hvis det ikke vises innen noen få minutter, sjekk søppelpostmappen.',
    passwordHint: 'Passordet ditt må være på minst 8 tegn',
    passwordLinkExpired: 'Linken for tilbakestilling av passord er utløpt. Skriv inn e-postadressen din for å generere en annen lenke.',
    passwordRequired: "Oppgi et passord for å logge på.",
    passwordSimple: 'Det oppgitte passordet er for lett å gjette og bør være noe mindre vanlig.',
    regError: 'Finner valideringsproblemer. Se detaljer ovenfor.',
    registerAsUserHeading: 'Registrer deg som bruker',
    registerAsUserButton: 'Registrer',
    registerEmailRequired: 'Vi trenger en e-postadresse for å registrere deg som ny bruker.',
    registerNewUser: 'Registrer deg som ny bruker',
    registerSuccessHighlight: 'Du har registrert deg som ny bruker!',
    registerSuccessParagraph1: 'Du har fått tilsendt en e-post med mer informasjon.',
    registerSuccessParagraph2: 'Klikk på koblingen i e-posten for å opprette passordet for kontoen din og logge på.',
    registerTerms: `By selecting <strong>Register</strong>, ved å velge <a target="_blank" href="https://adnuntius.com/resources/tc-self-service-advertising/">du godtar at du har lest og godtatt vår</a>.`,
    resetPasswordHeading: 'Tilbakestill passordet ditt',
    resetPasswordButton: 'Send e-post for tilbakestilling av passord',
    resetPasswordInfo: 'Skriv inn e-postadressen din, så sender vi deg en lenke for å tilbakestille passordet ditt.',
    signInButton: 'Logg inn',
    signInHeading: 'Logg inn',
    updatePasswordHeading: 'Oppdater passordet ditt',
    updatePasswordButton: 'Oppdater passord'
  },
  adBlockerParagraph1: "Vi har oppdaget at nettleseren din blokkerer skript som er nødvendige for denne applikasjonen.",
  adBlockerParagraph2: "Du må kanskje deaktivere annonseblokkering og/eller personvernnettleserutvidelser for dette nettstedet og oppdatere siden for å sikre at applikasjonen fungerer som forventet.",
  adBlockerTurnOff: "Slå av denne advarselen",
  validation: {
    required: 'Obligatorisk',
    archived: 'Denne kampanjen er arkivert. Du kan fortsatt samhandle med kampanjen, men den kan ikke levere noen reklame.',
    pattern: "Tekst må være {{minLength}}-{{maxLength}} tegn lang.",
    maxSize: "Den opplastede filen er {{actual}}kb i størrelse, som er større enn {{maximum}}kb maksimalt",
    fileType: "Den opplastede filen må være en av følgende typer: {{allowedTypes}}",
    fileUploadFail: "Følgende filer kunne ikke lastes opp:",
    fileUploadTimeout: "Opplastingen kunne ikke fullføres. Prøv å laste opp på nytt eller, hvis mulig, oppdater siden for å se hvilke ressurser som er lastet opp.",
    anonymousFileSizeLarge: 'Filen må være mindre enn {{fileSize}}kb i størrelse når du ikke har logget på.',
    anonymousFileSizeSuperLarge: 'Filopplastingen mislyktes av en uspesifisert grunn, mer enn sannsynlig fordi filen var for stor til at vi kunne analysere den. Logg på for å øke grensene for filopplasting og få bedre analyser.',
    minPrice: "Minimumsverdi er {{minValue}}",
    maxPrice: "Maksimal verdi er {{maxValue}}",
    number: "Ugyldig størrelse",
    url: "URL-en er ikke i en gyldig form.",
    parse: "Innholdet samsvarer ikke med det forventede formatet",
    endDateStartDate: 'Sluttdatoen må være etter startdatoen.',
    email: 'Trenger en gyldig e-postadresse',
    error: {
      validation: '<strong>Ugyldig innsending:</strong> Vennligst sjekk detaljene ovenfor og prøv på nytt.',
      'must.be.greater.than.or.equal': '{{value}} må være større enn {{minValue}}.',
      'non.empty': 'Obligatorisk',
      "auth.failed": "Vi kunne ikke logge deg på. Påloggingsinformasjonen samsvarer ikke med noen kontoer",
      "sign.in.blocked": "Du er ikke registrert som bruker her. Logg på {{uiAddress}}.",
    },
    warning: {
      "creative.invalid.product.dimensions": "Gyldige dimensjoner må velges for annonsen din",
      "lineItem.start.date.before.end.date": "Kampanjens startdato må være før sluttdatoen",
      "lineItem.end.date.before.today": "Kampanjens sluttdato må være etter gjeldende dato og klokkeslett",
      "lineItem.invalid.budget.enddate": "Kampanjen må avsluttes innen 100 dager",
      "payment.gateway.error": "Problemet er knyttet til vår tilkobling til betalingsleverandør."
    }
  }
};
