import {Component, Input, OnInit} from '@angular/core';
import {CampaignForm} from '@app/@core/advertising/campaign/campaign-form';

@Component({
  selector: 'app-ad-delivery-style[form]',
  templateUrl: './ad-delivery-style.component.html',
  styleUrls: ['./ad-delivery-style.component.scss']
})
export class AdDeliveryStyleComponent implements OnInit {
  @Input() form!: CampaignForm;

  constructor() { }

  ngOnInit(): void {
  }

}
