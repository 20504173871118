import {ComponentRequired, LayoutTag} from '@app/@core/@models/advertising/layout/layout.model';
import {LayoutComponent} from '@app/@core/@models/advertising/layout/layout-component.model';

// ng.model.layout.ChoiceComponent.Display
export type Display = 'COMBO' | 'RADIO';

// ng.model.layout.ChoiceComponent
export interface ChoiceComponent {
  display: Display
  name?: string
  options: string[]
  required: ComponentRequired
  tag: LayoutTag
  type: 'CHOICE'
}

export function isChoiceComponent(layoutComponent: LayoutComponent): layoutComponent is ChoiceComponent {
  return (layoutComponent.type === 'CHOICE');
}

