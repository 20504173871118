import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl} from '@angular/forms';
import {Asset} from '@app/@core/@models/advertising/asset.model';
import {Creative} from '@app/@core/@models/advertising/creative.model';
import {Layout} from '@app/@core/@models/advertising/layout/layout.model';
import {AssetComponentForm} from '@app/@core/advertising/campaign/layout/asset-comoponent-form';
import {LayoutComponentForm} from '@app/@core/advertising/campaign/layout/layout-component-form';
import {UrlComponentForm} from '@app/@core/advertising/campaign/layout/url-compnent-form';
import {TextComponentForm} from '@app/@core/advertising/campaign/layout/text-component-form';
import {ChoiceComponentForm,} from '@app/@core/advertising/campaign/layout/choice-comoponent-form';
import {ValidationWarningMessage} from '@app/@core/@models/advertising/validation-warning-message.model';
import {AdvertDraft} from '@app/@core/advertising/campaign/advert/advert-draft';
import {ConfigService} from '@app/@core/@config/config.service';
import { Product } from '@app/@core/@models/design/product.model';

@Component({
  selector: 'app-creative-editor[advertDraft][showLoadingOverlays]',
  templateUrl: './creative-editor.component.html',
  styleUrls: ['./creative-editor.component.scss'],
})
export class CreativeEditorComponent implements OnInit {
  @Input() advertDraft!: AdvertDraft;
  @Input() showLoadingOverlays!: boolean;
  product: Product | undefined;
  layouts: Layout[] | undefined;
  layoutId: string | undefined;
  selectedLayout: Layout | undefined;
  assetControls: FormArray<FormControl<Asset>> | undefined;
  creativeId: string = "";
  hasCreativeCopyrightStatus:boolean = false;

  constructor(private configService: ConfigService) {
  }

  ngOnInit(): void {
    this.product = this.advertDraft.campaignDraft.getProduct();
    this.layouts = this.advertDraft.campaignDraft.layoutsForSelectedProduct(this.product);
    this.layoutId = this.advertDraft.advertForm.value.layoutId;
    this.selectedLayout = this.layouts.find(layout => layout.id === this.layoutId)!;

    this.assetControls = this.advertDraft.advertForm.controls.assets;
    this.creativeId = this.advertDraft.advertForm.controls.creativeId.value;
    this.hasCreativeCopyrightStatus = this.configService.getNetworkData()!.hasCreativeCopyrightStatus || false;
  }

  get assets(): FormArray<FormControl<Asset>> {
    return this.advertDraft.advertForm.get('assets') as FormArray<FormControl<Asset>>;
  }

  getAssetArray(): Asset[] {
    const result: Asset[] = [];
    const assets = this.assets;
    for (let i = 0; i < assets.length; i++) {
      result.push(assets.at(i).getRawValue());
    }
    return result;
  }

  getCreativeFromForm(): Creative {
    return this.advertDraft.campaignDraft.creativeFromCreativeForm(
      this.advertDraft.campaignDraft.getProduct()!,
      this.advertDraft.advertForm
    );
  }

  toAssetComponentForm(layoutComponentForm: LayoutComponentForm): AssetComponentForm | undefined {
    if (this.advertDraft.campaignDraft.isAssetComponentForm(layoutComponentForm)) {
      return layoutComponentForm;
    } else {
      return undefined;
    }
  }

  toUrlComponentForm(layoutComponentForm: LayoutComponentForm): UrlComponentForm | undefined {
    if (this.advertDraft.campaignDraft.isUrlComponentForm(layoutComponentForm)) {
      return layoutComponentForm;
    } else {
      return undefined;
    }
  }

  toTextComponentForm(layoutComponentForm: LayoutComponentForm): TextComponentForm | undefined {
    if (this.advertDraft.campaignDraft.isTextComponentForm(layoutComponentForm)) {
      return layoutComponentForm;
    } else {
      return undefined;
    }
  }

  toChoiceComponentForm(layoutComponentForm: LayoutComponentForm): ChoiceComponentForm | undefined {
    if (this.advertDraft.campaignDraft.isChoiceComponentForm(layoutComponentForm)) {
      return layoutComponentForm;
    } else {
      return undefined;
    }
  }

  serverValidationWarnings(layoutComponentForm: LayoutComponentForm): ValidationWarningMessage[] | undefined {
    return this.advertDraft.advertForm.value.creative?.validationWarnings?.filter((validationWarningMessage) => {
      return validationWarningMessage.parameters?.componentTag === layoutComponentForm.value.layoutComponent?.tag;
    })
  }
}
