<div>
  <ng-container *ngIf="data$ | async as data; else loadingOrError">
    <div class="col-auto mr-auto">
      <h1>
        <span class='itemType'>{{ 'campaign.campaign' | translate }}:</span>
        <ng-container>{{ data.campaignDraft.campaignForm.value.name }}</ng-container>
      </h1>
    </div>
    <ngb-alert [dismissible]="false" type="info">
      <div class="vstack gap-2">
        <div>{{ 'campaign.anon.heading' | translate }}</div>
        <div>
          <span [innerHTML]="'campaign.anon.beforeText' | translate"></span><a
            [routerLink]="configService.getUrl('/sign-in/register')"
            [innerHTML]="'campaign.anon.linkText' | translate"
          ></a><span [innerHTML]="'campaign.anon.postText' | translate"></span>
        </div>
      </div>
    </ngb-alert>
    <app-campaign-drafting [campaignDraft]="data.campaignDraft" [disabled]="false"></app-campaign-drafting>
  </ng-container>
  <ng-template #loadingOrError>
    <div class="text-center">
      <i *ngIf="!errorKey" class="fas fa-cog fa-spin"></i>
      <span *ngIf="errorKey">{{ errorKey | translate }}</span>
    </div>
  </ng-template>
</div>
