import { Injectable } from '@angular/core';
import { EphemeralLocalObjectStore } from '@shared/storage/local/ephemeral-local-object-store';
import { CacheRegistry } from '@shared/storage/cache-registry.service';
import { Observable, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthUserPerms } from '../@models/auth/authenticated-user.model';
import _flatten from 'lodash-es/flatten';
import _get from 'lodash-es/get';
import _map from 'lodash-es/map';
import _includes from 'lodash-es/includes';
import { ConfigService } from '@app/@core/@config/config.service';
import {AuthUserService} from '@app/@core/auth/auth-user.service';

/**
 * Loads and caches AuthUserPerms in local storage, flushed on sign-in / sign-out.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthUserPermsService {
  private readonly localAuthUserPerms: EphemeralLocalObjectStore<AuthUserPerms>;

  constructor(
    cacheRegistry: CacheRegistry,
    private authUserService: AuthUserService,
    private configService: ConfigService
  ) {
    this.localAuthUserPerms = new EphemeralLocalObjectStore(cacheRegistry, 'user.perms');
  }

  getAuthUserPerms(): Observable<AuthUserPerms> {
    const authUserPerms = this.localAuthUserPerms.getObject();
    if (authUserPerms) {
      return of(authUserPerms);
    } else {
      return this.getPermissionsFromServer();
    }
  }

  isSelfService(perms: AuthUserPerms): boolean {
    const anyPerms = this.getAnyVisibility(perms);
    return (
      !anyPerms.MANAGE_ADVERTISING &&
      !anyPerms.MANAGE_PUBLISHING &&
      (anyPerms.SELF_SERVICE_TEAM_ADVERTISING || anyPerms.SELF_SERVICE_OWN_ADVERTISING)
    );
  }

  private getAnyVisibility(perms: AuthUserPerms) {
    return {
      SELF_SERVICE_OWN_ADVERTISING: this.hasAny(perms, 'SELF_SERVICE_OWN_ADVERTISING'),
      SELF_SERVICE_TEAM_ADVERTISING: this.hasAny(perms, 'SELF_SERVICE_TEAM_ADVERTISING'),
      MANAGE_ADVERTISING: this.hasAny(perms, 'MANAGE_ADVERTISING'),
      MANAGE_PUBLISHING: this.hasAny(perms, 'MANAGE_PUBLISHING'),
      AX_ADVERTISER: this.hasAny(perms, 'AX_ADVERTISER'),
      AX_PUBLISHER: this.hasAny(perms, 'AX_PUBLISHER'),
      RUN_REPORTS: this.hasAny(perms, 'RUN_REPORTS'),
    };
  }

  private hasAny(perms: AuthUserPerms, permission: string) {
    return this.hasAnyPermission(perms, permission);
  }

  private hasAnyPermission(perms: AuthUserPerms, permission: string) {
    return _includes(this.getPermissions(perms), permission);
  }

  private getPermissions(perms: AuthUserPerms, groupId?: string) {
    let networkId = this.configService.getNetworkData()!.id;
    return _flatten(
      _map(_get(perms, [networkId, 'teamPermissions']), function (val, key) {
        if (groupId) {
          return groupId === key ? val : [];
        }
        return val;
      })
    );
  }

  private getPermissionsFromServer(): Observable<AuthUserPerms> {
    return this.authUserService.getAuthUser().pipe(
      map((authUser) => authUser.permissions),
      tap((permissions) => this.localAuthUserPerms.setObject(permissions))
    );
  }
}
