<div class="vstack gap-3">
  <div *ngIf='paymentCalculation.discountAmount as discount'
       [innerHTML]="'campaign.payment.manual.reserveParagraph0Coupon' | translate: {
                  amount: i18nFormatService.currency(discount),
                  budget: i18nFormatService.currency(campaignDraft.lineItemReview.lineItem.selfServiceBudget) }">
  </div>
  <div>{{ 'campaign.payment.manual.reserveParagraph1' | translate}}</div>
  <div [innerHTML]="'campaign.payment.manual.reserveParagraph2' | translate: {
              amount: i18nFormatService.currency(getChargeAmount(paymentCalculation)),
              imps: i18nFormatService.number(getBudgetedImpressions(), '1.0-0')
              }">
  </div>
  <div *ngIf="!submit$">
    <button [disabled]='submit$ || campaignDraft.campaignForm.dirty' name='submit-and-review'
            type='button' class='btn btn-primary btnPayment' (click)='onSubmit()'>
      <i class='fas fa-check-square me-1'></i>
      <span>{{ 'campaign.payment.manual.reserveButton' | translate }}</span>
    </button>
  </div>
</div>
<ng-container *ngIf="submit$">
  <ng-container *ngIf="submit$ | async"></ng-container>
</ng-container>
