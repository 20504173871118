<div *ngIf='isVisible && !forceOff' class='warningBanner blockedBanner alert-danger'>
  <p>{{ 'adBlockerParagraph1' | translate }}</p>
  <p>{{ 'adBlockerParagraph2' | translate }}</p>
  <p>
    <button type='button' (click)='forceOff=true' class='btn btn-link'>
      <span class='fa fa-times-circle'></span>
      <span>{{ "adBlockerTurnOff" | translate }}</span>
    </button>
  </p>
</div>
