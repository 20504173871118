import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-error[errorKey][objectId]',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() errorKey!: string;
  @Input() objectId?: string;

  constructor() { }

  ngOnInit(): void {
  }

  isObjectNotFound(): boolean {
    return (this.errorKey === 'error.object.not.found');
  }

  isGenericError(): boolean {
    return !this.isObjectNotFound();
  }

  genericErrorTranslationCode(): string {
    if (this.errorKey ===  'error.internal.server') {
      return 'errors.api.generic';
    } else {
      return this.errorKey;
    }
  }

}
