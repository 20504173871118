import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SignInComponent} from '@app/sign-in/sign-in.component';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SignIn404Component} from '@app/sign-in/sign-in-404/sign-in-404.component';
import {RegisterComponent} from '@app/sign-in/register/register.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '@shared';
import {PasswordResetComponent} from '@app/sign-in/password-reset/password-reset.component';
import {
  PwdResetEmailEntryComponent
} from '@app/sign-in/password-reset/pwd-reset-email-entry/pwd-reset-email-entry.component';
import {
  PwdResetEmailSentComponent
} from '@app/sign-in/password-reset/pwd-reset-email-sent/pwd-reset-email-sent.component';
import {
  PwdResetEnterPasswordComponent
} from '@app/sign-in/password-reset/pwd-reset-enter-password/pwd-reset-enter-password.component';
import {SignUpCouponComponent} from '@app/sign-in/register/sign-up-coupon/sign-up-coupon.component';
import {MasqueradeComponent} from '@app/sign-in/masquerade/masquerade.component';
import {EmailFieldComponent} from '@app/sign-in/register/email-field/email-field.component';
import {NameFieldComponent} from '@app/sign-in/register/name-field/name-field.component';
import {CompanyFieldComponent} from '@app/sign-in/register/company-field/company-field.component';
import {VatIdFieldComponent} from '@app/sign-in/register/vat-id-field/vat-id-field.component';
import {PhoneNumberFieldComponent} from '@app/sign-in/register/phone-number-field/phone-number-field.component';
import {LocaleFieldComponent} from '@app/sign-in/register/locale-field/locale-field.component';
import {CaptchaFieldComponent} from '@app/sign-in/register/captcha-field/captcha-field.component';
import {SignInOtherComponent} from '@app/sign-in/sign-in-other/sign-in-other.component';
import { SignUpConditionFieldComponent } from './register/sign-up-condition-field/sign-up-condition-field.component';

@NgModule({
  imports: [CommonModule, NgbModule, TranslateModule, ReactiveFormsModule, RouterModule, SharedModule],
  declarations: [
    SignInComponent, SignIn404Component, RegisterComponent, PasswordResetComponent, PwdResetEmailEntryComponent,
    PwdResetEmailSentComponent, PwdResetEnterPasswordComponent, SignUpCouponComponent,
    MasqueradeComponent,
    EmailFieldComponent, NameFieldComponent, CompanyFieldComponent, VatIdFieldComponent,
    PhoneNumberFieldComponent, LocaleFieldComponent, CaptchaFieldComponent, SignInOtherComponent, SignUpConditionFieldComponent
  ],
})
export class SignInModule {
}
