import {Injectable} from '@angular/core';
import {from, map, Observable, tap} from 'rxjs';
import {LocaleMappingService} from '@app/@i18n/services/locale-mapping.service';
import {registerLocaleData} from '@angular/common';
import {Locale} from '@app/@core/@models/common/locale';

@Injectable({
  providedIn: 'root'
})
export class LocaleDataLoaderService {

  constructor(private localeMappingService: LocaleMappingService) { }

  localeDataLoader(locale: Locale): Observable<undefined> {
    const id = this.localeMappingService.getAngularLocaleId(locale)
    return from(import(
      /* webpackExclude: /\.d\.ts$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-base" */
      `@/../../node_modules/@angular/common/locales/${id}.mjs`)
    ).pipe(
      map(module => module.default),
      tap(data => {
        registerLocaleData(data);
      })
    )
  }

}
