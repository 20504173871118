import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Product} from '@app/@core/@models/design/product.model';
import {FormControl} from '@angular/forms';
import {AdvertDraft} from '@app/@core/advertising/campaign/advert/advert-draft';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-ad-size-selector[product][advertDraft]',
  templateUrl: './ad-size-selector.component.html',
  styleUrls: ['./ad-size-selector.component.scss']
})
export class AdSizeSelectorComponent implements OnInit, OnChanges {
  @Input() product!: Product;
  @Input() advertDraft!: AdvertDraft;
  control!: FormControl<string>;
  uuid: string = Uuid.generate();

  constructor() {
  }

  ngOnInit(): void {
    if (!this.advertDraft.advertForm.controls.creativeSize.value) {
      this.advertDraft.advertForm.controls['creativeSize'].setValue(this.getAllowedCreativeSizes()[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.control = this.advertDraft.advertForm.controls.creativeSize;
  }

  getAllowedCreativeSizes(): string[] {
    return this.advertDraft.campaignDraft.campaignDraftFactory.allowedCreativeSizes(this.product);
  }
}
