import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable} from 'rxjs';
import {Network} from '@app/@core/@models/network/network.model';
import {NetworkResource} from '@app/@core/@rest/admin/network-resource.service';
import {AnonSelfServiceResource} from '@app/@core/@rest/anon/anon-self-service-resource.service';
import {LineItemReview} from '@app/@core/@models/advertising/line-item-review.model';
import _merge from 'lodash-es/merge';
import {CreativeResource} from '@app/@core/@rest/advertising/creative-resource.service';
import {ConfigService} from '@app/@core/@config/config.service';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {AnonCampaignStore} from '@app/@core/anon/anon-campaign-store';
import {ProductData, ProductDataHelper} from '@app/campaigns/campaign/@common/campaign-drafting/product-selection/product-data-helper.service';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {NonNullableFormBuilder} from '@angular/forms';
import {MediaTypeHelper} from '@app/@core/design/media-type-helper.service';
import {CampaignDraftFactory} from '@app/@core/advertising/campaign/campaign-draft-factory.service';
import {CampaignEvent} from '@app/@core/advertising/campaign/campaign-event';
import {CampaignUpdate} from '@app/@core/advertising/campaign/campaign-update';

@Component({
  selector: 'app-new-anon-campaign',
  templateUrl: './new-anon-campaign.component.html',
  styleUrls: ['./new-anon-campaign.component.scss'],
})
export class NewAnonCampaignComponent implements OnInit {
  data$!: Observable<{
    network: Network,
    productData: ProductData,
    campaignDraft: CampaignDraft
  }>;
  campaignUpdate$ = new BehaviorSubject<CampaignUpdate | undefined>(undefined);
  errorKey?: string;

  constructor(
    private networkResource: NetworkResource,
    private anonSelfServiceResource: AnonSelfServiceResource,
    private creativeResource: CreativeResource,
    public configService: ConfigService,
    private anonCampaignStore: AnonCampaignStore,
    private productDataHelper: ProductDataHelper,
    private campaignDraftFactory: CampaignDraftFactory,
    private fb: NonNullableFormBuilder,
    private mediaTypeHelper: MediaTypeHelper
  ) {
  }

  ngOnInit(): void {
    this.data$ = this.getData();
  }

  handleActionResult(lineItemReview: LineItemReview, actionResult: CampaignEvent) {
    if (actionResult.lineItemReviewUpdate && actionResult.reloadLineItemReview) {
      throw "don't specify both update and reload, update implies reload";
    } else if (actionResult.lineItemReviewUpdate && actionResult.save) {
      const updatedLineItemReview = _merge({}, lineItemReview, actionResult.lineItemReviewUpdate);
      this.anonCampaignStore.storeAnonCampaignToLocalStorage(updatedLineItemReview);
      this.campaignUpdate$.next({lineItemReview: updatedLineItemReview, saved: true});
    } else if (actionResult.lineItemReviewUpdate && !actionResult.save) {
      const updatedLineItemReview = _merge({}, lineItemReview, actionResult.lineItemReviewUpdate);
      this.campaignUpdate$.next({ lineItemReview: updatedLineItemReview, saved: false});
    } else if (actionResult.reloadLineItemReview) {
      const lineItemReview = this.anonCampaignStore.loadAnonCampaignFromLocalStorage()!;
      this.campaignUpdate$.next({ lineItemReview , saved: true});
    } else {
      throw "don't know how to handle campaign action result "+actionResult;
    }
  }

  private getInitialData(): Observable<{ network: Network, productData: ProductData, lineItemReview: LineItemReview | undefined }> {
    return forkJoin([
      this.anonSelfServiceResource.getNetwork$(),
      this.productDataHelper.getProductData$({anon: true})
    ]).pipe(
      map(([network, productData]) => ({network, productData})),
      catchError((error: HttpErrorResponse) => {
        this.errorKey = error.error?.code || error.statusText || error;
        return EMPTY;
      }),
      map(({network, productData}) => {
        return ({network, productData, lineItemReview: this.anonCampaignStore.loadAnonCampaignFromLocalStorage()})
      })
    )
  }

  private getData(): Observable<{ network: Network, productData: ProductData, campaignDraft: CampaignDraft }> {
    return combineLatest([
      this.getInitialData(),
      this.campaignUpdate$
    ]).pipe(
      // use most recent version of lineItemReview
      map(([initialData, campaignUpdate]) => ({
        network: initialData.network,
        productData: initialData.productData,
        campaignUpdate: campaignUpdate ? campaignUpdate : { lineItemReview: initialData.lineItemReview, saved: true}
      })),
      // create campaign draft
      map(({ network, productData, campaignUpdate }) => {
        const campaignDraft = this.campaignDraftFactory.newCampaignDraft(this.mediaTypeHelper, this.fb, undefined,
          this.configService.getNetworkData()!, network, true, productData.products.results, productData.layouts.results, campaignUpdate.lineItemReview,
          productData.productSelectionData, campaignUpdate.saved, productData.signUpCoupons);
        campaignDraft.subscribeUpdate(campaignActionResult => this.handleActionResult(campaignDraft.lineItemReview, campaignActionResult));
        return ({ network, productData, lineItemReview: campaignUpdate.lineItemReview, campaignDraft});
      })
    );
  }
}
