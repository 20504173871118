import {Injectable} from '@angular/core';
import {Observable, tap} from 'rxjs';
import {Network} from '@app/@core/@models/network/network.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigService} from '@app/@core/@config/config.service';
import {Coupon} from '@app/@core/@models/design/coupon.model';
import {Layout} from '@app/@core/@models/advertising/layout/layout.model';
import {ResultList} from '@app/@core/@models/common/result-list';
import {convertJsonStringFieldToObject} from '@shared/utils/json-response-util';
import {ProductSelectionData} from '@app/@core/@models/network/network-data.model';
import {ProductQueryParams} from '@app/@core/@rest/design/product-resource.service';
import {Product} from '@app/@core/@models/design/product.model';
import {addToHttpParams, PersistenceQueryOptions} from '../../@models/persistence/persistence-query-options';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnonSelfServiceResource {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  getNetwork$(): Observable<Network> {
    const networkId = this.configService.getNetworkData()?.id!;
    const endPoint: string = this.configService.getVersionedUri('sui/network');
    return this.httpClient.get<Network>(`${endPoint}?context=${networkId}`);
  }

  getProducts$(params: ProductQueryParams): Observable<ResultList<Product>> {
    return this.httpClient
      .get<ResultList<Product>>(this.configService.getVersionedUri('sui/product'), {
        params: new HttpParams()
          .set('context', params.context)
          .set('excludeInvalid', params.excludeInvalid)
          .set('includeActive', params.includeActive)
          .set('includeHidden', params.includeHidden)
          .set('includeInactive', params.includeInactive)
          .set('orderBy', params.orderBy)
          .set('pageSize', params.pageSize),
      }).pipe(
        map((queryResult) => {
          queryResult.results.forEach(product => {
            product.data = convertJsonStringFieldToObject(product,"data").data;
          })
          return queryResult;
        })
      );
  }

  getCoupon(code: string): Observable<Coupon> {
    const networkId = this.configService.getNetworkData()!.id;
    return this.httpClient.get<Coupon>(this.configService.getVersionedUri(['sui', 'coupon', code]), {
      params: new HttpParams().set('context', networkId),
    });
  }

  getLayouts$(options: PersistenceQueryOptions, ids: string[]): Observable<ResultList<Layout>> {
    let httpParams = new HttpParams()
      .set('context', this.configService.getNetworkData()!.id)
      .set('id', ids.join(';'))
    httpParams = addToHttpParams(options, httpParams);
    return this.httpClient.get<ResultList<Layout>>(
      this.configService.getVersionedUri(['sui', 'layout']),
      {params: httpParams}
    )
  }

  getProductFilters$(): Observable<ResultList<ProductSelectionData>> {
    const networkId = this.configService.getNetworkData()?.id!;
    return this.httpClient.get<ResultList<ProductSelectionData>>(
      this.configService.getVersionedUri(['sui', 'networkdata']),
      {
        params: new HttpParams()
          .set('context', networkId)
          .set('filterBy', 'type')
          .set('filterByValue', 'productSelection')
      }
    ).pipe(
      tap((resultList) => {
        resultList.results.forEach(model => {
          model.data = convertJsonStringFieldToObject(model, "data").data;
        })
      })
    );
  }

}
