import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DATE_PIPE_DEFAULT_OPTIONS, DatePipe, DatePipeConfig} from '@angular/common';
import {DateTime} from 'luxon';

// This fixes bug seen in DatePipe as described in https://github.com/angular/angular/issues/20225
// where DatePipe DATE_PIPE_DEFAULT_TIMEZONE doesn't support timezone abbreviations, and therefore it
// can't handle daylight savings.
// We can't just pass an offset such as -0500 through DATE_PIPE_DEFAULT_TIMEZONE, because the offset varies with value
// being formatted, eg. "America/Chicago", offset would be UTC-5 (CDT) or UTC-6 (CST) depending on the date being formatted.
// Angular team have closed the bug and expect developers to fix it outside angular framework.
@Pipe({
  name: 'date'
})
export class LuxonDatePipe extends DatePipe implements PipeTransform {

  readonly timezoneProvider: DatePipeConfig;

  constructor(@Inject(LOCALE_ID) locale: string, @Inject(DATE_PIPE_DEFAULT_OPTIONS) timezone: DatePipeConfig) {
    super(locale, timezone.timezone);
    this.timezoneProvider = timezone;
  }

  // @ts-ignore
  override transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null {
    let offset;
    if (typeof value === 'number') {
      offset = DateTime.fromMillis(value as number, {zone: this.timezoneProvider.timezone}).toFormat("ZZ");
    } else if (value instanceof Date) {
      offset = DateTime.fromJSDate(value as Date, {zone: this.timezoneProvider.timezone}).toFormat("ZZ");
    } else {
      offset = DateTime.fromISO(value, {zone: this.timezoneProvider.timezone}).toFormat("ZZ");
    }
    return super.transform(value, format, offset, locale);
  }

}
