<div class="card">
  <div class="card-header">
    <h3>{{ 'signIn.resetPasswordHeading' | translate}}</h3>
  </div>
  <div class="card-body">
    <ngb-alert [dismissible]="false" type="secondary">
      <strong>{{ 'signIn.passwordResetEmailSentHeading' | translate}}</strong>
      <div>{{ 'signIn.passwordResetEmailSentExplanation' | translate}}</div>
    </ngb-alert>
  </div>
  <div class="card-footer">
    <div class="text-end"><small>{{ 'adn.poweredBy' | translate }}</small></div>
  </div>
</div>
