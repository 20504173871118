import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ConfigService} from '@app/@core/@config/config.service';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {finalize, Observable, of} from 'rxjs';
import {CreativeResource} from '@app/@core/@rest/advertising/creative-resource.service';

@Component({
  selector: 'app-campaign-drafting[campaignDraft][disabled]',
  templateUrl: './campaign-drafting.component.html',
  styleUrls: ['./campaign-drafting.component.scss'],
})
export class CampaignDraftingComponent implements OnInit, OnChanges {
  @Input() campaignDraft!: CampaignDraft
  @Input() disabled!: boolean
  isSubmitting: boolean = false;
  createMissingCreative$?: Observable<{ errorKey?: string }>;

  constructor(
    public configService: ConfigService,
    private creativeResource: CreativeResource
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleMissingCreatives();
  }

  showLoadingOverlays(): boolean {
    return !!this.createMissingCreative$ || this.isSubmitting || this.disabled;
  }

  submitValidationWarnings() {
    return this.campaignDraft.lineItemReview.lineItem.validationWarnings.filter(
      (message) => {
        return message.code !== 'validation.warning.lineItem.unapproved'
      }
    )
  }

  showSubmitCampaignComponent(): boolean {
    return this.submitValidationWarnings().length === 0 && this.campaignDraft.canSubmit();
  }

  handleMissingCreatives() {
    if (this.campaignDraft.lineItemReview.creatives.length > 0) {
      return;
    }
    const productId = this.campaignDraft.lineItemReview.lineItem.product!.id;
    const product = this.campaignDraft.products.find((p) => p.id === productId);
    if (!product) {
      return;
    }
    this.createMissingCreative$ = this.creativeResource.update(
      this.campaignDraft.campaignDraftFactory.newCreative(
        product,
        this.campaignDraft.lineItemReview.lineItem.id,
        this.campaignDraft.lineItemReview.lineItem.name)
    ).pipe(
        // success, reload lineItemReview from server
        map(() => {
          this.campaignDraft.sendUpdate({reloadLineItemReview: true});
          return ({});
        }),
        // error handling
        catchError((error: HttpErrorResponse) => {
          return of({errorKey: error.error?.code || error.statusText || error});
        }),
        // clean up after sequence is done
        finalize(() => this.createMissingCreative$ = undefined)
      );
  }
}
