import {Component, Input, OnInit} from '@angular/core';
import {Product} from '@app/@core/@models/design/product.model';
import {TargetingForm} from '@app/@core/advertising/campaign/advert/targeting-form';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-domain-name-targeting-options[product][targetingForm]',
  templateUrl: './domain-name-targeting-options.component.html',
  styleUrls: ['./domain-name-targeting-options.component.scss']
})
export class DomainNameTargetingOptionsComponent implements OnInit {
  @Input() product!: Product;
  @Input() targetingForm!: TargetingForm;
  uuid: string = Uuid.generate();

  constructor() { }

  ngOnInit(): void {
  }

  selectedDomainNames(): string[] {
    const result: string[] = [];
    for (let i = 0; i < this.targetingForm.value.domainNameTargets!.length; i++) {
      if (this.targetingForm.value.domainNameTargets![i]) {
        const domainNameTarget = this.getDomainNameTargetFromIndex(i);
        if (domainNameTarget) {
          result.push(domainNameTarget);
        }
      }
    }
    return result;
  }

  getDomainNameTargetFromIndex(index: number): string | undefined {
    const domainNameTargets = this.product.creativeTargeting.domainNameTarget;
    if (domainNameTargets?.names) {
      return domainNameTargets?.names[index];
    }
    return undefined;
  }

}
