import {Component, Input, OnInit} from '@angular/core';
import {EMPTY, finalize, Observable, of, switchMap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {AnonSelfServiceResource} from '@app/@core/@rest/anon/anon-self-service-resource.service';
import {CouponForm} from '@app/sign-in/register/@form/coupon-form';

@Component({
  selector: 'app-sign-up-coupon[couponForm]',
  templateUrl: './sign-up-coupon.component.html',
  styleUrls: ['./sign-up-coupon.component.scss'],
})
export class SignUpCouponComponent implements OnInit {
  @Input() couponForm!: CouponForm;
  couponCodeCheck$?: Observable<string>;
  loading?: boolean
  errorKey?: string;
  invalidCouponCode?: string;

  constructor(private anonSelfServiceResource: AnonSelfServiceResource) {
  }

  ngOnInit(): void {
  }

  onCheckCouponCode() {
    if (this.couponForm.value.couponCode) {
      this.errorKey = undefined;
      this.invalidCouponCode = undefined;
      this.loading = true;
      this.couponCodeCheck$ = of(this.couponForm.value.couponCode).pipe(
        // check coupon
        switchMap((code) =>
          this.anonSelfServiceResource.getCoupon(code).pipe(
            map(() => code),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 404) {
                this.couponForm.controls.couponCode.setValue('');
                this.invalidCouponCode = code;
              } else {
                this.errorKey = error.error?.code || error.statusText || error;
              }
              return EMPTY;
            }),
            finalize(() => this.loading = false)
          ),
        ),
      );
    }
  }

}
