import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '@shared/logging/logger';

const log = new Logger('HttpErrorInterceptor');

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error) => HttpErrorInterceptor.errorHandler(error))
      );
  }

  private static errorHandler(error: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.productionBuild) {
      log.error(error);
    }
    throw error;
  }
}
