import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/@core/@config/config.service';

@Component({
  selector: 'app-localhost-env-banner',
  templateUrl: './localhost-env-banner.component.html',
  styleUrls: ['./localhost-env-banner.component.scss'],
})
export class LocalhostEnvBannerComponent implements OnInit {
  isVisible: boolean = false;
  env: string = "";

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.isVisible = location.host.startsWith('localhost') && !this.configService.apiConfig().getId().startsWith('localhost');
    this.env = this.configService.apiConfig().getId().split('_')[0].toUpperCase();
  }
}
