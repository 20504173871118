import {Inject, Injectable} from '@angular/core';
import {ROLLBAR_INSTANCE} from '@shared/logging/rollbar';
import * as Rollbar from 'rollbar';
import {ConfigService} from '@app/@core/@config/config.service';


@Injectable({
  providedIn: 'root'
})
export class RollbarService {

  constructor(@Inject(ROLLBAR_INSTANCE) private rollbar: Rollbar,
              private configService: ConfigService) { }

  setUserId(userId?: string) {
    this.rollbar.configure({
      payload: {
        person: {
          id: userId || 'undefined'
        },
      }
    });
  }

  updateNetworkId() {
    const networkData = this.configService.getNetworkData();
    this.rollbar.configure({
      payload: {
        custom: {
          networkId: networkData?.id || 'undefined'
        },
      }
    });
  }

}
