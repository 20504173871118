<div class='mb-2' [formGroup]='advertDraft.advertForm' *ngIf="'ad-size-group'+uuid as idGroup">
  <label class='form-label d-block' [for]='idGroup'>
    <strong>{{ 'campaign.ad.adSizeTitle' | translate }}</strong>
  </label>
  <div class='btn-group mb-1 has-validation' [ngClass]="{ 'is-invalid': control.invalid }" [id]='idGroup'
       role='group' *ngIf='getAllowedCreativeSizes() as allSizes'>
    <ng-container *ngIf='allSizes.length > 4'>
      <select class="form-select" [id]="'select'+uuid"
              [formControl]='advertDraft.advertForm.controls.creativeSize'
              [ngClass]="{ 'is-invalid': control.invalid}">
        <ng-container *ngFor="let opt of allSizes">
          <option [value]="opt">{{ opt }}</option>
        </ng-container>
      </select>
    </ng-container>
    <ng-container *ngIf='allSizes.length <= 4'>
      <ng-container *ngFor='let d of allSizes; let i = index'>
        <ng-container *ngIf="allSizes.length <= 4 && 'option_'+uuid+i as idRadio">
          <input
            type='radio'
            class='btn-check form-check-input'
            [id]='idRadio'
            [name]="'ad-size'+uuid"
            [formControl]='advertDraft.advertForm.controls.creativeSize'
            value='{{ d }}'
            autocomplete='off'
          />
          <label class='btn btn-outline-secondary'
                 [ngClass]="{
              'border-danger': control.invalid,
              'fw-bolder': d === advertDraft.advertForm.value.creativeSize
              }"
                 [for]='idRadio'>
            {{ d }}
          </label>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div *ngFor='let err of control.errors | keyvalue' class='invalid-feedback'>
    {{ 'validation.' + err.key | translate }}
  </div>
</div>
