export const COMPANY_TRANSLATIONS_ES = {
  v8t7: {
    campaign: {
      campaignInfo: `<p class="importantHighlight"><strong>Llamar al +34 615 335 382 si necesita asistencia</strong></p>`,
      campaignSubInfo: `<p class="importantHighlight"><strong>Llamar al +34 615 335 382 si necesita asistencia</strong></p>`,
    },
    location: {
      Spain: '<strong>España</strong>',
    },
    frontPage: `
      <div><img src="https://regalia.adnuntius.com/label/confi/confi-logo.png" width="100%"></div>
      <h2 style="font-size: 18px">El Autoservicio de la Publicidad</h2>
      <h3>4 sencillos pasos</h3>
      <ol>
      <li>Acceda a su cuenta</li>
      <li>Elija su presupuesto</li>
      <li>Defina su creatividad</li>
      <li>Lance su campaña</li>
      </ol>
      <p class="form-text small">El Confidencial Digital es la web de las personas informadas que desean estar más informadas.</p>
      <p class="importantHighlight"><strong>Llamar al +34 615 335 382 si necesita asistencia</strong></p>
    `,
    emailFooter: 'Contáctenos en <strong>cliente@elconfidencialdigital.es</strong>',
  },
};
