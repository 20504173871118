import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {
  CommonModule,
  CurrencyPipe, DATE_PIPE_DEFAULT_OPTIONS,
  DecimalPipe,
  PercentPipe
} from '@angular/common';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LocaleIdService} from '@app/@i18n/services/locale-id.service';
import {DefaultCurrencyCodeService} from '@app/@i18n/services/default-currency-code.service';
import {TimeZoneService} from '@app/@i18n/services/time-zone.service';
import {LuxonDatePipe} from '@app/@i18n/pipes/luxon-date.pipe';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule],
  declarations: [LuxonDatePipe],
  exports: [LuxonDatePipe],
  providers: [
    {
      provide: LOCALE_ID,
      useExisting: LocaleIdService,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useExisting: DefaultCurrencyCodeService,
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useExisting: TimeZoneService
    },
    CurrencyPipe,
    DecimalPipe,
    LuxonDatePipe,
    TranslatePipe,
    PercentPipe,
  ],
})
export class I18nModule {
}
