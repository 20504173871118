// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { git_env } from './.env.git-env';
import { adn_env } from '@env/.env.adn-env';

export const environment = {
  productionBuild: true,
  version: '' + git_env['sha'],
  ADN_ENV_KEY: adn_env['ADN_ENV_KEY'],
};
