import { Component, Input, OnInit } from '@angular/core';
import { Asset } from '@app/@core/@models/advertising/asset.model';
import {AssetComponent} from '@app/@core/@models/advertising/layout/asset-component.model';
import {MediaTypeHelper} from '@app/@core/design/media-type-helper.service';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-existing-asset[asset][creativeId][invalid][anon][assetComponent]',
  templateUrl: './existing-asset.component.html',
  styleUrls: ['./existing-asset.component.scss'],
})
export class ExistingAssetComponent implements OnInit {
  @Input() asset!: Asset;
  @Input() creativeId!: string;
  @Input() invalid!: boolean;
  @Input() anon!: boolean;
  @Input() assetComponent!: AssetComponent;
  base64size?: string;

  constructor(private mediaTypeHelper: MediaTypeHelper) {}

  ngOnInit(): void {
  }

  assetMediaTypeId(): string | undefined {
    return this.mediaTypeHelper.getSupportedMediaType(this.asset!.mimeType)?.id;
  }

  onBase64ImageLoad($event: Event) {
    if ($event.target) {
      const target: HTMLImageElement = $event.target as HTMLImageElement;
      this.base64size = target.naturalWidth + "x" + target.naturalHeight;
    }
  }
}
