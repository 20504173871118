import {ComponentRequired, LayoutTag} from '@app/@core/@models/advertising/layout/layout.model';
import {LayoutComponent} from '@app/@core/@models/advertising/layout/layout-component.model';

// ng.model.layout.UrlComponent
export interface UrlComponent {
  name?: string
  tag: LayoutTag
  type: 'URL'
  required: ComponentRequired
}

export function isUrlComponent(layoutComponent:LayoutComponent): layoutComponent is UrlComponent {
  return (layoutComponent.type === 'URL');
}
