import {Component, Input, OnInit} from '@angular/core';
import { RegisterForm } from '../@form/register-form';

@Component({
  selector: 'app-company-field[registerForm][showValidation]',
  templateUrl: './company-field.component.html',
  styleUrls: ['./company-field.component.scss']
})
export class CompanyFieldComponent implements OnInit {
  @Input() registerForm!: RegisterForm;
  @Input() showValidation!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  isInvalid(): boolean {
    return this.showValidation && this.registerForm.controls.companyName.invalid;
  }

}
