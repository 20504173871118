import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import {I18nLangService} from '@app/@i18n/services/i18n-lang.service';

@Component({
  selector: 'app-footer-overlay',
  templateUrl: './footer-overlay.component.html',
  styleUrls: ['./footer-overlay.component.scss'],
})
export class FooterOverlayComponent implements OnInit {
  version: string | null = environment.version;

  constructor(public i18nLangService: I18nLangService) {}

  ngOnInit(): void {}
}
