import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigService} from '@app/@core/@config/config.service';
import {Layout} from '@app/@core/@models/advertising/layout/layout.model';
import {Observable} from 'rxjs';
import {ResultList} from '@app/@core/@models/common/result-list';
import {addToHttpParams, PersistenceQueryOptions} from '@app/@core/@models/persistence/persistence-query-options';

@Injectable({
  providedIn: 'root'
})
export class LayoutsResource {

  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  getLayouts$(options: PersistenceQueryOptions, ids: string[]): Observable<ResultList<Layout>> {
    let httpParams = new HttpParams()
      .set('context', this.configService.getNetworkData()!.id)
      .set('id', ids.join(';'))
    httpParams = addToHttpParams(options, httpParams);
    return this.httpClient.get<ResultList<Layout>>(
      this.configService.getVersionedUri('layouts'),
      {params: httpParams}
    )
  }
}
