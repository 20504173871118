import {ErrorHandler, Inject, Injectable} from '@angular/core';
import * as Rollbar from 'rollbar';
import {Logger} from '@shared/logging/logger';
import {environment} from '@env/environment';
import {ROLLBAR_INSTANCE} from '@shared/logging/rollbar';
import {RollbarService} from '@shared/logging/rollbar.service';

const log = new Logger('RollbarErrorHandler');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(ROLLBAR_INSTANCE) private rollbar: Rollbar,
              private rollbarService: RollbarService) {}

  handleError(err:any) : void {
    if (!location.host.startsWith('localhost')) {
      this.rollbarService.updateNetworkId();
      this.rollbar.error(err.originalError || err);
    }
    if (!environment.productionBuild) {
      log.error(err.originalError || err);
    }
  }
}
