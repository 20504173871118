import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';
import {ConfigService} from '@app/@core/@config/config.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordResetUpdateEvent, ResetStep} from '@app/sign-in/password-reset/password-reset.component';
import {EMPTY, finalize, Observable, tap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserResource} from '@app/@core/@rest/users/user-resource.service';
import {AuthService} from '@app/@core/auth/auth.service';
import {AccessToken} from '@app/@core/@models/auth/access-token.model';

type PwdResetForm = FormGroup<{
  password: FormControl<string>
  passwordConfirm: FormControl<string>
}>;

@Component({
  selector: 'app-pwd-reset-enter-password[token][parentUpdate]',
  templateUrl: './pwd-reset-enter-password.component.html',
  styleUrls: ['./pwd-reset-enter-password.component.scss'],
})
export class PwdResetEnterPasswordComponent implements OnInit {
  @Input() token!: string;
  @Output() parentUpdate = new EventEmitter<PasswordResetUpdateEvent>();
  formGroup!: PwdResetForm;
  resetSequence$?: Observable<AccessToken>;
  errorKey?: string;

  constructor(
    private fb: NonNullableFormBuilder,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private userResource: UserResource,
    private router: Router,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required],
    });
  }

  public doResetSequence() {
    if (this.formGroup.value.password!.length < 8) {
      this.errorKey = 'signIn.passwordHint';
      return;
    }
    if (this.formGroup.value.password !== this.formGroup.value.passwordConfirm) {
      this.errorKey = 'signIn.confirmPasswordMismatch';
      return;
    }
    this.errorKey = undefined;
    this.resetSequence$ = this.userResource.resetPassword(this.token, this.formGroup.value.password!)
      .pipe(
        // success
        tap((accessToken) => {
          this.authService.setAccessToken(accessToken);
          this.router.navigate([this.configService.getUrl('')], {replaceUrl: true});
        }),
        // error handling
        catchError(error => {
          this.errorKey = error.error?.code || error.statusText || error;
          if (this.errorKey === "error.unauthorised") {
            this.parentUpdate.next({
              nextStep: ResetStep.EmailEntry,
              errorKey: 'signIn.passwordLinkExpired'
            });
          }
          return EMPTY;
        }),
        // clean-up
        finalize(() => this.resetSequence$ = undefined)
      );
  }

}
