import { Injectable } from '@angular/core';
import {PermanentLocalObjectStore} from '@shared/storage/local/permanent-local-object-store';
import {LineItemReview} from '@app/@core/@models/advertising/line-item-review.model';
import {CacheRegistry} from '@shared/storage/cache-registry.service';
import {ConfigService} from '@app/@core/@config/config.service';

@Injectable({
  providedIn: 'root'
})
export class AnonCampaignStore {
  private readonly localCampaign: PermanentLocalObjectStore<LineItemReview>;

  constructor(cacheRegistry: CacheRegistry, configService: ConfigService) {
    const key = 'anon_campaign-' + configService.getNetworkData()?.id
    this.localCampaign = new PermanentLocalObjectStore<LineItemReview>(cacheRegistry, key);
  }

  loadAnonCampaignFromLocalStorage(): LineItemReview | undefined {
    return this.localCampaign.getObject();
  }

  storeAnonCampaignToLocalStorage(lineItemReview: LineItemReview) {
    this.localCampaign.setObject(lineItemReview);
  }

  removeAnonCampaignFromLocalStorage() {
    this.localCampaign.removeObject();
  }

}
