import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Asset} from '@app/@core/@models/advertising/asset.model';
import {FormArray, FormControl} from '@angular/forms';
import {AssetComponent} from '@app/@core/@models/advertising/layout/asset-component.model';
import {I18nFormatService} from '@app/@i18n/services/i18n-format.service';
import {AssetComponentForm} from '@app/@core/advertising/campaign/layout/asset-comoponent-form';
import {ValidationWarningMessage} from '@app/@core/@models/advertising/validation-warning-message.model';
import {MediaTypeHelper} from '@app/@core/design/media-type-helper.service';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-asset-layout-component[anon][creativeId][assetComponentForm][assets][serverValidationWarnings]',
  templateUrl: './asset-layout.component.html',
  styleUrls: ['./asset-layout.component.scss']
})
export class AssetLayoutComponent implements OnInit, OnChanges {
  @Input() anon!: boolean;
  @Input() creativeId!: string;
  @Input() assetComponentForm!: AssetComponentForm;
  @Input() assets!: FormArray<FormControl<Asset>>;
  @Input() serverValidationWarnings?: ValidationWarningMessage[];

  constructor(public i18nFormat: I18nFormatService,
              private mediaTypeHelper: MediaTypeHelper) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  fileUploaded(asset: Asset) {
    this.assetIdControl().setValue(asset.id);
    this.assetIdControl().markAsDirty();
    this.assets.push(new FormControl(asset, {nonNullable: true}));
    this.assets.markAsDirty();
  }

  getMediaTypeTranslationsCS(): string {
    const typeTranslations = [];
    for (let mediaType of this.mediaTypeHelper.getSupportedMediaTypes(this.assetComponent())) {
      const key = 'media.' + mediaType.id;
      const typeTranslation = this.i18nFormat.translate(key);
      if (key !== typeTranslation) {
        typeTranslations.push(typeTranslation);
      }
    }
    return typeTranslations.join(', ');
  }

  assetComponent(): AssetComponent {
    return this.assetComponentForm.value.layoutComponent!;
  }

  assetIdControl(): FormControl<string> {
    return this.assetComponentForm.controls.componentValue;
  }

  getAsset(): Asset | undefined {
    const assetId = this.assetIdControl().value;
    return this.assets.value.find(asset => asset.id === assetId);
  }

  isInvalid(): boolean {
    return this.assetIdControl().invalid || this.invalidSize() || this.showServerValidationWarning();
  }

  showServerValidationWarning(): boolean {
    return this.assetIdControl().pristine && !!this.serverValidationWarnings?.length;
  }

  invalidSize(): boolean {
    const asset = this.getAsset();
    return !!asset && asset.fileSizeBytes > this.assetComponent().maxFileSizeBytes;
  }
}
