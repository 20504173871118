<ng-container [formGroup]="targetingForm">
  <hr class='subDivider'>
  <h3>{{ 'campaign.device.title' | translate }}</h3>
  <div formArrayName="deviceTargets" class="d-flex flex-column align-items-center">
    <div class="mb-1">
      <span [innerHTML]="'campaign.device.descriptionPrefix' | translate"></span><span
      *ngIf="getSelectedDevicePropertyTargets() as selectedDeviceTargets"><strong
      *ngIf="selectedDeviceTargets.length === 0" translate="campaign.device.any"></strong><span
      *ngIf="selectedDeviceTargets.length > 0"><span
      *ngFor="let selectedItem of selectedDeviceTargets; let isLastTarget=last"><strong><span
      *ngFor="let deviceType of getDevices(selectedItem); let isLastDevice=last">{{'devices.' + deviceType | translate}}<span
      *ngIf="!isLastDevice">,</span> </span></strong><span
      *ngIf="!isLastTarget" translate="grammar.booleanOr"></span> </span></span></span>
    </div>
    <div class="">
      <ng-container *ngFor="let control of targetingForm.controls.deviceTargets.controls; let i = index">
        <span class="form-check form-check-inline" *ngIf="'devicePropertyTarget_'+uuid+i as id">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="i"
            [id]="id"
          />
          <label class="form-check-label" [for]="id">
            <ng-container *ngFor="let deviceType of getDevicesFromIndex(i); let isLast=last">
              {{'devices.' + deviceType | translate}}<span *ngIf="!isLast">,</span>
            </ng-container>
          </label>
        </span>
      </ng-container>
    </div>
    <div class="form-text mt-0">{{ 'campaign.device.instructions' | translate }}</div>
  </div>
</ng-container>
