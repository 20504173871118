import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/@core/@config/config.service';

@Component({
  selector: 'app-sign-in-other',
  templateUrl: './sign-in-other.component.html',
  styleUrls: ['./sign-in-other.component.scss']
})
export class SignInOtherComponent implements OnInit {
  signInUrl = "";
  registerUrl = "";

  constructor(public configService: ConfigService) { }

  ngOnInit(): void {
    this.signInUrl = this.configService.getUrl('/sign-in');
    this.registerUrl = this.configService.getUrl('/sign-in/register');
  }
}
