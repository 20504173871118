<ng-container [formGroup]="urlComponentForm">
  <label [for]="'url'+uuid" class="form-label">{{ urlComponent().name || urlComponent().tag }}</label>
  <div class="has-validation">
    <input
      type="url"
      class="form-control"
      [id]="'url'+uuid"
      formControlName="componentValue"
      [ngClass]="{ 'is-invalid': isInvalid() }"
      data-lpignore="true"
      (ngModelChange)="prefixHttps($event)"
    />
    <div [ngClass]="{ 'invalid-feedback': isInvalid()}" class="form-text">
      <div *ngIf="urlComponent().required === 'MANDATORY'" translate="campaign.ad.urlHint"></div>
      <div *ngIf="urlComponent().required === 'OPTIONAL'" translate="campaign.ad.urlHintOptional"></div>
      <ng-container *ngIf="isInvalid()">
        <div *ngFor="let err of urlControl().errors | keyvalue">
          {{ 'validation.' + err.key | translate }}
        </div>
        <ng-container *ngIf="showServerValidationWarning()">
          <ng-container *ngFor="let warning of serverValidationWarnings">
            <div *ngIf="warning.code !== 'validation.warnings.creative.missing.component'">{{ i18nFormat.translateValidationWarningMessage(warning) }}</div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
