import {NgModule} from '@angular/core';
import {Routes, RouterModule, Route, TitleStrategy} from '@angular/router';
import {SignInComponent} from '@app/sign-in/sign-in.component';
import {SignIn404Component} from '@app/sign-in/sign-in-404/sign-in-404.component';
import {PageNotFoundComponent} from '@shared/page-not-found/page-not-found.component';
import {AuthShellComponent} from '@app/@shells/auth-shell/auth-shell.component';
import {AnonShellComponent} from '@app/@shells/anon-shell/anon-shell.component';
import {NewAnonCampaignComponent} from '@app/campaigns/campaign/anonymous-campaign/new-anon-campaign.component';
import {RegisterComponent} from '@app/sign-in/register/register.component';
import {PasswordResetComponent} from '@app/sign-in/password-reset/password-reset.component';
import {SignedInShellComponent} from '@app/@shells/signed-in-shell/signed-in-shell.component';
import {HomeComponent} from '@app/home/home.component';
import {AnonCampaignGuard} from '@app/@core/@guards/anon-campaign.guard';
import {MasqueradeComponent} from '@app/sign-in/masquerade/masquerade.component';
import {MatchesUrlSectionGuard} from '@app/@core/@guards/matches-url-section.guard';
import {NetworkKnownGuard} from '@app/@core/@guards/network-known.guard';
import {SignInOtherComponent} from '@app/sign-in/sign-in-other/sign-in-other.component';
import {AuthenticationGuard} from '@app/@core/@guards/authentication.guard';
import {TitleService} from '@app/@shells/title/title.service';
import {RollbarTestComponent} from '@app/rollbartest/rollbar-test.component';

function signInRoutes(): Route[] {
  return [
    {path: '', component: SignInComponent, pathMatch: 'full'},
    {path: 'register', component: RegisterComponent, pathMatch: 'full'},
    {path: 'password-reset', component: PasswordResetComponent, pathMatch: 'full'},
    {path: 'masquerade', component: MasqueradeComponent, pathMatch: 'full'},
    {path: 'sign-in-other', component: SignInOtherComponent, pathMatch: 'full'},
  ];
}

function signedInRoutes(): Route[] {
  return [
    {path: 'home', component: HomeComponent, pathMatch: 'full',},
    {path: 'rollbar-test', component: RollbarTestComponent, pathMatch: 'full'},
    {
      path: 'campaigns',
      loadChildren: () => import('./campaigns/campaign.module').then((m) => m.CampaignModule),
    },
    {
      path: 'reports',
      loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
    },
    {
      path: 'settings',
      loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    },

  ];
}

function createAppRoutes(): Route[] {
  return [
    {path: '', redirectTo: 'campaigns', pathMatch: 'full'},
    {path: 'rollbar-test-no-user', component: RollbarTestComponent, pathMatch: 'full'},
    {path: 'sign-in', canActivate: [NetworkKnownGuard], component: AuthShellComponent, children: signInRoutes()},
    {
      path: '404',
      canActivate: [NetworkKnownGuard, AuthenticationGuard],
      component: SignedInShellComponent,
      children: [{ path: '', component: PageNotFoundComponent }],
      pathMatch: 'full'
    },
    {
      path: 'campaigns/campaign/anonymous-campaign',
      canActivate: [AnonCampaignGuard],
      component: AnonShellComponent,
      children: [{path: '', component: NewAnonCampaignComponent}],
      title: 'campaign.campaign',
      pathMatch: 'full',
    },
    {
      path: '',
      canActivate: [NetworkKnownGuard, AuthenticationGuard],
      component: SignedInShellComponent,
      children: signedInRoutes()
    },
  ];
}

const allRoutes: Routes = [
  {
    path: 'sign-in-404',
    component: AuthShellComponent,
    children: [{path: '', component: SignIn404Component}],
    pathMatch: 'full',
  },
  {path: 'rollbar-test-no-network', component: RollbarTestComponent, pathMatch: 'full'},
  ...createAppRoutes(),
  {
    path: ':network', // urlSection support, this must be the last route
    canActivate: [MatchesUrlSectionGuard],
    children: [
      ...createAppRoutes(),
      {path: '**', redirectTo: '404', pathMatch: 'full'},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(allRoutes, {enableTracing: false, urlUpdateStrategy: 'eager'})],
  exports: [RouterModule],
  providers: [{provide: TitleStrategy, useExisting: TitleService}],
})
export class AppRoutingModule {
}
