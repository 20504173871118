import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import {ConfigService} from '@app/@core/@config/config.service';
import {NetworkData} from '@app/@core/@config/registered-urls-data';
import {I18nLangService} from '@app/@i18n/services/i18n-lang.service';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss'],
})
export class FooterBarComponent implements OnInit {
  version: string | null = environment.version;
  networkData: NetworkData;

  constructor(private configService: ConfigService, public i18nLangService: I18nLangService) {
    this.networkData = this.configService.getNetworkData()!;
  }

  ngOnInit(): void {}
}
