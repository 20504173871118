<div class='upload' [ngClass]="{' border-danger': invalid, 'hovering': highlightDropzone}">
  <div
    class='drop-zone'
    (dragover)='onDragOver($event)'
    (dragenter)='onDragEnter($event)'
    (dragleave)='onDragLeave($event)'
    (drop)='onDrop($event)'
  >
    <ng-container *ngIf='!uploading'>
      {{ 'button.upload.drop' | translate }}
    </ng-container>
    <ngb-progressbar [showValue]="true" [height]="'1.3rem'" class='flex-fill' *ngIf='uploading' type='primary'
                     [value]='uploadPercentage'></ngb-progressbar>
  </div>
</div>
<div class='text-center' *ngIf="'upload'+uuid as id">
  <label *ngIf='!uploading' [for]='id' class='btn btn-outline-dark'>
    <i class='fas fa-cloud-upload-alt'></i>
    {{ 'button.upload.asset' | translate }}
    <input [id]='id' type='file' [accept]='getAcceptMediaTypesCS()' hidden (change)='handleFileInput($event)' />
  </label>
</div>
