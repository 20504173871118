import { HttpParams } from '@angular/common/http';

export type OrderByDirection = 'ASCENDING' | 'DESCENDING';

// ng.services.persistence.PersistenceQueryOptions
export interface PersistenceQueryOptions {
  concise?: boolean;
  includeActive?: boolean;
  includeHidden?: boolean;
  includeInactive?: boolean;
  joins?: string[];
  where?: string;
  orderBy?: string;
  orderByDirection?: OrderByDirection;
  page?: number;
  pageSize: number;
}

export function addToHttpParams(queryOptions: PersistenceQueryOptions, httpParams: HttpParams): HttpParams {
  let result = httpParams;
  if (queryOptions.concise !== undefined) {
    result = result.set('concise', queryOptions.concise);
  }
  if (queryOptions.includeActive !== undefined) {
    result = result.set('includeActive', queryOptions.includeActive);
  }
  if (queryOptions.includeHidden !== undefined) {
    result = result.set('includeHidden', queryOptions.includeHidden);
  }
  if (queryOptions.includeInactive !== undefined) {
    result = result.set('includeInactive', queryOptions.includeInactive);
  }
  if (queryOptions.joins !== undefined) {
    result = result.set('joins', queryOptions.joins.join(';'));
  }
  if (queryOptions.where !== undefined) {
    result = result.set('where', queryOptions.where);
  }
  if (queryOptions.orderBy !== undefined) {
    result = result.set('orderBy', queryOptions.orderBy);
  }
  if (queryOptions.orderByDirection !== undefined) {
    result = result.set('orderByDirection', queryOptions.orderByDirection);
  }
  if (queryOptions.page !== undefined) {
    result = result.set('page', queryOptions.page);
  }
  result = result.set('pageSize', queryOptions.pageSize);
  return result;
}
