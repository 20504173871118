import {Component, Input, OnInit} from '@angular/core';
import {SignInForm} from '@shared/sign-in/@form/sign-in-form';

@Component({
  selector: 'app-username[signInForm][showInvalidFeedback]',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.scss']
})
export class UsernameComponent implements OnInit {
  @Input() signInForm!: SignInForm;
  @Input() showInvalidFeedback!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
