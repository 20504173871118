import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '@app/@core/@config/config.service';
import { NetworkIdStore } from '@shared/storage/local/network-id-store';

export interface ObjectStore<T> {
  getObject(): T | undefined;
  setObject(val: T): void;
  removeObject(): void;
}

export interface FlushableCache {
  flush(): void;
}

/**
 * All caches used to store context specific data should be registered here.
 * In-memory, localStorage and sessionStorage caches are supported.
 * FlushableCaches are flushed when context change is detected, ie. when networkId changes, or user logs in/out.
 * note: flushLazyCreated flag is used to handle scenario where caches are lazily created after a previous flush
 */
@Injectable({
  providedIn: 'root',
})
export class CacheRegistry {
  readonly cachesByKey = new Map<string, FlushableCache>();
  readonly networkIdStore: NetworkIdStore;
  flushLazyCreated = false;

  constructor(private activatedRoute: ActivatedRoute, private configService: ConfigService) {
    this.networkIdStore = new NetworkIdStore(this);
    this.detectNetworkChange();
    activatedRoute.url.subscribe(() => this.detectNetworkChange());
  }

  registerFlushable(key: string, cache: FlushableCache) {
    if (this.cachesByKey.has(key)) {
      throw Error('cache for key already registered: ' + key);
    }
    this.cachesByKey.set(key, cache);
    if (this.flushLazyCreated) {
      cache.flush();
    }
  }

  registerNonFlushable(key: string, cache: ObjectStore<any>) {
    if (this.cachesByKey.has(key)) {
      throw Error('cache for key already registered: ' + key);
    }
  }

  flushAll() {
    this.flushLazyCreated = true;
    this.cachesByKey.forEach((cache) => cache.flush());
  }

  private detectNetworkChange() {
    const networkId = this.configService.getNetworkData()?.id;
    const localNetworkId = this.networkIdStore.getNetworkId();
    if (localNetworkId && localNetworkId !== networkId) {
      this.flushAll();
    }
    if (networkId) {
      this.networkIdStore.setNetworkId(networkId);
    }
  }
}
