/**
 * Copyright © 2023 Adnuntius AS.
 */

export const TRANSLATIONS_ES = {
  adn: {
    copyright: 'Copyright © 2023 Adnuntius AS',
    releaseId: 'ID de publicación: {{id}}',
    poweredBy: 'Desarrollado por Adnuntius AS',
  },
  grammar: {
    listSeparator: ', ',
    titleSeparator: ': ',
    booleanOr: 'O',
  },
  general: {
    new: 'Nuevo',
    saveSuccess: 'Tus actualizaciones se guardaron correctamente.',
  },
  button: {
    upload: {
      asset: 'Subir',
      drop: 'Arrastre un archivo aquí o utilice el botón de subir archivo',
    },
    archive: 'Archivar',
    activate: 'Activar',
    applyCustomRange: 'Aplicar rango personalizado',
    cancel: 'Cancelar',
    clear: 'Borrar',
    close: 'Cerrar',
    newCampaign: 'Crear nueva campaña',
    save: 'Guardar',
    undo: 'Deshacer',
    today: 'Hoy',
  },
  locale: {
    ar: 'Árabe - عربى',
    ar_JO: 'Árabe (Jordania) - العربية (الأردن)',
    ar_EG: 'Árabe (Egipto) - العربية (مصر)',
    ar_AE: 'Árabe (EAU) - العربية (الإمارات العربية المتحدة)',
    my: 'Birmano - ဗမာ',
    en: 'Inglés',
    en_AU: 'Inglés (Australia)',
    en_CA: 'Inglés (Canadá)',
    en_US: 'Inglés (EE.UU.)',
    en_GB: 'Inglés (Gran Bretaña)',
    en_PH: 'Inglés (Filipinas)',
    es: 'Español - español',
    es_ES: 'Español (España) - español (España)',
    es_AR: 'Español (Argentina) - español (Argentina)',
    et: 'Estonio - eesti keel',
    fi: 'Finlandés - Suomalainen',
    id: 'Indonesio - bahasa Indonesia',
    is: 'Islandés - íslensku',
    de: 'Alemán - Alemán',
    de_DE: 'Alemán (Alemania) - Alemán (Deutschland)',
    de_AT: 'Alemán (Austria) - Alemán (Österreich)',
    de_CH: 'Alemán (Suiza) - Alemán (Schweiz)',
    lt: 'Lituano - Lietuvis',
    lb: 'Luxemburgués - Lëtzebuergesch',
    ms: 'Malayo - Bahasa Melayu',
    ms_BN: 'Malayo (Brunei) - Bahasa Melayu (Brunei)',
    ms_MY: 'Malayo (Malaysia) - Bahasa Melayu (Malaysia)',
    nl: 'Holandés',
    nl_BE: 'Holandés (Bélgica) - Holandés (België)',
    nl_NL: 'Holandés (Holanda) - Holandés (Nederland)',
    no: 'Noruego - norsk',
    sv: 'Sueco - svenska',
    sv_SE: 'Sueco (Suecia) - svenska (Sverige)',
    sv_FI: 'Sueco (Finlandia) - svenska (Finland)',
    pl: 'Polaco - Polskie',
    pt: 'Portugués - Português',
    pt_BR: 'Portugués (Brasil) - Português (Brasil)',
    pt_PT: 'Portugués (Portugal) - Português (Portugal)',
    ro: 'Rumano - Română',
    ru: 'Ruso - русский',
    el: 'Griego - Ελληνικά',
    fr: 'Francés - français',
    da: 'Danés - Dansk',
    th: 'Tailandés - ไทย',
    fil: 'Tagalo - Tagalog',
    tr: 'Turco - Türk',
  },
  language: {
    text: 'Idioma:',
    en: 'Inglés',
    enShort: 'EN',
    el: 'Ελληνικά',
    elShort: 'EΛ',
    id: 'Indonesio',
    idShort: 'ID',
    de: 'Alemán',
    deShort: 'DE',
    fr: 'Francés',
    frShort: 'FR',
  },
  campaign: {
    archivedHeading: 'Campañas archivadas',
    archivedNoResults: 'No se encontraron campañas archivadas.',
    archivedShowLink: 'Mostrar campañas archivadas',
    adRejected: {
      budgetUnchanged: 'No se puede cambiar el presupuesto cuando la publicidad no ha superado la revisión.',
      productUnchanged: 'No se pueden cambiar los productos cuando la publicidad no ha superado la revisión.',
      explanationGeneral:
        'El anuncio #{{id}} no ha superado la revisión, por lo que su campaña no puede generar impresiones. Consulte los detalles a continuación.',
      explanation:
        'Ajuste su anuncio y vuelva a enviar su campaña para que sea revisada y que pueda generar las impresiones presupuestadas.',
      ADULT_CONTENT: {
        name: 'Contenido adulto',
        explanation: 'El anuncio fue rechazado porque contiene o conduce a una página con contenido para adultos.',
      },
      BLANK_CONTENT: {
        name: 'Contenido en blanco',
        explanation:
          'El anuncio fue rechazado porque está en blanco. Por favor, asegúrese de que el contenido requerido ha sido introducido correctamente.',
      },
      MALFORMED_CLICK_THROUGH: {
        name: 'URL de destino mal formada',
        explanation: 'El anuncio fue rechazado porque la URL de destino del clic no funciona correctamente.',
      },
      DRUG_RELATED_CONTENT: {
        name: 'Contenido relacionado con drogas',
        explanation:
          'El anuncio fue rechazado porque éste o su página de destino promueven contenidos o productos relacionados con las drogas. Esto puede incluir tabaco, alcohol, productos farmacéuticos u otras drogas legales o ilegales.',
      },
      WEAPON_RELATED_CONTENT: {
        name: 'Contenido relacionado con armas',
        explanation:
          'El anuncio fue rechazado porque éste o su página de destino promueven las armas o contenido relacionado con armas. Esto puede incluir contenido que promueva cuchillos peligrosos, pistolas, piezas de armas y otros contenidos relacionados.',
      },
      HATE_SPEECH_CONTENT: {
        name: 'Contenido de odio',
        explanation:
          'El anuncio fue rechazado porque contiene o conduce a una página de destino con contenido de incitación al odio. Esto puede incluir contenido que promueva la violencia, el racismo, el odio y la intolerancia.',
      },
      LANDING_PAGE_REJECTED: {
        name: 'Página de aterrizaje rechazada',
        explanation:
          'El anuncio fue rechazado porque la página de destino era de baja calidad o difícil de interactuar. Esto puede incluir páginas que deshabilitan las funciones de navegación del navegador, incluyen contenido de poco valor o conducen a una dirección de correo electrónico o a un archivo, como una imagen, un vídeo o un documento, que requiere una aplicación adicional para ser abierto.',
      },
      OFFENSIVE_CONTENT: {
        name: 'Contenido ofensivo',
        explanation:
          'El anuncio fue rechazado porque contiene o conduce a una página de destino con contenido ofensivo o inapropiado. Asegúrese de que sus anuncios y páginas de destino sólo muestren contenido seguro para la familia.',
      },
      UNACCEPTABLE_VISUAL_EFFECTS: {
        name: 'Efectos visuales inaceptables',
        explanation:
          'El anuncio fue rechazado porque utiliza efectos visuales inaceptables. Esto incluye GIFs animados con una tasa de fotogramas superior a 5 fotogramas por segundo.',
      },
      DECEPTIVE_CONTENT: {
        name: 'Contenido engañoso',
        explanation:
          'El anuncio fue rechazado porque contiene afirmaciones o información engañosa. Algunos ejemplos son los anuncios que hacen afirmaciones científicamente imposibles, que ofrecen resultados garantizados sin una política de reembolso o que muestran testimonios que afirman resultados específicos sin un enlace de verificación de terceros. Los anuncios también pueden ser rechazados si el diseño se asemeja a los botones de descarga de software, a las advertencias oficiales del sistema o a las características de una página web, como los menús, los resultados de las búsquedas u otros contenidos del sitio web.',
      },
      GAMBLING_CONTENT: {
        name: 'Contenido de juegos de azar',
        explanation: 'El anuncio fue rechazado porque contiene contenido relacionado con los juegos de azar.',
      },
      PROHIBITED_ELECTION_RELATED_CONTENT: {
        name: 'Contenido prohibido relacionado con las elecciones',
        explanation:
          'El anuncio fue rechazado porque contiene contenido prohibido relacionado con elecciones gubernamentales o con cargos políticos actualmente elegidos.',
      },
      SHOCKING_CONTENT: {
        name: 'Contenido impactante',
        explanation:
          'El anuncio fue rechazado porque contiene contenido chocante, como lenguaje soez, imágenes horripilantes o desagradables, o promociones diseñadas para escandalizar o asustar.',
      },
      MALWARE_CONTENT: {
        name: 'Contenido de tipo malware',
        explanation:
          'El anuncio fue rechazado porque éste o su página de destino contienen o conducen a contenidos inaceptables de tipo malware.',
      },
    },
    anon: {
      signInText: 'Cree una campaña sin iniciar sesión',
      heading: 'Cree una campaña a continuación.',
      beforeText:
        'Los detalles de su campaña se están guardando en su navegador actual. Antes de que su campaña pueda iniciarse y se pueda acceder a ella desde cualquier lugar, necesitará&nbsp;',
      linkText: 'crear una cuenta e iniciar sesión',
      postText: '.',
    },
    layout: {
      title: 'Seleccione un diseño de anuncio',
      missing: 'El anuncio que seleccionó anteriormente no está disponible para la opción publicitaria actual.',
      missingPreview: 'Es necesario seleccionar un diseño válido antes de poder previsualizar un anuncio.',
    },
    device: {
      title: 'Opciones de selección de dispositivos',
      descriptionPrefix: 'Su anuncio se mostrará en:&nbsp;',
      instructions: 'Especifique una o más opciones de dispositivos para determinar dónde aparecerá su anuncio',
      any: 'cualquier dispositivo',
    },
    domainName: {
      title: 'Opciones de selección de nombres de dominio',
      descriptionPrefix: 'Su anuncio se mostrará en:&nbsp;',
      instructions: 'Especifique uno o más dominios de destino donde aparecerá su anuncio',
      any: 'cualquier dominio disponible',
    },
    location: {
      title: 'Opciones de selección de ubicación',
      descriptionPrefix: 'Su anuncio se mostrará en:&nbsp;',
      instructions: 'Especifique una o más ubicaciones de destino donde aparecerá su anuncio',
      any: 'cualquier ubicación',
    },
    userSegment: {
      title: 'Opciones de selección de segmentos de usuario',
      descriptionPrefix: 'Su anuncio se mostrará a:&nbsp;',
      instructions: 'Especifique uno o más segmentos de usuario para orientar el lugar donde aparecerá su anuncio',
      any: 'cualquier usuario',
    },
    product: {
      title: 'Seleccione una opción publicitaria',
      adDesignChoice: '<strong>{{count}} diseños de anuncios</strong> disponibles en estos tamaños:',
      cpm: '<strong>{{value}}</strong> por mil impresiones',
      minSpend: '<strong>{{value}}</strong> gasto mínimo',
      maxSpend: 'Inversión máxima de <strong>{{value}}</strong>',
    },
    ad: {
      title: 'Diseñe su anuncio',
      defaultName: 'Creativo para {{campaignName}}',
      layoutFirst: 'Seleccione un diseño de anuncio antes de introducir los datos de su anuncio.',
      deletedAsset: 'Este componente hace referencia a una imagen eliminada. Suba una nueva.',
      imageHint: '{{mediaTypes}}, Máx: {{maxSize}}kb',
      imageHintOptional: '{{mediaTypes}}, Máx: {{maxSize}}kb (opcional)',
      adSizeTitle: 'Tamaño del anuncio',
      create: 'Cree su anuncio',
      add: 'Añada otro anuncio',
      count: 'Anuncio <strong>#{{count}}</strong>',
      maximum: 'Se pueden añadir un máximo de tres anuncios a su campaña',
      choiceOptional: '(Sin elección)',
      remove: 'Eliminar anuncio',
      runningPreviewTitle: 'Su anuncio de {{width}}&times;{{height}}',
      previewTitle: 'Previsualización del anuncio',
      border: 'Mostrar borde en el anuncio',
      previewWithExample: 'La previsualización de este anuncio incluye datos de ejemplo con fines demostrativos.',
      textCount: 'Recuento: {{count}}',
      textHintChars: '{{min}}&ndash;{{max}} caracteres',
      textHintCharsOptional: '{{min}}&ndash;{{max}} caracteres (opcional)',
      urlHint: 'URL',
      urlHintOptional: 'URL (opcional)',
      copyrightMessage:
        'Declaro que ninguno de los contenidos que he utilizado infringe las leyes de derechos de autor o de marcas registradas',
    },
    addNewCampaign: `
      <h3>Cree una campaña para transmitir su mensaje</h3>
      <p>Las campañas son las que generan anuncios en nuestro portal publicitario.</p>
      <p>Cree una nueva campaña con el botón de arriba y genere sus anuncios.</p>
      <p>Podrá acceder a sus campañas después de crearlas y hacerles un seguimiento a medida que se vayan generando.</p>
    `,
    campaign: 'Campaña',
    campaignPlural: 'Campañas',
    copy: {
      copyText: 'Se ha creado con éxito una copia de esta campaña.',
      copyTitle: 'Campaña copiada',
      copyPartialText: 'Esta campaña ha sido copiada, pero no todo el material publicitario pudo ser copiado con ella.',
      copyPartialTitle: 'Campaña copiada pero no con todo el material publicitario',
    },
    filter: {
      message: 'Seleccionar por {{option}}:',
    },
    copyAfterwards: 'Ir a la nueva campaña después de copiar',
    defaultName: 'Campaña de {{productName}}',
    detailsTitle: 'Especifique los detalles de su campaña',
    draftedHeading: 'Campañas redactadas',
    draftedNoResults: 'No se han encontrado campañas redactadas.',
    endedHeading: 'Campañas finalizadas',
    endedNoResults: 'No se han encontrado campañas finalizadas.',
    expectedImpressions: 'Corresponde a unas {{imps}} impresiones',
    cpmBoost:
      'Un CPM por encima del mínimo de {{minPrice}} aumenta sus posibilidades de que se generen todas sus impresiones presupuestadas',
    maxedOutCampaigns:
      '<p>Actualmente no puede crear más campañas.</p><p>Sólo se pueden crear un máximo de {{max}} campañas en un momento dado.</p><p>Archive una campaña existente o haga que genere impresiones para poder crear otra campaña.</p>',
    payment: {
      title: 'Pago',
      missing: 'No hay ningún pago asociado a esta campaña.',
      cancelled: 'Su reserva fue cancelada y su campaña no fue enviada para revisión.',
      infoComplete: 'Se realizó un pago de {{price}} {{time}}.',
      refunded: 'Se realizó un reembolso de {{price}} {{time}}.',
      infoAuthorised: 'Se autorizó un pago de {{price}} {{time}}.',
      receiptLink: 'Su <a href="{{link}}" target="_blank">recibo de pago está disponible</a>.',
      registering: 'Registrando transacción...',
      reserveTitle: 'Realice el pago y envíe de su campaña para revisión',
      reserveButton: 'Realice el pago y envíe su campaña para revisión',
      reserveParagraph1:
        '<strong>Cargaremos a su cuenta</strong> la suma presupuestada de <strong>{{amount}}</strong>, que permitirá que su campaña sea revisada y puesta en marcha.',
      reserveParagraph2:
        'Si sus <strong>{{imps}} impresiones</strong> presupuestadas no se entregan, se reembolsará el monto restante a su cuenta.',
      failure:
        'Ha habido un problema al intentar registrar su transacción. Inténtelo de nuevo y, si el problema persiste, póngase en contacto con nosotros.',
      saveFirst: 'Deberá guardar los cambios antes de proceder a la reserva del pago',
      PAYPAL: {
        waitingForRedirect: 'Estamos pasando los detalles de su transacción a PayPal y le redirigiremos allí en breve.',
        reserveButton: 'Proceda a pagar vía PayPal o mediante tarjeta de crédito y enviar su campaña para revisión',
      },
      prepay: {
        detailsInfo: `
          <div class="small">
            <p>Su campaña se pondrá en marcha después de que se realice el pago a través y se revise el contenido de su campaña.</p>
            <p>Si su campaña no entrega el valor de impresiones de su presupuesto, se reembolsará a su cuenta la cantidad restante. Sin embargo, se aplica una tarifa mínima de {{minimumFee}}.</p>
            <p>Una vez finalizada su campaña, se generará un informe de rendimiento y un recibo.</p>
            <p>Podrá detener su campaña en cualquier momento, pero se aplicará la tarifa mínima de {{minimumFee}} incluso si no se generan impresiones.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Su campaña se pondrá en marcha después de que se realice el pago a través y se revise el contenido de su campaña.</p>
            <p>Si su campaña no entrega el valor de impresiones de su presupuesto, se reembolsará a su cuenta la cantidad restante.</p>
            <p>Una vez finalizada su campaña, se generará un informe de rendimiento y un recibo.</p>
            <p>Podrá detener su campaña en cualquier momento.</p>
          </div>
        `,
      },
      manual: {
        reserveTitle: 'Envíe su campaña para revisión',
        reserveButton: 'Enviar campaña para revisión',
        reserveParagraph1: 'Antes de poder poner en marcha su campaña, necesitamos revisarla.',
        reserveParagraph2:
          'Una vez que su campaña haya finalizado, emitiremos una factura por valor de <strong>{{amount}}</strong> o la cantidad prorrateada basada en el número real de impresiones generadas frente al número esperado de <strong>{{imps}} impresiones</strong>.',
        detailsInfo: `
          <div class="small">
            <p>Su campaña se pondrá en marcha una vez que el contenido sea revisado y aprobado.</p>
            <p>Se le enviará una factura una vez finalizada su campaña. Se le cobrará en función del número de impresiones que genere su campaña y nunca por encima de su presupuesto especificado. Sin embargo, se aplica una tarifa mínima de {{minimumFee}}.</p>
            <p>Una vez que su campaña haya finalizado, se generará un informe de resultados.</p>
            <p>Podrá detener su campaña en cualquier momento, pero se aplicará la tarifa mínima de {{minimumFee}} incluso si no se generan impresiones.</p>
          </div>
        `,
        detailsInfoNoMinFee: `
          <div class="small">
            <p>Su campaña se pondrá en marcha una vez que el contenido sea revisado y aprobado.</p>
            <p>Se le enviará una factura una vez finalizada su campaña. Se le cobrará en función del número de impresiones y nunca por encima de su presupuesto especificado.</p>
            <p>Una vez que su campaña haya finalizado, se generará un informe de resultados.</p>
            <p>Podrá detener su campaña en cualquier momento.</p>
          </div>
        `,
      },
      status: {
        CREATED: 'Creado',
        NOT_AUTHORISED: 'No autorizado',
        AUTHORISED: 'Autorizado',
        COMPLETED: 'Completado',
        PAUSED: 'Pausado',
        CANCELLED: 'Cancelado',
      },
    },
    postPayment: {
      reserveParagraph1: 'El pago de su campaña ha sido autorizado.',
      reserveParagraph2: 'Si está contento con su campaña, puede enviarla para revisión a continuación.',
      saveFirst: 'Necesitará guardar sus cambios antes de poder enviar su campaña para revisión.',
      failed:
        'Hubo un problema al enviar su campaña para revisión. Inténtelo de nuevo y, si el problema persiste, contacte con nosotros.',
      submitButton: 'Enviar su campaña para revisión',
    },
    rejectedHeading: 'Campañas rechazadas',
    report: {
      title: 'Informe',
      ungenerated: 'Podrá descargar un informe en breve.',
      generated: 'Hay <a href="{{link}}" target="_blank">un informe disponible para su descarga</a>.',
    },
    runningHeading: 'Campañas en curso y en revisión',
    runningNoResults: 'No se encontraron campañas en curso.',
    running: {
      budgetHeading: 'Posición presupuestaria',
      budgetPosition: '<strong>{{budgetSpent}}</strong> de su <strong>{{budget}} presupuesto</strong> ha sido gastado.',
      cancelledPaymentParagraph1: 'La reserva de su pago ha sido cancelada.',
      cancelledPaymentParagraph2:
        'Si aún desea continuar con su campaña, puede generar una nueva transacción a continuación.',
      datesHeading: 'Fechas de ejecución',
      dates: '<strong>{{startDate}}</strong> a <strong>{{endDate}}</strong>',
      datesExplanation:
        'Su campaña ha estado en marcha <strong>{{days}} días</strong>, que es un <strong>{{percentage}}</strong> de su duración programada máxima.',
      datesLeftExplanation:
        'Su campaña se detendrá en <strong>{{days}} días</strong> si no se alcanzan las impresiones presupuestadas.',
      datesSubmittedExplanation:
        'Su campaña se ejecutará durante <strong>{{days}} días</strong> si no se alcanzan las impresiones presupuestadas.',
      impressions:
        'Su campaña ha generado <strong>{{imps}} impresiones</strong>, que es un <strong>{{percentage}} de sus impresiones presupuestadas</strong>.',
      impressionsRemaining: 'Aún le quedan alrededor de <strong>{{imps}} impresiones</strong> que generar.',
      statusPrefix: 'Estado de la campaña: ',
      successfulPaymentParagraph1: 'Se ha reservado el pago y su campaña ha sido enviada para revisión.',
      successfulPaymentParagraph2: 'Una vez que su campaña pase la revisión, generará sus anuncios.',
      prepay: {
        stoppedParagraph1: 'Su campaña ha sido detenida.',
        stoppedParagraph2:
          'Cualquier presupuesto de campaña no utilizado se reembolsará y se generará un informe para su campaña.',
      },
      manual: {
        stoppedParagraph1: 'Su campaña ha sido detenida.',
        stoppedParagraph2: 'Se le emitirá una factura y un informe generado sobre su campaña.',
      },
      submittedParagraph1: 'Su campaña ha sido enviada para revisión.',
      submittedParagraph2: 'Una vez que su campaña pase la revisión, generará sus anuncios.',
      unexpectedPaymentParagraph1: 'La reserva de su pago no fue según lo previsto.',
      unexpectedPaymentParagraph2:
        'En este punto, probablemente sea mejor copiar esta campaña y comenzar de nuevo el proceso de creación de una reserva de pago.',
    },
    save: {
      button: 'Guardar su progreso',
      messageTitle: 'Campaña guardada',
      messageText: 'Su campaña ha sido guardada correctamente.',
    },
    smoothing: {
      title: 'Estilo de entrega del anuncio',
      true: 'Uniforme',
      trueExplanation: 'Su anuncio se entregará uniformemente a lo largo de la duración de la campaña.',
      trueField:
        '<strong>Uniforme</strong> - Genera sus impresiones publicitarias uniformemente a lo largo de la duración de su campaña',
      false: 'Rápido',
      falseExplanation: 'Su anuncio se entregará lo más rápidamente posible.',
      falseField: '<strong>Rápido</strong> - Genera sus impresiones publicitarias lo más rápidamente posible',
    },
    status: {
      STOPPED: 'Detenida',
      PROPOSED: 'Redactada',
      SUBMITTED: 'En revisión',
      NOT_READY: 'Necesita más revisión',
      READY: 'Lista para la entrega',
      REJECTED: 'Rechazada',
      SUBMISSION_READY: 'Lista para el envío',
      RUNNING: 'En marcha',
      ENDED: 'Finalizada',
    },
    statsHeading: 'Estadísticas de entrega',
    stop: {
      button: 'Detener campaña',
      cancelButton: 'Cancelar detención de la campaña',
      confirmButton: 'Confirmar detención de la campaña',
      failParagraph1: 'Hubo un problema y no se pudo detener su campaña.',
      failParagraph2: 'Por favor, intente detener su campaña de nuevo y, si el problema persiste, avísenos.',
      prepay: {
        info1:
          'La detención de una campaña no se puede revertir, aunque se puede copiar cualquier campaña y poner en marcha una nueva.',
        info2:
          'Al detener su campaña, se reembolsará cualquier presupuesto no utilizado. Nota: se aplica una tarifa mínima de {{money}}.',
        info2NoMinFee: 'Al detener su campaña, se le cobrará su gasto publicitario actual.',
      },
      manual: {
        info1:
          'La detención de una campaña no se puede revertir, aunque se puede copiar cualquier campaña y poner en marcha una nueva.',
        info2:
          'Al detener su campaña, se le enviará una factura por importe de la tarifa mínima de {{money}} o de su gasto publicitario actual, lo que sea mayor.',
        info2NoMinFee: 'Al detener su campaña, se le enviará una factura por valor de su gasto publicitario actual.',
      },
      intro: 'Si en cualquier momento desea detener su campaña, puede hacerlo a continuación.',
    },
    validationProblemsPresent: `
      <p>Hay algunos problemas de validación con su campaña.</p>
      <p>Una vez que los problemas de validación se solucionen, podremos iniciar el proceso de puesta en marcha de su campaña.</p>
    `,
  },
  devices: {
    ACER: 'Acer',
    APPLE: 'Dispositivos Apple',
    ASUS: 'Asus',
    DESKTOP: 'Escritorio',
    FUJITSU: 'Fujitsu',
    HTC: 'HTC',
    HUAWEI: 'Huawei',
    KYOCERA: 'Kyocera',
    LG: 'LG',
    MOTOROLA: 'Motorola',
    NEC: 'NEC',
    NOKIA: 'Nokia',
    ONE_PLUS: 'OnePlus',
    OPPO: 'Oppo',
    PANASONIC: 'Panasonic',
    RIM: 'RIM',
    SAMSUNG: 'Samsung',
    SHARP: 'Sharp',
    SONY_ERICSSON: 'Sony Ericsson',
    UNKNOWN: 'Desconocido',
    VIVO: 'Vivo',
    XIAOMI: 'Xiaomi',
    ZTE: 'ZTE',
    ANDROID: 'Android',
    BLACKBERRY: 'Blackberry',
    BSD: 'BSD',
    IOS: 'iOS',
    LINUX: 'Linux',
    MACINTOSH: 'Apple Mac OS',
    SUNOS: 'SunOS',
    SYMBIAN: 'Symbian',
    WINDOWS: 'Windows',
    WINDOWS_PHONE: 'Windows Phone',
    CONSOLE: 'Consola',
    E_READER: 'E-Reader',
    MEDIA_HUB: 'Media Hub',
    MOBILE: 'Smart Phone',
    SMART_WATCH: 'Smart Watch',
    TABLET: 'Tablet',
    TV: 'Televisión',
  },
  emailPreferences: {
    CREATIVE_REJECTED: '<strong>Ajustes necesarios:</strong> un revisor solicita un cambio en mi campaña',
    REPORT_COMPLETE:
      '<strong>El informe de la campaña está listo:</strong> mi campaña ha finalizado y su informe de resultados ha sido generado',
    LINE_ITEM_RUNNING:
      '<strong>La campaña está en marcha:</strong> mi campaña ha superado la revisión y ya está generando impresiones',
  },
  media: {
    gif: 'GIF',
    png: 'PNG',
    svg: 'SVG',
    jpg: 'JPG',
    mp4: 'MP4',
    webm: 'WebM',
  },
  chart: {
    countType: {
      singular: 'Tendencia',
      cumulative: 'Acumulado',
    },
    yAxisOption: {
      shared: 'Eje y compartido',
      multiple: 'Múltiples ejes y',
    },
    dateOption: {
      last1Hour: 'Última hora',
      last12Hours: 'Últimas 12 horas',
      last7Days: 'Últimos 7 días',
      last30Days: 'Últimos 30 días',
      today: 'Hoy',
      yesterday: 'Ayer',
      campaignDays: 'Fechas de ejecución: {{startDate}}-{{endDate}}',
    },
  },
  layout: {
    fieldSummary: {
      image: 'imagen',
      imagePlural: 'imágenes',
      url: 'URL',
      urlPlural: 'URLs',
      code: 'campo de código',
      codePlural: 'campos de código',
      text: 'campo de texto',
      textPlural: 'campos de texto',
      option: 'opción',
      optionPlural: 'opciones',
      video: 'vídeo',
      videoPlural: 'vídeos',
    },
  },
  label: {
    copy: 'Copiar',
    copyLineItem: 'Copiar campaña',
    paginationAllShown: 'Mostrando todos los <strong>{{total}}</strong> resultados',
    paginationWithPages: 'Mostrando <strong>{{start}}-{{end}}</strong> de <strong>{{total}}</strong> resultados',
    paginationLoading: 'Mostrando <span class="fa fa-spin fa-cog"></span> resultados',
  },
  report: {
    downloadXlsComma: 'Descargar como XLS (coma decimal)',
    downloadXlsPoint: 'Descargar como XLS (punto decimal)',
    fieldCountPlural: '{{count}} campos',
    fieldCount: '{{count}} campo',
    report: 'Informe',
    reportPlural: 'Informes',
    noReport: `
      <h3>No se han encontrado informes</h3>
      <p>Una vez que su campaña haya finalizado, se generará un informe mostrando los resultados.</p>
      <p>Aquí encontrará una lista de todos los informes generados para todas sus campañas.</p>
    `,
    status: {
      PENDING: 'Pendiente',
      PROCESSING: 'Procesando',
      COMPLETED: 'Completado',
      REJECTED: 'Rechazado',
      FAILED: 'Fallido',
      CANCELLED: 'Cancelado',
      QUEUED: 'En cola',
      TIMED_OUT: 'Tiempo agotado',
    },
    field: {
      rendered: 'Impresiones',
      visibles: 'Impresiones visibles',
      visibility: 'Visibilidad',
      viewables: 'Impresiones visualizables',
      viewability: 'Visualización',
      uniqueUsers: 'Usuarios únicos',
      clicks: 'Clics',
      ctr: 'Tasa de clics',
      cost: 'Coste',
    },
  },
  errors: {
    standard: {
      unregistered: {
        title: 'Su cuenta no está registrada aquí',
        p1: 'No está registrado en este portal publicitario.',
        p2: '',
        p2LinkText: 'Regístrese en este portal publicitario',
        p2Suffix: '&nbsp;antes de continuar.',
        p3: 'Alternativamente,&nbsp;',
        p3LinkText: 'inicie sesión con otra cuenta',
        p3Suffix: '.',
      },
      dataNotFoundTitle: 'Faltan datos',
      dataNotFoundParagraph1: 'No pudimos encontrar ningún dato en la siguiente ubicación: <strong>{{link}}</strong>.',
      dataNotFoundParagraph2: 'Esto podría deberse a que los datos subyacentes ya no están a su disposición.',
      dataNotFoundParagraph3: 'Acceda a cualquiera de los enlaces del menú para volver a ponerse en marcha.',
      pageNotFoundTitle: 'Página no encontrada',
      pageNotFoundParagraph1: 'No pudimos encontrar la página solicitada.',
      pageNotFoundParagraph2: 'Acceda a cualquiera de los enlaces del menú para volver a ponerse en marcha.',
      objectNotFoundTitle: 'Objeto no encontrado',
      objectNotFoundParagraph1: 'No pudimos encontrar ningún dato para el ID siguiente: <strong>{{id}}</strong>.',
      objectNotFoundParagraph2: 'Esto podría deberse a que el objeto ya no está a su disposición.',
      objectNotFoundParagraph3: 'Acceda a cualquiera de los enlaces del menú para volver a ponerse en marcha.',
    },
    api: {
      stats:
        'No pudimos recuperar las estadísticas de la API. Si el problema persiste, póngase en contacto con el servicio de asistencia.',
      title: 'Lo sentimos, parece que hay un problema',
      noConnection: {
        title: 'Pérdida de conexión',
        noConnection:
          '<p>Hemos perdido la conexión con nuestro servidor interno. Si el problema persiste, por favor contacte con el servicio de asistencia.</p>',
      },
      generic:
        '<p>Estamos teniendo problemas de interacción con nuestro sistema interno. Si el problema persiste, por favor contacte con el servicio de asistencia.</p>',
      unauthorised: {
        title: 'Operación no autorizada',
        unauthorised: '<p>No tiene los permisos necesarios para llevar a cabo la operación solicitada.</p>',
      },
    },
  },
  field: {
    advertiserName: 'Nombre de la empresa',
    address1: 'Dirección',
    selfServiceBudget: 'Presupuesto',
    city: 'Ciudad',
    confirmPassword: 'Confirmar contraseña',
    state: 'Estado',
    postCode: 'Código postal',
    country: 'País',
    invoiceEmail: 'Dirección de correo electrónico para facturas',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    emailPreferences: 'Preferencias de correo electrónico',
    password: 'Contraseña',
    locale: 'Configuración regional',
    smoothing: 'Estilo de entrega de anuncios',
    startDate: 'Inicio',
    status: 'Estado',
    userState: 'Estado',
    vatId: 'ID de IVA',
    displayName: 'Nombre mostrado',
    displayNameHint: 'El nombre mostrado se usa para referirse a usted dentro de la aplicación',
    endDate: 'Fin',
    executionStartTime: 'Fecha de ejecución',
    name: 'Nombre',
    cpm: 'Coste por 1000 impresiones (CPM)',
    required: 'Obligatorio',
    malformedEmail: 'Debe ser un correo electrónico válido',
    optional: 'Opcional',
  },
  location: {
    Navarre: 'Navarra',
    Spain: 'España',
  },
  settings: {
    settingsPlural: 'Ajustes',
    about: 'Acerca de',
    signOut: 'Cerrar sesión',
  },
  signIn: {
    accountLocked:
      'Su cuenta ha sido bloqueada debido a un exceso de intentos fallidos de inicio de sesión. Se le ha enviado un correo electrónico con el que puede restablecer su contraseña, desbloquear su cuenta e iniciar sesión.',
    alreadyUser: '¿Ya es usuario?',
    confirmPasswordMismatch:
      'Las contraseñas introducidas no coinciden. Asegúrese de introducir la misma contraseña las dos veces.',
    differentAccount: 'Iniciar sesión con una cuenta diferente',
    emailFirst:
      'Es necesario que introduzca la dirección de correo electrónico de su cuenta antes de poder restablecer su contraseña.',
    emailRequired: 'Necesitamos la dirección de correo electrónico de su cuenta para poder registrarle.',
    expiredLinkExplanation:
      'Parece que ha hecho clic en un enlace de restablecimiento de contraseña no válido o caducado. Por favor, intente restablecer su contraseña de nuevo',
    forgotPassword: '¿Ha olvidado su contraseña?',
    humanConfirmationFailure:
      'Hay un problema para obtener la confirmación del registro de su cuenta. Vuelva a intentarlo y avísenos si el problema persiste.',
    nameRequired: 'Necesitamos un nombre para registrarle como nuevo usuario.',
    passwordResetTooMuchHeading: 'Demasiados restablecimientos de contraseña',
    passwordResetTooMuchExplanation:
      'Ha intentado restablecer su contraseña demasiadas veces en muy poco tiempo. No podrá restablecer su contraseña hasta pasado un rato.',
    passwordResetEmailSentHeading: 'Correo electrónico de restablecimiento de contraseña enviado',
    passwordResetEmailSentExplanation:
      'Revise su correo electrónico para ver si le llega un enlace para restablecer su contraseña. Si no le llega en unos minutos, revise la carpeta de spam.',
    passwordHint: 'Su contraseña debe tener al menos 8 caracteres de longitud',
    passwordLinkExpired:
      'Su enlace de restablecimiento de contraseña ha caducado. Introduzca su dirección de correo electrónico para generar otro enlace.',
    passwordRequired: 'Facilite una contraseña para iniciar sesión.',
    passwordSimple: 'La contraseña facilitada se puede adivinar con demasiada facilidad y debería ser menos común.',
    regError: 'Ha habido problemas de validación. Consulte los detalles arriba.',
    registerAsUserHeading: 'Registro como usuario',
    registerAsUserButton: 'Registrarse',
    registerEmailRequired: 'Necesitamos una dirección de correo electrónico para registrarle como nuevo usuario.',
    registerNewUser: 'Registro como nuevo usuario',
    registerSuccessHighlight: '¡Se ha registrado con éxito como nuevo usuario!',
    registerSuccessParagraph1: 'Se le ha enviado un correo electrónico con más información.',
    registerSuccessParagraph2:
      'Haga clic en el enlace de su correo electrónico para crear la contraseña de su cuenta e iniciar sesión.',
    registerTerms: `Al seleccionar <strong>Registrarse</strong>, acepta haber leído y aceptado nuestros <a target="_blank" href="https://adnuntius.com/resources/tc-self-service-advertising/">términos y condiciones</a>.`,
    resetPasswordHeading: 'Restablecer su contraseña',
    resetPasswordButton: 'Enviar correo electrónico de restablecimiento de contraseña',
    resetPasswordInfo:
      'Introduzca su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.',
    signInButton: 'Iniciar sesión',
    signInHeading: 'Iniciar sesión',
    updatePasswordHeading: 'Actualice su contraseña',
    updatePasswordButton: 'Actualizar contraseña',
  },
  adBlockerParagraph1:
    'Hemos detectado que tiene un bloqueador de anuncios instalado. Los bloqueadores de anuncios interfieren con el funcionamiento de la aplicación.',
  adBlockerParagraph2:
    'Desactive su bloqueador de anuncios en este sitio para asegurar el correcto funcionamiento de la aplicación.',
  adBlockerTurnOff: 'Desactiva esta advertencia',
  validation: {
    required: 'Obligatorio',
    pattern: 'El texto debe tener {{minLength}}-{{maxLength}} caracteres de longitud.',
    maxSize: 'El archivo subido tiene {{actual}}kb de tamaño, que es mayor que el máximo de {{maximum}}kb',
    fileType: 'El archivo subido debe ser de uno de los siguientes tipos: {{allowedTypes}}',
    minPrice: 'El valor mínimo es {{minValue}}',
    maxPrice: 'El valor máximo es {{maxValue}}',
    number: 'Número invalido',
    url: 'La URL no tiene un formato válido.',
    parse: 'El contenido no coincide con el formato esperado',
    endDateStartDate: 'La fecha final debe ser posterior a la fecha inicial.',
    email: 'Hace falta una dirección de correo electrónico válida',
    fileUploadFail: 'No se pudieron cargar los siguientes archivos:',
    fileUploadTimeout:
      'No se pudo completar la carga. Intente cargar de nuevo o, si es posible, actualice la página para ver qué recursos se han cargado.',
    anonymousFileSizeLarge: 'El archivo debe tener un tamaño inferior a {{fileSize}}kb cuando no ha iniciado sesión.',
    anonymousFileSizeSuperLarge:
      'La carga del archivo falló por una razón no especificada, más que probablemente porque el archivo era demasiado grande para que lo analizáramos. Inicie sesión para aumentar los límites de carga de archivos y obtener un mejor análisis.',
    error: {
      validation: '<strong>Envío no válido:</strong> Por favor revise los detalles anteriores e inténtelo de nuevo.',
      'must.be.greater.than.or.equal': '{{value}} debe ser mayor de {{minValue}}.',
      'non.empty': 'Obligatorio',
      'auth.failed': 'No se pudo iniciar sesión. Las credenciales no pertenecen a ninguna cuenta.',
      'sign.in.blocked': 'No está registrado como usuario aquí. Inicie sesión en {{uiAddress}}.',
    },
    warning: {
      'creative.invalid.product.dimensions': 'Debe seleccionar unas dimensiones válidas para su anuncio',
      'lineItem.start.date.before.end.date': 'La fecha inicial de la campaña debe ser anterior a la fecha final',
      'lineItem.end.date.before.today': 'La fecha final de la campaña debe ser posterior a la fecha y hora actuales',
      'lineItem.invalid.budget.enddate': 'La campaña debe finalizar en 100 días',
      'payment.gateway.error': 'El problema está relacionado con nuestra conexión.',
    },
  },
};
