import Rollbar from 'rollbar';
import {environment} from '@env/environment';
import {InjectionToken} from '@angular/core';


const rollbarConfig: Rollbar.Configuration = {
  accessToken: '1ecaf659743d4c32b7e91b8a0d1de29f',
  captureUncaught: true,
  captureUnhandledRejections: true,
  scrubFields: ['refresh_token', 'access_token', 'auth_token'],
  payload: {
    environment: environment.ADN_ENV_KEY,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: environment.version,
        guess_uncaught_frames: true
      }
    },
    server: {
      host: location.host
    }
  }
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const ROLLBAR_INSTANCE = new InjectionToken<Rollbar>('rollbar');
