import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NonNullableFormBuilder} from '@angular/forms';
import {AuthService} from '@app/@core/auth/auth.service';
import {ConfigService} from '@app/@core/@config/config.service';
import {AnonProfileStore} from '@app/@core/anon/anon-profile-store.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {Observable, tap} from 'rxjs';
import {SignInCommand, SignInModalLauncher} from '@app/@shared/sign-in/launcher/sign-in-modal-launcher.service';

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss']
})
export class SignInModalComponent implements OnInit {
  signInCommand$!: Observable<SignInCommand>;
  modal!: NgbModalRef;
  @ViewChild('modal_new_session') modalNewSession!: TemplateRef<any>;
  @ViewChild('modal_current_session') modalCurrentSession!: TemplateRef<any>;

  constructor(private modalService: NgbModal,
              private fb: NonNullableFormBuilder,
              private authService: AuthService,
              private configService: ConfigService,
              private anonProfileStore: AnonProfileStore,
              private signInModalLauncher: SignInModalLauncher) {
  }

  ngOnInit(): void {
    this.signInCommand$ = this.signInModalLauncher.signInCommandEmitter().pipe(
      tap((signInCommand) => {
        if (signInCommand.isNewSession) {
          this.open(this.modalNewSession);
        } else {
          this.open(this.modalCurrentSession);
        }
      })
    );
  }

  private open(content: TemplateRef<any>) {
    this.modal = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', beforeDismiss: () => false});
  }

  hasMasqueradeUser(): boolean {
    return !!this.anonProfileStore.getAnonProfile().masqueradeUser
  }

}
