import { EphemeralLocalObjectStore } from '@shared/storage/local/ephemeral-local-object-store';
import { CacheRegistry } from '@shared/storage/cache-registry.service';

export class NetworkIdStore {
  private readonly localNetworkId: EphemeralLocalObjectStore<string>;

  constructor(cacheRegistry: CacheRegistry) {
    this.localNetworkId = new EphemeralLocalObjectStore<string>(cacheRegistry, 'networkId');
  }

  getNetworkId(): string | undefined {
    return this.localNetworkId.getObject();
  }

  setNetworkId(networkId: string) {
    this.localNetworkId.setObject(networkId);
  }
}
