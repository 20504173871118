<header class='app'>
  <nav class='navbar navbar-expand-md navbar-dark'>
    <div class='container containerManual'>
      <a class='navbar-brand' [routerLink]="configService.getUrl('/')">
        <img *ngIf="networkData.logo" class="uiLogo" [src]="networkData.logo" alt='logo'/>
        <img *ngIf="!networkData.logo" width="164" src="https://regalia.adnuntius.com/logo-horizontal-white.svg" alt="Adnuntius"/>
      </a>
      <button
        class='navbar-toggler'
        type='button'
        aria-controls='navbar-menu'
        aria-label='Toggle navigation'
        (click)='toggleMenu()'
        [attr.aria-expanded]='!menuHidden'
      >
        <span class='navbar-toggler-icon'></span>
      </button>
      <div id='navbar-menu' class='collapse navbar-collapse float-xs-none' [ngbCollapse]='menuHidden'>
        <div class='navbar-nav me-auto mb-2 mb-lg-0 titleNavContainer'>
          <ng-container *ngIf='isUserSelfService'>
            <ul class='list-unstyled navbarLinks'>
              <li>
                <a class='nav-item nav-link' [routerLink]="configService.getUrl('/campaigns')" routerLinkActive='active'>
                  {{ 'campaign.campaignPlural' | translate }}
                </a>
              </li>
              <li>
                <a class='nav-item nav-link' [routerLink]="configService.getUrl('/reports')" routerLinkActive='active'>
                  {{ 'report.reportPlural' | translate }}
                </a>
              </li>
              <li>
                <a class='nav-item nav-link' [routerLink]="configService.getUrl('/settings')" routerLinkActive='active'>
                  {{ 'settings.settingsPlural' | translate }}
                </a>
              </li>
            </ul>
          </ng-container>
        </div>
        <app-language-selector [signedInContext]='signedInContext' [inNav]='true'></app-language-selector>
        <div class='navbar-nav' ngbDropdown>
          <a id='user-dropdown' class="menuText" ngbDropdownToggle>{{ displayName }}</a>
          <ng-container *ngIf="!isAnon">
            <div ngbDropdownMenu aria-labelledby='user-dropdown' class='dropdown-menu dropdown-menu-right'>
              <a class='dropdown-item' [routerLink]="configService.getUrl('/settings')">{{
                'settings.settingsPlural' | translate
                }}</a>
              <div class='dropdown-divider'></div>
              <a type='button' class='dropdown-item' (click)='logout()'>
                {{ 'settings.signOut' | translate }}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="isAnon">
            <div ngbDropdownMenu aria-labelledby='user-dropdown' class='dropdown-menu-right'>
              <button name='sign-out' class='dropdown-item' [routerLink]="configService.getUrl('/sign-in/register')">
                {{ 'campaign.anon.signUp' | translate }}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </nav>
</header>
