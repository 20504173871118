import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CampaignCouponAvailable, Coupon} from '@app/@core/@models/design/coupon.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigService} from '@app/@core/@config/config.service';
import {ResultList} from '@app/@core/@models/common/result-list';

@Injectable({
  providedIn: 'root',
})
export class CouponResource {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  getCoupon(code: string, productId: string): Observable<Coupon> {
    const networkId = this.configService.getNetworkData()!.id;
    return this.httpClient.get<Coupon>(this.configService.getVersionedUri(['coupons', 'coupon', code, productId]), {
      params: new HttpParams().set('context', networkId),
    });
  }

  signupCoupon(): Observable<ResultList<Coupon>> {
    return this.httpClient.get<ResultList<Coupon>>(
      this.configService.getVersionedUri(['coupons', 'signupcoupon']),
      {
        params: new HttpParams()
          .set('context', this.configService.getNetworkData()!.id)
          .set('includeActive', true)
          .set('includeHidden', false)
          .set('includeInactive', false)
          .set('orderBy', 'name')
          .set('pageSize', 301),
      });
  }

  getCampaignCouponAvailable(productId: string): Observable<CampaignCouponAvailable> {
    const networkId = this.configService.getNetworkData()!.id;
    return this.httpClient.get<CampaignCouponAvailable>(
      this.configService.getVersionedUri(['coupons', 'coupon', productId]),
      {params: new HttpParams().set('context', networkId)}
    );
  }
}
