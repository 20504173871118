import { Injectable } from '@angular/core';
import {Observable, of, Subscriber} from 'rxjs';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import { Asset } from '@app/@core/@models/advertising/asset.model';
import { Uuid } from '@shared/utils/uuid';
import { ConfigService } from '@app/@core/@config/config.service';
import { fromFetch } from 'rxjs/fetch';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssetResource {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {}

  post(creativeId: string, assetId: string, file: File): Observable<HttpEvent<Asset>> {
    const endPoint: string = this.configService.getVersionedUri('assets');
    const formData = new FormData();
    formData.append('file', file, file.name);
    const networkId = this.configService.getNetworkData()!.id;
    return this.httpClient.post<Asset>(
      `${endPoint}/${creativeId}/${assetId}?context=${networkId}`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
    });
  }

  postBase64Asset(creativeId: string, asset: Asset): Observable<Asset> {
    const endPoint = this.configService.getVersionedUri('assets');
    const networkId = this.configService.getNetworkData()!.id;
    // use fetch to load the base64 image
    return fromFetch(asset.base64).pipe(
      // convert to blob
      switchMap((response) => response.blob()),
      // upload to backend
      switchMap((blob) => {
        const formData = new FormData();
        formData.append('file', blob, asset.fileName);
        return this.httpClient.post<Asset>(
          `${endPoint}/${creativeId}/${asset.id}?context=${networkId}`,
          formData,
          {}
        );
      })
    );
  }

  base64Asset(file: File): Observable<Asset> {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    return Observable.create((observer: Subscriber<Asset>): void => {
      fileReader.onload = (ev: ProgressEvent): void => {
        const asset = {} as Asset;
        asset.base64 = (<any>ev.target).result;
        asset.fileName = file.name;
        asset.mimeType = file.type;
        asset.id = Uuid.generate();
        asset.fileSizeBytes = file.size;
        observer.next(asset);
        observer.complete();
      };
    });
  }

  update(creativeId: string, assets: Asset[]): Observable<undefined> {
    if (assets.length == 0) {
      return of(undefined)
    } else {
      const endPoint: string = this.configService.getVersionedUri('assets');
      return this.httpClient.post<undefined>(
        `${endPoint}/${creativeId}`,
        assets,
        {
          headers: new HttpHeaders().set('Content-Type', 'text/plain'),
          params: new HttpParams().set('context', this.configService.getNetworkData()?.id!)
        }
      );
    }
  }

}
