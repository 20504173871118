
// additional suing translations, keeping separate from translations-*.ts in case those are updated in sui
export const TRANSLATIONS_SUING_EN = {
  report: {
    downloadExcelWorkbook: 'Download as Excel Workbook (.xlsx)'
  },
  chart: {
    noData: 'Select at least one field to chart here'
  },
  media: {
    mp3: "MP3"
  },
  locale: {
    nb: 'Norwegian Bokmål - norsk bokmål',
  }
}

