import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EMPTY, finalize, Observable, tap} from 'rxjs';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators
} from '@angular/forms';
import {ConfigService} from '@app/@core/@config/config.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '@app/@core/auth/auth.service';
import {catchError} from 'rxjs/operators';
import {PasswordResetUpdateEvent, ResetStep} from '@app/sign-in/password-reset/password-reset.component';

type ResetEmailForm = FormGroup<{
  email: FormControl<string>
}>;


@Component({
  selector: 'app-pwd-reset-email-entry[errorKey][prefillUsername][parentUpdate]',
  templateUrl: './pwd-reset-email-entry.component.html',
  styleUrls: ['./pwd-reset-email-entry.component.scss'],
})
export class PwdResetEmailEntryComponent implements OnInit, OnChanges {
  @Input() errorKey?: string;
  @Input() prefillUsername!: string | null;
  @Output() parentUpdate = new EventEmitter<PasswordResetUpdateEvent>();
  resetEmailForm!: ResetEmailForm;
  resetEmailRequest$?: Observable<undefined>;

  constructor(
    private fb: NonNullableFormBuilder,
    public configService: ConfigService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetEmailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.errorKey = undefined;
    if (this.resetEmailForm.controls.email.invalid) {
      this.errorKey = 'signIn.emailFirst';
      return;
    }
    this.resetEmailRequest$ = this.authService.reset(this.resetEmailForm.value.email!)
      .pipe(
        // success
        tap(() => {
          this.parentUpdate.next({nextStep: ResetStep.EmailSent});
        }),
        // error handling
        catchError(error => {
          this.errorKey = error.error?.code || error.statusText || error;
          return EMPTY;
        }),
        // cleanup
        finalize(() => this.resetEmailRequest$ = undefined)
      );
  }

  isEmailInvalid() {
    return false;
  }

}
