<app-localhost-env-banner></app-localhost-env-banner>
<app-masquerade-banner *ngIf="masqueraderUsername && username" [masqueraderUsername]="masqueraderUsername"
                       [username]="username"></app-masquerade-banner>
<app-adblock-detected-banner></app-adblock-detected-banner>
<div id="app" class="overflow-hidden d-flex flex-column flex-fill">
  <app-sign-in-modal></app-sign-in-modal>
  <ng-container *ngIf="signedInContext$ | async as signedInContext; else loadingOrError">
    <app-header [signedInContext]="signedInContext"></app-header>
    <article class='container-fluid d-flex flex-column px-0 flex-fill'>
      <div class="mainContainer container">
        <router-outlet></router-outlet>
      </div>
    </article>
    <app-footer-bar></app-footer-bar>
  </ng-container>
</div>
<ng-template #loadingOrError>
  <div class="text-center flex-fill mt-5">
    <i *ngIf="!errorKey" class="fas fa-cog fa-spin"></i>
    <ngb-alert *ngIf="errorKey" type='danger' [dismissible]="false">{{ errorKey | translate }}</ngb-alert>
  </div>
</ng-template>
