<ng-container [formGroup]="targetingForm">
  <hr class='subDivider'>
  <h3>{{ 'campaign.userSegment.title' | translate }}</h3>
  <div formArrayName="userSegmentTargets" class="d-flex flex-column align-items-center">
    <div class="mb-1">
      <span [innerHTML]="'campaign.userSegment.descriptionPrefix' | translate"></span><strong
      *ngIf="selectedUserSegments() as selectedList"><span
      *ngIf="selectedList.length === 0">{{ 'campaign.userSegment.any' | translate }}</span><span
      *ngIf="selectedList.length > 0"><ng-container
      *ngFor="let userSegments of selectedList; let isLastTarget=last"><span
      *ngFor="let userSegment of userSegments; let isLastSegment=last">{{ userSegment.name }}<span
      *ngIf="!isLastSegment" translate="grammar.listSeparator"></span> <span *ngIf="!isLastTarget" translate="grammar.listSeparator"></span>
    </span></ng-container></span></strong>
    </div>
    <div class="">
      <ng-container *ngFor="let option of options; let i = index">
        <span *ngIf="'userSegmentTarget_'+uuid+i as id" class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="option.controlName"
            [id]="id"
          />
          <label class="form-check-label" [for]="id">
            <ng-container *ngFor="let userSegment of option.userSegments; let isLast=last">
              <span>{{ userSegment.name }}</span><span *ngIf="!isLast">,</span>
            </ng-container>
            <div class="form-text">
              <ng-container *ngFor="let userSegment of option.userSegments; let isLast=last">
                <span>{{ userSegment.description }}</span><span *ngIf="!isLast">,</span>
              </ng-container>
            </div>
          </label>
        </span>
      </ng-container>
    </div>
    <div class="form-text mt-0">{{ 'campaign.userSegment.instructions' | translate }}</div>
  </div>
</ng-container>
