import { Injectable } from '@angular/core';
import { EphemeralLocalObjectStore } from '@shared/storage/local/ephemeral-local-object-store';
import { AccessToken } from '@app/@core/@models/auth/access-token.model';
import { CacheRegistry } from '@shared/storage/cache-registry.service';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenStore {
  private readonly localAccessToken: EphemeralLocalObjectStore<AccessToken>;

  constructor(cacheRegistry: CacheRegistry) {
    this.localAccessToken = new EphemeralLocalObjectStore<AccessToken>(cacheRegistry, 'access_token');
  }

  getAccessToken(): AccessToken | undefined {
    return this.localAccessToken.getObject();
  }

  setAccessToken(accessToken: AccessToken) {
    this.localAccessToken.setObject(accessToken);
  }
}
