<div *ngIf='supportedLanguages.length > 1' class='languageContainer'>
  <i class='fas fa-globe'></i>
  <span *ngIf='!inNav'>{{ 'language.text' | translate }}</span>
  <ul class='list-inline'>
    <li *ngFor='let lang of supportedLanguages;'><a
      *ngIf='lang !== language' (click)='setLanguage(lang)'
          class='ngClick'>{{ 'language.' + lang + (inNav ? 'Short' : '') | translate }}</a><strong
      *ngIf='lang === language' [ngClass]="{ 'fw-bold': lang === language }"
      class='active'>{{ 'language.' + lang + (inNav ? 'Short' : '') | translate }}</strong></li>
  </ul>
  <ngb-alert class='p-0' *ngIf='errorKey' type='danger' [dismissible]='false'>{{ errorKey | translate }}</ngb-alert>
</div>
