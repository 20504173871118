<div style='background-color: #fff; padding: 50px;'>
  <h3>{{ 'errors.standard.unregistered.title' | translate  }}</h3>
  <p [innerHTML]="'errors.standard.unregistered.p1' | translate"></p>
  <p>
    <span [innerHTML]="'errors.standard.unregistered.p2' | translate"></span><a
    [routerLink]="registerUrl">{{ 'errors.standard.unregistered.p2LinkText' | translate  }}</a><span
    [innerHTML]="'errors.standard.unregistered.p2Suffix' | translate"></span>
  </p>
  <p>
    <span [innerHTML]="'errors.standard.unregistered.p3' | translate"></span><a
    [routerLink]="signInUrl">{{ 'errors.standard.unregistered.p3LinkText' | translate  }}</a><span
    [innerHTML]="'errors.standard.unregistered.p3Suffix' | translate"></span>
  </p>
</div>
