import { Component, OnInit } from '@angular/core';
import {AccessToken} from '@app/@core/@models/auth/access-token.model';

@Component({
  selector: 'app-masquerade',
  templateUrl: './masquerade.component.html',
  styleUrls: ['./masquerade.component.scss']
})
export class MasqueradeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  handleAccessToken(_accessToken: AccessToken) {
    throw "should never get a new AccessToken in MasqueradeComponent";
  }
}
