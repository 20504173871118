import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ConfigService } from '@app/@core/@config/config.service';
import { UserProfile } from '@app/@core/@models/users/profile/user-profile.model';
import { map } from 'rxjs/operators';
import { convertJsonStringFieldToObject } from '@shared/utils/json-response-util';

interface BackendUserProfilePayload {
  id: string;
  profile: Map<string, string>;
}

// ng.api.resource.UserProfileResource$UserProfile
interface BackendUserProfileResponse {
  username: string;
  profile: Map<string, string>;
}

@Injectable({
  providedIn: 'root',
})
export class UserProfileResource {
  constructor(private httpClient: HttpClient, private configService: ConfigService) {}

  update(userId: string, user: UserProfile): Observable<UserProfile> {
    return this.httpClient
      .post<BackendUserProfileResponse>(
        this.configService.getVersionedUri('userprofiles/' + userId),
        this.toBackendUserProfilePayload(userId, user),
        {
          headers: new HttpHeaders().set('Content-Type', 'text/plain')
        }
      )
      .pipe(
        map((backendUserProfile) => {
          return convertJsonStringFieldToObject(backendUserProfile, 'profile').profile as UserProfile;
        })
      );
  }

  private toBackendUserProfilePayload(userId: string, userProfile: UserProfile): BackendUserProfilePayload {
    const result: BackendUserProfilePayload = { id: userId, profile: new Map() };
    Object.keys(userProfile).forEach((key) => {
      result.profile[key] = JSON.stringify(userProfile[key]);
    });
    return result;
  }
}
