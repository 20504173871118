/**
 * Copyright © 2023 Adnuntius AS.
 */
import * as _ from 'lodash-es';
import _assign from 'lodash-es/assign';
import _compact from 'lodash-es/compact';
import _trim from 'lodash-es/trim';
import _find from 'lodash-es/find';
import { ALL_END_POINTS } from './all-end-points';

const defaultEndPoint = 'localhost';

const buildUri = function (
  isRelative: boolean,
  scheme: string,
  host: string,
  port: number,
  basePath: string,
  path: string | Array<string>,
  isInternal?: boolean
) {
  let uri = '';
  if (!isRelative) {
    uri = scheme + '://' + host;
    if (!!port && ((scheme === 'http' && port !== 80) || (scheme === 'https' && port !== 443))) {
      uri += ':' + port;
    }
  }

  path = _.map(Array.isArray(path) ? path : path.split('/'), encodeURIComponent)
    .join('/')
    .replace(/%3A/gi, ':');

  if (isInternal) {
    basePath += '/internal';
  }

  return uri + '/' + _compact([_trim(basePath, '/'), _trim(path, '/')]).join('/');
};

class RemoteConfig {
  config: any = _assign({}, { isRelative: false }, ALL_END_POINTS[defaultEndPoint]['ADN_API']);

  setConfig(config: any) {
    this.config = config;
    return this;
  }

  getUri(path: string) {
    return buildUri(
      this.config.isRelative,
      this.config.scheme,
      this.config.host,
      this.config.port,
      this.config.basePath,
      path
    );
  }

  getId() {
    return this.config.id;
  }

  getEnv() {
    return this.config.env;
  }

  getType() {
    return this.config.type;
  }

  getSiteKey() {
    return this.config.siteKey;
  }

  updateConfig(newConfig: any) {
    _assign(this.config, newConfig);
  }
}

class VersionedRemoteConfig extends RemoteConfig {
  constructor() {
    super();
    this.updateConfig({ version: 'v1' });
  }

  getVersionedUri(path: string | Array<string>) {
    return buildUri(
      this.config.isRelative,
      this.config.scheme,
      this.config.host,
      this.config.port,
      _compact([this.config.basePath, this.config.version]).join('/'),
      path
    );
  }

  getVersionedInternalUri(path: string) {
    return buildUri(
      this.config.isRelative,
      this.config.scheme,
      this.config.host,
      this.config.port,
      _compact([this.config.basePath, this.config.version]).join('/'),
      path,
      true
    );
  }
}

export class ApiConfig extends VersionedRemoteConfig {
  private static instance: ApiConfig;

  private constructor() {
    super();
  }

  public static obtain(): ApiConfig {
    return this.instance || (this.instance = new ApiConfig());
  }
}

export class AdServerConfig extends RemoteConfig {
  private static instance: AdServerConfig;

  private constructor() {
    super();
  }

  public static obtain(): AdServerConfig {
    return this.instance || (this.instance = new AdServerConfig());
  }
}

export class DataServerConfig extends RemoteConfig {
  private static instance: DataServerConfig;

  private constructor() {
    super();
  }

  public static obtain(): DataServerConfig {
    return this.instance || (this.instance = new DataServerConfig());
  }
}

export function initApiConfig(win: any) {
  const windowEnvKey = win.ADN_ENV_KEY;
  if (windowEnvKey) {
    const newEndPoints =
      _find(ALL_END_POINTS, function (val, key) {
        return key.indexOf(windowEnvKey) === 0;
      }) || ALL_END_POINTS[defaultEndPoint];

    ApiConfig.obtain().setConfig(newEndPoints['ADN_API']);
    AdServerConfig.obtain().setConfig(newEndPoints['ADN_AD_SERVER']);
    DataServerConfig.obtain().setConfig(newEndPoints['ADN_DATA_SERVER']);
  }
}
