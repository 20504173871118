import { Injectable } from '@angular/core';
import { CacheRegistry } from '@shared/storage/cache-registry.service';
import { AnonProfile } from '@app/@core/@models/auth/anon-profile.model';
import {deepUpdate} from '@shared/utils/update-utils';
import {PermanentLocalObjectStore} from '@shared/storage/local/permanent-local-object-store';

@Injectable({
  providedIn: 'root',
})
export class AnonProfileStore {
  private readonly anonProfile: PermanentLocalObjectStore<AnonProfile>;

  constructor(cacheRegistry: CacheRegistry) {
    this.anonProfile = new PermanentLocalObjectStore<AnonProfile>(cacheRegistry, 'anon_profile');
  }

  getAnonProfile(): AnonProfile {
    return this.anonProfile.getObject() || {};
  }

  clearAnonProfile() {
    this.updateAnonProfile({username: undefined});
  }

  updateAnonProfile(update: AnonProfile) {
    this.anonProfile.setObject(deepUpdate(this.getAnonProfile(), update));
  }
}
