import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {I18nLangService} from '@app/@i18n/services/i18n-lang.service';
import {Logger} from '@shared/logging/logger';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ConfigService} from '@app/@core/@config/config.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  faviconLinkElement: HTMLLinkElement | null = document.querySelector('#favicon');
  bodyElement: HTMLBodyElement | null = document.querySelector('body');
  headElement: HTMLHeadElement | null = document.querySelector('head');

  constructor(
    private router: Router,
    private i18nService: I18nLangService,
    private configService: ConfigService
  ) {
  }

  ngOnInit() {
    this.initLogger();
    this.initFavicon();
    this.initUserTheme();
    this.initStylesheets();
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  private initLogger() {
    if (environment.productionBuild) {
      Logger.enableProductionMode();
    }
  }

  private initFavicon() {
    const networkData = this.configService.getNetworkData();
    if (this.faviconLinkElement && networkData && networkData.favicon) {
      this.faviconLinkElement.href = networkData.favicon.href;
      this.faviconLinkElement.rel = networkData.favicon.rel;
      this.faviconLinkElement.type = networkData.favicon.type;
    }
  }

  private initUserTheme() {
    const networkData = this.configService.getNetworkData();
    if (this.bodyElement && networkData && networkData.theme) {
      this.bodyElement.setAttribute('data-user-theme', networkData.theme);
    }
  }

  private initStylesheets() {
    const networkData = this.configService.getNetworkData();
    if (this.headElement && networkData && networkData.stylesheets) {
      networkData.stylesheets.forEach((stylesheet) => {
        const fontLink = document.createElement("link");
        fontLink.rel = "stylesheet";
        fontLink.href = stylesheet;
        this.headElement?.appendChild(fontLink);
      });
    }
  }
}
