import { CacheRegistry, FlushableCache } from '@shared/storage/cache-registry.service';
import {BaseLocalObjectStore} from '@shared/storage/local/base-local-object-store';

/**
 * Stores objects to local storage, is flushed during certain authentication flows, and when network changes.
 */
export class EphemeralLocalObjectStore<T> extends BaseLocalObjectStore<T> implements FlushableCache {

  constructor(private cacheRegistry: CacheRegistry, key: string, private flushCallback?: () => void ) {
    super(key);
    this.cacheRegistry.registerFlushable(key, this);
  }

  flush(): void {
    this.removeObject();
    if (this.flushCallback) {
      this.flushCallback();
    }
  }
}
