import { CacheRegistry } from '@shared/storage/cache-registry.service';
import {BaseLocalObjectStore} from '@shared/storage/local/base-local-object-store';

/**
 * Permanent local storage.
 */
export class PermanentLocalObjectStore<T> extends BaseLocalObjectStore<T> {

  constructor(private cacheRegistry: CacheRegistry, key: string) {
    super(key);
    cacheRegistry.registerNonFlushable(key, this);
  }

}
