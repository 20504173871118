import { Injectable } from '@angular/core';
import { Observable, Subject, take} from 'rxjs';
import {AccessToken} from '@app/@core/@models/auth/access-token.model';

/**
 * TokenInterceptor commands the UI to show sign in dialog
 */
export interface SignInCommand {
  isNewSession: boolean
}

/**
 * This service provides a mechanism for TokenInterceptor to obtain a new AccessToken from SignInModalComponent.
 */
@Injectable({
  providedIn: 'root'
})
export class SignInModalLauncher {
  launchSource = new Subject<SignInCommand>();
  tokenSource?: Subject<AccessToken>;

  constructor() {
  }

  /**
   * TokenInterceptor calls this method to request the SignInModalComponent to be shown.
   * Returned Observable will return a new AccessToken, or an error if the user gave up on signing in.
   */
  requestSignIn(command: SignInCommand): Observable<AccessToken> {
    this.launchSource.next(command);
    if (this.tokenSource) {
      console.log("weird, requesting modal sign in while previous sign in request is in progress")
    }
    this.tokenSource = new Subject<AccessToken>();
    return this.tokenSource.pipe(
      take(1)   // complete after 1 token comes through, or on error
    )
  }

  /**
   * SignInModal subscribes via this method to receive sign-in requests.
   */
  signInCommandEmitter(): Observable<SignInCommand> {
    return this.launchSource;
  }

  /**
   * SignInModal should call this method when the user sign-in successfully.
   * This will allow TokenInterceptor to retry requests and resume operation as if nothing happened.
   */
  signInSuccess(accessToken: AccessToken) {
    this.tokenSource!.next(accessToken);
    this.tokenSource = undefined;
  }

  /**
   * SingInModal should call this method when the user gives up on signin in.
   * This will communicate to TokenInterceptor to abandon all requests that were not authenticated.
   * Note: in practice the sign-in modal will probably perform a browser navigation to /sign-in
   * which effectively re-loads the page, and is not an SPA transition. So this method is strictly
   * not necessary, but keeping it here for completeness.
   */
  signInAbandoned(err: any) {
    this.tokenSource!.error(err);
    this.tokenSource = undefined;
  }

}
