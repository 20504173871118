import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CampaignDraft} from '@app/@core/advertising/campaign/campaign-draft';
import {defaultIfEmpty, EMPTY, finalize, forkJoin, Observable, of, switchMap, tap} from 'rxjs';
import {LineItemResource} from '@app/@core/@rest/advertising/line-item-resource.service';
import {LineItem} from '@app/@core/@models/advertising/lineitem.model';
import {catchError} from 'rxjs/operators';
import {CreativeResource} from '@app/@core/@rest/advertising/creative-resource.service';
import {Creative} from '@app/@core/@models/advertising/creative.model';
import {CampaignEvent} from '@app/@core/advertising/campaign/campaign-event';

@Component({
  selector: 'app-submit-post-payment[campaignDraft][isSubmitting][submitResult]',
  templateUrl: './submit-post-payment.component.html',
  styleUrls: ['./submit-post-payment.component.scss']
})
export class SubmitPostPaymentComponent implements OnInit {
  @Input() campaignDraft!: CampaignDraft;
  @Output() isSubmitting = new EventEmitter<boolean>();
  @Output() submitResult = new EventEmitter<CampaignEvent>();
  submit$?: Observable<any>;
  errorKey?: string;

  constructor(private lineItemResource: LineItemResource,
              private creativeResource: CreativeResource) {
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.errorKey = undefined;
    this.isSubmitting.next(true);
    this.submit$ = of(undefined).pipe(
      // update creatives
      switchMap(() =>
        forkJoin(
          this.campaignDraft.advertDrafts.map(advertDraft => this.creativeResource.update({
            id: advertDraft.creative.id,
            userState: 'APPROVED'
          } as Creative))
        ).pipe(
          // in case there were no creatives, we still want the stream to continue
          defaultIfEmpty(undefined),
        )
      ),
      // update line item
      switchMap(() => this.lineItemResource.update({
        id: this.campaignDraft.lineItemReview.lineItem.id,
        userState: 'SUBMITTED',
      } as LineItem)),
      // success
      tap(() => {
        this.submitResult.next({
          infoKeyParagraph1: 'campaign.running.submittedParagraph1',
          infoKeyParagraph2: 'campaign.running.submittedParagraph2',
          reloadLineItemReview: true,
        });
      }),
      // error handling
      catchError((error) => {
        this.errorKey = error.error?.code || error.statusText || error;
        return EMPTY;
      }),
      finalize(() => {
        this.isSubmitting.next(false);
      })
    );
  }
}
