import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {ValidationWarningMessage} from '@app/@core/@models/advertising/validation-warning-message.model';
import { ConfigService } from '@app/@core/@config/config.service';
import { Uuid } from '@shared/utils/uuid';

@Component({
  selector: 'app-date-time-input[dateControl][timeControl][labelKey][validationWarnings]',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss']
})
export class DateTimeInputComponent implements OnInit {
  @Input() dateControl!: FormControl<NgbDateStruct>;
  @Input() timeControl!: FormControl<NgbTimeStruct>;
  @Input() labelKey!: string;
  @Input() validationWarnings!: ValidationWarningMessage[];
  hideDateTimes: boolean = false;
  uuid: string = Uuid.generate();

  constructor(private configService: ConfigService) { }

  ngOnInit(): void {
    this.hideDateTimes = !!this.configService.getNetworkData()?.hideDateTimes;
  }

  isDateControlInvalid(): boolean {
    return this.dateControl.invalid || (this.dateControl.pristine && this.validationWarnings.length > 0);
  }
}
