import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NetworkData } from '@app/@core/@config/registered-urls-data';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfigService } from '@app/@core/@config/config.service';
import {PasswordResetUpdateEvent} from '@app/sign-in/password-reset/password-reset.component';

@Component({
  selector: 'app-pwd-reset-email-sent[parentUpdate]',
  templateUrl: './pwd-reset-email-sent.component.html',
  styleUrls: ['./pwd-reset-email-sent.component.scss'],
})
export class PwdResetEmailSentComponent implements OnInit {
  @Output() parentUpdate = new EventEmitter<PasswordResetUpdateEvent>();
  networkData!: NetworkData;

  constructor(private formBuilder: UntypedFormBuilder, private configService: ConfigService) {
    this.networkData = configService.getNetworkData()!;
  }

  ngOnInit(): void {}
}
